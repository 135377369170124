import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import React, { FC, useCallback, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { Card, CardBody, Col, Form, Row } from "reactstrap";

import { formUfonSchema } from "./validations";
import { UfonT } from "../../../types/Ufon";
import { DeviceFormInput, SelectOptionsT } from "../../../types/Common";
import { useQueryCompaniesGet } from "../../../hooks/useQueries";
import { formatDate } from "../../../functions/formatDate";
import { DATE_FORMATS } from "../../../constants";
import { Cards } from "../../atoms/Cards/Cards";
import { InputController } from "../Form/InputController";

type PropsT = {
  defaultValues?: UfonT;
  onTouch: () => void;
  onSubmit: (v: DeviceFormInput) => void;
  isDisabledAll?: boolean;
};

export const FormUfon: FC<PropsT> = ({
  onSubmit,
  onTouch,
  defaultValues,
  isDisabledAll,
}) => {
  const getDefaultValues = useCallback(() => {
    return {
      title: defaultValues?.name ?? "",
      name: defaultValues?.internalName ?? "",
      serialNumber: defaultValues?.serialNumber ?? "",
      companyId: `${defaultValues?.company.id}` ?? "",
      purchaseDate: defaultValues?.purchaseDate
        ? formatDate(defaultValues?.purchaseDate, DATE_FORMATS.dateForm)
        : "",
    };
  }, [defaultValues]);

  const {
    handleSubmit,
    control,
    formState: { errors, isDirty },
    reset,
  } = useForm<DeviceFormInput>({
    resolver: yupResolver(formUfonSchema),
    defaultValues: getDefaultValues(),
  });
  const { data: companiesData, isLoading } = useQueryCompaniesGet();

  const selectCompanyOptions = useMemo(
    () =>
      (
        [
          { value: "", label: "Choose company", disabled: true },
        ] as SelectOptionsT
      ).concat(
        (companiesData?.data.items || []).map((company) => ({
          label: company.name,
          value: company.id,
        }))
      ),

    [companiesData]
  );

  useEffect(() => {
    reset(getDefaultValues());
  }, [getDefaultValues, reset]);

  useEffect(() => {
    if (isDirty) {
      onTouch();
    }
  }, [onTouch, isDirty]);

  return (
    <Form onSubmit={handleSubmit(onSubmit)} id="ufon-form">
      <Cards>
        <Card>
          <CardBody>
            <Row>
              <Col lg="6">
                <InputController
                  name="title"
                  label="Title"
                  control={control}
                  errors={errors}
                  input={{ disabled: isDisabledAll }}
                />
                <InputController
                  name="companyId"
                  label="Company"
                  control={control}
                  errors={errors}
                  options={selectCompanyOptions}
                  input={{
                    disabled: isDisabledAll || isLoading,
                  }}
                />
                <InputController
                  name="serialNumber"
                  label="Serial number"
                  control={control}
                  errors={errors}
                  input={{ disabled: isDisabledAll }}
                />
                <InputController
                  name="name"
                  label="Internal code"
                  control={control}
                  errors={errors}
                  input={{ disabled: isDisabledAll }}
                />
                <InputController
                  name="purchaseDate"
                  label="Purchase date"
                  control={control}
                  errors={errors}
                  input={{
                    type: "date",
                    disabled: isDisabledAll,
                  }}
                />
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Cards>
    </Form>
  );
};
