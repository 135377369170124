import {
  Locale,
  addDays,
  addMonths,
  addYears,
  endOfDay,
  endOfMonth,
  endOfWeek,
  endOfYear,
  isSameDay,
  startOfDay,
  startOfMonth,
  startOfWeek,
  startOfYear,
} from "date-fns";

export type BaseStaticDateRangeT = {
  label: string;
  startDate: () => Date;
  endDate: () => Date;
};

export type StaticDateRangeT = BaseStaticDateRangeT & {
  isSame: (start: Date, end: Date) => boolean;
};

function isSameFactory(
  range: BaseStaticDateRangeT
): (start: Date, end: Date) => boolean {
  return (start, end) =>
    isSameDay(start, range.startDate()) && isSameDay(end, range.endDate());
}

function toDateRange(baseRange: BaseStaticDateRangeT): StaticDateRangeT {
  return {
    ...baseRange,
    isSame: isSameFactory(baseRange),
  };
}

function lastNDaysRange(days: number): BaseStaticDateRangeT {
  return {
    label: `Last ${days} days`,
    startDate: () => startOfDay(addDays(new Date(), -days + 1)),
    endDate: () => endOfDay(new Date()),
  };
}

// Using `startOfDay` and similar functions moves the date to the start of day in the local timezone
// If we wanted to support switching to different timezones then we have 2 problems:
// - The base date-fns library doesn't support timezones
// - The react-date-range uses these functions internally, so we would need to somehow make it aware of timezones (probably fork)
const baseStaticRanges: (currLocale: Locale) => BaseStaticDateRangeT[] = (
  currLocale
) => [
  {
    label: "Today",
    startDate: () => startOfDay(new Date()),
    endDate: () => endOfDay(new Date()),
  },
  {
    label: "Yesterday",
    startDate: () => startOfDay(addDays(new Date(), -1)),
    endDate: () => endOfDay(addDays(new Date(), -1)),
  },
  {
    label: "This Week",
    startDate: () => startOfWeek(new Date(), { locale: currLocale }),
    endDate: () => endOfWeek(new Date(), { locale: currLocale }),
  },
  {
    label: "Last Week",
    startDate: () =>
      startOfWeek(addDays(new Date(), -7), { locale: currLocale }),
    endDate: () => endOfWeek(addDays(new Date(), -7), { locale: currLocale }),
  },
  {
    label: "This Month",
    startDate: () => startOfMonth(new Date()),
    endDate: () => endOfMonth(new Date()),
  },
  {
    label: "Last Month",
    startDate: () => startOfMonth(addMonths(new Date(), -1)),
    endDate: () => endOfMonth(addMonths(new Date(), -1)),
  },
  lastNDaysRange(7),
  lastNDaysRange(30),
  lastNDaysRange(90),
  {
    label: "Last 12 Months",
    startDate: () => startOfDay(addYears(new Date(), -1)),
    endDate: () => endOfDay(new Date()),
  },
  {
    label: "This Year",
    startDate: () => startOfYear(new Date()),
    endDate: () => endOfYear(new Date()),
  },
  {
    label: "Last Year",
    startDate: () => startOfYear(addYears(new Date(), -1)),
    endDate: () => endOfYear(addYears(new Date(), -1)),
  },
];

export const defaultStaticRanges: (currLocale: Locale) => StaticDateRangeT[] = (
  currLocale
) => baseStaticRanges(currLocale).map(toDateRange);
