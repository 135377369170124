import { toast } from "react-toastify";

export async function writeToClipboard(text: string) {
  try {
    await navigator.clipboard.writeText(text);
    return true;
  } catch (err) {
    toast.error("Failed to copy to clipboard.");
    return false;
  }
}
