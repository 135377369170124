import { endOfDay, parse, startOfDay } from "date-fns";
import { DATE_FORMATS } from "../../../constants";
import { NotificationListQueryT } from "../../../types/Notification";
import { useSearchParams } from "../../../hooks/useSearchParam";
import { parseSelection } from "../../../functions/notifications";
import { formatDate, isValidDate } from "../../../functions/formatDate";
import { NotificationList } from "../../molecules/NotificationList/NotificationList";
import CardWithGenericHeader from "../../atoms/CardWithHeader/CardWithGenericHeader";
import { NotificationFilter } from "./NotificationFilter";
import styles from "./notificationTab.module.scss";

type PropsT = {
  baseQuery: NotificationListQueryT;
};

const DATE_FORMAT = DATE_FORMATS.dateForm;
const parseDate = (date: string | null, reference: Date) => {
  if (!date) {
    return reference;
  }
  const parsed = parse(date, DATE_FORMAT, reference);
  return isValidDate(parsed) ? parsed : reference;
};

export default function NotificationTab({ baseQuery }: PropsT) {
  const { updateSearchParams, getParamValue } = useSearchParams();
  const page = Math.max(Number(getParamValue("page")) || 1, 1);
  const selectedOption =
    parseSelection(getParamValue("notificationType")) || "all";
  const endDate = parseDate(getParamValue("endDate"), endOfDay(new Date()));
  const startDate = parseDate(
    getParamValue("startDate"),
    startOfDay(new Date())
  );

  return (
    <CardWithGenericHeader
      header={
        <div>
          <span className={styles.title}>Message history</span>
          <NotificationFilter
            className={styles.outsideFilter}
            dateRange={{ start: startDate, end: endDate }}
            selectedOption={selectedOption}
            onDateRangeChange={({ start, end }) =>
              updateSearchParams((state) => {
                return {
                  ...state,
                  startDate: formatDate(start, DATE_FORMAT),
                  endDate: formatDate(end, DATE_FORMAT),
                  page: null,
                };
              })
            }
            onSelectionChange={(option) =>
              updateSearchParams((state) => {
                return { ...state, notificationType: option, page: null };
              })
            }
          />
        </div>
      }
    >
      <NotificationList
        currentPage={page}
        setCurrentPage={(newPage) =>
          updateSearchParams((params) => {
            return { ...params, page: String(newPage) };
          })
        }
        query={{
          ...baseQuery,
          notificationType:
            selectedOption !== "all" ? selectedOption : undefined,
          createdAtFrom: startDate,
          createdAtTo: endDate,
        }}
      />
    </CardWithGenericHeader>
  );
}
