import { useState } from "react";
import { Card } from "reactstrap";
import { useDispatch, useSelector } from "../../../store/hooks";
import {
  setHidePageHeader,
  setShowFullOverview,
} from "../../../store/slices/jobRouteTypeSlice";
import { jobStateCanBeChanged } from "../../../functions/jobArchive";
import { getJobStatus } from "../../../functions/jobStatus";
import { StyledButton } from "../../../components/atoms/Button/Button";
import { FooterButtons } from "../../../components/atoms/FooterButtons/FooterButtons";
import JobCard from "../../../components/molecules/JobCard/JobCard";
import { CardHeader } from "../../../components/atoms/CardHeader/CardHeader";
import { Cards } from "../../../components/atoms/Cards/Cards";
import { JobStateProps } from "../types";
import {
  filterMowersBySegmentStatus,
  findAllFinishedSegments,
  getMowersByUser,
} from "../utils";
import MowerMenOverview from "./MowerMenOverview";
import styles from "../jobRouteType.module.scss";
import { autoUploadRouteAction } from "../../../store/slices/mowerSlice";
import { useMutationStartJob } from "../../../hooks/useMutationStartJob";

const JobOverview = ({
  job,
  isMultiMower,
  setCurrentPage,
  hasSegments,
  setMowerMan,
  setDetailMower,
  continueFromOverview,
  refetchJob,
}: JobStateProps) => {
  const dispatch = useDispatch();
  const [showFinishedSegments, setShowFinishedSegments] = useState(false);
  const { showFullOverview } = useSelector((store) => store.jobRouteType);
  const filteredMowers = filterMowersBySegmentStatus(job);
  const mowersByUser = getMowersByUser(job);
  const jobStatus = getJobStatus(job);

  const finishedSegmentsList = findAllFinishedSegments(job);

  const uploadRoute = () => {
    if (hasSegments) {
      const mowersWithSegments = job.segments.users.flatMap(
        (user) => user.mowers
      );
      mowersWithSegments.forEach((jobMower) => {
        dispatch(
          autoUploadRouteAction({
            jobId: job.id,
            mowerId: jobMower.id,
            segmentId: jobMower.segmentId,
          })
        );
      });
    } else {
      job.mowers.forEach((jobMower) =>
        dispatch(autoUploadRouteAction({ jobId: job.id, mowerId: jobMower.id }))
      );
    }
  };

  const startJobMutation = useMutationStartJob({
    onSuccess: () => {
      refetchJob();
      uploadRoute();
      continueFromOverview();
    },
  });

  const startJobMutationWithSegments = useMutationStartJob({
    onSuccess: () => {
      refetchJob();
      uploadRoute();
      setCurrentPage("segmentsForm");
    },
  });

  const startJobHandler = async () => {
    startJobMutation.mutateAsync(job.id);
  };

  const startJobWithSegmentsHandler = async () => {
    startJobMutationWithSegments.mutateAsync(job.id);
  };

  const getMultiMowerFooterButtons = (disabled: boolean) => {
    if (jobStatus === "beforeStart") {
      return (
        <>
          <StyledButton
            title={"Start job"}
            color="primary"
            onClick={startJobHandler}
            disabled={disabled}
          />
          <StyledButton
            title="Start job with segments"
            color="primary"
            disabled={disabled}
            onClick={startJobWithSegmentsHandler}
          />
        </>
      );
    }
    if (hasSegments) {
      return (
        <>
          <StyledButton
            title="Create route segments"
            disabled={filteredMowers.finished.length === 0 || disabled}
            color="light"
            onClick={() => setCurrentPage("segmentsForm")}
          />
          <StyledButton
            title={"Job Progress"}
            color="primary"
            disabled={filteredMowers.active.length === 0 || disabled}
            onClick={() => continueFromOverview()}
          />
        </>
      );
    }
    return (
      <>
        <StyledButton
          title={"Continue without segments"}
          color="light"
          onClick={() => continueFromOverview()}
          disabled={disabled}
        />
        <StyledButton
          title="Create route segments"
          color="primary"
          disabled={disabled}
          onClick={() => setCurrentPage("segmentsForm")}
        />
      </>
    );
  };

  const getFooterButtons = (disabled: boolean) => {
    if (jobStatus === "completed") {
      return null;
    }
    if (isMultiMower) {
      return getMultiMowerFooterButtons(disabled);
    }
    if (jobStatus !== "beforeStart") {
      return (
        <StyledButton
          title={"Job progress"}
          color="primary"
          onClick={() => continueFromOverview()}
          disabled={disabled}
        />
      );
    }
    return null;
  };

  if (showFinishedSegments && finishedSegmentsList.length !== 0) {
    return (
      <>
        <h5 className={styles.title}>Finished segments</h5>
        <Cards>
          {finishedSegmentsList.map((segment, index) => (
            <Card
              key={`${segment.segmentId} - ${index}`}
              style={{
                borderLeftColor: segment.segmentColor?.primaryColor || "white",
                borderLeftWidth: "5px",
                borderLeftStyle: "solid",
              }}
            >
              <CardHeader
                title={`${segment.name} / ${segment.segmentName}`}
                icon="mower"
                size="sm"
                className="ps-3 pt-2"
              />
            </Card>
          ))}
        </Cards>
        <FooterButtons>
          <StyledButton
            title="Back"
            color="primary"
            onClick={() => {
              setShowFinishedSegments(false);
              dispatch(setHidePageHeader(false));
            }}
          />
        </FooterButtons>
      </>
    );
  }

  if (showFullOverview && !hasSegments) {
    return (
      <>
        <MowerMenOverview
          setCurrentPage={setCurrentPage}
          setMowerMan={setMowerMan}
          setDetailMower={setDetailMower}
          job={job}
          mowersByUser={mowersByUser}
        />
        <FooterButtons>
          <StyledButton
            title="Back"
            color="primary"
            onClick={() => {
              dispatch(setShowFullOverview(false));
              dispatch(setHidePageHeader(false));
            }}
          />
        </FooterButtons>
      </>
    );
  }

  const canUserChangeJobState = jobStateCanBeChanged(job);

  return (
    <>
      <>
        <JobCard
          job={job}
          setCurrentPage={() => setCurrentPage("jobHistory")}
        />
        {hasSegments && (
          <>
            <MowerMenOverview
              setCurrentPage={setCurrentPage}
              setMowerMan={setMowerMan}
              setDetailMower={setDetailMower}
              job={job}
              userWithInactiveMowers={filteredMowers.finished}
              userWithActiveMowers={filteredMowers.active}
            />
            {finishedSegmentsList.length !== 0 && (
              <StyledButton
                color="link"
                title="Finished segments"
                className={styles.finishedBtn}
                disabled={!canUserChangeJobState}
                onClick={() => {
                  dispatch(setHidePageHeader(true));
                  setShowFinishedSegments(true);
                }}
              />
            )}
          </>
        )}
        <FooterButtons>
          {getFooterButtons(!canUserChangeJobState)}
        </FooterButtons>
      </>
    </>
  );
};

export default JobOverview;
