import React, { FC } from "react";
import { UserT } from "../../../types/User";
import styles from "./styles.module.scss";

type PropsT = {
  user: UserT;
};

const UserInitials: FC<PropsT> = ({ user }) => {
  return (
    <div className={styles.wrapper}>
      <span>
        {user && user.firstName && user.firstName.charAt(0).toUpperCase()}
        {user && user.surname && user.surname.charAt(0).toUpperCase()}
      </span>
    </div>
  );
};

export default UserInitials;
