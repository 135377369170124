import classNames from "classnames";
import React, { FC } from "react";
import { Link, LinkProps } from "react-router-dom";
import { IconsId } from "../../../assets/fonts/iconfont/icons";
import { Icon } from "../Icon";
import styles from "./actionButton.module.scss";

export type ActionButtonPropsT = {
  icon: IconsId;
  disabled?: boolean;
  link?: LinkProps["to"];
  onClick?: () => void;
  tagTitle: string;
};

const HelperContent: FC<ActionButtonPropsT> = ({
  icon,
  disabled,
  onClick,
  tagTitle,
}) => {
  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (disabled) {
      return;
    }
    if (onClick) {
      onClick();
    }
  };

  return (
    <button
      className={classNames(styles["action-button"], {
        [styles["action-button--disabled"]]: disabled,
      })}
      onClick={handleClick}
      title={tagTitle}
    >
      <Icon icon={icon} />
    </button>
  );
};

export const ActionButton: FC<ActionButtonPropsT> = (props) => {
  if (props.link) {
    return (
      <Link to={props.link}>
        <HelperContent {...props} />
      </Link>
    );
  }

  return <HelperContent {...props} />;
};
