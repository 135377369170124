import classNames from "classnames";
import React, { FC } from "react";
import { Spinner } from "reactstrap";
import styles from "./loader.module.scss";

type PropsT = {
  isLoading?: boolean;
  isOverlay?: boolean;
  isCentered?: boolean;
  text?: string;
};

export const Loader: FC<PropsT> = ({
  isOverlay,
  isLoading = true,
  isCentered,
  text,
}) => {
  if (!isLoading) {
    return null;
  }

  return (
    <div
      className={classNames(styles.wrapper, {
        [styles["wrapper-overlay"]]: isOverlay,
        [styles["wrapper-centered"]]: isCentered,
      })}
    >
      <Spinner color="primary" />
      {text && <p>{text}</p>}
    </div>
  );
};
