import React, { FC } from "react";
import { SubmitHandler } from "react-hook-form";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { queryClient } from "../../..";
import { useMutationRouteGroupCreate } from "../../../hooks/useMutationRouteGroupCreate";
import {
  FormRouteGroup,
  FormRouteGroupInputsT,
} from "../../molecules/FormRouteGroup/FormRouteGroup";

type PropsT = {
  isOpen: boolean;
  onClose: () => void;
};

export const RouteGroupCreateModal: FC<PropsT> = ({ isOpen, onClose }) => {
  const createGroupMutation = useMutationRouteGroupCreate({
    onSuccess: () => {
      onClose();
      queryClient.invalidateQueries(["group"]);
    },
  });

  const handleSubmitGroupCreate: SubmitHandler<FormRouteGroupInputsT> = async (
    data: FormRouteGroupInputsT
  ) => {
    createGroupMutation.mutate(data.name);
  };

  return (
    <Modal isOpen={isOpen} toggle={onClose}>
      <ModalHeader toggle={onClose}>Add new route's group</ModalHeader>
      <ModalBody>
        <FormRouteGroup onSubmit={handleSubmitGroupCreate} />
      </ModalBody>
      <ModalFooter>
        <Button color="light" onClick={onClose}>
          Cancel
        </Button>
        <Button
          color="primary"
          type="submit"
          form="route-group-form"
          className="me-2"
        >
          Create
        </Button>
      </ModalFooter>
    </Modal>
  );
};
