import React, { useState } from "react";
import { useNavigate } from "react-router";
import { Marker } from "@react-google-maps/api";
import { ROUTES } from "../../../routes";
import UfonIcon from "../../../assets/images/ufon-tracker-online.svg";
import UfonIconOffline from "../../../assets/images/ufon-tracker-offline.svg";
import { JobMapInfo } from "../../../types/Route";
import { useSelector } from "../../../store/hooks";
import { TrackedUfonT } from "../../../store/slices/ufonSlice";
import JobMapInfoWindow from "../../molecules/MapInfoWindow/JobMapInfoWindow";
import { Loader } from "../Loader/Loader";

type UfonMarkerType = {
  ufonId: number;
};

const UfonMarker = ({ ufonId }: UfonMarkerType) => {
  const ufon: TrackedUfonT | undefined = useSelector(
    (store) => store.ufon.ufons[ufonId]
  );
  const isLoading = useSelector((store) => store.ufon.loading);
  const navigate = useNavigate();
  const [activeMarker, setActiveMarker] = useState<JobMapInfo | undefined>(
    undefined
  );

  if (isLoading) {
    return <Loader isCentered />;
  }

  if (ufon && ufon.ufon && ufon.ufon?.latitude && ufon.ufon.longitude) {
    return (
      <>
        <Marker
          icon={{
            url:
              "isOnline" in ufon.ufon && ufon.ufon.isOnline
                ? UfonIcon
                : UfonIconOffline,
            scaledSize: new google.maps.Size(40, 40),
          }}
          position={{
            lat: ufon.ufon.latitude,
            lng: ufon.ufon.longitude,
          }}
          onClick={() => {
            if (ufon.ufon) {
              setActiveMarker({
                deviceId: ufon.ufon.id,
                name: ufon.ufon.name,
                lat: ufon.ufon.latitude || 0,
                lng: ufon.ufon.longitude || 0,
              });
            }
          }}
        />
        {activeMarker && (
          <JobMapInfoWindow
            onClose={() => {
              setActiveMarker(undefined);
            }}
            onClick={() => navigate(ROUTES.ufon(activeMarker.deviceId))}
            icon="broadcast"
            position={{
              lat: activeMarker.lat,
              lng: activeMarker.lng,
            }}
            title={activeMarker.name}
            pixelOffset={new google.maps.Size(0, 20)}
          />
        )}
      </>
    );
  } else {
    return null;
  }
};

export default UfonMarker;
