import React, { FC } from "react";
import { StyledButton } from "../../components/atoms/Button/Button";
import { LoginScreenLayout } from "../../components/organisms/LoginScreenLayout/LoginScreenLayout";
import { useNavigate, useParams } from "react-router";
import { ROUTES } from "../../routes";
import Title from "../../components/atoms/Title/Title";
import { useMutationPasswordRecovery } from "../../hooks/useMutationPasswordRecovery";
import {
  PasswordRecoveryFieldsT,
  PasswordRecoveryForm,
} from "./PasswordRecoveryForm";
import { WINDOW_TITLE_NAMES } from "../../constants";

export const RecoverPasswordPage: FC = () => {
  document.title = WINDOW_TITLE_NAMES.default;
  const navigate = useNavigate();
  const { token } = useParams() as { token: string };
  const passwordRecoveryMutation = useMutationPasswordRecovery({
    onSuccess: () => {
      navigate(ROUTES.login());
    },
  });

  const handleSubmit = ({ password }: PasswordRecoveryFieldsT) => {
    passwordRecoveryMutation.mutate({ password, token });
  };

  return (
    <LoginScreenLayout>
      <Title type="h1" size="h5">
        Create new password
      </Title>
      <PasswordRecoveryForm onSubmit={handleSubmit} />
      <StyledButton
        disabled={passwordRecoveryMutation.isSuccess}
        isLoading={passwordRecoveryMutation.isLoading}
        title="Save"
        size="lg"
        color="primary"
        icon="check"
        block
        className="mb-3 mt-4"
        form="password-recovery-form"
      />
      <StyledButton
        onClick={() => navigate(ROUTES.login())}
        className="mb-5"
        tag="a"
        block
        color="link"
        size="sm"
        title="Back to login"
      />
    </LoginScreenLayout>
  );
};
