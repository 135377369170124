import { useCallback, useMemo } from "react";
import Dropdown from "../../../../components/molecules/Dropdown/Dropdown";
import { DropdownConfig } from "../../../../types/Common";
import { useDispatch, useSelector } from "../../../../store/hooks";
import { MapLayers, ShowActivityTypesT } from "../../../../types/Route";
import {
  changeActiveRouteLayers,
  changeActiveSegmentsLayers,
  routeLayersInitial,
  setActiveRouteLayers,
  setActiveSegmentLayers,
  setLastSegments,
  setShowActivityByMower,
  setShowActivityType,
  setShowActivityTypes,
  setShowMowingArrows,
  toggleActiveSegmentsLayers,
  toggleShowActivityByMower,
} from "../../../../store/slices/jobRouteTypeSlice";
import { getLastSegments } from "../../utils";
import { JobT, MowersBySegment } from "../../../../types/Job";
import { getHumanReadableText } from "../../../../functions/getHumanReadableText";
import { JOB_ROUTE_TYPE_COLORS } from "../../../../constants";
import {
  getLayersFromCoordinates,
  getSegmentLayersFromCoordinates,
} from "../../../../store/sagas/functions";
import { getActivityTypeLabel } from "../../../../functions/mapLayers";

type Props = {
  job: JobT;
  segments: MowersBySegment[];
  currentUserId?: number;
  mowerIds: number[];
};

const segmentSubItems: MapLayers[] = [
  "activity",
  "route",
  "startPoint",
  "endPoint",
];

const routeSubItems: MapLayers[] = ["route", "startPoint", "endPoint"];

export default function DropdownComponent({
  segments,
  job,
  currentUserId,
  mowerIds,
}: Props) {
  const dispatch = useDispatch();

  const hasSegments = segments.length > 0;

  const {
    activeRouteLayers: checkedRouteItems,
    activeSegmentsLayers: checkedSegmentsItems,
    showActivityByMower,
    lastSegments,
    showMowingArrows,
    routeCoordinates,
    segmentsCoordinates,
    showActivityTypes,
  } = useSelector((store) => store.jobRouteType);

  const resetActiveLayers = () => {
    dispatch(setShowMowingArrows(false));
    dispatch(setShowActivityTypes(true));

    if (routeCoordinates) {
      const newActiveRouteLayers = getLayersFromCoordinates(
        routeCoordinates,
        routeLayersInitial,
        true
      );
      dispatch(setActiveRouteLayers(newActiveRouteLayers));
    }

    if (segmentsCoordinates) {
      const jobSegmentIds = job.segments.users
        .flatMap((user) => user.mowers)
        .map((mower) => mower.segmentId);
      const newSegmentLayers = getSegmentLayersFromCoordinates(
        segmentsCoordinates,
        null,
        jobSegmentIds,
        lastSegments
      );
      dispatch(setActiveSegmentLayers(newSegmentLayers));
    }

    if (showActivityByMower) {
      dispatch(
        setShowActivityByMower(
          (Object.keys(showActivityByMower) as unknown as Array<number>).reduce<
            Record<number, boolean>
          >((obj, key) => {
            obj[key] = true;
            return obj;
          }, {})
        )
      );
    }

    const segmentIdsToShow = getLastSegments(job, currentUserId);
    dispatch(setLastSegments(segmentIdsToShow));
    dispatch(
      changeActiveSegmentsLayers({
        segmentIdsToShow, // all segments for current user
      })
    );
  };

  const handleSegmentLayersChange = useCallback(
    (layer: MapLayers, segmentId?: number) => {
      if (!segmentId) return;
      dispatch(toggleActiveSegmentsLayers({ layer, segmentIds: [segmentId] }));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleLastSegmentsOnly = useCallback(() => {
    if (lastSegments.length > 0) {
      dispatch(setLastSegments([]));
      dispatch(
        changeActiveSegmentsLayers({
          segmentIdsToShow: [], // rewrite all segments from job to checked
        })
      );
    } else {
      const segmentIdsToShow = getLastSegments(job, currentUserId);
      dispatch(setLastSegments(segmentIdsToShow));
      dispatch(
        changeActiveSegmentsLayers({
          segmentIdsToShow, // all segments for current user
        })
      );
    }
  }, [currentUserId, dispatch, job, lastSegments.length]);

  const dropdownConfig = useMemo<DropdownConfig>(() => {
    const config: DropdownConfig = [];

    const allActivitiesShown = Object.values(showActivityTypes).every((x) => x);
    config.push({
      id: "activityType",
      title: "Activity Type",
      selected: allActivitiesShown,
      onSelect: () => {
        dispatch(setShowActivityTypes(!allActivitiesShown));
      },
      subItems: (
        Object.entries(showActivityTypes) as Array<
          [keyof ShowActivityTypesT, boolean]
        >
      ).map(([key, shown]) => {
        return {
          id: `show_${key}`,
          title: getActivityTypeLabel(key),
          selected: shown,
          onSelect: () =>
            dispatch(setShowActivityType({ type: key, show: !shown })),
        };
      }),
    });

    if (segments && checkedSegmentsItems) {
      const isChecked = (item: MapLayers, segmentId: number) => {
        switch (item) {
          case "endPoint":
            return checkedSegmentsItems[segmentId]?.endPointChecked;
          case "startPoint":
            return checkedSegmentsItems[segmentId]?.startPointChecked;
          case "route":
            return checkedSegmentsItems[segmentId]?.routeChecked;
          case "activity":
            return checkedSegmentsItems[segmentId]?.activityChecked;
          default:
            return true;
        }
      };
      segments.forEach((segment) => {
        config.push({
          id: segment.segmentId.toString(),
          title: `${segment.name} / ${segment.segmentName}`,
          color: segment.segmentColor.primaryColor,
          selected: checkedSegmentsItems[segment.segmentId]?.checkedAll,
          onSelect: () => handleSegmentLayersChange("all", segment.segmentId),
          subItems: segmentSubItems.map((item) => {
            return {
              id: item,
              title: getHumanReadableText(item === "route" ? "segment" : item),
              selected: isChecked(item, segment.segmentId),
              onSelect: () =>
                handleSegmentLayersChange(item, segment.segmentId),
            };
          }),
        });
      });
    }

    if (!hasSegments && showActivityByMower) {
      mowerIds.forEach((mower) => {
        const currentMower = job.mowers.find((m) => m.id === mower);
        config.push({
          id: mower.toString(),
          title: `${currentMower?.name}`,
          color:
            currentMower?.color.primaryColor ||
            JOB_ROUTE_TYPE_COLORS.activeDevice,
          selected: showActivityByMower[mower],
          onSelect: () => dispatch(toggleShowActivityByMower(mower)),
        });
      });
    }

    if (checkedRouteItems) {
      const isChecked = (item: MapLayers) => {
        switch (item) {
          case "endPoint":
            return checkedRouteItems.endPointChecked;
          case "startPoint":
            return checkedRouteItems.startPointChecked;
          case "route":
            return checkedRouteItems.routeChecked;
          default:
            return true;
        }
      };
      config.push({
        id: "route_level1",
        title: "Planned route",
        color: JOB_ROUTE_TYPE_COLORS.plannedRoute,
        selected: checkedRouteItems.checkedAll,
        hasSeparator: true,
        onSelect: () => dispatch(changeActiveRouteLayers("all")),
        subItems: routeSubItems.map((item) => {
          return {
            id: item,
            title: getHumanReadableText(item),
            selected: isChecked(item),
            onSelect: () => dispatch(changeActiveRouteLayers(item)),
          };
        }),
      });

      if (hasSegments && checkedRouteItems) {
        config.push({
          id: "lastSegments",
          title: "Show last segments only",
          selected: lastSegments.length > 0,
          onSelect: handleLastSegmentsOnly,
          hasSeparator: true,
          backgroundColor: "#f0f0f0",
        });
      }

      config.push({
        id: "direction",
        title: "Show direction",
        selected: showMowingArrows,
        onSelect: () => {
          dispatch(setShowMowingArrows(!showMowingArrows));
        },
        backgroundColor: "#f0f0f0",
      });
    }

    return config;
  }, [
    checkedRouteItems,
    checkedSegmentsItems,
    dispatch,
    handleLastSegmentsOnly,
    handleSegmentLayersChange,
    hasSegments,
    job.mowers,
    lastSegments.length,
    mowerIds,
    segments,
    showActivityByMower,
    showMowingArrows,
    showActivityTypes,
  ]);

  return <Dropdown config={dropdownConfig} resetLayers={resetActiveLayers} />;
}
