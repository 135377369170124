import { Input } from "reactstrap";
import classNames from "classnames";
import { DatePicker } from "../../../components/molecules/DatePicker/DatePicker";
import { useDeviceScreenWidth } from "../../../hooks/useDeviceScreenWidth";
import { SelectionTypesT } from "../../../types/Notification";
import styles from "./notificationTab.module.scss";

export type DateRangeT = {
  start: Date;
  end: Date;
};

type PropsT = {
  dateRange: DateRangeT;
  selectedOption: SelectionTypesT;
  onDateRangeChange: (dateRange: DateRangeT) => void;
  onSelectionChange: (option: SelectionTypesT) => void;
  className?: string;
};

const selectionTypes: Record<SelectionTypesT, string | null> = {
  all: "All messages",
  jobAssigned: "Job assigned",
  jobCompleted: "Job completed",
  jobStarted: "Job started",
  mowerError: "Mower error",
};

export function NotificationFilter({
  dateRange,
  selectedOption,
  onDateRangeChange,
  onSelectionChange,
  className,
}: PropsT) {
  const isSmallScreen = useDeviceScreenWidth(1054);

  return (
    <div
      className={classNames(
        styles.filter,
        {
          [styles["filter--compact"]]: isSmallScreen,
        },
        className
      )}
    >
      <DatePicker
        className={classNames({ [styles["item--large"]]: !isSmallScreen })}
        startDatetime={dateRange.end}
        endDatetime={dateRange.start}
        onRangeSelected={(start, end) => {
          onDateRangeChange({ start, end });
        }}
      />
      <Input
        type="select"
        value={selectedOption}
        onChange={(e) => onSelectionChange(e.target.value as SelectionTypesT)}
        className={classNames(styles.select, {
          [styles["item--large"]]: !isSmallScreen,
        })}
      >
        {Object.entries(selectionTypes).map(([type, label]) => (
          <option key={type} value={type}>
            {label}
          </option>
        ))}
      </Input>
    </div>
  );
}
