import classNames from "classnames";
import React, { FC } from "react";
import styles from "./cards.module.scss";

type PropsT = {
  className?: string;
};

export const Cards: FC<PropsT> = ({ children, className }) => {
  return <div className={classNames(styles.cards, className)}>{children}</div>;
};
