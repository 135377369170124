import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { QueryClientProvider, QueryClient } from "react-query";
import { toast, ToastContainer } from "react-toastify";
import { firebaseStartup } from "./services/firebase";
import { ApiErrorT } from "./types/Common";
import App from "./App";
import { store } from "./store";
import reportWebVitals from "./reportWebVitals";
import ConfirmBoxProvider from "./components/molecules/ConfirmBoxProvider/ConfirmBoxProvider";
import "./styles/main.scss";
import "react-toastify/dist/ReactToastify.css";

firebaseStartup();

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      onError: (error) => toast.error((error as ApiErrorT).message),
    },
  },
});

ReactDOM.render(
  <Provider store={store}>
    <QueryClientProvider client={queryClient}>
      <ConfirmBoxProvider>
        <App />
      </ConfirmBoxProvider>
    </QueryClientProvider>
    <ToastContainer theme="colored" />
  </Provider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
