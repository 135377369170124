import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { FormCompanyInputsT } from "../components/molecules/FormCompany/FormCompany";
import { client } from "../services/axios";
import { MutationOptionsT } from "../types/ReactQuery";

export const useMutationCompanyUpdate = (
  companyId: number,
  options?: MutationOptionsT
) => {
  return useMutation(
    (data: FormCompanyInputsT) => {
      return client.put(`/api/v1/company/${companyId}`, data);
    },
    {
      onSuccess: () => {
        toast.success("Company has been updated");
        if (options?.onSuccess) {
          options.onSuccess();
        }
      },
      onError: () => {
        toast.error("Updating company failed");
        if (options?.onError) {
          options.onError();
        }
      },
    }
  );
};
