import React, { useMemo } from "react";
import { Card, CardBody, CardHeader } from "reactstrap";
import classNames from "classnames";
import { useMutationCompleteUserSegments } from "../../../hooks/useMutationCompleteUserSegments";
import { useMutationSkipUserSegments } from "../../../hooks/useMutationSkipUserSegments";
import { JobStateProps } from "../types";
import { FooterButtons } from "../../../components/atoms/FooterButtons/FooterButtons";
import { StyledButton } from "../../../components/atoms/Button/Button";
import UfonInfoCard from "../../../components/molecules/UfonInfoCard/UfonInfoCard";
import MowerInfoCard from "../../../components/molecules/MowerInfoCard/MowerInfoCard";
import styles from "./mowerManStatus.module.scss";

const MowerManStatus = ({
  mowerMan,
  setCurrentPage,
  job,
  jobId,
  refetchJob,
  setDetailMower,
  setMowerMan,
  hasSegments,
}: JobStateProps) => {
  const completeSegmentMutation = useMutationCompleteUserSegments();
  const skipSegmentMutation = useMutationSkipUserSegments();

  const reset = () => {
    setDetailMower(null);
    setMowerMan(null);
    setCurrentPage("jobOverview");
  };

  const skipCompleteHandler = async (action: "skip" | "complete") => {
    if (!mowerMan?.id) return;
    if (action === "skip") {
      await skipSegmentMutation.mutateAsync({ jobId, userId: mowerMan.id });
    } else {
      await completeSegmentMutation.mutateAsync({
        jobId,
        userId: mowerMan.id,
      });
    }
    refetchJob();
    reset();
  };

  const ufons = useMemo(
    () =>
      job.users
        .find((user) => user.id === mowerMan?.id)
        ?.mowers.map((mower) => mower.ufon)
        .filter((ufon, index, array) => {
          if (ufon) {
            return (
              index ===
              array.findIndex((foundItem) => foundItem?.id === ufon.id)
            );
          }
        }) as {
        id: number;
        name: string;
      }[],
    [job, mowerMan]
  );

  const getMowerInfoCard = () => {
    if (hasSegments) {
      const mowerManWithMowers = job.segments.users
        .find((user) => user.id === mowerMan?.id)
        ?.mowers.filter((mower) => mower.status === "active");
      if (mowerManWithMowers?.length === 0) {
        return (
          <CardBody>
            <p>{`${mowerMan?.name} does not have any active segments`}</p>
          </CardBody>
        );
      }
      return mowerManWithMowers?.map((mower, index) => {
        return (
          <React.Fragment key={`mower-${index}`}>
            {index !== 0 && <div className={classNames(styles.divider)} />}
            <MowerInfoCard
              mowerId={mower.id}
              mowerName={mower.name}
              showFull={true}
              segment={{
                id: mower.segmentId,
                name: mower.segmentName,
                color: mower.segmentColor,
              }}
              borderColor={mower.segmentColor.primaryColor}
              onClick={() => {
                setDetailMower({
                  id: mower.id,
                  name: mower.name,
                  segment: { id: mower.segmentId, name: mower.segmentName },
                });
                setCurrentPage("mowerStatus");
              }}
            />
          </React.Fragment>
        );
      });
    } else {
      const mowerManWithMowers = job.users.find(
        (user) => user.id === mowerMan?.id
      );
      return mowerManWithMowers?.mowers.map((mower, index) => {
        const currentMower = job.mowers.find((m) => m.id === mower.id);
        return (
          <React.Fragment key={`mower-${index}`}>
            {index !== 0 && <div className={classNames(styles.divider)} />}
            <MowerInfoCard
              mowerId={mower.id}
              mowerName={mower.name}
              showFull={true}
              borderColor={currentMower?.color.primaryColor || "white"}
              onClick={() => {
                setDetailMower({ id: mower.id, name: mower.name });
                setCurrentPage("mowerStatus");
              }}
            />
          </React.Fragment>
        );
      });
    }
  };

  if (!mowerMan) {
    return null;
  }

  return (
    <>
      <Card className={classNames("mb-2")}>
        {ufons.length > 1 && (
          <CardHeader className={styles.header}>
            <span className={styles.operator}>Ufons</span>
          </CardHeader>
        )}
        {ufons.length > 0 &&
          ufons.map((ufon, index) => (
            <React.Fragment key={`${ufon.id}-${mowerMan.id}`}>
              {index !== 0 && <div className={classNames(styles.divider)} />}
              <UfonInfoCard ufonId={ufon.id} />
            </React.Fragment>
          ))}
      </Card>
      <Card className={classNames("mb-2")}>
        <CardHeader className={styles.header}>
          <span className={styles.operator}>Mowers</span>
        </CardHeader>
        {getMowerInfoCard()}
      </Card>
      {hasSegments && (
        <FooterButtons>
          <StyledButton
            title="Skip segments"
            color="link"
            onClick={() => skipCompleteHandler("skip")}
          />
          <StyledButton
            title="Finish segments"
            color="success"
            onClick={() => skipCompleteHandler("complete")}
          />
        </FooterButtons>
      )}
    </>
  );
};

export default MowerManStatus;
