import { ChangeEvent, useEffect, useRef, useState } from "react";
import { Card, CardBody, CardHeader, Col, Input, Row } from "reactstrap";
import { JobStateProps } from "../types";
import { ENVIRONMENT } from "../../../env";
import { useMutationEditJobSegments } from "../../../hooks/useMutationEditJobSegments";
import { useMutationSaveJobSegments } from "../../../hooks/useMutationSaveJobSegments";
import { useQueryJobSegmentsGet } from "../../../hooks/useQueries";
import { getCalculatedLength } from "../utils";
import { Loader } from "../../../components/atoms/Loader/Loader";
import { StyledButton } from "../../../components/atoms/Button/Button";
import { Cards } from "../../../components/atoms/Cards/Cards";
import { Icon } from "../../../components/atoms/Icon";
import { useDispatch } from "react-redux";
import { setLastCreatedSegmentIds } from "../../../store/slices/jobRouteTypeSlice";
import styles from "./jobSegmentList.module.scss";
import {
  formatToMinutes,
  formatToSeconds,
} from "../../../functions/unitConversion";

const SegmentsForm = ({
  jobId,
  setCurrentPage,
  refetchJob,
  isJobRefetching,
}: JobStateProps) => {
  const dispatch = useDispatch();
  const { data: segmentData, isLoading } = useQueryJobSegmentsGet(jobId);
  const calculatedLength = useRef(getCalculatedLength(segmentData?.data));
  const remainingLength = useRef("");
  const defaultValues = {
    criterion: segmentData?.data.inputs.criterion[0].name,
    value: segmentData?.data.inputs.criterion[0].items.value.toString(),
    users: segmentData?.data.users,
  };
  const [formState, setFormState] = useState(defaultValues);
  const timeout = useRef<number | null>(null);
  const segmentsTotal = segmentData?.data.users
    .flatMap((user) => user.mowers)
    .filter((mower) => mower.selected).length;

  const mutation = useMutationEditJobSegments(jobId, {
    onSuccess: (data) => {
      const criterionIndex = formState.criterion
        ? data.inputs.criterion.findIndex(
            (crit) => crit.name === formState.criterion
          )
        : 0;
      const value =
        data.inputs.criterion[criterionIndex].items.value.toString();
      setFormState((prev) => {
        return {
          ...prev,
          value,
          users: data.users,
        };
      });
      calculatedLength.current = getCalculatedLength(data);
      remainingLength.current = `~${data.remainingInfo.time} / ${data.remainingInfo.length}`;
      if (segmentData) segmentData.data = data;
    },
  });

  const saveMutation = useMutationSaveJobSegments(jobId, {
    onSuccess: (data) => {
      refetchJob();
      dispatch(setLastCreatedSegmentIds(data.ids));
      setCurrentPage("segmentsOverview");
    },
  });

  useEffect(() => {
    if (segmentData) {
      const criterionIndex = formState.criterion
        ? segmentData.data.inputs.criterion.findIndex(
            (crit) => crit.name === formState.criterion
          )
        : 0;

      setFormState({
        criterion: formState.criterion
          ? formState.criterion
          : segmentData.data.inputs.criterion[0].name,
        value:
          segmentData.data.inputs.criterion[
            criterionIndex
          ].items.value.toString(),
        users: segmentData.data.users,
      });
      calculatedLength.current = getCalculatedLength(segmentData.data);
      remainingLength.current = `~${segmentData.data.remainingInfo.time} / ${segmentData.data.remainingInfo.length}`;
    }
  }, [segmentData, segmentData?.data.users, formState.criterion]);

  if (isLoading) return <Loader isCentered />;
  if (!segmentData) return <div>No segment data</div>;

  const handleToggleMower = (mowerId: number) => {
    const updatedData = {
      mowers:
        formState?.users?.flatMap((user) =>
          user.mowers.map((mower) => ({
            id: mower.id,
            selected: mowerId === mower.id ? !mower.selected : mower.selected,
          }))
        ) || [],
      criterion: formState.criterion,
      ...(formState.value && { value: parseInt(formState.value) }),
    };
    mutation.mutate(updatedData);
  };

  const handleToggleUser = (userId: number) => {
    const updatedData = {
      mowers:
        formState?.users?.flatMap((user) =>
          user.mowers.map((mower) => ({
            id: mower.id,
            selected:
              userId === user.id
                ? user.mowers.some((m) => m.selected === true)
                  ? false
                  : true
                : mower.selected,
          }))
        ) || [],
      criterion: formState.criterion,
      ...(formState.value && { value: parseInt(formState.value) }),
    };
    mutation.mutate(updatedData);
  };

  const handleInputHandler = (e: ChangeEvent<HTMLInputElement>) => {
    if (timeout.current) {
      clearTimeout(timeout.current);
      timeout.current = null;
    }
    const invalid = isNaN(parseInt(e.target.value));
    if (invalid) {
      setFormState((prev) => ({
        ...prev,
        value: "",
      }));
      return;
    }
    setFormState((prev) => ({
      ...prev,
      value:
        prev.criterion === "Time"
          ? (formatToSeconds(e.target.value, "string") as string)
          : e.target.value,
    }));
    if (!invalid) {
      timeout.current = window.setTimeout(() => {
        const updatedData = {
          mowers:
            formState?.users?.flatMap((user) =>
              user.mowers.map((mower) => ({
                id: mower.id,
                selected: mower.selected,
              }))
            ) || [],
          criterion: formState.criterion,
          value:
            formState.criterion === "Time"
              ? (formatToSeconds(e.target.value, "number") as number)
              : Number(e.target.value),
        };
        mutation.mutate(updatedData);
      }, 1500);
    }
  };

  const remainingLengthHandler = () => {
    const updatedData = {
      mowers:
        formState?.users?.flatMap((user) =>
          user.mowers.map((mower) => ({
            id: mower.id,
            selected: mower.selected,
          }))
        ) || [],
      criterion: formState.criterion,
      value: null,
    };
    mutation.mutate(updatedData);
  };

  const handleSave = async () => {
    if (
      formState.criterion &&
      formState.value &&
      Array.isArray(formState.users)
    ) {
      try {
        await saveMutation.mutateAsync({
          criterion: formState.criterion,
          value: parseInt(formState.value),
          mowers: formState.users.flatMap((user) =>
            user.mowers.map((mower) => ({
              id: mower.id,
              selected: mower.selected,
            }))
          ),
        });
      } catch {}
    }
  };

  const getInputValue = () => {
    if (!formState.value) {
      return "";
    } else {
      if (formState.criterion === "Time") {
        return formatToMinutes(formState.value, "string");
      } else {
        return formState.value;
      }
    }
  };

  if (saveMutation.isLoading || isJobRefetching) {
    return <Loader isCentered />;
  }

  return (
    <>
      <Row>
        <Col md="6" sm="12" lg="12">
          <div className={styles.header}>
            <p className={styles["header-text"]}>Available mower</p>
          </div>
          <Cards>
            {formState.users &&
              formState.users.map((user) => {
                return (
                  <Card className={styles.segment} key={`user-${user.id}`}>
                    <CardHeader className={styles["segment-header"]}>
                      <div className={styles["segment-header-left"]}>
                        <Icon
                          icon="grip-dots"
                          className={styles["segment-header-icon"]}
                        />
                        <p className={styles["segment-header-text"]}>
                          {user.name}
                        </p>
                      </div>
                      <StyledButton
                        kind="iconButton"
                        icon={
                          user.mowers.some((mower) => mower.selected === true)
                            ? "toggle-on"
                            : "toggle-off"
                        }
                        className={styles.toggle}
                        onClick={() => handleToggleUser(user.id)}
                      />
                    </CardHeader>
                    {user.mowers.map((mower) => {
                      return (
                        <CardBody
                          className={styles["segment-item"]}
                          key={`mower-${mower.id}`}
                        >
                          <div className={styles["segment-item-left"]}>
                            <Icon
                              icon="mower"
                              className={styles["segment-item-icon"]}
                            />
                            <p className={styles["segment-item-text"]}>
                              {mower.name}
                            </p>
                          </div>
                          <StyledButton
                            kind="iconButton"
                            icon={mower.selected ? "toggle-on" : "toggle-off"}
                            className={styles.toggle}
                            onClick={() => handleToggleMower(mower.id)}
                          />
                        </CardBody>
                      );
                    })}
                  </Card>
                );
              })}
          </Cards>
        </Col>
        <Col md="6" sm="12" lg="12">
          <div className={styles.header}>
            <p className={styles["header-text"]}>Segment calculation</p>
          </div>
          <Cards>
            <Card>
              <CardBody className={styles.route}>
                <div className={styles["route-info"]}>
                  <p className={styles["route-info-label"]}>
                    Total route Length
                  </p>
                  <p className={styles["route-info-value"]}>
                    {segmentData.data.routeInfo.length}
                  </p>
                </div>
                <div className={styles["route-info"]}>
                  <p className={styles["route-info-label"]}>Total Time</p>
                  <p className={styles["route-info-value"]}>
                    {segmentData.data.routeInfo.time}
                  </p>
                </div>
              </CardBody>
            </Card>
            <Card>
              <CardBody className={styles.form}>
                {segmentsTotal && (
                  <p className={styles["form-count"]}>
                    You will create {segmentsTotal} route segments
                  </p>
                )}
                <label className={styles["form-label"]}>
                  Create segment according
                  <div>
                    <Input
                      type="select"
                      bsSize="sm"
                      className={styles.input}
                      value={formState.criterion}
                      onChange={(e) => {
                        setFormState((prev) => ({
                          ...prev,
                          criterion: e.target.value,
                        }));
                      }}
                    >
                      {segmentData.data.inputs.criterion.map((criterion) => (
                        <option
                          key={`criterion-${criterion.name}`}
                          value={criterion.name}
                        >
                          {criterion.name}
                        </option>
                      ))}
                    </Input>
                  </div>
                </label>
                <label className={styles["form-label"]}>
                  each segment will have
                  <div className="position-relative">
                    <Input
                      type="text"
                      bsSize="sm"
                      className={styles.input}
                      value={getInputValue()}
                      onChange={handleInputHandler}
                    />
                    <span className={styles.unit}>
                      {formState.criterion === "Time" ? "min" : "m"}
                    </span>
                  </div>
                </label>
              </CardBody>
            </Card>
            <Card>
              <CardBody className={styles.route}>
                <div className={styles["route-info"]}>
                  <p className={styles["route-info-label"]}>
                    Calculated segment length
                  </p>
                  {calculatedLength.current && (
                    <p className={styles["route-info-value"]}>
                      {calculatedLength.current}
                    </p>
                  )}
                </div>
                <div className={styles["route-info"]}>
                  <p className={styles["route-info-label"]}>Remaining length</p>
                  <p className={styles["route-info-value"]}>
                    {remainingLength.current}
                  </p>
                </div>
                {ENVIRONMENT != "production" && (
                  <StyledButton
                    title="ADD REMAINING LENGTH"
                    color="link"
                    className={styles.remaining}
                    onClick={remainingLengthHandler}
                  />
                )}
              </CardBody>
            </Card>
          </Cards>
        </Col>
      </Row>
      <div className={styles.footerButtons}>
        <StyledButton
          title="Cancel"
          color="light"
          onClick={() => setCurrentPage("jobOverview")}
          className={styles.formBtn}
        />
        <StyledButton
          title="Create"
          color="primary"
          onClick={handleSave}
          className={styles.formBtn}
        />
      </div>
    </>
  );
};

export default SegmentsForm;
