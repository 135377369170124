import React, { FC, useEffect, useRef, useState } from "react";
import { GoogleMap, GoogleMapProps } from "@react-google-maps/api";
import classNames from "classnames";
import { PRAGUE_LAT_LNG } from "../../constants";
import { useQueryDashboardDataGet } from "../../hooks/useQueries";
import { useCompactDesign } from "../../hooks/useCompactDesign";
import { useUserLocation } from "../../hooks/useUserLocation";
import MapDeviceCluster from "../../components/organisms/Map/MapDeviceCluster";
import { Loader } from "../../components/atoms/Loader/Loader";
import MapControls from "../../components/molecules/MapControls/MapControls";
import UserMapMarker from "../../components/atoms/UserMapMarker/UserMapMarker";
import { getMapBounds } from "./functions";
import DeviceStatusSelector from "./DeviceStatusSelector";
import styles from "./dashboard.module.scss";

const DashboardMap: FC<GoogleMapProps> = ({ center, zoom, ...restProps }) => {
  const mapRef = useRef<GoogleMap>(null);

  const [showActiveDevices, setShowActiveDevices] = useState(true);

  const {
    data: devices,
    isLoading,
    refetch: refetchData,
    isRefetching,
  } = useQueryDashboardDataGet(showActiveDevices, 10, 0);

  const isCompact = useCompactDesign();

  useEffect(() => {
    refetchData();
  }, [showActiveDevices, refetchData]);

  const { userLocation, setMapCenter, mapCenter, handleUserLocation } =
    useUserLocation(center, mapRef);

  const handleHomeLocation = () => {
    if (devices && devices.data.map.some((device) => device.items.length > 0)) {
      const bounds: google.maps.LatLngBounds = getMapBounds(devices.data.map);
      mapRef.current?.state.map?.fitBounds(bounds);
    } else {
      setMapCenter(center || PRAGUE_LAT_LNG);
      mapRef.current?.state.map?.setZoom(8);
    }
  };

  const onLoad = (map: google.maps.Map) => {
    map.setOptions({
      disableDefaultUI: true,
      minZoom: 5,
    });
  };

  useEffect(() => {
    if (devices && devices.data.map.some((device) => device.items.length > 0)) {
      const bounds: google.maps.LatLngBounds = getMapBounds(devices.data.map);
      mapRef.current?.state.map?.fitBounds(bounds);
    }
  }, [devices]);

  return (
    <div
      className={classNames(styles.map, { [styles["map--large"]]: !isCompact })}
    >
      <GoogleMap
        mapContainerStyle={{ height: "100%" }}
        ref={mapRef}
        center={mapCenter}
        zoom={zoom}
        onLoad={onLoad}
        mapTypeId={google.maps.MapTypeId.TERRAIN}
        {...restProps}
      >
        {devices && <MapDeviceCluster devices={devices.data.map} />}
        {userLocation && <UserMapMarker userLocation={userLocation} />}
        <MapControls
          handleHomeLocation={handleHomeLocation}
          handleUserLocation={handleUserLocation}
        />
        <DeviceStatusSelector
          showActiveDevices={showActiveDevices}
          setShowActiveDevices={setShowActiveDevices}
        />
        {(isLoading || isRefetching) && <Loader isCentered />}
      </GoogleMap>
    </div>
  );
};

export default DashboardMap;
