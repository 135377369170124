import { useMutation } from "react-query";
import { useConfirm } from "../components/molecules/ConfirmBoxProvider/ConfirmBoxProvider";
import { client } from "../services/axios";
import { AxiosResponse } from "axios";
import { ApiErrorT, ApiSuccessModal } from "../types/Common";
import { responseWithModal } from "../functions/responseWithModal";
import { MutationOptionsT } from "../types/ReactQuery";
import { toast } from "react-toastify";

export const useMutationGeostickReopenJob = (options?: MutationOptionsT) => {
  const confirm = useConfirm();
  return useMutation(
    (jobId: string | number) => {
      return client.put(`/api/v1/job/${jobId}/reopen`);
    },
    {
      onSuccess: (response: AxiosResponse<ApiSuccessModal>) => {
        responseWithModal({
          response,
          confirm,
          regularSuccess: () => {
            toast.success("Job has been reopened");
            if (options?.onSuccess) {
              options.onSuccess();
            }
          },
          modalSuccess: async () => {
            toast.success("Job has been reopened");
            if (options?.onSuccess) {
              options.onSuccess();
            }
          },
        });
      },
      onError: (error: ApiErrorT) => {
        toast.error(error?.message || "Reopening job failed");
        if (options?.onError) {
          options.onError();
        }
      },
    }
  );
};
