import React from "react";
import { useNavigate } from "react-router";
import { Button, Container } from "reactstrap";
import { Icon } from "../../components/atoms/Icon";
import Title from "../../components/atoms/Title/Title";
import { Layout } from "../../components/organisms/Layout/Layout";
import { ROUTES } from "../../routes";
import styles from "./notFound.module.scss";

type Props = {};

export const NotFound: React.FC<Props> = () => {
  const navigate = useNavigate();
  return (
    <Layout>
      <Container>
        <div className={styles.wrapper}>
          <Title type="h1" size="h1">
            Page not found
          </Title>

          <Button
            className={styles.button}
            onClick={() => navigate(ROUTES.dashboard())}
          >
            <span>
              <Icon
                className={"back-to-dash" && "me-2"}
                icon={"chevron-left"}
              />
            </span>
            Return to Dashboard
          </Button>
        </div>
      </Container>
    </Layout>
  );
};
