import { InfoWindow } from "@react-google-maps/api";
import { FC } from "react";
import { Link } from "react-router-dom";
import { ROUTES } from "../../../routes";
import { MapMarkerT } from "../../../types/Common";
// import { DescriptionItem } from "../../atoms/DescriptionItem/DescriptionItem";
import styles from "./map.module.scss";

type PropsT = {
  activeMarker: MapMarkerT | undefined;
  onClose: () => void;
};

const getDeviceLink = (deviceType: string, deviceId: number) =>
  deviceType === "mower" ? ROUTES.mower(deviceId) : ROUTES.ufon(deviceId);

export const MapInfoWindow: FC<PropsT> = ({ activeMarker, onClose }) => {
  if (!activeMarker) {
    return null;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { latitude, longitude, type, id, lastUser, name, job } = activeMarker;

  return (
    <InfoWindow
      onCloseClick={onClose}
      position={{
        lat: latitude,
        lng: longitude,
      }}
    >
      <div>
        <Link
          className={styles["info-window-link"]}
          to={getDeviceLink(type || "", id)}
        >
          {name}
        </Link>
        {lastUser && (
          <p>
            <strong>{lastUser.name}</strong>
          </p>
        )}
        {/* <DescriptionItem showArrow={Boolean(job)}>
          {job ? (
            <Link to={ROUTES.jobSignpost(job?.id || 0)}>{job.name}</Link>
          ) : (
            "No job assigned"
          )}
        </DescriptionItem> */}
      </div>
    </InfoWindow>
  );
};
