import React, { FC } from "react";
import { useDispatch } from "react-redux";
import { Modal, ModalBody } from "reactstrap";
import { updateTaskAction } from "../../../store/slices/tasksSlice";
import CalculateRouteForm, {
  CalculateRouteInputsT,
} from "../../molecules/CalculateRouteForm/CalculateRouteForm";
import Title from "../Title/Title";

type PropsT = {
  isOpen: boolean;
  jobId: string;
  jobName: string;
  mowerId: number | null;
  onClose: () => void;
};

export const TaskCalculateRouteDialog: FC<PropsT> = ({
  isOpen,
  jobId,
  jobName,
  mowerId,
  onClose,
}) => {
  const dispatch = useDispatch();

  const handleSubmit = (values: CalculateRouteInputsT) => {
    const { title, category, grassHeight } = values;

    dispatch(
      updateTaskAction({
        jobId,
        type: "calculateRoute",
        newStatus: "start",
        options: {
          mowerId: mowerId ?? undefined,
          diameter: Number(grassHeight),
          name: title || jobName,
          folderId: category ? Number(category) : undefined,
        },
      })
    );
    onClose();
  };

  return (
    <Modal isOpen={isOpen} toggle={onClose}>
      <ModalBody>
        <Title type="h3">Calculate route</Title>
        <CalculateRouteForm onSubmit={handleSubmit} onCancel={onClose} />
      </ModalBody>
    </Modal>
  );
};
