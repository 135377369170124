import { DeviceFormInput } from "../types/Common";

export const getMutationDeviceData = (data: DeviceFormInput) => {
  let dataToSend: Partial<DeviceFormInput> = data;
  if (data.purchaseDate.length === 0) {
    const { purchaseDate, ...valuesRest } = data;
    dataToSend = valuesRest;
  }
  return dataToSend;
};
