import { FC, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { Link } from "react-router-dom";
import classNames from "classnames";
import {
  Collapse,
  Input,
  Nav,
  Navbar,
  NavbarBrand,
  NavbarText,
  NavbarToggler,
  NavItem,
  NavLink,
} from "reactstrap";
import { queryClient } from "../../..";
import { usePermissions } from "../../../hooks/usePermissions";
import { useCompactDesign } from "../../../hooks/useCompactDesign";
import { useDispatch, useSelector } from "../../../store/hooks";
import { changeCompanyId } from "../../../store/slices/userSlice";
import { logoutUser } from "../../../functions/OAuth";
import { getCompany, setCompany } from "../../../functions/localStorage";
import { getLinks } from "./functions";
import { ENVIRONMENT } from "../../../env";
import { ROUTES } from "../../../routes";
import { ROLE_IDS } from "../../../constants";
import logoSmall from "../../../assets/images/logo-small.svg";
import { UserMenu } from "../../atoms/UserMenu";
import { CreateNewMenu } from "../../atoms/CreateNewMenu/CreateNewMenu";
import { Icon } from "../../atoms/Icon";
import styles from "./header.module.scss";
import { NotificationBell } from "../NotificationBell/NotificationBell";

type PropsT = {
  title?: string;
};

export const Header: FC<PropsT> = ({ title }: PropsT) => {
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const { user } = useSelector((state) => state.user);
  const selectedCompanyId = getCompany();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const permissions = usePermissions();
  const isDeveloper = user?.role.id === ROLE_IDS.developer;
  const areCompaniesShowing = isDeveloper && selectedCompanyId === 0;
  const areStatisticsShowing = isDeveloper && selectedCompanyId !== 0;
  const isCompact = useCompactDesign();

  const handleToggleOpen = () => {
    setIsOpen((prev) => !prev);
  };

  const links = getLinks(
    selectedCompanyId,
    Boolean(permissions.usersVisibility),
    areCompaniesShowing,
    areStatisticsShowing
  );

  const activeLink =
    links.find((l) => l.pathKeys.includes(location.pathname)) ||
    links
      .slice(1)
      .find((l) => l.pathKeys.some((p) => location.pathname.includes(p)));

  const chooseCompany = (id: number) => {
    queryClient.invalidateQueries();
    setCompany(id);
    dispatch(changeCompanyId({ id }));
    if (user?.role.id === ROLE_IDS.developer) {
      navigate(ROUTES.dashboard());
    }
  };

  const companyOptions = useMemo(() => {
    let companies = user?.companies || [];
    if (isDeveloper) {
      companies = [...companies, { name: "ADMINISTRATION", id: 0, phone: "" }];
    }
    return companies;
  }, [user, isDeveloper]);

  useEffect(() => {
    if (selectedCompanyId) {
      dispatch({ type: "UPDATE_DEVICE_STATUS" });
    }
  }, [selectedCompanyId, dispatch]);

  if (!user) {
    return null;
  }

  return (
    <Navbar
      className={classNames(styles.navbar, {
        [styles["navbar--test"]]: ENVIRONMENT === "test",
        [styles["navbar--dev"]]: ENVIRONMENT === "development",
        [styles["navbar--compact"]]: isCompact,
      })}
      container="fluid"
      expand="lg"
    >
      <NavbarBrand href="/">
        <img
          className={classNames(styles.navbarBrandImage)}
          src={logoSmall}
          alt="Robosheep logo"
        />
      </NavbarBrand>

      {isCompact && (
        <NavbarText className={classNames("me-auto", styles.navbarPath)}>
          {title ? title : activeLink?.title}
        </NavbarText>
      )}

      <Collapse
        navbar
        className={classNames({ [styles.collapse]: isCompact })}
        isOpen={isOpen}
      >
        <Nav navbar className={classNames({ [styles.navMenu]: isCompact })}>
          {links.map((link, index) => {
            return (
              <NavItem key={index}>
                <NavLink
                  className={classNames(styles.navLink, {
                    [styles["navLink--compact"]]: isCompact,
                  })}
                  tag={Link}
                  active={link.link === activeLink?.link}
                  to={link.link}
                  onClick={handleToggleOpen}
                >
                  {link.title}
                </NavLink>
              </NavItem>
            );
          })}
        </Nav>
        {companyOptions.length > 1 && (
          <div
            className={classNames(styles.companySelect, {
              [styles["companySelect--compact"]]: isCompact,
            })}
          >
            {isCompact && (
              <span className={styles.companySelectLabel}>Company</span>
            )}
            <Input
              type="select"
              bsSize="sm"
              value={selectedCompanyId === null ? undefined : selectedCompanyId}
              onChange={(e) => chooseCompany(Number(e.target.value))}
            >
              {companyOptions.map((company) => (
                <option key={`company-${company.id}`} value={company.id}>
                  {company.name}
                </option>
              ))}
            </Input>
          </div>
        )}
        {isCompact && (
          <Nav navbar className={classNames(styles.navSubmenu)}>
            <NavItem>
              <NavLink
                tag={Link}
                to={ROUTES.user(user.id || 0)}
                className={classNames(styles.navLink, {
                  [styles.navLinkWithIcon]: isCompact,
                  [styles["navLink--compact"]]: isCompact,
                })}
              >
                <Icon className={styles.icon} icon="user-circle" />
                User profile
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                href="#"
                onClick={logoutUser}
                className={classNames(styles.navLink, {
                  [styles.navLinkWithIcon]: isCompact,
                  [styles["navLink--compact"]]: isCompact,
                })}
              >
                <Icon className={styles.icon} icon="logout" />
                Logout
              </NavLink>
            </NavItem>
          </Nav>
        )}
      </Collapse>
      <Nav className={classNames("ms-auto", styles.navControl)}>
        <CreateNewMenu />
        {!isCompact && <UserMenu />}
        <NotificationBell />
      </Nav>
      <NavbarToggler
        className={classNames(
          styles.navbarToggler,
          isOpen && styles.navbarTogglerOpen
        )}
        onClick={handleToggleOpen}
      >
        <div className={classNames(styles.navbarTogglerIcon)}>
          <span className={classNames(styles.navbarTogglerIconLine)} />
          <span className={classNames(styles.navbarTogglerIconLine)} />
          <span className={classNames(styles.navbarTogglerIconLine)} />
        </div>
      </NavbarToggler>
    </Navbar>
  );
};
