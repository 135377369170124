import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import React, { FC } from "react";
import { useForm } from "react-hook-form";
import { Form } from "reactstrap";
import { InputController } from "../Form/InputController";
import { formRouteGroupSchema } from "./validations";

export type FormRouteGroupInputsT = {
  name: string;
};

type PropsT = {
  onSubmit: (inputs: FormRouteGroupInputsT) => void;
};

export const FormRouteGroup: FC<PropsT> = ({ onSubmit }) => {
  const getDefaultValues = () => ({
    name: "",
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<FormRouteGroupInputsT>({
    resolver: yupResolver(formRouteGroupSchema),
    defaultValues: getDefaultValues(),
  });

  return (
    <Form onSubmit={handleSubmit(onSubmit)} id="route-group-form">
      <InputController
        name="name"
        label="Name"
        control={control}
        errors={errors}
        input={{
          placeholder: "Group name",
        }}
      />
    </Form>
  );
};
