import React from "react";
import { CardBody, CardHeader } from "reactstrap";
import { useQueryJobHistoryGet } from "../../../hooks/useQueries";
import { formatDate } from "../../../functions/formatDate";
import { DATE_FORMATS } from "../../../constants";
import { JobStateProps } from "../types";
import { Loader } from "../../../components/atoms/Loader/Loader";
import { Icon } from "../../../components/atoms/Icon";
import styles from "./jobHistory.module.scss";

const JobHistory = ({ jobId }: JobStateProps) => {
  const { data: jobHistory, isLoading } = useQueryJobHistoryGet(jobId);

  if (isLoading) {
    return <Loader isCentered={true} />;
  }

  if (!jobHistory) {
    return null;
  }

  return (
    <>
      {jobHistory.data.length === 0 && (
        <h5 className="text-center">This job has no history...</h5>
      )}
      {jobHistory.data.length > 0 && (
        <ul className={styles.list}>
          {jobHistory.data.map((item, index) => (
            <li key={`${item.mower}--${index}`} className={styles["list-item"]}>
              <CardHeader className={styles.header}>
                <div className={styles["header-left"]}>
                  <span>{`${index + 1}.`}</span>
                  <span>{item.segment.name}</span>
                </div>
                <Icon icon="chevron-right" className={styles.chevron} />
              </CardHeader>
              <CardBody className={styles.body}>
                <div className={styles["body-item"]}>
                  <Icon icon="calendar-alt" />
                  <span>
                    {formatDate(item.date, DATE_FORMATS.dateTimeMedium)}
                  </span>
                </div>
                <div className={styles["body-item"]}>
                  <Icon icon="user" />
                  <span>{item.user.name}</span>
                </div>
                <div className={styles["body-item"]}>
                  <Icon icon="mower" />
                  <span>{item.mower.name}</span>
                </div>
              </CardBody>
            </li>
          ))}
        </ul>
      )}
    </>
  );
};

export default JobHistory;
