import { toast } from "react-toastify";
import { FirebaseMessaging } from "@capacitor-firebase/messaging";
import { Capacitor } from "@capacitor/core";
import {
  Geolocation,
  GeolocationPluginPermissions,
} from "@capacitor/geolocation";
import { firebaseTokenRegistration } from "../services/firebase";

export async function requestGeolocationPermission() {
  if (!Capacitor.isNativePlatform()) {
    try {
      await Geolocation.getCurrentPosition();
      return "granted";
    } catch (error) {
      return "denied";
    }
  }
  try {
    const arg: GeolocationPluginPermissions | undefined =
      Capacitor.getPlatform() === "android"
        ? { permissions: ["location"] }
        : undefined;
    const permission = await Geolocation.requestPermissions(arg);
    return permission.location;
  } catch (error) {
    toast.error(`Error requesting geolocation permission`);
    return "denied";
  }
}

export async function geolocationPermissionStatus() {
  return (await Geolocation.checkPermissions()).location;
}

export async function requestNotificationPermission() {
  try {
    const permission = await FirebaseMessaging.requestPermissions();
    await firebaseTokenRegistration();
    return permission.receive;
  } catch (error) {
    toast.error(`Error requesting notification permission`);
    return "denied";
  }
}

export async function notificationPermissionStatus() {
  return (await FirebaseMessaging.checkPermissions()).receive;
}
