import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import React, { FC, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Form } from "reactstrap";
import { CompanyT } from "../../../types/Company";
import { InputController } from "../Form/InputController";
import { formCompanySchema } from "./validations";

export type FormCompanyInputsT = {
  name: string;
  phone: string;
};

type PropsT = {
  initialData?: CompanyT;
  onTouch: () => void;
  onSubmit: (inputs: FormCompanyInputsT) => void;
};

export const FormCompany: FC<PropsT> = ({ initialData, onSubmit, onTouch }) => {
  const getDefaultValues = () => ({
    name: initialData?.name || "",
    phone: initialData?.phone || "",
  });

  const {
    handleSubmit,
    control,
    formState: { errors, isDirty },
  } = useForm<FormCompanyInputsT>({
    resolver: yupResolver(formCompanySchema),
    defaultValues: getDefaultValues(),
  });

  useEffect(() => {
    if (isDirty) {
      onTouch();
    }
  }, [onTouch, isDirty]);

  return (
    <Form onSubmit={handleSubmit(onSubmit)} id="company-form">
      <InputController
        required
        name="name"
        label="Name"
        control={control}
        errors={errors}
        input={{
          placeholder: "Company name",
        }}
      />
      <InputController
        name="phone"
        label="Phone"
        control={control}
        errors={errors}
        input={{
          placeholder: "Phone number",
        }}
      />
    </Form>
  );
};
