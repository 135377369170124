import { JourneyTypes, RouteTypes, ShowActivityTypesT } from "../types/Route";

export const getLayerName = (layerType: JourneyTypes | RouteTypes): string => {
  switch (layerType) {
    case "endPoint":
      return "End point";
    case "startPoint":
      return "Start point";
    case "mowingRoute":
      return "Route";
    case "mowingRoute_5cm":
      return "Mowing route 5cm";
    case "noGPSSignalZone":
      return "No GPS signal zone";
    case "noGpsMowing":
      return "No GPS route";
    case "noGoZone":
      return "No-go zone";
    case "geofence":
      return "Perimeter";
    case "arrow":
      return "Arrow";
    case "autonomous":
      return "Autonomous";
    case "fix_location":
      return "Fix location";
    case "grass_cutting":
      return "Grass cutting";
    case "manual":
      return "Manual";
    case "marker":
      return "Marker";
    case "rtk_location":
      return "RTK location";
    case "without_gps_location":
      return "Without GPS location";
    case "transitRoute":
      return "Transit route";
    default:
      return "Layer";
  }
};

const activityTypesLabels: Record<keyof ShowActivityTypesT, string> = {
  autonomousMowing: "Autonomous - Mowing",
  autonomousTransit: "Autonomous - Transit",
  manualMowing: "Manual - Mowing",
  manualTransit: "Manual - Transit",
};

export const getActivityTypeLabel = (type: keyof ShowActivityTypesT) =>
  activityTypesLabels[type];
