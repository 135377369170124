import React, { FC } from "react";
import { SubmitHandler } from "react-hook-form";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { queryClient } from "../../..";
import { useMutationLeicaGroupCreate } from "../../../hooks/useMutationLeicaGroupCreate";
import {
  FormRouteGroup,
  FormRouteGroupInputsT,
} from "../../molecules/FormRouteGroup/FormRouteGroup";

type PropsT = {
  isOpen: boolean;
  onClose: () => void;
};

export const LeicaGroupCreateModal: FC<PropsT> = ({ isOpen, onClose }) => {
  const createGroupMutation = useMutationLeicaGroupCreate({
    onSuccess: () => {
      onClose();
      queryClient.invalidateQueries(["leicasGroup"]);
    },
  });

  const handleSubmitGroupCreate: SubmitHandler<FormRouteGroupInputsT> = async (
    data: FormRouteGroupInputsT
  ) => {
    createGroupMutation.mutate(data.name);
  };

  return (
    <Modal isOpen={isOpen} toggle={onClose}>
      <ModalHeader toggle={onClose}>
        Add new correction station's point group
      </ModalHeader>
      <ModalBody>
        <FormRouteGroup onSubmit={handleSubmitGroupCreate} />
      </ModalBody>
      <ModalFooter>
        <Button color="light" onClick={onClose}>
          Cancel
        </Button>
        <Button
          color="primary"
          type="submit"
          form="route-group-form"
          className="me-2"
        >
          Create
        </Button>
      </ModalFooter>
    </Modal>
  );
};
