import React from "react";
import { StyledButton } from "../Button/Button";

type PropsT = {
  isArchived: boolean;
  isLoading?: boolean;
  disabled?: boolean;
  archiveTitle?: string;
  restoreTitle?: string;
  onArchive: VoidFunction;
  onRestore: VoidFunction;
  color: string;
  onlyIcon?: boolean;
  outline?: boolean;
  className?: string;
};

export const ArchiveButton = ({
  isArchived,
  isLoading,
  disabled,
  archiveTitle = "Archive",
  restoreTitle = "Unarchive",
  onArchive,
  onRestore,
  color,
  onlyIcon,
  outline,
  className,
}: PropsT) => {
  return (
    <StyledButton
      className={className}
      isLoading={isLoading}
      title={onlyIcon ? undefined : isArchived ? restoreTitle : archiveTitle}
      color={color}
      disabled={disabled}
      onClick={isArchived ? onRestore : onArchive}
      icon={isArchived ? "trash-undo" : "archive"}
      onlyIconOnMobile
      outline={outline}
    />
  );
};
