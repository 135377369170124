import { FC } from "react";
import { useParams } from "react-router";
import classNames from "classnames";
import { WINDOW_TITLE_NAMES } from "../../constants";
import { useCompactDesign } from "../../hooks/useCompactDesign";
import { useQueryLeicaGet } from "../../hooks/useQueries";
import MapLeica from "../../components/organisms/Map/MapLeica";
import { Loader } from "../../components/atoms/Loader/Loader";
import { Layout } from "../../components/organisms/Layout/Layout";
import SubHeader from "../../components/atoms/SubHeader/SubHeader";
import styles from "./Leica.module.scss";

export const LeicaPage: FC = () => {
  document.title = WINDOW_TITLE_NAMES.routes;
  const { leicaId, leicaName } = useParams() as {
    leicaId: string;
    leicaName: string;
  };
  const isCompact = useCompactDesign();

  const { data, isLoading } = useQueryLeicaGet(leicaId);

  return (
    <Layout>
      <SubHeader title={leicaName} isDetail />
      <Loader isLoading={isLoading} isCentered></Loader>
      {data?.data && (
        <>
          <div className="row m-0">
            <div
              className={classNames(
                "col-xl-12 col-12 px-0",
                styles["map-wrapper"],
                isCompact && styles["map-wrapper-compact"]
              )}
            >
              {data.data.coordinates?.readonly?.length > 0 && (
                <MapLeica
                  mapType="satellite"
                  zoom={18}
                  polygonStyle={data.data.styles?.geofence}
                  coordinates={data.data.coordinates}
                  center={{
                    lat: data.data.coordinates.readonly[0].coordinates?.[0]
                      ?.lat,
                    lng: data.data.coordinates.readonly[0].coordinates?.[0]
                      ?.lng,
                  }}
                />
              )}
            </div>
          </div>
        </>
      )}
    </Layout>
  );
};
