import { PERMISSIONS, ROLE_IDS } from "../constants";
import { PermissionsT } from "../types/Common";

export const getUserPermissions = (roleId: number) => {
  const roleName = (Object.keys(ROLE_IDS) as Array<keyof typeof ROLE_IDS>).find(
    (key) => ROLE_IDS[key] === roleId
  );

  return (Object.keys(PERMISSIONS) as Array<keyof typeof PERMISSIONS>).reduce(
    (acc, key) => ({
      ...acc,
      [key]: PERMISSIONS[key].includes(roleName as keyof typeof ROLE_IDS),
    }),
    {}
  ) as PermissionsT;
};
