import React from "react";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";
import styles from "./pagination.module.scss";

type PaginationProp = {
  currentPage: number;
  pagesCount: number;
  handlePreviousClick: () => void;
  handleNextClick: () => void;
};

const LogPagination = ({
  currentPage,
  pagesCount,
  handlePreviousClick,
  handleNextClick,
}: PaginationProp) => {
  return (
    <Pagination className={styles["paginnation-wrapper"]}>
      <PaginationItem disabled={currentPage <= 0}>
        <PaginationLink onClick={handlePreviousClick} previous href="#">
          Prev
        </PaginationLink>
      </PaginationItem>
      <PaginationItem disabled={currentPage >= pagesCount - 1}>
        <PaginationLink onClick={handleNextClick} next href="#">
          Next
        </PaginationLink>
      </PaginationItem>
    </Pagination>
  );
};

export default LogPagination;
