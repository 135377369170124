import { initializeApp } from "firebase/app";
import { MessagePayload } from "firebase/messaging";
import {
  FirebaseMessaging,
  GetTokenOptions,
} from "@capacitor-firebase/messaging";
import { firebaseConfig } from "../env";
import { Capacitor } from "@capacitor/core";
import { notificationPermissionStatus } from "../functions/servicePermissions";
import { register } from "../serviceWorkerRegistration";
import { client } from "./axios";
import { toast } from "react-toastify";

export const initializeFirebase = () => {
  initializeApp(firebaseConfig);
  // Performed if app is in the foreground when notification is received
  // or a few other scenarios (data or silent notifications):
  // https://github.com/capawesome-team/capacitor-firebase/tree/main/packages/messaging#addlistenernotificationreceived
  FirebaseMessaging.addListener("notificationReceived", () => {});
  // Performed when notification is clicked
  FirebaseMessaging.addListener("notificationActionPerformed", () => {});

  if (Capacitor.getPlatform() === "web") {
    navigator.serviceWorker.addEventListener(
      "message",
      (event: MessageEvent<MessagePayload>) => {
        if (!event.data.notification) {
          return;
        }
        const notification = new Notification(
          event.data.notification.title || "Title",
          {
            body: event.data.notification.body,
          }
        );
        notification.onclick = () => {};
      }
    );
  }
};

const registerDevice: (
  token: string
) => Promise<{ success?: boolean; id?: string }> = async (token: string) => {
  const response = await client.post("/api/v1/notification/device", { token });
  return response.data;
};

export const getFirebaseToken = async () => {
  if ((await notificationPermissionStatus()) !== "granted") {
    // const res = await requestPermissions();
    // Do not interrupt the user with a permission request, request only when asked for
    return undefined;
  }
  const options: GetTokenOptions = {
    vapidKey: firebaseConfig.vapidKey,
  };
  if (Capacitor.getPlatform() === "web") {
    options.serviceWorkerRegistration = await navigator.serviceWorker.ready;
  }
  const { token } = await FirebaseMessaging.getToken(options);
  return token;
};

export const firebaseStartup = async () => {
  initializeFirebase();
  if (!Capacitor.isNativePlatform()) {
    register();
  }
};

export const firebaseTokenRegistration = async () => {
  const token = await getFirebaseToken();
  if (token) {
    try {
      await registerDevice(token);
    } catch (error) {
      toast.error(`Error registering device for notifications`);
    }
  }
};

export const firebaseUnregisterDevice = async () => {
  await FirebaseMessaging.deleteToken();
};
