import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import React, { FC, useCallback, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { Card, CardBody, Form } from "reactstrap";
import { InputController } from "../Form/InputController";
import { formLeicaSchema } from "./validations";
import { Cards } from "../../atoms/Cards/Cards";
import { SelectOptionsT } from "../../../types/Common";
import { UTM_ZONES } from "../../../constants";
import { LeicaGroupT } from "../../../types/Leica";
import { UfonT } from "../../../types/Ufon";

export type LeicaInputsT = {
  name: string;
  group: string;
  file: FileList;
  zone: string;
};

type PropsT = {
  onTouch: () => void;
  onSubmit: (v: LeicaInputsT) => void;
  leicaGroups?: LeicaGroupT[];
  defaultValues?: UfonT;
  isDisabledAll?: boolean;
};

export const FormLeica: FC<PropsT> = ({
  onTouch,
  onSubmit,
  leicaGroups,
  defaultValues,
  isDisabledAll,
}) => {
  const selectZoneOptions = useMemo(
    () =>
      (
        [{ value: "", label: "Choose zone", disabled: true }] as SelectOptionsT
      ).concat(
        Object.keys(UTM_ZONES).map((zone) => ({
          label: zone,
          value: zone,
        }))
      ),
    []
  );

  const selectLeicaGroupsOptions = useMemo(
    () =>
      (
        [{ value: "", label: "Choose group", disabled: true }] as SelectOptionsT
      ).concat(
        (leicaGroups || []).map((group) => ({
          label: group.name,
          value: group.id,
        }))
      ),
    [leicaGroups]
  );

  const getDefaultValues = useCallback(
    () => ({
      name: defaultValues?.name || "",
      group: "",
      file: undefined,
    }),
    [defaultValues]
  );

  const {
    handleSubmit,
    reset,
    control,
    formState: { errors, isDirty },
  } = useForm<LeicaInputsT>({
    resolver: yupResolver(formLeicaSchema),
    defaultValues: getDefaultValues(),
  });

  useEffect(() => {
    reset(getDefaultValues());
  }, [getDefaultValues, reset]);

  useEffect(() => {
    if (isDirty) {
      onTouch();
    }
  }, [onTouch, isDirty]);

  return (
    <Form onSubmit={handleSubmit(onSubmit)} id="leica-form">
      <Cards>
        <Card>
          <CardBody>
            <InputController
              name="name"
              label="Name"
              control={control}
              errors={errors}
              input={{ disabled: isDisabledAll }}
            />
            <InputController
              name="group"
              label="Group"
              control={control}
              errors={errors}
              options={selectLeicaGroupsOptions}
              input={{ disabled: isDisabledAll }}
            />
            <InputController
              name="zone"
              label="Zone"
              control={control}
              errors={errors}
              options={selectZoneOptions}
              input={{ disabled: isDisabledAll }}
            />
            <InputController
              name="file"
              label="File (csv)"
              control={control}
              errors={errors}
              input={{ type: "file", disabled: isDisabledAll }}
            />
          </CardBody>
        </Card>
      </Cards>
    </Form>
  );
};
