import React, { FC } from "react";
import classNames from "classnames";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { useCompactDesign } from "../../hooks/useCompactDesign";
import styles from "./dashboard.module.scss";

interface NavProps {
  tabName: string;
  mapComponent: JSX.Element;
  setActiveTab: (tabId: number) => void;
  activeTab: number;
}

const DashboardTabWrapper: FC<NavProps> = ({
  tabName,
  mapComponent,
  children,
  activeTab,
  setActiveTab,
}) => {
  const isCompact = useCompactDesign();

  const navLinkStyle = isCompact
    ? styles["nav-link"]
    : styles["nav-link--large"];
  const navLinkActiveStyle = isCompact
    ? styles["nav-link--active"]
    : styles["nav-link--large--active"];
  return (
    <>
      {isCompact && (
        <Nav tabs className={classNames(styles.nav)}>
          <NavItem className={styles["nav-item--status"]}>
            <NavLink
              onClick={() => setActiveTab(0)}
              className={classNames(navLinkStyle, {
                [navLinkActiveStyle]: activeTab === 0,
              })}
            >
              {tabName}
            </NavLink>
          </NavItem>
          <NavItem className={styles["nav-item--map"]}>
            <NavLink
              onClick={() => setActiveTab(1)}
              className={classNames(navLinkStyle, {
                [navLinkActiveStyle]: activeTab === 1,
              })}
            >
              Map
            </NavLink>
          </NavItem>
        </Nav>
      )}
      <TabContent
        activeTab={activeTab}
        className={classNames(styles["tab-wrapper"], {
          [styles["tab-wrapper--map"]]: activeTab === 1,
        })}
      >
        <TabPane
          tabId={0}
          className={classNames(styles.tab, styles["tab-status"], {
            [styles["tab-status--large"]]: !isCompact,
          })}
          cssModule={styles}
        >
          {children}
        </TabPane>
        <TabPane tabId={1} className={styles["tab-map"]}>
          {mapComponent}
        </TabPane>
      </TabContent>
    </>
  );
};

export default DashboardTabWrapper;
