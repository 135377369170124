import { Card, CardBody } from "reactstrap";
import { StyledButton } from "../../atoms/Button/Button";
import { Icon } from "../../atoms/Icon";
import styles from "./styles.module.scss";

type PropsT = {
  name: string;
  allowed: boolean;
  allow: VoidFunction;
  loading?: boolean;
};

export const Permission = ({ name, allowed, allow, loading }: PropsT) => {
  return (
    <Card>
      <CardBody className="d-flex justify-content-between align-items-center">
        <span>{name}</span>
        <span>
          {allowed ? (
            <Icon icon="check" className={styles.checkmark} />
          ) : (
            <StyledButton
              className="p-0"
              isLoading={loading}
              color="link"
              onClick={allow}
            >
              Allow
            </StyledButton>
          )}
        </span>
      </CardBody>
    </Card>
  );
};
