import React, { FC, useState } from "react";
import { useNavigate } from "react-router";
import { Input, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { queryClient } from "../..";
import { StyledButton } from "../../components/atoms/Button/Button";
import { Loader } from "../../components/atoms/Loader/Loader";
import { useMutationUserDecide } from "../../hooks/useMutationUserDecide";
import { useQueryCompaniesGet } from "../../hooks/useQueries";
import { ROUTES } from "../../routes";
import styles from "./styles.module.scss";

type PropsT = {
  userId: number;
  isOpen: boolean;
  onClose: () => void;
};

const AssignCompanyModal: FC<PropsT> = ({ userId, isOpen, onClose }) => {
  const navigate = useNavigate();
  const [checkedCompanies, setCheckedCompanies] = useState<number[]>([]);
  const { data, isLoading } = useQueryCompaniesGet();
  const userDecideMutation = useMutationUserDecide({
    onSuccess: () => {
      queryClient.invalidateQueries(["pending-users"]);
      queryClient.invalidateQueries(["users"]);
      navigate(ROUTES.users());
      onClose();
    },
  });

  const handleClick = (id: number) => {
    if (checkedCompanies.includes(id)) {
      setCheckedCompanies((prev) => prev.filter((prevId) => prevId !== id));
    } else {
      setCheckedCompanies((prev) => [...prev, id]);
    }
  };

  const handleAssign = () => {
    userDecideMutation.mutate({
      userId,
      approved: true,
      company: checkedCompanies,
    });
  };

  return (
    <Modal isOpen={isOpen} toggle={onClose}>
      <ModalHeader toggle={onClose}>Assign company</ModalHeader>
      <ModalBody>
        <Loader isLoading={isLoading} />
        <ul className={styles["company-list"]}>
          {data?.data.items.map(({ name, id }) => (
            <li
              onClick={() => handleClick(id)}
              key={id}
              className={styles["company-item"]}
            >
              <Input
                id={`check-${id}`}
                type="checkbox"
                readOnly
                className={styles.checkbox}
                checked={checkedCompanies.includes(id)}
              />
              <span>{name}</span>
            </li>
          ))}
        </ul>
      </ModalBody>
      <ModalFooter>
        <StyledButton color="light" onClick={onClose}>
          Cancel
        </StyledButton>
        <StyledButton
          disabled={checkedCompanies.length === 0}
          isLoading={userDecideMutation.isLoading}
          color="primary"
          type="submit"
          form="invite-user-form"
          className="me-2"
          onClick={handleAssign}
        >
          Assign
        </StyledButton>
      </ModalFooter>
    </Modal>
  );
};

export default AssignCompanyModal;
