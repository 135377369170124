import classNames from "classnames";
import React, { FC, MouseEventHandler } from "react";
import { IconsId } from "../../assets/fonts/iconfont/icons";

interface IconProps {
  icon: IconsId;
  className?: string;
  onClick?: MouseEventHandler<HTMLSpanElement>;
  color?: string;
  id?: string;
}

export const Icon: FC<IconProps> = ({
  icon,
  className,
  onClick,
  color,
  id,
}) => (
  <span
    id={id}
    className={classNames("icon", `icon-${icon}`, className)}
    onClick={onClick}
    style={{ color }}
  />
);
