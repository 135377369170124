import React, { FC } from "react";
import classNames from "classnames";
import { NewDiode } from "../NewDiode/NewDiode";
import styles from "./deviceStatusRow.module.scss";
import { LedT } from "../../../types/Common";

interface PropsT {
  label: string;
  status: string;
  led?: LedT;
  hideDiode?: boolean;
  className?: string;
  style?: React.CSSProperties;
}

export const DeviceStatusRow: FC<PropsT> = ({
  label,
  status,
  led,
  hideDiode = false,
  className,
  style,
}) => {
  return (
    <div className={classNames(styles.wrapper, className)} style={style}>
      <span className={classNames(styles.label)}>{label}</span>
      <div className={classNames(styles.status)}>
        {!hideDiode && <NewDiode {...led} border />}
        <span>{status}</span>
      </div>
    </div>
  );
};
