import React, { useEffect } from "react";
import { useNavigate } from "react-router";
import { ROUTES } from "../../routes";
import { useDispatch, useSelector } from "../../store/hooks";
import { updateMowerStatusAction } from "../../store/slices/mowerSlice";
import { useMutationStopMower } from "../../hooks/useMutationStopMower";
import { useMutationMowerPause } from "../../hooks/useMutationMowerPause";
import { useMutationMowerUnpause } from "../../hooks/useMutationMowerUnpause";
import { usePermissions } from "../../hooks/usePermissions";
import { StyledButton } from "../../components/atoms/Button/Button";
import SubHeader from "../../components/atoms/SubHeader/SubHeader";
import { useConfirm } from "../../components/molecules/ConfirmBoxProvider/ConfirmBoxProvider";
import { isMowerAutonomous, isMowerPaused } from "./functions";

type Props = {
  mowerId: number;
  onDelete: () => void;
};

const MowerSubheader: React.FC<Props> = ({ mowerId, onDelete }) => {
  const permissions = usePermissions();
  const stopMowerMutation = useMutationStopMower();
  const confirm = useConfirm();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const isLoading = useSelector((store) => store.mower.isLoading);
  const trackedMower = useSelector((store) => store.mower.mowers[mowerId]);
  const mower = trackedMower.mowerData;

  const handleMowerStop = () => {
    confirm({
      title: "Stop mower?",
      asyncCallback: () => stopMowerMutation.mutateAsync(mower?.id || 0),
      type: "ok",
    });
  };

  useEffect(() => {
    dispatch(updateMowerStatusAction({ mowerId }));
  }, [mowerId, dispatch]);

  const isPaused = isMowerPaused(trackedMower);
  const pauseMutation = useMutationMowerPause(mowerId, {
    onSuccess: () => dispatch(updateMowerStatusAction({ mowerId })),
  });
  const unpauseMutation = useMutationMowerUnpause(mowerId, {
    onSuccess: () => dispatch(updateMowerStatusAction({ mowerId })),
  });
  const handlePause = async () => {
    if (isPaused) {
      unpauseMutation.mutateAsync();
    } else {
      pauseMutation.mutateAsync();
    }
  };

  const pauseIsLoading =
    isLoading || pauseMutation.isLoading || unpauseMutation.isLoading;

  return (
    <SubHeader
      isDetail
      title={mower?.name || "Loading..."}
      status={mower?.isOnline}
      onGoBack={() => navigate(ROUTES.devices())}
      actions={
        <>
          {isPaused !== undefined && mower?.isOnline && (
            <StyledButton
              disabled={pauseIsLoading || !mower || !isMowerAutonomous(mower)}
              onlyIconOnMobile
              onClick={handlePause}
              color="primary"
              icon={isPaused ? "play" : "pause"}
              title={isPaused ? "Unpause autonomy" : "Pause autonomy"}
            />
          )}
          {mower?.btn.totalStop && (
            <StyledButton
              disabled={stopMowerMutation.isLoading}
              onlyIconOnMobile
              onClick={handleMowerStop}
              color="error"
              icon="stop-circle"
              title="Stop device"
            />
          )}
          {permissions.deviceManage ? (
            <>
              <StyledButton
                icon="pen"
                color="dark"
                outline
                link={ROUTES.mowerEdit(mowerId || 0)}
              />
              <StyledButton
                onClick={onDelete}
                icon="trash"
                color="dark"
                outline
              />
            </>
          ) : undefined}
        </>
      }
    />
  );
};

export default MowerSubheader;
