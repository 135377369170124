import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import React, { FC, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Form } from "reactstrap";
import { InputController } from "../../components/molecules/Form/InputController";
import { userRegisterSchema } from "./validations";

export type UserRegisterFieldsT = {
  firstName: string;
  surname: string;
  email: string;
  password: string;
  cpassword: string;
  token?: string;
};

type PropsT = {
  email?: string;
  onSubmit: (inputs: UserRegisterFieldsT) => void;
};

export const UserRegisterForm: FC<PropsT> = ({ email, onSubmit }) => {
  const getDefaultValues = () => ({
    firstName: "",
    surname: "",
    email: email || "",
    password: "",
    cpassword: "",
  });

  const {
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm<UserRegisterFieldsT>({
    resolver: yupResolver(userRegisterSchema),
    defaultValues: getDefaultValues(),
  });

  useEffect(() => {
    if (email) {
      setValue("email", email);
    }
  }, [email, setValue]);

  return (
    <Form onSubmit={handleSubmit(onSubmit)} id="user-register-form" inline>
      <InputController
        name="firstName"
        control={control}
        errors={errors}
        input={{
          placeholder: "First name",
          bsSize: "lg",
        }}
        formGroup={{
          className: "mb-2",
        }}
      />
      <InputController
        name="surname"
        control={control}
        errors={errors}
        input={{
          placeholder: "Surname",
          bsSize: "lg",
        }}
        formGroup={{
          className: "mb-2",
        }}
      />
      <InputController
        name="email"
        control={control}
        errors={errors}
        input={{
          placeholder: "Email",
          bsSize: "lg",
          type: "email",
        }}
        formGroup={{
          className: "mb-4",
        }}
      />
      <InputController
        label="New password"
        name="password"
        control={control}
        errors={errors}
        input={{
          placeholder: "Enter new password",
          bsSize: "lg",
          type: "password",
        }}
        formGroup={{
          className: "mb-2",
        }}
      />
      <InputController
        label="Confirm password"
        name="cpassword"
        control={control}
        errors={errors}
        input={{
          placeholder: "Confirm new password",
          bsSize: "lg",
          type: "password",
        }}
        formGroup={{
          className: "mb-4",
        }}
      />
    </Form>
  );
};
