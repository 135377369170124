import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { JobStateProps } from "../types";
import { useMutationCompleteSingleSegment } from "../../../hooks/useMutationCompleteSingleSegment";
import { useMutationSkipSingleSegment } from "../../../hooks/useMutationSkipSingleSegment";
import { useQueryMowerStatusGet } from "../../../hooks/useQueries";
import { useSelector } from "../../../store/hooks";
import { setMowerStatusAction } from "../../../store/slices/mowerSlice";
import { ENVIRONMENT } from "../../../env";
import { Loader } from "../../../components/atoms/Loader/Loader";
import { Cards } from "../../../components/atoms/Cards/Cards";
import { FooterButtons } from "../../../components/atoms/FooterButtons/FooterButtons";
import { StyledButton } from "../../../components/atoms/Button/Button";
import { JobStatusCard } from "../../../components/molecules/JobStatusCard/JobStatusCard";
import { UfonCard } from "../../../components/molecules/UfonCard/UfonCard";
import { MowerCard } from "../../../components/molecules/MowerCard/MowerCard";
import DefaultUfonCard from "../../../components/molecules/UfonCard/DefaultUfonCard";

const MowerStatus = ({
  job,
  jobId,
  setCurrentPage,
  detailMower,
  mowerMan,
  hasSegments,
  setDetailMower,
  setMowerMan,
  refetchJob,
}: JobStateProps) => {
  const dispatch = useDispatch();
  const mowerId = detailMower?.id as number;
  const segmentId = detailMower?.segment?.id;

  const reset = () => {
    setDetailMower(null);
    setMowerMan(null);
    setCurrentPage("jobOverview");
  };

  const completeSegmentMutation = useMutationCompleteSingleSegment();
  const skipSegmentMutation = useMutationSkipSingleSegment();

  const buttonDisabled =
    hasSegments &&
    job.segments.users
      .find((user) => user.id === mowerMan?.id)
      ?.mowers.filter((mower) => mower.status === "active").length === 0;

  const {
    data: mowerData,
    isLoading,
    error,
  } = useQueryMowerStatusGet(detailMower?.id);

  const mowerStatus = useSelector(
    (store) => store.mower.mowers[mowerId]?.mowerStatus
  );

  const skipCompleteBtnHandler = async (action: "skip" | "complete") => {
    if (!segmentId) return;
    if (action === "skip") {
      await skipSegmentMutation.mutateAsync({ jobId, segmentId });
    } else {
      await completeSegmentMutation.mutateAsync({ jobId, segmentId });
    }
    refetchJob();
    reset();
  };

  useEffect(() => {
    if (mowerData && detailMower?.id) {
      dispatch(
        setMowerStatusAction({
          mowerStatus: mowerData?.data,
          mowerId: detailMower.id,
        })
      );
    }
  }, [mowerData, dispatch, detailMower?.id]);

  const getUfonCard = () => {
    const ufonIdFromJob = job.users
      .find((user) => user.id === mowerMan?.id)
      ?.mowers.find((mower) => mower.id === mowerId)?.ufon?.id;
    if (mowerStatus && mowerStatus.ufon) {
      return <UfonCard ufonId={mowerStatus.ufon.id} />;
    } else if (ufonIdFromJob) {
      return <UfonCard ufonId={ufonIdFromJob} />;
    } else {
      return (
        <DefaultUfonCard
          error={{
            status: "",
            message: "Mower is not connected to any ufon",
          }}
        />
      );
    }
  };

  if (!detailMower) {
    return null;
  }

  if (isLoading) {
    return <Loader isCentered />;
  }

  return (
    <>
      <Cards>
        {ENVIRONMENT !== "production" && (
          <JobStatusCard mowerId={mowerId} segmentId={segmentId} />
        )}
        <MowerCard
          mowerId={detailMower.id}
          jobId={jobId}
          routeName={job.route?.name}
          segment={detailMower.segment}
          error={error}
        />
        {getUfonCard()}
      </Cards>
      {hasSegments && segmentId && (
        <FooterButtons>
          <StyledButton
            title="Skip segment"
            color="link"
            onClick={() => {
              skipCompleteBtnHandler("skip");
            }}
            disabled={buttonDisabled}
          />
          <StyledButton
            title="Finish segment"
            color="success"
            onClick={() => {
              skipCompleteBtnHandler("complete");
            }}
            disabled={buttonDisabled}
          />
        </FooterButtons>
      )}
    </>
  );
};

export default MowerStatus;
