import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { client } from "../services/axios";
import { MutationOptionsT } from "../types/ReactQuery";
import { ApiErrorT } from "../types/Common";

type JobMergeDataT = {
  ids: number[];
  name: string;
  mapping: {
    mowerId: number;
    userId: number;
  }[];
};

export const useMutationMergeJobs = (options?: MutationOptionsT) => {
  return useMutation(
    (mergeData: JobMergeDataT) => client.post("/api/v1/job/merge", mergeData),
    {
      onSuccess: () => {
        toast.success("Jobs have been merged");
        if (options?.onSuccess) {
          options.onSuccess();
        }
      },
      onError: (error: ApiErrorT) => {
        toast.error(error?.message || "Merging jobs failed");
        if (options?.onError) {
          options.onError();
        }
      },
    }
  );
};
