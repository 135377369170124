import { FC } from "react";
import { Marker } from "@react-google-maps/api";
import { JOB_ROUTE_TYPE_COLORS } from "../../../constants";

type PropsT = {
  position: { lat: number; lng: number };
};

const getPointIcon = (color: string) => {
  const encodedColor = color.replace("#", "%23");
  return `data:image/svg+xml;charset=UTF-8,<svg width="500" height="500" viewBox="0 0 500 500" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g transform="scale(20.833333)" fill="${encodedColor}" fill-rule="nonzero">
      <path d="M12 0C12.7969 0 13.5 0.703125 13.5 1.5V2.01562C17.8594 2.67188 21.3281 6.14062 21.9844 10.5H22.5C23.2969 10.5 24 11.2031 24 12C24 12.8438 23.2969 13.5 22.5 13.5H21.9844C21.3281 17.9062 17.8594 21.375 13.5 22.0312V22.5C13.5 23.3438 12.7969 24 12 24C11.1562 24 10.5 23.3438 10.5 22.5V22.0312C6.09375 21.375 2.625 17.9062 1.96875 13.5H1.5C0.65625 13.5 0 12.8438 0 12C0 11.2031 0.65625 10.5 1.5 10.5H1.96875C2.625 6.14062 6.09375 2.67188 10.5 2.01562V1.5C10.5 0.703125 11.1562 0 12 0ZM5.01562 13.5C5.57812 16.2656 7.73438 18.4219 10.5 18.9844V18C10.5 17.2031 11.1562 16.5 12 16.5C12.7969 16.5 13.5 17.2031 13.5 18V18.9844C16.2188 18.4219 18.375 16.2656 18.9375 13.5H18C17.1562 13.5 16.5 12.8438 16.5 12C16.5 11.2031 17.1562 10.5 18 10.5H18.9375C18.375 7.78125 16.2188 5.625 13.5 5.0625V6C13.5 6.84375 12.7969 7.5 12 7.5C11.1562 7.5 10.5 6.84375 10.5 6V5.0625C7.73438 5.625 5.57812 7.78125 5.01562 10.5H6C6.79688 10.5 7.5 11.2031 7.5 12C7.5 12.8438 6.79688 13.5 6 13.5H5.01562ZM12 10.5C12.7969 10.5 13.5 11.2031 13.5 12C13.5 12.8438 12.7969 13.5 12 13.5C11.1562 13.5 10.5 12.8438 10.5 12C10.5 11.2031 11.1562 10.5 12 10.5Z"/>
    </g>
</svg>

`;
};

export const GeostickMarker: FC<PropsT> = ({ position }: PropsT) => {
  return (
    <Marker
      position={position}
      zIndex={300}
      icon={{
        url: getPointIcon(JOB_ROUTE_TYPE_COLORS.activeDevice),
        scaledSize: new google.maps.Size(18, 18),
        anchor: new google.maps.Point(9, 9),
      }}
    />
  );
};
