import React, { FC } from "react";
import { Card, CardHeader } from "reactstrap";
import classNames from "classnames";
import { Color, JobT, MowersBySegment } from "../../../../types/Job";
import { JobState, MowerLocalState, NameAndId } from "../../types";
import { Icon } from "../../../../components/atoms/Icon";
import MowerInfoCard from "../../../../components/molecules/MowerInfoCard/MowerInfoCard";
import styles from "./mowerManCard.module.scss";
import { UserMowerT } from "../../../../types/User";

interface PropsT {
  mowersFromSegments?: MowersBySegment[];
  mowerInactive?: {
    id: number;
    name: string;
    segmentName?: string;
    segmentId?: number;
    segmentColor?: {
      primaryColor: string;
      secondaryColor: string;
    };
    status?: "finished" | "active";
  }[];
  mowersByUser?: (UserMowerT & { color: Color })[];
  operator: { name: string; id: number };
  job: JobT;
  setCurrentPage?: (state: JobState) => void;
  setMowerMan?: (user: NameAndId | null) => void;
  setDetailMower?: (mower: MowerLocalState | null) => void;
}

export const MowerManCard: FC<PropsT> = ({
  mowersFromSegments,
  mowerInactive,
  mowersByUser,
  operator,
  setCurrentPage,
  setMowerMan,
  setDetailMower,
  job,
}) => {
  const clickableCard =
    setMowerMan && setDetailMower && setCurrentPage && job.startedAt;
  const onMowerClick = (selectedMower: MowerLocalState) => {
    if (!clickableCard) return;
    setMowerMan(operator);
    setDetailMower(selectedMower);
    setCurrentPage("mowerStatus");
  };

  const onMowerManClick = () => {
    if (!clickableCard) return;
    setMowerMan(operator);
    setCurrentPage("mowerManStatus");
  };
  return (
    <Card className="mb-2">
      <CardHeader
        className={styles.header}
        tag={clickableCard ? "button" : "div"}
        onClick={clickableCard ? onMowerManClick : undefined}
      >
        <span className={styles.operator}>{operator.name}</span>
        {clickableCard && (
          <Icon icon="chevron-right" className={styles.chevron} />
        )}
      </CardHeader>
      {mowersFromSegments &&
        mowersFromSegments.map((mower, index) => {
          return (
            <React.Fragment key={`mower-${index}`}>
              {index !== 0 && <div className={classNames(styles.divider)} />}
              <MowerInfoCard
                mowerId={mower.id}
                mowerName={mower.name}
                segment={{
                  id: mower.segmentId,
                  name: mower.segmentName,
                  color: mower.segmentColor,
                }}
                borderColor={mower.segmentColor.primaryColor}
                onClick={
                  clickableCard
                    ? () =>
                        onMowerClick({
                          id: mower.id,
                          name: mower.name,
                          segment: {
                            id: mower.segmentId,
                            name: mower.segmentName,
                          },
                        })
                    : undefined
                }
              />
            </React.Fragment>
          );
        })}
      {mowerInactive &&
        mowerInactive.map((mower, index) => {
          const segment =
            mower.segmentId && mower.segmentName && mower.segmentColor
              ? {
                  id: mower.segmentId,
                  name: mower.segmentName,
                  color: mower.segmentColor,
                }
              : undefined;
          return (
            <React.Fragment key={`mower-${index}`}>
              {index !== 0 && <div className={classNames(styles.divider)} />}
              <MowerInfoCard
                mowerId={mower.id}
                mowerName={mower.name}
                segment={segment}
                borderColor={segment?.color.primaryColor}
              />
            </React.Fragment>
          );
        })}
      {mowersByUser &&
        mowersByUser.map((mower, index) => (
          <React.Fragment key={`mower-${index}`}>
            {index !== 0 && <div className={classNames(styles.divider)} />}
            <MowerInfoCard
              mowerId={mower.id}
              mowerName={mower.name}
              borderColor={mower.color.primaryColor}
            />
          </React.Fragment>
        ))}
    </Card>
  );
};
