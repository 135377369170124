import { useMemo } from "react";
import { CoordinatesT } from "../../../types/Route";
import {
  RoutePageLayers,
  RoutePageLayersType,
  getRoutePageDropdownItems,
} from "./functions";
import { JOB_ROUTE_TYPE_COLORS } from "../../../constants";
import { DropdownItemConfig, DropdownConfig } from "../../../types/Common";
import Dropdown from "../../molecules/Dropdown/Dropdown";

type Props = {
  activeRouteLayers: RoutePageLayers;
  showMowingArrows: boolean;
  setShowMowingArrows: () => void;
  coordinates: CoordinatesT<"route">;
  handleRouteLayersChange: (id: number[], type: RoutePageLayersType) => void;
  handleAllLayersByType: (id: number[], type: RoutePageLayersType) => void;
  resetActiveLayers: () => void;
};

export default function DropdownComponent({
  showMowingArrows,
  setShowMowingArrows,
  activeRouteLayers,
  coordinates,
  handleRouteLayersChange,
  handleAllLayersByType,
  resetActiveLayers,
}: Props) {
  const dropdownConfig = useMemo<DropdownConfig>(() => {
    const geofenceItems = activeRouteLayers.geofence
      ? getRoutePageDropdownItems("geofence", coordinates)
      : [];
    const noGoZoneItems = activeRouteLayers.noGoZone
      ? getRoutePageDropdownItems("noGoZone", coordinates)
      : [];
    const routeItems = activeRouteLayers.route
      ? getRoutePageDropdownItems("route", coordinates)
      : [];

    const config: DropdownItemConfig[] = [];

    if (activeRouteLayers.geofence) {
      config.push({
        id: "geofence",
        title: "Geofence",
        selected: activeRouteLayers.geofence.length !== 0,
        onSelect: () =>
          handleRouteLayersChange(geofenceItems[0].ids, "geofence"),
        color: geofenceItems[0]?.color,
      });
    }

    if (activeRouteLayers.noGoZone) {
      config.push({
        id: "noGoZone",
        title: "No go zone",
        selected: activeRouteLayers.noGoZone.length !== 0,
        onSelect: () =>
          handleRouteLayersChange(noGoZoneItems[0].ids, "noGoZone"),
        color: noGoZoneItems[0]?.color,
      });
    }

    if (activeRouteLayers.route) {
      const isSubItemChecked = (ids: number[]) => {
        let isChecked = false;
        for (const id of ids) {
          if (activeRouteLayers.route?.includes(id)) {
            isChecked = true;
            break;
          }
        }
        return isChecked;
      };
      config.push({
        id: "route",
        title: "Route",
        selected: activeRouteLayers.route.length !== 0,
        onSelect: () =>
          handleAllLayersByType(
            routeItems.flatMap((id) => id.ids),
            "route"
          ),
        color: JOB_ROUTE_TYPE_COLORS.plannedRoute,
        subItems: routeItems.map((item) => {
          return {
            id: item.uniqueId,
            title: item.title === "Route" ? "Mowing route" : item.title,
            selected: isSubItemChecked(item.ids),
            onSelect: () => handleRouteLayersChange(item.ids, "route"),
          };
        }),
      });
    }
    config.push({
      id: "direction",
      title: "Show direction",
      selected: showMowingArrows,
      onSelect: setShowMowingArrows,
      hasSeparator: true,
    });

    return config;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeRouteLayers, showMowingArrows, coordinates]);

  return <Dropdown config={dropdownConfig} resetLayers={resetActiveLayers} />;
}
