import React, { FC } from "react";
import Title from "../../components/atoms/Title/Title";
import { StyledButton } from "../../components/atoms/Button/Button";
import { LoginScreenLayout } from "../../components/organisms/LoginScreenLayout/LoginScreenLayout";
import { FormLostPassword, FormLostPasswordFieldsT } from "./FormLostPassword";
import { useMutationLostPassword } from "../../hooks/useMutationLostPassword";
import { useNavigate } from "react-router";
import { ROUTES } from "../../routes";
import { WINDOW_TITLE_NAMES } from "../../constants";

export const LostPasswordPage: FC = () => {
  document.title = WINDOW_TITLE_NAMES.default;
  const navigate = useNavigate();
  const lostPasswordMutation = useMutationLostPassword({
    onSuccess: () => {
      navigate(ROUTES.login());
    },
  });

  const handleSubmit = (field: FormLostPasswordFieldsT) => {
    lostPasswordMutation.mutate(field);
  };

  return (
    <LoginScreenLayout>
      <Title type="h1" size="h5">
        Enter recovery email:
      </Title>
      <FormLostPassword
        isDisabled={lostPasswordMutation.isSuccess}
        onSubmit={handleSubmit}
      />
      <StyledButton
        disabled={lostPasswordMutation.isSuccess}
        isLoading={lostPasswordMutation.isLoading}
        title="Send"
        size="lg"
        color="primary"
        icon="check"
        block
        className="mb-3 mt-4"
        form="lost-password-form"
      />
      <StyledButton
        onClick={() => navigate(ROUTES.login())}
        className="mb-5"
        tag="a"
        block
        color="link"
        size="sm"
        title="Back to login"
      />
    </LoginScreenLayout>
  );
};
