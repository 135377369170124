import { useMutation } from "react-query";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { ROUTES } from "../routes";
import { client } from "../services/axios";
import { ApiErrorT, DeviceFormInput } from "../types/Common";
import { getMutationDeviceData } from "../functions/getMutationDeviceData";

export const useMutationEditMower = (mowerId: string | number) => {
  const navigate = useNavigate();

  return useMutation(
    (data: DeviceFormInput) => {
      const dataToSend = getMutationDeviceData(data);
      return client.put(`/api/v1/mower/${mowerId}`, {
        ...dataToSend,
        companyId: Number(data.companyId),
      });
    },
    {
      onSuccess: () => {
        navigate(ROUTES.devices());
        toast.success("Mower has been updated");
      },
      onError: (error: ApiErrorT) => {
        toast.error(error?.message);
      },
    }
  );
};
