import { FC } from "react";
import classNames from "classnames";
import { GeostickJobT } from "../../types/Job";
import JobCard from "../../components/molecules/JobCard/JobCard";
import { FooterButtons } from "../../components/atoms/FooterButtons/FooterButtons";
import { StyledButton } from "../../components/atoms/Button/Button";
import { JobProgressStatusT } from "../../components/molecules/JobSubheader/JobSubheader";
import styles from "./geostick.module.scss";

type PropsT = {
  job: GeostickJobT | undefined;
  status: JobProgressStatusT;
  continueFromOverview: VoidFunction;
  className?: string;
};

export const GeostickOverview: FC<PropsT> = ({
  job,
  status,
  continueFromOverview,
  className,
}: PropsT) => {
  return (
    <div className={classNames(className, styles.overview)}>
      {job && <JobCard job={job} />}
      <FooterButtons>
        {(status === "started" || status === "completed") && (
          <StyledButton
            color="primary"
            title="Job progress"
            onClick={continueFromOverview}
          />
        )}
      </FooterButtons>
    </div>
  );
};
