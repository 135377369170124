import { FC, useEffect, useState } from "react";
import classNames from "classnames";
import { Col, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { getActiveTab } from "../../../pages/JobRouteType/utils";
import { useCompactDesign } from "../../../hooks/useCompactDesign";
import { useSearchParams } from "../../../hooks/useSearchParam";
import { Icon } from "../../../components/atoms/Icon";
import styles from "./tabsWithMapLayout.module.scss";

interface NavProps {
  tabName: string;
  mapComponent: JSX.Element;
  logComponent?: JSX.Element;
  onlyMobileTabs?: boolean;
  noPadding?: boolean;
}

const TabsWithMapLayout: FC<NavProps> = ({
  tabName,
  mapComponent,
  logComponent,
  children,
  onlyMobileTabs = false,
  noPadding = false,
}) => {
  const { updateSearchParams, getParamValue } = useSearchParams();

  const isCompact = useCompactDesign();

  const tab = getParamValue("tab");

  const [activeTab, setActiveTab] = useState(() => getActiveTab(tab));

  useEffect(() => {
    updateSearchParams((prev) => {
      return {
        ...prev,
        tab: activeTab.toString(),
      };
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);

  useEffect(() => {
    if (tab !== null && tab !== activeTab.toString()) {
      setActiveTab(getActiveTab(tab));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab]);

  useEffect(() => {
    if (!isCompact && activeTab === 1) {
      setActiveTab(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCompact]);

  return (
    <>
      <div
        className={classNames(styles.row, !isCompact && styles["row--large"])}
      >
        {!isCompact && <Col className="d-none d-lg-block">{mapComponent}</Col>}
        <div
          className={classNames(
            styles.sidebar,
            !isCompact && styles["sidebar--large"]
          )}
        >
          <div
            className={classNames(
              styles["sidebar-wrapper"],
              !isCompact && styles["sidebar-wrapper--large"],
              noPadding && styles["sidebar-wrapper-no-padding"]
            )}
          >
            <Nav
              tabs
              className={classNames(styles.nav, {
                [styles["nav--large"]]: !isCompact,
                [styles["nav-no-tabs-on-desktop"]]:
                  !Boolean(logComponent) && !isCompact,
                [styles["nav-only-mobile-tabs"]]: onlyMobileTabs,
                [styles["nav-padding"]]:
                  Boolean(logComponent) && !isCompact && noPadding,
              })}
            >
              <NavItem className={styles["nav-item--status"]}>
                <NavLink
                  onClick={() => setActiveTab(0)}
                  className={classNames({
                    [styles["nav-link"]]: isCompact,
                    [styles["nav-link--large"]]: !isCompact,
                    [styles["nav-link--active"]]: activeTab === 0,
                    [styles["nav-link--active--large"]]:
                      !isCompact && activeTab === 0,
                  })}
                >
                  {tabName}
                </NavLink>
              </NavItem>
              {isCompact && (
                <NavItem className={styles["nav-item--map"]}>
                  <NavLink
                    onClick={() => setActiveTab(1)}
                    className={classNames({
                      [styles["nav-link"]]: isCompact,
                      [styles["nav-link--large"]]: !isCompact,
                      [styles["nav-link--active"]]: activeTab === 1,
                      [styles["nav-link--active--large"]]:
                        !isCompact && activeTab === 1,
                    })}
                  >
                    Map
                  </NavLink>
                </NavItem>
              )}
              {Boolean(logComponent) && (
                <NavItem
                  className={classNames(styles["nav-item--log"], {
                    [styles["nav-item--log--large"]]: !isCompact,
                  })}
                >
                  <NavLink
                    onClick={() => setActiveTab(2)}
                    className={classNames({
                      [styles["nav-link"]]: isCompact,
                      [styles["nav-link--large"]]: !isCompact,
                      [styles["nav-link--active"]]: activeTab === 2,
                      [styles["nav-link--active--large"]]:
                        !isCompact && activeTab === 2,
                    })}
                  >
                    {isCompact && (
                      <Icon
                        icon="list-timeline"
                        className={styles["nav-item--log-icon"]}
                      />
                    )}
                    {!isCompact && <span>Log</span>}
                  </NavLink>
                </NavItem>
              )}
            </Nav>
            <TabContent
              activeTab={activeTab}
              className={classNames(styles.tab, {
                [styles["tab--large"]]: !isCompact,
                [styles["tab-no-tabs-on-desktop--large"]]:
                  !Boolean(logComponent) && !isCompact,
                [styles["tab-no-padding"]]: noPadding,
              })}
            >
              {/* STATUS PANE */}
              <TabPane
                tabId={0}
                className={classNames(styles.tab, styles["tab-status"], {
                  [styles["tab--large"]]: !isCompact,
                  [styles["tab-no-padding"]]: noPadding,
                })}
                cssModule={styles}
              >
                {children}
              </TabPane>
              {/* MAP PANE */}
              {isCompact && (
                <TabPane tabId={1} className={styles["tab-map"]}>
                  {mapComponent}
                </TabPane>
              )}
              {/* LOG PANE */}
              {Boolean(logComponent) && (
                <TabPane
                  tabId={2}
                  className={classNames(styles.tab, styles["tab-log"], {
                    [styles["tab--large"]]: !isCompact,
                  })}
                  cssModule={styles}
                >
                  {logComponent}
                </TabPane>
              )}
            </TabContent>
          </div>
        </div>
      </div>
    </>
  );
};

export default TabsWithMapLayout;
