import { Link } from "react-router-dom";
import { Card, CardBody, CardHeader } from "reactstrap";
import classNames from "classnames";
import { JOB_STATUSES } from "../../constants";
import { usePermissions } from "../../hooks/usePermissions";
import { useCompactDesign } from "../../hooks/useCompactDesign";
import { formatDate } from "../../functions/formatDate";
import { getJobLink } from "../../functions/routing";
import { JobListItemT } from "../../types/Job";
import { Icon } from "../../components/atoms/Icon";
import { Status } from "../../components/atoms/Status/Status";
import JobIcon from "../../components/atoms/JobIcon/JobIcon";
import styles from "./dashboard.module.scss";

type Props = {
  job: JobListItemT;
  showJobOwnerName?: boolean;
};

const JobItem = ({ job, showJobOwnerName = false }: Props) => {
  const permissions = usePermissions();
  const isCompact = useCompactDesign();
  const getJobDetailLink = () =>
    getJobLink(
      job.type.name,
      job.id,
      job.users.map((user) => user.id),
      permissions
    );

  return (
    <Card className={styles.jobCard}>
      <CardHeader className={styles["jobCard-header"]}>
        <Link to={getJobDetailLink()} className={styles.superLink} />
        <span>{job.name}</span>
        <Icon icon="angle-right" className={styles.chevron} />
      </CardHeader>
      <CardBody className={styles["jobCard-body"]}>
        <div>
          <Status job={job} />
        </div>
        <div
          className={classNames(
            job.status.name === JOB_STATUSES.Overdue && "text-error"
          )}
        >
          <Icon className="me-2" icon="calendar-alt" />
          {formatDate(job.dueDate)}
        </div>
        <div
          className={
            isCompact
              ? styles["jobCard-item--jobType--compact"]
              : styles["jobCard-item--jobType"]
          }
        >
          {<JobIcon jobName={job.type.name} />}
        </div>
        {showJobOwnerName && (
          <div>
            <Icon className="me-2" icon="user" />
            {`${job.users[0].name} ${
              job.users.length > 1 ? `(+${job.users.length - 1})` : ""
            }`}
          </div>
        )}
      </CardBody>
    </Card>
  );
};

export default JobItem;
