import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import React, { FC, useCallback, useEffect, useMemo } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { Card, CardBody, Col, Form, Row } from "reactstrap";
import { InputController } from "../Form/InputController";
import { getFormUserSchema } from "./validations";
import { Cards } from "../../atoms/Cards/Cards";
import { UserCurrentT, UserT } from "../../../types/User";
import { StyledButton } from "../../atoms/Button/Button";
import { SelectOptionsT } from "../../../types/Common";
import { Loader } from "../../atoms/Loader/Loader";
import { CompanyT } from "../../../types/Company";
import { useQueryRolesGet } from "../../../hooks/useQueries";
import { ROLE_IDS, USER_ROLES } from "../../../constants";
import { getCompany } from "../../../functions/localStorage";
import { usePermissions } from "../../../hooks/usePermissions";
import { useSelector } from "../../../store/hooks";

export type UserInputsT = {
  firstName: string;
  surname: string;
  email: string;
  phone: string;
  role: number;
  company: {
    companyId: string;
  }[];
  password?: string;
};

type PropsT = {
  defaultUserData?: UserT;
  onSubmit: (v: UserInputsT) => void;
  onTouch: () => void;
  isDisabledAll?: boolean;
  isEditingMode?: boolean;
  companies: CompanyT[] | null;
};

export const FormUser: FC<PropsT> = ({
  onSubmit,
  onTouch,
  defaultUserData,
  isDisabledAll,
  isEditingMode,
  companies,
}) => {
  const { data: rolesData, isLoading: isLoadingRoles } = useQueryRolesGet();
  const permissions = usePermissions();
  const userRoles = rolesData ? rolesData.data.items : USER_ROLES;
  const currentCompany = getCompany();
  const radiosRoleOptions = useMemo(
    () =>
      userRoles.reduce<{ label: string; value: number }[]>((acc, next) => {
        if (next.name === "Developer" && !permissions.createDeveloper) {
          return acc;
        }
        acc.push({
          label: next.name,
          value: next.id,
        });
        return acc;
      }, []),
    [userRoles, permissions]
  );

  const changePasswordPermission = (
    currentUser: UserCurrentT | null,
    targetUser?: UserT
  ) => {
    if (!currentUser || !targetUser) {
      return false;
    }

    const currentUserCompanies = currentUser.companies.map((c) => c.id);
    const targetUserCompanies = new Set(targetUser.companies.map((c) => c.id));
    const intersection = currentUserCompanies.filter((id) =>
      targetUserCompanies.has(id)
    );
    const isManagerOfUser =
      currentUser.role.id === ROLE_IDS.manager &&
      intersection.length > 0 &&
      targetUser.role.id !== ROLE_IDS.developer;
    const isCurrentUser = currentUser.id === targetUser.id;
    const isDeveloper = currentUser.role.id === ROLE_IDS.developer;

    return isManagerOfUser || isCurrentUser || isDeveloper;
  };

  const { user: currentUser } = useSelector((state) => state.user);
  const canChangePassword =
    !isEditingMode || changePasswordPermission(currentUser, defaultUserData);

  const getDefaultValues = useCallback((): UserInputsT => {
    const firstName = defaultUserData?.firstName || "";
    const surname = defaultUserData?.surname || "";
    const email = defaultUserData?.email || "";
    const phone = defaultUserData?.phone || "";
    const role =
      defaultUserData?.role?.id || rolesData?.data.items?.[0]?.id || 3;
    const company = defaultUserData?.companies
      ? defaultUserData.companies.map((userCompany) => {
          return {
            companyId: `${userCompany.id || ""}`,
          };
        })
      : [{ companyId: `${currentCompany}` || "" }];
    const password = "";

    return {
      firstName,
      surname,
      email,
      phone,
      role,
      company,
      password,
    };
  }, [defaultUserData, rolesData, currentCompany]);

  const {
    handleSubmit,
    control,
    formState: { errors, isDirty },
    reset,
  } = useForm<UserInputsT>({
    resolver: yupResolver(getFormUserSchema(!!isEditingMode)),
    defaultValues: getDefaultValues(),
  });

  useEffect(() => {
    if (isDirty) {
      onTouch();
    }
  }, [isDirty, onTouch]);

  const {
    fields: companyFields,
    append: companyAppend,
    remove: companyRemove,
  } = useFieldArray({
    control,
    name: "company",
  });

  useEffect(() => {
    reset(getDefaultValues());
  }, [getDefaultValues, reset]);

  const handleAddCompany = () => {
    companyAppend({});
  };

  const handleRemoveCompany = (index: number) => {
    companyRemove(index);
  };

  const selectCompanyOptions = useMemo(
    () =>
      (
        [
          { value: "", label: "Choose company", disabled: true },
        ] as SelectOptionsT
      ).concat(
        (companies || []).map((company) => ({
          label: company.name,
          value: company.id,
        }))
      ),

    [companies]
  );

  return (
    <Form onSubmit={handleSubmit(onSubmit)} id="user-form">
      <Cards>
        <Loader isLoading={isDisabledAll} isOverlay />
        <Card>
          <CardBody>
            <Row>
              <Col lg="6">
                <InputController
                  name="firstName"
                  label="First Name"
                  control={control}
                  errors={errors}
                  input={{ disabled: isDisabledAll }}
                />
              </Col>
            </Row>
            <Row>
              <Col lg="6">
                <InputController
                  name="surname"
                  label="Last Name"
                  control={control}
                  errors={errors}
                  input={{ disabled: isDisabledAll }}
                />
              </Col>
            </Row>
            <Row>
              <Col lg="6">
                <InputController
                  name="email"
                  label="E-mail"
                  control={control}
                  errors={errors}
                  input={{ disabled: isDisabledAll }}
                />
              </Col>
            </Row>
            <Row>
              <Col lg="6">
                <InputController
                  name="phone"
                  label="Phone"
                  control={control}
                  errors={errors}
                  input={{ disabled: isDisabledAll }}
                />
              </Col>
            </Row>
          </CardBody>
        </Card>
        <Card>
          <CardBody>
            <Row>
              <Col lg="6">
                <InputController
                  name="role"
                  label={"Role"}
                  control={control}
                  errors={errors}
                  options={radiosRoleOptions}
                  input={{ disabled: isDisabledAll || isLoadingRoles }}
                />
              </Col>
            </Row>
          </CardBody>
        </Card>
        {companies?.length !== 1 && (
          <Card>
            <CardBody>
              <Row>
                <Col lg="6">
                  {companyFields.map((companyField, index) => (
                    <div
                      key={companyField.id}
                      className="d-flex align-items-end gap-2"
                    >
                      <InputController
                        key={`company-company-${index}`}
                        name={`company.${index}.companyId`}
                        label={`Company #${index + 1}`}
                        control={control}
                        errors={errors}
                        options={selectCompanyOptions}
                        input={{
                          disabled: isDisabledAll,
                        }}
                      />
                      <>
                        <StyledButton
                          icon="minus-circle"
                          className="ms-2 mt-4 mb-3"
                          color="dark"
                          outline
                          onClick={() => handleRemoveCompany(index)}
                          disabled={companyFields.length < 2}
                          input={{ disabled: isDisabledAll }}
                        />
                        {index === companyFields.length - 1 && (
                          <StyledButton
                            className="ms-2 mt-4 mb-3"
                            icon="plus-circle"
                            onClick={handleAddCompany}
                            color="dark"
                            outline
                          />
                        )}
                      </>
                    </div>
                  ))}
                </Col>
              </Row>
            </CardBody>
          </Card>
        )}

        {canChangePassword && (
          <Card>
            <CardBody>
              <Row>
                <Col lg="6">
                  <span>Password</span>
                  <div className="pb-2">
                    <i>
                      {isEditingMode
                        ? "Optional. If left non empty, the user's password changes to the value of this field"
                        : "Optional. Leaving this field blank allows user to create his own password"}
                    </i>
                  </div>
                  <InputController
                    name="password"
                    control={control}
                    errors={errors}
                    input={{
                      type: "password",
                      disabled: isDisabledAll,
                    }}
                  />
                </Col>
              </Row>
            </CardBody>
          </Card>
        )}
      </Cards>
    </Form>
  );
};
