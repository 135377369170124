import { useState, useEffect } from "react";
import { Capacitor } from "@capacitor/core";
import { Geolocation } from "@capacitor/geolocation";
import { LocationT } from "../types/Common";
import { geolocationPermissionStatus } from "../functions/servicePermissions";

// Get the user's location
// as opposed to `useUserLocation` this watches the location, not just gets it once when `handleUserLocation` is called
export const useDeviceLocation = () => {
  const [position, setPosition] = useState<LocationT | null>(null);
  const [error, setError] = useState<string | null>(null);

  const onChange = ({ coords }: { coords: LocationT }) => {
    setPosition({
      latitude: coords.latitude,
      longitude: coords.longitude,
    });
  };
  useEffect(() => {
    async function getLocation() {
      const permissionStatus = await geolocationPermissionStatus();
      if (
        (Capacitor.isNativePlatform() && permissionStatus !== "granted") ||
        (!Capacitor.isNativePlatform() && permissionStatus === "denied")
      ) {
        setError("Geolocation not enabled");
        return;
      }
      const watcher = await Geolocation.watchPosition(
        { enableHighAccuracy: true, maximumAge: Infinity },
        (pos) => {
          if (pos) {
            onChange(pos);
          } else {
            setError("Geolocation not found");
          }
        }
      );
      return () => Geolocation.clearWatch({ id: watcher });
    }
    getLocation();
  }, []);
  return { location: position, error };
};
