enum BaseNotificationTypes {
  jobAssigned = "jobAssigned",
  jobCompleted = "jobCompleted",
  jobStarted = "jobStarted",
  mowerError = "mowerError",
}

export const NotificationTypes = { ...BaseNotificationTypes, test: "test" };
export type NotificationTypeT = keyof typeof NotificationTypes;

export const SelectionTypes = { ...BaseNotificationTypes, all: "all" };
export type SelectionTypesT = keyof typeof SelectionTypes;

export type NotificationT = {
  id: number;
  userId: number;
  jobId: number;
  mowerId: number;
  ufonId: number;
  notificationType: NotificationTypeT;
  subject: string;
  content: string;
  sentAt: Date;
  readAt: Date;
  createdAt: Date;
  updatedAt: Date;
};

export type NotificationListT = {
  items: NotificationT[];
  totalCount: number;
};

export type ListedTypeT = "all" | "unread";

export type NotificationListQueryT = {
  userId?: string;
  jobId?: string;
  mowerId?: string;
  ufonId?: string;
  notificationType?: NotificationTypeT;
  isRead?: boolean;
  createdAtFrom?: Date;
  createdAtTo?: Date;
};
