import React from "react";
import classNames from "classnames";
import { useGoogleMap } from "@react-google-maps/api";
import { Icon } from "../Icon";
import styles from "./mapTypeControls.module.scss";

type Props = {
  mapTypeId: google.maps.MapTypeId;
  setMapTypeId: (type: google.maps.MapTypeId) => void;
  onlyIcons?: boolean;
};

const MapTypeTabs = ({ mapTypeId, setMapTypeId, onlyIcons = false }: Props) => {
  const map = useGoogleMap();
  if (!map) return null;

  return (
    <div
      className={classNames(styles["tab-selector__wrapper"], {
        [styles["tab-selector__wrapper--onlyIcon"]]: onlyIcons,
      })}
    >
      <div
        className={classNames(styles["tab-selector__tab"], {
          [styles["tab-selector__tab--onlyIcon"]]: onlyIcons,
          [styles["tab-selector__tab--active"]]:
            mapTypeId === google.maps.MapTypeId.SATELLITE,
        })}
        onClick={() => {
          map.setMapTypeId(google.maps.MapTypeId.SATELLITE);
          setMapTypeId(google.maps.MapTypeId.SATELLITE);
        }}
      >
        {!onlyIcons && (
          <span className={styles["tab-selector__tab-label"]}>Satellite</span>
        )}
        <Icon
          icon="satellite"
          className={classNames(styles["tab-selector__tab-icon"], {
            [styles["tab-selector__tab-icon--onlyIcon"]]: onlyIcons,
          })}
        />
      </div>
      <div
        className={classNames(styles["tab-selector__tab"], {
          [styles["tab-selector__tab--onlyIcon"]]: onlyIcons,
          [styles["tab-selector__tab--active"]]:
            mapTypeId === google.maps.MapTypeId.TERRAIN,
        })}
        onClick={() => {
          map.setMapTypeId(google.maps.MapTypeId.TERRAIN);
          setMapTypeId(google.maps.MapTypeId.TERRAIN);
        }}
      >
        {!onlyIcons && (
          <span className={styles["tab-selector__tab-label"]}>Terrain</span>
        )}
        <Icon
          icon="terrain"
          className={classNames(styles["tab-selector__tab-icon"], {
            [styles["tab-selector__tab-icon--onlyIcon"]]: onlyIcons,
          })}
        />
      </div>
    </div>
  );
};

export default MapTypeTabs;
