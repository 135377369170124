import React, { FC, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Container } from "reactstrap";
import { StyledButton } from "../../components/atoms/Button/Button";
import { PageWrapper } from "../../components/atoms/PageWrapper/PageWrapper";
import SubHeader from "../../components/atoms/SubHeader/SubHeader";
import {
  FormUser,
  UserInputsT,
} from "../../components/molecules/FormUser/FormUser";
import { Layout } from "../../components/organisms/Layout/Layout";
import { WINDOW_TITLE_NAMES } from "../../constants";
import { useMutationUserCreate } from "../../hooks/useMutationUserCreate";
import { useQueryUserGet, useQueryCompaniesGet } from "../../hooks/useQueries";

type PropsT = {};

export const UserCreatePage: FC<PropsT> = () => {
  document.title = WINDOW_TITLE_NAMES.users;
  const [isFormTouched, setIsFormTouched] = useState(false);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const userId = searchParams.get("userId");
  const { data: userData, isLoading } = useQueryUserGet(userId);

  const createUserMutation = useMutationUserCreate();
  const { data: companies } = useQueryCompaniesGet();

  const handleSubmit = (values: UserInputsT) => {
    if (values.password) {
      createUserMutation.mutate(values);
    } else {
      createUserMutation.mutate({ ...values, password: undefined });
    }
  };

  return (
    <Layout>
      <SubHeader
        isDetail
        title="New user"
        actions={
          <>
            <StyledButton
              onClick={() => navigate(-1)}
              color="dark"
              outline
              title="Cancel"
            />
            <StyledButton
              disabled={!isFormTouched}
              color="primary"
              type="submit"
              form="user-form"
              isLoading={createUserMutation.isLoading}
            >
              {!createUserMutation.isLoading ? "Create" : "Saving"}
            </StyledButton>
          </>
        }
      />
      <PageWrapper>
        <Container>
          <FormUser
            onSubmit={handleSubmit}
            onTouch={() => setIsFormTouched(true)}
            companies={companies?.data.items || []}
            isDisabledAll={isLoading}
            defaultUserData={userData?.data}
          />
        </Container>
      </PageWrapper>
    </Layout>
  );
};
