import React, { FC, useState } from "react";
import { Modal, ModalBody } from "reactstrap";
import classNames from "classnames";
import { StyledButton } from "../Button/Button";
import { Icon } from "../Icon";
import styles from "./deviceMessage.module.scss";
interface PropsT {
  text: string;
  additionalMessage?: string;
  status?: "error" | "warning" | "success";
  help?: string;
}

export const DeviceMessage: FC<PropsT> = ({
  text,
  additionalMessage,
  status = "off",
  help,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!open);
  };

  return (
    <>
      <div
        className={classNames(styles.wrapper, styles[status], {
          [styles.cursor]: help,
        })}
      >
        <div className={styles["text-wrapper"]}>
          <p className={classNames(styles.text, styles["text--main"])}>
            {text}
          </p>
          {additionalMessage && (
            <p className={classNames(styles.text, styles["text--additional"])}>
              {additionalMessage}
            </p>
          )}
        </div>
        {help && (
          <Icon
            className={styles.chevron}
            icon="chevron-right"
            onClick={() => setIsOpen(true)}
          />
        )}
      </div>
      <Modal
        isOpen={isOpen}
        toggle={toggle}
        contentClassName={classNames(styles[status])}
      >
        <ModalBody>
          <div className="d-flex justify-content-end">
            <StyledButton icon="times" onClick={toggle} />
          </div>
          <p className={classNames("text-uppercase", styles.text)}>{help}</p>
        </ModalBody>
      </Modal>
    </>
  );
};
