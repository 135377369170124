import { FC } from "react";
import {
  Bar,
  BarChart,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  CartesianGrid,
  Legend,
} from "recharts";
import { DateT } from "../../types/Statistics";
import { splitPascalCase } from "../../functions/getHumanReadableText";
import { useCompactDesign } from "../../hooks/useCompactDesign";
import { formatHours } from "./functions";
import styles from "./statistics.module.scss";

type PropsT = {
  data: DateT[];
};

export const StatisticsGraph: FC<PropsT> = ({ data }: PropsT) => {
  const isCompact = useCompactDesign();
  const blackTextFormatter = (value: string) => {
    return <span style={{ color: "#353535" }}>{splitPascalCase(value)}</span>;
  };

  return (
    <ResponsiveContainer className={styles.graph} height={450}>
      <BarChart
        data={data}
        margin={{ top: 5, bottom: 5, right: 5, left: isCompact ? -10 : 5 }}
        barGap={isCompact ? 1 : 4}
      >
        <XAxis dataKey="date" interval="preserveStartEnd" />
        <YAxis
          domain={[0, "auto"]}
          tickFormatter={(value: number) =>
            formatHours(value, { singular: "h", plural: "h" })
          }
        />
        <Tooltip
          formatter={(value: number, name: string) => [
            formatHours(value),
            splitPascalCase(name),
          ]}
        />
        <CartesianGrid
          strokeDasharray="1 3"
          stroke="#999999"
          vertical={false}
        />
        <Bar dataKey="autonomousMowing" fill="#CCC918" />
        <Bar dataKey="manualMowing" fill="#E5E39C" />
        <Bar dataKey="autonomousTransit" fill="#1FA9FF" />
        <Bar dataKey="manualTransit" fill="#9CD9FE" />
        <Legend verticalAlign="bottom" formatter={blackTextFormatter} />
      </BarChart>
    </ResponsiveContainer>
  );
};
