import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { client } from "../services/axios";
import { MutationOptionsT } from "../types/ReactQuery";

export const useMutationLeicaGroupDelete = (options?: MutationOptionsT) => {
  return useMutation(
    (groupId: number | string) => {
      return client.delete(`/api/v1/leica-folder/${groupId}`);
    },
    {
      onSuccess: () => {
        toast.success("Correction station's point has been deleted");
        if (options?.onSuccess) {
          options.onSuccess();
        }
      },
      onError: () => {
        toast.error("Deleting correction station's point group failed");
        if (options?.onError) {
          options.onError();
        }
      },
    }
  );
};
