import React, { FC } from "react";
import { Loader } from "../../atoms/Loader/Loader";
import Title from "../../atoms/Title/Title";
import styles from "./styles.module.scss";

type PropsT = {
  isLoading?: boolean;
};

const MapOverlay: FC<PropsT> = ({ isLoading = false }) => {
  if (isLoading) {
    return <Loader isOverlay />;
  }

  return (
    <div className={styles["map-overlay"]}>
      <Title className="opacity-50" type="h4">
        No GPS Coordinates
      </Title>
    </div>
  );
};

export default MapOverlay;
