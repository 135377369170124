import createSagaMiddleware from "redux-saga";
import { configureStore } from "@reduxjs/toolkit";
import { userSlice } from "./slices/userSlice";
import { mowerSlice } from "./slices/mowerSlice";
import { tasksSlice } from "./slices/tasksSlice";
import { rootSaga } from "./sagas/rootSaga";
import { ufonSlice } from "./slices/ufonSlice";
import { webSocketSlice } from "./slices/webSocketSlice";
import { jobRouteTypeSlice } from "./slices/jobRouteTypeSlice";
import { geostickSlice } from "./slices/geostickSlice";

const sagaMiddleware = createSagaMiddleware();

export const store = configureStore({
  reducer: {
    user: userSlice.reducer,
    mower: mowerSlice.reducer,
    tasks: tasksSlice.reducer,
    ufon: ufonSlice.reducer,
    webSocket: webSocketSlice.reducer,
    jobRouteType: jobRouteTypeSlice.reducer,
    geostick: geostickSlice.reducer,
  },
  middleware: [sagaMiddleware],
});

sagaMiddleware.run(rootSaga);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
