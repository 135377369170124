import React from "react";
import { Card, CardBody, Table } from "reactstrap";
import { formatDate } from "../../../functions/formatDate";
import { LogT } from "../../../types/Log";
import styles from "./log.module.scss";
import Title from "../../atoms/Title/Title";
import { LoadMoreButton } from "../../atoms/LoadMoreButton/LoadMoreButton";
import classNames from "classnames";

type Props = {
  logs: LogT[];
};

export const Log: React.FC<Props> = ({ logs }) => {
  return (
    <div>
      <Title type={"h2"} size={"h4"}>
        Log
      </Title>
      <Card>
        <CardBody>
          <Table>
            <thead className={styles["log-thead"]}>
              <tr>
                <th>Datetime</th>
                <th>Message</th>
              </tr>
            </thead>
            <tbody>
              {logs.map((log) => {
                return (
                  <tr key={log.id} className={styles["log-tr"]}>
                    <td
                      className={classNames(
                        styles["log-td"],
                        styles["log-td--date"]
                      )}
                    >
                      {formatDate(log.date, "eee LLL LL y H:mm:s OOOO")}
                    </td>
                    <td className={styles["log-td"]}>{log.content}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          <LoadMoreButton
            onClick={() => {
              return;
            }}
          />
        </CardBody>
      </Card>
    </div>
  );
};
