import React, { useState } from "react";
import { useParams, useNavigate } from "react-router";
import { StyledButton } from "../../components/atoms/Button/Button";
import SubHeader from "../../components/atoms/SubHeader/SubHeader";
import { Log } from "../../components/molecules/Log/Log";
import { UserCard } from "../../components/molecules/UserCard/UserCard";
import { Layout } from "../../components/organisms/Layout/Layout";
import { ROUTES } from "../../routes";
import { useQueryUserGet } from "../../hooks/useQueries";
import { UserT } from "../../types/User";
import { Loader } from "../../components/atoms/Loader/Loader";
import { WINDOW_TITLE_NAMES } from "../../constants";
import { useMutationUserDecide } from "../../hooks/useMutationUserDecide";
import { queryClient } from "../..";
import AssignCompanyModal from "./AssignCompanyModal";
import { useConfirm } from "../../components/molecules/ConfirmBoxProvider/ConfirmBoxProvider";

type Props = {};

export const PendingUserDetailPage: React.FC<Props> = () => {
  document.title = WINDOW_TITLE_NAMES.users;
  const navigate = useNavigate();
  const { userId } = useParams() as { userId: string };
  const confirm = useConfirm();
  const { data, isLoading } = useQueryUserGet(userId);
  const [isAssignCompanyModalShowing, setIsAssignCompanyModalShowing] =
    useState(false);

  const userDecideMutation = useMutationUserDecide({
    onSuccess: () => {
      queryClient.invalidateQueries(["pending-users"]);
      navigate(ROUTES.users());
    },
  });

  const handleReject = () => {
    confirm({
      title: "Reject user",
      subTitle: `Are you sure you want to reject this user, ${
        data?.data.firstName + " " + data?.data.surname
      }?`,
      asyncCallback: () =>
        userDecideMutation.mutateAsync({
          userId: data?.data.id || 0,
          approved: false,
        }),
      type: "delete",
    });
  };

  return (
    <Layout>
      <SubHeader
        title={
          data ? `${data.data.firstName} ${data.data.surname}` : "Loading..."
        }
        isDetail
        actions={
          <>
            <StyledButton
              onClick={handleReject}
              icon="times"
              color="dark"
              outline
            />
            <StyledButton
              onClick={() => setIsAssignCompanyModalShowing(true)}
              icon="check"
              color="dark"
              outline
            />
          </>
        }
      />
      {isLoading && <Loader isCentered />}
      {data?.data && (
        <>
          <div className={"p-4 d-flex flex-column gap-3"}>
            <UserCard user={data.data as UserT} />
            <Log logs={data.data.logs} />
          </div>
        </>
      )}
      <AssignCompanyModal
        userId={Number(userId)}
        isOpen={isAssignCompanyModalShowing}
        onClose={() => setIsAssignCompanyModalShowing(false)}
      />
    </Layout>
  );
};
