import React, { FC, useEffect, useMemo, useState } from "react";
import { queryClient } from "../..";
import { LoadMoreButton } from "../../components/atoms/LoadMoreButton/LoadMoreButton";
import { DataGrid } from "../../components/molecules/DataGrid/DataGrid";
import { Layout } from "../../components/organisms/Layout/Layout";
import MenuWithLayout, {
  MenuWithLayoutItemT,
} from "../../components/organisms/MenuWithLayout/MenuWithLayout";
import { RouteT } from "../../types/Route";
import SubHeader from "../../components/atoms/SubHeader/SubHeader";
import {
  useQueryLeicaGroupListGet,
  useQueryLeicaListInfiniteGet,
} from "../../hooks/useQueries";
import { DropdownButton } from "../../components/atoms/DropdownButton/DropdownButton";
import { usePermissions } from "../../hooks/usePermissions";
import { Link } from "react-router-dom";
import { ROUTES } from "../../routes";
import { useDeviceLocation } from "../../hooks/useDeviceLocation";
import { useMutationLeicaGroupDelete } from "../../hooks/useMutationLeicaGroupDelete";
import { useMutationLeicaDelete } from "../../hooks/useMutationLeicaDelete";
import { LeicaGroupCreateModal } from "../../components/organisms/LeicaGroupCreateModal/LeicaGroupCreateModal";
import { LeicaGroupT } from "../../types/Leica";
import { WINDOW_TITLE_NAMES } from "../../constants";
import { useSearchParams } from "../../hooks/useSearchParam";
import { useConfirm } from "../../components/molecules/ConfirmBoxProvider/ConfirmBoxProvider";

export const LeicasPage: FC = () => {
  document.title = WINDOW_TITLE_NAMES.routes;
  const permissions = usePermissions();
  // const navigate = useNavigate();
  const { location } = useDeviceLocation();
  const [shownData, setShownData] = useState<RouteT[]>([]);
  const [currentPage, setCurrentPage] = useState(0);
  const { getParamValue, updateSearchParams } = useSearchParams();
  const showNearest = getParamValue("showNearest");
  const search = getParamValue("search");
  const folderId = getParamValue("folderId");
  const confirm = useConfirm();

  const [isCreateGroupModalOpen, setIsCreateGroupModalOpen] = useState(false);

  const toggleCreateGroupModal = () => {
    setIsCreateGroupModalOpen((prev) => !prev);
  };

  const deleteGroupMutation = useMutationLeicaGroupDelete({
    onSuccess: () => {
      queryClient.invalidateQueries(["leicasGroup"]);
    },
  });

  const deleteLeicaMutation = useMutationLeicaDelete({
    onSuccess: () => {
      queryClient.invalidateQueries(["leicas"]);
    },
  });

  const handleDeleteGroup = (group: LeicaGroupT) => {
    confirm({
      title: "Remove group",
      subTitle: `Are you sure you want to delete route group ${group.name}?`,
      asyncCallback: () => deleteGroupMutation.mutateAsync(group.id),
      type: "delete",
    });
  };

  const handleDeleteLeica = (leica: RouteT) => {
    confirm({
      title: "Remove leica",
      subTitle: `Are you sure you want to delete leica name ${leica.name}?`,
      asyncCallback: () => deleteLeicaMutation.mutateAsync(leica.id),
      type: "delete",
    });
  };

  const onSearch = (searchString: string) => {
    updateSearchParams((prev) => ({ ...prev, search: searchString }));
    queryClient.invalidateQueries(["leicas", { type: searchString }]);
  };

  const { isLoading: groupIsLoading, data: groupData } =
    useQueryLeicaGroupListGet();

  const {
    isLoading: leicasIsLoading,
    isFetchingNextPage,
    data: leicas,
    fetchNextPage,
  } = useQueryLeicaListInfiniteGet(
    search || "",
    Number(folderId) || 0,
    showNearest ? location : null,
    showNearest ? "nearest" : null
  );

  const setPageAndFetchNextPage = () => {
    setCurrentPage(currentPage + 1);
    fetchNextPage({ pageParam: currentPage + 1 });
  };

  const handleShowNearest = () => {
    updateSearchParams((prev) => ({
      ...prev,
      showNearest: "true",
      folderId: null,
    }));
  };

  const handleAllLeicas = () => {
    updateSearchParams((prev) => ({
      ...prev,
      showNearest: null,
      search: null,
      folderId: null,
    }));
  };

  const handleFolderSelect = (groupId: number) => {
    updateSearchParams((prev) => ({
      ...prev,
      folderId: `${groupId}`,
      showNearest: null,
    }));
  };

  const data = useMemo(
    () => (leicas?.pages || []).map((page) => page.data.items),
    [leicas]
  );

  const totalCount = leicas?.pages[0].data.totalCount || 0;
  const groups = (groupData?.data.items || []).map((group) => ({
    ...group,
    title: group.name,
    onClick: () => handleFolderSelect(group.id),
    onDelete: () => handleDeleteGroup(group),
  }));

  const isLoading = groupIsLoading || leicasIsLoading;

  useEffect(() => {
    setShownData(data.flat());
  }, [data]);

  // const switchers = [
  //   {
  //     id: 0,
  //     title: "Routes",
  //     onClick: () => navigate(ROUTES.routes()),
  //   },
  //   {
  //     id: 1,
  //     title: "Ufon's points",
  //     active: true,
  //   },
  // ];

  const actions = [
    {
      label: "Route - upload .kml",
      tag: Link,
      to: ROUTES.routeCreate(),
    },
    // {
    //   label: "Ufon - correction point",
    //   tag: Link,
    //   to: ROUTES.leicaCreate(),
    // },
    {
      label: "Add group",
      onClick: toggleCreateGroupModal,
    },
  ];

  return (
    <Layout whiteBackground>
      <SubHeader
        title="Correction station's points"
        titleDropdown="My routes"
        search={{
          placeholder: "Search for route title",
          onChange: onSearch,
        }}
        items={[
          {
            id: 0,
            title: "All correction station's points",
            onClick: () => handleAllLeicas(),
            hasSeparator: true,
          } as MenuWithLayoutItemT,
        ].concat(groups)}
        addNewTitle={"Add group"}
        addNew={toggleCreateGroupModal}
        activeItem={Number(folderId)}
        // switchers={switchers}
        actions={
          permissions.routeManage ? (
            <DropdownButton
              actions={actions}
              toggleProps={{ icon: "plus-circle", onlyIconOnMobile: true }}
              menuProps={{ end: true }}
            >
              Create new
            </DropdownButton>
          ) : undefined
        }
      />

      <MenuWithLayout
        items={[
          {
            id: "nearest",
            title: "Nearest",
            onClick: () => handleShowNearest(),
            hasSeparator: true,
          } as MenuWithLayoutItemT,
          {
            id: 0,
            title: "All routes",
            onClick: () => handleAllLeicas(),
            hasSeparator: true,
          } as MenuWithLayoutItemT,
        ].concat(groups)}
        activeItem={showNearest ? "nearest" : Number(folderId)}
        // switchers={switchers}
        addNewButton={
          permissions.routeManage
            ? {
                title: "Add group",
                onClick: toggleCreateGroupModal,
              }
            : undefined
        }
      >
        <DataGrid
          headers={["Route title", "ID"]}
          rowComponent={"RouteGridRow"}
          data={shownData}
          isLoading={isLoading}
          onDelete={handleDeleteLeica}
          isLeica={true}
        />
        {totalCount > shownData.length && (
          <LoadMoreButton
            isLoading={isFetchingNextPage}
            onClick={() => setPageAndFetchNextPage()}
          />
        )}
      </MenuWithLayout>

      <LeicaGroupCreateModal
        isOpen={isCreateGroupModalOpen}
        onClose={toggleCreateGroupModal}
      />
    </Layout>
  );
};
