import { FC, useState } from "react";
import { FormGroup, Input } from "reactstrap";
import classNames from "classnames";
import { JOB_ROUTE_TYPE_COLORS } from "../../../constants";
import { formatDate } from "../../../functions/formatDate";
import { JobListItemT } from "../../../types/Job";
import styles from "./CardRouteLayers.module.scss";

export const JobItem: FC<{
  job: JobListItemT;
  onJourneySelect: (journeyIds: number[]) => void;
}> = ({ job, onJourneySelect }) => {
  const className = `d-flex align-items-center gap-3`;
  const color = JOB_ROUTE_TYPE_COLORS.activeDevice;
  const { id, name, users, mowers, dueDate, journeys } = job;
  const [selectItem, setSelectItem] = useState(false);

  return (
    <div className={className} key={id}>
      <FormGroup check>
        <Input
          checked={selectItem}
          type="checkbox"
          onChange={() => {
            setSelectItem((prev) => !prev);
            onJourneySelect(journeys);
          }}
        />
      </FormGroup>
      {selectItem ? (
        <div
          style={{
            backgroundColor: color,
          }}
          className={styles.circle}
        />
      ) : (
        <div style={{ border: "1px solid gray" }} className={styles.circle} />
      )}
      <div>
        <div className={classNames(styles["fs-7"], "fw-bold")}>{name}</div>
        <div>
          {formatDate(dueDate)} • {users.find((user) => !!user.mainUser)?.name}{" "}
          • {mowers?.[0].name}
        </div>
      </div>
    </div>
  );
};
