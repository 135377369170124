import React, { FC, Fragment } from "react";
import { Marker, Polygon, Polyline } from "@react-google-maps/api";
import { CoordinatesT, CoordinateT, StylesT } from "../../../types/Route";
import { RoutePageLayers, getPolygonOptions } from "./functions";
import {
  getEndPointIcon,
  getStartPointIcon,
} from "../../../pages/JobRouteType/utils";
import { JOB_ROUTE_TYPE_COLORS } from "../../../constants";
import { getPolylineOptions } from "../../../pages/Job/functions";

type MapEvent = google.maps.MapMouseEvent;

type PropsT = {
  route: CoordinatesT<"route">;
  editablePolyline: number;
  showArrows: boolean;
  onClick?: (event: MapEvent, coordinate: CoordinateT<"route">) => void;
  onMouseDown?: (event: MapEvent, coordinate: CoordinateT<"route">) => void;
  onMouseUp?: (event: MapEvent) => void;
  activeRouteLayers: RoutePageLayers | null;
};

const MapRouteSection: FC<PropsT> = ({
  route,
  editablePolyline,
  showArrows,
  onClick,
  onMouseDown,
  onMouseUp,
  activeRouteLayers,
}) => {
  const { styles } = route;
  const activeLayers = activeRouteLayers
    ? (activeRouteLayers.geofence || [])
        .concat(activeRouteLayers.noGoZone || [])
        .concat(activeRouteLayers.route || [])
    : [];
  return (
    <>
      {route.coordinates.map((coordinate, index) => {
        const { coordinates: latLng, styleType, id, isPoint } = coordinate;
        const isPolygon = Boolean(styles[styleType].PolyStyle);
        const options = getPolylineOptions(
          styleType,
          styles as StylesT<"route">,
          showArrows && !isPolygon
        );
        const polygonOptions = getPolygonOptions(
          latLng,
          styleType,
          styles as StylesT<"route">
        );
        options.clickable = true;
        options.draggable = false;
        const isVisible = activeLayers.includes(index);

        if (!isPoint) {
          return (
            <Fragment key={index}>
              {isPolygon ? (
                <Polygon
                  visible={isVisible}
                  key={`polygon-item-${index}`}
                  path={latLng}
                  options={polygonOptions}
                />
              ) : (
                <Polyline
                  visible={isVisible}
                  editable={editablePolyline === id}
                  onClick={onClick ? (e) => onClick(e, coordinate) : undefined}
                  key={`polyline-item-${index}`}
                  path={latLng}
                  options={options}
                  onMouseDown={
                    onMouseDown ? (e) => onMouseDown(e, coordinate) : undefined
                  }
                  onMouseUp={onMouseUp}
                />
              )}
            </Fragment>
          );
        } else {
          const lat = latLng[0].lat || 0;
          const lng = latLng[0].lng || 0;
          return (
            <Marker
              key={`marker-${index}`}
              position={{ lat, lng }}
              visible={isVisible}
              onMouseDown={
                onMouseDown ? (e) => onMouseDown(e, coordinate) : undefined
              }
              onMouseUp={onMouseUp}
              zIndex={styleType === "startPoint" ? 300 : 250}
              icon={{
                url:
                  styleType === "endPoint"
                    ? getEndPointIcon(JOB_ROUTE_TYPE_COLORS.plannedRoute)
                    : getStartPointIcon(JOB_ROUTE_TYPE_COLORS.plannedRoute),
                scaledSize:
                  styleType === "endPoint"
                    ? new google.maps.Size(18, 18)
                    : new google.maps.Size(30, 30),
                anchor:
                  styleType === "endPoint"
                    ? new google.maps.Point(9, 9)
                    : undefined,
              }}
            />
          );
        }
      })}
    </>
  );
};

export default MapRouteSection;
