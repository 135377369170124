import { Dispatch, SetStateAction, createContext } from "react";

type ContextState = {
  showMenu: boolean;
  setShowMenu: Dispatch<SetStateAction<boolean>>;
  openSubmenu: string[];
  setOpenSubmenu: Dispatch<SetStateAction<string[]>>;
};

const initialContext: ContextState = {
  showMenu: false,
  setShowMenu: () => {},
  openSubmenu: [],
  setOpenSubmenu: () => {},
};

export const DropdownContext = createContext(initialContext);
