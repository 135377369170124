import { FC, Fragment, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { ROUTES } from "../../../routes";
import SubHeader from "../../atoms/SubHeader/SubHeader";
import { StyledButton } from "../../atoms/Button/Button";
import { ArchiveButton } from "../../atoms/ArchiveButton/ArchiveButton";
import { PopoverButton } from "../../atoms/PopoverButton/PopoverButton";
import HorizontalDivider from "../../atoms/Divider/HorizontalDivider";
import styles from "./jobSubheader.module.scss";

export type JobProgressStatusT = "completed" | "started" | "beforeStart";

export type SecondaryActionT = {
  action: () => void;
  title: string;
  disabled?: boolean;
  divider?: boolean;
  closeOnClick?: boolean;
};

type PropsT = {
  title: string;
  status: JobProgressStatusT;
  isDisabled: boolean;
  isLoading: boolean;
  isArchived: boolean;
  onStart?: () => void;
  onComplete: () => void;
  onCancel: () => void;
  onArchive: () => void;
  onRestore: () => void;
  onExport: () => void;
  onReopen?: () => void;
  secondaryActions: SecondaryActionT[];
  headerRef?: React.RefObject<HTMLDivElement>;
  onGoBack?: () => void;
  completeJobTitle?: string;
  showOnExportOverride?: boolean;
};

export const JobSubheader: FC<PropsT> = ({
  title,
  status,
  isDisabled,
  isLoading,
  isArchived,
  onStart,
  onComplete,
  onCancel,
  onArchive,
  onRestore,
  onExport,
  onReopen,
  secondaryActions,
  headerRef,
  onGoBack,
  completeJobTitle = "Complete",
  showOnExportOverride,
}) => {
  const location = useLocation();
  const state = location?.state as { from?: string } | null;
  const navigate = useNavigate();

  const [secondaryOpen, setSecondaryOpen] = useState(false);

  const goBack = onGoBack
    ? onGoBack
    : () => {
        if (state?.from === "jobs") {
          navigate(-1);
        } else {
          navigate(ROUTES.jobs());
        }
      };

  const exportButton = (
    <StyledButton
      className={styles.completeButtons}
      title="Export"
      color="light"
      isLoading={isLoading}
      disabled={isDisabled}
      onClick={onExport}
      icon="export"
      onlyIconOnMobile
    />
  );

  let buttons;
  if (status === "beforeStart") {
    buttons = (
      <>
        {onStart && (
          <StyledButton
            title="Start job"
            color="primary"
            isLoading={isLoading}
            disabled={isDisabled || isArchived}
            onClick={onStart}
            icon="play"
          />
        )}
      </>
    );
  } else if (status === "started") {
    buttons = (
      <>
        {showOnExportOverride && exportButton}
        <StyledButton
          title={completeJobTitle}
          color="success"
          isLoading={isLoading}
          disabled={isDisabled || isArchived}
          onClick={onComplete}
          icon="check"
          onlyIconOnMobile
        />
        <StyledButton
          title="Cancel"
          color="danger"
          isLoading={isLoading}
          disabled={isDisabled || isArchived}
          onClick={onCancel}
          icon="times"
          onlyIconOnMobile
        />
      </>
    );
  } else if (status === "completed") {
    buttons = (
      <>
        {(showOnExportOverride || showOnExportOverride === undefined) &&
          exportButton}
        <ArchiveButton
          className={styles.completeButtons}
          archiveTitle="Archive"
          restoreTitle="Unarchive"
          color="light"
          isLoading={isLoading}
          disabled={isDisabled}
          isArchived={!!isArchived}
          onArchive={onArchive}
          onRestore={onRestore}
        />
        {onReopen && (
          <StyledButton
            className={styles.completeButtons}
            title="Reopen"
            color="light"
            isLoading={isLoading}
            disabled={isDisabled}
            onClick={onReopen}
            icon="reload-arrow"
            onlyIconOnMobile
          />
        )}
      </>
    );
  }

  return (
    <SubHeader
      onGoBack={goBack}
      title={title}
      isDetail
      headerRef={headerRef}
      actions={
        <>
          {buttons}
          {
            <PopoverButton
              id="subheaderActions"
              isOpen={secondaryOpen}
              toggle={() => setSecondaryOpen(!secondaryOpen)}
              color="dark"
              outline
              icon="pending"
            >
              <div className={styles.body}>
                {secondaryActions.map((action, index) => (
                  <Fragment key={index}>
                    <StyledButton
                      className={styles.button}
                      title={action.title}
                      color="link"
                      onClick={
                        action.closeOnClick
                          ? () => {
                              setSecondaryOpen(false);
                              action.action();
                            }
                          : action.action
                      }
                      disabled={action.disabled}
                    />
                    {action.divider && <HorizontalDivider />}
                  </Fragment>
                ))}
              </div>
            </PopoverButton>
          }
        </>
      }
    />
  );
};
