/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/naming-convention */
import { lensPath, view } from "ramda";
import { FieldError } from "react-hook-form";

export const getFieldError = <_, K>(
  name: string,
  errors: K
): FieldError | undefined => {
  const lens = lensPath(name.replace(/[\[\]']+/g, "").split("."));
  return view(lens, errors);
};
