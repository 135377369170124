import React, { FC, useMemo, useState } from "react";
import { useSearchParams, useNavigate, useParams } from "react-router-dom";
import { Container } from "reactstrap";
import { WINDOW_TITLE_NAMES } from "../../constants";
import {
  JobCreateInputsT,
  useMutationCreateJob,
} from "../../hooks/useMutationCreateJob";
import { usePermissions } from "../../hooks/usePermissions";
import { useQueryJobGet, useQueryRouteGet } from "../../hooks/useQueries";
import { useSelector } from "../../store/hooks";
import { getDefaultValues, getDefaultValuesFromJob } from "./functions";
import { Layout } from "../../components/organisms/Layout/Layout";
import {
  JobCreateFormFieldsT,
  FormJobCreate,
} from "../../components/molecules/FormJobCreate/FormJobCreate";
import { StyledButton } from "../../components/atoms/Button/Button";
import { PageWrapper } from "../../components/atoms/PageWrapper/PageWrapper";
import SubHeader from "../../components/atoms/SubHeader/SubHeader";
import { Loader } from "../../components/atoms/Loader/Loader";

export const JobsCreatePage: FC = () => {
  document.title = WINDOW_TITLE_NAMES.jobs;
  const { jobId } = useParams() as { jobId: string };
  const [isFormTouched, setIsFormTouched] = useState(jobId ? true : false);
  const currentUser = useSelector((store) => store.user.user);
  const [searchParams] = useSearchParams();
  const [isQuickStart, setIsQuickStart] = useState(false);
  const navigate = useNavigate();
  const permissions = usePermissions();

  const type = searchParams.get("type");
  const routeId = searchParams.get("route");
  const routeFolderId = searchParams.get("folder");
  const mowerId = searchParams.get("mowerId");
  const ufonId = searchParams.get("ufonId");
  const roverUfonId = searchParams.get("roverUfonId");
  const userId = searchParams.get("userId");

  const { data: jobData, isLoading: isLoadingJob } = useQueryJobGet(jobId);
  const job =
    jobData?.data && jobData.data.geostick === undefined
      ? jobData.data
      : undefined;
  const { data: routeData, isLoading: isLoadingRoute } = useQueryRouteGet(
    jobData?.data.route?.id
  );

  const createJobMutation = useMutationCreateJob(isQuickStart);

  const handleJobSubmit = (values: JobCreateFormFieldsT) => {
    const assignedUserId =
      !permissions.jobManage && currentUser ? currentUser.id : values.userId;

    const valuesForMutation: JobCreateInputsT = {
      ...values,
      userId: `${assignedUserId}`,
      assign: values.assign.map((user) => ({
        userId: user.userId || values.userId || `${assignedUserId}`,
        mowerId: user.mowers.map((mower) => mower.mowerId || values.mowerId),
      })),
    };

    createJobMutation.mutate(valuesForMutation);
  };

  const formDefaultValues = useMemo(
    () =>
      jobId && job
        ? getDefaultValuesFromJob(job, routeData?.data)
        : getDefaultValues(
            type,
            routeId,
            routeFolderId,
            mowerId,
            ufonId,
            roverUfonId,
            userId ? userId : currentUser?.id.toString()
          ),
    [
      job,
      type,
      routeId,
      routeFolderId,
      mowerId,
      ufonId,
      roverUfonId,
      currentUser?.id,
      userId,
      jobId,
      routeData,
    ]
  );

  if (isLoadingJob || isLoadingRoute) {
    return <Loader isCentered />;
  }

  return (
    <Layout>
      <SubHeader
        isDetail
        title="New job"
        actions={
          <>
            <StyledButton
              onClick={() => navigate(-1)}
              icon="times"
              color="dark"
              outline
              title="Cancel"
              onlyIconOnMobile
            />
            <StyledButton
              icon="play"
              isLoading={createJobMutation.isLoading}
              disabled={!isFormTouched}
              color="primary"
              type="submit"
              form="create-job-form"
              onlyIconOnMobile
              onClick={() => setIsQuickStart(true)}
            >
              {createJobMutation.isLoading ? "Saving" : "Create & start"}
            </StyledButton>
            <StyledButton
              isLoading={createJobMutation.isLoading}
              disabled={!isFormTouched}
              icon="check"
              color="primary"
              type="submit"
              form="create-job-form"
              onlyIconOnMobile
              onClick={() => setIsQuickStart(false)}
            >
              {createJobMutation.isLoading ? "Saving" : "Create"}
            </StyledButton>
          </>
        }
      />
      <PageWrapper>
        <Container>
          <FormJobCreate
            defaultValues={formDefaultValues}
            isDisabledAll={isLoadingJob || isLoadingRoute}
            onTouch={() => setIsFormTouched(true)}
            onSubmit={handleJobSubmit}
          />
        </Container>
      </PageWrapper>
    </Layout>
  );
};
