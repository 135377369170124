import classNames from "classnames";
import React, { FC } from "react";
import { Button } from "reactstrap";
import styles from "./switchButton.module.scss";

type PropsT = {
  stats?: boolean;
  active?: boolean;
  title: string;
  onClick?: () => void;
};

export const SwitchButton: FC<PropsT> = ({ stats, active, title, ...rest }) => {
  return (
    <Button
      {...rest}
      block
      outline
      color="dark"
      size="sm"
      className={classNames(styles["switch-button"], {
        [styles["switch-button--active"]]: active,
      })}
    >
      {title}
    </Button>
  );
};
