import { ReactNode } from "react";
import classNames from "classnames";
import { DEVICE_DETAIL_COLORS } from "../../../constants";
import { DeviceCurrentInfo } from "../../../types/Common";
import { Icon } from "../../atoms/Icon";
import styles from "./deviceInfo.module.scss";

type Props = {
  data: DeviceCurrentInfo;
  children?: ReactNode;
  onClick?: (label: string) => void;
};

export default function DeviceDetailStatusRow({
  data,
  children,
  onClick,
}: Props) {
  return (
    <>
      <div className="d-flex justify-content-between align-items-center">
        <div className={styles.rowinfo}>
          {data.icon && (
            <Icon
              icon={data.icon.id}
              color={data.icon.color}
              className={classNames(styles.icon)}
            />
          )}
          <span>{data.label}</span>
        </div>
        <div className={styles.valueWrapper}>
          <div className={styles.value}>
            {data.value ? (
              <span style={{ color: data.textColor }}>{data.value}</span>
            ) : (
              <span style={{ color: DEVICE_DETAIL_COLORS.default }}>
                &mdash;
              </span>
            )}
          </div>
          {onClick && (
            <Icon
              icon="chevron-right"
              className={styles.chevron}
              onClick={() => onClick(data.label)}
            />
          )}
        </div>
      </div>
      {children}
    </>
  );
}
