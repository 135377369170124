import React, { FC } from "react";
import classNames from "classnames";
import styles from "./footerButtons.module.scss";

type PropsT = {
  className?: string;
};

export const FooterButtons: FC<PropsT> = ({ children, className }) => {
  return (
    <div className={classNames(styles["footer-buttons"], className)}>
      {children}
    </div>
  );
};
