import { lineSymbol, manualMowingSymbol } from "../constants";

export function getMowerPolylineOptions(
  color: string,
  isDirectionShowing: boolean,
  knivesOn: boolean,
  isAutonomous?: boolean
): google.maps.PolylineOptions {
  const icons: google.maps.IconSequence[] = [];
  if (!knivesOn) {
    icons.push({
      icon: lineSymbol,
      offset: "0",
      repeat: "15px",
    });
  }
  const isManualMowing = knivesOn && isAutonomous === false;
  if (isManualMowing) {
    icons.push({
      icon: manualMowingSymbol,
      offset: "0",
      repeat: "30px",
    });
  }
  if (isDirectionShowing) {
    icons.push({
      icon: {
        path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
        scale: 1.5,
      },
      repeat: "30px",
    });
  }

  return {
    strokeColor: color,
    strokeOpacity: 1,
    strokeWeight: knivesOn && !isManualMowing ? 2 : 0,
    zIndex: 1200,
    icons,
  };
}

export function getMowerFuturePolylineOptions(
  color: string,
  isDirectionShowing: boolean
): google.maps.PolylineOptions {
  const icons: google.maps.IconSequence[] = [];

  if (isDirectionShowing) {
    icons.push({
      icon: {
        path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
        scale: 1.5,
      },
      repeat: "30px",
    });
  }
  return {
    strokeColor: color,
    strokeOpacity: 1,
    strokeWeight: 1,
    zIndex: 900,
    icons,
  };
}
