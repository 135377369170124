import classNames from "classnames";
import React, { FC } from "react";
import { ButtonGroup } from "reactstrap";
import styles from "./switchGroup.module.scss";

type PropsT = {
  stats?: boolean;
};

export const SwitchGroup: FC<PropsT> = ({ children, stats, ...rest }) => {
  return (
    <ButtonGroup
      {...rest}
      className={classNames(styles["switch-group"], {
        [styles["switch-group--stats"]]: stats,
      })}
    >
      {children}
    </ButtonGroup>
  );
};
