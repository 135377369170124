import React from "react";
import { StyledButton } from "../Button/Button";
import styles from "./mapLocationControls.module.scss";

type Props = {
  handleHomeLocation: () => void;
  handleUserLocation: () => void;
};

const MapLocationControls = ({
  handleHomeLocation,
  handleUserLocation,
}: Props) => {
  return (
    <>
      <StyledButton
        color="light"
        icon="home"
        kind="iconButton"
        className={styles["icon-button"]}
        onClick={handleHomeLocation}
      />
      <StyledButton
        color="light"
        icon="target"
        kind="iconButton"
        className={styles["icon-button"]}
        onClick={handleUserLocation}
      />
    </>
  );
};

export default MapLocationControls;
