import { Route, Routes } from "react-router-dom";
import { Login } from "./pages/Login/Login";
import { Dashboard } from "./pages/Dashboard/Dashboard";
import { PrivateRoute } from "./components/atoms/PrivateRoute";
import { UsersPage } from "./pages/Users/Users";
import { MowerPage } from "./pages/Mower/Mower";
import { UserDetailPage } from "./pages/Users/UserDetail";
import { UfonPage } from "./pages/Ufon/Ufon";
import { DevicesPage } from "./pages/Devices/Devices";
import { RoutesPage } from "./pages/Routes/Routes";
import { RoutePage } from "./pages/Route/Route";
import { RouteEditPage } from "./pages/Routes/RouteEdit";
import { NotFound } from "./pages/404/NotFound";
import { JobsPage } from "./pages/Jobs/Jobs";
import { ROUTES } from "./routes";
import { JobPage } from "./pages/Job/Job";
import { JobsCreatePage } from "./pages/Jobs/JobsCreate";
import { MowerEditPage } from "./pages/Mower/MowerEdit";
import { MowerCreatePage } from "./pages/Mower/MowerCreate";
import { JobSignpostPage } from "./pages/Job/JobSignpost";
import { UfonCreatePage } from "./pages/Ufon/UfonCreate";
import { UfonEditPage } from "./pages/Ufon/UfonEdit";
import { UserCreatePage } from "./pages/Users/UserCreate";
import { UserEditPage } from "./pages/Users/UserEdit";
import { UserSelectCompanyPage } from "./pages/Users/UserSelectCompany";
import { RouteCreatePage } from "./pages/Routes/RouteCreate";
import { LeicasPage } from "./pages/Leicas/Leicas";
import { LeicaCreatePage } from "./pages/Leica/LeicaCreate";
import { LeicaPage } from "./pages/Leica/Leica";
import { CompaniesPage } from "./pages/Companies/Companies";
import { unstable_HistoryRouter as HistoryRouter } from "react-router-dom";
import { createBrowserHistory } from "history";
import { JobEditPage } from "./pages/Jobs/JobEdit";
import { LostPasswordPage } from "./pages/LostPassword/LostPassword";
import { UserRegisterPage } from "./pages/UserRegister/UserRegister";
import { RecoverPasswordPage } from "./pages/RecoverPassword/RecoverPassword";
import { PendingUserDetailPage } from "./pages/Users/PendingUserDetail";
import { JoinCompany } from "./pages/JoinCompany/JoinCompany";
import { RouteClonePage } from "./pages/Routes/RouteClone";
import { JobRouteType } from "./pages/JobRouteType/JobRouteType";
import { JobManualPage } from "./pages/JobManual/JobManual";
import { StatisticsPage } from "./pages/Statistics/Statistics";
import { Geostick } from "./pages/Geostick/Geostick";
import { RouteChangePage } from "./pages/Routes/RouteChange";
import { RouteCreateFromJobPage } from "./pages/Routes/RouteCreateFromJob";
import { NotificationsPage } from "./pages/Notifications/Notifications";

export const history = createBrowserHistory();

const Routing = () => {
  return (
    <HistoryRouter history={history}>
      {/* <InjectAxiosInterceptors /> */}
      <Routes>
        {/* Main routes */}
        <Route
          path="/"
          element={
            <PrivateRoute>
              <Dashboard />
            </PrivateRoute>
          }
        />
        <Route path="/login" element={<Login />} />
        <Route
          path="/user/select-company"
          element={
            <PrivateRoute>
              <UserSelectCompanyPage />
            </PrivateRoute>
          }
        />

        {/* User routes */}
        <Route
          path="/users"
          element={
            <PrivateRoute>
              <UsersPage />
            </PrivateRoute>
          }
        />
        <Route
          path={ROUTES.userCreate()}
          element={
            <PrivateRoute>
              <UserCreatePage />
            </PrivateRoute>
          }
        />
        <Route
          path="/user/:userId"
          element={
            <PrivateRoute>
              <UserDetailPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/pending-user/:userId"
          element={
            <PrivateRoute>
              <PendingUserDetailPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/user/edit/:userId"
          element={
            <PrivateRoute>
              <UserEditPage />
            </PrivateRoute>
          }
        />
        <Route path={ROUTES.lostPassword()} element={<LostPasswordPage />} />
        <Route path={"/user-register/:token"} element={<UserRegisterPage />} />
        <Route path={"/user-register"} element={<UserRegisterPage />} />
        <Route
          path={"/recover-password/:token"}
          element={<RecoverPasswordPage />}
        />
        <Route path={"/approve/:token"} element={<JoinCompany />} />

        {/* Devices routes */}
        <Route
          path="/mower/create/"
          element={
            <PrivateRoute>
              <MowerCreatePage />
            </PrivateRoute>
          }
        />
        <Route
          path="/mower/:mowerId"
          element={
            <PrivateRoute>
              <MowerPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/mower/edit/:mowerId"
          element={
            <PrivateRoute>
              <MowerEditPage />
            </PrivateRoute>
          }
        />
        <Route
          path={ROUTES.notifications()}
          element={
            <PrivateRoute>
              <NotificationsPage />
            </PrivateRoute>
          }
        />
        <Route
          path={ROUTES.ufonCreate()}
          element={
            <PrivateRoute>
              <UfonCreatePage />
            </PrivateRoute>
          }
        />
        <Route
          path="/ufon/:ufonId"
          element={
            <PrivateRoute>
              <UfonPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/ufon/edit/:ufonId"
          element={
            <PrivateRoute>
              <UfonEditPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/devices"
          element={
            <PrivateRoute>
              <DevicesPage />
            </PrivateRoute>
          }
        />

        {/* Routes routes */}
        <Route
          path="/routes"
          element={
            <PrivateRoute>
              <RoutesPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/route/:routeId"
          element={
            <PrivateRoute>
              <RoutePage />
            </PrivateRoute>
          }
        />
        <Route
          path={ROUTES.routeCreate()}
          element={
            <PrivateRoute>
              <RouteCreatePage />
            </PrivateRoute>
          }
        />
        <Route
          path="/route/create-from-job/:jobId"
          element={
            <PrivateRoute>
              <RouteCreateFromJobPage />
            </PrivateRoute>
          }
        />
        <Route
          path={ROUTES.routeClone()}
          element={
            <PrivateRoute>
              <RouteClonePage />
            </PrivateRoute>
          }
        />
        <Route
          path={ROUTES.routeChange()}
          element={
            <PrivateRoute>
              <RouteChangePage />
            </PrivateRoute>
          }
        />
        <Route
          path={ROUTES.leicaCreate()}
          element={
            <PrivateRoute>
              <LeicaCreatePage />
            </PrivateRoute>
          }
        />
        <Route
          path={ROUTES.leicas()}
          element={
            <PrivateRoute>
              <LeicasPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/route/edit/:routeId"
          element={
            <PrivateRoute>
              <RouteEditPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/leica/:leicaId/:leicaName"
          element={
            <PrivateRoute>
              <LeicaPage />
            </PrivateRoute>
          }
        />

        {/* Jobs routes */}
        <Route
          path="/jobs"
          element={
            <PrivateRoute>
              <JobsPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/job-signpost/:jobId"
          element={
            <PrivateRoute>
              <JobSignpostPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/job/:jobId"
          element={
            <PrivateRoute>
              <JobPage />
            </PrivateRoute>
          }
        />
        <Route
          path={"/job/create"}
          element={
            <PrivateRoute>
              <JobsCreatePage />
            </PrivateRoute>
          }
        />
        <Route
          path={"/job/create/:jobId"}
          element={
            <PrivateRoute>
              <JobsCreatePage />
            </PrivateRoute>
          }
        />
        <Route
          path="/job/edit/:jobId"
          element={
            <PrivateRoute>
              <JobEditPage />
            </PrivateRoute>
          }
        />

        {/* Companies routes */}
        <Route
          path="/companies"
          element={
            <PrivateRoute>
              <CompaniesPage />
            </PrivateRoute>
          }
        />

        <Route
          path="/route-job/:jobId"
          element={
            <PrivateRoute>
              <JobRouteType />
            </PrivateRoute>
          }
        />

        <Route
          path="/manual-job/:jobId"
          element={
            <PrivateRoute>
              <JobManualPage />
            </PrivateRoute>
          }
        />

        <Route
          path="geostick-job/:jobId"
          element={
            <PrivateRoute>
              <Geostick />
            </PrivateRoute>
          }
        />

        <Route
          path="/statistics"
          element={
            <PrivateRoute>
              <StatisticsPage />
            </PrivateRoute>
          }
        />

        {/* 404 */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </HistoryRouter>
  );
};

export default Routing;
