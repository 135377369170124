import { MapMarkersT } from "../../types/Common";

export const getMapBounds = (devices: MapMarkersT[]) => {
  const bounds = new window.google.maps.LatLngBounds();
  devices?.forEach((device) =>
    device.items.forEach(({ latitude, longitude }) => {
      if (latitude && longitude) {
        bounds.extend({ lat: latitude, lng: longitude });
      }
    })
  );
  return bounds;
};
