import React, { Fragment } from "react";
import { UfonT } from "../../../types/Ufon";
import { useSelector } from "../../../store/hooks";
import CardWithHeader from "../../../components/atoms/CardWithHeader/CardWithHeader";
import DeviceDetailStatusRow from "../../../components/molecules/DeviceInfo/DeviceDetailStatusRow";
import HorizontalDivider from "../../../components/atoms/Divider/HorizontalDivider";
import { getCurrentInfo } from "../functions";

type PropsT = {
  ufon: UfonT;
};

export const UfonCurrentInfoCard: React.FC<PropsT> = ({ ufon }: PropsT) => {
  const connection = useSelector(
    (state) => state.ufon.ufons[ufon.id]?.ufonConnection
  );
  return (
    <CardWithHeader title="Correction station">
      {getCurrentInfo(ufon, connection?.correction.type).map((row, idx) => {
        return (
          <Fragment key={`${row.label}-${idx}`}>
            <DeviceDetailStatusRow data={row} />
            {row.separator ? <HorizontalDivider /> : null}
          </Fragment>
        );
      })}
    </CardWithHeader>
  );
};
