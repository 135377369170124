import classNames from "classnames";
import { FC, MouseEvent } from "react";
import { Input } from "reactstrap";
import { JOB_STATUSES } from "../../../constants";
import { useCompactDesign } from "../../../hooks/useCompactDesign";
import { MenuWithLayoutItemT } from "../../organisms/MenuWithLayout/MenuWithLayout";
import { Diode } from "../Diode/Diode";
import { Icon } from "../Icon";
import { NumberBullet } from "../NumberBullet/NumberBullet";
import styles from "./filterItem.module.scss";

type PropsT = {
  className?: string;
  isStandalone?: boolean;
  item: MenuWithLayoutItemT;
  itemCount?: number;
  isActiveItem: boolean;
  isCheckedItem?: boolean;
  onClick: (event: MouseEvent<HTMLElement>) => void;
  onDelete?: (
    event: MouseEvent<HTMLDivElement>,
    item: MenuWithLayoutItemT
  ) => void;
  onUpdate?: (
    event: MouseEvent<HTMLDivElement>,
    item: MenuWithLayoutItemT
  ) => void;
};

export const FilterItem: FC<PropsT> = ({
  children,
  className,
  item,
  isActiveItem,
  isStandalone,
  itemCount,
  isCheckedItem,
  onDelete,
  onUpdate,
  ...rest
}) => {
  const isCompact = useCompactDesign();
  return (
    <>
      <button
        {...rest}
        className={classNames(
          className,
          "dropdown-item",
          styles["filter-item"],
          {
            [styles["filter-item--active"]]: isActiveItem && !isStandalone,
            [styles["filter-item--active-standalone"]]:
              isActiveItem && isStandalone,
            [styles["filter-item--standalone"]]: isStandalone,
            [styles["filter-item--checked"]]: isCheckedItem,
          }
        )}
      >
        {item.isRadio && (
          <Input
            className={classNames(styles["radio-input"], {
              [styles["radio-input--compact"]]: isCompact,
            })}
            checked={isCheckedItem}
            type="radio"
            readOnly
          />
        )}
        {item.title === JOB_STATUSES.Overdue && (
          <Diode withBorderSmall status="nok" />
        )}
        {item.title === JOB_STATUSES.Open && (
          <Diode withBorderSmall status="ok" />
        )}
        {item.title === JOB_STATUSES.Finished && (
          <Diode withBorderSmall status="done" />
        )}
        {item.title}
        {itemCount && itemCount > 0 ? (
          <NumberBullet
            className={styles["filter-item__count"]}
            number={itemCount}
          />
        ) : null}
        {onUpdate && (
          <div
            className={styles["filter-item__update"]}
            onClick={(event) => onUpdate(event, item)}
          >
            <Icon icon="pen" />
          </div>
        )}
        {onDelete && (
          <div
            className={styles["filter-item__remove"]}
            onClick={(event) => onDelete(event, item)}
          >
            <Icon icon="minus-circle" />
          </div>
        )}
      </button>
      {item.hasSeparator && <div className={styles["filter-item-separator"]} />}
    </>
  );
};
