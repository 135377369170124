import React, { FC } from "react";
import classNames from "classnames";
import { IconsId } from "../../../assets/fonts/iconfont/icons";
import { Icon } from "../Icon";
import ProgressBar from "../ProgressBar/ProgressBar";
import styles from "./cardHeader.module.scss";

interface PropsT {
  title: string;
  icon?: IconsId;
  size?: "sm" | "md" | "lg";
  withProgress?: { value: number };
  withRtkMode?: { mode: string | null };
  onClick?: VoidFunction;
  className?: string;
}

export const CardHeader: FC<PropsT> = ({
  icon,
  title,
  size,
  withProgress,
  withRtkMode,
  onClick,
  className,
}) => {
  return (
    <div
      className={classNames(
        "d-flex",
        "justify-content-between",
        "mb-3",
        styles.wrapper,
        {
          [styles.clickable]: Boolean(onClick),
        },
        className
      )}
      onClick={onClick}
    >
      <div className={styles["title-wrapper"]}>
        {icon && <Icon icon={icon} />}
        <span
          className={classNames(styles.title, {
            [styles["title--large"]]: size === "lg",
            [styles["title--small"]]: size === "sm",
          })}
        >
          {title}
        </span>
      </div>
      <div className={styles["action-wrapper"]}>
        {withProgress && <ProgressBar value={withProgress.value} />}
        {withRtkMode && withRtkMode.mode && (
          <div className={styles["rtk-wrapper"]}>
            <p className={styles["rtk-label"]}>RTK Mode</p>
            <p className={styles["rtk-value"]}>{withRtkMode.mode}</p>
          </div>
        )}
        {onClick && (
          <Icon icon="chevron-right" className={classNames(styles.icon)} />
        )}
      </div>
    </div>
  );
};
