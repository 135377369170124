import { string, object } from "yup";
import { VALIDATIONS } from "../../../constants";

export const formMowerSchema = object({
  name: string().required(VALIDATIONS.required),
  title: string().required(VALIDATIONS.required),
  serialNumber: string().required(VALIDATIONS.required),
  smautSerialNumber: string(),
  spiderSerialNumber: string(),
  companyId: string().required(VALIDATIONS.required),
}).required();
