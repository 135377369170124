import { ReactElement } from "react";
import { Navigate } from "react-router";
import { useSelector } from "../../store/hooks";

type Props = {
  children: ReactElement;
};

export const PrivateRoute: React.FC<Props> = ({ children }) => {
  const user = useSelector((state) => state.user.user);
  return user ? children : <Navigate to="/login" />;
};
