import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { GoogleMap, GoogleMapProps } from "@react-google-maps/api";
import { useNavigate } from "react-router";
import { ROUTES } from "../../routes";
import {
  DEFAULT_SHOW_ACTIVITY_TYPES,
  JOB_ROUTE_TYPE_COLORS,
} from "../../constants";
import { useUserLocation } from "../../hooks/useUserLocation";
import { useSelector } from "../../store/hooks";
import {
  getBoundsFromJourneys,
  getMapBounds,
  getSingleBound,
} from "../../functions/getBounds";
import { onLoad } from "../../functions/onMapLoad";
import {
  CoordinatesRawT,
  JobMapInfo,
  CoordinatesT,
  CoordinateT,
  ShowActivityTypesT,
} from "../../types/Route";
import { JobT, TaskT } from "../../types/Job";
import JourneyPolylinesBase from "../../components/molecules/JourneyPolylines/JourneyPolylinesBase";
import JobMapInfoWindow from "../../components/molecules/MapInfoWindow/JobMapInfoWindow";
import { Loader } from "../../components/atoms/Loader/Loader";
import UserMapMarker from "../../components/atoms/UserMapMarker/UserMapMarker";
import MapControls from "../../components/molecules/MapControls/MapControls";
import MowerMarker from "../../components/atoms/MapMarkers/MowerMarker";
import UfonMarker from "../../components/atoms/MapMarkers/UfonMarker";
import { PerimeterLayersType, RouteLayersPerimeter } from "./types";
import RoutePolylines from "./RoutePolylines";
import MapMowerTracker from "./MapMowerTracker";
import DropdownComponent from "./DropdownComponent";
import styles from "./job.module.scss";

interface PropsT extends GoogleMapProps {
  isLoading: boolean;
  isLoadingJourneys: boolean;
  isLoadingTasks: boolean;
  isLoadingCoordinates: boolean;
  journeys: CoordinatesRawT[];
  routeCoordinates?: CoordinatesT<"route">;
  mowerIds: number[];
  tempNogo: TaskT[] | undefined;
  jobId: string;
  job: JobT;
  currentUserId: number | undefined;
  ufonId?: number;
}

const routeLayersPerimeterInitial = {
  calculated: [],
  recording: [],
};

const JobMap: FC<PropsT> = ({
  routeCoordinates,
  isLoading,
  isLoadingJourneys,
  isLoadingTasks,
  isLoadingCoordinates,
  journeys,
  center,
  mowerIds,
  tempNogo,
  jobId,
  job,
  currentUserId,
  ufonId,
  ...restProps
}) => {
  const navigate = useNavigate();

  const [activeRouteLayers, setActiveRouteLayers] =
    useState<RouteLayersPerimeter>(routeLayersPerimeterInitial);
  const [activityTypes, setActivityTypes] = useState<ShowActivityTypesT>(
    DEFAULT_SHOW_ACTIVITY_TYPES
  );

  const [showMowingArrows, setShowMowingArrows] = useState(false);

  const [activityChecked, setActivityChecked] = useState(true);

  const [activeMarker, setActiveMarker] = useState<JobMapInfo | undefined>(
    undefined
  );
  const mapRef = useRef<GoogleMap>(null);

  const { userLocation, mapCenter, setMapCenter, handleUserLocation } =
    useUserLocation(center, mapRef);

  const getActiveLayers = (coordinates: CoordinateT<"route">[]) => {
    const layers = coordinates.reduce<RouteLayersPerimeter>(
      (acc, item, index) => {
        if (
          item.styleType === "endPoint" ||
          item.styleType === "startPoint" ||
          item.styleType === "mowingRoute" ||
          item.styleType === "transitRoute"
        ) {
          acc.calculated = [...acc.calculated, index];
        } else if (
          item.styleType === "geofence" ||
          item.styleType === "noGoZone"
        ) {
          acc.recording = [...acc.recording, index];
        }
        return acc;
      },
      { ...routeLayersPerimeterInitial }
    );
    return layers;
  };

  const resetLayers = () => {
    setShowMowingArrows(false);
    setActivityChecked(true);
    setActivityTypes(DEFAULT_SHOW_ACTIVITY_TYPES);
    setActiveRouteLayers(
      routeCoordinates
        ? getActiveLayers(routeCoordinates.coordinates)
        : routeLayersPerimeterInitial
    );
  };

  useEffect(() => {
    if (routeCoordinates) {
      const bounds: google.maps.LatLngBounds = getMapBounds(routeCoordinates);
      mapRef.current?.state.map?.fitBounds(bounds);
      setActiveRouteLayers(getActiveLayers(routeCoordinates.coordinates));
    }
  }, [routeCoordinates]);

  useEffect(() => {
    if (journeys && journeys.length > 0) {
      if (!routeCoordinates) {
        const bounds: google.maps.LatLngBounds =
          getBoundsFromJourneys(journeys);
        mapRef.current?.state.map?.fitBounds(bounds);
      }
    }
  }, [journeys, routeCoordinates]);

  const handleRouteLayersChange = useCallback(
    (ids: number[], type: PerimeterLayersType) => {
      if (!activeRouteLayers) return;
      let helperArr = [...activeRouteLayers[type]];
      for (const id of ids) {
        if (helperArr.includes(id)) {
          helperArr = helperArr.filter((item) => item !== id);
        } else {
          helperArr.push(id);
        }
      }
      setActiveRouteLayers((prev) => ({
        ...prev,
        [type]: helperArr,
      }));
    },
    [activeRouteLayers]
  );

  const handleCheckAllRoutes = useCallback(
    (type: PerimeterLayersType) => {
      if (!routeCoordinates) return;
      if (activeRouteLayers[type].length > 0) {
        setActiveRouteLayers((prev) => ({
          ...prev,
          [type]: [],
        }));
      } else {
        setActiveRouteLayers((prev) => {
          const newArr = routeCoordinates.coordinates.reduce<number[]>(
            (acc, item, index) => {
              if (type === "calculated") {
                if (
                  item.styleType === "endPoint" ||
                  item.styleType === "startPoint" ||
                  item.styleType === "mowingRoute" ||
                  item.styleType === "transitRoute"
                ) {
                  acc.push(index);
                }
              } else {
                if (
                  item.styleType === "geofence" ||
                  item.styleType === "noGoZone"
                ) {
                  acc.push(index);
                }
              }
              return acc;
            },
            []
          );
          return { ...prev, [type]: newArr };
        });
      }
    },
    [routeCoordinates, activeRouteLayers]
  );

  const isLoadingOn = isLoading || isLoadingCoordinates || isLoadingTasks;

  const mowers = useSelector((state) => state.mower.mowers);
  const currentMowerLocation = useMemo(
    () =>
      job &&
      job.mowers[0] &&
      mowers[job.mowers[0].id] &&
      mowers[job.mowers[0].id].currentLocation,
    [job, mowers]
  );

  const handleJobLocation = () => {
    if (!mapRef.current) {
      return;
    }
    if (routeCoordinates) {
      const bounds = getMapBounds(routeCoordinates);
      mapRef.current?.state.map?.fitBounds(bounds);
      return;
    } else if (journeys.length > 0 && !routeCoordinates) {
      const bounds = getBoundsFromJourneys(journeys);
      mapRef.current?.state.map?.fitBounds(bounds);
      return;
    } else if (currentMowerLocation) {
      const bounds = getSingleBound(currentMowerLocation);
      mapRef.current?.state.map?.fitBounds(bounds);
    } else if (center && journeys.length === 0 && !routeCoordinates) {
      const bounds = getSingleBound({
        lat: typeof center.lat === "number" ? center.lat : center.lat(),
        lng: typeof center.lng === "number" ? center.lng : center.lng(),
      });
      mapRef.current?.state.map?.fitBounds(bounds);
    }
  };

  useEffect(() => {
    setMapCenter(center);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [center]);

  return (
    <div className={styles.map}>
      {isLoadingOn && (
        <div className={styles.loader}>
          <Loader />
        </div>
      )}
      <GoogleMap
        tilt={0}
        onLoad={onLoad}
        mapTypeId={google.maps.MapTypeId.SATELLITE}
        mapContainerStyle={{ height: "100%" }}
        ref={mapRef}
        zoom={18}
        center={mapCenter}
        {...restProps}
      >
        {userLocation && <UserMapMarker userLocation={userLocation} />}
        <MapControls
          dropdownComponent={
            job.type.name !== "Manual" ? (
              <DropdownComponent
                showMowingArrows={showMowingArrows}
                setShowMowingArrows={() => setShowMowingArrows((prev) => !prev)}
                handleCheckActivity={() => setActivityChecked((prev) => !prev)}
                activityChecked={activityChecked}
                activeRouteLayers={activeRouteLayers}
                handleCheckAllRoutes={handleCheckAllRoutes}
                handleRouteLayersChange={handleRouteLayersChange}
                isDisabled={!routeCoordinates}
                coordinates={routeCoordinates}
                activityTypes={journeys.length > 0 ? activityTypes : undefined}
                setActivityType={(
                  type: keyof ShowActivityTypesT,
                  value: boolean
                ) =>
                  setActivityTypes((x) => {
                    return { ...x, [type]: value };
                  })
                }
                resetLayers={resetLayers}
              />
            ) : undefined
          }
          handleUserLocation={handleUserLocation}
          handleHomeLocation={handleJobLocation}
        />
        {routeCoordinates && (
          <RoutePolylines
            showArrows={showMowingArrows}
            activeLayers={
              activeRouteLayers?.calculated.concat(
                activeRouteLayers?.recording
              ) || []
            }
            routeCoordinates={routeCoordinates}
            tempNogoCoordinates={tempNogo?.[0]?.subtask?.items}
          />
        )}
        {journeys.length > 0 && (
          <JourneyPolylinesBase
            journeys={journeys}
            isLoadingJourneys={isLoadingJourneys}
            showActivity={activityChecked}
            strokeColor={JOB_ROUTE_TYPE_COLORS.activeDevice}
            isDirectionShowing={showMowingArrows}
            showActivityTypes={activityTypes}
          />
        )}
        {mowerIds.map((mowerId) => (
          <React.Fragment key={mowerId}>
            <MapMowerTracker
              mowerId={mowerId}
              isDirectionShowing={showMowingArrows}
              jobId={jobId}
              showActivity={activityChecked}
              job={job}
              hideMissedCoordinates={journeys.length > 0}
            />
            <MowerMarker
              mowerId={mowerId}
              showMarker={activityChecked}
              setActiveMarker={setActiveMarker}
              job={job}
            />
          </React.Fragment>
        ))}
        {ufonId && <UfonMarker ufonId={ufonId} />}
        {activeMarker && (
          <JobMapInfoWindow
            onClose={() => setActiveMarker(undefined)}
            onClick={() => navigate(ROUTES.mower(activeMarker.deviceId))}
            title={activeMarker.name}
            position={{
              lat: activeMarker.lat,
              lng: activeMarker.lng,
            }}
            icon="mower"
          />
        )}
      </GoogleMap>
    </div>
  );
};

export default JobMap;
