const REFRESH_TOKEN = "refresh_token";
const ACCESS_TOKEN = "access_token";
const TOKEN_EXPIRATION_DATE = "token_expiration_date";
const COMPANY_ID = "company_id";

export const setTokens = (
  accessToken: string,
  refreshToken: string,
  acExpirationDate: Date
) => {
  window.localStorage.setItem(
    TOKEN_EXPIRATION_DATE,
    acExpirationDate.toISOString()
  );
  window.localStorage.setItem(REFRESH_TOKEN, refreshToken);
  window.localStorage.setItem(ACCESS_TOKEN, accessToken);
};

export const getTokens = () => {
  const accessToken = window.localStorage.getItem(ACCESS_TOKEN);
  const refreshToken = window.localStorage.getItem(REFRESH_TOKEN);
  const acExpirationDate = window.localStorage.getItem(TOKEN_EXPIRATION_DATE);

  if (!accessToken || !refreshToken || !acExpirationDate) {
    return null;
  }

  return {
    accessToken,
    refreshToken,
    tokenExpirationDate: new Date(acExpirationDate),
  };
};

export const removeTokens = () => {
  window.localStorage.removeItem(ACCESS_TOKEN);
  window.localStorage.removeItem(REFRESH_TOKEN);
  window.localStorage.removeItem(TOKEN_EXPIRATION_DATE);
};

export const setCompany = (companyId: number) => {
  window.localStorage.setItem(COMPANY_ID, `${companyId}`);
};

export const getCompany = () => {
  const companyId = window.localStorage.getItem(COMPANY_ID);
  if (companyId) {
    return parseInt(companyId);
  }
  return null;
};

export const removeCompany = () => {
  window.localStorage.removeItem(COMPANY_ID);
};
