import React from "react";
import {
  useQueryJobDashboardGet,
  // useQueryUserGet,
} from "../../hooks/useQueries";
import { Loader } from "../../components/atoms/Loader/Loader";
import JobsList from "./JobsList";
import DashboardZeroState from "./DashboardZeroState";
// import { useSelector } from "../../store/hooks";
// import MowerServiceList from "./MowerServiceList";

const DashboardSidebar = () => {
  // const { user } = useSelector((store) => store.user);
  const { data: myJobs, isLoading: isLoadingMyJobs } =
    useQueryJobDashboardGet("my");
  const { data: delegatedJobs, isLoading } =
    useQueryJobDashboardGet("delegated");
  const { data: allJobs, isLoading: isAllLoading } =
    useQueryJobDashboardGet(null);

  // const { data: userData } = useQueryUserGet(user?.id || null);

  const zeroState =
    myJobs?.data.items.length === 0 &&
    delegatedJobs?.data.items.length === 0 &&
    allJobs?.data.items.length === 0;

  const showAllJobs =
    myJobs?.data.items.length === 0 &&
    delegatedJobs?.data.items.length === 0 &&
    allJobs?.data.items.length !== 0;

  if (isLoadingMyJobs || isLoading || isAllLoading) {
    return <Loader isCentered />;
  }
  return (
    <>
      {myJobs && myJobs.data.items.length > 0 && (
        <JobsList
          title="My jobs"
          items={myJobs.data.items}
          count={{
            open: myJobs?.data.filterCount.status[1],
            overdue: myJobs?.data.filterCount.status[2],
          }}
        />
      )}
      {delegatedJobs && delegatedJobs.data.items.length > 0 && (
        <JobsList
          title="Delegated jobs"
          items={delegatedJobs.data.items}
          count={{
            open: delegatedJobs.data.filterCount.status[1],
            overdue: delegatedJobs.data.filterCount.status[2],
          }}
          showJobOwnerName
        />
      )}
      {allJobs && showAllJobs && (
        <JobsList title="All jobs" items={allJobs.data.items} />
      )}
      {/* {userData && userData.data.devices.mower.length > 0 && (
        <MowerServiceList mowers={userData.data.devices.mower} />
      )} */}

      {zeroState && <DashboardZeroState />}
    </>
  );
};

export default DashboardSidebar;
