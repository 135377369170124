import { FC } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import { Control, FormState } from "react-hook-form";
import { useCompactDesign } from "../../../hooks/useCompactDesign";
import { Loader } from "../../atoms/Loader/Loader";
import { InputController } from "../Form/InputController";
import { JobCreateFormFieldsT } from "./FormJobCreate";

type PropsT = {
  control: Control<JobCreateFormFieldsT, object>;
  formState: FormState<JobCreateFormFieldsT>;
  radiosJobTypeOptions?: { label: string; value: number }[];
  isLoadingTypes: boolean;
};

export const FormJobTypeCard: FC<PropsT> = ({
  control,
  formState,
  radiosJobTypeOptions,
  isLoadingTypes,
}: PropsT) => {
  const { errors } = formState;
  const isCompact = useCompactDesign();

  return (
    <Card>
      <CardBody>
        {!isLoadingTypes &&
          (isCompact ? (
            <Row>
              <Col lg="6">
                <InputController
                  name="type"
                  label="Job type"
                  control={control}
                  errors={errors}
                  options={radiosJobTypeOptions}
                  input={{ disabled: isLoadingTypes }}
                />
              </Col>
            </Row>
          ) : (
            <InputController
              name="type"
              label="Job type"
              control={control}
              errors={errors}
              radioOptions={radiosJobTypeOptions}
              isRadioSwitch
              input={{ disabled: isLoadingTypes }}
            />
          ))}
        <Loader isLoading={isLoadingTypes} />
      </CardBody>
    </Card>
  );
};
