import {
  DEVICE_DETAIL_COLORS,
  INTERNET_VALUE,
  SATELLITE_VALUE,
} from "../constants";
import { ChartType, ConnectionDatapoint, ConnectionT } from "../types/Common";
import { PositionFixT } from "../types/Mower";
import { IconsId } from "../assets/fonts/iconfont/icons";

export function getDesiredTimestamps(
  limit: number,
  frequency: number,
  now: number
) {
  const start = now - limit;
  const timestamps = [];
  for (let i = start; i <= now; i += frequency) {
    timestamps.push(i);
  }
  return timestamps;
}

export function getIcon(type: ChartType): IconsId {
  switch (type) {
    case ChartType.correction:
      return "compas";
    case ChartType.internet:
      return "signal";
    case ChartType.position:
      return "satellite-pin";
    default:
      return "server";
  }
}

export function getLabel(type: ChartType) {
  switch (type) {
    case ChartType.correction:
      return "Corrections / RTK";
    case ChartType.internet:
      return "Internet";
    case ChartType.position:
      return "Position / GPS";
    case ChartType.server:
      return "Server";
    default:
      return "";
  }
}

export function getConnectionIconColor(
  value: number | null | undefined,
  errorValue: number,
  warningValue: number
) {
  if (value === null || value === undefined) {
    return DEVICE_DETAIL_COLORS.default;
  }
  if (value <= errorValue) {
    return DEVICE_DETAIL_COLORS.error;
  } else if (value <= warningValue) {
    return DEVICE_DETAIL_COLORS.warning;
  } else {
    return DEVICE_DETAIL_COLORS.ok;
  }
}

export function getInternetIcon(value: number | null | undefined): IconsId {
  if (!value) return "signal";
  if (value <= INTERNET_VALUE.error) {
    return "circle-xmark";
  } else if (value <= INTERNET_VALUE.fair) {
    return "signal-fair";
  } else if (value <= INTERNET_VALUE.warning) {
    return "signal-good";
  } else if (value <= INTERNET_VALUE.strong) {
    return "signal-strong";
  } else {
    return "signal";
  }
}

export function newestTimestamp(connection: ConnectionT | undefined) {
  return Math.max(
    connection?.correction.chartData[connection.correction.chartData.length - 1]
      ?.date || 0,
    connection?.internet.chartData[connection.internet.chartData.length - 1]
      ?.date || 0,
    connection?.position.chartData[connection.position.chartData.length - 1]
      ?.date || 0
  );
}

export function anyChartDataPresent(connection: ConnectionT | undefined) {
  // Start charts only if there is more than one datapoint since we sometimes
  // get a single datapoint for the current value and no more
  return (
    connection &&
    (connection?.correction.chartData.length > 1 ||
      connection?.internet.chartData.length > 1 ||
      connection?.position.chartData.length > 1)
  );
}

function changeChartData(
  chartData?: ConnectionDatapoint<number>[] | ConnectionDatapoint<boolean>[],
  errorValue = 0,
  warningValue = 0
) {
  if (!chartData) return;

  return chartData.map((item) => {
    if (typeof item.value === "boolean") {
      return {
        value: 1,
        color: item.value
          ? DEVICE_DETAIL_COLORS.ok
          : DEVICE_DETAIL_COLORS.error,
        time: item.date,
      };
    } else {
      return {
        value: item.value,
        color: getConnectionIconColor(item.value, errorValue, warningValue),
        time: item.date,
      };
    }
  });
}

export function getConnectionChartData(
  label: string,
  connection?: ConnectionT
):
  | {
      chartData:
        | {
            value: number;
            color: string;
            time: number;
          }[]
        | undefined;
      maxValue?: number;
      referenceValue?: number;
    }
  | undefined {
  if (!connection) return;
  switch (label) {
    case "Corrections / RTK": {
      return {
        chartData: changeChartData(connection.correction.chartData),
      };
    }
    case "Internet": {
      return {
        chartData: changeChartData(
          connection.internet.chartData,
          INTERNET_VALUE.error,
          INTERNET_VALUE.warning
        ),
        maxValue: INTERNET_VALUE.max,
        referenceValue: INTERNET_VALUE.error,
      };
    }
    case "Position / GPS": {
      return {
        chartData: changeChartData(
          connection.position.chartData,
          SATELLITE_VALUE.error,
          SATELLITE_VALUE.warning
        ),
        referenceValue: SATELLITE_VALUE.error,
      };
    }
    default:
      return;
  }
}

const PositionLabels: Record<PositionFixT, string> = {
  "rtk-fix": "fix",
  "rtk-float": "float",
  "3D": "3D",
};

export function getPositionFixLabel(fix: PositionFixT | null) {
  if (!fix) {
    return "none";
  }

  return PositionLabels[fix];
}
