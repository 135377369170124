import { JOB_TYPE_IDS } from "../constants";
import { GeostickJobT, JobT } from "../types/Job";
import { JobCreateInputsT } from "../hooks/useMutationCreateJob";

export const getCreateJobApiData = (data: JobCreateInputsT) => {
  const base = {
    type: parseInt(data.type),
    name: data.name,
    dueDate: data.dueDate,
    note: data.note,
    ufonId: parseInt(data.ufonId),
    latitude: data.latitude,
    longitude: data.longitude,
    routeId: data.routeId ? parseInt(data.routeId) : undefined,
  };

  if (base.type === JOB_TYPE_IDS.route) {
    return {
      ...base,
      assign: data.assign.map((item, index) => ({
        ...item,
        mowerId: item.mowerId.filter((id) => id),
        userId: parseInt(item.userId),
        mainUser: index === 0,
      })),
    };
  }

  if (base.type === JOB_TYPE_IDS.manual) {
    return {
      ...base,
      assign: [
        {
          userId: parseInt(data.userId),
          mowerId: [parseInt(data.mowerId)],
          mainUser: true,
        },
      ],
      location: data.location,
    };
  }

  if (base.type === JOB_TYPE_IDS.perimeter) {
    return {
      ...base,
      assign: [
        {
          userId: parseInt(data.userId),
          mowerId: [parseInt(data.mowerId)],
          mainUser: true,
        },
      ],
      location: data.location,
    };
  }

  if (base.type === JOB_TYPE_IDS.survey) {
    return {
      ...base,
      assign: [
        {
          userId: parseInt(data.userId),
          mainUser: true,
        },
      ],
      roverUfonId: parseInt(data.roverUfonId),
    };
  }

  return {};
};

export const createInputsFromJob = (
  job: JobT | GeostickJobT,
  newName: string
): Partial<JobCreateInputsT> => {
  const inputs = {
    name: newName,
    type: job.type.id.toString(),
  };
  if (job.type.id === JOB_TYPE_IDS.route) {
    return {
      ...inputs,
      routeId: job.route?.id.toString(),
      assign: job.users.map((user) => ({
        userId: user.id.toString(),
        mowerId: user.mowers.map((mower) => mower.id.toString()),
      })),
    };
  }
  if (
    job.type.id === JOB_TYPE_IDS.manual ||
    job.type.id === JOB_TYPE_IDS.perimeter
  ) {
    return {
      ...inputs,
      userId: job.users[0].id.toString(),
      mowerId: job.mowers?.[0].id.toString(),
    };
  }
  if (job.type.id === JOB_TYPE_IDS.survey) {
    if (!job.geostick) return inputs;
    return {
      ...inputs,
      ufonId: job.geostick.ufon.id.toString(),
      roverUfonId: job.geostick.roverUfon.id.toString(),
      userId: job.users[0].id.toString(),
    };
  }
  return inputs;
};

export const getEditJobApiData = (data: Partial<JobCreateInputsT>) => {
  const base = {
    name: data.name,
    dueDate: data.dueDate,
    note: data.note,
    latitude: data.latitude,
    longitude: data.longitude,
  };

  if (data.type === JOB_TYPE_IDS.route.toString()) {
    return {
      ...base,
      routeId: data.routeId ? parseInt(data.routeId) : undefined,
      ufonId: data.ufonId ? parseInt(data.ufonId) : null,
      assign:
        data.assign &&
        data.assign.map((item, index) => ({
          ...item,
          userId: parseInt(item.userId),
          mainUser: index === 0,
        })),
    };
  }

  if (data.type === JOB_TYPE_IDS.manual.toString()) {
    return {
      ...base,
      assign: [
        {
          userId: data.userId && parseInt(data.userId),
          mowerId: data.mowerId && [parseInt(data.mowerId)],
          mainUser: true,
        },
      ],
      location: data.location,
    };
  }

  if (data.type === JOB_TYPE_IDS.perimeter.toString()) {
    return {
      ...base,
      assign: [
        {
          userId: data.userId && parseInt(data.userId),
          mowerId: data.mowerId && [parseInt(data.mowerId)],
          mainUser: true,
        },
      ],
      ufonId: data.ufonId ? parseInt(data.ufonId) : null,
      location: data.location,
    };
  }

  if (data.type === JOB_TYPE_IDS.survey.toString()) {
    return {
      ...base,
      assign: [
        {
          userId: data.userId && parseInt(data.userId),
          mainUser: true,
        },
      ],
      ufonId: data.ufonId ? parseInt(data.ufonId) : null,
      roverUfonId: data.roverUfonId ? parseInt(data.roverUfonId) : null,
    };
  }

  return {};
};

export const reduceStringArray = (items: string[]): string =>
  items.reduce((item, acc, index) => {
    if (index !== 0) {
      acc += `,${item}`;
      return acc;
    } else {
      acc += `${item}`;
      return acc;
    }
  }, "");
