import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import React, { FC } from "react";
import { useForm } from "react-hook-form";
import { Form } from "reactstrap";
import { DeviceListItemT } from "../../../types/Common";
import { InputController } from "../Form/InputController";
import { formAssignDeviceSchema } from "./validations";

export type FormAssignDeviceInputsT = {
  deviceId: string;
};

type PropsT = {
  onSubmit: (inputs: FormAssignDeviceInputsT) => void;
  ufons?: DeviceListItemT[];
  mowers?: DeviceListItemT[];
  isLoadingData: boolean;
};

export const FormAssignDevice: FC<PropsT> = ({
  onSubmit,
  ufons,
  mowers,
  isLoadingData,
}) => {
  const mowerIdOptions =
    mowers?.map((mower) => ({
      label: mower.name,
      value: `mower_${mower.id}`,
    })) || [];

  const ufonIdOptions =
    ufons?.map((ufon) => ({
      label: ufon.name,
      value: `ufon_${ufon.id}`,
    })) || [];

  const getDefaultValues = () => ({
    deviceId: "",
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<FormAssignDeviceInputsT>({
    resolver: yupResolver(formAssignDeviceSchema),
    defaultValues: getDefaultValues(),
  });

  return (
    <Form onSubmit={handleSubmit(onSubmit)} id="assign-device-form">
      <InputController
        name="deviceId"
        label="Device"
        control={control}
        errors={errors}
        input={{
          disabled: isLoadingData,
        }}
        options={[
          {
            label: "Select device",
            value: "",
            disabled: true,
          },
          ...mowerIdOptions,
          ...ufonIdOptions,
        ]}
      />
    </Form>
  );
};
