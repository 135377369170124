import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import React, { FC, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { Form } from "reactstrap";
import usePrevious from "../../../hooks/usePrevious";
import { JobCommentT } from "../../../types/Job";
import { InputController } from "../Form/InputController";
import { formMowerSchema } from "./validations";

export type JobCommentInputsT = {
  comment: string;
};

type PropsT = {
  onSubmit: (v: JobCommentInputsT) => void;
  comments?: JobCommentT[];
};

export const FormJobComment: FC<PropsT> = ({ onSubmit, comments }) => {
  const prevComments = usePrevious(comments);

  const defaultValues = useMemo(
    () => ({
      comment: "",
    }),
    []
  );

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm<JobCommentInputsT>({
    resolver: yupResolver(formMowerSchema),
    defaultValues: defaultValues,
  });

  useEffect(() => {
    if (prevComments !== comments) {
      reset(defaultValues);
    }
  }, [prevComments, comments, defaultValues, reset]);

  return (
    <Form
      onSubmit={handleSubmit((values) => onSubmit(values))}
      id="job-comment-form"
    >
      <InputController
        name="comment"
        label="Comment"
        control={control}
        errors={errors}
        input={{ type: "textarea" }}
      />
    </Form>
  );
};
