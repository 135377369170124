import { FC } from "react";
import { MeasurementTriggerT } from "../../../types/Geostick";
import { IconsId } from "../../../assets/fonts/iconfont/icons";
import { Icon } from "../../../components/atoms/Icon";

type PropsT = {
  trigger: MeasurementTriggerT;
  className?: string;
};

const triggerMap: Record<MeasurementTriggerT, IconsId> = {
  button: "crosshairs-location",
  command: "server",
};

export const Trigger: FC<PropsT> = ({ trigger, className }: PropsT) => {
  return <Icon className={className} icon={triggerMap[trigger]} />;
};
