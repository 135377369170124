import React, { FC, useMemo } from "react";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { useForm } from "react-hook-form";
import { Form, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { InputController } from "../../components/molecules/Form/InputController";
import { useMutationUserInvite } from "../../hooks/useMutationUserInvite";
import { inviteUserSchema } from "./validations";
import { StyledButton } from "../../components/atoms/Button/Button";
import { SelectOptionsT } from "../../types/Common";
import { useQueryCompaniesGet } from "../../hooks/useQueries";

type PropsT = {
  isOpen: boolean;
  onClose: () => void;
};

type FormFieldsT = {
  email: string;
  companyId: string;
};

const InviteUserModal: FC<PropsT> = ({ isOpen, onClose }) => {
  const inviteUserMutation = useMutationUserInvite({
    onSuccess: () => onClose(),
  });

  const {
    handleSubmit,
    control,
    formState: { errors, isDirty },
  } = useForm<FormFieldsT>({
    resolver: yupResolver(inviteUserSchema),
    defaultValues: { email: "" },
  });

  const handleInvite = (fields: FormFieldsT) => {
    inviteUserMutation.mutate({
      email: fields.email,
      company: [Number(fields.companyId)],
    });
  };

  const { data: companiesData, isLoading } = useQueryCompaniesGet();

  const selectCompanyOptions = useMemo(
    () =>
      (
        [
          { value: "", label: "Choose company", disabled: true },
        ] as SelectOptionsT
      ).concat(
        (companiesData?.data.items || []).map((company) => ({
          label: company.name,
          value: company.id,
        }))
      ),

    [companiesData]
  );

  return (
    <Modal isOpen={isOpen} toggle={onClose}>
      <ModalHeader toggle={onClose}>Invite user</ModalHeader>
      <ModalBody>
        <Form onSubmit={handleSubmit(handleInvite)} id="invite-user-form">
          <InputController
            name="email"
            label="Email"
            control={control}
            errors={errors}
            input={{
              placeholder: "User email",
            }}
          />
          <InputController
            name="companyId"
            label="Company"
            control={control}
            errors={errors}
            options={selectCompanyOptions}
            input={{
              disabled: isLoading,
            }}
          />
        </Form>
      </ModalBody>
      <ModalFooter>
        <StyledButton color="light" onClick={onClose}>
          Cancel
        </StyledButton>
        <StyledButton
          disabled={!isDirty}
          isLoading={inviteUserMutation.isLoading}
          color="primary"
          type="submit"
          form="invite-user-form"
          className="me-2"
        >
          Send
        </StyledButton>
      </ModalFooter>
    </Modal>
  );
};

export default InviteUserModal;
