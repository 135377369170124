import classNames from "classnames";
import { IconsId } from "../../../assets/fonts/iconfont/icons";
import { useCompactDesign } from "../../../hooks/useCompactDesign";
import { Icon } from "../Icon";
import styles from "./plainButton.module.scss";

type Props = {
  title?: string;
  icon?: IconsId;
  onClick: () => void;
  className?: string;
};

export const PlainButton: React.FC<Props> = ({
  title,
  icon,
  onClick,
  className,
}) => {
  const isCompact = useCompactDesign();

  return (
    <button
      onClick={onClick}
      className={classNames(
        styles["plain-button"],
        isCompact && styles["plain-button--compact"],
        className
      )}
    >
      {icon && <Icon className={title && "me-2"} icon={icon} />}
      {title && title}
    </button>
  );
};
