import styles from "./status.module.scss";
import classNames from "classnames";

type OnlineStatusT = "Online" | "Offline";

type Props = {
  status: OnlineStatusT;
  large?: boolean;
};

export const OnlineStatus: React.FC<Props> = ({ status, large }) => {
  return (
    <div
      className={classNames(styles.status, large && styles["status--large"], {
        [styles["status--active"]]: status === "Online",
      })}
    >
      {status}
    </div>
  );
};
