import { useContext, useRef } from "react";
import { Button, Input } from "reactstrap";
import { StyledButton } from "../../atoms/Button/Button";
import styles from "./mapDropdown.module.scss";
import { DropdownItemConfig } from "../../../types/Common";
import { DropdownContext } from "./dropdownContext";
import { ColorCircle } from "../../atoms/ColorCircle/ColorCircle";
import classNames from "classnames";

type Props = {
  configItem: DropdownItemConfig;
};

export const DropdownItem = ({ configItem }: Props) => {
  const { openSubmenu, setOpenSubmenu } = useContext(DropdownContext);

  const {
    id,
    title,
    selected,
    color,
    onSelect,
    subItems,
    backgroundColor,
    hasSeparator = false,
  } = configItem;

  const currentOpen = useRef<HTMLLIElement>(null);

  return (
    <li
      ref={currentOpen}
      className={classNames(styles.item, {
        [styles["item-separate"]]: hasSeparator,
      })}
      style={backgroundColor ? { backgroundColor } : undefined}
    >
      <div className={styles["item-left"]}>
        <Button className={styles.checkbox} onClick={onSelect}>
          <Input
            className={styles.checkbox}
            readOnly
            type="checkbox"
            checked={selected}
          />
        </Button>
        {color && <ColorCircle className={styles.circle} color={color} />}
        <span className={styles.title}>{title}</span>
      </div>
      {Boolean(subItems) && (
        <StyledButton
          icon="angle-right"
          color="link"
          onClick={(ev) => {
            ev.stopPropagation();
            setOpenSubmenu([...openSubmenu, id]);
          }}
          className={styles["item-chevron"]}
        />
      )}
    </li>
  );
};
