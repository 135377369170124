import { createAction, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UfonConnectionT, UfonStatusT, UfonT } from "../../types/Ufon";
import { LatLngT } from "../../types/Route";
import { objectReducerGenerator } from "../objectReducerGenerator";
import { storeClear } from "../actions";

export type TrackedUfonT = {
  ufon:
    | UfonT
    | {
        id: number;
        name: string;
        latitude?: number;
        longitude?: number;
      }
    | null;
  ufonStatus: UfonStatusT | undefined;
  ufonConnection?: UfonConnectionT;
};
export interface UfonState {
  loading: boolean;
  ufons: Record<number, TrackedUfonT>;
}

const initialState: UfonState = {
  loading: false,
  ufons: {},
};

export const ufonSlice = createSlice({
  name: "ufon",
  initialState,
  reducers: {
    ...objectReducerGenerator<UfonState>(),
    setUfonStatus: (
      state,
      action: PayloadAction<{ ufonStatus: UfonStatusT; ufonId: number }>
    ) => {
      const { ufonId, ufonStatus } = action.payload;
      state.ufons[ufonId] = {
        ...state.ufons[ufonId],
        ufonStatus,
      };
    },
    setUfonData: (
      state,
      action: PayloadAction<{
        ufonData:
          | UfonT
          | {
              id: number;
              name: string;
              latitude?: number;
              longitude?: number;
            };
        ufonId: number;
      }>
    ) => {
      const { ufonId, ufonData } = action.payload;
      state.ufons[ufonId] = {
        ...state.ufons[ufonId],
        ufon: ufonData,
      };
    },
    setUfonConnectionDataAction: (
      state,
      action: PayloadAction<{
        ufonId: number;
        connectionData: UfonConnectionT;
      }>
    ) => {
      const { ufonId, connectionData } = action.payload;
      state.ufons[ufonId] = {
        ...state.ufons[ufonId],
        ufonConnection: connectionData,
      };
    },
    setUfonLocationAction: (
      state,
      { payload }: PayloadAction<{ ufonId: number; currentLocation: LatLngT }>
    ) => {
      const { ufonId, currentLocation } = payload;
      const ufon = state.ufons[ufonId].ufon;
      if (ufon) {
        state.ufons[ufonId].ufon = {
          ...ufon,
          latitude: currentLocation.lat,
          longitude: currentLocation.lng,
        };
      }
    },
    setUfonLoadingAction: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    clearUfonAction: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(storeClear, () => initialState);
  },
});

export const subscribeToUfonConnectionAction = createAction<{
  ufonId: number;
}>("SUBSCRIBE_TO_UFON_CONNECTION");
export type SubscribeToUfonConnectionActionType = ReturnType<
  typeof subscribeToUfonConnectionAction
>;

export const unsubscribeFromUfonConnectionAction = createAction<{
  ufonId: number;
}>("UNSUBSCRIBE_FROM_UFON_CONNECTION");
export type UnsubscribeFromUfonConnectionActionType = ReturnType<
  typeof unsubscribeFromUfonConnectionAction
>;

export const startUfonTrackingAction = createAction<{
  ufonId: number;
}>("START_UFON_TRACKING");
export type StartUfonTrackingActionType = ReturnType<
  typeof startUfonTrackingAction
>;

export const stopUfonTrackingAction = createAction<{
  ufonId: number;
}>("START_UFON_TRACKING");
export type StopUfonTrackingActionType = ReturnType<
  typeof stopUfonTrackingAction
>;

export const {
  setUfonStatus,
  setUfonData,
  setUfonConnectionDataAction,
  setUfonLocationAction,
  setUfonLoadingAction,
  clearUfonAction,
} = ufonSlice.actions;
