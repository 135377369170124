import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { client } from "../services/axios";
import { MutationOptionsT } from "../types/ReactQuery";
import { CoordinateT } from "../types/Route";

export const useMutationEditRouteCoordinates = (
  routeId: string | number,
  options?: MutationOptionsT
) =>
  useMutation(
    (newCoordinates: CoordinateT<"route">[]) => {
      const updatedCoordinates = [];
      for (const coordinate of newCoordinates) {
        updatedCoordinates.push({
          id: coordinate.id,
          areaType: coordinate.styleType,
          coordinates: coordinate.coordinates,
        });
      }

      return client.put(
        `/api/v1/route/${routeId}/coordinates`,
        updatedCoordinates
      );
    },
    {
      onSuccess: () => {
        toast.success("Coordinates have been updated");
        if (options?.onSuccess) {
          options.onSuccess();
        }
      },
      onError: () => {
        toast.error("Coordinates update failed");
        if (options?.onError) {
          options.onError();
        }
      },
    }
  );
