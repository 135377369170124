import React, { FC, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate, useParams } from "react-router";
import { useDispatch } from "react-redux";
import { CoordinatesT } from "../../types/Route";
import { WINDOW_TITLE_NAMES } from "../../constants";
import { ROUTES } from "../../routes";
import { useQueryMowerListGet, useQueryRouteGet } from "../../hooks/useQueries";
import { useMutationEditRouteCoordinates } from "../../hooks/useMutationEditRouteCoordinates";
import { useMutationRouteDelete } from "../../hooks/useMutationRouteDelete";
import { usePermissions } from "../../hooks/usePermissions";
import { useMutationArchiveRoute } from "../../hooks/useMutationArchiveRoute";
import { useMutationRestoreRoute } from "../../hooks/useMutationRestoreRoute";
import { Layout } from "../../components/organisms/Layout/Layout";
import Map from "../../components/organisms/Map/Map";
import TabsWithMapLayout from "../../components/organisms/TabsWithMapLayout/TabsWithMapLayout";
import { CardRouteLayers } from "../../components/organisms/CardRouteLayers/CardRouteLayers";
import { useConfirm } from "../../components/molecules/ConfirmBoxProvider/ConfirmBoxProvider";
import { CardRouteInfo } from "../../components/atoms/CardRouteInfo/CardRouteInfo";
import SubHeader from "../../components/atoms/SubHeader/SubHeader";
import { StyledButton } from "../../components/atoms/Button/Button";
import { ArchiveButton } from "../../components/atoms/ArchiveButton/ArchiveButton";
import { RouteChangeModal } from "../Routes/RouteChangeModal";
import { startMowerTrackingAction } from "../../store/slices/mowerSlice";

export const RoutePage: FC = () => {
  document.title = WINDOW_TITLE_NAMES.routes;
  const { routeId } = useParams() as { routeId: string };
  const permissions = usePermissions();
  const navigate = useNavigate();
  const [showEditActions, setShowEditActions] = useState<boolean>(false);
  const [routeCoordinates, setRouteCoordinates] =
    useState<CoordinatesT<"route">>();
  const { data: route, isLoading, refetch } = useQueryRouteGet(routeId);
  const [activeJobs, setActiveJobs] = useState<number[]>([]);
  const [showChangeRouteModal, setShowChangeRouteModal] = useState(false);
  const jobsOnRouteWithJourney = route?.data.jobs.filter(
    (job) => job.journeys.length > 0
  );
  const dispatch = useDispatch();

  const confirm = useConfirm();

  useEffect(() => {
    if (route?.data.coordinateData) {
      setRouteCoordinates(route.data.coordinateData);
    }
  }, [route]);

  const editRouteMutation = useMutationEditRouteCoordinates(routeId, {
    onSuccess: () => {
      refetch();
      setShowEditActions(false);
    },
  });

  const deleteRouteMutation = useMutationRouteDelete({
    onSuccess: () => {
      navigate(ROUTES.routes());
    },
  });

  const onDelete = () => {
    confirm({
      title: "Remove route",
      subTitle: "Are you sure you want to delete this route?",
      asyncCallback: () => deleteRouteMutation.mutateAsync(routeId),
      type: "delete",
    });
  };

  const onCoordinatesChange = (updatedCoordinates: CoordinatesT<"route">) => {
    setShowEditActions(true);
    setRouteCoordinates(updatedCoordinates);
  };

  const handleCancelRouteChanges = () => {
    window.location.reload();
  };

  const handleSaveRouteChanges = () => {
    if (routeCoordinates) {
      const updatedCoordinates = routeCoordinates.coordinates.map(
        (routeData) => routeData
      );
      confirm({
        title: "Edit route",
        subTitle: "Do you want to edit this route?",
        asyncCallback: () => editRouteMutation.mutateAsync(updatedCoordinates),
        type: "ok",
      });
    }
  };

  const archiveJobMutation = useMutationArchiveRoute({
    onSuccess: () => {
      refetch();
    },
  });

  const restoreJobMutation = useMutationRestoreRoute({
    onSuccess: () => {
      refetch();
    },
  });

  const handleArchiveJob = () => {
    archiveJobMutation.mutateAsync(routeId);
  };

  const handleRestoreJob = () => {
    restoreJobMutation.mutateAsync(routeId);
  };

  const handleLayerSelect = (ids: number[]) => {
    let newArr = [...activeJobs];
    for (const id of ids) {
      if (newArr.includes(id)) {
        newArr = newArr.filter((layer) => layer !== id);
      } else {
        newArr.push(id);
      }
    }
    setActiveJobs(newArr);
  };

  const { data: mowerList } = useQueryMowerListGet();
  const onlineMowers = mowerList?.data.items.filter((mower) => mower.isOnline);
  useEffect(() => {
    if (!onlineMowers) {
      return;
    }

    onlineMowers.forEach((mower) => {
      dispatch(startMowerTrackingAction({ mowerId: mower.id }));
    });

    return () => {
      dispatch({ type: "STOP_MOWER_TRACKING" }); // currently the mower tracking being stopped does not differentiate between mowers
    };
  }, [onlineMowers, dispatch]);

  return (
    <Layout>
      <SubHeader
        title={route?.data.name || `Route ${routeId}`}
        isDetail
        actions={
          <>
            {showEditActions && permissions.routeManage && (
              <>
                <StyledButton
                  onClick={handleCancelRouteChanges}
                  disabled={route?.data.archived}
                  color="dark"
                  outline
                  title="Cancel"
                />
                <StyledButton
                  onClick={handleSaveRouteChanges}
                  disabled={route?.data.archived}
                  color="primary"
                  title="Save"
                />
              </>
            )}
            {!showEditActions && permissions.routeManage && (
              <>
                <StyledButton
                  icon="play"
                  disabled={route?.data.archived}
                  onClick={() =>
                    navigate(
                      `${ROUTES.jobCreate()}?type=2&route=${routeId}${
                        route ? `&folder=${route.data.folderId}` : ""
                      }`
                    )
                  }
                  color="dark"
                  outline
                  title={"Create job"}
                  className="me-2"
                  onlyIconOnMobile
                />
                <StyledButton
                  icon="pen"
                  disabled={route?.data.archived}
                  color="dark"
                  outline
                  onClick={() => setShowEditActions(true)}
                />
                <StyledButton
                  icon="code-branch"
                  disabled={route?.data.archived}
                  color="dark"
                  outline
                  onClick={() => setShowChangeRouteModal(true)}
                />
                <StyledButton
                  icon="input-text-light"
                  disabled={route?.data.archived}
                  color="dark"
                  outline
                  tag={Link}
                  link={ROUTES.routeEdit(routeId)}
                />
                <ArchiveButton
                  disabled={!route}
                  isArchived={route?.data.archived || false}
                  onArchive={handleArchiveJob}
                  onRestore={handleRestoreJob}
                  color="dark"
                  outline
                  onlyIcon
                />
                <StyledButton
                  onClick={onDelete}
                  disabled={route?.data.archived}
                  icon="trash"
                  color="dark"
                  outline
                />
              </>
            )}
          </>
        }
      />
      <TabsWithMapLayout
        tabName="Route detail"
        mapComponent={
          <Map
            isLoading={isLoading}
            onCoordinatesChange={
              route && !route.data.archived ? onCoordinatesChange : undefined
            }
            fitBounds={true}
            mapType="satellite"
            zoom={8}
            selectedJourneys={activeJobs}
            coordinates={routeCoordinates}
            center={{
              lat: 50.0807843861,
              lng: 14.715616947,
            }}
            showEditActions={showEditActions}
            onlineMowers={onlineMowers}
          />
        }
      >
        {route?.data && (
          <div className="d-flex flex-column gap-4">
            <CardRouteInfo data={route?.data} />
            {jobsOnRouteWithJourney && jobsOnRouteWithJourney.length > 0 && (
              <CardRouteLayers
                jobs={jobsOnRouteWithJourney}
                selectedJourneys={activeJobs}
                onJourneySelect={handleLayerSelect}
              />
            )}
          </div>
        )}
      </TabsWithMapLayout>
      {showChangeRouteModal && route && (
        <RouteChangeModal
          route={route.data}
          isOpen={showChangeRouteModal}
          onClose={() => setShowChangeRouteModal(false)}
          refetch={refetch}
        />
      )}
    </Layout>
  );
};
