import { object, mixed, string } from "yup";
import { VALIDATIONS } from "../../../constants";

export const formRouteSchema = (editingMode: boolean) =>
  object({
    name: string().required(VALIDATIONS.required),
    group: editingMode ? string() : string().required(VALIDATIONS.required),
    file: editingMode ? mixed() : mixed().required(VALIDATIONS.required),
  }).required();

export const updateRouteSchema = () =>
  object({
    route: string().required(VALIDATIONS.required),
    file: mixed().required(VALIDATIONS.required),
  });

export const formRouteCloneSchema = (editingMode: boolean) =>
  object({
    name: string().required(VALIDATIONS.required),
    group: editingMode ? string() : string().required(VALIDATIONS.required),
  }).required();

export const formFromJobSchema = () =>
  object({
    name: string().required(VALIDATIONS.required),
    folderId: string().required(VALIDATIONS.required),
  }).required();
