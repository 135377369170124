import { useRef } from "react";
import classNames from "classnames";
import { Link, useNavigate } from "react-router-dom";
import { queryClient } from "../../..";
import { client } from "../../../services/axios";
import { ROUTES } from "../../../routes";
import { RouteT } from "../../../types/Route";
import { useSearchParams } from "../../../hooks/useSearchParam";
import { usePermissions } from "../../../hooks/usePermissions";
import { useObserveHeight } from "../../../hooks/useObserveHeight";
import { useCompactDesign } from "../../../hooks/useCompactDesign";
import { useMutationRestoreRoute } from "../../../hooks/useMutationRestoreRoute";
import { useMutationArchiveRoute } from "../../../hooks/useMutationArchiveRoute";
import { ActionButton } from "../../atoms/ActionButton/ActionButton";
import { ActionDivider } from "../../atoms/ActionDivider/ActionDivider";
import { Icon } from "../../atoms/Icon";
import { useConfirm } from "../ConfirmBoxProvider/ConfirmBoxProvider";
import styles from "./gridRow.module.scss";

type Props = {
  data: RouteT;
  onDelete?: (item: RouteT) => void;
  isLeica: boolean;
};

export const RouteGridRow: React.FC<Props> = ({ data, onDelete, isLeica }) => {
  const permissions = usePermissions();
  const { getParamValue } = useSearchParams();
  const navigate = useNavigate();
  const confirm = useConfirm();
  let folderId = getParamValue("folderId");
  const showArchive = getParamValue("showArchive");
  const copyRouteLink = ROUTES.routeClone();
  const copyLeicaLink = ROUTES.leicaCreate();
  const restoreRoute = useMutationRestoreRoute({
    onSuccess: () => {
      queryClient.invalidateQueries(["route"]);
    },
  });
  const archiveRoute = useMutationArchiveRoute({
    onSuccess: () => {
      queryClient.invalidateQueries(["route"]);
    },
  });
  const isCompact = useCompactDesign();

  const rowRef = useRef<HTMLTableRowElement>(null);
  const linkHeight = useObserveHeight(rowRef);

  const onCopy = () => {
    if (isLeica) {
      confirm({
        title: "Copy correction station point",
        subTitle: "Create copy of this correction station point?",
        callback: () => navigate(`${copyLeicaLink}?leicaId=${data.id}`),
        type: "ok",
      });
    } else {
      confirm({
        title: "Copy route",
        subTitle: "Create copy of this route?",
        callback: () => navigate(`${copyRouteLink}?routeId=${data.id}`),
        type: "ok",
      });
    }
  };

  const handleDelete = () => {
    onDelete?.(data);
  };

  const restoreClick = async () => {
    restoreRoute.mutate(data.id);
  };

  const archiveClick = async () => {
    archiveRoute.mutate(data.id);
  };

  const link = isLeica
    ? ROUTES.leica(data.id, data.name)
    : ROUTES.route(data.id);

  const handleEdit = () => {
    const editLink = ROUTES.routeEdit(data.id);
    navigate(editLink);
  };

  const handleNewJob = async () => {
    if (!folderId) {
      const result: { data: RouteT } = await client.get(
        `/api/v1/route/${data.id}`
      );
      folderId = result.data.folderId.toString();
    }
    navigate(
      `${ROUTES.jobCreate()}?type=2&route=${data.id}&folder=${folderId}`
    );
  };

  return (
    <tr
      ref={rowRef}
      className={isCompact ? styles["grid-row"] : styles["grid-row--large"]}
    >
      <td
        className={classNames(
          styles["grid-row__cell"],
          isCompact && styles["grid-row__cell--compact"],
          styles["grid-row__cell--head"],
          isCompact && styles["grid-row__cell--head--compact"],
          !isCompact && styles["grid-row__cell--head-route-title--large"]
        )}
      >
        <span className={styles["grid-row__cell-head-link"]}>
          {data.name}
          {isCompact && (
            <Icon
              icon={"angle-right"}
              className={styles["grid-row__cell-head-icon"]}
            />
          )}
        </span>
      </td>
      <td
        className={classNames(
          styles["grid-row__cell"],
          isCompact && styles["grid-row__cell--compact"],
          styles["grid-row__cell--head"],
          isCompact && styles["grid-row__cell--head--compact"],
          styles["grid-row__cell--12"]
        )}
      >
        {data.id}
        {permissions.routeManage && !showArchive && (
          <div className={styles.actions}>
            <ActionButton
              onClick={handleNewJob}
              icon="play-circle"
              tagTitle="Create job"
            />
            <ActionDivider />
            <ActionButton onClick={onCopy} icon="clone" tagTitle="Copy" />
            <ActionButton
              onClick={() => navigate(ROUTES.routeChange(data.id))}
              icon="code-branch"
              tagTitle="Change"
            />
            <ActionButton
              onClick={handleEdit}
              icon="input-text-light"
              tagTitle="Edit"
            />
            <ActionButton
              onClick={archiveClick}
              icon="archive"
              tagTitle="Archive"
            />
            <ActionButton
              onClick={handleDelete}
              icon="trash"
              tagTitle="Delete"
            />
          </div>
        )}
        {permissions.routeManage && showArchive && (
          <div className={styles.actions}>
            <ActionButton
              onClick={restoreClick}
              icon="trash-undo"
              tagTitle="Unarchive"
            />
          </div>
        )}
      </td>
      <td className={styles.superLinkCell} style={{ height: linkHeight }}>
        <Link to={link} className={styles.superLink} />
      </td>
    </tr>
  );
};
