import { FC, MouseEventHandler } from "react";
import { Icon } from "../Icon";
import styles from "./selectedTag.module.scss";
import classNames from "classnames";

type PropsT = {
  name: string;
  onRemove: MouseEventHandler<HTMLSpanElement>;
  className?: string;
};

export const SelectedTag: FC<PropsT> = ({
  name,
  onRemove,
  className,
}: PropsT) => {
  return (
    <div className={classNames(styles.tag, className)}>
      <div>{name}</div>
      <Icon className={styles.tagClose} icon="times" onClick={onRemove} />
    </div>
  );
};
