import { FC, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router";
import { GoogleMap, GoogleMapProps } from "@react-google-maps/api";
import { DEFAULT_SHOW_ACTIVITY_TYPES } from "../../../constants";
import { JobT } from "../../../types/Job";
import { onLoad } from "../../../functions/onMapLoad";
import {
  getBoundsFromJourneys,
  getSingleBound,
} from "../../../functions/getBounds";
import { ROUTES } from "../../../routes";
import { JobMapInfo, ShowActivityTypesT } from "../../../types/Route";
import { useUserLocation } from "../../../hooks/useUserLocation";
import { useQueriesJourneyGet } from "../../../hooks/useQueries";
import { useSelector } from "../../../store/hooks";
import JobMapInfoWindow from "../../../components/molecules/MapInfoWindow/JobMapInfoWindow";
import MapControls from "../../../components/molecules/MapControls/MapControls";
import UserMapMarker from "../../../components/atoms/UserMapMarker/UserMapMarker";
import { Loader } from "../../../components/atoms/Loader/Loader";
import MowerMarker from "../../../components/atoms/MapMarkers/MowerMarker";
import DropdownComponent from "./DropdownComponent";
import MapMowerTracker from "./MapMowerTracker";
import styles from "./manualMap.module.scss";

export interface JobWithRouteMapProps extends GoogleMapProps {
  job: JobT;
  journeyIds: number[];
  mowerId: number;
  jobId: string;
  isLoading: boolean;
}

const containerStyle = {
  height: "100%",
};

const JobManualMap: FC<JobWithRouteMapProps> = ({
  job,
  journeyIds,
  mowerId,
  center,
  jobId,
  isLoading,
  ...restProps
}) => {
  const mapRef = useRef<GoogleMap>(null);
  const navigate = useNavigate();

  const [journeys] = useQueriesJourneyGet(journeyIds);

  const [activeMowerMarker, setActiveMowerMarker] = useState<
    JobMapInfo | undefined
  >(undefined);

  const [showMowingArrows, setShowMowingArrows] = useState(false);
  const [activityChecked, setActivityChecked] = useState(true);
  const [activityTypes, setActivityTypes] = useState<ShowActivityTypesT>(
    DEFAULT_SHOW_ACTIVITY_TYPES
  );

  const resetLayers = () => {
    setActivityTypes(DEFAULT_SHOW_ACTIVITY_TYPES);
    setShowMowingArrows(false);
    setActivityChecked(true);
  };

  const { userLocation, mapCenter, setMapCenter, handleUserLocation } =
    useUserLocation(center, mapRef);

  useEffect(() => {
    setMapCenter(center);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [center]);

  const mowers = useSelector((state) => state.mower.mowers);
  const currentMowerLocation = useMemo(
    () =>
      job &&
      job.mowers[0] &&
      mowers[job.mowers[0].id] &&
      mowers[job.mowers[0].id].currentLocation,
    [job, mowers]
  );

  const handleJobLocation = () => {
    if (!mapRef.current) {
      return;
    }
    if (journeys.length > 0) {
      const bounds = getBoundsFromJourneys(journeys);
      mapRef.current?.state.map?.fitBounds(bounds);
    } else if (currentMowerLocation) {
      const bounds = getSingleBound(currentMowerLocation);
      mapRef.current?.state.map?.fitBounds(bounds);
    } else if (center) {
      const bounds = getSingleBound({
        lat: typeof center.lat === "number" ? center.lat : center.lat(),
        lng: typeof center.lng === "number" ? center.lng : center.lng(),
      });
      mapRef.current?.state.map?.fitBounds(bounds);
    }
  };

  return (
    <div className={styles.map}>
      {isLoading && (
        <div className={styles.loader}>
          <Loader />
        </div>
      )}
      <GoogleMap
        ref={mapRef}
        center={mapCenter}
        zoom={12}
        tilt={0}
        mapContainerStyle={containerStyle}
        mapTypeId={google.maps.MapTypeId.SATELLITE}
        onLoad={onLoad}
        {...restProps}
      >
        {userLocation && <UserMapMarker userLocation={userLocation} />}
        <MapControls
          dropdownComponent={
            <DropdownComponent
              showMowingArrows={showMowingArrows}
              setShowMowingArrows={() => setShowMowingArrows((prev) => !prev)}
              activityChecked={activityChecked}
              handleCheckActivity={() => setActivityChecked((prev) => !prev)}
              activityTypes={journeyIds.length > 0 ? activityTypes : undefined}
              setActivityType={(
                type: keyof ShowActivityTypesT,
                value: boolean
              ) =>
                setActivityTypes((x) => {
                  return { ...x, [type]: value };
                })
              }
              resetLayers={resetLayers}
            />
          }
          handleUserLocation={handleUserLocation}
          handleHomeLocation={handleJobLocation}
        />
        <MapMowerTracker
          mowerId={mowerId}
          isDirectionShowing={showMowingArrows}
          showActivity={activityChecked}
          job={job}
          journeyIds={journeyIds}
        />
        <MowerMarker
          mowerId={mowerId}
          showMarker={activityChecked}
          setActiveMarker={setActiveMowerMarker}
          job={job}
        />
        {activeMowerMarker && (
          <JobMapInfoWindow
            onClose={() => setActiveMowerMarker(undefined)}
            onClick={() => navigate(ROUTES.mower(activeMowerMarker.deviceId))}
            mowerId={activeMowerMarker.deviceId}
            icon="mower"
            title={activeMowerMarker.name}
            position={{
              lat: activeMowerMarker.lat,
              lng: activeMowerMarker.lng,
            }}
          />
        )}
      </GoogleMap>
    </div>
  );
};

export default JobManualMap;
