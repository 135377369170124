import { DATE_FORMATS, JOB_TYPE_IDS } from "../constants";
import { ROUTES } from "../routes";
import { PermissionNamesT } from "../types/Common";
import { JobTypeNamesEnum, JobTypesIdsT } from "../types/Job";
import { formatDate } from "./formatDate";

export function createQueryString(
  params: Record<string, Date | string | number | null | undefined>
) {
  const query = new URLSearchParams();
  Object.entries(params).forEach(([key, value]) => {
    if (value !== undefined && value !== null && value !== "") {
      query.set(
        key,
        value instanceof Date
          ? formatDate(value, `${DATE_FORMATS.dateBackend}`) +
              "T" +
              formatDate(value, "HH:mm:ssxxx")
          : String(value)
      );
    }
  });
  const queryString = query.toString();
  return queryString ? "?" + queryString : "";
}

export function getJobLink(
  type: JobTypeNamesEnum,
  jobId: number | string,
  usersIds: number[] | string[],
  permissions?: Partial<Record<PermissionNamesT, boolean>>
) {
  if (permissions?.jobUsersAll && usersIds.length > 1) {
    return ROUTES.jobSignpost(jobId);
  }
  switch (type) {
    case JobTypeNamesEnum.Manual:
      return ROUTES.jobManual(jobId);
    case JobTypeNamesEnum.Perimeter:
      return ROUTES.job(jobId, usersIds[0]);
    case JobTypeNamesEnum.Route:
      return ROUTES.jobRouteType(jobId, usersIds[0]);
    case JobTypeNamesEnum.Survey:
      return ROUTES.jobGeostick(jobId);
    case JobTypeNamesEnum.Auto:
      return ROUTES.job(jobId, usersIds[0]);
    default:
      return ROUTES.job(jobId, usersIds[0]);
  }
}

export function getJobLinkFromJobType(
  jobTypeId: JobTypesIdsT,
  jobId: number | string,
  userIds: number[] | string[],
  permissions?: Partial<Record<PermissionNamesT, boolean>>
) {
  const getType: Record<JobTypesIdsT, JobTypeNamesEnum> = {
    [JOB_TYPE_IDS.manual]: JobTypeNamesEnum.Manual,
    [JOB_TYPE_IDS.perimeter]: JobTypeNamesEnum.Perimeter,
    [JOB_TYPE_IDS.route]: JobTypeNamesEnum.Route,
    [JOB_TYPE_IDS.auto]: JobTypeNamesEnum.Auto,
    [JOB_TYPE_IDS.survey]: JobTypeNamesEnum.Survey,
  };
  if (!(jobTypeId in getType)) {
    throw new Error(`Job type ${jobTypeId} not found`);
  }
  return getJobLink(getType[jobTypeId], jobId, userIds, permissions);
}
