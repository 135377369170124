import React, { FC, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Container } from "reactstrap";
import { StyledButton } from "../../components/atoms/Button/Button";
import { PageWrapper } from "../../components/atoms/PageWrapper/PageWrapper";
import SubHeader from "../../components/atoms/SubHeader/SubHeader";
import { FormUfon } from "../../components/molecules/FormUfon/FormUfon";

import { Layout } from "../../components/organisms/Layout/Layout";
import { WINDOW_TITLE_NAMES } from "../../constants";
import { useMutationUfonCreate } from "../../hooks/useMutationUfonCreate";
import { useQueryUfonGet } from "../../hooks/useQueries";
import { DeviceFormInput } from "../../types/Common";

type PropsT = {};

export const UfonCreatePage: FC<PropsT> = () => {
  document.title = WINDOW_TITLE_NAMES.devices;
  const [isFormTouched, setIsFormTouched] = useState(false);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const ufonId = searchParams.get("ufonId");
  const { data: ufonData, isLoading } = useQueryUfonGet(ufonId);

  const createUfonMutation = useMutationUfonCreate();

  const handleSubmit = (values: DeviceFormInput) => {
    createUfonMutation.mutate(values);
  };

  return (
    <Layout>
      <SubHeader
        isDetail
        title="New correction station"
        actions={
          <>
            <StyledButton
              onClick={() => navigate(-1)}
              color="dark"
              outline
              title="Cancel"
            />
            <StyledButton
              disabled={!isFormTouched}
              color="primary"
              type="submit"
              form="ufon-form"
              isLoading={createUfonMutation.isLoading}
            >
              {!createUfonMutation.isLoading ? "Create" : "Saving"}
            </StyledButton>
          </>
        }
      />
      <PageWrapper>
        <Container>
          <FormUfon
            onTouch={() => setIsFormTouched(true)}
            onSubmit={handleSubmit}
            defaultValues={ufonData?.data}
            isDisabledAll={isLoading}
          />
        </Container>
      </PageWrapper>
    </Layout>
  );
};
