import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { RouteInputsT } from "../components/molecules/FormRoute/FormRoute";
import { client } from "../services/axios";
import { ApiErrorT } from "../types/Common";

export const useMutationEditRoute = (routeId: string | number) =>
  useMutation(
    (data: RouteInputsT) => {
      return client.put(`/api/v1/route/${routeId}`, {
        name: data.name,
        areaFolder: Number(data.group),
      });
    },
    {
      onSuccess: () => {
        toast.success("Route has been updated");
      },
      onError: (error: ApiErrorT) => {
        toast.error(error?.message);
      },
    }
  );
