import React, { FC } from "react";
import { useDispatch } from "react-redux";
import { Button, Modal, ModalBody } from "reactstrap";
import { updateTaskAction } from "../../../store/slices/tasksSlice";
import Title from "../Title/Title";

type PropsT = {
  isOpen: boolean;
  mowerId: number | null;
  jobId: string;
  onClose: () => void;
};

export const TaskMowingDialog: FC<PropsT> = ({
  isOpen,
  mowerId,
  jobId,
  onClose,
}) => {
  const dispatch = useDispatch();

  const handleClick = () => {
    if (!mowerId) {
      throw new Error("Missing mowerId!");
    }
    dispatch(
      updateTaskAction({
        jobId,
        type: "startMowing",
        newStatus: "start",
        options: {
          mowerId,
        },
      })
    );
    onClose();
  };

  return (
    <Modal isOpen={isOpen} toggle={onClose}>
      <ModalBody>
        <Title type="h3">Do you want to upload route to mower?</Title>
        <div className="d-flex">
          <Button
            color="primary"
            size="sm"
            className="me-2 mb-2"
            onClick={handleClick}
          >
            Yes
          </Button>
          <div>
            <Button onClick={onClose} size="sm">
              No
            </Button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};
