import React, { FC, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { Container } from "reactstrap";
import { StyledButton } from "../../components/atoms/Button/Button";
import { PageWrapper } from "../../components/atoms/PageWrapper/PageWrapper";
import SubHeader from "../../components/atoms/SubHeader/SubHeader";
import { FormMower } from "../../components/molecules/FormMower/FormMower";

import { Layout } from "../../components/organisms/Layout/Layout";
import { WINDOW_TITLE_NAMES } from "../../constants";
import { useMutationEditMower } from "../../hooks/useMutationEditMower";
import { useQueryMowerGet } from "../../hooks/useQueries";
import { DeviceFormInput } from "../../types/Common";

export const MowerEditPage: FC = () => {
  document.title = WINDOW_TITLE_NAMES.devices;
  const [isFormTouched, setIsFormTouched] = useState(false);
  const { mowerId } = useParams();
  const navigate = useNavigate();
  const { data: mowerData, isLoading } = useQueryMowerGet(mowerId as string);
  const editMowerMutation = useMutationEditMower(mowerId as string);

  const handleSubmit = (values: DeviceFormInput) => {
    editMowerMutation.mutate(values);
  };

  return (
    <Layout>
      <SubHeader
        isDetail
        title={mowerData?.data.name}
        actions={
          <>
            <StyledButton
              onClick={() => navigate(-1)}
              color="dark"
              outline
              title="Cancel"
            />
            <StyledButton
              disabled={!isFormTouched}
              color="primary"
              type="submit"
              form="mower-form"
              isLoading={editMowerMutation.isLoading}
            >
              {!editMowerMutation.isLoading ? "Save" : "Saving"}
            </StyledButton>
          </>
        }
      />
      <PageWrapper>
        <Container>
          <FormMower
            onTouch={() => setIsFormTouched(true)}
            onSubmit={handleSubmit}
            defaultValues={mowerData?.data}
            isDisabledAll={isLoading}
          />
        </Container>
      </PageWrapper>
    </Layout>
  );
};
