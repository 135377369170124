import { Modal, ModalFooter, ModalHeader } from "reactstrap";
import { ChartData, ChartType } from "../../../types/Common";
import ConnectionChart from "../../organisms/ConnectionChart/ConnectionChart";
import { DatePicker } from "../DatePicker/DatePicker";
import HorizontalDivider from "../../atoms/Divider/HorizontalDivider";
import { StyledButton } from "../../atoms/Button/Button";
import { DATE_FORMATS } from "../../../constants";
import styles from "./chartModal.module.scss";

export type ModalConfig = {
  title: string;
  onRangeSelected: VoidFunction;
  from: Date;
  to: Date;
  chartData?: ChartData[];
  referenceLine?: number;
  chartType: ChartType;
};

type Props = {
  isOpen?: boolean;
  onClose: () => void;
  config?: ModalConfig;
};

export default function ChartModal({ isOpen, onClose, config }: Props) {
  if (!config) return null;

  const withYAxis =
    config.chartType === ChartType.internet ||
    config.chartType === ChartType.position;
  return (
    <Modal
      size="xl"
      fullscreen="lg"
      isOpen={isOpen}
      toggle={onClose}
      backdropClassName={styles.backdropModal}
      contentClassName={styles.modal}
    >
      <div className={styles.modalWrapper}>
        <ModalHeader toggle={onClose} className={styles.modalHeader}>
          {config.title}
        </ModalHeader>
        <div className={styles.modalBody}>
          <div className="p-4">
            <DatePicker
              startDatetime={config.from}
              endDatetime={config.to}
              onRangeSelected={config.onRangeSelected}
              displayTime={true}
              maxDate={new Date()}
              displayCommonRangeNames={false}
              dateFormat={DATE_FORMATS.dateTimeMedium}
            />
          </div>
          <HorizontalDivider />
          <div className="p-4">
            <ConnectionChart
              data={config.chartData}
              referenceLine={config.referenceLine}
              height={400}
              chartType={config.chartType}
              yAxis={withYAxis}
              xAxis
              withLegend
            />
          </div>
        </div>
        <ModalFooter className={styles.modalHeader}>
          <StyledButton color="light" onClick={onClose} wide>
            Close
          </StyledButton>
        </ModalFooter>
      </div>
    </Modal>
  );
}
