import { FC } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import { Control, FormState } from "react-hook-form";
import { InputController } from "../Form/InputController";
import { JobCreateFormFieldsT } from "./FormJobCreate";

type PropsT = {
  control: Control<JobCreateFormFieldsT, object>;
  formState: FormState<JobCreateFormFieldsT>;
  isDisabledAll?: boolean;
};

export const FormLocationCard: FC<PropsT> = ({
  control,
  formState,
  isDisabledAll,
}: PropsT) => {
  const { errors } = formState;
  return (
    <Card>
      <CardBody>
        <Row>
          <Col lg="6">
            <InputController
              id="location"
              name="location"
              label="Location"
              placeholder="Location name"
              control={control}
              errors={errors}
              input={{ disabled: isDisabledAll }}
            />
          </Col>
        </Row>
        <Row>
          <Col lg="3">
            <InputController
              id="latitude"
              name="latitude"
              label="Latitude"
              placeholder="Latitude coordinate"
              control={control}
              errors={errors}
              input={{
                disabled: isDisabledAll,
                type: "number",
                step: "any",
              }}
            />
          </Col>
          <Col lg="3">
            <InputController
              id="longitude"
              name="longitude"
              label="Longitude"
              placeholder="Longitude coordinate"
              control={control}
              errors={errors}
              input={{
                disabled: isDisabledAll,
                type: "number",
                step: "any",
              }}
            />
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};
