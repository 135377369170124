import React, { FC } from "react";
import { useForm } from "react-hook-form";
import { Form } from "reactstrap";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { InputController } from "../../components/molecules/Form/InputController";
import { formLostPassword } from "./validations";

export type FormLostPasswordFieldsT = {
  email: string;
};

type PropsT = {
  isDisabled?: boolean;
  onSubmit: (inputs: FormLostPasswordFieldsT) => void;
};

export const FormLostPassword: FC<PropsT> = ({ isDisabled, onSubmit }) => {
  const getDefaultValues = () => ({
    email: "",
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<FormLostPasswordFieldsT>({
    resolver: yupResolver(formLostPassword),
    defaultValues: getDefaultValues(),
  });

  return (
    <Form onSubmit={handleSubmit(onSubmit)} id="lost-password-form" inline>
      <InputController
        name="email"
        control={control}
        errors={errors}
        input={{
          disabled: isDisabled,
          placeholder: "Recovery email",
          bsSize: "lg",
          type: "email",
        }}
        formGroup={{
          className: "mb-2",
        }}
      />
    </Form>
  );
};
