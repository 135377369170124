import React, { FC } from "react";
import { Card, CardBody } from "reactstrap";
import { CardHeader } from "../../atoms/CardHeader/CardHeader";
import { Icon } from "../../atoms/Icon";
import { useSelector } from "../../../store/hooks";
import { getJobStatus } from "./utils";
import styles from "./jobStatusCard.module.scss";

interface PropsT {
  onClick?: VoidFunction;
  mowerId?: number;
  segmentId?: number;
}

export const JobStatusCard: FC<PropsT> = React.memo(
  ({ onClick, mowerId, segmentId }) => {
    const jobProgress = useSelector((store) => store.jobRouteType.jobProgress);

    const jobStatus = getJobStatus(jobProgress, mowerId, segmentId);

    if (!jobStatus) {
      return null;
    }

    return (
      <Card>
        <CardBody>
          <CardHeader
            title="Status"
            withProgress={{ value: jobStatus.donePercent }}
            onClick={onClick}
          >
            <Icon icon="chevron-right" className={styles.chevron} />
          </CardHeader>
          <div className={styles.status}>
            <div className={styles["status-item"]}>
              <p className={styles["text-gray"]}>Remaining</p>
              <p className={styles["text-black"]}>
                {jobStatus.remaining.distance.toFixed(0)} m /{" "}
                {(jobStatus.remaining.minutes / 60).toFixed(2)} hours
              </p>
            </div>
          </div>
        </CardBody>
      </Card>
    );
  }
);
