import { FC, Fragment, useState } from "react";
import { addDays } from "date-fns";
import classNames from "classnames";
import { WINDOW_TITLE_NAMES } from "../../constants";
import { useCompactDesign } from "../../hooks/useCompactDesign";
import { StatisticsQueryT } from "../../types/Statistics";
import { useQueryStatisticsPost } from "../../hooks/useQueries";
import { Layout } from "../../components/organisms/Layout/Layout";
import SubHeader from "../../components/atoms/SubHeader/SubHeader";
import CardWithGenericHeader from "../../components/atoms/CardWithHeader/CardWithGenericHeader";
import CardWithHeader from "../../components/atoms/CardWithHeader/CardWithHeader";
import { Loader } from "../../components/atoms/Loader/Loader";
import HorizontalDivider from "../../components/atoms/Divider/HorizontalDivider";
import DeviceDetailStatusRow from "../../components/molecules/DeviceInfo/DeviceDetailStatusRow";
import { StatisticsFilter } from "./StatisticsFilters";
import { StatisticsGraph } from "./StatisticsGraph";
import {
  getMowingTotals,
  getQuery,
  getTotals,
  getTransitTotals,
  makeDatesFitScreen,
} from "./functions";
import styles from "./statistics.module.scss";

const initialParameters = {
  startDate: addDays(new Date(), -6),
  endDate: new Date(),
  mowers: [],
  users: [],
  jobs: [],
};

const defaultQuery = getQuery(
  initialParameters.startDate,
  initialParameters.endDate,
  initialParameters.mowers,
  initialParameters.jobs,
  initialParameters.users
);

export const StatisticsPage: FC = () => {
  document.title = WINDOW_TITLE_NAMES.statistics;
  const isCompact = useCompactDesign();

  const [query, setQuery] = useState<StatisticsQueryT>(defaultQuery);
  const { isLoading, data } = useQueryStatisticsPost(query);

  return (
    <Layout>
      <Loader isOverlay isLoading={isLoading} />
      <SubHeader title="Statistics" />
      <div
        className={classNames(!isCompact && styles["statisticsCard--large"])}
      >
        <CardWithGenericHeader
          header={
            <StatisticsFilter
              statisticsQueried={setQuery}
              initialStartDate={initialParameters.startDate}
              initialEndDate={initialParameters.endDate}
            />
          }
        >
          <StatisticsGraph
            data={makeDatesFitScreen(
              data ? Object.values(data.data.dates) : undefined,
              isCompact
            )}
          />
        </CardWithGenericHeader>
      </div>
      <div
        className={classNames(
          styles.summaryCards,
          !isCompact && styles["summaryCards--large"]
        )}
      >
        <CardWithHeader title="Totals" className={styles.summaryCard}>
          {getTotals(data?.data).map((row, idx) => (
            <Fragment key={`${row.label}-${idx}`}>
              <DeviceDetailStatusRow data={row} />
              {row.divider ? <HorizontalDivider /> : null}
            </Fragment>
          ))}
        </CardWithHeader>
        <CardWithHeader title="Mowing" className={styles.summaryCard}>
          {getMowingTotals(data?.data).map((row, idx) => (
            <Fragment key={`${row.label}-${idx}`}>
              <DeviceDetailStatusRow data={row} />
            </Fragment>
          ))}
        </CardWithHeader>
        <CardWithHeader title="Transit" className={styles.summaryCard}>
          {getTransitTotals(data?.data).map((row, idx) => (
            <Fragment key={`${row.label}-${idx}`}>
              <DeviceDetailStatusRow data={row} />
            </Fragment>
          ))}
        </CardWithHeader>
      </div>
    </Layout>
  );
};
