import React, { FC } from "react";
import classNames from "classnames";
import { NewDiode } from "../NewDiode/NewDiode";
import { LedT } from "../../../types/Common";
import styles from "./deviceStatusItem.module.scss";

interface PropsT {
  text: string;
  hideDiode?: boolean;
  led?: LedT;
  classNameWrapper?: string;
  classNameSpan?: string;
}

export const DeviceStatusItem: FC<PropsT> = ({
  text,
  hideDiode = false,
  led,
  classNameWrapper,
  classNameSpan,
}) => {
  return (
    <div className={classNames(styles.wrapper, classNameWrapper)}>
      {!hideDiode && <NewDiode {...led} border />}
      <span className={classNames(styles.text, classNameSpan)}>{text}</span>
    </div>
  );
};
