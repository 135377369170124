import { object, ref, string } from "yup";
import { VALIDATIONS } from "../../constants";

const passwordRegExp = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*])");

export const userRegisterSchema = object({
  firstName: string().required(VALIDATIONS.required),
  surname: string().required(VALIDATIONS.required),
  email: string().required(VALIDATIONS.required).email(VALIDATIONS.email),
  password: string()
    .required(VALIDATIONS.required)
    .min(8, "Password length must be at least 8 characters")
    .matches(
      passwordRegExp,
      "Password must contain at least one capital letter and one special character"
    ),
  cpassword: string()
    .required(VALIDATIONS.required)
    .oneOf([ref("password")], "Passwords do not match"),
}).required();
