import React, { FC } from "react";
import { Card, CardBody, Table } from "reactstrap";
import { formatDate } from "../../../functions/formatDate";
import { RouteT } from "../../../types/Route";
import Title from "../Title/Title";

type PropsT = {
  data: RouteT;
};

export const CardRouteInfo: FC<PropsT> = ({ data }) => {
  return (
    <div>
      <Title type="h2" size="h4" children={"Info"} />
      <Card>
        <CardBody>
          <Table className="table--simple">
            <tbody>
              <tr>
                <td>Route length:</td>
                <td>
                  <strong>{data.routeLengthMeter} m</strong>
                </td>
              </tr>
              <tr>
                <td>Company:</td>
                <td>
                  <strong>{data.company}</strong>
                </td>
              </tr>
              <tr>
                <td>Owner:</td>
                <td>
                  <strong></strong>
                </td>
              </tr>
              <tr>
                <td>Phone number:</td>
                <td>
                  <strong>{data.phone}</strong>
                </td>
              </tr>
              <tr>
                <td>Created:</td>
                <td>
                  <strong>{data.createdBy.name}</strong>
                </td>
              </tr>
              <tr>
                <td>Created Date:</td>
                <td>
                  <strong>{formatDate(data.createdAt)}</strong>
                </td>
              </tr>
              {data.updatedAt && data.updatedBy && (
                <>
                  <tr>
                    <td>Updated:</td>
                    <td>
                      <strong>{data.updatedBy.name}</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>Updated Date:</td>
                    <td>
                      <strong>{formatDate(data.updatedAt)}</strong>
                    </td>
                  </tr>
                </>
              )}
              <tr>
                <td>Version:</td>
                <td>
                  <strong>{data.version}</strong>
                </td>
              </tr>
            </tbody>
          </Table>
        </CardBody>
      </Card>
    </div>
  );
};
