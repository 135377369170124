import React, { FC } from "react";
import { JobT } from "../../../../types/Job";
import UfonMarker from "../../../../components/atoms/MapMarkers/UfonMarker";

type Props = {
  job: JobT;
};

const UfonMarkers: FC<Props> = ({ job }) => {
  const ufons: number[] = [];
  job.users.flatMap((user) => {
    user.mowers.forEach((mower) => {
      if (mower.ufon && !ufons.includes(mower.ufon.id)) {
        ufons.push(mower.ufon.id);
      }
    });
  });

  if (ufons.length === 0) {
    return null;
  }

  return (
    <>
      {ufons.map(
        (ufon, index) => ufon && <UfonMarker key={index} ufonId={ufon} />
      )}
    </>
  );
};

export default UfonMarkers;
