import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { client } from "../services/axios";
import { MutationOptionsT } from "../types/ReactQuery";
import { ApiErrorT } from "../types/Common";

export const useMutationGeostickMeasurementDelete = (
  jobId: string | number,
  options?: MutationOptionsT
) => {
  return useMutation(
    (pointId: string | number) => {
      return client.delete(`/api/v1/job/${jobId}/geostick/${pointId}/remove`);
    },
    {
      onSuccess: () => {
        toast.success("Measurement has been deleted");
        if (options?.onSuccess) {
          options.onSuccess();
        }
      },
      onError: (error: ApiErrorT) => {
        toast.error(error?.message || "Deleting measurement failed");
        if (options?.onError) {
          options.onError();
        }
      },
    }
  );
};
