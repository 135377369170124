import { Polyline } from "@react-google-maps/api";
import React from "react";
import {
  CoordinatesRawT,
  LatLngT,
  ShowActivityTypesT,
} from "../../../types/Route";
import { getMowerPolylineOptions } from "../../../functions/getMowerPolylineOptions";
import { getJourneyCoordinates } from "../../../functions/getJourneyCoordinates";
import { Loader } from "../../atoms/Loader/Loader";

export type JourneyPolylinesPropsT = {
  journeys: CoordinatesRawT[];
  isLoadingJourneys: boolean;
  strokeColor: string;
  isDirectionShowing: boolean;
  showActivity: boolean;
  showActivityTypes: ShowActivityTypesT;
};

const showCoordinates = (
  coordinates: (LatLngT & { isAutonomous?: boolean })[],
  showActivityTypes: ShowActivityTypesT
) => {
  if (coordinates.length === 0) {
    return true;
  }

  const knivesOn = coordinates[0].knivesOn;
  const isAutonomous = coordinates[0].isAutonomous;
  if (knivesOn === undefined && isAutonomous === undefined) {
    return true;
  }
  if (knivesOn === undefined && isAutonomous) {
    return (
      showActivityTypes.autonomousMowing || showActivityTypes.autonomousTransit
    );
  }
  if (knivesOn === undefined && !isAutonomous) {
    return showActivityTypes.manualMowing || showActivityTypes.manualTransit;
  }
  if (knivesOn && isAutonomous === undefined) {
    return showActivityTypes.autonomousMowing || showActivityTypes.manualMowing;
  }
  if (!knivesOn && isAutonomous === undefined) {
    return (
      showActivityTypes.autonomousTransit || showActivityTypes.manualTransit
    );
  }

  if (knivesOn && isAutonomous) {
    return showActivityTypes.autonomousMowing;
  }
  if (!knivesOn && isAutonomous) {
    return showActivityTypes.autonomousTransit;
  }
  if (knivesOn && !isAutonomous) {
    return showActivityTypes.manualMowing;
  }
  if (!knivesOn && !isAutonomous) {
    return showActivityTypes.manualTransit;
  }

  return true;
};

export const JourneyPolylinesBase = React.memo(
  ({
    journeys,
    isLoadingJourneys,
    strokeColor,
    isDirectionShowing,
    showActivity,
    showActivityTypes,
  }: JourneyPolylinesPropsT) => {
    const journeyCoordinates = getJourneyCoordinates(journeys);

    if (isLoadingJourneys) {
      return <Loader isCentered />;
    }

    return (
      <>
        {journeyCoordinates.coordinates.map((coordinates, index) => (
          <Polyline
            path={coordinates}
            key={index}
            visible={
              showActivity && showCoordinates(coordinates, showActivityTypes)
            }
            options={getMowerPolylineOptions(
              strokeColor,
              isDirectionShowing,
              coordinates[0].knivesOn ?? true,
              coordinates[0].isAutonomous ?? true
            )}
          />
        ))}
      </>
    );
  }
);

export default JourneyPolylinesBase;
