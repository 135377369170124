import { FC } from "react";
import { Popover, PopoverBody } from "reactstrap";
import { ButtonPropsT, StyledButton } from "../Button/Button";

export type PopoverButtonPropsT = ButtonPropsT & {
  id: string;
  isOpen: boolean;
  toggle: VoidFunction;
  trigger?: "click" | "focus" | "legacy";
  placement?: "bottom" | "top" | "left" | "right";
};

export const PopoverButton: FC<PopoverButtonPropsT> = ({
  isOpen,
  toggle,
  trigger = "legacy",
  placement = "bottom",
  children,
  ...rest
}) => {
  return (
    <>
      <StyledButton {...rest} />
      <Popover
        trigger={trigger}
        placement={placement}
        target={rest.id}
        isOpen={isOpen}
        toggle={toggle}
      >
        <PopoverBody style={{ padding: 0 }}>{children}</PopoverBody>
      </Popover>
    </>
  );
};
