import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { useQueryJobGet } from "../../hooks/useQueries";
import { clearMowerStateAction } from "../../store/slices/mowerSlice";
import { clearUfonAction } from "../../store/slices/ufonSlice";
import {
  clearJobAction,
  subscribeToJobProgressAction,
  subscribeToNextCoordinatesUpdate,
} from "../../store/slices/jobRouteTypeSlice";
import { Layout } from "../../components/organisms/Layout/Layout";
import { Loader } from "../../components/atoms/Loader/Loader";
import JobMainComponent from "./JobMainComponent";

export const JobRouteType = () => {
  const { jobId } = useParams() as { jobId: string };
  const dispatch = useDispatch();
  const {
    data: jobData,
    isLoading,
    refetch: refetchJob,
    isFetching: isJobRefetching,
  } = useQueryJobGet(jobId);
  const job =
    jobData?.data && jobData.data.geostick === undefined
      ? jobData.data
      : undefined;

  useEffect(() => {
    dispatch(subscribeToJobProgressAction({ jobId }));
    dispatch(subscribeToNextCoordinatesUpdate({ jobId }));
    return () => {
      dispatch({ type: "STOP_MOWER_TRACKING" });
      dispatch({ type: "UNSUBSCRIBE_FROM_JOB_PROGRESS" });
      dispatch({ type: "UNSUBSCRIBE_FROM_NEXT_COORDINATES_UPDATE" });
      dispatch(clearJobAction());
      dispatch(clearMowerStateAction());
      dispatch(clearUfonAction());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) return <Loader isCentered={true} />;
  if (!job) return <div>No job found with id {jobId}</div>;

  return (
    <Layout requireWS>
      <JobMainComponent
        job={job}
        jobId={jobId}
        refetchJob={refetchJob}
        isJobRefetching={isJobRefetching}
      />
    </Layout>
  );
};
