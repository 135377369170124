import { FC } from "react";
import {
  DEFAULT_SHOW_ACTIVITY_TYPES,
  JOB_ROUTE_TYPE_COLORS,
} from "../../../constants";
import { JobT } from "../../../types/Job";
import CurrentMovePolylines from "../../../components/molecules/CurrentMovePolylines/CurrentMovePolylines";
import MissedCoordinatesPolylines from "../../../components/molecules/MissedcoordinatesPolylines/MissedCoordinatesPolylines";
import JourneyPolylines from "../../../components/molecules/JourneyPolylines/JourneyPolylines";

type PropsT = {
  isDirectionShowing: boolean;
  mowerId: number;
  job?: JobT;
  showActivity: boolean;
  journeyIds: number[];
};

const MapMowerTracker: FC<PropsT> = ({
  isDirectionShowing,
  mowerId,
  job,
  showActivity,
  journeyIds,
}) => {
  const showJourneys = journeyIds.length > 0;
  return (
    <>
      {job && !showJourneys && (
        <MissedCoordinatesPolylines
          isDirectionShowing={isDirectionShowing}
          strokeColor={JOB_ROUTE_TYPE_COLORS.activeDevice}
          jobId={job.id}
          mowerId={mowerId}
          showActivity={showActivity}
        />
      )}
      {showJourneys && (
        <JourneyPolylines
          journeyIds={journeyIds}
          strokeColor={JOB_ROUTE_TYPE_COLORS.activeDevice}
          showActivity={showActivity}
          isDirectionShowing={isDirectionShowing}
          showActivityTypes={DEFAULT_SHOW_ACTIVITY_TYPES}
        />
      )}
      {
        <CurrentMovePolylines
          mowerId={mowerId}
          strokeColor={JOB_ROUTE_TYPE_COLORS.activeDevice}
          isDirectionShowing={isDirectionShowing}
          showActivity={showActivity}
          isVisible
        />
      }
    </>
  );
};

export default MapMowerTracker;
