import React, { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../routes";
import { useMutationUserDelete } from "../../hooks/useMutationUserDelete";
import { useSearchParams } from "../../hooks/useSearchParam";
import { usePermissions } from "../../hooks/usePermissions";
import { useMutationUserDecide } from "../../hooks/useMutationUserDecide";
import { UserT } from "../../types/User";
import { queryClient } from "../..";
import { WINDOW_TITLE_NAMES } from "../../constants";
import {
  useQueryPendingUsersGet,
  useQueryUsersGet,
} from "../../hooks/useQueries";
import { Layout } from "../../components/organisms/Layout/Layout";
import MenuWithLayout, {
  MenuWithLayoutItemT,
} from "../../components/organisms/MenuWithLayout/MenuWithLayout";
import { DataGrid } from "../../components/molecules/DataGrid/DataGrid";
import { useConfirm } from "../../components/molecules/ConfirmBoxProvider/ConfirmBoxProvider";
import { Loader } from "../../components/atoms/Loader/Loader";
import { StyledButton } from "../../components/atoms/Button/Button";
import SubHeader from "../../components/atoms/SubHeader/SubHeader";
import InviteUserModal from "./InviteUserModal";
import AssignCompanyModal from "./AssignCompanyModal";

export const UsersPage: React.FC = () => {
  document.title = WINDOW_TITLE_NAMES.users;
  const { updateSearchParams, getParamValue } = useSearchParams();
  const navigate = useNavigate();
  const [isInviteModalShowing, setIsInviteModalShowing] = useState(false);
  const [isAssignCompanyModalShowing, setIsAssignCompanyModalShowing] =
    useState(false);
  const [userId, setUserId] = useState(0);
  const permissions = usePermissions();
  const search = getParamValue("search");
  const userType = getParamValue("userType");
  const { isLoading: isLoadingUsers, data: users } = useQueryUsersGet(
    search || ""
  );
  const { isLoading: isLoadingPendingUsers, data: pendingUsers } =
    useQueryPendingUsersGet(search || "");
  const userDecideMutation = useMutationUserDecide({
    onSuccess: () => {
      queryClient.invalidateQueries(["pending-users"]);
    },
  });

  const confirm = useConfirm();

  const onSearch = (searchString: string) => {
    updateSearchParams((prev) => ({ ...prev, search: searchString }));
    queryClient.invalidateQueries(["users", { type: searchString }]);
  };

  const deleteUserMutation = useMutationUserDelete({
    onSuccess: () => {
      queryClient.invalidateQueries(["users"]);
    },
  });

  const handleDelete = (user: UserT) => {
    confirm({
      title: "Remove user",
      subTitle: `Are you sure you want to remove this user, ${
        user.firstName + " " + user.surname
      }?`,
      asyncCallback: () => deleteUserMutation.mutateAsync(user.id),
      type: "delete",
    });
  };

  const handleAcceptUser = (user: UserT) => {
    setUserId(user.id);
    setIsAssignCompanyModalShowing(true);
  };

  const handleRejectUser = (user: UserT) => {
    confirm({
      title: "Reject user",
      subTitle: `Are you sure you want to reject this user, ${
        user.firstName + " " + user.surname
      }?`,
      asyncCallback: () =>
        userDecideMutation.mutateAsync({ userId: user.id, approved: false }),
      type: "delete",
    });
  };

  const userRows: UserT[] = useMemo(() => {
    const usersData = users?.data.items || [];
    const pendingUsersData =
      pendingUsers?.data.items.map((pendingUser) => ({
        ...pendingUser,
        imageUrl: "",
        lastTraining: null,
        logs: [],
        devices: { mower: [], ufon: [] },
        isPendingUser: true,
      })) || [];

    if (userType === "activeUsers") {
      return usersData;
    }
    if (userType === "pendingUsers") {
      return pendingUsersData;
    }
    return [...usersData, ...pendingUsersData];
  }, [users, pendingUsers, userType]);

  const menuItems: MenuWithLayoutItemT[] = [
    {
      id: "activeUsers",
      title: "Active users",
      itemCount: users?.data.totalCount,
      onClick: () =>
        updateSearchParams((prev) => ({ ...prev, userType: "activeUsers" })),
    },
    {
      id: "pendingUsers",
      title: "Pending users",
      itemCount: pendingUsers?.data.totalCount,
      hasSeparator: true,
      active: userType === "pendingUsers",
      onClick: () =>
        updateSearchParams((prev) => ({ ...prev, userType: "pendingUsers" })),
    },
    {
      id: "all",
      title: "All users",
      active: userType === null,
      onClick: () =>
        updateSearchParams((prev) => ({ ...prev, userType: null })),
    },
  ];

  const HeaderActions = () => {
    return (
      <>
        <StyledButton
          title="Invite user"
          outline
          color="dark"
          icon="share"
          onlyIconOnMobile
          onClick={() => setIsInviteModalShowing(true)}
        />
        <StyledButton
          onlyIconOnMobile
          color="primary"
          title="New user"
          icon="plus-circle"
          onClick={() => navigate(ROUTES.userCreate())}
        />
      </>
    );
  };

  const getHeaders = () => {
    const headers = ["Name"];
    if (permissions.companiesVisibility) {
      headers.push("Companies");
    }

    return [...headers, "Role", "Phone", "Email"];
  };

  return (
    <Layout whiteBackground>
      <Loader
        isLoading={userDecideMutation.isLoading}
        isOverlay={userDecideMutation.isLoading}
      />
      <SubHeader
        title="Users"
        search={{
          placeholder: "Search user",
          onChange: onSearch,
        }}
        actions={HeaderActions()}
      />

      <MenuWithLayout items={menuItems} activeItem={[userType || "all"]}>
        <DataGrid
          headers={getHeaders()}
          rowComponent={"UserGridRow"}
          data={userRows}
          onDelete={handleDelete}
          onAccept={handleAcceptUser}
          onReject={handleRejectUser}
          isLoading={isLoadingUsers || isLoadingPendingUsers}
          dividerPosition={
            users && userType === null ? users.data.totalCount - 1 : undefined
          }
        />
      </MenuWithLayout>
      <InviteUserModal
        isOpen={isInviteModalShowing}
        onClose={() => setIsInviteModalShowing(false)}
      />
      <AssignCompanyModal
        userId={userId}
        isOpen={isAssignCompanyModalShowing}
        onClose={() => setIsAssignCompanyModalShowing(false)}
      />
    </Layout>
  );
};
