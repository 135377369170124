import { useState } from "react";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import classNames from "classnames";
import { Icon } from "../../components/atoms/Icon";
import UfonLog from "../../components/molecules/LogNew/UfonLog";
import styles from "./logComponent.module.scss";
import { IconsId } from "../../assets/fonts/iconfont/icons";

type PropsT = {
  ufonId: number | string;
  roverUfonId: number | string;
};

type SelectedT = "ufon" | "rover";

const tabs: Record<SelectedT, { iconId: IconsId }> = {
  ufon: {
    iconId: "broadcast-tower",
  },
  rover: {
    iconId: "crosshairs-location",
  },
};

export function LogComponent({ ufonId, roverUfonId }: PropsT) {
  const [selected, setSelected] = useState<"ufon" | "rover">("ufon");
  return (
    <div className={styles.wrapper}>
      <Nav tabs className={styles.nav}>
        {Object.entries(tabs).map(([key, { iconId }]) => (
          <NavItem key={key} className={styles["nav-item"]}>
            <NavLink
              key={key}
              active={selected === key}
              onClick={() => setSelected(key as SelectedT)}
              className={classNames(styles["nav-link"], {
                [styles["nav-link--active"]]: selected === key,
              })}
            >
              <Icon icon={iconId} />
            </NavLink>
          </NavItem>
        ))}
      </Nav>
      <TabContent activeTab={selected} className={styles.tabContent}>
        <TabPane tabId="ufon" className={styles.regularTab}>
          {selected === "ufon" && <UfonLog id={ufonId} />}
        </TabPane>
        <TabPane tabId="rover" className={styles.regularTab}>
          {selected === "rover" && <UfonLog id={roverUfonId} />}
        </TabPane>
      </TabContent>
    </div>
  );
}
