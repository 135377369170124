import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";
import { JOB_TYPE_IDS, ROLE_IDS, WINDOW_TITLE_NAMES } from "../../constants";
import { ROUTES } from "../../routes";
import { useSelector } from "../../store/hooks";
import { useSearchParams } from "../../hooks/useSearchParam";
import { useMutationCancelJob } from "../../hooks/useMutationCancelJob";
import {
  useQueriesJourneyGet,
  useQueryCoordinatesGet,
  useQueryJobGet,
  useQueryUfonGet,
} from "../../hooks/useQueries";
import { useMutationCompleteJob } from "../../hooks/useMutationCompleteJob";
import { useMutationArchiveJob } from "../../hooks/useMutationArchiveJob";
import { useMutationRestoreJob } from "../../hooks/useMutationRestoreJob";
import { useMutationStartJob } from "../../hooks/useMutationStartJob";
import {
  clearTasksAction,
  fetchTasksAction,
  subscribeToTaskAction,
  unsubscribeFromTaskAction,
} from "../../store/slices/tasksSlice";
import { clearMowerAction } from "../../store/slices/mowerSlice";
import { waitForMessageArr } from "../../store/sagas/functions";
import { setUfonData } from "../../store/slices/ufonSlice";
import { getJobStatus } from "../../functions/jobStatus";
import { JobWizard } from "../../components/organisms/JobWizard/JobWizard";
import { ExportJourneyDialog } from "../../components/atoms/ExportJourneyDialog/ExportJourneyDialog";
import TabsWithMapLayout from "../../components/organisms/TabsWithMapLayout/TabsWithMapLayout";
import Log from "../../components/molecules/LogNew/Log";
import JobCard from "../../components/molecules/JobCard/JobCard";
import { StyledButton } from "../../components/atoms/Button/Button";
import MowerInfoCard from "../../components/molecules/MowerInfoCard/MowerInfoCard";
import UfonInfoCard from "../../components/molecules/UfonInfoCard/UfonInfoCard";
import { MowerCard } from "../../components/molecules/MowerCard/MowerCard";
import { getRouteNames } from "../../components/molecules/WizardItem/functions";
import { UfonCard } from "../../components/molecules/UfonCard/UfonCard";
import { JobStatusCard } from "../../components/molecules/JobStatusCard/JobStatusCard";
import { Cards } from "../../components/atoms/Cards/Cards";
import PageHeader from "./PageHeader";
import JobMap from "./JobMap";
import { getRouteId } from "./functions";
import styles from "./job.module.scss";

export const JobPage = () => {
  useEffect(() => {
    document.title = WINDOW_TITLE_NAMES.jobs;
  }, []);

  const [center, setCenter] = useState({
    lat: 50.0807843861,
    lng: 14.715616947,
  });
  const [mapZoom, setMapZoom] = useState(8);
  const [openExport, setOpenExport] = useState(false);
  const [showOverview, setShowOverview] = useState(true);
  const dispatch = useDispatch();
  const { jobId } = useParams() as { jobId: string };
  const { getParamValue } = useSearchParams();
  const navigate = useNavigate();
  const userId = getParamValue("userId");
  const { user: currentUser } = useSelector((store) => store.user);
  const {
    data: jobData,
    isLoading,
    refetch: refetchJob,
  } = useQueryJobGet(jobId);
  const job =
    jobData?.data && jobData.data.geostick === undefined
      ? jobData.data
      : undefined;
  const { userTasks } = useSelector((store) => store.tasks);

  const [journeys, isLoadingJourneys] = useQueriesJourneyGet(
    userTasks?.infoStatus.journeys
  );

  const routePreviews = userTasks?.infoStatus.route || [];
  const { data: routeCoordinates, isLoading: isLoadingCoordinates } =
    useQueryCoordinatesGet(getRouteId(0, routePreviews));

  const startJobMutation = useMutationStartJob({
    onSuccess: () => {
      refetchJob();
      setShowOverview((prev) => !prev);
    },
  });

  const onStart = () => {
    startJobMutation.mutate(jobId);
  };

  const fetchTasks = useCallback(() => {
    if (userId) {
      dispatch(fetchTasksAction({ jobId, userId }));
    }
  }, [dispatch, jobId, userId]);

  useEffect(() => {
    fetchTasks();
  }, [fetchTasks]);

  useEffect(() => {
    if (job && job.latitude && job.longitude) {
      const { latitude, longitude } = job;
      if (latitude && longitude) {
        setCenter({ lat: latitude, lng: longitude });
        setMapZoom(20);
      }
    } else if (routeCoordinates?.data) {
      const location = routeCoordinates.data.coordinates[0].coordinates[0];
      if (location) {
        setCenter(location);
        setMapZoom(20);
      }
    } else if (userTasks?.infoStatus?.mower?.[0]) {
      const { latitude, longitude } = userTasks.infoStatus.mower[0];

      if (latitude && longitude) {
        setCenter({ lat: latitude, lng: longitude });
        setMapZoom(20);
      }
    }
  }, [job, userTasks, routeCoordinates?.data]);

  const completeJobMutation = useMutationCompleteJob(jobId, {
    onSuccess: () => {
      navigate(ROUTES.jobs());
      fetchTasks();
    },
  });

  const cancelJobMutation = useMutationCancelJob(jobId, {
    onSuccess: () => {
      refetchJob();
      fetchTasks();
      setShowOverview(true);
    },
  });

  useEffect(() => {
    dispatch(subscribeToTaskAction({ jobId }));

    return () => {
      if (userTasks) {
        dispatch(
          clearMowerAction({ mowerIds: userTasks.infoStatus.allMowers })
        );
      }
      dispatch({ type: "STOP_MOWER_TRACKING" });
      dispatch(unsubscribeFromTaskAction({ jobId }));
      dispatch(clearTasksAction());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCompleteJob = () => {
    completeJobMutation.mutate();
  };

  const handleCancelJob = () => {
    cancelJobMutation.mutate();
  };

  const handleExportJourney = () => {
    setOpenExport(true);
  };

  const archiveJobMutation = useMutationArchiveJob(jobId, {
    onSuccess: () => {
      navigate(ROUTES.jobs());
      fetchTasks();
    },
  });

  const restoreJobMutation = useMutationRestoreJob(jobId, {
    onSuccess: () => {
      navigate(ROUTES.jobs());
      fetchTasks();
    },
  });

  const handleArchiveJob = () => {
    archiveJobMutation.mutateAsync();
  };

  const handleRestoreJob = () => {
    restoreJobMutation.mutateAsync();
  };

  const ufonId = userTasks?.infoStatus.ufon?.id;
  const { data: ufonData, isLoading: isUfonLoading } = useQueryUfonGet(ufonId);
  useEffect(() => {
    if (ufonData && ufonId) {
      dispatch(setUfonData({ ufonData: ufonData.data, ufonId }));
    }
  }, [ufonData, ufonId, dispatch]);

  if (!job) {
    return null;
  }

  const allStepsFinished =
    job.allStepsFinished ||
    userTasks?.tasks.every((task) => task.status === "done");

  const canUserHandleTask =
    userId === `${currentUser?.id}` || job.type.id === JOB_TYPE_IDS.auto;

  // When job is archived, we cannot start, cancel, continue, etc. with job
  const mowerId = job.mowers[0].id;
  const mowerName = job.mowers[0].name;

  const taskUfon = userTasks?.infoStatus.ufon;

  const waitingForUfon = (ufonData && !ufonData.data.isOnline) || isUfonLoading;

  const isWaitingArr = userTasks ? waitForMessageArr(userTasks) : [];

  const status = getJobStatus(job);

  return (
    <PageHeader
      title={job?.name || `Job ${jobId}`}
      job={job}
      status={status}
      isDisabled={!job /*|| !canUserHandleTask*/}
      isLoading={completeJobMutation.isLoading}
      isArchived={job.archived}
      onComplete={handleCompleteJob}
      onCancel={handleCancelJob}
      onExport={
        journeys.length > 0 || job.route
          ? handleExportJourney
          : () => {
              toast.error("No journeys to export");
            }
      }
      onArchive={handleArchiveJob}
      onRestore={handleRestoreJob}
      onStart={onStart}
      refetchJob={refetchJob}
    >
      <TabsWithMapLayout
        tabName="Status"
        mapComponent={
          <JobMap
            id="mapElement"
            mowerIds={userTasks?.infoStatus.allMowers || []}
            routeCoordinates={routeCoordinates?.data}
            center={center}
            zoom={mapZoom}
            isLoading={isLoading}
            isLoadingJourneys={isLoadingJourneys || !userTasks}
            isLoadingTasks={!Boolean(userTasks)}
            isLoadingCoordinates={isLoadingCoordinates}
            journeys={journeys}
            tempNogo={userTasks?.tasks.filter(
              (task) => task.type === "setNoGoZones"
            )}
            jobId={jobId}
            job={job}
            currentUserId={userTasks?.user.id}
            ufonId={taskUfon?.id}
          />
        }
        logComponent={
          currentUser?.role.id === ROLE_IDS.developer ? (
            <Log id={mowerId} />
          ) : undefined
        }
      >
        {job &&
          userTasks &&
          (allStepsFinished ? (
            <Cards>
              <JobStatusCard mowerId={mowerId} />
              <MowerCard
                jobId={jobId}
                mowerId={mowerId}
                routeName={getRouteNames(
                  userTasks?.infoStatus.mower || [],
                  userTasks?.infoStatus?.route || [],
                  mowerId
                )}
                error={null}
              />
              {ufonId && <UfonCard ufonId={ufonId} />}
            </Cards>
          ) : (
            <>
              {showOverview || status === "beforeStart" ? (
                <JobCard job={job} showStatus={false} />
              ) : (
                <div className={styles.overview}>
                  <MowerInfoCard
                    mowerId={mowerId}
                    mowerName={mowerName}
                    onClick={() => navigate(ROUTES.mower(mowerId))}
                    showFull
                  />
                  {userTasks.infoStatus.ufon && (
                    <UfonInfoCard ufonId={userTasks.infoStatus.ufon.id} />
                  )}
                  <h3 className={styles.title}>Job steps</h3>
                  <JobWizard
                    jobType={job.type}
                    jobName={job?.name || `Job ${jobId}`}
                    jobId={jobId}
                    tasks={userTasks.tasks}
                    isDisabled={!canUserHandleTask}
                    isWaiting={waitingForUfon}
                    isWaitingArr={isWaitingArr}
                    fetchTasks={fetchTasks}
                  />
                </div>
              )}
            </>
          ))}

        {status === "started" && showOverview && (
          <StyledButton
            title={"Job progress"}
            color="primary"
            onClick={() => {
              setShowOverview((prev) => !prev);
            }}
          />
        )}
      </TabsWithMapLayout>
      <ExportJourneyDialog
        isOpen={openExport}
        onClose={() => setOpenExport(false)}
        data={userTasks?.infoStatus}
        jobId={jobId}
        route={job.route}
      />
    </PageHeader>
  );
};
