import React, { FC } from "react";
import { useNavigate } from "react-router";
import classNames from "classnames";
import { Card, CardBody } from "reactstrap";
import { ROUTES } from "../../../routes";
import { ENVIRONMENT } from "../../../env";
import { useDispatch, useSelector } from "../../../store/hooks";
import { setMowerRouteUploadingAction } from "../../../store/slices/mowerSlice";
import { useMutationUploadMowerCoordinates } from "../../../hooks/useMutationUploadMowerCoordinates";
import { ApiErrorT } from "../../../types/Common";
import { useConfirm } from "../ConfirmBoxProvider/ConfirmBoxProvider";
import { CardHeader } from "../../atoms/CardHeader/CardHeader";
import { DeviceMessage } from "../../atoms/DeviceMessage/DeviceMessage";
import { DeviceStatusItem } from "../../atoms/DeviceStatusItem/DeviceStatusItem";
import { DeviceStatusRow } from "../../atoms/DeviceStatusRow/DeviceStatusRow";
import { Mower } from "../../atoms/Mower/Mower";
import { Loader } from "../../atoms/Loader/Loader";
import { StyledButton } from "../../atoms/Button/Button";
import DefaultMowerCard from "./DefaultMowerCard";
import styles from "./mowerCard.module.scss";

type PropsT = {
  jobId: string;
  mowerId: number;
  segment?: {
    id: number;
    name: string;
  };
  error: ApiErrorT | null;
  routeName: string | undefined;
  skipRoute?: boolean;
};

export const MowerCard: FC<PropsT> = ({
  mowerId,
  jobId,
  segment,
  error,
  routeName,
  skipRoute,
}) => {
  const navigate = useNavigate();
  const confirm = useConfirm();
  const dispatch = useDispatch();

  const mowerStatus = useSelector(
    (store) => store.mower.mowers[mowerId]?.mowerStatus
  );
  const routeUploaded: boolean | undefined = useSelector(
    (store) => store.mower.mowers[mowerId]?.routeUploaded
  );

  const routeUploading: boolean | undefined = useSelector(
    (store) => store.mower.mowers[mowerId]?.routeUploading
  );

  const isMowerOnline: boolean | undefined = useSelector(
    (store) => store.mower.mowers[mowerId]?.isOnline
  );

  const routeTitle = segment
    ? `${segment.name} - Route ${routeName ?? ""}`
    : `Route ${routeName ?? ""}`;

  const mowerTitle = segment
    ? `${mowerStatus?.name}/${segment.name}`
    : mowerStatus?.name;

  const uploadRouteMutation = useMutationUploadMowerCoordinates(
    jobId,
    mowerId,
    segment?.id
  );

  const uploadDisabled =
    !isMowerOnline ||
    !mowerStatus?.status.readyForUploadRoute ||
    mowerStatus.status.mode.additionalValue?.toLocaleLowerCase() ===
      "autonomus";

  const uploadShown = !routeUploaded && !routeUploading;

  const reuploadShown = routeUploaded && !routeUploading;

  const reauploadDisabled =
    !isMowerOnline ||
    mowerStatus?.status.mode.additionalValue?.toLocaleLowerCase() ===
      "autonomus";

  const uploadRouteAgain = () => {
    confirm({
      title: "Upload route",
      subTitle: `If you upload the route again the mower will want to return to the starting point. 
        (If you want to continue at the current mowing point use the Autonomus button (2x short and 1x long Press))`,
      asyncCallback: async () => {
        dispatch(
          setMowerRouteUploadingAction({ mowerId, routeUploading: true })
        );
        await uploadRouteMutation.mutateAsync();
        dispatch(
          setMowerRouteUploadingAction({ mowerId, routeUploading: false })
        );
      },
      type: "ok",
    });
  };

  if (!mowerStatus) {
    return error ? <DefaultMowerCard error={error} /> : <DefaultMowerCard />;
  }

  return (
    <Card>
      <CardBody>
        <CardHeader
          icon="mower"
          title={mowerTitle || ""}
          withRtkMode={
            isMowerOnline
              ? {
                  mode: mowerStatus?.status.rtkMode ?? null,
                }
              : undefined
          }
          onClick={() => navigate(ROUTES.mower(mowerId))}
        />
        {ENVIRONMENT != "production" && (
          <div className="d-flex justify-content-center mb-4">
            <Mower
              remoteLed={mowerStatus?.topLed?.remoteControl}
              rtkLed={mowerStatus.topLed?.rtkMode}
              leftLed={mowerStatus.led?.left}
              middleLed={mowerStatus.led?.middle}
              rightLed={mowerStatus.led?.right}
            />
          </div>
        )}
        {mowerStatus.message && (
          <DeviceMessage
            text={mowerStatus.message.message}
            additionalMessage={mowerStatus.message.additionalMessage ?? ""}
            status={mowerStatus.message?.type}
            help={mowerStatus.message?.help ?? ""}
          />
        )}
        {mowerStatus && (
          <>
            <DeviceStatusRow
              label="Mode"
              status={mowerStatus.status.mode.additionalValue ?? "Manual"}
              led={mowerStatus.status.mode.led}
              className="mt-4"
            />
            <DeviceStatusRow
              label="Mowing"
              status={mowerStatus.status.mowing.additionalValue ?? "Off"}
              led={mowerStatus.status.mowing.led}
            />
            <div className={classNames(styles.divider)} />
            {!skipRoute && (
              <div className="row align-items-center">
                <DeviceStatusItem
                  text={routeTitle}
                  led={mowerStatus?.status.route.led}
                  classNameSpan={styles.routeTitle}
                  classNameWrapper={styles.wrapper}
                />
                <div className="col-6 d-flex align-items-center justify-content-end ps-0">
                  {uploadShown && (
                    <StyledButton
                      color="primary"
                      icon="reboot"
                      size="sm"
                      disabled={uploadDisabled}
                      className="px-2 d-flex align-items-center"
                      onClick={async () => {
                        dispatch(
                          setMowerRouteUploadingAction({
                            mowerId,
                            routeUploading: true,
                          })
                        );
                        await uploadRouteMutation.mutateAsync();
                        dispatch(
                          setMowerRouteUploadingAction({
                            mowerId,
                            routeUploading: false,
                          })
                        );
                      }}
                    >
                      Upload route
                    </StyledButton>
                  )}
                  {reuploadShown && (
                    <>
                      {mowerStatus.status.mode.additionalValue?.toLocaleLowerCase() !==
                        "autonomus" && (
                        <span className={styles.label}>Done</span>
                      )}
                      <StyledButton
                        icon="reboot"
                        className={styles.reupload}
                        onClick={uploadRouteAgain}
                        disabled={reauploadDisabled}
                      />
                    </>
                  )}
                  {routeUploading && <Loader text="Route is uploading" />}
                </div>
              </div>
            )}
            <div className={classNames(styles.divider)} />
            <div style={{ display: "flex", flexWrap: "wrap" }}>
              <DeviceStatusItem
                text="Correction data"
                led={mowerStatus.status.correctionData.led}
              />
              <DeviceStatusItem
                text={`Petrol${
                  mowerStatus.status.petrol.additionalValue
                    ? ` [${mowerStatus.status.petrol.additionalValue}]`
                    : ""
                }`}
                led={mowerStatus?.status.petrol.led}
              />
              <DeviceStatusItem
                text="Position/GPS"
                led={mowerStatus.status.position.led}
              />
              <DeviceStatusItem
                text="Engine"
                led={mowerStatus.status.engine.led}
              />
              <DeviceStatusItem
                text={`LTE${
                  mowerStatus.status.lte.additionalValue
                    ? ` [${mowerStatus.status.lte.additionalValue}%]`
                    : ""
                }`}
                led={mowerStatus.status.lte.led}
              />
              <DeviceStatusItem
                text="Remote control"
                led={mowerStatus.status.remoteControl.led}
              />
            </div>
          </>
        )}
      </CardBody>
    </Card>
  );
};
