import { intervalToDuration } from "date-fns";
import {
  DEVICE_DETAIL_COLORS,
  LOCALE,
  LOCALE_START_OF_WEEK,
} from "../../constants";
import { DeviceCurrentInfo } from "../../types/Common";
import {
  DateT,
  OptionT,
  StatisticsQueryT,
  StatisticsResponseT,
} from "../../types/Statistics";

function findLastIndex<T>(arr: T[], predicate: (item: T) => boolean): number {
  for (let i = arr.length - 1; i >= 0; i--) {
    if (predicate(arr[i])) return i;
  }
  return -1;
}

function getIds(options: OptionT[]): number[] | null {
  return options.length > 0 ? options.map((option) => option.id) : null;
}

function getTimestamp(date: Date): string {
  return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
}

export function getQuery(
  startDate: Date,
  endDate: Date,
  selectedMowers: OptionT[],
  selectedJobs: OptionT[],
  selectedUsers: OptionT[]
): StatisticsQueryT {
  return {
    dateFrom: getTimestamp(startDate),
    dateUntil: getTimestamp(endDate),
    mowerIds: getIds(selectedMowers),
    userIds: getIds(selectedUsers),
    jobIds: getIds(selectedJobs),
  };
}

export function formatHours(
  hours: number | undefined,
  hourNames: { singular: string; plural: string } = {
    singular: "hour",
    plural: "hours",
  }
): string {
  if (hours === undefined) return `0 ${hourNames.plural}`;

  const fullHours = Math.floor(hours);
  if (hours === 0) return `0 ${hourNames.plural}`;
  if (fullHours === 0) return `${Math.round(hours * 60)} min`;
  const usedHourName = fullHours === 1 ? hourNames.singular : hourNames.plural;
  if (hours % 1 === 0) return `${fullHours} ${usedHourName}`;
  return `${fullHours} ${usedHourName} ${Math.round((hours % 1) * 60)} min`;
}

export function getTotals(
  response: StatisticsResponseT | undefined
): (DeviceCurrentInfo & { divider: boolean })[] {
  const totalDistance =
    response &&
    Object.values(response.distance).reduce((acc, curr) => acc + curr, 0) *
      1000;
  const mowingHours =
    response && response.time.autonomousMowing + response.time.manualMowing;
  const mowingDistance =
    response &&
    response.distance.autonomousMowing + response.distance.manualMowing;
  const transitHours =
    response && response.time.autonomousTransit + response.time.manualTransit;
  const transitDistance =
    response &&
    response.distance.autonomousTransit + response.distance.manualTransit;
  return [
    {
      label: "Engine hours",
      value: response && formatHours(response.engineHours),
      divider: false,
      textColor: DEVICE_DETAIL_COLORS.black,
    },
    {
      label: "Distance",
      value: response && `${totalDistance} metres`,
      divider: true,
      textColor: DEVICE_DETAIL_COLORS.black,
    },
    {
      label: "Mowing",
      value: response && `${formatHours(mowingHours)} / ${mowingDistance} km`,
      divider: false,
      textColor: DEVICE_DETAIL_COLORS.black,
    },
    {
      label: "Transit",
      value:
        response && ` ${formatHours(transitHours)} / ${transitDistance} km`,
      divider: false,
      textColor: DEVICE_DETAIL_COLORS.black,
    },
  ];
}

export function getMowingTotals(
  response: StatisticsResponseT | undefined
): DeviceCurrentInfo[] {
  return [
    {
      label: "Autonomous mowing",
      value:
        response &&
        `${formatHours(response.time.autonomousMowing)} / ${
          response.distance.autonomousMowing
        } km`,
      textColor: DEVICE_DETAIL_COLORS.black,
    },
    {
      label: "Manual mowing",
      value:
        response &&
        `${formatHours(response.time.manualMowing)} / ${
          response.distance.manualMowing
        } km`,
      textColor: DEVICE_DETAIL_COLORS.black,
    },
  ];
}

export function getTransitTotals(
  response: StatisticsResponseT | undefined
): DeviceCurrentInfo[] {
  return [
    {
      label: "Autonomous transit",
      value:
        response &&
        `${formatHours(response.time.autonomousTransit)} / ${
          response.distance.autonomousTransit
        } km`,
      textColor: DEVICE_DETAIL_COLORS.black,
    },
    {
      label: "Manual transit",
      value:
        response &&
        `${formatHours(response.time.manualTransit)} / ${
          response.distance.manualTransit
        } km`,
      textColor: DEVICE_DETAIL_COLORS.black,
    },
  ];
}

export function getOptions(options: Record<string, string | null>): OptionT[] {
  return Object.entries(options).map(([key, value]) => ({
    name: value,
    id: parseInt(key),
  }));
}

type DateRangeT = {
  startDate: string;
  endDate: string;
  autonomousMowing: number;
  manualMowing: number;
  autonomousTransit: number;
  manualTransit: number;
};

function getDayName(date: Date, locale: string = LOCALE): string {
  return date.toLocaleDateString(locale, { weekday: "short" });
}

function toFullDate(date: Date): string {
  return `${date.getFullYear() % 100}/${date.getMonth() + 1}/${date.getDate()}`;
}

function toMonthDay(date: Date): string {
  return `${date.getMonth() + 1}/${date.getDate()}`;
}

function getRelativeDate(date: Date, relativeTo = new Date()): string {
  if (date.getFullYear() === relativeTo.getFullYear()) {
    return toMonthDay(date);
  }
  return toFullDate(date);
}

const WeekDuration = 7;
function formatDateRange(from: Date, until: Date): string {
  if (from.getTime() === until.getTime()) {
    const now = new Date();
    const { days } = intervalToDuration({ start: from, end: now });
    if (days !== undefined && days <= WeekDuration - 1) {
      return getDayName(from);
    }

    return getRelativeDate(from, now);
  }

  const startDate = getRelativeDate(from);
  if (
    from.getFullYear() === until.getFullYear() &&
    from.getMonth() === until.getMonth()
  ) {
    return `${startDate} - ${until.getDate()}`;
  }
  return `${startDate} - ${getRelativeDate(until, from)}`;
}

function chunkArray<T>(arr: T[], size: number): T[][] {
  return arr.reduce((acc, curr, i) => {
    const index = Math.floor(i / size);
    if (!acc[index]) acc[index] = [];
    acc[index].push(curr);
    return acc;
  }, [] as T[][]);
}

function combineDates(dates: DateT[]): DateRangeT | undefined {
  if (dates.length === 0) return undefined;
  if (dates.length === 1) {
    return {
      ...dates[0],
      startDate: dates[0].date,
      endDate: dates[0].date,
    };
  }

  let autonomousMowing = 0;
  let manualMowing = 0;
  let autonomousTransit = 0;
  let manualTransit = 0;
  for (const date of dates) {
    autonomousMowing += date.autonomousMowing;
    manualMowing += date.manualMowing;
    autonomousTransit += date.autonomousTransit;
    manualTransit += date.manualTransit;
  }
  return {
    startDate: dates[0].date,
    endDate: dates[dates.length - 1].date,
    autonomousMowing,
    manualMowing,
    autonomousTransit,
    manualTransit,
  };
}

function aggregateDates(dates: DateT[], window: number): DateRangeT[] {
  const chunks = chunkArray(dates, window);
  return chunks
    .map((chunk) => combineDates(chunk))
    .filter((range) => range !== undefined) as DateRangeT[];
}

function aggregateToSum(dates: DateT[]): DateRangeT[] {
  const aggregation = combineDates(dates);
  return aggregation ? [aggregation] : [];
}

function aggregateToMonths(dates: DateT[]): DateRangeT[] {
  const months: (DateRangeT | undefined)[] = [];
  let month: DateT[] = [];
  for (const date of dates) {
    const day = new Date(date.date).getDate();
    if (day === 1 && month.length > 0) {
      months.push(combineDates(month));
      month = [];
    }
    month.push(date);
  }
  if (month.length > 0) {
    months.push(combineDates(month));
  }
  return months.filter((range) => range !== undefined) as DateRangeT[];
}

function aggregateToWeeks(dates: DateT[]): DateRangeT[] {
  const firstStartOfWeek = dates.findIndex(
    (item) => new Date(item.date).getDay() === LOCALE_START_OF_WEEK
  );
  const lastStartOfWeek = findLastIndex(
    dates,
    (item) => new Date(item.date).getDay() === LOCALE_START_OF_WEEK
  );
  const firstWeek = combineDates(dates.slice(0, firstStartOfWeek));
  const middleWeeks = aggregateDates(
    dates.slice(firstStartOfWeek, lastStartOfWeek),
    WeekDuration
  );
  const lastWeek = combineDates(dates.slice(lastStartOfWeek));

  return [firstWeek, ...middleWeeks, lastWeek].filter(
    (range) => range !== undefined
  ) as DateRangeT[];
}

function aggregateToBiDays(dates: DateT[]): DateRangeT[] {
  return aggregateDates(dates, 2);
}

const SmallScreenBarLimit = 4;
const LargeScreenBarLimit = 8;
const aggregationRatios: Record<number, (dates: DateT[]) => DateRangeT[]> = {
  1: (dates: DateT[]) =>
    dates.map((date) => ({
      ...date,
      startDate: date.date,
      endDate: date.date,
    })),
  2: (dates: DateT[]) => aggregateToBiDays(dates),
  7: (dates: DateT[]) => aggregateToWeeks(dates),
  30: (dates: DateT[]) => aggregateToMonths(dates),
  [Number.MAX_VALUE]: (dates: DateT[]) => aggregateToSum(dates),
};

export function makeDatesFitScreen(
  dates: DateT[] | undefined,
  smallScreen: boolean
): DateT[] {
  if (!dates) return [];

  const limit = smallScreen ? SmallScreenBarLimit : LargeScreenBarLimit;
  const ratio = dates.length / limit;

  const smallestLargerRatio = Object.keys(aggregationRatios)
    .map(Number)
    .filter((r: number) => ratio <= r)[0];
  return aggregationRatios[smallestLargerRatio](dates).map((date) => ({
    autonomousMowing: date.autonomousMowing,
    manualMowing: date.manualMowing,
    autonomousTransit: date.autonomousTransit,
    manualTransit: date.manualTransit,
    date: formatDateRange(new Date(date.startDate), new Date(date.endDate)),
  }));
}
