import React, { FC } from "react";
import { Card, CardBody } from "reactstrap";
import Title from "../../atoms/Title/Title";
import { JobListItemT } from "../../../types/Job";
import { JobItem } from "./JobItem";

type PropsT = {
  jobs?: JobListItemT[];
  selectedJourneys: number[];
  onJourneySelect: (journeyIds: number[]) => void;
};

export const CardRouteLayers: FC<PropsT> = ({ jobs, onJourneySelect }) => {
  return (
    <div>
      <Title type="h2" size="h4">
        Activity layers
      </Title>
      <Card>
        <CardBody>
          <div className="d-flex flex-column gap-2">
            {jobs?.map((job) => (
              <JobItem
                key={job.id}
                onJourneySelect={onJourneySelect}
                job={job}
              />
            ))}
          </div>
        </CardBody>
      </Card>
    </div>
  );
};
