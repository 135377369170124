import React from "react";
import classNames from "classnames";
import { Progress } from "reactstrap";
import styles from "./progress.module.scss";

type Props = {
  value: number;
};

const ProgressBar = ({ value }: Props) => {
  return (
    <div className={styles["progress-wrapper"]}>
      <Progress value={value} className={classNames(styles.progress)} />
      <p className={classNames(styles.progress_description)}>
        {Math.round(value * 100) / 100}%
      </p>
    </div>
  );
};

export default ProgressBar;
