import React, { FC } from "react";
import Title from "../Title/Title";

export type PropsT = {
  title: string;
};

export const DescriptionTerm: FC<PropsT> = ({ title }) => {
  return (
    <dt>
      <Title type="h3" size="h6">
        {title}
      </Title>
    </dt>
  );
};
