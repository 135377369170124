import { FC } from "react";
import { StaticDateRangeT } from "../defautRanges";
import { Input } from "reactstrap";

type PropsT = {
  ranges: StaticDateRangeT[];
  selected: string;
  onRangeSelection: (range: StaticDateRangeT) => void;
  classNames?: string;
};

export const DefinedRange: FC<PropsT> = ({
  ranges,
  selected,
  onRangeSelection,
  classNames,
}: PropsT) => {
  const options = [<option key={-1} value=""></option>].concat(
    ranges.map((range, idx) => {
      return (
        <option value={range.label} key={idx}>
          {range.label}
        </option>
      );
    })
  );
  return (
    <Input
      className={classNames}
      type="select"
      bsSize="sm"
      value={selected}
      onChange={(e) => {
        const value = e.target.value;
        const selectedRange = ranges.find((range) => range.label === value);
        if (value && selectedRange) {
          onRangeSelection(selectedRange);
        }
      }}
    >
      {options}
    </Input>
  );
};
