import { useSearchParams as useSearchParamsDOM } from "react-router-dom";

enum SearchParamsKeys {
  search = "search",
  types = "types",
  status = "status",
  owner = "owner",
  showNearest = "showNearest",
  showArchive = "showArchive",
  folderId = "folderId",
  device = "device",
  userId = "userId",
  mowerId = "mowerId",
  pageIndex = "pageIndex",
  tab = "tab",
  userType = "userType",
  company = "company",
  auto = "auto",
  notificationType = "notificationType",
  page = "page",
  startDate = "startDate",
  endDate = "endDate",
}

type SearchParamsStateT = {
  [key in SearchParamsKeys]: string | null;
};

export const useSearchParams = () => {
  const [searchParams, setSearchParams] = useSearchParamsDOM();

  const initialState: SearchParamsStateT = {
    search: null,
    types: null,
    status: null,
    owner: null,
    showNearest: null,
    showArchive: null,
    folderId: null,
    device: null,
    userId: null,
    mowerId: null,
    pageIndex: null,
    tab: null,
    userType: null,
    company: null,
    auto: null,
    notificationType: null,
    page: null,
    startDate: null,
    endDate: null,
  };

  Object.keys(SearchParamsKeys).forEach((key) => {
    initialState[key as keyof typeof SearchParamsKeys] = searchParams.get(key);
  });

  const updateSearchParams = (
    callback: (initialState: SearchParamsStateT) => SearchParamsStateT
  ) => {
    const mutatedState = callback(initialState);
    const newSearchParams = new URLSearchParams();
    Object.entries(mutatedState).forEach(([key, value]) => {
      if (value) {
        newSearchParams.set(key, value);
      }
    });
    setSearchParams(newSearchParams);
  };

  const getParamValue = (key: keyof typeof SearchParamsKeys) =>
    searchParams.get(key);

  return { updateSearchParams, getParamValue };
};
