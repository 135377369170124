import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { client } from "../services/axios";
import { MutationOptionsT } from "../types/ReactQuery";
import { ApiErrorT } from "../types/Common";

type UserInviteDataT = {
  userId: number;
  approved: boolean;
  company?: number[];
};

export const useMutationUserDecide = (options?: MutationOptionsT) => {
  return useMutation(
    ({ userId, approved, company }: UserInviteDataT) =>
      client.put(`/api/v1/user/registration-decide/${userId}`, {
        approved,
        company,
      }),
    {
      onSuccess: (_, { approved }) => {
        toast.success(approved ? "User approved" : "User rejected");
        if (options?.onSuccess) {
          options.onSuccess();
        }
      },
      onError: (error: ApiErrorT, { approved }) => {
        toast.error(
          error?.message || approved
            ? "Failed to approve user"
            : "Failed to reject user"
        );
        if (options?.onError) {
          options.onError();
        }
      },
    }
  );
};
