import { FC } from "react";
import { StaticDateRangeT } from "../defautRanges";
import classNames from "classnames";
import style from "./DefinedRangeList.module.scss";

type PropsT = {
  ranges: StaticDateRangeT[];
  selected: string;
  onRangeSelection: (range: StaticDateRangeT) => void;
  classNames?: string;
};

export const DefinedRangeList: FC<PropsT> = ({
  ranges,
  selected,
  onRangeSelection,
  classNames: cNames,
}: PropsT) => {
  const items = ranges.map((range) => (
    <li
      key={range.label}
      onClick={() => {
        if (selected !== range.label) {
          onRangeSelection(range);
        }
      }}
      className={classNames(style["list-btn"], {
        [style["list-btn-highlighted"]]: selected === range.label,
      })}
    >
      {range.label}
    </li>
  ));
  return (
    <div className={cNames}>
      <ul className={style.list}>{items}</ul>
    </div>
  );
};
