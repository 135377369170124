import React, { FC, Fragment, useState } from "react";
import { DATE_FORMATS } from "../../../constants";
import { formatDate } from "../../../functions/formatDate";
import { JobCommentT } from "../../../types/Job";
import { LoadMoreButton } from "../../atoms/LoadMoreButton/LoadMoreButton";

type PropsT = {
  comments: JobCommentT[];
};

const NUM_OF_COMMENTS_SHOWN = 3;

export const JobCommentsList: FC<PropsT> = ({ comments }) => {
  const numOfHiddenComments = comments.length - NUM_OF_COMMENTS_SHOWN;
  const [isShowingAll, setIsShowingAll] = useState(false);
  const isBtnShown = !isShowingAll && numOfHiddenComments > 0;

  return (
    <div>
      {[...comments]
        .sort(
          (a, b) =>
            new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        )
        .map((comment, index) => {
          if (!isShowingAll && index >= NUM_OF_COMMENTS_SHOWN) {
            return null;
          }
          const { id, createdAt, comment: body, surname, firstName } = comment;
          return (
            <Fragment key={id}>
              <p className="mb-2">
                <strong>
                  {firstName} {surname}
                </strong>
                <span className="p-2">
                  {formatDate(createdAt, DATE_FORMATS.dateTime)}
                </span>
              </p>
              <p>{body}</p>
            </Fragment>
          );
        })}
      {isBtnShown && (
        <LoadMoreButton
          title={`Show more (${numOfHiddenComments})`}
          onClick={() => setIsShowingAll(true)}
        />
      )}
    </div>
  );
};
