import classNames from "classnames";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { Card } from "reactstrap";
import { useQueryUfonStatusGet } from "../../../hooks/useQueries";
import { useSelector } from "../../../store/hooks";
import { setUfonStatus } from "../../../store/slices/ufonSlice";
import { ROUTES } from "../../../routes";
import { Loader } from "../../../components/atoms/Loader/Loader";
import { DeviceStatusItem } from "../../../components/atoms/DeviceStatusItem/DeviceStatusItem";
import { DeviceMessage } from "../../../components/atoms/DeviceMessage/DeviceMessage";
import { CardHeader } from "../../../components/atoms/CardHeader/CardHeader";
import styles from "./ufonInfoCard.module.scss";

const UfonInfoCard = ({
  ufonId,
  showFull = false,
}: {
  ufonId: number;
  showFull?: boolean;
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { data: ufonData, isLoading, error } = useQueryUfonStatusGet(ufonId);

  const ufonStatus = useSelector(
    (store) => store.ufon.ufons[ufonId]?.ufonStatus
  );

  useEffect(() => {
    if (ufonData) {
      dispatch(setUfonStatus({ ufonStatus: ufonData.data, ufonId }));
    }
  }, [ufonData, ufonId, dispatch]);

  if (!ufonStatus) {
    return error ? (
      <Card className={styles["device-disabled"]}>
        <CardHeader icon="broadcast" title="Default ufon" size="sm" />
        <DeviceMessage text={error.message} />
      </Card>
    ) : (
      <Card className={styles["device-disabled"]}>
        <CardHeader icon="broadcast" title="Default ufon" size="sm" />
      </Card>
    );
  }

  if (isLoading) {
    return <Loader isCentered />;
  }

  return (
    <Card
      className={classNames("mb-2", styles.wrapper)}
      onClick={() => navigate(ROUTES.ufon(ufonId))}
    >
      <CardHeader
        title={ufonStatus.name}
        icon="broadcast"
        size="sm"
        onClick={() => navigate(ROUTES.ufon(ufonId))}
      />
      {ufonStatus && (
        <>
          <div style={{ display: "flex", gap: "16px" }}>
            <DeviceStatusItem
              led={ufonStatus.status.lte.led}
              text={`LTE${
                ufonStatus.status.lte.additionalValue
                  ? ` ${ufonStatus.status.lte.additionalValue}%`
                  : ""
              }`}
              classNameWrapper={styles["led-wrapper"]}
              classNameSpan={styles["led-span"]}
            />
            <DeviceStatusItem
              led={ufonStatus.status.position.led}
              text="GPS"
              classNameWrapper={styles["led-wrapper"]}
              classNameSpan={styles["led-span"]}
            />
            <DeviceStatusItem
              led={ufonStatus.status.battery.led}
              classNameWrapper={styles["led-wrapper"]}
              classNameSpan={styles["led-span"]}
              text={`Battery${
                ufonStatus.status.battery.additionalValue
                  ? ` [${ufonStatus.status.battery.additionalValue}%]`
                  : ""
              }`}
            />
          </div>
          {ufonStatus.message && showFull && (
            <DeviceMessage
              text={ufonStatus.message.message ?? ""}
              additionalMessage={ufonStatus.message.additionalMessage}
              status={ufonStatus.message.type}
              help={ufonStatus.message.help}
            />
          )}
        </>
      )}
    </Card>
  );
};

export default UfonInfoCard;
