import React, { FC } from "react";
import { useDispatch } from "react-redux";
import { Button, Modal, ModalBody } from "reactstrap";
import { updateTaskAction } from "../../../store/slices/tasksSlice";
import Title from "../Title/Title";

type PropsT = {
  isOpen: boolean;
  jobId: string;
  mowerId: number | null;
  onClose: () => void;
};

export const TaskNoGoZonesDialog: FC<PropsT> = ({
  isOpen,
  jobId,
  mowerId,
  onClose,
}) => {
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(
      updateTaskAction({
        jobId,
        type: "setNoGoZones",
        newStatus: "start",
        options: {
          mowerId: mowerId || undefined,
        },
      })
    );
    onClose();
  };

  return (
    <Modal isOpen={isOpen} toggle={onClose}>
      <ModalBody>
        <Title type="h3">Skip “no go” zones?</Title>
        <div className="d-flex">
          <div>
            <Button
              color="primary"
              size="sm"
              className="me-2 mb-2"
              onClick={handleClick}
            >
              Yes, skip
            </Button>
          </div>
          <div>
            <Button onClick={onClose} color="danger" size="sm">
              Cancel
            </Button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};
