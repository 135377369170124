import classNames from "classnames";
import styles from "./divider.module.scss";

type Props = {
  className?: string;
};

export default function HorizontalDivider({ className }: Props) {
  return <div className={classNames(styles.divider, className)} />;
}
