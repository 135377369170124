import { toast } from "react-toastify";
import { SelectionTypes, SelectionTypesT } from "../types/Notification";

type ToastTypes = "error" | "success";

export const showToastMessage = (type: ToastTypes, message: string) => {
  toast[type](message);
};

export const parseSelection = (
  potentialSelection: string | null
): SelectionTypesT | undefined => {
  const selections = Object.keys(SelectionTypes) as SelectionTypesT[];
  return selections.find((select) => select === potentialSelection);
};
