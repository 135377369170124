import { Fragment } from "react";
import { Table } from "reactstrap";
import { useCompactDesign } from "../../../hooks/useCompactDesign";
import { Loader } from "../../atoms/Loader/Loader";
import { UserGridRow } from "../GridRows/UserGridRow";
import { JobGridRow } from "../GridRows/JobGridRow";
import { CompanyGridRow } from "../GridRows/CompanyGridRow";
import { DeviceGridRow } from "../GridRows/DeviceGridRow";
import { RouteGridRow } from "../GridRows/GridRowRoute";
import { NotificationGridRow } from "../GridRows/NotificationGridRow";
import styles from "./dataGrid.module.scss";

type Props<T> = {
  headers: string[];
  data: T[];
  rowComponent: string;
  onRefetechData?: () => void;
  onDelete?: (item: T) => void;
  onCopy?: (item: T) => void;
  onUpdate?: (item: T) => void;
  onCheck?: (id: number, jobType: number) => void;
  onAccept?: (item: T) => void;
  onReject?: (item: T) => void;
  checkedJobs?: number[];
  isLoading?: boolean;
  isLeica?: boolean;
  isMerging?: boolean;
  dividerPosition?: number;
  isPendingUser?: boolean;
};

type ComponentMappingT = {
  // eslint-disable-next-line
  [id: string]: any;
};

const componentsMapping: ComponentMappingT = {
  UserGridRow,
  DeviceGridRow,
  RouteGridRow,
  JobGridRow,
  CompanyGridRow,
  NotificationGridRow,
};

export const DataGrid = <T extends object | number>({
  headers,
  data,
  rowComponent,
  onRefetechData,
  onDelete,
  onCopy,
  onUpdate,
  onCheck,
  onAccept,
  onReject,
  checkedJobs,
  isLoading,
  isLeica,
  isMerging,
  dividerPosition,
}: Props<T>) => {
  const isCompact = useCompactDesign();

  return (
    <div className="position-relative">
      <Loader isOverlay isLoading={isLoading} />
      <Table className={isCompact ? styles.table : styles["table--large"]}>
        <thead>
          <tr>
            {headers.map((header, index) => (
              <th key={index}>{header}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((row: T, index: number) => {
            const TypeComponent = componentsMapping[rowComponent];

            return (
              <Fragment key={`item-${index}`}>
                <TypeComponent
                  data={row}
                  onRefetechData={onRefetechData}
                  onDelete={onDelete}
                  isLeica={isLeica}
                  onCopy={onCopy}
                  onUpdate={onUpdate}
                  onCheck={onCheck}
                  onAccept={onAccept}
                  onReject={onReject}
                  checkedJobs={checkedJobs}
                  isMerging={Boolean(isMerging)}
                />
                {dividerPosition === index && (
                  <tr className={styles.divider}>
                    <td />
                  </tr>
                )}
              </Fragment>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};
