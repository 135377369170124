import React, { FC } from "react";
import Title from "../../components/atoms/Title/Title";
import { StyledButton } from "../../components/atoms/Button/Button";
import { FormLogin } from "../../components/molecules/FormLogin/FormLogin";
import { useDispatch } from "react-redux";
import { authUserAction } from "../../store/slices/userSlice";
import { useSelector } from "../../store/hooks";
import { LoginScreenLayout } from "../../components/organisms/LoginScreenLayout/LoginScreenLayout";
import { useNavigate } from "react-router";
import { ROUTES } from "../../routes";
import { WINDOW_TITLE_NAMES } from "../../constants";

export const Login: FC = () => {
  document.title = WINDOW_TITLE_NAMES.default;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isLoading = useSelector((store) => store.user.isAuthLoading);

  return (
    <LoginScreenLayout>
      <Title type="h1" size="h5">
        Login
      </Title>

      <FormLogin onSubmit={(data) => dispatch(authUserAction(data))} />

      <StyledButton
        isLoading={isLoading}
        title="Log in"
        size="lg"
        color="primary"
        icon="check"
        block
        className="mb-3"
        form="login-form"
      />

      <StyledButton
        onClick={() => navigate(ROUTES.lostPassword())}
        className="mb-5"
        tag="a"
        block
        color="link"
        size="sm"
        title="Forgot password"
      />
    </LoginScreenLayout>
  );
};
