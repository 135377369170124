import { getLayerName } from "../../../functions/mapLayers";
import { DropdownItemPerimeter } from "../../../pages/Job/types";
import { MapMarkersT } from "../../../types/Common";
import {
  CoordinateT,
  CoordinatesT,
  LatLngT,
  StylesT,
} from "../../../types/Route";
import { DropdownItemT } from "./MapDropdown";

// for card map in mower detail page
export const getPolylineOptions = (
  styleType: keyof StylesT<"journey" | "route">,
  coordinatesStyles: StylesT<"journey" | "route">,
  showArrows: boolean,
  clickable = false
): google.maps.PolylineOptions => {
  const { LineStyle } = coordinatesStyles[styleType];

  return {
    strokeColor: `#${LineStyle.color}`,
    strokeOpacity: 0.8,
    strokeWeight: LineStyle.width,
    clickable,
    draggable: false,
    zIndex: 1000,
    icons: showArrows
      ? [
          {
            icon: {
              path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
              scale: 1.5,
            },
            repeat: "30px",
          },
        ]
      : [],
  };
};

export const getPolygonOptions = (
  path: LatLngT[],
  styleType: keyof StylesT<"route" | "journey">,
  coordinatesStyles: StylesT<"route" | "journey">
): google.maps.PolygonOptions => {
  const currentStyle = coordinatesStyles[styleType];
  const fillColor = currentStyle.PolyStyle?.color;
  const { color, width } = currentStyle.LineStyle;

  return {
    strokeColor: `#${color}`,
    strokeOpacity: 0.8,
    strokeWeight: width,
    clickable: true,
    paths: path,
    zIndex: 1,
    fillColor: "#" + fillColor,
    fillOpacity: 0.5,
  };
};

export const getMapBounds = (coordinates?: CoordinatesT<"route">) => {
  const bounds = new window.google.maps.LatLngBounds();
  coordinates?.coordinates.forEach((coordinate) => {
    coordinate.coordinates.forEach((marker) => {
      bounds.extend({ lat: marker.lat, lng: marker.lng });
    });
  });
  return bounds;
};

export const getMapBoundsFromDevices = (devices?: MapMarkersT[]) => {
  const bounds = new window.google.maps.LatLngBounds();
  devices?.forEach((device) =>
    device.items.forEach(({ latitude, longitude }) => {
      if (latitude && longitude) {
        bounds.extend({ lat: latitude, lng: longitude });
      }
    })
  );
  return bounds;
};

export type RoutePageLayers = {
  geofence?: number[];
  noGoZone?: number[];
  route?: number[];
};

export type RoutePageLayersType = "geofence" | "noGoZone" | "route";

export const getRoutePageDropdownItems = (
  type: RoutePageLayersType,
  coordinateItems?: CoordinatesT<"route">
) => {
  if (!coordinateItems) return [];

  const { coordinates, styles: coordStyles } = coordinateItems;
  const dropdownItems: DropdownItemPerimeter[] = [];
  coordinates.forEach((coord, index) => {
    if (type === "geofence" && coord.styleType !== "geofence") {
      return;
    }
    if (type === "noGoZone" && coord.styleType !== "noGoZone") {
      return;
    }
    if (
      type === "route" &&
      coord.styleType !== "endPoint" &&
      coord.styleType !== "startPoint" &&
      coord.styleType !== "mowingRoute" &&
      coord.styleType !== "transitRoute" &&
      coord.styleType !== "noGpsMowing"
    ) {
      return;
    }
    const color = "#" + coordStyles[coord.styleType].LineStyle.color;
    const duplicatedItemIndex = dropdownItems.findIndex((item) => {
      return item.title === getLayerName(coord.styleType);
    });
    if (duplicatedItemIndex !== -1) {
      dropdownItems[duplicatedItemIndex].ids.push(index);
    } else {
      dropdownItems.push({
        title: getLayerName(coord.styleType),
        ids: [index],
        color,
        uniqueId: coord.styleType,
      });
    }
  });
  return dropdownItems;
};

export const getDropdownItems = (coordinateItems: CoordinatesT<"journey">) => {
  const { coordinates, styles: coordStyles } = coordinateItems;
  const dropdownItems: DropdownItemT<string>[] = coordinates.map(
    ({ styleType }) => ({
      label: getLayerName(styleType),
      id: styleType,
      color: "#" + coordStyles[styleType].LineStyle.color,
    })
  );
  return dropdownItems;
};

export const activeLayersSetter = (coord: CoordinateT<"route">[]) => {
  const layers: RoutePageLayers = {
    geofence: [],
    noGoZone: [],
    route: [],
  };
  for (let i = 0; i < coord.length; i++) {
    if (
      coord[i].styleType === "endPoint" ||
      coord[i].styleType === "startPoint" ||
      coord[i].styleType === "mowingRoute" ||
      coord[i].styleType === "transitRoute" ||
      coord[i].styleType === "noGpsMowing"
    ) {
      if (!layers.route?.includes(i)) {
        layers.route?.push(i);
        continue;
      }
    } else if (coord[i].styleType === "geofence") {
      if (!layers.geofence?.includes(i)) {
        layers.geofence?.push(i);
        continue;
      }
    } else if (coord[i].styleType === "noGoZone") {
      if (!layers.noGoZone?.includes(i)) {
        layers.noGoZone?.push(i);
        continue;
      }
    }
  }
  Object.entries(layers).map((layer) => {
    if (layer[1].length === 0) {
      const key = layer[0] as RoutePageLayersType;
      delete layers[key];
    }
  });
  return layers;
};
