import { StyledButton } from "../Button/Button";
import styles from "./loadMoreButton.module.scss";

type Props = {
  title?: string;
  isLoading?: boolean;
  onClick: () => void;
};

export const LoadMoreButton: React.FC<Props> = ({
  title,
  isLoading,
  onClick,
}) => {
  return (
    <div className={styles["load-more"]}>
      <StyledButton
        isLoading={isLoading}
        color="dark"
        onClick={onClick}
        outline
        title={title || "Load more"}
        block
      />
    </div>
  );
};
