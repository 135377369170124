import { useParams } from "react-router";
import { useMowerTracker } from "../../../hooks/useMowerTracker";
import { DeviceTypes } from "../../../types/Common";
import DeviceDetailMap from "../../../components/molecules/DeviceDetailMap/DeviceDetailMap";
import { useSelector } from "../../../store/hooks";

export default function MowerMapTab() {
  const { mowerId } = useParams();
  const trackerData = useMowerTracker(Number(mowerId));
  const isLoading = useSelector((store) => store.mower.isLoading);

  return (
    <DeviceDetailMap
      deviceType={DeviceTypes.mower}
      center={trackerData?.currentLocation ?? undefined}
      deviceId={Number(mowerId)}
      isLoading={isLoading}
    />
  );
}
