import { useMutation } from "react-query";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { ROUTES } from "../routes";
import { client } from "../services/axios";
import { ApiErrorT } from "../types/Common";
import { MutationOptionsT } from "../types/ReactQuery";
import { RouteInputsT } from "../components/molecules/FormRoute/FormChangeRoute";

/**
 * Hook to edit the data (route) of a certain Route.
 * We send the `routeId` of an existing Route and the new data
 */
export const useMutationRouteChange = (options?: MutationOptionsT) => {
  const navigate = useNavigate();

  return useMutation(
    (data: RouteInputsT) => {
      const formData = new FormData();
      formData.append("routeId", String(data.route));
      formData.append("file", data.file[0]);
      return client.post(`/api/v1/route/import`, formData);
    },
    {
      onSuccess: (data) => {
        toast.success("Route has been edited");
        if (options?.onSuccess) {
          options.onSuccess();
        }
        navigate(ROUTES.route(data.data.id));
      },
      onError: (error: ApiErrorT) => {
        toast.error(error?.message);
        if (options?.onError) {
          options.onError();
        }
      },
    }
  );
};
