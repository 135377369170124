import React, { useRef } from "react";
import classNames from "classnames";
import { Link, useNavigate } from "react-router-dom";
import { DEVICE_ICONS } from "../../../constants";
import { ROUTES } from "../../../routes";
import { formatDate } from "../../../functions/formatDate";
import { DeviceListItemT, DeviceTypes } from "../../../types/Common";
import { useCompactDesign } from "../../../hooks/useCompactDesign";
import { usePermissions } from "../../../hooks/usePermissions";
import { useObserveHeight } from "../../../hooks/useObserveHeight";
import { ActionButton } from "../../atoms/ActionButton/ActionButton";
import { ActionDivider } from "../../atoms/ActionDivider/ActionDivider";
import { Icon } from "../../atoms/Icon";
import { OnlineStatus } from "../../atoms/Status/OnlineStatus";
import { useConfirm } from "../ConfirmBoxProvider/ConfirmBoxProvider";
import { getUsersNames } from "./function";
import styles from "./gridRow.module.scss";

type Props = {
  data: DeviceListItemT;
  onRefetechData: () => void;
  onDelete?: (item: DeviceListItemT) => void;
};

export const DeviceGridRow: React.FC<Props> = ({ data, onDelete }) => {
  const permissions = usePermissions();
  const navigate = useNavigate();
  const confirm = useConfirm();
  const isMower = data.type === DeviceTypes.mower;
  const isUfon = data.type === DeviceTypes.ufon;
  let deviceLink = "";
  let deviceEditLink = "";
  let deviceCopyLink = "";
  if (isMower) {
    deviceLink = ROUTES.mower(data.id);
    deviceEditLink = ROUTES.mowerEdit(data.id);
    deviceCopyLink = `${ROUTES.mowerCreate()}?mowerId=${data.id}`;
  }
  if (isUfon) {
    deviceLink = ROUTES.ufon(data.id);
    deviceEditLink = ROUTES.ufonEdit(data.id);
    deviceCopyLink = `${ROUTES.ufonCreate()}?ufonId=${data.id}`;
  }
  const isCompact = useCompactDesign();

  const rowRef = useRef<HTMLTableRowElement>(null);
  const linkHeight = useObserveHeight(rowRef);

  const onCopy = () => {
    confirm({
      title: "Copy device",
      subTitle: "Create copy of this device?",
      callback: () => navigate(deviceCopyLink),
      type: "ok",
    });
  };

  const handleDelete = () => {
    onDelete?.(data);
  };

  const handleEdit = () => {
    navigate(deviceEditLink);
  };

  const handleNewJob = () => {
    navigate(
      `${ROUTES.jobCreate()}?type=Manual&${
        data.type === DeviceTypes.mower ? `mowerId` : `ufonId`
      }=${data.id}`
    );
  };

  return (
    <tr
      ref={rowRef}
      className={isCompact ? styles["grid-row"] : styles["grid-row--large"]}
    >
      <td
        className={classNames(
          styles["grid-row__cell"],
          isCompact && styles["grid-row__cell--compact"]
        )}
      >
        <Icon
          icon={DEVICE_ICONS[data.type]}
          className={styles["grid-row__type-icon"]}
        />
      </td>
      <td
        className={classNames(
          styles["grid-row__cell"],
          isCompact && styles["grid-row__cell--compact"],
          isCompact && styles["grid-row__cell--head-title--compact"]
        )}
      >
        <span
          className={classNames(
            styles["grid-row__cell-head-link"],
            isCompact && styles["grid-row__cell-head-link--compact"]
          )}
        >
          {data.name}
          {isCompact && (
            <Icon
              icon={"angle-right"}
              className={styles["grid-row__cell-head-icon"]}
            />
          )}
        </span>
      </td>
      {data.company && (
        <td
          className={classNames(
            styles["grid-row__cell"],
            isCompact && styles["grid-row__cell--compact"],
            isCompact && styles["grid-row__cell--3--compact"]
          )}
        >
          {data.company.name}
        </td>
      )}
      <td
        className={classNames(
          styles["grid-row__cell"],
          isCompact && styles["grid-row__cell--compact"],
          isCompact && styles["grid-row__cell--3--compact"]
        )}
      >
        <OnlineStatus status={data.isOnline ? "Online" : "Offline"} />
      </td>
      <td
        className={classNames(
          styles["grid-row__cell"],
          isCompact && styles["grid-row__cell--compact"],
          isCompact && styles["grid-row__cell--6--compact"]
        )}
      >
        {data.lastSeen && formatDate(new Date(data.lastSeen), "dd.MM.Y H.mm")}
        {data.lastLocation && ` near ${data.lastLocation}`}
      </td>
      <td
        className={classNames(
          styles["grid-row__cell"],
          isCompact && styles["grid-row__cell--compact"],
          isCompact && styles["grid-row__cell--3--compact"]
        )}
      >
        <span>{getUsersNames(data.users)}</span>
        {permissions.deviceManage && (
          <div className={styles.actions}>
            <ActionButton
              onClick={handleNewJob}
              icon="play-circle"
              tagTitle="Create job"
            />
            <ActionDivider />
            <ActionButton onClick={onCopy} icon="clone" tagTitle="Copy" />
            <ActionButton onClick={handleEdit} icon="pen" tagTitle="Edit" />
            <ActionButton
              onClick={handleDelete}
              icon="trash"
              tagTitle="Delete"
            />
          </div>
        )}
      </td>
      <td className={styles.superLinkCell} style={{ height: linkHeight }}>
        <Link to={deviceLink} className={styles.superLink} />
      </td>
    </tr>
  );
};
