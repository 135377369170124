import { JOB_STATUSES, JOB_TYPE_IDS } from "../constants";
import { GeostickT } from "./Geostick";
import { MowerPreviewT } from "./Mower";
import { LatLngT, RoutePreviewT } from "./Route";
import { UfonPreviewT } from "./Ufon";
import { MowerProgressT, UserMowerT, UserPreviewT } from "./User";
import { MowerReplyTypes, WSEventType } from "./Websocket";

export type JobListItemT = {
  id: number;
  name: string;
  percentDone: number;
  status: JobStatusT;
  dueDate: Date;
  startTime?: Date;
  endTime?: Date;
  users: UserPreviewT[];
  type: JobTypeT;
  mowers?: MowerPreviewT[];
  journeys: number[];
  archived: boolean;
};

export type JobWithStatusT = {
  status: JobStatusT;
  archived: boolean;
};

export type JobsList = {
  items: JobListItemT[];
  filterCount: FilterCountT;
  totalCount: number;
};

export type JobStatusNameT = keyof typeof JOB_STATUSES;

export type JobStatusT = {
  id: number;
  name: JobStatusNameT;
};

export type JobProgress = {
  mowerId: number;
  overall: MowerProgressT;
  segments?: {
    segmentId: number;
    progress: MowerProgressT;
  }[];
};

export enum JobTypeNamesEnum {
  Manual = "Manual",
  Route = "Route",
  Perimeter = "Perimeter",
  Auto = "Auto",
  Survey = "Survey",
}

export type JobTypesIdsT = typeof JOB_TYPE_IDS[keyof typeof JOB_TYPE_IDS];

type JobTypeFilterValuesT =
  | "manual"
  | "route"
  | "perimeter"
  | "quick"
  | "survey";

export type JobTypeT = {
  id: number;
  name: JobTypeNamesEnum;
  filterValue: JobTypeFilterValuesT;
};

export enum TaskStatusEnum {
  start = "start",
  pause = "pause",
  resume = "resume",
  done = "done",
  pending = "pending",
  readyForDone = "readyForDone",
}

export enum TaskTypesEnum {
  setupUfon = "setupUfon",
  moweMowerToStartPoint = "moweMowerToStartPoint",
  manualModeToSetPerimeter = "manualModeToSetPerimeter",
  setNoGoZones = "setNoGoZones",
  turnOffRecordingMode = "turnOffRecordingMode",
  calculateRoute = "calculateRoute",
  uploadRouteToMower = "uploadRouteToMower",
  turnOnAutonomousMode = "turnOnAutonomousMode",
  moweMowerToRouteStartPoint = "moweMowerToRouteStartPoint",
  startMowing = "startMowing",
  collectMower = "collectMower",
  collectUfon = "collectUfon",
}

export type FilterCountT = {
  owner: {
    delegated: number;
    my: number;
  };
  status: {
    1: number; // open
    2: number; // overdue
    3: number; // finished
  };
  types: {
    manual: number;
    route: number;
    perimeter: number;
    auto: number;
  };
};

export type JobInfoStatusT = {
  mower?: MowerPreviewT[];
  ufon?: UfonPreviewT;
  route?: RoutePreviewT[];
  journeys: number[];
  allMowers: number[];
};

export type TaskTypeT = keyof typeof TaskTypesEnum;

export type TaskStatusT = keyof typeof TaskStatusEnum;

export type TaskWaitForT = {
  entityId: number;
  eventType: WSEventType;
  key?: "type";
  value?: MowerReplyTypes;
};

export type TaskT = {
  finishedAt: Date | null;
  mowerId: number | null;
  name: string;
  progressAt: string | null;
  startAt: Date | null;
  status: TaskStatusT | null;
  type: TaskTypeT;
  waitFor: TaskWaitForT;
  subtask: { items: NogoZoneT[] };
  isActive: boolean;
  hideButton: boolean;
};

export type NogoZoneT = {
  id: number;
  type: string;
  name: string;
  startAt: string;
  progressAt: string | null;
  finishedAt: string | null;
  status: string;
  coordinates: { lat: number; lon: number }[] | null;
};

export type TaskConfigT = [TaskTypeT, TaskStatusT, Object];

export type JobHistory = {
  date: Date;
  segment: {
    name: string;
  };
  user: {
    id: number;
    name: string;
  };
  mower: {
    id: number;
    name: string;
  };
};

export type JobUserTaskT = {
  canCompleteJob: boolean;
  infoStatus: JobInfoStatusT;
  tasks: TaskT[];
  user: UserPreviewT;
  isCompletedJob: boolean;
};

export type JobMutateStateInputT = {
  type: TaskTypeT;
  status: string;
  options?: { mowerId?: number };
};

export type JobMutateFunctionT = (
  type: TaskTypeT,
  status: string,
  options?: {
    mowerId?: number;
    folderId?: number;
    diameter?: number;
    name?: string;
  }
) => void;

export type JobCommentT = {
  comment: string;
  createdAt: Date;
  id: number;
  userId: number;
  firstName: string;
  surname: string;
};

export type JobMergeOptionT = {
  jobs: number[];
  mower: { id: number; name: string };
};

export type JobBaseT = {
  description: string;
  dueDate: Date;
  id: number;
  name: string;
  status: JobStatusT;
  type: JobTypeT;
  users: UserPreviewT[];
  mowers: { id: number; name: string; color: Color }[];
  location: string;
  journeys: number[];
  latitude?: number | null;
  longitude?: number | null;
  route?: RoutePreviewT;
  startedAt: Date | null;
  progress: JobProgress[];
  allStepsFinished: boolean;
  archived: boolean;
};

export type JobT = JobBaseT & {
  geostick: undefined;
  segments: {
    users: UsersBySegment[];
  };
};

export type GeostickJobT = JobBaseT & {
  geostick: GeostickT;
};

export type UsersBySegment = {
  id: number;
  name: string;
  mowers: MowersBySegment[];
};

export type MowersByUser = {
  userId: number;
  userName: string;
  mowers: (UserMowerT & { color: Color })[];
};

export type Color = {
  primaryColor: string;
  secondaryColor: string;
};

export type MowersBySegment = {
  id: number;
  name: string;
  routeAlreadyUploaded: boolean;
  journeys: number[];
  segmentName: string;
  segmentId: number;
  segmentColor: Color;
  status: "active" | "finished";
};

export type JobSegmentsT = {
  users: {
    id: number;
    name: string;
    mowers: {
      id: number;
      name: string;
      selected: boolean;
    }[];
  }[];
  routeInfo: {
    length: string;
    time: string;
  };
  remainingInfo: {
    length: string;
    time: string;
  };
  inputs: {
    criterion: {
      name: string;
      items: {
        value: number;
        name: string;
      };
    }[];
  };
};

export type MissedCoorT = {
  mowerId: number;
  coordinates: LatLngT[][];
};

export type NextCoordinatesByMower = {
  mowerId: number;
  segment: {
    id: number;
    coordinates: LatLngT[];
  }[];
  general: {
    coordinates: LatLngT[];
  };
};

export type RouteComputeStatus = {
  isDone: boolean;
  isFailed: boolean;
  status: string;
  percentage: number;
  message: string | null;
  jobId: number;
};
