import React, { Fragment, useState } from "react";
import { Marker, Polyline } from "@react-google-maps/api";
import { MowersBySegment } from "../../../../types/Job";
import {
  CoordinatesT,
  JobMapPointInfo,
  RouteLayers,
  StylesT,
} from "../../../../types/Route";
import { getPolylineOptions } from "../../../Job/functions";
import { getEndPointIcon, getStartPointIcon } from "../../utils";
import { JOB_ROUTE_TYPE_COLORS } from "../../../../constants";
import JobMapInfoWindow from "../../../../components/molecules/MapInfoWindow/JobMapInfoWindow";

type PropsT = {
  mower: MowersBySegment;
  showArrows: boolean;
  showLastSegments: boolean;
  segmentsCoordinates: Record<number, CoordinatesT<"route">>;
  activeLayers: Record<number, RouteLayers> | null;
  // tempNogoCoordinates: NogoZoneT[] | undefined;
  segment: {
    id: number;
    name: string;
    color: {
      primaryColor: string;
      secondaryColor: string;
    };
  };
};

export const SegmentPolylines = ({
  mower,
  showArrows,
  segment,
  activeLayers,
  segmentsCoordinates,
}: PropsT) => {
  const stylesFromBE = segmentsCoordinates[mower.segmentId]?.styles;

  const [pointActiveMarker, setPoinActiveMarker] = useState<
    JobMapPointInfo | undefined
  >(undefined);

  if (
    !segmentsCoordinates[mower.segmentId] ||
    !activeLayers ||
    !activeLayers[mower.segmentId]
  ) {
    return null;
  }

  return (
    <>
      {stylesFromBE &&
        segmentsCoordinates[mower.segmentId].coordinates.map(
          (coordinate, index) => {
            const isVisibleRoute =
              activeLayers[mower.segmentId].routeChecked &&
              activeLayers[mower.segmentId].route.includes(index);
            const isVisibleEndPoint =
              activeLayers[mower.segmentId].endPointChecked &&
              activeLayers[mower.segmentId].endPoint === index;
            const isVisibleStartPoint =
              activeLayers[mower.segmentId].startPointChecked &&
              activeLayers[mower.segmentId].startPoint === index;
            const { coordinates: latLng, id, styleType } = coordinate;
            const isPolygon = Boolean(stylesFromBE[styleType].PolyStyle);
            const options = getPolylineOptions(
              styleType,
              stylesFromBE as StylesT<"route">,
              showArrows && !isPolygon,
              segment.color
            );
            if (!coordinate.isPoint) {
              return (
                <Fragment key={id}>
                  <Polyline
                    visible={isVisibleRoute}
                    key={`polyline-item-${id}`}
                    path={latLng}
                    options={options}
                  />
                </Fragment>
              );
            } else {
              const lat = latLng[0].lat || 0;
              const lng = latLng[0].lng || 0;
              return (
                <Marker
                  key={`marker-${index}`}
                  position={{ lat, lng }}
                  zIndex={mower.segmentId}
                  visible={
                    styleType === "endPoint"
                      ? isVisibleEndPoint
                      : isVisibleStartPoint
                  }
                  icon={{
                    url:
                      styleType === "endPoint"
                        ? getEndPointIcon(segment.color.primaryColor)
                        : getStartPointIcon(segment.color.primaryColor),
                    scaledSize:
                      styleType === "endPoint"
                        ? new google.maps.Size(18, 18)
                        : new google.maps.Size(30, 30),
                    anchor:
                      styleType === "endPoint"
                        ? new google.maps.Point(9, 9)
                        : undefined,
                  }}
                  onClick={() =>
                    setPoinActiveMarker({
                      styleType,
                      name:
                        styleType === "endPoint"
                          ? `${segment.name.toLocaleUpperCase()} - END POINT`
                          : `${segment.name.toLocaleUpperCase()} - START POINT`,
                      lat,
                      lng,
                      segment: {
                        id: segment.id,
                        name: segment.name,
                        color: segment.color.primaryColor,
                      },
                    })
                  }
                />
              );
            }
          }
        )}
      {pointActiveMarker && (
        <JobMapInfoWindow
          onClose={() => setPoinActiveMarker(undefined)}
          title={pointActiveMarker.name}
          pixelOffset={
            pointActiveMarker.styleType === "endPoint"
              ? new google.maps.Size(0, 40)
              : new google.maps.Size(0, 20)
          }
          position={{
            lat: pointActiveMarker.lat,
            lng: pointActiveMarker.lng,
          }}
          circleColor={
            pointActiveMarker.segment?.color ||
            JOB_ROUTE_TYPE_COLORS.activeDevice
          }
        />
      )}
    </>
  );
};

export default SegmentPolylines;
