import { CoordinateRawT, CoordinatesRawT } from "../types/Route";

export const getJourneyCoordinates = (journeys?: CoordinatesRawT[]) => {
  if (!journeys) return { coordinates: [] };
  const obj = journeys.reduce<{ grass_cutting: CoordinateRawT }>(
    (acc, next) => {
      if (next.grass_cutting && next.grass_cutting.length > 0) {
        acc.grass_cutting.push(...next.grass_cutting);
      }
      return acc;
    },
    { grass_cutting: [] }
  );
  return {
    coordinates: obj.grass_cutting.map((coor) =>
      coor.map(
        ({
          longitude: lng,
          latitude: lat,
          date,
          id,
          knivesOn,
          isAutonomous,
        }) => {
          return {
            lng,
            lat,
            date,
            id,
            knivesOn,
            isAutonomous,
          };
        }
      )
    ),
  };
};
