import { GoogleMap, GoogleMapProps } from "@react-google-maps/api";
import { useEffect, useRef } from "react";
import { DeviceTypes } from "../../../types/Common";
import { useUserLocation } from "../../../hooks/useUserLocation";
import { getSingleBound } from "../../../functions/getBounds";
import { onLoad } from "../../../functions/onMapLoad";
import MowerMarker from "../../atoms/MapMarkers/MowerMarker";
import UfonMarker from "../../atoms/MapMarkers/UfonMarker";
import UserMapMarker from "../../atoms/UserMapMarker/UserMapMarker";
import { Loader } from "../../atoms/Loader/Loader";
import MapControls from "../MapControls/MapControls";
import styles from "./deviceDetailMap.module.scss";

interface Props extends GoogleMapProps {
  deviceType: DeviceTypes;
  deviceId: number;
  isLoading?: boolean;
}

export default function DeviceDetailMap({
  center,
  deviceType,
  deviceId,
  isLoading,
  ...rest
}: Props) {
  const mapRef = useRef<GoogleMap>(null);
  const { userLocation, handleUserLocation, mapCenter, setMapCenter } =
    useUserLocation(center, mapRef);

  const handleHomeLocation = () => {
    if (!mapRef.current || !center) return;
    mapRef.current.state.map?.fitBounds(
      getSingleBound({
        lat: typeof center.lat === "number" ? center.lat : center.lat(),
        lng: typeof center.lng === "number" ? center.lng : center.lng(),
      })
    );
  };

  useEffect(() => {
    setMapCenter(center);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [center]);

  return (
    <div className={styles.map}>
      {!center ? (
        <div className="d-flex justify-content-center align-items-center h-100">
          <p>No GPS Coordinates</p>
        </div>
      ) : (
        <GoogleMap
          tilt={0}
          mapTypeId={google.maps.MapTypeId.SATELLITE}
          mapContainerStyle={{ height: "100%" }}
          center={mapCenter}
          zoom={18}
          ref={mapRef}
          onLoad={(map: google.maps.Map) => {
            onLoad(map);
          }}
          {...rest}
        >
          <Loader isLoading={!!isLoading} isCentered />
          <MapControls
            showZoomControls={false}
            handleUserLocation={handleUserLocation}
            handleHomeLocation={handleHomeLocation}
          />
          {deviceType === DeviceTypes.mower ? (
            <MowerMarker mowerId={deviceId} />
          ) : (
            <UfonMarker ufonId={deviceId} />
          )}
          {userLocation && <UserMapMarker userLocation={userLocation} />}
        </GoogleMap>
      )}
    </div>
  );
}
