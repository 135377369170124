import classNames from "classnames";
import { JOB_STATUSES } from "../../../constants";
import { JobWithStatusT } from "../../../types/Job";
import { useCompactDesign } from "../../../hooks/useCompactDesign";
import styles from "./status.module.scss";

type Props = {
  job: JobWithStatusT;
  large?: boolean;
};

export const Status: React.FC<Props> = ({ job, large }) => {
  const isCompact = useCompactDesign();
  return (
    <div
      className={classNames(styles.status, {
        [styles["status--large"]]: large && !isCompact,
        [styles["status--active"]]: job.status.name === JOB_STATUSES.Open,
        [styles["status--danger"]]: job.status.name === JOB_STATUSES.Overdue,
        [styles["status--archived"]]: job.archived,
      })}
    >
      {job.archived ? "Archived" : job.status.name}
    </div>
  );
};
