import React from "react";
import { UfonT } from "../../../types/Ufon";
import CardWithHeader from "../../../components/atoms/CardWithHeader/CardWithHeader";
import { NearbyMowersList } from "./NearbyMowersList";

type PropsT = {
  ufon: UfonT;
};

export const UfonActivityCard: React.FC<PropsT> = ({ ufon }: PropsT) => {
  return (
    <CardWithHeader title="Activity">
      <NearbyMowersList nearbyMowers={ufon.nearbyMowers} />
    </CardWithHeader>
  );
};
