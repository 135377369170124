import { FC, useEffect, useState } from "react";
import { DateRange } from "react-date-range";
import { Button, PopoverBody, Input, Popover } from "reactstrap";
import classNames from "classnames";
import { Locale, endOfDay, format, startOfDay } from "date-fns";
import { enUS } from "date-fns/locale";
import { DATE_FORMATS } from "../../../constants";
import { useCompactDesign } from "../../../hooks/useCompactDesign";
import { Icon } from "../../atoms/Icon";
import { StaticDateRangeT, defaultStaticRanges } from "./defautRanges";
import { DefinedRange } from "./RangeSelection/DefinedRange";
import { DefinedRangeList } from "./RangeSelection/DefinedRangeList";
import styles from "./DatePicker.module.scss";
import "./ReactDateRange.scss";
import "./Popover.scss";

type PropsT = {
  startDatetime: Date;
  endDatetime: Date;
  onRangeSelected: (start: Date, end: Date) => void;
  displayTime?: boolean;
  locale?: Locale;
  dateFormat?: string;
  displayCommonRangeNames?: boolean;
  minDate?: Date;
  maxDate?: Date;
  weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6;
  placement?: "auto" | "top" | "bottom" | "left" | "right";
  className?: string;
};

type Range = {
  startDate: Date;
  endDate: Date;
  key: "selection";
};

const DefaultDateFormat = DATE_FORMATS.dateForm;

function makeRange(startDate: Date, endDate: Date): Range[] {
  return [
    {
      startDate: startDate,
      endDate: endDate,
      key: "selection",
    },
  ];
}

function rangeName(
  ranges: StaticDateRangeT[],
  currStart: Date,
  currEnd: Date
): string {
  for (const range of ranges) {
    if (range.isSame(currStart, currEnd)) {
      return range.label;
    }
  }
  return "";
}

function toTwoDecimalString(n: number) {
  const s = n.toString();
  if (s.length < 2) {
    return "0" + s;
  }
  return s;
}

function toHoursMinutes(datetime: Date) {
  const hours = toTwoDecimalString(datetime.getHours());
  const minutes = toTwoDecimalString(datetime.getMinutes());
  return `${hours}:${minutes}`;
}

/**
 *
 * @param startDatetime The picked start of the range
 * @param endDatetime The picked end of the range
 * @param onRangeSelected Callback function that is called when a new date range is selected
 * @param displayTime Whether to display controls for selecting the time (hours and minutes)
 * @param locale The locale to use for calendar and names of days
 * @param dateFormat The format dates will appear in on the date button
 * @param displayCommonRangeNames Whether the component should, instead of, for example, the date range `2023/10/01-2023/10/8`,
 *  display `This Week` if the ranges are the same
 * @param minDate The minimum date to allow to select
 * @param maxDate The maximum date to allow to select
 * @param weekStartsOn The index of the day the week should start on.
 *  Used to override the value given by locale. (0 - Sunday, 1 - Monday, ...)
 * @param placement The placement of the popover body
 * @param className The class name to apply to the component
 */
export const DatePicker: FC<PropsT> = ({
  startDatetime,
  endDatetime,
  onRangeSelected,
  displayTime = false,
  locale = enUS,
  dateFormat = DefaultDateFormat,
  displayCommonRangeNames = true,
  minDate,
  maxDate,
  weekStartsOn,
  placement = "bottom",
  className,
}) => {
  const isCompact = useCompactDesign();

  const [open, setOpen] = useState(false);

  const [startDate, setStartDate] = useState<Date>(startDatetime);
  const [endDate, setEndDate] = useState<Date>(endDatetime);

  // using the number representation to avoid two Date objects of the same time not equaling each other
  const startTime = startDate.getTime();
  useEffect(() => {
    setStartDate(new Date(startTime));
  }, [startTime]);

  const endTime = endDate.getTime();
  useEffect(() => {
    setEndDate(new Date(endTime));
  }, [endTime]);

  const staticRanges = defaultStaticRanges(locale);
  const selectedRange = rangeName(staticRanges, startDate, endDate);

  let dateText = "";
  if (selectedRange && displayCommonRangeNames) {
    dateText = selectedRange;
  } else {
    dateText = `${format(startDate, dateFormat)} — ${format(
      endDate,
      dateFormat
    )}`;
  }

  const fromTime = displayTime ? toHoursMinutes(startDate) : "";
  const toTime = displayTime ? toHoursMinutes(endDate) : "";

  function setRangesWrapper(start: Date, end: Date) {
    const newStartDate = displayTime ? start : startOfDay(start);
    let newEndDate = displayTime ? end : endOfDay(end);
    if (newStartDate > newEndDate) {
      newEndDate = newStartDate;
    }
    setStartDate(newStartDate);
    setEndDate(newEndDate);
  }

  function shiftTime(date: Date, hours: number, minutes: number) {
    const newDate = new Date(date.getTime());
    newDate.setHours(hours, minutes);
    return newDate;
  }

  function changeDatesTime(datetime: Date, time: string) {
    const times = time.split(":");
    const hours = parseInt(times[0]);
    const minutes = parseInt(times[1]);
    return shiftTime(datetime, hours, minutes);
  }

  function timeChanged(from: string, to: string) {
    // `from` and `to` are in the 24 hour format
    const newStart = changeDatesTime(startDate, from);
    const newEnd = changeDatesTime(endDate, to);
    setRangesWrapper(newStart, newEnd);
  }

  function setRangesUnchangedTime(start: Date, end: Date) {
    const newStartDate = shiftTime(
      start,
      startDate.getHours(),
      startDate.getMinutes()
    );
    const newEndDate = shiftTime(end, endDate.getHours(), endDate.getMinutes());
    setRangesWrapper(newStartDate, newEndDate);
  }

  const rangeSelection = isCompact ? (
    <DefinedRange
      ranges={staticRanges}
      selected={selectedRange}
      onRangeSelection={(r) =>
        setRangesUnchangedTime(r.startDate(), r.endDate())
      }
    />
  ) : (
    <DefinedRangeList
      classNames={styles["date-select--large"]}
      ranges={staticRanges}
      selected={selectedRange}
      onRangeSelection={(r) =>
        setRangesUnchangedTime(r.startDate(), r.endDate())
      }
    />
  );

  return (
    <div className={classNames(styles["datepicker-container"], className)}>
      <Button
        type="button"
        id="datepicker"
        className={styles["datepicker-button"]}
      >
        <span className={styles["date-text"]}>{dateText}</span>
        <Icon icon="calendar-alt" className={styles["date-icon"]} />
      </Button>
      <Popover
        placement={placement}
        trigger="legacy"
        target="datepicker"
        toggle={() => {
          onRangeSelected(startDate, endDate);
          setOpen(!open);
        }}
        isOpen={open}
      >
        <PopoverBody
          className={classNames(
            styles["date-popover"],
            !isCompact && styles["date-popover--large"]
          )}
        >
          {rangeSelection}
          <DateRange
            className={classNames(!isCompact && styles["daterange--large"])}
            onChange={(item) => {
              if (
                item.selection &&
                item.selection?.startDate &&
                item.selection?.endDate
              ) {
                setRangesUnchangedTime(
                  item.selection.startDate,
                  item.selection.endDate
                );
              }
            }}
            moveRangeOnFirstSelection={false}
            ranges={makeRange(startDate, endDate)}
            minDate={minDate}
            maxDate={maxDate}
            locale={locale}
            months={isCompact ? 1 : 2}
            calendarFocus="backwards"
            showDateDisplay={false}
            direction="horizontal"
            preventSnapRefocus={!isCompact}
            weekStartsOn={weekStartsOn}
          />
          {displayTime && (
            <label
              className={classNames(!isCompact && styles["time-from--large"])}
            >
              Start Time:
              <Input
                type="time"
                value={fromTime}
                onChange={(e) => timeChanged(e.target.value, toTime)}
                name="fromTime"
              />
            </label>
          )}
          {displayTime && (
            <label
              className={classNames(!isCompact && styles["time-to--large"])}
            >
              End Time:
              <Input
                type="time"
                value={toTime}
                onChange={(e) => timeChanged(fromTime, e.target.value)}
                name="toTime"
              />
            </label>
          )}
        </PopoverBody>
      </Popover>
    </div>
  );
};
