import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { Button } from "reactstrap";
import { useSelector } from "../../../store/hooks";
import { calibrateWheelsAction } from "../../../store/slices/mowerSlice";
import { getCurrentDataInfo, getCurrentStatusInfo } from "../functions";
import { DEVICE_DETAIL_COLORS } from "../../../constants";
import { useConfirm } from "../../../components/molecules/ConfirmBoxProvider/ConfirmBoxProvider";
import DeviceDetailStatusRow from "../../../components/molecules/DeviceInfo/DeviceDetailStatusRow";
import CardWithHeader from "../../../components/atoms/CardWithHeader/CardWithHeader";
import HorizontalDivider from "../../../components/atoms/Divider/HorizontalDivider";
import styles from "./mowerInfoTab.module.scss";

export default function MowerCurrentInfoCard() {
  const { mowerId } = useParams();

  const dispatch = useDispatch();

  const confirm = useConfirm();

  const mowerData = useSelector(
    (store) => store.mower.mowers[Number(mowerId as string)]?.mowerData
  );
  const startCalibrateWheels = () => {
    if (mowerId) {
      dispatch(
        calibrateWheelsAction({ mowerId: Number(mowerId as string), confirm })
      );
    }
  };

  const statusInfo = getCurrentStatusInfo(mowerData);

  const dataInfo = getCurrentDataInfo(mowerData);

  // Check that the angle contains a number since sometimes we get only " °"
  const wheelAngle =
    mowerData?.data.wheelAngle && !isNaN(parseInt(mowerData.data.wheelAngle))
      ? mowerData.data.wheelAngle
      : undefined;

  return (
    <CardWithHeader title="Mower">
      {statusInfo.map((status, index) => (
        <DeviceDetailStatusRow key={`${status.label}-${index}`} data={status} />
      ))}
      <HorizontalDivider />
      {dataInfo.map((data, index) => (
        <DeviceDetailStatusRow data={data} key={`${data.label}-${index}`} />
      ))}
      {mowerData?.isOnline && (
        <>
          <HorizontalDivider />
          <div className={styles.calibrateRow}>
            <div className={styles.calibrateData}>
              <span>Wheel angle</span>
              <span
                style={{
                  color: wheelAngle
                    ? DEVICE_DETAIL_COLORS.black
                    : DEVICE_DETAIL_COLORS.default,
                }}
              >
                {wheelAngle ? wheelAngle : "—"}
              </span>
            </div>
            <Button
              onClick={startCalibrateWheels}
              color="dark"
              outline
              className={styles.calibrateBtn}
            >
              Calibrate wheels
            </Button>
          </div>
        </>
      )}
    </CardWithHeader>
  );
}
