import React from "react";
import classNames from "classnames";
import { InfoWindow } from "@react-google-maps/api";
import { IconsId } from "../../../assets/fonts/iconfont/icons";
import { Icon } from "../../atoms/Icon";
import { useMowerTracker } from "../../../hooks/useMowerTracker";
import styles from "./mapInfoWindow.module.scss";

type Props = {
  onClose: () => void;
  onClick?: () => void;
  icon?: IconsId;
  circleColor?: string;
  title: string;
  position: { lat: number; lng: number };
  mowerId?: number;
  pixelOffset?: google.maps.Size;
};

const JobMapInfoWindow = ({
  onClose,
  onClick,
  icon,
  circleColor,
  title,
  position,
  mowerId,
  pixelOffset,
}: Props) => {
  const mowerPosition = useMowerTracker(mowerId || -1);

  const clickHandler = () => {
    if (onClick) onClick();
    onClose();
  };

  return (
    <InfoWindow
      onCloseClick={onClose}
      position={
        mowerId
          ? {
              lat: mowerPosition?.currentLocation?.lat || position.lat,
              lng: mowerPosition?.currentLocation?.lng || position.lng,
            }
          : position
      }
      options={{ pixelOffset }}
    >
      <div className={styles.infoWindow} onClick={clickHandler}>
        {circleColor && (
          <div
            style={{
              backgroundColor: circleColor,
              marginRight: "5px",
            }}
            className={styles.circle}
          />
        )}
        {icon && <Icon icon={icon} className={styles["infoWindow-icon"]} />}
        <span className={classNames({ [styles["infoWindow-text"]]: !onClick })}>
          {title}
        </span>
      </div>
    </InfoWindow>
  );
};

export default JobMapInfoWindow;
