import React from "react";
import { useParams } from "react-router";
import { Container } from "reactstrap";
import { StyledButton } from "../../components/atoms/Button/Button";
import SubHeader from "../../components/atoms/SubHeader/SubHeader";
import Title from "../../components/atoms/Title/Title";
import { Layout } from "../../components/organisms/Layout/Layout";
import { WINDOW_TITLE_NAMES } from "../../constants";
import { useQueryJobGet } from "../../hooks/useQueries";
import styles from "./jobSignpost.module.scss";
import { getJobLink } from "../../functions/routing";

export const JobSignpostPage = () => {
  document.title = WINDOW_TITLE_NAMES.jobs;
  const { jobId } = useParams() as { jobId: string };
  const { data: job } = useQueryJobGet(jobId);

  return (
    <Layout>
      <SubHeader title={job?.data.name} isDetail />
      <Container fluid>
        <div className={styles["user-select"]}>
          <Title type="h2" className="text-center">
            Select user to display related tasks
          </Title>
          {job?.data.users.map((user) => (
            <StyledButton
              key={user.id}
              color="light"
              block
              className="mt-4"
              link={getJobLink(job.data.type.name, jobId, [user.id])}
            >
              {user.name}
            </StyledButton>
          ))}
        </div>
      </Container>
    </Layout>
  );
};
