import { AxiosResponse } from "axios";
import { ApiSuccessModal } from "../types/Common";
import { client } from "../services/axios";
import { ConfirmBoxT } from "../components/molecules/ConfirmBoxProvider/ConfirmBoxProvider";

type Params<T = never> = {
  response: AxiosResponse<ApiSuccessModal<T>>;
  confirm: (params: ConfirmBoxT) => void;
  regularSuccess?: () => void;
  modalSuccess?: () => Promise<unknown>;
  anotherModal?: () => void;
  modalContent?: JSX.Element;
  buttonCancelTitle?: string;
};

const responseAction = () => ({
  DELETE: client.delete,
  PATCH: client.patch,
  PUT: client.put,
  POST: client.post,
  GET: client.get,
});

export const responseWithModal = <T = never>({
  response,
  confirm,
  regularSuccess,
  modalSuccess,
  anotherModal,
  modalContent,
  buttonCancelTitle,
}: Params<T>) => {
  if (response.data.success) {
    regularSuccess?.();
  } else {
    if (response.data.modal) {
      confirm({
        title: response.data.modal.buttonTitle,
        subTitle: response.data.modal.message,
        asyncCallback: async () => {
          if (response.data.modal) {
            const action =
              responseAction()[response.data.modal.method] || client.get;
            const resp = await action(response.data.modal.buttonAction);
            if (resp.data.modal) {
              responseWithModal({
                response: resp,
                confirm,
                regularSuccess,
                modalSuccess,
                modalContent,
                buttonCancelTitle,
              });
              anotherModal?.();
              return;
            }
          }
          if (modalSuccess) {
            await modalSuccess();
          } else {
            regularSuccess?.();
          }
        },
        type: "ok",
        buttonConfirmTitle: response.data.modal.buttonTitle,
        buttonCancelTitle,
        modalContent,
      });
    }
  }
};
