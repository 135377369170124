import { FC } from "react";
import { useMutationCompleteJob } from "../../../hooks/useMutationCompleteJob";
import { ROUTES } from "../../../routes";
import { OtherDataStartJob } from "../../../types/Common";
import { StyledButton } from "../../atoms/Button/Button";

interface IProps {
  job: OtherDataStartJob;
  onComplete: (jobId: number) => void;
}

export const OtherJobRow: FC<IProps> = ({ job, onComplete }) => {
  const completeJobMutation = useMutationCompleteJob(job.id, {
    onSuccess: () => {
      onComplete(job.id);
    },
  });

  const getJobDetailLink = () => {
    if (job.type.name === "Route") {
      return ROUTES.jobRouteType(job.id);
    } else if (job.type.name === "Manual") {
      return ROUTES.jobManual(job.id);
    } else {
      return ROUTES.job(job.id, job.users[0].id);
    }
  };

  const clickHandler = () => {
    window.open(getJobDetailLink());
  };

  return (
    <div key={job.id} className="mt-4 d-flex gap-3">
      <StyledButton
        color="light"
        className="flex-grow-1"
        onClick={clickHandler}
      >
        {job.name}
      </StyledButton>
      <StyledButton
        key={job.id}
        color="primary"
        className=""
        onClick={() => completeJobMutation.mutate()}
        isLoading={completeJobMutation.isLoading}
      >
        Complete
      </StyledButton>
    </div>
  );
};
