import { useMutation } from "react-query";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { RouteInputsT } from "../components/molecules/FormRoute/FormRoute";
import { ROUTES } from "../routes";
import { client } from "../services/axios";
import { ApiErrorT } from "../types/Common";

export const useMutationRouteCreate = () => {
  const navigate = useNavigate();

  return useMutation(
    (data: RouteInputsT) => {
      const formData = new FormData();
      formData.append("name", data.name);
      formData.append("folderId", data.group);
      formData.append("file", data.file[0]);
      return client.post(`/api/v1/route/import`, formData);
    },
    {
      onSuccess: (data) => {
        toast.success("Route has been created");
        navigate(ROUTES.route(data.data.id));
      },
      onError: (error: ApiErrorT) => {
        toast.error(error?.message);
      },
    }
  );
};
