import classNames from "classnames";
import React, { FC, Fragment, useState } from "react";
import { JobTypeT, TaskT } from "../../../types/Job";
import { TaskCalculateRouteDialog } from "../../atoms/TaskCalculateRouteDialog/TaskCalculateRouteDialog";
import { TaskMowingDialog } from "../../atoms/TaskMowingDialog/TaskMowingDialog";
import { TaskNoGoZonesDialog } from "../../atoms/TaskNoGoZonesDialog/TaskNoGoZonesDialog";
import { DialogTypes, WizardItem } from "../../molecules/WizardItem/WizardItem";
import { JobComments } from "../JobComments/JobComments";
import styles from "./jobWizard.module.scss";

type PropsT = {
  jobId: string;
  jobName: string;
  tasks: TaskT[];
  jobType: JobTypeT;
  isDisabled?: boolean;
  isWaiting?: boolean;
  isWaitingArr: TaskT[];
  fetchTasks: () => void;
};

export const JobWizard: FC<PropsT> = ({
  jobId,
  jobName,
  tasks,
  jobType,
  isDisabled,
  isWaiting,
  isWaitingArr,
  fetchTasks,
}) => {
  const [selectedDialog, setSelectedDialog] =
    useState<{ type: DialogTypes; mowerId: number }>();

  const handleOpenDialog = (dialogData: {
    type: DialogTypes;
    mowerId: number;
  }) => {
    setSelectedDialog(dialogData);
  };

  return (
    <div className={classNames({ [styles["wrapper-disabled"]]: isDisabled })}>
      {tasks.map((task, index) => {
        // const isActive =
        //   !task.finishedAt &&
        //   (index === 0 ||
        //     (tasks[index - 1]?.finishedAt && !tasks[index + 1]?.finishedAt));
        const wasActivePrevious =
          index + 1 < tasks.length && tasks[index + 1].isActive;
        return (
          <Fragment key={`${task.type}-${index}`}>
            <WizardItem
              key={`${task.type}-${index}`}
              jobType={jobType}
              task={task}
              isActive={task.isActive && !isWaiting}
              wasActivePrevious={wasActivePrevious}
              isWaitingArr={isWaitingArr}
              onOpenDialog={handleOpenDialog}
              jobId={`${jobId}`}
              fetchTasks={fetchTasks}
            />
            {task.type === "startMowing" && jobType.name !== "Manual" && (
              <TaskMowingDialog
                isOpen={selectedDialog?.type === "mowing"}
                mowerId={selectedDialog?.mowerId || 0}
                onClose={() => setSelectedDialog(undefined)}
                jobId={jobId}
              />
            )}
            {task.type === "setNoGoZones" && (
              <TaskNoGoZonesDialog
                isOpen={selectedDialog?.type === "noGoZones"}
                jobId={jobId}
                mowerId={selectedDialog?.mowerId || 0}
                onClose={() => setSelectedDialog(undefined)}
              />
            )}
            {task.type === "calculateRoute" && (
              <TaskCalculateRouteDialog
                jobId={jobId}
                jobName={jobName}
                isOpen={selectedDialog?.type === "calculateRoute"}
                onClose={() => setSelectedDialog(undefined)}
                mowerId={selectedDialog?.mowerId || 0}
              />
            )}
          </Fragment>
        );
      })}
      <JobComments jobId={jobId} />
    </div>
  );
};
