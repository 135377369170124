import { format } from "date-fns";
import { hasPath } from "ramda";
import { DATE_FORMATS } from "../constants";

type DateFormatT = keyof typeof DATE_FORMATS;

export const isValidDate = (date: Date) => {
  return date instanceof Date && !isNaN(date.getTime());
};

export const formatDate = (
  date: Date,
  dateFormat: DateFormatT | string = DATE_FORMATS.date
) => {
  date = new Date(date);
  if (!isValidDate(date)) {
    return "";
  }

  return format(
    date,
    hasPath([dateFormat], DATE_FORMATS)
      ? DATE_FORMATS[dateFormat as DateFormatT]
      : dateFormat
  );
};
