import React, { FC, Fragment } from "react";
import { Marker, Polygon, Polyline } from "@react-google-maps/api";
import { NogoZoneT } from "../../types/Job";
import { CoordinateT, CoordinatesT, StylesT } from "../../types/Route";
import { getPolygonOptions, getPolylineOptions } from "./functions";
import { getEndPointIcon, getStartPointIcon } from "../JobRouteType/utils";
import { JOB_ROUTE_TYPE_COLORS } from "../../constants";

type PropsT = {
  activeLayers: number[];
  routeCoordinates: CoordinatesT<"route">;
  showArrows: boolean;
  tempNogoCoordinates: NogoZoneT[] | undefined;
};

type EnrichedCoordinate = CoordinateT<"route"> & { visible: boolean };

const RoutePolylines: FC<PropsT> = ({
  routeCoordinates,
  activeLayers,
  showArrows,
  tempNogoCoordinates,
}) => {
  const styles = routeCoordinates?.styles;
  const enrichedCoordinates: EnrichedCoordinate[] =
    routeCoordinates.coordinates.map((coor, index) => {
      return { ...coor, visible: activeLayers.includes(index) };
    });
  const coordinates = enrichedCoordinates.filter(
    (coor) => coor.styleType !== "noGoZone"
  );
  const nogoCoordinates =
    tempNogoCoordinates?.length && tempNogoCoordinates.length > 0
      ? tempNogoCoordinates
      : enrichedCoordinates.filter((coord) => coord.styleType === "noGoZone");

  return (
    <>
      {styles &&
        coordinates.map((coordinate, index) => {
          const isVisible = coordinate.visible;
          const { coordinates: latLng, id, styleType } = coordinate;
          const isPolygon = Boolean(styles[styleType].PolyStyle);
          const options = getPolylineOptions(
            styleType,
            styles as StylesT<"route">,
            showArrows && !isPolygon
          );
          const polygonOptions = getPolygonOptions(latLng, styleType, styles);
          if (!coordinate.isPoint) {
            return (
              <Fragment key={id}>
                {isPolygon ? (
                  <Polygon
                    visible={isVisible}
                    key={`polygon-item-${id}`}
                    path={latLng}
                    options={polygonOptions}
                  />
                ) : (
                  <Polyline
                    visible={isVisible}
                    key={`polyline-item-${id}`}
                    path={latLng}
                    options={options}
                  />
                )}
              </Fragment>
            );
          } else {
            const lat = latLng[0].lat || 0;
            const lng = latLng[0].lng || 0;
            return (
              <Marker
                key={`marker-${index}`}
                position={{ lat, lng }}
                visible={isVisible}
                zIndex={styleType === "startPoint" ? 300 : 250}
                icon={{
                  url:
                    styleType === "endPoint"
                      ? getEndPointIcon(JOB_ROUTE_TYPE_COLORS.plannedRoute)
                      : getStartPointIcon(JOB_ROUTE_TYPE_COLORS.plannedRoute),
                  scaledSize:
                    styleType === "endPoint"
                      ? new google.maps.Size(18, 18)
                      : new google.maps.Size(30, 30),
                  anchor:
                    styleType === "endPoint"
                      ? new google.maps.Point(9, 9)
                      : undefined,
                }}
              />
            );
          }
        })}
      {nogoCoordinates.map((coordinate) => {
        const { coordinates: latLon, id } = coordinate;
        if (!latLon) return;

        const visibility = (coordinate as EnrichedCoordinate)?.visible;
        const isVisible = visibility !== undefined ? visibility : true;

        const fixLatLng = latLon?.map((coord) => ({
          lat: coord.lat,
          // @ts-expect-error we can either receive { lat; lng }[] or { lat; lon }[] and typescript is a PITA when dealing with unions
          // prettier-ignore
          lng: coord.hasOwnProperty("lon") ? coord.lon as number : coord.lng as number,
        }));

        return (
          <Fragment key={id}>
            <Polyline
              visible={isVisible}
              key={`polyline-item-${id}`}
              path={fixLatLng}
              options={{
                strokeColor: `#FF0000FF`,
                strokeOpacity: 0.8,
                strokeWeight: 2,
                zIndex: 1000,
                icons: showArrows
                  ? [
                      {
                        icon: {
                          path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                          scale: 1.5,
                        },
                        repeat: "30px",
                      },
                    ]
                  : [],
              }}
            />
            <Polygon
              visible={isVisible}
              key={`polygon-item-${id}`}
              path={fixLatLng}
              options={{
                strokeColor: `#FF0000FF`,
                strokeOpacity: 0.8,
                strokeWeight: 2,
                paths: fixLatLng,
                zIndex: 1,
                fillColor: "#600000FF",
                fillOpacity: 0.5,
              }}
            />
          </Fragment>
        );
      })}
    </>
  );
};

export default RoutePolylines;
