import React, { FC, ReactNode, useState } from "react";
import { useNavigate } from "react-router";
import { ROUTES } from "../../routes";
import { JobT } from "../../types/Job";
import { Layout } from "../../components/organisms/Layout/Layout";
import {
  JobSubheader,
  JobProgressStatusT,
} from "../../components/molecules/JobSubheader/JobSubheader";
import { useBaseActions } from "../../components/molecules/JobSubheader/functions";
import { EditJobModal } from "../../components/molecules/EditJobModal/EditJobModal";

type PropsT = {
  children: ReactNode;
  title: string;
  job: JobT;
  status: JobProgressStatusT;
  isDisabled?: boolean;
  isLoading: boolean;
  isCompletedJob: boolean;
  isArchived: boolean;
  onStart: () => void;
  onComplete: () => void;
  onCancel: () => void;
  onArchive: () => void;
  onRestore: () => void;
  onExport: () => void;
  refetchJob: () => void;
  headerRef?: React.RefObject<HTMLDivElement>;
};

export const PageHeader: FC<PropsT> = ({
  children,
  title,
  job,
  status,
  isDisabled,
  isLoading,
  isArchived,
  onStart,
  onComplete,
  onCancel,
  onArchive,
  onRestore,
  onExport,
  refetchJob,
  headerRef,
}) => {
  const navigate = useNavigate();
  const [editModalData, setEditModalData] = useState<{
    job: JobT;
    name: string;
  } | null>(null);

  const handleEditJob = () => {
    if (!job) return;
    if (status !== "beforeStart") {
      setEditModalData({
        job: job,
        name: job.name,
      });
    } else {
      navigate(`${ROUTES.jobEdit(job.id)}/?type=${job.type.id}`);
    }
  };

  const secondaryActions = [
    {
      title: "Edit job",
      action: handleEditJob,
      disabled: isDisabled,
      closeOnClick: true,
    },
    ...useBaseActions(job.id, job.type),
  ];

  /*
  if (status === "completed") {
    const createRoute = () => {
      navigate(ROUTES.routeCreateFromJob(job.id), {
        state: {
          type: "route",
        },
      });
    };

    const createAutonomousJob = async () => {
      navigate(ROUTES.routeCreateFromJob(job.id), {
        state: {
          type: "job",
        },
      });
    };

    secondaryActions = [
      { title: "Create a route", action: createRoute },
      { title: "Create autonomous job", action: createAutonomousJob },
      ...secondaryActions,
    ];
  }
  */

  return (
    <Layout requireWS>
      {editModalData && (
        <EditJobModal
          jobId={editModalData.job.id}
          initialName={editModalData.name}
          isOpen={true}
          onClose={() => {
            setEditModalData(null);
          }}
          refetch={refetchJob}
        />
      )}
      <JobSubheader
        title={title}
        status={status}
        isDisabled={!!isDisabled}
        isLoading={isLoading}
        isArchived={isArchived}
        onStart={onStart}
        onComplete={onComplete}
        onCancel={onCancel}
        onArchive={onArchive}
        onRestore={onRestore}
        onExport={onExport}
        secondaryActions={secondaryActions}
        headerRef={headerRef}
      />

      {children}
    </Layout>
  );
};
