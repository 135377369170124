import { PayloadAction, createAction, createSlice } from "@reduxjs/toolkit";
import { GeostickPointsT } from "../../types/Geostick";

type GeostickStateT = {
  points: GeostickPointsT;
};

const initialState: GeostickStateT = {
  points: [],
};

export const geostickSlice = createSlice({
  name: "geostick",
  initialState,
  reducers: {
    setPoints: (state, action: PayloadAction<GeostickPointsT>) => {
      state.points = action.payload;
    },
    clearPoints: (state) => {
      state.points = [];
    },
  },
});

export const subscribeToGeostickAction = createAction<{
  jobId: number;
}>("SUBSCRIBE_TO_GEOSTICK");
export type SubscribeToGeostickActionT = ReturnType<
  typeof subscribeToGeostickAction
>;

export const unsubscribeFromGeostickAction = createAction<{
  jobId: number;
}>("UNSUBSCRIBE_FROM_GEOSTICK");
export type UnsubscribeFromGeostickActionT = ReturnType<
  typeof unsubscribeFromGeostickAction
>;

export const { setPoints, clearPoints } = geostickSlice.actions;
