import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { client } from "../services/axios";
import { JobSegmentsT } from "../types/Job";

export const useMutationEditJobSegments = (
  jobId: string,
  options?: { onSuccess?: (data: JobSegmentsT) => void; onError?: () => void }
) => {
  return useMutation(
    (data: {
      mowers: { id: number; selected: boolean }[];
      criterion?: string;
      value?: number | null;
    }) => {
      return client.post(`/api/v1/job/${jobId}/segments`, data);
    },
    {
      onSuccess: (data: { data: JobSegmentsT }) => {
        if (options?.onSuccess) {
          options.onSuccess(data.data);
        }
      },
      onError: (error: { status: boolean; message: string }) => {
        toast.error(error?.message);
      },
    }
  );
};
