import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export const webSocketSlice = createSlice({
  name: "websocket",
  initialState: { wsIsOpen: true },
  reducers: {
    setWSChannelStateAction: (state, action: PayloadAction<boolean>) => {
      state.wsIsOpen = action.payload;
    },
  },
});
export const { setWSChannelStateAction } = webSocketSlice.actions;
