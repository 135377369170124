import classNames from "classnames";
import React, { FC } from "react";
import { DropdownMenu } from "reactstrap";
import { useCompactDesign } from "../../../hooks/useCompactDesign";
import styles from "./filterMenu.module.scss";

type PropsT = {
  className?: string;
  standalone?: boolean;
};

export const FilterMenu: FC<PropsT> = ({
  children,
  className,
  standalone,
  ...rest
}) => {
  const isCompact = useCompactDesign();
  return (
    <DropdownMenu
      {...rest}
      className={classNames(
        styles["filter-menu"],
        {
          [styles["filter-menu--standalone"]]: standalone,
          [styles["filter-menu--large"]]: !isCompact,
        },
        className
      )}
    >
      {children}
    </DropdownMenu>
  );
};
