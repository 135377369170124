import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { client } from "../services/axios";

export const useMutationSaveJobSegments = (
  jobId: string,
  options?: {
    onSuccess?: (data: { ids: number[] }) => void;
    onError?: () => void;
  }
) => {
  return useMutation(
    (data: {
      criterion: string;
      value: number;
      mowers: { id: number; selected: boolean }[];
    }) => {
      return client.post(`/api/v1/job/${jobId}/segments/save`, data);
    },
    {
      onSuccess: (data: { data: { ids: number[] } }) => {
        toast.success("Job segments have been saved");
        if (options?.onSuccess) {
          options.onSuccess(data.data);
        }
      },
      onError: (error: { status: boolean; message: string }) => {
        toast.error(error?.message);
      },
    }
  );
};
