import { useNavigate } from "react-router";
import { queryClient } from "../../..";
import { ROUTES } from "../../../routes";
import { JobTypeT } from "../../../types/Job";
import { useMutationDeleteJob } from "../../../hooks/useMutationDeleteJob";
import { useCompactDesign } from "../../../hooks/useCompactDesign";
import { writeToClipboard } from "../../../functions/clipboard";
import { useConfirm } from "../ConfirmBoxProvider/ConfirmBoxProvider";
import { SecondaryActionT } from "./JobSubheader";

export function useBaseActions(
  jobId: number | undefined,
  jobType: JobTypeT | undefined,
  disabled?: boolean
): SecondaryActionT[] {
  const navigate = useNavigate();
  const confirm = useConfirm();
  const isCompact = useCompactDesign();

  const deleteJobMutation = useMutationDeleteJob({
    onSuccess: () => {
      queryClient.invalidateQueries(["jobs"]);
      navigate(ROUTES.jobs());
    },
  });

  if (!(jobId || jobId === 0) || !jobType) {
    return [];
  }

  const copyJobLink = `${ROUTES.jobCreate(jobId)}/?type=${jobType.id}`;

  const onCopy = () => {
    confirm({
      title: "Copy job",
      subTitle: "Are you sure you want to copy the job?",
      callback: () => navigate(copyJobLink),
      type: "ok",
    });
  };

  const handleDelete = () => {
    confirm({
      title: "Remove job",
      subTitle: `Are you sure you want to delete the job?`,
      asyncCallback: () => deleteJobMutation.mutateAsync(jobId),
      type: "delete",
    });
  };

  const actions = [];
  actions.push({
    title: "Duplicate job",
    action: onCopy,
    divider: true,
    disabled: disabled,
    closeOnClick: true,
  });
  if (isCompact) {
    actions.push({
      title: "Copy page link",
      action: () => writeToClipboard(location.pathname),
      divider: true,
      disabled: disabled,
    });
  }
  actions.push({
    title: "Delete job",
    action: handleDelete,
    disabled: disabled,
    closeOnClick: true,
  });

  return actions;
}
