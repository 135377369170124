import React, { FC, useState } from "react";
import { toast } from "react-toastify";
import { Button, Input, Modal, ModalBody } from "reactstrap";
import { client } from "../../../services/axios";
import { ApiErrorT } from "../../../types/Common";
import { downloadFile } from "../../../functions/downloadFile";
import Title from "../Title/Title";

export type InputRoutesT = {
  name: string | null;
  id: number | string;
};

type ExportT = {
  type: ExportTypeT;
  name: string;
  id: string | number;
};

type ExportTypeT = "Mower" | "Route";

type PropsT = {
  isOpen: boolean;
  mowers?: { id: number; name: string }[];
  journeyIds?: number[];
  jobId: string | number;
  onClose: () => void;
  route?: InputRoutesT;
};

const aggregateExports = (
  journeyIds?: number[],
  mowers?: { id: number; name: string }[],
  route?: InputRoutesT
) => {
  const exports: ExportT[] = [];
  if (journeyIds && mowers) {
    for (let i = 0; i < journeyIds.length; i++) {
      if (mowers[i] && journeyIds[i] !== undefined) {
        exports.push({
          type: "Mower",
          name: journeyIds.length > 1 ? mowers[i].name : "Recorded data",
          id: journeyIds[i],
        });
      }
    }
  }
  if (route) {
    exports.push({
      type: "Route",
      name: "Mowing data",
      id: route.id,
    });
  }
  return exports;
};

const exportFilename: Record<ExportTypeT, (expo: ExportT) => string> = {
  Mower: (expo) => `journey-id-${expo.id}.kml`,
  Route: (expo) => `route-id-${expo.id}.kml`,
};

export const ExportJourneyDialogBase: FC<PropsT> = ({
  isOpen,
  mowers,
  journeyIds,
  jobId,
  onClose,
  route,
}) => {
  const [selectedJourney, setSelectedJourney] = useState<number>(0);
  const exports = aggregateExports(journeyIds, mowers, route);

  const handleClick =
    exports.length > 0
      ? async () => {
          try {
            const expo = exports[selectedJourney];
            const exportLink =
              expo.type === "Mower"
                ? `/api/v1/job/${jobId}/export-journey/${expo.id}`
                : `/api/v1/route/${expo.id}/export`;
            const fileName = exportFilename[expo.type](expo);

            const response = await client.get(exportLink);
            const url = window.URL.createObjectURL(new Blob([response.data]));

            await downloadFile(url, fileName);

            toast.success("Export was successfull");
          } catch (e) {
            const { message } = e as ApiErrorT;
            toast.error(`There was an error while exporting: ${message}`);
          }

          onClose();
        }
      : undefined;

  let availableTypesTitle = "journey";
  if (journeyIds && journeyIds.length && route) {
    availableTypesTitle = "journey/route";
  } else if (journeyIds && journeyIds.length) {
    availableTypesTitle = "journey";
  } else if (route) {
    availableTypesTitle = "route";
  }

  return (
    <Modal isOpen={isOpen} toggle={onClose} fade>
      <ModalBody>
        <Title type="h3">{`Export ${availableTypesTitle}`}</Title>
        <div className="d-flex flex-column gap-3">
          {exports.length > 1 && (
            <>
              <div className="my-2">
                {`Select ${availableTypesTitle} to export: `}
              </div>
              <Input
                type="select"
                bsSize="sm"
                value={selectedJourney}
                onChange={(e) => setSelectedJourney(Number(e.target.value))}
              >
                {exports.map((expo, index) => (
                  <option key={`${expo.type}-${expo.id}`} value={index}>
                    {expo.name}
                  </option>
                ))}
              </Input>
            </>
          )}

          <div className="d-flex flex-row gap-10">
            <Button
              className="m-1"
              onClick={handleClick}
              disabled={!handleClick}
              size="sm"
            >
              Export
            </Button>
            <Button className="m-1" onClick={onClose} size="sm">
              Cancel
            </Button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};
