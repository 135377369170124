import {
  BATTERY_VALUE,
  DATE_FORMATS,
  DEVICE_DETAIL_COLORS,
  INTERNET_VALUE,
  SATELLITE_VALUE,
} from "../../constants";
import {
  ChartType,
  CorrectionTypeT,
  DeviceCurrentInfo,
} from "../../types/Common";
import { UfonConnectionT, UfonT } from "../../types/Ufon";
import {
  getConnectionIconColor,
  getIcon,
  getInternetIcon,
  getLabel,
} from "../../functions/charts";
import {
  roundCoordinates,
  roundDistance,
} from "../../functions/unitConversion";
import { formatDate } from "../../functions/formatDate";

function getBatteryColor(percent: number | undefined | null) {
  if (percent === undefined || percent === null) {
    return DEVICE_DETAIL_COLORS.default;
  } else if (percent <= BATTERY_VALUE.error) {
    return DEVICE_DETAIL_COLORS.error;
  } else if (percent <= BATTERY_VALUE.warning) {
    return DEVICE_DETAIL_COLORS.warning;
  }
  return DEVICE_DETAIL_COLORS.ok;
}

function getTextColor(percent: number | undefined | null) {
  if (percent === undefined || percent === null) {
    return DEVICE_DETAIL_COLORS.default;
  } else if (percent <= BATTERY_VALUE.error) {
    return DEVICE_DETAIL_COLORS.error;
  }
  return DEVICE_DETAIL_COLORS.black;
}

function getBatteryIcon(percent: number | undefined | null) {
  if (
    percent === undefined ||
    percent === null ||
    percent <= BATTERY_VALUE.error
  ) {
    return "battery-empty";
  } else if (percent <= BATTERY_VALUE.warning) {
    return "battery-quarter";
  } else if (percent <= BATTERY_VALUE.good) {
    return "battery-half";
  } else if (percent <= BATTERY_VALUE.strong) {
    return "battery-three-quarters";
  }
  return "battery-full";
}

export function getBatteryInfo(
  batteryPercent: number | undefined | null
): DeviceCurrentInfo {
  return {
    label: "Battery",
    value: batteryPercent !== null ? `${batteryPercent}%` : undefined,
    textColor: getTextColor(batteryPercent),
    icon: {
      id: getBatteryIcon(batteryPercent),
      color: getBatteryColor(batteryPercent),
    },
  };
}

export function getCurrentInfo(
  ufon: UfonT,
  mode: CorrectionTypeT | "" | undefined
): (DeviceCurrentInfo & { separator: boolean })[] {
  let modeColor = DEVICE_DETAIL_COLORS.default;
  if (mode === "lte") {
    modeColor = DEVICE_DETAIL_COLORS.ok;
  } else if (mode === "radio") {
    modeColor = DEVICE_DETAIL_COLORS.warning;
  }

  const modeValue: Record<CorrectionTypeT, string> = {
    radio: "Radio",
    lte: "LTE",
  };

  return [
    {
      ...getBatteryInfo(ufon.data.batteryPercent),
      separator: true,
    },
    {
      label: "Mode",
      value: mode ? modeValue[mode] : undefined,
      textColor: DEVICE_DETAIL_COLORS.black,
      icon: {
        id: "compas",
        color: modeColor,
      },
      separator: true,
    },
    {
      label: "Position",
      value: ufon.data.rtk,
      textColor: DEVICE_DETAIL_COLORS.black,
      separator: false,
    },
    {
      label: "Longitude",
      value:
        ufon.data.longitude !== null
          ? roundCoordinates(ufon.data.longitude).toString()
          : undefined,
      textColor: DEVICE_DETAIL_COLORS.black,
      separator: false,
    },
    {
      label: "Latitude",
      value:
        ufon.data.latitude !== null
          ? roundCoordinates(ufon.data.latitude).toString()
          : undefined,
      textColor: DEVICE_DETAIL_COLORS.black,
      separator: false,
    },
    {
      label: "Altitude",
      value:
        ufon.data.altitude !== null
          ? `${roundDistance(ufon.data.altitude)} m`
          : undefined,
      textColor:
        ufon.data.altitude !== null
          ? DEVICE_DETAIL_COLORS.black
          : DEVICE_DETAIL_COLORS.default,
      separator: false,
    },
  ];
}

export function getGeneralData(ufon: UfonT): DeviceCurrentInfo[] {
  const info: DeviceCurrentInfo[] = [
    {
      label: "Title",
      value: ufon?.name,
      textColor: ufon?.name
        ? DEVICE_DETAIL_COLORS.black
        : DEVICE_DETAIL_COLORS.default,
    },
    {
      label: "Company",
      value: ufon?.company.name,
      textColor: ufon?.company.name
        ? DEVICE_DETAIL_COLORS.black
        : DEVICE_DETAIL_COLORS.default,
    },
    {
      label: "Control unit serial number",
      value: ufon?.serialNumber,
      textColor: ufon?.serialNumber
        ? DEVICE_DETAIL_COLORS.black
        : DEVICE_DETAIL_COLORS.default,
    },
    {
      label: "Purchase date",
      value: ufon?.purchaseDate
        ? formatDate(ufon?.purchaseDate, DATE_FORMATS.date)
        : undefined,
      textColor: ufon?.purchaseDate
        ? DEVICE_DETAIL_COLORS.black
        : DEVICE_DETAIL_COLORS.default,
    },
  ];
  return info;
}

export function getIconColor(
  type: ChartType,
  connection: UfonConnectionT
): string {
  switch (type) {
    case ChartType.correction:
      if (connection.correction.value) {
        return DEVICE_DETAIL_COLORS.ok;
      } else if (connection.correction.value === false) {
        return DEVICE_DETAIL_COLORS.error;
      }
      return DEVICE_DETAIL_COLORS.default;
    case ChartType.internet:
      return getConnectionIconColor(
        connection.internet.signalStrength,
        INTERNET_VALUE.error,
        INTERNET_VALUE.warning
      );
    case ChartType.position:
      return getConnectionIconColor(
        connection.position.satellites,
        SATELLITE_VALUE.error,
        SATELLITE_VALUE.warning
      );
    case ChartType.server:
      if (connection.server.isConnected !== null) {
        if (!connection.server.isConnected) {
          return DEVICE_DETAIL_COLORS.error;
        } else if (connection.server.delay === 0) {
          return DEVICE_DETAIL_COLORS.ok;
        } else {
          return DEVICE_DETAIL_COLORS.warning;
        }
      }
      return DEVICE_DETAIL_COLORS.default;
  }
}

type UfonCurrentInfo = DeviceCurrentInfo & { type: ChartType };

export function getConnectionData(
  connection?: UfonConnectionT
): UfonCurrentInfo[] {
  const data = Object.values(ChartType).map((chartType) => {
    return {
      type: chartType,
      label: getLabel(chartType),
      value: undefined,
      textColor: DEVICE_DETAIL_COLORS.default,
      icon: {
        id: getIcon(chartType),
        color: DEVICE_DETAIL_COLORS.default,
      },
    };
  });

  if (!connection) {
    return data;
  }

  return data.map((item) => {
    const iconColor = getIconColor(item.type, connection);
    switch (item.type) {
      case ChartType.correction:
        return {
          ...item,
          value: connection.correction.type
            ? `Mode: ${connection.correction.type.toUpperCase()}`
            : undefined,
          icon: {
            ...item.icon,
            color: iconColor,
          },
          textColor: DEVICE_DETAIL_COLORS.black,
        };
      case ChartType.internet:
        return {
          ...item,
          value: connection.internet.signalStrength
            ? `${connection.internet.providerName} / ${connection.internet.signalStrength}%`
            : undefined,
          icon: {
            id: getInternetIcon(connection.internet.signalStrength),
            color: iconColor,
          },
          textColor:
            iconColor === DEVICE_DETAIL_COLORS.ok
              ? DEVICE_DETAIL_COLORS.black
              : iconColor,
        };
      case ChartType.position:
        return {
          ...item,
          value:
            connection.position.satellites !== null
              ? `${connection.position.satellites} satellite${
                  connection.position.satellites !== 1 ? "s" : ""
                }`
              : undefined,
          icon: {
            ...item.icon,
            color: iconColor,
          },
          textColor:
            iconColor === DEVICE_DETAIL_COLORS.ok
              ? DEVICE_DETAIL_COLORS.black
              : iconColor,
        };
      case ChartType.server:
        let serverValue = undefined;
        if (
          connection.server.isConnected !== null &&
          connection.server.delay !== null
        ) {
          if (!connection.server.isConnected) {
            serverValue = "No connection";
          } else if (connection.server.delay === 0) {
            serverValue = "No delay";
          } else {
            serverValue = `Delay ${connection.server.delay} s`;
          }
        }

        return {
          ...item,
          value: serverValue,
          icon: {
            ...item.icon,
            color: iconColor,
          },
          textColor:
            iconColor === DEVICE_DETAIL_COLORS.ok
              ? DEVICE_DETAIL_COLORS.black
              : iconColor,
        };
    }
  });
}
