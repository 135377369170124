import React, { useEffect, useRef } from "react";
import classNames from "classnames";
import { useGoogleMap } from "@react-google-maps/api";
import Hammer from "hammerjs";
import { StyledButton } from "../Button/Button";
import styles from "./mapZoomControls.module.scss";

type Props = {
  setMapTypeId: (type: google.maps.MapTypeId) => void;
};

const MapZoomControls = ({ setMapTypeId }: Props) => {
  const map = useGoogleMap();
  const prevZoom = useRef(map?.getZoom());

  const zoomInClick = () => {
    const currentZoom = map?.getZoom() ?? 10;
    map?.setZoom(currentZoom + 1);
    if (currentZoom === map?.getZoom()) {
      map?.setMapTypeId(google.maps.MapTypeId.TERRAIN);
      setMapTypeId(google.maps.MapTypeId.TERRAIN);
      map?.setZoom(currentZoom + 1);
    }
  };

  const zoomOutClick = () => {
    const currentZoom = map?.getZoom() ?? 10;
    map?.setZoom(currentZoom - 1);
  };

  useEffect(() => {
    const mapElement = document.getElementById("mapElement");
    if (!mapElement) return;
    const hammer = new Hammer(mapElement);

    hammer.set({ enable: true });
    const pinch = new Hammer.Pinch({
      event: "pinch",
      threshold: 0.3,
      enable: true,
    });

    hammer.add([pinch]);

    hammer.on("doubletap", zoomInClick);

    hammer.on("pinchstart", () => {
      prevZoom.current = map?.getZoom();
    });

    hammer.on("pinchend", (e) => {
      if (e.scale > 1) {
        if (prevZoom.current === map?.getZoom() && e.scale > 1.6) {
          map?.setMapTypeId(google.maps.MapTypeId.TERRAIN);
          setMapTypeId(google.maps.MapTypeId.TERRAIN);
          if (prevZoom.current) {
            map?.setZoom(prevZoom.current + 1);
          }
        }
      }
    });

    return () => {
      hammer.destroy();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!map) return null;

  return (
    <div className={styles.zoom}>
      <StyledButton
        color="light"
        icon="plus"
        kind="iconButton"
        className={classNames(
          styles["icon-button"],
          styles["icon-button--left"]
        )}
        onClick={zoomInClick}
      />
      <StyledButton
        color="light"
        icon="minus"
        kind="iconButton"
        className={classNames(
          styles["icon-button"],
          styles["icon-button--right"]
        )}
        onClick={zoomOutClick}
      />
    </div>
  );
};

export default MapZoomControls;
