import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { Col } from "reactstrap";
import {
  PRAGUE_LAT_LNG,
  LG_SCREEN_WIDTH,
  WINDOW_TITLE_NAMES,
} from "../../constants";
import { ROUTES } from "../../routes";
import { useCompactDesign } from "../../hooks/useCompactDesign";
import { Layout } from "../../components/organisms/Layout/Layout";
import { Icon } from "../../components/atoms/Icon";
import DashboardMap from "./DashboardMap";
import DashboardTabWrapper from "./DashboardTabWrapper";
import DashboardSidebar from "./DashboardSidebar";
import styles from "./dashboard.module.scss";

type Props = {};

export const Dashboard: React.FC<Props> = () => {
  document.title = WINDOW_TITLE_NAMES.dashboard;
  const [activeTab, setActiveTab] = useState(0);
  const isCompact = useCompactDesign();

  useEffect(() => {
    const handleTabChange = () => {
      if (window.innerWidth >= LG_SCREEN_WIDTH && activeTab === 1) {
        setActiveTab(0);
      }
    };

    window.addEventListener("resize", handleTabChange);

    return () => window.removeEventListener("resize", handleTabChange);
  }, [activeTab]);

  const mapComponent = <DashboardMap center={PRAGUE_LAT_LNG} zoom={8} />;

  return (
    <Layout>
      <div
        className={classNames(styles.row, {
          [styles["row--large"]]: !isCompact,
        })}
      >
        <Col className="d-none d-lg-block">
          <>
            <div className={styles.title}>
              <div className={styles["title-left"]}>
                <Link className={styles.superLink} to={ROUTES.devices()} />
                <span className={styles["title-link"]}>Devices</span>
              </div>
              <Icon icon="arrow-right" className={styles.chevron} />
            </div>
            {mapComponent}
          </>
        </Col>
        <div
          className={classNames(styles.sidebar, {
            [styles["sidebar--large"]]: !isCompact,
          })}
        >
          <DashboardTabWrapper
            tabName="Overview"
            mapComponent={mapComponent}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          >
            <DashboardSidebar />
          </DashboardTabWrapper>
        </div>
      </div>
    </Layout>
  );
};
