import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import { useQueryMowerGet } from "../../hooks/useQueries";
import { usePermissions } from "../../hooks/usePermissions";
import { useMutationDeleteMower } from "../../hooks/useMutationDeleteMower";
import { useDispatch } from "../../store/hooks";
import {
  setMowerDataAction,
  startMowerTrackingAction,
  subscribeToMowerConnectionAction,
  unsubscribeFromMowerConnectionAction,
} from "../../store/slices/mowerSlice";
import { ROUTES } from "../../routes";
import { WINDOW_TITLE_NAMES } from "../../constants";
import { Layout } from "../../components/organisms/Layout/Layout";
import { useConfirm } from "../../components/molecules/ConfirmBoxProvider/ConfirmBoxProvider";
import { Loader } from "../../components/atoms/Loader/Loader";
import MowerSubheader from "./MowerSubheader";
import MowerTabWrapper from "./MowerTabWrapper";

type Props = {};

export const MowerPage: React.FC<Props> = () => {
  document.title = WINDOW_TITLE_NAMES.devices;
  const navigate = useNavigate();
  const { mowerId } = useParams();
  const { data: mower, isLoading } = useQueryMowerGet(Number(mowerId));
  const dispatch = useDispatch();
  const confirm = useConfirm();
  const permissions = usePermissions();

  const deleteMowerMutation = useMutationDeleteMower({
    onSuccess: () => {
      navigate(ROUTES.devices());
    },
  });

  const onDelete = () => {
    if (!permissions.deviceCreate) {
      confirm({
        title: "Contact support",
        subTitle: (
          <p>
            For deleting a device, please contact customer support on email:{" "}
            <a href="mailto:info@spidernewtech.com">info@spidernewtech.com</a>
          </p>
        ),
        callback: () => {},
        type: "ok",
      });
    } else {
      confirm({
        title: "Remove mower",
        subTitle: `Are you sure you want to delete this mower, ${mower?.data.name}?`,
        asyncCallback: () => deleteMowerMutation.mutateAsync(mowerId || 0),
        type: "delete",
      });
    }
  };

  useEffect(() => {
    if (!mower) return;
    dispatch(
      setMowerDataAction({ mowerId: Number(mowerId), mowerData: mower.data })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mower, mowerId]);

  useEffect(() => {
    if (mowerId) {
      dispatch(startMowerTrackingAction({ mowerId: Number(mowerId) }));
    }
    // return () => {
    //   dispatch(clearMowerAction({ mowerIds: [Number(mowerId)] }));
    // };
  }, [dispatch, mowerId]);

  useEffect(() => {
    if (mowerId) {
      dispatch(subscribeToMowerConnectionAction({ mowerId: Number(mowerId) }));
    }
    return () => {
      dispatch(
        unsubscribeFromMowerConnectionAction({ mowerId: Number(mowerId) })
      );
    };
  }, [dispatch, mowerId]);

  return (
    <Layout requireWS>
      {isLoading && <Loader isCentered />}
      {!mower && !isLoading && (
        <p className="text-center">Mower with ID {mowerId} was not found</p>
      )}
      {mower && (
        <>
          <MowerSubheader mowerId={Number(mowerId)} onDelete={onDelete} />
          <MowerTabWrapper mower={mower.data} />
        </>
      )}
    </Layout>
  );
};
