import React from "react";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { useSelector } from "../../store/hooks";
import { Container, Modal, ModalBody } from "reactstrap";
import { changeCompanyId } from "../../store/slices/userSlice";
import { setCompany } from "../../functions/localStorage";
import { ROUTES } from "../../routes";
import { Layout } from "../../components/organisms/Layout/Layout";
import Title from "../../components/atoms/Title/Title";
import { StyledButton } from "../../components/atoms/Button/Button";
import styles from "./styles.module.scss";

export const UserSelectCompanyPage = () => {
  const { user } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  if (!user) {
    return null;
  }

  const handleClick = (id: number) => {
    setCompany(id);
    dispatch(changeCompanyId({ id }));
    navigate(ROUTES.dashboard());
  };

  return (
    <Layout>
      <Container fluid>
        <Modal isOpen centered>
          <ModalBody>
            <div className={styles["company-select"]}>
              <Title type="h2" className="text-center">
                Select company
              </Title>
              {user.companies.map(({ id, name }) => (
                <StyledButton
                  key={id}
                  color="light"
                  block
                  className="mt-4"
                  onClick={() => handleClick(id)}
                >
                  {name}
                </StyledButton>
              ))}
            </div>
          </ModalBody>
        </Modal>
      </Container>
    </Layout>
  );
};
