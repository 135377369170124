import React, { FC } from "react";
import { StyledButton } from "../../components/atoms/Button/Button";
import { LoginScreenLayout } from "../../components/organisms/LoginScreenLayout/LoginScreenLayout";
import { useNavigate, useParams } from "react-router";
import { ROUTES } from "../../routes";
import { UserRegisterFieldsT, UserRegisterForm } from "./UserRegisterForm";
import { useMutationUserRegister } from "../../hooks/useMutationUserRegister";
import Title from "../../components/atoms/Title/Title";
import { useQueryRegistrationDataGet } from "../../hooks/useQueries";
import { Loader } from "../../components/atoms/Loader/Loader";
import { WINDOW_TITLE_NAMES } from "../../constants";

export const UserRegisterPage: FC = () => {
  document.title = WINDOW_TITLE_NAMES.default;
  const navigate = useNavigate();
  const { token } = useParams() as { token?: string };
  const { data, isLoading } = useQueryRegistrationDataGet(token);

  const userRegisterMutation = useMutationUserRegister({
    onSuccess: () => {
      navigate(ROUTES.login());
    },
  });

  const handleSubmit = ({
    firstName,
    surname,
    email,
    password,
  }: UserRegisterFieldsT) => {
    userRegisterMutation.mutate({ firstName, surname, email, password, token });
  };

  return (
    <LoginScreenLayout>
      <Loader isCentered isLoading={isLoading} />
      <Title type="h1" size="h5">
        Register information
      </Title>
      {data && (
        <p>
          Invited to company: <strong>{data.data.companies[0].name}</strong>
        </p>
      )}
      <UserRegisterForm email={data?.data.email} onSubmit={handleSubmit} />
      <StyledButton
        disabled={userRegisterMutation.isSuccess}
        isLoading={userRegisterMutation.isLoading}
        title="Register"
        size="lg"
        color="primary"
        icon="check"
        block
        className="mb-3 mt-4"
        form="user-register-form"
      />
      <StyledButton
        onClick={() => navigate(ROUTES.login())}
        className="mb-5"
        tag="a"
        block
        color="link"
        size="sm"
        title="Back to login"
      />
    </LoginScreenLayout>
  );
};
