import React, { FC, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Container } from "reactstrap";
import { StyledButton } from "../../components/atoms/Button/Button";
import { PageWrapper } from "../../components/atoms/PageWrapper/PageWrapper";
import SubHeader from "../../components/atoms/SubHeader/SubHeader";

import {
  FormRoute,
  RouteInputsT,
} from "../../components/molecules/FormRoute/FormRoute";

import { Layout } from "../../components/organisms/Layout/Layout";
import { WINDOW_TITLE_NAMES } from "../../constants";

import { useMutationEditRoute } from "../../hooks/useMutationEditRoute";
import {
  useQueryRouteGet,
  useQueryRouteGroupListGet,
} from "../../hooks/useQueries";

export const RouteEditPage: FC = () => {
  document.title = WINDOW_TITLE_NAMES.routes;
  const [isFormTouched, setIsFormTouched] = useState(false);
  const { routeId } = useParams() as { routeId: string };
  const navigate = useNavigate();
  const { data: routeGroups } = useQueryRouteGroupListGet();
  const routeEditMutation = useMutationEditRoute(routeId);

  const { data, isLoading } = useQueryRouteGet(routeId);

  const handleSubmit = (values: RouteInputsT) => {
    routeEditMutation.mutate(values);
  };

  return (
    <Layout>
      <SubHeader
        isDetail
        title="Edit route"
        actions={
          <>
            <StyledButton
              onClick={() => navigate(-1)}
              color="dark"
              outline
              title="Cancel"
            />
            <StyledButton
              disabled={!isFormTouched}
              color="primary"
              type="submit"
              form="route-form"
            >
              {!routeEditMutation.isLoading ? "Save" : "Saving"}
            </StyledButton>
          </>
        }
      />
      <PageWrapper>
        <Container>
          <FormRoute
            defaultValues={data?.data}
            onTouch={() => setIsFormTouched(true)}
            onSubmit={handleSubmit}
            routeGroups={routeGroups?.data.items}
            isDisabledAll={isLoading}
            isEditingMode={true}
          />
        </Container>
      </PageWrapper>
    </Layout>
  );
};
