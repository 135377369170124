import { FC, useMemo, useState } from "react";
import { ListedTypeT } from "../../types/Notification";
import { useSelector } from "../../store/hooks";
import { useSearchParams } from "../../hooks/useSearchParam";
import { useQueryNotificationInfiniteList } from "../../hooks/useQueries";
import { useMutationNotificationAllRead } from "../../hooks/useMutationNotificationAllRead";
import { splitPascalCase } from "../../functions/getHumanReadableText";
import { Layout } from "../../components/organisms/Layout/Layout";
import MenuWithLayout, {
  MenuWithLayoutItemT,
} from "../../components/organisms/MenuWithLayout/MenuWithLayout";
import { DataGrid } from "../../components/molecules/DataGrid/DataGrid";
import SubHeader from "../../components/atoms/SubHeader/SubHeader";
import { LoadMoreButton } from "../../components/atoms/LoadMoreButton/LoadMoreButton";
import { StyledButton } from "../../components/atoms/Button/Button";

type PropsT = {};

export const NotificationsPage: FC<PropsT> = () => {
  const { getParamValue, updateSearchParams } = useSearchParams();
  const [currentPage, setCurrentPage] = useState(0);
  const user = useSelector((state) => state.user.user);

  const unfilteredListedType = getParamValue("notificationType");
  const listedType: ListedTypeT =
    unfilteredListedType === "all" ? "all" : "unread";

  const { data, isLoading, isFetchingNextPage, fetchNextPage, refetch } =
    useQueryNotificationInfiniteList({
      isRead: listedType === "all" ? undefined : false,
      userId: user?.id.toString(),
    });

  const { isLoading: isMarkingRead, mutate: markAsReadMutation } =
    useMutationNotificationAllRead({
      onSuccess: () => {
        refetch();
      },
    });
  const markAsRead = () => markAsReadMutation();

  const changeType = (type: ListedTypeT) => {
    setCurrentPage(0);
    updateSearchParams((state) => {
      return { ...state, notificationType: type };
    });
  };

  const fetchNext = () => {
    setCurrentPage(currentPage + 1);
    fetchNextPage({ pageParam: currentPage + 1 });
  };

  const totalCount = data?.pages[0].data.totalCount || 0;
  const notifications = useMemo(() => {
    return data?.pages
      .flat()
      .map((list) => list.data.items)
      .flat();
  }, [data]);

  return (
    <Layout whiteBackground title="Notifications">
      <SubHeader
        title="Notifications"
        titleDropdown={splitPascalCase(listedType)}
        items={[
          {
            id: "unread",
            title: "Unread notifications",
            onClick: () => changeType("unread"),
          } as MenuWithLayoutItemT,
          {
            id: "all",
            title: "All notifications",
            onClick: () => changeType("all"),
          } as MenuWithLayoutItemT,
        ]}
        activeItem={listedType}
        actions={
          <StyledButton
            color="light"
            isLoading={isMarkingRead}
            onClick={markAsRead}
          >
            Mark all as read
          </StyledButton>
        }
      />
      <MenuWithLayout
        separateItems={[
          {
            id: "unread",
            title: "Unread notifications",
            onClick: () => changeType("unread"),
          } as MenuWithLayoutItemT,
          {
            id: "all",
            title: "All notifications",
            onClick: () => changeType("all"),
          } as MenuWithLayoutItemT,
        ]}
        activeItem={listedType}
      >
        <DataGrid
          rowComponent="NotificationGridRow"
          headers={["Notification"]}
          data={notifications || []}
          isLoading={isLoading}
        />
        {notifications && totalCount > notifications.length && (
          <LoadMoreButton isLoading={isFetchingNextPage} onClick={fetchNext} />
        )}
      </MenuWithLayout>
    </Layout>
  );
};
