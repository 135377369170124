import React, { useEffect } from "react";
import { Card } from "reactstrap";
import classNames from "classnames";
import { useDispatch } from "react-redux";
import { useQueryMowerStatusGet } from "../../../hooks/useQueries";
import { useSelector } from "../../../store/hooks";
import { setMowerStatusAction } from "../../../store/slices/mowerSlice";
import { CardHeader } from "../../atoms/CardHeader/CardHeader";
import { DeviceMessage } from "../../atoms/DeviceMessage/DeviceMessage";
import { Loader } from "../../atoms/Loader/Loader";
import { Icon } from "../../atoms/Icon";
import { DeviceStatusItem } from "../../atoms/DeviceStatusItem/DeviceStatusItem";
import styles from "./mowerInfoCard.module.scss";
import { getJobStatus } from "../JobStatusCard/utils";

interface MowerInfoProps {
  mowerId: number;
  mowerName: string;
  showFull?: boolean;
  onClick?: VoidFunction;
  segment?: {
    id: number;
    name: string;
    color: {
      primaryColor: string;
      secondaryColor: string;
    };
  };
  borderColor?: string;
}

const MowerInfoCard = ({
  mowerId,
  mowerName,
  segment,
  borderColor,
  showFull,
  onClick,
}: MowerInfoProps) => {
  const dispatch = useDispatch();

  const { data: mowerData, isLoading, error } = useQueryMowerStatusGet(mowerId);

  const jobProgress = useSelector((store) => store.jobRouteType.jobProgress);

  const jobStatus = getJobStatus(jobProgress, mowerId, segment?.id);

  const withProgress = segment && jobStatus; // show status only on job with segments

  const mowerStatus = useSelector(
    (store) => store.mower.mowers[mowerId]?.mowerStatus
  );

  const isMowerOnline = useSelector(
    (store) => store.mower.mowers[mowerId]?.isOnline
  );

  useEffect(() => {
    if (mowerData) {
      dispatch(setMowerStatusAction({ mowerStatus: mowerData?.data, mowerId }));
    }
  }, [mowerData, dispatch, mowerId]);

  if (!mowerStatus) {
    return error ? (
      <Card className={styles["device-disabled"]}>
        <CardHeader title="Default mower" icon="mower" />
        <DeviceMessage text={error.message} />
      </Card>
    ) : (
      <Card className={styles["device-disabled"]}>
        <CardHeader title="Default mower" icon="mower" />
      </Card>
    );
  }

  if (isLoading) {
    return <Loader isCentered={true} />;
  }

  return (
    <Card
      className={classNames(styles.wrapper)}
      style={{
        borderLeftColor: borderColor || "white",
        borderLeftWidth: "5px",
        borderLeftStyle: "solid",
      }}
    >
      <CardHeader
        title={`${mowerName}${segment?.name ? "/".concat(segment.name) : ""}`}
        icon="mower"
        withProgress={
          withProgress ? { value: jobStatus.donePercent } : undefined
        }
        withRtkMode={
          withProgress || !isMowerOnline
            ? undefined
            : { mode: mowerStatus?.status.rtkMode ?? null }
        }
        size="sm"
        onClick={onClick}
      >
        {Boolean(onClick) && (
          <Icon icon="chevron-right" className={styles.chevron} />
        )}
      </CardHeader>
      <div className={classNames("d-flex", styles["status-wrapper"])}>
        <DeviceStatusItem
          led={mowerStatus.status.mode.led}
          text={mowerStatus.status.mode.additionalValue ?? "Autonomous"}
          classNameWrapper={styles["led-wrapper"]}
        />
        <DeviceStatusItem
          led={mowerStatus.status.mowing.led}
          text={mowerStatus.status.mowing.additionalValue ?? "Mowing"}
          classNameWrapper={styles["led-wrapper"]}
        />
      </div>
      {showFull && mowerStatus.message && (
        <DeviceMessage
          text={mowerStatus.message.message ?? ""}
          additionalMessage={mowerStatus.message.additionalMessage ?? ""}
          status={mowerStatus.message.type}
          help={mowerStatus.message.help ?? ""}
        />
      )}
    </Card>
  );
};

export default React.memo(MowerInfoCard);
