import { Link } from "react-router-dom";
import { JobListItemT } from "../../types/Job";
import { ROUTES } from "../../routes";
import { Icon } from "../../components/atoms/Icon";
import { NumberBullet } from "../../components/atoms/NumberBullet/NumberBullet";
import JobItem from "./JobItem";
import styles from "./dashboard.module.scss";

type Props = {
  title: string;
  items: JobListItemT[];
  count?: { open: number; overdue: number };
  showJobOwnerName?: boolean;
};

const JobsList = ({ title, items, count, showJobOwnerName = false }: Props) => {
  return (
    <>
      <div className={styles.title}>
        <div className={styles["title-left"]}>
          <Link
            className={styles.superLink}
            to={ROUTES.jobs(showJobOwnerName ? "delegated" : "my")}
          />
          <span className={styles["title-link"]}>{title}</span>
          {count && (
            <>
              {count.open && <NumberBullet number={count.open} status="Open" />}
              {count.overdue && (
                <NumberBullet number={count.overdue} status="Overdue" />
              )}
            </>
          )}
        </div>
        <Icon icon="arrow-right" className={styles.chevron} />
      </div>
      {items.map((job) => (
        <JobItem key={job.id} job={job} showJobOwnerName={showJobOwnerName} />
      ))}
    </>
  );
};

export default JobsList;
