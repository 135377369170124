import React, { FC, useMemo, useState } from "react";
import { queryClient } from "../..";
import { Link } from "react-router-dom";
import { ROUTES } from "../../routes";
import { WINDOW_TITLE_NAMES } from "../../constants";
import { useSearchParams } from "../../hooks/useSearchParam";
import { useMutationRouteGroupDelete } from "../../hooks/useMutationRouteGroupDelete";
import { useMutationRouteDelete } from "../../hooks/useMutationRouteDelete";
import { usePermissions } from "../../hooks/usePermissions";
import { useDeviceLocation } from "../../hooks/useDeviceLocation";
import {
  useQueryRouteGroupListGet,
  useQueryRouteListInfiniteGet,
} from "../../hooks/useQueries";
import { RouteGroupT, RouteT } from "../../types/Route";
import { Layout } from "../../components/organisms/Layout/Layout";
import { RouteGroupCreateModal } from "../../components/organisms/RouteGroupModal/RouteGroupCreateModal";
import { RouteGroupRenameModal } from "../../components/organisms/RouteGroupModal/RouteGroupRenameModal";
import MenuWithLayout, {
  MenuWithLayoutItemT,
} from "../../components/organisms/MenuWithLayout/MenuWithLayout";
import { useConfirm } from "../../components/molecules/ConfirmBoxProvider/ConfirmBoxProvider";
import { DataGrid } from "../../components/molecules/DataGrid/DataGrid";
import SubHeader from "../../components/atoms/SubHeader/SubHeader";
import { LoadMoreButton } from "../../components/atoms/LoadMoreButton/LoadMoreButton";
import { DropdownButton } from "../../components/atoms/DropdownButton/DropdownButton";

type PropsT = {};

export const RoutesPage: FC<PropsT> = () => {
  document.title = WINDOW_TITLE_NAMES.routes;
  const permissions = usePermissions();
  const { location } = useDeviceLocation();
  const { getParamValue, updateSearchParams } = useSearchParams();
  const confirm = useConfirm();
  const [currentPage, setCurrentPage] = useState(0);
  const [isCreateGroupModalOpen, setIsCreateGroupModalOpen] = useState(false);
  const [isRenameGroupOpen, setIsRenameGroupOpen] = useState(false);
  const [editGroupId, setEditGroupId] = useState<number | undefined>(undefined);
  const search = getParamValue("search");
  const showNearest = getParamValue("showNearest");
  const folderId = getParamValue("folderId");
  const showArchive = getParamValue("showArchive");
  const toggleCreateGroupModal = () => {
    setIsCreateGroupModalOpen((prev) => !prev);
  };
  const deleteGroupMutation = useMutationRouteGroupDelete({
    onSuccess: () => {
      queryClient.invalidateQueries(["group"]);
    },
  });

  const deleteRouteMutation = useMutationRouteDelete({
    onSuccess: () => {
      queryClient.invalidateQueries(["route"]);
    },
  });

  const handleDeleteGroup = (group: RouteGroupT) => {
    confirm({
      title: "Remove group",
      subTitle: `Are you sure you want to delete route group ${group.name}?`,
      asyncCallback: () => deleteGroupMutation.mutateAsync(group.id),
      type: "delete",
    });
  };

  const handleUpdateGroup = (group: RouteGroupT) => {
    setEditGroupId(group.id);
    setIsRenameGroupOpen(true);
  };

  const handleDeleteRoute = (route: RouteT) => {
    confirm({
      title: "Remove route",
      subTitle: `Are you sure you want to delete route name ${route.name}?`,
      asyncCallback: () => deleteRouteMutation.mutateAsync(route.id),
      type: "delete",
    });
  };

  const onSearch = (searchString: string) => {
    updateSearchParams((prev) => ({ ...prev, search: searchString }));
    queryClient.invalidateQueries(["route", { type: searchString }]);
  };

  const { isLoading: groupIsLoading, data: groupData } =
    useQueryRouteGroupListGet();

  const {
    isLoading: routesIsLoading,
    isFetchingNextPage,
    data: routes,
    fetchNextPage,
  } = useQueryRouteListInfiniteGet(
    search || "",
    folderId,
    showNearest ? location : null,
    showArchive ? "archive" : null,
    showNearest ? "nearest" : null
  );

  const routeRows = useMemo(
    () =>
      routes?.pages
        .flat(0)
        .reduce((acc: RouteT[], page) => [...acc, ...page.data.items], []) ??
      [],
    [routes]
  );

  const setPageAndFetchNextPage = () => {
    setCurrentPage(currentPage + 1);
    fetchNextPage({ pageParam: currentPage + 1 });
  };

  const handleShowNearest = () => {
    updateSearchParams((prev) => ({
      ...prev,
      showNearest: "true",
      showArchive: null,
      folderId: null,
    }));
  };

  const handleShowArchive = () => {
    updateSearchParams((prev) => ({
      ...prev,
      showArchive: "true",
      showNearest: null,
      folderId: null,
    }));
  };

  const handleAllRoutes = () => {
    updateSearchParams((prev) => ({
      ...prev,
      showNearest: null,
      showArchive: null,
      search: null,
      folderId: null,
    }));
  };

  const handleFolderSelect = (groupId: number) => {
    updateSearchParams((prev) => ({
      ...prev,
      folderId: `${groupId}`,
      showNearest: null,
      showArchive: null,
    }));
  };

  const totalCount = routes?.pages[0].data.totalCount || 0;
  const groups = (groupData?.data.items || []).map((group) => ({
    ...group,
    title: group.name,
    onClick: () => handleFolderSelect(group.id),
    onUpdate: () => handleUpdateGroup(group),
    onDelete: () => handleDeleteGroup(group),
  }));

  const isLoading =
    groupIsLoading || routesIsLoading || deleteRouteMutation.isLoading;

  // const switchers = [
  //   {
  //     id: 0,
  //     title: "Routes",
  //     active: true,
  //   },
  //   {
  //     id: 1,
  //     title: "Ufon's points",
  //     onClick: () => navigate(ROUTES.leicas()),
  //   },
  // ];
  const actions = [
    {
      label: "New route - upload .kml",
      tag: Link,
      to: ROUTES.routeCreate(),
    },
    {
      label: "Change route - upload .kml",
      tag: Link,
      to: ROUTES.routeChange(),
    },
    {
      label: "Add group",
      onClick: toggleCreateGroupModal,
    },
  ];

  const getActiveItems = () => {
    if (showNearest) {
      return "nearest";
    }
    if (showArchive) {
      return "archive";
    }
    return Number(folderId) || 0;
  };

  const getDropdownTitle = () => {
    if (showNearest) {
      return "Nearest";
    }
    if (showArchive) {
      return "Archive";
    }
    const a = groups.find((g) => g.id === Number(folderId));
    if (a) {
      return a.title;
    }
    return "All routes";
  };

  return (
    <Layout whiteBackground>
      <SubHeader
        title="Routes"
        titleDropdown={getDropdownTitle()}
        search={{
          placeholder: "Search for route title",
          onChange: onSearch,
        }}
        items={[
          {
            id: 0,
            title: "All routes",
            onClick: () => handleAllRoutes(),
          } as MenuWithLayoutItemT,
          {
            id: "nearest",
            title: "Nearest",
            onClick: () => handleShowNearest(),
            hasSeparator: true,
          } as MenuWithLayoutItemT,
        ].concat(groups)}
        fixedItem={
          {
            id: "archive",
            title: "Archive",
            onClick: () => handleShowArchive(),
          } as MenuWithLayoutItemT
        }
        addNewTitle={"Add group"}
        addNew={toggleCreateGroupModal}
        activeItem={getActiveItems()}
        // switchers={switchers}
        actions={
          permissions.routeManage ? (
            <DropdownButton
              actions={actions}
              toggleProps={{ icon: "plus-circle", onlyIconOnMobile: true }}
              menuProps={{ end: true }}
            >
              Create new
            </DropdownButton>
          ) : undefined
        }
      />
      <MenuWithLayout
        items={groups}
        separateItems={[
          {
            id: 0,
            title: "All routes",
            onClick: handleAllRoutes,
            hasSeparator: true,
          } as MenuWithLayoutItemT,
          {
            id: "nearest",
            title: "Nearest",
            onClick: handleShowNearest,
            hasSeparator: true,
          } as MenuWithLayoutItemT,
        ]}
        fixedItem={
          {
            id: "archive",
            title: "Archive",
            onClick: handleShowArchive,
          } as MenuWithLayoutItemT
        }
        activeItem={getActiveItems()}
        // switchers={switchers}
        addNewButton={
          permissions.routeManage
            ? {
                title: "Add group",
                onClick: toggleCreateGroupModal,
              }
            : undefined
        }
      >
        <DataGrid
          headers={["Route title", "ID"]}
          rowComponent={"RouteGridRow"}
          data={routeRows}
          isLoading={isLoading}
          onDelete={handleDeleteRoute}
        />
        {totalCount > routeRows.length && (
          <LoadMoreButton
            isLoading={isFetchingNextPage}
            onClick={() => setPageAndFetchNextPage()}
          />
        )}
      </MenuWithLayout>
      <RouteGroupCreateModal
        isOpen={isCreateGroupModalOpen}
        onClose={toggleCreateGroupModal}
      />
      <RouteGroupRenameModal
        isOpen={isRenameGroupOpen}
        onClose={() => setIsRenameGroupOpen(false)}
        groupId={editGroupId}
      />
    </Layout>
  );
};
