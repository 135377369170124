import { LatLngT } from "../../../types/Route";

export function sortCoordinatesByMode(
  coordinates: LatLngT[]
): LatLngT[][] | [] {
  if (coordinates.length === 0) {
    return [];
  }

  const sortedCoordinates: LatLngT[][] = [];

  let currentModeIsKnivesOn: boolean | null = null;

  for (let i = 0; i < coordinates.length; i++) {
    if (currentModeIsKnivesOn === null) {
      currentModeIsKnivesOn = coordinates[i].knivesOn ?? false;
      sortedCoordinates.push([coordinates[i]]);
      continue;
    }

    const lastElement = sortedCoordinates[sortedCoordinates.length - 1];

    if ((coordinates[i].knivesOn ?? false) === currentModeIsKnivesOn) {
      lastElement.push(coordinates[i]);
      continue;
    } else if (
      (coordinates[i].knivesOn ?? false) !== currentModeIsKnivesOn &&
      (coordinates[i + 1]?.knivesOn ?? false) === currentModeIsKnivesOn
    ) {
      lastElement.push(coordinates[i]);
    } else {
      sortedCoordinates.push([coordinates[i]]);
      currentModeIsKnivesOn = coordinates[i].knivesOn ?? false;
    }
  }

  return sortedCoordinates;
}
