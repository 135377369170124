import classNames from "classnames";
import styles from "./axis.module.scss";

type Props = {
  data: string[];
  withPadding: boolean;
};

export default function XAxisTick({ data, withPadding }: Props) {
  return (
    <div
      className={classNames(styles.horizontal, {
        [styles["horizontal-withPadding"]]: withPadding,
      })}
    >
      {data.map((item) => (
        <span key={item}>{item}</span>
      ))}
    </div>
  );
}
