import { object, string } from "yup";
import { VALIDATIONS } from "../../../constants";

const phoneRegExp = /^\+[1-9][0-9]{6,14}$/;

export const formCompanySchema = object({
  name: string().required(VALIDATIONS.required),
  phone: string().matches(phoneRegExp, {
    message: VALIDATIONS.phone,
    excludeEmptyString: true,
  }),
}).required();
