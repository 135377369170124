import { useMutation } from "react-query";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { UserInputsT } from "../components/molecules/FormUser/FormUser";
import { ROUTES } from "../routes";
import { client } from "../services/axios";
import { ApiErrorT } from "../types/Common";
import { MutationOptionsT } from "../types/ReactQuery";

export const useMutationUserUpdate = (
  userId: string | number,
  options?: MutationOptionsT
) => {
  const navigate = useNavigate();

  return useMutation(
    (data: UserInputsT) => {
      const { password, company, ...dataToPut } = data;
      const passwordToPut =
        password !== undefined && password !== "" ? { password } : {};
      return client.put(`/api/v1/user/${userId}`, {
        ...dataToPut,
        ...passwordToPut,
        company: company.map((comp) => Number(comp.companyId)),
      });
    },
    {
      onSuccess: () => {
        toast.success("User has been updated");
        navigate(ROUTES.users());
        if (options?.onSuccess) {
          options.onSuccess();
        }
      },
      onError: (error: ApiErrorT) => {
        toast.error(error?.message);
        if (options?.onError) {
          options.onError();
        }
      },
    }
  );
};
