import { ReactNode } from "react";
import { Card, CardBody, CardHeader } from "reactstrap";
import styles from "./cardWithHeader.module.scss";
import classNames from "classnames";

type Props = {
  title: string;
  className?: string;
  bodyClassName?: string;
  children: ReactNode;
};

export default function CardWithHeader({
  title,
  className,
  bodyClassName,
  children,
}: Props) {
  return (
    <Card className={className}>
      <CardHeader className={styles.header}>
        <span className={styles.title}>{title}</span>
      </CardHeader>
      <CardBody className={classNames(styles.body, bodyClassName)}>
        {children}
      </CardBody>
    </Card>
  );
}
