import React, { FC, useState } from "react";
import { useNavigate } from "react-router";
import classNames from "classnames";
import { Card, CardBody } from "reactstrap";
import { ROUTES } from "../../../routes";
import { DATE_FORMATS } from "../../../constants";
import { ENVIRONMENT } from "../../../env";
import { GeostickJobT, JobT } from "../../../types/Job";
import { formatDate } from "../../../functions/formatDate";
import { IconsId } from "../../../assets/fonts/iconfont/icons";
import { Icon } from "../../atoms/Icon";
import { Status } from "../../atoms/Status/Status";
import JobIcon from "../../atoms/JobIcon/JobIcon";
import { JobStatusCard } from "../JobStatusCard/JobStatusCard";
import styles from "./jobCard.module.scss";

interface PropsT {
  job: JobT | GeostickJobT;
  showFull?: boolean;
  showStatus?: boolean;
  setCurrentPage?: VoidFunction;
}

const JobCard: FC<PropsT> = ({
  job,
  showFull = true,
  showStatus = true,
  setCurrentPage,
}) => {
  const [isShowingFull, setIsShowingFull] = useState(showFull);
  const navigate = useNavigate();
  const mowers = job.users.flatMap((user) => user.mowers);
  const route = job.route;
  const ufons = mowers
    .map((mower) => mower.ufon)
    .filter((ufon, index, array) => {
      if (ufon) {
        return (
          index === array.findIndex((foundItem) => foundItem?.id === ufon.id)
        );
      }
    }) as {
    id: number;
    name: string;
  }[];
  const geostickBaseUfon = job.geostick && job.geostick.ufon;
  const geostickRoverUfon = job.geostick && job.geostick.roverUfon;

  const renderJobLinks = (
    icon: IconsId,
    links: { name: string; action: VoidFunction }[]
  ) => {
    return (
      <div className={styles.row}>
        <Icon icon={icon} className={styles["text-gray"]} />
        <p className={styles["text-black"]}>
          {links.map((link, index) => {
            return (
              <React.Fragment key={`${link.name}-${index}`}>
                {index !== 0 && ", "}
                <a
                  onClick={link.action}
                  className={classNames(styles["text-black"], styles.link)}
                >
                  {link.name}
                </a>
              </React.Fragment>
            );
          })}
        </p>
      </div>
    );
  };

  return (
    <>
      {/* Overview subcard */}
      {isShowingFull && (
        <>
          <Card>
            <CardBody>
              <div className={styles.container}>
                <Status job={job} large />
                <div className={styles["row-header"]}>
                  <Icon icon="calendar-alt" className="me-2" />
                  <p className={styles["text-black"]}>
                    {formatDate(job.dueDate, DATE_FORMATS.date)}
                  </p>
                </div>
                <div className={styles["row-header"]}>
                  <JobIcon jobName={job.type.name} />
                </div>
              </div>
              <p className={styles["detail-text"]}>{job.description}</p>
            </CardBody>
          </Card>
          <div className={styles.divider} />
        </>
      )}
      {/* Status subcard */}
      {ENVIRONMENT !== "production" && showStatus && (
        <JobStatusCard onClick={setCurrentPage && setCurrentPage} />
      )}
      {/* Links subcard */}
      <div className={styles.divider} />
      {isShowingFull && (
        <Card>
          <CardBody>
            {route &&
              renderJobLinks("route", [
                {
                  name: route.name,
                  action: () => navigate(ROUTES.route(route.id)),
                },
              ])}
            {renderJobLinks(
              "user",
              job.users.map((user) => ({
                name: user.name,
                action: () => navigate(ROUTES.user(user.id)),
              }))
            )}
            {mowers.length > 0 &&
              renderJobLinks(
                "mower",
                mowers.map((mower) => ({
                  name: mower.name,
                  action: () => navigate(ROUTES.mower(mower.id)),
                }))
              )}
            {ufons.length > 0 &&
              renderJobLinks(
                "broadcast",
                ufons.map((ufon) => ({
                  name: ufon.name,
                  action: () => navigate(ROUTES.ufon(ufon.id)),
                }))
              )}
            {geostickBaseUfon &&
              renderJobLinks("broadcast-tower", [
                {
                  name: geostickBaseUfon.name,
                  action: () => navigate(ROUTES.ufon(geostickBaseUfon.id)),
                },
              ])}
            {geostickRoverUfon &&
              renderJobLinks("crosshairs-location", [
                {
                  name: geostickRoverUfon.name,
                  action: () => navigate(ROUTES.ufon(geostickRoverUfon.id)),
                },
              ])}
          </CardBody>
        </Card>
      )}
      {!showFull && (
        <p
          className={styles["show-button"]}
          onClick={() => setIsShowingFull((prev) => !prev)}
        >
          {isShowingFull ? "Show less" : "Show more"}
        </p>
      )}
    </>
  );
};

export default JobCard;
