import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { client } from "../services/axios";
import { ApiErrorT } from "../types/Common";
import { MutationOptionsT } from "../types/ReactQuery";

type CompleteSegmentT = {
  jobId: string | number;
  userId: number;
};

export const useMutationCompleteUserSegments = (options?: MutationOptionsT) => {
  return useMutation(
    ({ jobId, userId }: CompleteSegmentT) => {
      return client.put(`/api/v1/job/${jobId}/segment/user/${userId}/complete`);
    },
    {
      onSuccess: () => {
        toast.success("Segments have been completed");
        if (options?.onSuccess) {
          options.onSuccess();
        }
      },
      onError: (error: ApiErrorT) => {
        toast.error(error?.message);
        if (options?.onError) {
          options.onError();
        }
      },
    }
  );
};
