import { FC } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { JobT } from "../../../types/Job";
import { UserPreviewT } from "../../../types/User";
import { StyledButton } from "../../../components/atoms/Button/Button";
import styles from "../jobRouteType.module.scss";

type PropsT = {
  job: JobT;
  isOpen: boolean;
  onClose: VoidFunction;
  onSelectUser: (user: UserPreviewT) => void;
};

export const UserSelectModal: FC<PropsT> = ({
  job,
  isOpen,
  onClose,
  onSelectUser,
}: PropsT) => {
  return (
    <Modal toggle={onClose} isOpen={isOpen} centered>
      <ModalHeader>Select user to display related tasks</ModalHeader>
      <ModalBody>
        <div className={styles["user-select"]}>
          {job.users.map((user) => (
            <StyledButton
              key={user.id}
              color="light"
              block
              className="mt-4"
              onClick={() => onSelectUser(user)}
            >
              {user.name}
            </StyledButton>
          ))}
        </div>
      </ModalBody>
    </Modal>
  );
};
