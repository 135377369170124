import { ChartData, ChartType } from "../../../types/Common";
import {
  Bar,
  BarChart,
  Cell,
  ReferenceLine,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";
import { fromUnixTime } from "date-fns";
import { formatDate } from "../../../functions/formatDate";
import { DATE_FORMATS } from "../../../constants";
import XAxisTick from "./ChartElements/XAxisTick";
import Legend from "./ChartElements/Legend";

type Props = {
  chartType: ChartType;
  data?: ChartData[];
  referenceLine?: number;
  desiredTimestamps?: number[];
  height?: number;
  width?: number;
  yAxis?: boolean;
  xAxis?: boolean;
  withLegend?: boolean;
  maxValue?: number;
};

export default function ConnectionChart({
  chartType,
  data,
  referenceLine,
  desiredTimestamps,
  height = 50,
  yAxis = false,
  xAxis = false,
  withLegend = false,
  maxValue,
}: Props) {
  function formatYAxis(value: number) {
    return `${value}%`;
  }
  function getXAxisData() {
    if (!data) return [];
    const first = data[0].time;
    const last = data[data.length - 1].time;
    if (!first || !last) return [];

    return [
      formatDate(fromUnixTime(first), DATE_FORMATS.dateTimeMedium),
      formatDate(fromUnixTime(last), DATE_FORMATS.dateTimeMedium),
    ];
  }

  function padData(chartData: ChartData[], templateTimestamps: number[]) {
    if (chartData.length === 0) return chartData;

    const paddedData: ChartData[] = [];

    let idx = 0;
    let current: ChartData = { value: 0, color: undefined, time: 0 };
    let nextTimestamp: number | undefined = chartData[idx].time;

    for (const timestamp of templateTimestamps) {
      while (nextTimestamp && timestamp >= nextTimestamp) {
        current = chartData[idx];
        idx += 1;
        nextTimestamp = chartData[idx]?.time;
      }
      paddedData.push({
        time: timestamp,
        value: current.value,
        color: current.color,
      });
    }

    return paddedData;
  }

  if (!data) return null;

  const paddedData = desiredTimestamps
    ? padData(data, desiredTimestamps)
    : data;

  return (
    <>
      <ResponsiveContainer width="100%" height={height}>
        <BarChart data={paddedData}>
          <Bar dataKey="value" fill="#82ca9d" animationDuration={0}>
            {paddedData.map((item, index) => (
              <Cell key={`cell-${index}`} fill={item.color} />
            ))}
          </Bar>
          {referenceLine && (
            <ReferenceLine
              y={referenceLine}
              stroke="black"
              ifOverflow="extendDomain"
            />
          )}
          {!yAxis && (
            <YAxis
              type="number"
              domain={maxValue !== undefined ? [0, maxValue] : [0, "auto"]}
              hide
            />
          )}
          {yAxis && (
            <YAxis
              axisLine={false}
              tickLine={false}
              tickFormatter={formatYAxis}
              tickCount={3}
              type="number"
              domain={maxValue !== undefined ? [0, maxValue] : [0, "auto"]}
            />
          )}
          {yAxis && <XAxis tick={false} axisLine={false} />}
        </BarChart>
      </ResponsiveContainer>
      {xAxis && <XAxisTick data={getXAxisData()} withPadding={yAxis} />}
      {withLegend && <Legend chartType={chartType} />}
    </>
  );
}
