import React, { FC, useState } from "react";
import { Container } from "reactstrap";
import { StyledButton } from "../../components/atoms/Button/Button";
import { Loader } from "../../components/atoms/Loader/Loader";
import SubHeader from "../../components/atoms/SubHeader/SubHeader";
import { DataGrid } from "../../components/molecules/DataGrid/DataGrid";
import { CompanyCreateModal } from "../../components/organisms/CompanyCreateModal/CompanyCreateModal";
import { Layout } from "../../components/organisms/Layout/Layout";
import { WINDOW_TITLE_NAMES } from "../../constants";
import { useQueryCompaniesGet } from "../../hooks/useQueries";
import { CompanyT } from "../../types/Company";
import { CompanyUpdateModal } from "./CompanyUpdateModal";

export const CompaniesPage: FC = () => {
  document.title = WINDOW_TITLE_NAMES.companies;
  const { isLoading, data } = useQueryCompaniesGet();
  const [currentCompany, setCurrentCompany] = useState<CompanyT>();
  const [isCreateCompanyModalOpen, setIsCreateCompanyModalOpen] =
    useState(false);
  const [isUpdateCompanyModalOpen, setIsUpdateCompanyModalOpen] =
    useState(false);

  const toggleCreateCompanyModal = () => {
    setCurrentCompany(undefined);
    setIsCreateCompanyModalOpen((prev) => !prev);
  };

  const toggleUpdateCompanyModal = () => {
    setIsUpdateCompanyModalOpen((prev) => !prev);
  };

  const toggleCopyCompanyModal = () => {
    setIsCreateCompanyModalOpen((prev) => !prev);
  };

  const handleCopy = (item: CompanyT) => {
    setCurrentCompany(item);
    toggleCopyCompanyModal();
  };

  const handleUpdate = (item: CompanyT) => {
    setCurrentCompany(item);
    toggleUpdateCompanyModal();
  };

  const HeaderActions = () => (
    <StyledButton
      onlyIconOnMobile
      color="primary"
      title="New company"
      icon="plus-circle"
      onClick={toggleCreateCompanyModal}
    />
  );

  const companies: CompanyT[] = data?.data.items ?? [];

  return (
    <Layout whiteBackground>
      <SubHeader title="Companies" actions={HeaderActions()} />
      <Container fluid>
        <Loader isLoading={isLoading} isOverlay />
        <DataGrid
          onCopy={handleCopy}
          onUpdate={handleUpdate}
          headers={["ID", "Company name"]}
          rowComponent={"CompanyGridRow"}
          data={companies}
          isLoading={isLoading}
        />
      </Container>
      <CompanyCreateModal
        initialData={currentCompany}
        isOpen={isCreateCompanyModalOpen}
        onClose={toggleCreateCompanyModal}
      />
      {currentCompany && (
        <CompanyUpdateModal
          initialData={currentCompany}
          isOpen={isUpdateCompanyModalOpen}
          onClose={toggleUpdateCompanyModal}
        />
      )}
    </Layout>
  );
};
