import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { client } from "../services/axios";
import { ApiErrorT } from "../types/Common";
import { MutationOptionsT } from "../types/ReactQuery";
import { createQueryString } from "../functions/routing";

export const useMutationUploadMowerCoordinates = (
  jobId: string | number,
  mowerId: string | number,
  segmentId?: number,
  options?: MutationOptionsT
) => {
  return useMutation(
    () => {
      return client.put(
        `/api/v1/job/${jobId}/send-coordinates/${mowerId}` +
          createQueryString({ segmentId })
      );
    },
    {
      onError: (error: ApiErrorT) => {
        toast.error(error?.message);
        if (options?.onError) {
          options.onError();
        }
      },
    }
  );
};
