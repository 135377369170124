import classNames from "classnames";
import { ChartType } from "../../../../types/Common";
import styles from "./legend.module.scss";

type Props = {
  chartType: ChartType;
};

type ItemProps = {
  data: {
    className: string;
    title: string;
  };
};

const completeLegend = [
  {
    title: "Strong signal",
    className: "signal-strong",
  },
  {
    title: "Medium signal",
    className: "signal-medium",
  },
  {
    title: "Poor signal",
    className: "signal-poor",
  },
];

const shortLegend = [
  {
    title: "Signal",
    className: "signal-strong",
  },
  {
    title: "No signal",
    className: "signal-poor",
  },
];

const LegendItem = ({ data: { className, title } }: ItemProps) => {
  return (
    <div className={styles.box}>
      <div className={classNames(styles.signal, styles[className])} />
      <span>{title}</span>
    </div>
  );
};

export default function Legend({ chartType }: Props) {
  if (chartType === ChartType.correction) {
    return (
      <div className={styles.wrapper}>
        {shortLegend.map((item) => (
          <LegendItem data={item} key={item.title} />
        ))}
      </div>
    );
  }

  return (
    <div className={styles.wrapper}>
      {completeLegend.map((item) => (
        <LegendItem data={item} key={item.title} />
      ))}
      <div className={styles.box}>
        <div className={styles.strength} />
        <span>Strength limit</span>
      </div>
    </div>
  );
}
