import { useMemo, useState } from "react";
import { useNavigate } from "react-router";
import { Nav, NavItem, NavLink, Popover, PopoverBody } from "reactstrap";
import classNames from "classnames";
import { ROUTES } from "../../../routes";
import { ListedTypeT } from "../../../types/Notification";
import { useSelector } from "../../../store/hooks";
import { useDeviceScreenWidth } from "../../../hooks/useDeviceScreenWidth";
import { useQueryNotificationInfiniteList } from "../../../hooks/useQueries";
import { useMutationNotificationAllRead } from "../../../hooks/useMutationNotificationAllRead";
import { notificationUnread } from "../../../functions/notification";
import { Icon } from "../../atoms/Icon";
import { StyledButton } from "../../atoms/Button/Button";
import { Loader } from "../../atoms/Loader/Loader";
import { LoadMoreButton } from "../../atoms/LoadMoreButton/LoadMoreButton";
import { NotificationItem } from "../../atoms/NotificationItem/NotificationItem";
import { NotificationBadge } from "./NotificationBadge";
import styles from "./notificationBell.module.scss";

type PropsT = {};

export function NotificationBell({}: PropsT) {
  const isCompact = useDeviceScreenWidth(1054);
  const navigate = useNavigate();
  const user = useSelector((state) => state.user.user);
  const [isOpen, setIsOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [listedType, setListedType] = useState<ListedTypeT>("unread");
  const { data, isLoading, isFetchingNextPage, fetchNextPage, refetch } =
    useQueryNotificationInfiniteList({
      isRead: listedType === "all" ? undefined : false,
      userId: user?.id.toString(),
    });

  const { isLoading: isMarkingRead, mutate: markAsReadMutation } =
    useMutationNotificationAllRead({
      onSuccess: () => {
        refetch();
      },
    });
  const markAsRead = () => markAsReadMutation();

  const changeType = (type: ListedTypeT) => {
    setCurrentPage(0);
    setListedType(type);
  };

  const fetchNext = () => {
    setCurrentPage(currentPage + 1);
    fetchNextPage({ pageParam: currentPage + 1 });
  };

  const totalCount = data?.pages[0].data.totalCount || 0;
  const notifications = useMemo(() => {
    return data?.pages
      .flat()
      .map((list) => list.data.items)
      .flat();
  }, [data]);

  const unreadNotifications =
    notifications?.reduce(
      (unread, notification) =>
        unread + Number(notificationUnread(notification)),
      0
    ) || 0;

  return (
    <div className={styles.wrapper}>
      <Icon
        id="notificationBell"
        className={classNames(styles.bell, {
          [styles["bell-large"]]: !isCompact,
        })}
        icon="bell"
        onClick={() => {
          if (isCompact) {
            navigate(ROUTES.notifications());
          }
        }}
      />
      {unreadNotifications > 0 && (
        <NotificationBadge number={unreadNotifications} />
      )}
      <Popover
        target="notificationBell"
        trigger="legacy"
        isOpen={isOpen}
        toggle={() => {
          if (!isCompact) {
            setIsOpen(!isOpen);
          }
        }}
        placement="bottom-end"
      >
        <PopoverBody className={styles.body}>
          <div className={styles.selector}>
            <Nav className={styles.nav}>
              <NavItem className={styles.navItem}>
                <NavLink
                  className={classNames(styles["nav-link"], {
                    [styles["nav-link--active"]]: listedType === "unread",
                  })}
                  onClick={() => changeType("unread")}
                >
                  Unread
                </NavLink>
              </NavItem>
              <NavItem className={styles.navItem}>
                <NavLink
                  className={classNames(styles["nav-link"], {
                    [styles["nav-link--active"]]: listedType === "all",
                  })}
                  onClick={() => changeType("all")}
                >
                  All
                </NavLink>
              </NavItem>
            </Nav>
          </div>
          <Loader isLoading={isLoading} />
          <div className={styles.notificationList}>
            {notifications?.map((notification) => (
              <NotificationItem
                key={notification.id}
                notification={notification}
              />
            ))}
            {notifications && totalCount > notifications.length && (
              <div className={styles.loadMoreButton}>
                <LoadMoreButton
                  isLoading={isFetchingNextPage}
                  onClick={fetchNext}
                />
              </div>
            )}
          </div>
          <div className={styles.footer}>
            <StyledButton
              className={styles.footerButton}
              color="link"
              isLoading={isMarkingRead}
              onClick={markAsRead}
            >
              Mark all as read
            </StyledButton>
          </div>
        </PopoverBody>
      </Popover>
    </div>
  );
}
