import { createAction, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { objectReducerGenerator } from "../objectReducerGenerator";
import { storeClear } from "../actions";
import {
  JobUserTaskT,
  RouteComputeStatus,
  TaskStatusT,
  TaskTypeT,
} from "../../types/Job";
import { WSEventMessage } from "../../types/Websocket";

export interface Task {
  name: string;
  type: TaskTypeT;
}

export interface LastMessage {
  message: WSEventMessage<unknown>;
  timestamp: Date;
}

export interface TasksStateT {
  deviceStatusOnline: boolean;
  userTasks: JobUserTaskT | null;
  isWaiting: boolean;
  isLoading: boolean;
  isLoadingUser: boolean;
  jobId: string | null;
  lastMessage: LastMessage | null;
  routeComputeStatus: Record<string, RouteComputeStatus>;
}

const initialState: TasksStateT = {
  deviceStatusOnline: false,
  userTasks: null,
  isWaiting: false,
  isLoading: false,
  isLoadingUser: false,
  jobId: null,
  lastMessage: null,
  routeComputeStatus: {},
};

export const tasksSlice = createSlice({
  name: "tasks",
  initialState,
  reducers: {
    ...objectReducerGenerator<TasksStateT>(),
    setDeviceStatus: (state, action: PayloadAction<boolean>) => {
      state.deviceStatusOnline = action.payload;
    },
    setIsWaiting: (state, action: PayloadAction<boolean>) => {
      state.isWaiting = action.payload;
    },
    setJobIdAction: (state, action: PayloadAction<string>) => {
      state.jobId = action.payload;
    },
    setTasks: (state, action: PayloadAction<JobUserTaskT>) => {
      state.userTasks = action.payload;
    },
    setRouteComputeStatusAction: (
      state,
      action: PayloadAction<RouteComputeStatus>
    ) => {
      state.routeComputeStatus[action.payload.jobId] = action.payload;
    },
    setIsLoadingAction: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setIsLoadingUserAction: (state, action: PayloadAction<boolean>) => {
      state.isLoadingUser = action.payload;
    },
    setLastMessageAction: (state, action: PayloadAction<LastMessage>) => {
      state.lastMessage = action.payload;
    },
    clearTasksAction: (state) => {
      return {
        ...initialState,
        deviceStatusOnline: state.deviceStatusOnline,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(storeClear, () => initialState);
  },
});

export const fetchTasksAction =
  createAction<{ jobId: string; userId: string }>("FETCH_TASKS");
export type FetchTaskActionType = ReturnType<typeof fetchTasksAction>;

export const updateTaskAction = createAction<{
  jobId: string;
  type: TaskTypeT;
  newStatus: TaskStatusT;
  options?: {
    mowerId?: number;
    diameter?: number;
    name?: string;
    folderId?: number;
  };
}>("UPDATE_TASK");
export type UpdateTaskActionType = ReturnType<typeof updateTaskAction>;

export const changeUserAction =
  createAction<{ jobId: string; userId: string }>("CHANGE_USER");
export type ChangeUserActionType = ReturnType<typeof changeUserAction>;

export const subscribeToTaskAction = createAction<{
  jobId: number | string;
}>("SUBSCRIBE_TO_TASK");
export type SubscribeToTaskActionType = ReturnType<
  typeof subscribeToTaskAction
>;

export const unsubscribeFromTaskAction = createAction<{
  jobId: number | string;
}>("UNSUBSCRIBE_FROM_TASK");
export type UnsubscribeFromTaskActionType = ReturnType<
  typeof unsubscribeFromTaskAction
>;

export const {
  setDeviceStatus,
  setIsWaiting,
  setTasks,
  setIsLoadingAction,
  setIsLoadingUserAction,
  clearTasksAction,
  setJobIdAction,
  setLastMessageAction,
  setRouteComputeStatusAction,
} = tasksSlice.actions;
