import { FC } from "react";
import { useQueryMowerLogGet } from "../../../hooks/useQueries";
import { useSearchParams } from "react-router-dom";
import LogBase from "./LogBase";

type Props = {
  id?: number | string;
};

const Log: FC<Props> = ({ id }) => {
  const [searchParams] = useSearchParams();
  const mowerId = searchParams.get("mowerId");

  if (id || mowerId) {
    return (
      <LogBase id={id || mowerId || 0} useRawDataGet={useQueryMowerLogGet} />
    );
  }

  return <h5 className="text-center">Select mower to see its logs</h5>;
};

export default Log;
