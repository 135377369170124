import { AxiosResponse } from "axios";
import { differenceInMilliseconds } from "date-fns";
import { queryClient } from "../..";
import { client } from "../../services/axios";
import { JobUserTaskT, TaskT, TaskWaitForT } from "../../types/Job";
import {
  MowerReplyData,
  StatusDeviceData,
  WSEventMessage,
} from "../../types/Websocket";
import { CoordinatesT, RouteLayers } from "../../types/Route";
import { ConnectionDatapoint, ConnectionT } from "../../types/Common";
import { routeLayersInitial } from "../slices/jobRouteTypeSlice";
import { createQueryString } from "../../functions/routing";

export const waitForMessageArr = (userTask: JobUserTaskT) => {
  const { canCompleteJob, tasks } = userTask;
  if (canCompleteJob) {
    return [];
  }

  return tasks.filter(
    (task) => task.startAt && !task.finishedAt && task.status === "pending"
  );
};

export const getEventType1 = (parsedMsg: WSEventMessage<unknown>) => {
  const { eventType, entityId } = parsedMsg;
  let msgString = `${eventType}_${entityId}`;
  if (eventType === "mowerReplyReceived") {
    const { type } = parsedMsg.data as MowerReplyData;
    msgString += `_${type}`;
  }
  return msgString;
};

export const getEventType2 = (waitFor: TaskWaitForT) => {
  let msgString = "";

  if (waitFor) {
    const { eventType, entityId } = waitFor;
    msgString = `${eventType}_${entityId}`;
    if (waitFor.key && waitFor.value) {
      msgString += `_${waitFor.value}`;
    }

    return msgString;
  }

  return "";
};

export const getOpenTask = (tasks: TaskT[]) =>
  tasks.find((task) => task.startAt && !task.finishedAt);

export const fetchUserTasks = async (jobId: string, userId: string) => {
  const { data } = await queryClient.fetchQuery("user-tasks", () =>
    client.get(`/api/v1/job/${jobId}/task/${userId}`)
  );
  return data;
};

export const fetchMowerConnection = async (mowerId: number) => {
  const { data } = await queryClient.fetchQuery(
    ["mowerConnection", mowerId],
    () => client.get(`/api/v1/mower/${mowerId}/connection`)
  );
  return data;
};

export const fetchUfonConnection = async (ufonId: number) => {
  const { data } = await queryClient.fetchQuery(
    ["ufonConnection", ufonId],
    () => client.get(`/api/v1/ufon/${ufonId}/connection`)
  );
  return data;
};

export const fetchStatusDevice = async () => {
  const { data } = await queryClient.fetchQuery<
    AxiosResponse<StatusDeviceData>
  >("user-tasks", () => client.get("/api/v1/system/status-device"));
  return data;
};

export const getTimeDiffFromDate = (validTo: string) => {
  const validToDate = new Date(validTo);
  const currentDate = new Date();
  const timeToNextMessage = differenceInMilliseconds(validToDate, currentDate);
  return timeToNextMessage;
};

export const uploadRouteMutation = (
  jobId: string | number,
  mowerId: string | number,
  segmentId?: number
) => {
  client.put(
    `/api/v1/job/${jobId}/send-coordinates/${mowerId}` +
      createQueryString({ segmentId })
  );
};

export const fetchRouteCoordinates = async (routeId: number) => {
  const { data }: { data: CoordinatesT<"route"> } =
    await queryClient.fetchQuery(["coordinates", routeId], () =>
      client.get(`/api/v1/route/${routeId}/coordinates`)
    );
  return data;
};

export const fetchSegmentCoordinates = async (
  jobId: number | string,
  segmentId: number
) => {
  const { data }: { data: CoordinatesT<"route"> } =
    await queryClient.fetchQuery(["segmentcoordinates", segmentId], () =>
      client.get(
        `/api/v1/job/${jobId}/coordinates` + createQueryString({ segmentId })
      )
    );
  return data;
};

export const getLayersFromCoordinates = (
  routeCoordinates: CoordinatesT<"route">,
  routeLayers: RouteLayers,
  isChecked: boolean,
  layersForSegments?: boolean
) => {
  let newState = { ...routeLayers };
  routeCoordinates.coordinates.forEach((item, index) => {
    if (item.styleType !== "endPoint" && item.styleType !== "startPoint") {
      newState = {
        ...newState,
        route: newState.route.includes(index)
          ? [...newState.route]
          : [...newState.route, index],
        routeChecked: isChecked,
        activityChecked: isChecked,
      };
    } else if (item.styleType === "startPoint") {
      newState = {
        ...newState,
        startPoint: index,
        startPointChecked: isChecked,
      };
    } else if (item.styleType === "endPoint") {
      newState = {
        ...newState,
        endPoint: index,
        endPointChecked: isChecked,
      };
    }
  });
  let checkedAll =
    newState.endPointChecked &&
    newState.startPointChecked &&
    newState.routeChecked;

  if (layersForSegments) {
    checkedAll =
      newState.endPointChecked &&
      newState.startPointChecked &&
      newState.routeChecked &&
      newState.activityChecked;
  }
  newState = { ...newState, checkedAll };
  return newState;
};

export const getSegmentLayersFromCoordinates = (
  segmentsCoordinates: Record<number, CoordinatesT<"route">>,
  segmentsLayers: Record<number, RouteLayers> | null,
  segmentIds: number[],
  lastSegments: number[]
) => {
  return segmentIds.reduce<Record<number, RouteLayers>>((acc, curr) => {
    const checked = lastSegments.includes(curr);
    const newState = getLayersFromCoordinates(
      segmentsCoordinates[curr],
      segmentsLayers ? segmentsLayers[curr] : routeLayersInitial,
      checked,
      true
    );
    acc[curr] = newState;
    return acc;
  }, {});
};

type Seconds = number;

function lastBeforeCuttoff<T>(
  datapoints: ConnectionDatapoint<T>[],
  cutoff: number
) {
  let previous = undefined;
  for (const datapoint of datapoints) {
    if (datapoint.date > cutoff) {
      return previous;
    }
    previous = datapoint;
  }
  return previous;
}

function trimDatapoints<T>(
  datapoints: ConnectionDatapoint<T>[],
  limit: Seconds
): ConnectionDatapoint<T>[] {
  const unixTime = Math.floor(Date.now() / 1000);
  const limitTime = unixTime - limit;
  const afterLimit = datapoints.filter(
    (datapoint) => datapoint.date >= limitTime
  );
  const lastBefore = lastBeforeCuttoff(datapoints, limitTime);
  if (lastBefore) {
    afterLimit.unshift(lastBefore);
  }
  return afterLimit;
}

function mergeConnectionData<T>(
  current: ConnectionDatapoint<T>[],
  incoming: ConnectionDatapoint<T>[]
): ConnectionDatapoint<T>[] {
  if (incoming.length === 0) {
    return current;
  }
  if (current.length === 0) {
    return incoming;
  }
  // Incoming includes datapoints already in current, so we need to remove duplicates
  const lastCurrent = current[current.length - 1];
  const incomingAfterLastCurrent = incoming.filter(
    (datapoint) => datapoint.date > lastCurrent.date
  );
  return [...current, ...incomingAfterLastCurrent];
}

export function combineConnectionData<T extends ConnectionT>(
  current: T,
  incoming: T,
  limit: Seconds
): T {
  const corrections = trimDatapoints(
    mergeConnectionData(
      current.correction.chartData,
      incoming.correction.chartData
    ),
    limit
  );
  const internet = trimDatapoints(
    mergeConnectionData(
      current.internet.chartData,
      incoming.internet.chartData
    ),
    limit
  );
  const position = trimDatapoints(
    mergeConnectionData(
      current.position.chartData,
      incoming.position.chartData
    ),
    limit
  );
  return {
    ...incoming,
    correction: {
      ...incoming.correction,
      value:
        corrections.length > 0
          ? corrections[corrections.length - 1].value
          : null,
      chartData: corrections,
    },
    internet: {
      ...incoming.internet,
      signalStrength:
        internet.length > 0 ? internet[internet.length - 1].value : null,
      chartData: internet,
    },
    position: {
      ...incoming.position,
      satellites: position.length > 0 ? position[position.length - 1].value : 0,
      chartData: position,
    },
    server: {
      ...incoming.server,
    },
  };
}
