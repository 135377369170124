import React, { FC, useRef, useState } from "react";
import { GoogleMap, GoogleMapProps } from "@react-google-maps/api";
import { JobT } from "../../../types/Job";
import { JobMapInfo } from "../../../types/Route";
import { JobState, MowerLocalState, NameAndId } from "../types";
import { getMapBounds } from "../../../functions/getBounds";
import { onLoad } from "../../../functions/onMapLoad";
import { useSelector } from "../../../store/hooks";
import { useUserLocation } from "../../../hooks/useUserLocation";
import {
  findJourneyIds,
  getCurrentUserMowers,
  getUserByMower,
  sortSegmentsByMower,
} from "../utils";
import { useSearchParams } from "../../../hooks/useSearchParam";
import JobMapInfoWindow from "../../../components/molecules/MapInfoWindow/JobMapInfoWindow";
import MapControls from "../../../components/molecules/MapControls/MapControls";
import MowerMarker from "../../../components/atoms/MapMarkers/MowerMarker";
import UserMapMarker from "../../../components/atoms/UserMapMarker/UserMapMarker";
import { Loader } from "../../../components/atoms/Loader/Loader";
import RadioWarning from "../../../components/atoms/RadioWarning/RadioWarning";
import MapMowerTracker from "./MapElements/MapMowerTracker";
import RoutePolylines from "./MapElements/RoutePolylines";
import UfonMarkers from "./MapElements/UfonMarkers";
import SegmentPolylines from "./MapElements/SegmentPolylines";
import DropdownComponent from "./MapElements/DropdownComponent";

export interface JobWithRouteMapProps extends GoogleMapProps {
  job: JobT;
  hasSegments: boolean;
  routeId: number | undefined;
  mowerIds: number[];
  // tempNogo: TaskT[] | undefined;
  jobId: string;
  currentUserId?: number;
  setCurrentPage: (state: JobState) => void;
  setMowerMan: (user: NameAndId | null) => void;
  setDetailMower: (mower: MowerLocalState | null) => void;
  isJobRefetching: boolean;
  setActiveTab?: (tabId: number) => void;
}

export const JobMap: FC<JobWithRouteMapProps> = ({
  job,
  hasSegments,
  routeId,
  mowerIds,
  center,
  jobId,
  currentUserId,
  setCurrentPage,
  setMowerMan,
  setDetailMower,
  isJobRefetching,
  setActiveTab,
  ...restProps
}) => {
  const mapRef = useRef<GoogleMap>(null);

  const { updateSearchParams } = useSearchParams();

  const mowersBySegment = job.segments.users.flatMap((user) => user.mowers);
  const currentUserMowers = getCurrentUserMowers(currentUserId, job);
  const [activeMowerMarker, setActiveMowerMarker] = useState<
    JobMapInfo | undefined
  >(undefined);

  const {
    routeCoordinates,
    activeRouteLayers,
    showActivityByMower,
    segmentsCoordinates,
    activeSegmentsLayers,
    lastSegments: showLastSegments,
    showMowingArrows,
    routeIsLoading,
    segmentIsLoading,
    showActivityTypes,
  } = useSelector((store) => store.jobRouteType);

  const { userLocation, mapCenter, handleUserLocation } = useUserLocation(
    center,
    mapRef
  );

  const handleJobLocation = () => {
    if (!routeCoordinates || !mapRef.current?.state.map) {
      return;
    }
    const bounds: google.maps.LatLngBounds = getMapBounds(routeCoordinates);
    mapRef.current.state.map.fitBounds(bounds);
  };

  if (isJobRefetching || routeIsLoading || segmentIsLoading) {
    return <Loader isCentered={true} />;
  }

  return (
    <GoogleMap
      tilt={0}
      mapTypeId={google.maps.MapTypeId.SATELLITE}
      mapContainerStyle={{ height: "100%" }}
      center={mapCenter}
      zoom={8}
      ref={mapRef}
      onLoad={(map: google.maps.Map) => {
        onLoad(map);
        handleJobLocation();
        map.setZoom(8);
      }}
      {...restProps}
    >
      {job.status.name !== "Finished" && <UfonMarkers job={job} />}
      {userLocation && <UserMapMarker userLocation={userLocation} />}
      <RadioWarning />
      <MapControls
        dropdownComponent={
          <DropdownComponent
            segments={mowersBySegment}
            currentUserId={currentUserId}
            job={job}
            mowerIds={mowerIds}
          />
        }
        handleUserLocation={handleUserLocation}
        handleHomeLocation={handleJobLocation}
      />
      {routeCoordinates && (
        <>
          <RoutePolylines
            showArrows={showMowingArrows}
            activeLayers={activeRouteLayers}
            routeCoordinates={routeCoordinates}
            // tempNogoCoordinates={tempNogo?.[0]?.subtask?.items}
          />
          {!hasSegments && showActivityByMower && (
            <>
              {mowerIds.map((mowerId) => (
                <React.Fragment key={mowerId}>
                  {job.status.name !== "Finished" && (
                    <MowerMarker
                      mowerId={mowerId}
                      isCurrentUserMower={Boolean(
                        currentUserMowers?.find((mower) => mower.id === mowerId)
                      )}
                      setActiveMarker={setActiveMowerMarker}
                      showMarker={showActivityByMower[mowerId]}
                      job={job}
                    />
                  )}
                  <MapMowerTracker
                    mowerId={mowerId}
                    journeyIds={findJourneyIds(job, mowerId)}
                    isDirectionShowing={showMowingArrows}
                    job={job}
                    isCurrentUserMower={Boolean(
                      currentUserMowers?.find((mower) => mower.id === mowerId)
                    )}
                    showMowerActivity={showActivityByMower[mowerId]}
                    showActivityTypes={showActivityTypes}
                  />
                </React.Fragment>
              ))}
            </>
          )}
        </>
      )}
      {hasSegments &&
        job.status.name !== "Finished" &&
        sortSegmentsByMower(mowersBySegment).map((mower) => {
          return (
            <MowerMarker
              key={mower.id}
              mowerId={mower.id}
              isCurrentUserMower={Boolean(
                currentUserMowers?.find((m) => m.id === mower.id)
              )}
              setActiveMarker={setActiveMowerMarker}
              segment={{
                name: mower.segmentName,
                id: mower.segmentId,
                color: mower.segmentColor,
              }}
              job={job}
              showMarker={
                activeSegmentsLayers
                  ? activeSegmentsLayers[mower.segmentId]?.activityChecked
                  : false
              }
            />
          );
        })}
      {hasSegments &&
        segmentsCoordinates &&
        mowersBySegment.map((mower, index) => {
          return (
            <React.Fragment key={index}>
              <MapMowerTracker
                key={mower.segmentId}
                mowerId={mower.id}
                isDirectionShowing={showMowingArrows}
                journeyIds={mower.journeys}
                job={job}
                segment={{
                  name: mower.segmentName,
                  id: mower.segmentId,
                  color: mower.segmentColor,
                }}
                isCurrentUserMower={Boolean(
                  currentUserMowers?.find((item) => item.id === mower.id)
                )}
                showMowerActivity={
                  activeSegmentsLayers
                    ? activeSegmentsLayers[mower.segmentId]?.activityChecked
                    : false
                }
                showActivityTypes={showActivityTypes}
              />
              <SegmentPolylines
                key={`${mower.segmentId}-${jobId}`}
                mower={mower}
                showArrows={showMowingArrows}
                showLastSegments={showLastSegments.length > 0}
                activeLayers={activeSegmentsLayers}
                segmentsCoordinates={segmentsCoordinates}
                segment={{
                  name: mower.segmentName,
                  id: mower.segmentId,
                  color: mower.segmentColor,
                }}
              />
            </React.Fragment>
          );
        })}
      {activeMowerMarker && (
        <JobMapInfoWindow
          onClose={() => setActiveMowerMarker(undefined)}
          onClick={() => {
            const mowerId = activeMowerMarker.deviceId;
            const mowerMan = getUserByMower(mowerId, job);
            if (!mowerMan?.name || !mowerMan.id) return;
            setMowerMan({ name: mowerMan.name, id: mowerMan.id });
            setDetailMower({
              name: activeMowerMarker.name,
              id: activeMowerMarker.deviceId,
              ...(activeMowerMarker.segment && {
                segment: {
                  id: activeMowerMarker.segment?.id,
                  name: activeMowerMarker.segment?.name,
                },
              }),
            });
            setCurrentPage("mowerStatus");
            updateSearchParams((prev) => {
              return { ...prev, tab: "0" };
            });
          }}
          mowerId={activeMowerMarker.deviceId}
          icon="mower"
          title={
            activeMowerMarker.segment
              ? `${activeMowerMarker.name}/${activeMowerMarker.segment.name}`
              : activeMowerMarker.name
          }
          position={{ lat: activeMowerMarker.lat, lng: activeMowerMarker.lng }}
        />
      )}
    </GoogleMap>
  );
};
