import React, { FC, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { JobT } from "../../../../types/Job";
import { ShowActivityTypesT } from "../../../../types/Route";
import { getStrokeColor } from "../../utils";
import MissedCoordinatesPolylines from "../../../../components/molecules/MissedcoordinatesPolylines/MissedCoordinatesPolylines";
import JourneyPolylines from "../../../../components/molecules/JourneyPolylines/JourneyPolylines";
import CurrentMovePolylines from "../../../../components/molecules/CurrentMovePolylines/CurrentMovePolylines";
import NextCoordinatesPolylines from "./NextCoordinatesPolylines";
import { JOB_ROUTE_TYPE_COLORS } from "../../../../constants";

type PropsT = {
  isDirectionShowing: boolean;
  mowerId: number;
  isCurrentUserMower: boolean;
  journeyIds: number[] | [];
  segment?: {
    id: number;
    name: string;
    color: {
      primaryColor: string;
      secondaryColor: string;
    };
  };
  showMowerActivity: boolean;
  job: JobT;
  showActivityTypes: ShowActivityTypesT;
};

const MapMowerTracker: FC<PropsT> = ({
  isDirectionShowing,
  mowerId,
  isCurrentUserMower,
  segment,
  journeyIds,
  showMowerActivity,
  job,
  showActivityTypes,
}) => {
  const [searchParams] = useSearchParams();
  const userId = searchParams.get("userId");

  const currentMower = job.mowers.find((m) => m.id === mowerId);

  const strokeColor = useMemo(() => {
    const colorFromBE = segment
      ? segment.color.secondaryColor
      : currentMower?.color.primaryColor;
    const color = getStrokeColor(colorFromBE, userId, isCurrentUserMower);

    return color;
  }, [segment, userId, isCurrentUserMower, currentMower]);

  const showMissedPolyline =
    journeyIds.length === 0 && Boolean(job?.startedAt) && job.id;

  const showJourney = journeyIds.length > 0;

  return (
    <>
      {showMissedPolyline && (
        <MissedCoordinatesPolylines
          jobId={job.id}
          mowerId={mowerId}
          segmentId={segment?.id}
          strokeColor={strokeColor}
          isDirectionShowing={isDirectionShowing}
          showActivity={showMowerActivity}
        />
      )}

      {showMissedPolyline && (
        <CurrentMovePolylines
          mowerId={mowerId}
          isDirectionShowing={isDirectionShowing}
          strokeColor={strokeColor}
          showActivity={showMowerActivity}
        />
      )}
      {showJourney && (
        <JourneyPolylines
          journeyIds={journeyIds}
          isDirectionShowing={isDirectionShowing}
          strokeColor={strokeColor}
          showActivity={showMowerActivity}
          showActivityTypes={showActivityTypes}
        />
      )}
      <NextCoordinatesPolylines
        mowerId={mowerId}
        segmentId={segment?.id}
        strokeColor={
          segment?.color.primaryColor ??
          currentMower?.color.primaryColor ??
          JOB_ROUTE_TYPE_COLORS.activeDevice
        }
        isVisible={showMowerActivity}
        isDirectionShowing={isDirectionShowing}
      />
    </>
  );
};

export default MapMowerTracker;
