import { FC, useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { RouteT } from "../../types/Route";
import { useMutationRouteChange } from "../../hooks/useMutationRouteChange";
import { RouteInputsT } from "../../components/molecules/FormRoute/FormChangeRoute";
import { FormChangeSpecificRoute } from "../../components/molecules/FormRoute/FormChangeSpecificRoute";
import { StyledButton } from "../../components/atoms/Button/Button";

type PropsT = {
  route: RouteT;
  isOpen: boolean;
  onClose: VoidFunction;
  refetch: VoidFunction;
};

export const RouteChangeModal: FC<PropsT> = ({
  route,
  isOpen,
  onClose,
  refetch,
}) => {
  const [isFormTouched, setIsFormTouched] = useState(false);
  const routeEditMutation = useMutationRouteChange({
    onSuccess: () => {
      refetch();
      onClose();
    },
  });

  const onSubmit = (values: RouteInputsT) => {
    routeEditMutation.mutate(values);
  };

  return (
    <Modal isOpen={isOpen} toggle={onClose}>
      <ModalHeader toggle={onClose}>Change route</ModalHeader>
      <ModalBody>
        <FormChangeSpecificRoute
          route={route}
          onTouch={() => setIsFormTouched(true)}
          onSubmit={onSubmit}
        />
      </ModalBody>
      <ModalFooter>
        <StyledButton color="light" onClick={onClose}>
          Cancel
        </StyledButton>
        <StyledButton
          disabled={!isFormTouched}
          isLoading={routeEditMutation.isLoading}
          color="primary"
          type="submit"
          form="route-form"
          className="me-2"
        >
          {routeEditMutation.isLoading ? "Saving" : "Change"}
        </StyledButton>
      </ModalFooter>
    </Modal>
  );
};
