import React, { FC, useMemo, useState } from "react";
import { useNavigate } from "react-router";
import { useInfiniteQuery } from "react-query";
import { toast } from "react-toastify";
import { client } from "../../services/axios";
import { queryClient } from "../..";
import { ROUTES } from "../../routes";
import { ApiErrorT } from "../../types/Common";
import { WINDOW_TITLE_NAMES } from "../../constants";
import { renameJobType } from "../../functions/renameJobType";
import { useSearchParams } from "../../hooks/useSearchParam";
import { usePermissions } from "../../hooks/usePermissions";
import { useQueryJobTypesGet } from "../../hooks/useQueries";
import { useMutationDeleteJob } from "../../hooks/useMutationDeleteJob";
import { reduceStringArray } from "../../functions/createJob";
import { FilterCountT, JobListItemT } from "../../types/Job";
import { Layout } from "../../components/organisms/Layout/Layout";
import MenuWithLayout, {
  MenuWithLayoutItemT,
} from "../../components/organisms/MenuWithLayout/MenuWithLayout";
import { DataGrid } from "../../components/molecules/DataGrid/DataGrid";
import { useConfirm } from "../../components/molecules/ConfirmBoxProvider/ConfirmBoxProvider";
import { LoadMoreButton } from "../../components/atoms/LoadMoreButton/LoadMoreButton";
import SubHeader from "../../components/atoms/SubHeader/SubHeader";
import { MergeJobsModal } from "./MergeJobsModal";
import HeaderActions from "./HeaderActions";
import { EditJobModal } from "../../components/molecules/EditJobModal/EditJobModal";
import { createQueryString } from "../../functions/routing";

type PropsT = {};

const PAGE_LIMIT = 20;

export const JobsPage: FC<PropsT> = () => {
  document.title = WINDOW_TITLE_NAMES.jobs;
  const [checkedJobs, setCheckedJobs] = useState<number[]>([]);
  const { data: jobTypes } = useQueryJobTypesGet();
  const [currentPage, setCurrentPage] = useState(0);
  const [isMerging, setIsMerging] = useState(false);
  const [isMergeModalOpen, setIsMergeModalOpen] = useState(false);
  const [editModalData, setEditModalData] = useState<
    undefined | { name: string; jobId: number }
  >();
  const navigate = useNavigate();
  const permissions = usePermissions();
  const { updateSearchParams, getParamValue } = useSearchParams();
  const search = getParamValue("search");
  const owner = getParamValue("owner");
  const types = getParamValue("types");
  const status = getParamValue("status");
  const auto = getParamValue("auto");
  const confirm = useConfirm();

  const onSearch = (searchString: string) => {
    updateSearchParams((prev) => ({
      ...prev,
      search: searchString,
      auto: null,
    }));
  };

  const handleCheck = (id: number) => {
    let updatedStateVal = checkedJobs;
    if (checkedJobs.includes(id)) {
      updatedStateVal = checkedJobs.filter((prevId) => prevId !== id);
    } else {
      updatedStateVal = [...checkedJobs, id];
    }
    setCheckedJobs(updatedStateVal);
  };

  const getUrl = (pageParam: number) => {
    if (auto) {
      return (
        "/api/v1/job/list/autogenerated" +
        createQueryString({
          limit: PAGE_LIMIT,
          offset: pageParam * PAGE_LIMIT,
          search,
        })
      );
    } else {
      return (
        "/api/v1/job/list" +
        createQueryString({
          limit: PAGE_LIMIT,
          offset: pageParam * PAGE_LIMIT,
          jobOwner: owner,
          status,
          type: types,
          search,
        })
      );
    }
  };

  const {
    isLoading,
    isFetchingNextPage,
    data: jobs,
    fetchNextPage,
  } = useInfiniteQuery<{
    data: {
      items: JobListItemT[];
      filterCount: FilterCountT;
      totalCount: number;
    };
  }>(
    ["jobs", { type: search, PAGE_LIMIT, owner, status, types, auto }],
    async ({ pageParam = 0 }) => {
      const url = getUrl(pageParam);
      const res = await client.get(url);
      return res;
    }
  );

  const jobRows = useMemo(
    () =>
      jobs?.pages
        .flat(0)
        .reduce(
          (acc: JobListItemT[], page) => [...acc, ...page.data.items],
          []
        ) ?? [],
    [jobs]
  );

  const setCurrentPageAndFetchNextPage = () => {
    setCurrentPage(currentPage + 1);
    fetchNextPage({ pageParam: currentPage + 1 });
  };

  const handleStatusFilter = (value: string) => {
    updateSearchParams((prev) => {
      if (status && status === value) {
        return { ...prev, status: null, auto: null };
      }
      return { ...prev, status: value, auto: null };
    });
    queryClient.invalidateQueries(["jobs"]);
  };

  const handleOwnerFilter = (value: string) => {
    updateSearchParams((prev) => {
      if (owner && owner === value) {
        return { ...prev, owner: null, auto: null };
      }
      return { ...prev, owner: value, auto: null };
    });
    queryClient.invalidateQueries(["jobs"]);
  };

  const handlerAutoGenerated = () => {
    updateSearchParams((prev) => ({
      ...prev,
      search: null,
      owner: null,
      status: null,
      types: null,
      auto: "autogenerated",
    }));
    queryClient.invalidateQueries(["jobs"]);
  };

  const handleTypeFilter = (value: string) => {
    const typesArray = types?.split(",") || [];

    if (typesArray.includes(value)) {
      const updatedTypes = reduceStringArray(
        typesArray.filter((type) => type !== value)
      );
      updateSearchParams((prev) => ({ ...prev, types: updatedTypes }));
    } else {
      const updatedTypes = reduceStringArray([...typesArray, value]);
      updateSearchParams((prev) => ({
        ...prev,
        types: updatedTypes,
        auto: null,
      }));
    }
  };

  const resetFilter = () => {
    updateSearchParams((prev) => ({
      ...prev,
      search: null,
      owner: null,
      status: null,
      types: null,
      auto: null,
    }));
    queryClient.invalidateQueries(["jobs"]);
  };

  const totalCount = jobs?.pages[0].data.totalCount || 0;
  const filters = useMemo(
    () => (jobs?.pages || []).map((page) => page.data.filterCount),
    [jobs]
  );
  const jobTypesMenuItems =
    jobTypes?.data.items.map((jobType, index) => ({
      isRadio: true,
      id: jobType.filterValue,
      title: renameJobType(jobType.name),
      onClick: () => handleTypeFilter(jobType.filterValue),
      hasSeparator: jobTypes.data.items.length === index + 1,
    })) || [];

  const getMenuItems = () => {
    const menuItems: MenuWithLayoutItemT[] = [
      {
        id: "all",
        title: "All jobs",
        onClick: () => resetFilter(),
        hasSeparator: !permissions.jobManage,
      },
    ];

    if (permissions.jobManage) {
      menuItems.push(
        {
          id: "my",
          title: "My jobs",
          onClick: () => handleOwnerFilter("my"),
          itemCount: filters?.[0]?.owner.my || 0,
        },
        {
          id: "delegated",
          title: "Delegated jobs",
          onClick: () => handleOwnerFilter("delegated"),
          itemCount: filters?.[0]?.owner.delegated || 0,
        }
      );
    }
    menuItems.push({
      id: "autogenerated",
      title: "Autogenerated jobs",
      onClick: () => handlerAutoGenerated(),
      hasSeparator: true,
    });

    return [
      ...menuItems,
      ...jobTypesMenuItems,
      {
        id: "overdue",
        title: "Overdue",
        onClick: () => handleStatusFilter("overdue"),
        itemCount: filters?.[0]?.status[2] || 0,
      },
      {
        id: "open",
        title: "Open",
        onClick: () => handleStatusFilter("open"),
        itemCount: filters?.[0]?.status[1] || 0,
      },
      {
        id: "finished",
        title: "Finished",
        onClick: () => handleStatusFilter("finished"),
        itemCount: filters?.[0]?.status[3] || 0,
        hasSeparator: true,
      },
    ];
  };

  const archiveItem: MenuWithLayoutItemT = {
    id: "archived",
    title: "Archive",
    onClick: () => handleStatusFilter("archived"),
  };

  const deleteJobMutation = useMutationDeleteJob({
    onSuccess: () => {
      queryClient.invalidateQueries(["jobs"]);
    },
  });

  const handleDelete = (job: JobListItemT) => {
    confirm({
      title: "Remove job",
      subTitle: `Are you sure you want to delete selected job, ${job.name}?`,
      asyncCallback: () => deleteJobMutation.mutateAsync(job.id),
      type: "delete",
    });
  };

  const handleMergeClick = async () => {
    if (!isMerging) {
      setIsMerging(true);
    } else {
      try {
        await queryClient.fetchQuery("merge-dialog-options", () =>
          client.get(
            `/api/v1/job/merge-dialog-options?ids=${reduceStringArray(
              checkedJobs.map((jobId) => `${jobId}`)
            )}`
          )
        );
        setIsMergeModalOpen(true);
      } catch (e) {
        const { message } = e as ApiErrorT;
        toast.error(message);
      }
    }
  };

  const handleUpdate = (job: JobListItemT) => {
    const editJobLink = `${ROUTES.jobEdit(job.id)}/?type=${job.type.id}`;
    if (job.startTime) {
      setEditModalData({
        jobId: job.id,
        name: job.name,
      });
    } else {
      navigate(editJobLink);
    }
  };

  const handleCancelClick = () => {
    setIsMerging(false);
    setCheckedJobs([]);
  };

  const getActiveItems = () => {
    let activeItems = ["all"];
    if (owner) {
      activeItems = [owner];
    }
    if (status) {
      activeItems = [...activeItems, status];
    }
    if (auto) {
      activeItems = [auto];
    }
    return activeItems;
  };
  const getDropdownTitle = () => {
    let title = "All jobs";
    if (owner === "my") {
      title = "My jobs";
    } else if (owner === "delegated") {
      title = "Delegated jobs";
    } else if (auto) {
      title = "Autogenerated";
    }
    return title;
  };

  return (
    <Layout whiteBackground>
      <SubHeader
        title="Jobs"
        titleDropdown={getDropdownTitle()}
        search={{
          placeholder: "Search for job title",
          onChange: onSearch,
        }}
        activeItem={getActiveItems()}
        checkedItems={types?.split(",")}
        actions={
          <HeaderActions
            isMerging={isMerging}
            canManageJobs={Boolean(permissions.jobManage)}
            mergingDisabled={checkedJobs.length < 2}
            onCancel={handleCancelClick}
            onMergeClick={handleMergeClick}
          />
        }
        items={getMenuItems()}
      />

      <MenuWithLayout
        items={getMenuItems()}
        activeItem={getActiveItems()}
        fixedItem={archiveItem}
        checkedItems={types?.split(",")}
      >
        <DataGrid<JobListItemT>
          headers={[
            "Title",
            "Status",
            "Due date",
            "Start date",
            "End date",
            "Job type",
            "Assigned to",
          ]}
          rowComponent={"JobGridRow"}
          data={jobRows}
          isLoading={isLoading}
          onDelete={handleDelete}
          checkedJobs={checkedJobs}
          isMerging={isMerging}
          onCheck={handleCheck}
          onUpdate={handleUpdate}
        />
        {totalCount > jobRows.length && (
          <LoadMoreButton
            isLoading={isFetchingNextPage}
            onClick={() => setCurrentPageAndFetchNextPage()}
          />
        )}
      </MenuWithLayout>
      {isMergeModalOpen && (
        <MergeJobsModal
          jobs={jobRows.filter((job) => checkedJobs.includes(job.id))}
          isOpen={isMergeModalOpen}
          onClose={() => setIsMergeModalOpen(false)}
        />
      )}
      {editModalData && (
        <EditJobModal
          jobId={editModalData.jobId}
          initialName={editModalData.name}
          isOpen={Boolean(editModalData)}
          onClose={() => setEditModalData(undefined)}
          refetch={() => queryClient.refetchQueries(["jobs"])}
        />
      )}
    </Layout>
  );
};
