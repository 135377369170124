import { useMutation } from "react-query";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { JobCreateFormFieldsT } from "../components/molecules/FormJobCreate/FormJobCreate";
import { getCreateJobApiData } from "../functions/createJob";
import { ROUTES } from "../routes";
import { client } from "../services/axios";
import { ApiErrorT } from "../types/Common";
import { getJobLinkFromJobType } from "../functions/routing";
import { usePermissions } from "./usePermissions";
import { JobTypesIdsT } from "../types/Job";

export interface JobCreateInputsT extends Omit<JobCreateFormFieldsT, "assign"> {
  assign: {
    userId: string;
    mowerId: string[];
  }[];
}

export const useMutationCreateJob = (isQuickStart: boolean) => {
  const navigate = useNavigate();
  const permissions = usePermissions();

  return useMutation(
    (data: JobCreateInputsT) => {
      return client.post(`/api/v1/job`, getCreateJobApiData(data));
    },
    {
      onSuccess: (data, inputs) => {
        toast.success("Job has been created");
        if (!isQuickStart) {
          navigate(ROUTES.jobs());
          return;
        } else {
          navigate(
            getJobLinkFromJobType(
              parseInt(inputs.type) as JobTypesIdsT,
              data.data.id,
              inputs.assign.map((a) => a.userId),
              permissions
            )
          );
        }
      },
      onError: (error: ApiErrorT) => {
        toast.error(error?.message);
      },
    }
  );
};
