import { FC, useCallback, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Form } from "reactstrap";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { RouteT } from "../../../types/Route";
import { InputController } from "../Form/InputController";
import { updateRouteSchema } from "./validations";

export type RouteInputsT = {
  route: string;
  file: FileList;
};

type PropsT = {
  route: RouteT;
  onTouch: () => void;
  onSubmit: (v: RouteInputsT) => void;
  isDisabledAll?: boolean;
};

export const FormChangeSpecificRoute: FC<PropsT> = ({
  route,
  onTouch,
  onSubmit,
  isDisabledAll,
}) => {
  const getDefaultValues = useCallback(
    () => ({
      route: String(route.id),
    }),
    [route]
  );

  const {
    handleSubmit,
    control,
    formState: { errors, isDirty },
  } = useForm<RouteInputsT>({
    resolver: yupResolver(updateRouteSchema()),
    defaultValues: getDefaultValues(),
  });

  useEffect(() => {
    if (isDirty) {
      onTouch();
    }
  }, [onTouch, isDirty]);

  return (
    <Form onSubmit={handleSubmit(onSubmit)} id="route-form">
      <InputController
        name="file"
        label="New File (kml)"
        required
        control={control}
        errors={errors}
        input={{ type: "file", disabled: isDisabledAll }}
      />
    </Form>
  );
};
