import { FC, useEffect, useRef, useState } from "react";
import { DebounceInput } from "react-debounce-input";
import classNames from "classnames";
import { OptionT } from "../../../types/Statistics";
import { Icon } from "../Icon";
import { SelectedTag } from "./SelectedTag";
import styles from "./multiselect.module.scss";

type PropsT = {
  data: OptionT[];
  selected: OptionT[];
  onChange: (selected: OptionT[]) => void;
  initialTextValue?: string;
  className?: string;
};

/**
 *
 * @param data The data from which to select
 * @param selected The selected options
 * @param onChange The callback to call when the selection changes
 * @param initialTextValue The text to display when no option is selected
 * @param className The class name to apply to the container
 */
export const Multiselect: FC<PropsT> = ({
  data,
  selected,
  initialTextValue,
  onChange,
  className,
}: PropsT) => {
  const [query, setQuery] = useState<string>("");
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const selectRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const eventHandler = (event: MouseEvent) => {
      if (
        isOpen &&
        selectRef.current &&
        event.target instanceof HTMLElement &&
        !selectRef.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    };
    document.addEventListener("click", eventHandler);
    return () => {
      document.removeEventListener("click", eventHandler);
    };
  });

  const selectedIds = new Set(selected.map((option) => option.id));
  const availableOptions = data.filter(
    (option) =>
      !selectedIds.has(option.id) &&
      (!option.name || option.name.toLowerCase().includes(query))
  );

  const addSelected = (option: OptionT) => {
    onChange([...selected, option]);
  };

  const removeSelected = (option: OptionT) => {
    onChange(selected.filter((o) => o.id !== option.id));
  };

  const removeAll = () => {
    setQuery("");
    onChange([]);
  };

  const interactedWith = selected.length > 0 || query.length > 0;

  return (
    <div
      className={classNames(styles.container, className)}
      onClick={() => setIsOpen((prev) => !prev)}
      ref={selectRef}
    >
      <div className={styles.searchContainer}>
        {selected.map((option) => (
          <SelectedTag
            className={option.name === null ? styles.optionNullName : undefined}
            key={option.id}
            name={option.name || "No name"}
            onRemove={(e) => {
              removeSelected(option);
              e.stopPropagation();
            }}
          />
        ))}
        <DebounceInput
          className={styles.input}
          placeholder={interactedWith ? "" : initialTextValue}
          value={query}
          minLength={2}
          debounceTimeout={300}
          onChange={(event) => setQuery(event.target.value)}
        />
        {interactedWith ? (
          <div
            className={classNames(styles.chevron, styles.remove)}
            onClick={removeAll}
          >
            <Icon icon={"times"} />
          </div>
        ) : (
          <div className={styles.chevron}>
            <Icon icon="angle-down" />
          </div>
        )}
      </div>
      {isOpen && (
        <div className={styles.dropdown}>
          <div className={styles.optionList}>
            {availableOptions.map((option) => (
              <div
                className={classNames(styles.option, {
                  [styles.optionNullName]: option.name === null,
                })}
                key={option.id}
                onClick={(e) => {
                  addSelected(option);
                  e.stopPropagation();
                }}
              >
                {option.name || "No name"}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
