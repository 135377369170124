import React from "react";
import { Link } from "react-router-dom";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import classNames from "classnames";
import { ROUTES } from "../../routes";
import { useSelector } from "../../store/hooks";
import { logoutUser } from "../../functions/OAuth";
import { useCompactDesign } from "../../hooks/useCompactDesign";
import { Icon } from "./Icon";
import styles from "./userMenu.module.scss";

type Props = {};

export const UserMenu: React.FC<Props> = () => {
  const [open, setOpen] = React.useState<boolean>(false);
  const { user } = useSelector((state) => state.user);
  const isCompact = useCompactDesign();

  const toggle = () => {
    setOpen(!open);
  };

  return (
    <UncontrolledDropdown
      isOpen={open}
      toggle={toggle}
      inNavbar
      nav
      className={classNames(styles.userMenuDropdown, {
        [styles["userMenuDropdown--large"]]: !isCompact,
      })}
    >
      <DropdownToggle
        nav
        className={classNames(styles.userMenuDropdownToggle, {
          [styles["userMenuDropdownToggle--large"]]: !isCompact,
        })}
      >
        <Icon icon="user-circle" />
        <Icon
          icon="angle-down"
          className={classNames(styles.userMenuDropdownCaretIcon)}
        />
      </DropdownToggle>
      <DropdownMenu
        end
        flip={false}
        className={classNames(styles.userMenuDropdownMenu)}
      >
        <DropdownItem tag={Link} to={ROUTES.user(user?.id || 0)}>
          User detail
        </DropdownItem>
        <DropdownItem onClick={logoutUser}>Log out</DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};
