import { PayloadAction } from "@reduxjs/toolkit";
import { all, put, race, take, takeEvery } from "redux-saga/effects";
import {
  SubscribeToGeostickActionT,
  setPoints,
  unsubscribeFromGeostickAction,
} from "../slices/geostickSlice";
import { webSocket } from "./tasksSaga";
import { GeostickPointsT } from "../../types/Geostick";
import {
  subscribeMessage,
  unsubscribeMessage,
} from "../../functions/websockets";

function* subscribeToGeostickJob({ payload }: SubscribeToGeostickActionT) {
  const { jobId } = payload;

  webSocket.send(subscribeMessage(`job.geostick.${jobId}`));

  while (true) {
    const {
      message,
      unsubscribe,
    }: {
      message: PayloadAction<GeostickPointsT>;
      unsubscribe: PayloadAction<{ jobId: number }>;
    } = yield race({
      message: take(`geostickDataReceived_${jobId}`),
      unsubscribe: take(unsubscribeFromGeostickAction.type),
    });

    if (message) {
      const points = message.payload;
      yield put(setPoints(points));
    }
    if (unsubscribe && unsubscribe.payload.jobId === jobId) {
      webSocket.send(unsubscribeMessage(`job.geostick.${jobId}`));
      break;
    }
  }
}

export function* rootGeostickSaga() {
  yield all([takeEvery("SUBSCRIBE_TO_GEOSTICK", subscribeToGeostickJob)]);
}
