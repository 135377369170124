export const formatKilometres = (meters: number) => {
  return Math.round(meters / 100) / 10;
};

export const formatToSeconds = (
  minutes: string | number,
  returnType: "number" | "string"
): string | number => {
  const seconds =
    typeof minutes === "string"
      ? Math.round(parseInt(minutes) * 60)
      : Math.round((minutes as number) * 60);

  return returnType === "string" ? seconds.toString() : seconds;
};

export const formatToMinutes = (
  seconds: string | number,
  returnType: "number" | "string"
): string | number => {
  const minutes =
    typeof seconds === "string"
      ? Math.round(parseInt(seconds) / 60)
      : Math.round((seconds as number) / 60);

  return returnType === "string" ? minutes.toString() : minutes;
};

export const roundCoordinates = (coordinate: number) => +coordinate.toFixed(5);

export const roundDistance = (distance: number) => +distance.toFixed(2);
