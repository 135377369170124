import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { getEditJobApiData } from "../functions/createJob";
import { client } from "../services/axios";
import { ApiErrorT } from "../types/Common";
import { MutationOptionsT } from "../types/ReactQuery";
import { JobCreateInputsT } from "./useMutationCreateJob";

export const useMutationEditJob = (
  jobId: string,
  options?: MutationOptionsT
) => {
  return useMutation(
    (data: Partial<JobCreateInputsT>) => {
      return client.put(`/api/v1/job/${jobId}`, getEditJobApiData(data));
    },
    {
      onSuccess: () => {
        toast.success("Job has been updated");
        if (options?.onSuccess) {
          options.onSuccess();
        }
      },
      onError: (error: ApiErrorT) => {
        toast.error(error?.message);
      },
    }
  );
};
