import React, { FC, useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { JobListItemT, JobMergeOptionT } from "../../types/Job";
import styles from "./Jobs.module.scss";
import { Icon } from "../../components/atoms/Icon";
import { AxiosResponse } from "axios";
import { queryClient } from "../..";
import MergeJobsForm, { MergeJobsFormFieldsT } from "./MergeJobsForm";
import { useMutationMergeJobs } from "../../hooks/useMutationMergeJobs";
import { StyledButton } from "../../components/atoms/Button/Button";

type PropsT = {
  jobs: JobListItemT[];
  isOpen: boolean;
  onClose: () => void;
};

export const MergeJobsModal: FC<PropsT> = ({ jobs, isOpen, onClose }) => {
  const [isFormTouched, setIsFormTouched] = useState(false);
  const mergeOptionsResult = queryClient.getQueryData<
    AxiosResponse<JobMergeOptionT[]>
  >("merge-dialog-options");
  const mergeJobsMutation = useMutationMergeJobs({
    onSuccess: () => {
      queryClient.refetchQueries(["jobs"]);
      onClose();
    },
  });

  const handleSubmit = (formFields: MergeJobsFormFieldsT) => {
    const ids =
      mergeOptionsResult?.data.map((option) => option.jobs).flat(1) || [];
    mergeJobsMutation.mutate({ ...formFields, ids });
  };

  return (
    <Modal isOpen={isOpen} toggle={onClose}>
      <ModalHeader toggle={onClose}>Merge jobs</ModalHeader>
      <ModalBody>
        <ul className={styles["job-list"]}>
          {jobs.map(({ name, id }) => (
            <li key={id} className={styles["job-item"]}>
              <Icon className={styles["job-item__icon"]} icon="chevron-right" />
              <span>{name}</span>
            </li>
          ))}
        </ul>
        <MergeJobsForm
          mergeOptions={mergeOptionsResult?.data || []}
          onTouch={() => setIsFormTouched(true)}
          onSubmit={handleSubmit}
        />
      </ModalBody>
      <ModalFooter>
        <StyledButton color="light" onClick={onClose}>
          Cancel
        </StyledButton>
        <StyledButton
          isLoading={mergeJobsMutation.isLoading}
          disabled={!isFormTouched}
          color="primary"
          type="submit"
          form="merge-jobs-form"
          className="me-2"
        >
          Merge
        </StyledButton>
      </ModalFooter>
    </Modal>
  );
};
