import { FirebaseMessaging } from "@capacitor-firebase/messaging";
import { useEffect, useState } from "react";

export const usePermissionNotification = () => {
  const [permitted, setPermitted] = useState(false);
  const [loading, setLoading] = useState(true);

  async function checkPermission() {
    const state = await FirebaseMessaging.checkPermissions();
    if (state.receive === "granted") {
      setPermitted(true);
    }
    setLoading(false);
  }

  const reload = () => {
    setLoading(true);
    setPermitted(false);
    checkPermission();
  };

  useEffect(() => {
    checkPermission();
  });

  return { permitted, loading, reload };
};
