import { Polyline } from "@react-google-maps/api";
import React from "react";
import { getMowerPolylineOptions } from "../../../functions/getMowerPolylineOptions";
import { useSelector } from "../../../store/hooks";
import { Loader } from "../../atoms/Loader/Loader";
import { useMowerTrajectory } from "../../../hooks/useMowerTrajectory";
import { sortCoordinatesByMode } from "../MissedcoordinatesPolylines/utils";

type Props = {
  mowerId: number;
  strokeColor: string;
  isDirectionShowing: boolean;
  showActivity: boolean;
  isVisible?: boolean;
};

export const CurrentMovePolylines = React.memo(
  ({
    mowerId,
    strokeColor,
    isDirectionShowing,
    showActivity,
    isVisible = true,
  }: Props) => {
    const mowerTrajectory = useMowerTrajectory(mowerId);
    const isLoading = useSelector((store) => store.mower.isLoading);
    const trackerData = sortCoordinatesByMode(mowerTrajectory ?? []);

    if (trackerData.length === 0 || trackerData[0].length === 0) {
      return null;
    }

    if (isLoading) {
      return <Loader isCentered />;
    }

    return (
      <>
        {trackerData.map((coordinates, index) => (
          <Polyline
            path={coordinates}
            key={index}
            visible={showActivity && isVisible}
            options={getMowerPolylineOptions(
              strokeColor,
              isDirectionShowing,
              coordinates[0].knivesOn ?? true
            )}
          />
        ))}
      </>
    );
  }
);

export default CurrentMovePolylines;
