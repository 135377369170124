import JobOverview from "./Overview/JobOverview";
import SegmentsForm from "./Segments/SegmentsForm";
import SegmentsOverview from "./Segments/SegmentsOverview";
import MowerManStatus from "./MowerManStatus/MowerManStatus";
import MowerStatus from "./MowerStatus/MowerStatus";
import JobHistory from "./JobHistory/JobHistory";

export const jobState = {
  jobOverview: JobOverview,
  segmentsForm: SegmentsForm,
  segmentsOverview: SegmentsOverview,
  mowerManStatus: MowerManStatus,
  mowerStatus: MowerStatus,
  jobHistory: JobHistory,
} as const;
