import React from "react";
import { Card, CardBody } from "reactstrap";
import { ApiErrorT } from "../../../types/Common";
import { CardHeader } from "../../atoms/CardHeader/CardHeader";
import { Ufon } from "../../atoms/Ufon/Ufon";
import { DeviceMessage } from "../../atoms/DeviceMessage/DeviceMessage";
import { ENVIRONMENT } from "../../../env";
import styles from "./styles.module.scss";

type Props = {
  error?: ApiErrorT;
};

const DefaultUfonCard = ({ error }: Props) => {
  return (
    <Card className={styles["ufon-disabled"]}>
      <CardBody>
        <CardHeader icon="broadcast" title="Default correction station" />
        {ENVIRONMENT != "production" && (
          <div className="d-flex justify-content-center mb-4">
            <Ufon />
          </div>
        )}
        {error && error.message && (
          <DeviceMessage text={error.message} status="error" />
        )}
      </CardBody>
    </Card>
  );
};

export default DefaultUfonCard;
