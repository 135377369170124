import { FC } from "react";
import { InfoWindow } from "@react-google-maps/api";
import { MeasurementT } from "../../../types/Geostick";
import { StyledButton } from "../../../components/atoms/Button/Button";
import { displayMeasurement } from "../functions";
import { NoFixLabel } from "../Status/NoFixLabel";
import styles from "./measurementInfoWindow.module.scss";
import { Trigger } from "../Status/Trigger";

type PropsT = {
  measurement: MeasurementT;
  onClose: VoidFunction;
  onEdit?: VoidFunction;
  onDelete?: VoidFunction;
};

export const MeasurementInfoWindow: FC<PropsT> = ({
  measurement,
  onClose,
  onEdit,
  onDelete,
}: PropsT) => {
  return (
    <InfoWindow
      onCloseClick={onClose}
      position={{
        lat: measurement.latitude,
        lng: measurement.longitude,
      }}
      options={{ pixelOffset: new window.google.maps.Size(0, 40) }}
    >
      <div className={styles.info}>
        <div>
          <div className={styles.header}>
            <h4 className={styles.title}>{displayMeasurement(measurement)}</h4>
            {measurement.trigger && (
              <Trigger
                className={styles.trigger}
                trigger={measurement.trigger}
              />
            )}
          </div>
          <div className={styles.metadata}>
            <div className={styles.location}>
              <p>{`${measurement.latitude.toFixed(
                5
              )} ${measurement.longitude.toFixed(5)}`}</p>
            </div>
            {!measurement.fix && <NoFixLabel />}
          </div>
        </div>
        <div className={styles.buttons}>
          {onEdit && (
            <StyledButton onClick={onEdit} title="Edit" color="link" />
          )}
          {onDelete && (
            <StyledButton onClick={onDelete} title="Delete" color="link" />
          )}
        </div>
      </div>
    </InfoWindow>
  );
};
