import React, { FC, Fragment, useState } from "react";
import { Marker, Polygon, Polyline } from "@react-google-maps/api";
import {
  CoordinatesT,
  JobMapPointInfo,
  RouteLayers,
  StylesT,
} from "../../../../types/Route";
import { getPolygonOptions, getPolylineOptions } from "../../../Job/functions";
import { getEndPointIcon, getStartPointIcon } from "../../utils";
import { JOB_ROUTE_TYPE_COLORS } from "../../../../constants";
import JobMapInfoWindow from "../../../../components/molecules/MapInfoWindow/JobMapInfoWindow";
// import { NogoZoneT } from "../../types/Job";

type PropsT = {
  activeLayers: RouteLayers;
  routeCoordinates: CoordinatesT<"route">;
  showArrows: boolean;
  // tempNogoCoordinates: NogoZoneT[] | undefined;
};

const RoutePolylines: FC<PropsT> = ({
  routeCoordinates,
  activeLayers,
  showArrows,
  // tempNogoCoordinates,
}) => {
  // const routeWithNogo = routeCoordinates.coordinates.filter(
  //   (coor) => coor.styleType === "noGoZone"
  // );
  const [pointActiveMarker, setPoinActiveMarker] = useState<
    JobMapPointInfo | undefined
  >(undefined);
  const stylesFromBE = routeCoordinates?.styles;
  return (
    <>
      {stylesFromBE &&
        routeCoordinates.coordinates.map((coordinate, index) => {
          const isVisibleRoute =
            activeLayers.routeChecked && activeLayers.route.includes(index);
          const isVisibleEndPoint =
            activeLayers.endPointChecked && activeLayers.endPoint === index;
          const isVisibleStartPoint =
            activeLayers.startPointChecked && activeLayers.startPoint === index;
          const { coordinates: latLng, id, styleType } = coordinate;
          const isPolygon = Boolean(stylesFromBE[styleType].PolyStyle);
          const options = getPolylineOptions(
            styleType,
            stylesFromBE as StylesT<"route">,
            showArrows && !isPolygon
          );
          const polygonOptions = getPolygonOptions(
            latLng,
            styleType,
            stylesFromBE
          );
          if (!coordinate.isPoint) {
            return (
              <Fragment key={id}>
                <Polyline
                  visible={isVisibleRoute}
                  key={`polyline-item-${id}`}
                  path={latLng}
                  options={options}
                />
                {isPolygon && (
                  <Polygon
                    visible={isVisibleRoute}
                    key={`polygon-item-${id}`}
                    path={latLng}
                    options={polygonOptions}
                  />
                )}
              </Fragment>
            );
          } else {
            const lat = latLng[0].lat || 0;
            const lng = latLng[0].lng || 0;
            return (
              <React.Fragment key={`marker-${index}`}>
                <Marker
                  position={{ lat, lng }}
                  zIndex={styleType === "startPoint" ? 300 : 250}
                  visible={
                    styleType === "endPoint"
                      ? isVisibleEndPoint
                      : isVisibleStartPoint
                  }
                  icon={{
                    url:
                      styleType === "endPoint"
                        ? getEndPointIcon(JOB_ROUTE_TYPE_COLORS.plannedRoute)
                        : getStartPointIcon(JOB_ROUTE_TYPE_COLORS.plannedRoute),
                    scaledSize:
                      styleType === "endPoint"
                        ? new google.maps.Size(18, 18)
                        : new google.maps.Size(30, 30),
                    anchor:
                      styleType === "endPoint"
                        ? new google.maps.Point(9, 9)
                        : undefined,
                  }}
                  onClick={() => {
                    setPoinActiveMarker({
                      styleType,
                      name:
                        styleType === "endPoint"
                          ? "ROUTE - END POINT"
                          : "ROUTE - START POINT",
                      lat,
                      lng,
                    });
                  }}
                />
              </React.Fragment>
            );
          }
        })}
      {pointActiveMarker && (
        <JobMapInfoWindow
          onClose={() => setPoinActiveMarker(undefined)}
          title={pointActiveMarker.name}
          pixelOffset={
            pointActiveMarker.styleType === "endPoint"
              ? new google.maps.Size(0, 40)
              : new google.maps.Size(0, 20)
          }
          position={{
            lat: pointActiveMarker.lat,
            lng: pointActiveMarker.lng,
          }}
          circleColor={
            pointActiveMarker.segment?.color ||
            JOB_ROUTE_TYPE_COLORS.plannedRoute
          }
        />
      )}
      {/* {!routeWithNogo.length &&
        tempNogoCoordinates?.map((coordinate) => {
          const { coordinates: latLng, id } = coordinate;

          // @ts-expect-error: until be chaneg lon => lng, when it will be uncomment one line above and delete one line under
          const fixLatLng = latLng?.map(({ lat, lon }) => ({
            lat: lat,
            lng: lon,
          }));

          return (
            <Fragment key={id}>
              <Polyline
                visible={true}
                key={`polyline-item-${id}`}
                path={fixLatLng}
                options={{
                  strokeColor: `#FF0000FF`,
                  strokeOpacity: 0.8,
                  strokeWeight: 2,
                  zIndex: 1000,
                  icons: showArrows
                    ? [
                        {
                          icon: {
                            path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                            scale: 1.5,
                          },
                          repeat: "30px",
                        },
                      ]
                    : [],
                }}
              />
              <Polygon
                visible={true}
                key={`polygon-item-${id}`}
                path={fixLatLng}
                options={{
                  strokeColor: `#FF0000FF`,
                  strokeOpacity: 0.8,
                  strokeWeight: 2,
                  paths: latLng,
                  zIndex: 1,
                  fillColor: "#600000FF",
                  fillOpacity: 0.5,
                }}
              />
            </Fragment>
          );
        })} */}
    </>
  );
};

export default RoutePolylines;
