import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import React, { FC, useEffect, useMemo } from "react";
import { toast } from "react-toastify";
import { useFieldArray, useForm } from "react-hook-form";
import { Card, CardBody, Col, Form, Row } from "reactstrap";
import { JOB_TYPE_IDS } from "../../../constants";
import { renameJobType } from "../../../functions/renameJobType";
import { usePermissions } from "../../../hooks/usePermissions";
import {
  useQueryJobTypesGet,
  useQueryMowerListGet,
  useQueryRouteGroupListGet,
  useQueryUfonListGet,
  useQueryUsersGet,
} from "../../../hooks/useQueries";
import { chiselJobCreateSchema, formjobCreateSchema } from "./validations";
import { SelectOptionsT } from "../../../types/Common";
import { StyledButton } from "../../atoms/Button/Button";

import { Cards } from "../../atoms/Cards/Cards";
import { Loader } from "../../atoms/Loader/Loader";
import { InputController } from "../Form/InputController";
import { FormMowers } from "./FormMowers";
import { RouteSelect } from "./RouteSelect";
import styles from "./formJobCreate.module.scss";
import { FormJobTypeCard } from "./FormJobTypeCard";
import { FormBaseMetadataCard } from "./FormBaseMetadataCard";
import { FormLocationCard } from "./FormLocationCard";
import { FormDeviceInput } from "./FormDeviceInput";

const NEW_USER_VALUES = {
  userId: "",
  mowers: [
    {
      mowerId: "",
      routeId: "",
    },
  ],
};

export type JobCreateFormFieldsT = {
  type: string;
  name: string;
  dueDate: string;
  routeId?: string;
  routeFolderId?: string;
  userId: string;
  assign: {
    userId: string;
    mowers: {
      mowerId: string;
    }[];
  }[];
  location: string;
  latitude?: number;
  longitude?: number;
  mowerId: string;
  ufonId: string;
  roverUfonId: string;
  note: string;
};

export type JobCreateFormNamesT = keyof JobCreateFormFieldsT;

type PropsT = {
  defaultValues: JobCreateFormFieldsT;
  isDisabledAll?: boolean;
  isEditing?: boolean;
  editedJobHasSegments?: boolean;
  onTouch: () => void;
  onSubmit: (v: JobCreateFormFieldsT) => void;
};

export const FormJobCreate: FC<PropsT> = ({
  defaultValues,
  isDisabledAll,
  isEditing,
  editedJobHasSegments,
  onTouch,
  onSubmit,
}) => {
  const permissions = usePermissions();
  const { data: jobTypes, isLoading: isLoadingTypes } = useQueryJobTypesGet();
  const { data: usersData, isLoading: isLoadingUsers } = useQueryUsersGet();
  const { data: mowerData, isLoading: isLoadingMowers } =
    useQueryMowerListGet();
  const { data: ufonData, isLoading: isLoadingUfons } = useQueryUfonListGet();
  const { data: groupData, isLoading: isLoadingGroup } =
    useQueryRouteGroupListGet();
  const isLoading =
    isLoadingTypes ||
    isLoadingUsers ||
    isLoadingMowers ||
    isLoadingUfons ||
    isLoadingGroup;

  const {
    handleSubmit,
    control,
    watch,
    setValue,
    getValues,
    reset,
    trigger,
    formState,
  } = useForm<JobCreateFormFieldsT>({
    resolver: yupResolver(
      permissions.jobManage ? formjobCreateSchema : chiselJobCreateSchema
    ),
    defaultValues,
  });
  const { errors, isDirty } = formState;

  const {
    fields: usersFields,
    append: usersAppend,
    remove: usersRemove,
  } = useFieldArray({ name: "assign", control });
  const selectedType = watch("type");
  const isManual = selectedType === `${JOB_TYPE_IDS.manual}`;
  const isPerimeter = selectedType === `${JOB_TYPE_IDS.perimeter}`;
  const isRoute = selectedType === `${JOB_TYPE_IDS.route}`;
  const isGeostick = selectedType === `${JOB_TYPE_IDS.survey}`;

  useEffect(() => {
    if (!ufonData) return;
    if (defaultValues.ufonId) return;
    else {
      if (ufonData.data.items.length === 1) {
        setValue("ufonId", ufonData.data.items[0].id.toString());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ufonData, defaultValues]);

  useEffect(() => {
    if (!mowerData) return;
    if (defaultValues.mowerId) return;
    else {
      if (mowerData.data.items.length === 1) {
        if (isRoute) {
          setValue("assign", [
            {
              userId: defaultValues.assign[0].userId || "",
              mowers: [
                {
                  mowerId: mowerData.data.items[0].id.toString() || "",
                },
              ],
            },
          ]);
          return;
        }
        setValue("mowerId", mowerData.data.items[0].id.toString());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mowerData, defaultValues, isRoute]);

  const radiosJobTypeOptions = useMemo(
    () =>
      jobTypes?.data.items
        .map((jobType) => ({
          label: renameJobType(jobType.name),
          value: jobType.id,
        }))
        .filter((item) => item.value !== JOB_TYPE_IDS.auto),
    [jobTypes]
  );

  const selectUserOptions = useMemo(
    () =>
      (
        [{ value: "", label: "Choose user", disabled: true }] as SelectOptionsT
      ).concat(
        (usersData?.data.items || []).map((user) => ({
          label: `${user.firstName} ${user.surname}`,
          value: user.id,
        }))
      ),
    [usersData]
  );

  const selectMowerOptions = useMemo(
    () =>
      (
        [{ value: "", label: "Choose mower", disabled: true }] as SelectOptionsT
      ).concat(
        (mowerData?.data.items || []).map((mower) => ({
          label: mower.name,
          value: mower.id,
        }))
      ),
    [mowerData]
  );

  const selectUfonOptions: SelectOptionsT = useMemo(() => {
    const ufons = (ufonData?.data.items || []).map((ufon) => ({
      label: ufon.name,
      value: ufon.id,
    }));
    if (isGeostick) {
      return ufons;
    }
    return (
      [
        { value: "", label: "Choose correction station", disabled: true },
      ] as SelectOptionsT
    ).concat(ufons);
  }, [ufonData, isGeostick]);

  // const selectRouteOptions = useMemo(
  //   () =>
  //     (
  //       [{ value: "", label: "Choose route", disabled: true }] as SelectOptionsT
  //     ).concat(
  //       (routesData?.data.items || []).map((route) => ({
  //         label: route.name,
  //         value: route.id,
  //       }))
  //     ),
  //   [routesData]
  // );

  const selectGroupOptions = useMemo(
    () =>
      (
        [{ value: "", label: "Choose group", disabled: true }] as SelectOptionsT
      ).concat(
        (groupData?.data.items || []).map((group) => ({
          label: group.name,
          value: group.id,
        }))
      ),
    [groupData]
  );

  useEffect(() => {
    if (isDirty) {
      onTouch();
    }
  }, [onTouch, isDirty]);

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      toast.error("Form is invalid");
    }
  }, [errors]);

  // Clear form parts on change
  useEffect(() => {
    if (isRoute) {
      setValue("userId", defaultValues.userId);
      setValue("location", defaultValues.location);
      setValue("mowerId", defaultValues.mowerId);
    }

    if (!isRoute) {
      setValue("assign", defaultValues.assign);
    }
  }, [isManual, isPerimeter, isRoute, setValue, defaultValues]);

  const handleAddUser = () => {
    usersAppend(NEW_USER_VALUES);
  };

  const handleRemoveUser = (index: number) => {
    usersRemove(index);
    trigger("assign");
  };

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset, getValues, setValue]);

  if (isLoading) {
    return <Loader isCentered />;
  }

  const commonFields = (
    <>
      {!isEditing && (
        <FormJobTypeCard
          control={control}
          formState={formState}
          radiosJobTypeOptions={radiosJobTypeOptions}
          isLoadingTypes={isLoadingTypes}
        />
      )}
      <FormBaseMetadataCard
        control={control}
        formState={formState}
        selectUserOptions={selectUserOptions}
        isDisabledAll={isDisabledAll}
        isLoadingUsers={isLoadingUsers}
        chooseUser={permissions.jobManage}
      />
    </>
  );

  let body = undefined;
  if (isManual) {
    body = (
      <>
        {commonFields}
        {permissions.jobManage && (
          <FormLocationCard
            control={control}
            formState={formState}
            isDisabledAll={isDisabledAll}
          />
        )}

        <Card>
          <CardBody>
            <FormDeviceInput
              name="mowerId"
              label="Mower"
              control={control}
              formState={formState}
              selectOptions={selectMowerOptions}
              isDisabledAll={isDisabledAll}
              isLoading={isLoadingMowers}
            />
          </CardBody>
        </Card>
      </>
    );
  } else if (isPerimeter) {
    body = (
      <>
        {commonFields}
        {permissions.jobManage && (
          <FormLocationCard
            control={control}
            formState={formState}
            isDisabledAll={isDisabledAll}
          />
        )}

        <Card>
          <CardBody>
            <FormDeviceInput
              name="mowerId"
              label="Mower"
              control={control}
              formState={formState}
              selectOptions={selectMowerOptions}
              isDisabledAll={isDisabledAll}
              isLoading={isLoadingMowers}
            />
            <FormDeviceInput
              name="ufonId"
              label="Correction station"
              control={control}
              formState={formState}
              selectOptions={selectUfonOptions}
              isDisabledAll={isDisabledAll}
              isLoading={isLoadingUfons}
            />
          </CardBody>
        </Card>
      </>
    );
  } else if (isGeostick) {
    body = (
      <>
        {commonFields}
        <Card>
          <CardBody>
            <FormDeviceInput
              name="ufonId"
              label="Correction station"
              control={control}
              formState={formState}
              selectOptions={selectUfonOptions}
              isDisabledAll={isDisabledAll}
              isLoading={isLoadingUfons}
            />
            <FormDeviceInput
              name="roverUfonId"
              label="Rover station"
              control={control}
              formState={formState}
              selectOptions={selectUfonOptions}
              isDisabledAll={isDisabledAll}
              isLoading={isLoadingUfons}
            />
          </CardBody>
        </Card>
      </>
    );
  } else if (isRoute) {
    body = (
      <>
        {!isEditing && (
          <FormJobTypeCard
            control={control}
            formState={formState}
            radiosJobTypeOptions={radiosJobTypeOptions}
            isLoadingTypes={isLoadingTypes}
          />
        )}
        <FormBaseMetadataCard
          control={control}
          formState={formState}
          selectUserOptions={selectUserOptions}
          isDisabledAll={isDisabledAll}
          isLoadingUsers={isLoadingUsers}
        />
        {!editedJobHasSegments && (
          <Card>
            <CardBody>
              <Row>
                <Col lg="6">
                  <RouteSelect
                    index={1}
                    control={control}
                    errors={errors}
                    groupOptions={selectGroupOptions}
                    isLoadingGroup={isLoadingGroup}
                    folderId={defaultValues.routeFolderId}
                  ></RouteSelect>
                </Col>
              </Row>
            </CardBody>
          </Card>
        )}
        {!editedJobHasSegments && (
          <>
            {usersFields.map((userField, index) => (
              <Card key={userField.id}>
                <CardBody className={styles["form-card"]}>
                  <div className="pb-5">
                    {permissions.jobManage && (
                      <Row className={styles["user-fields"]}>
                        <Col lg="4" xs="9">
                          <InputController
                            key={`assign-user-${index}`}
                            name={`assign.${index}.userId`}
                            label={`User #${index + 1} ${
                              index === 0 ? "(Job owner)" : ""
                            }`}
                            control={control}
                            errors={errors}
                            options={selectUserOptions}
                            input={{
                              disabled: isDisabledAll || isLoadingUsers,
                            }}
                          />
                        </Col>
                        <Col lg="2" xs="3">
                          {usersFields.length > 1 && (
                            <StyledButton
                              icon="minus-circle"
                              className={styles["remove-btn"]}
                              onlyIconOnMobile
                              onClick={() => handleRemoveUser(index)}
                              input={{ disabled: isDisabledAll }}
                            >
                              Remove user
                            </StyledButton>
                          )}
                        </Col>
                      </Row>
                    )}
                    <FormMowers
                      nestIndex={index}
                      control={control}
                      errors={errors}
                      trigger={() => trigger("assign")}
                      mowerOptions={selectMowerOptions}
                      isLoadingMowers={isLoadingMowers}
                      isLoadingGroup={isLoadingGroup}
                    />
                  </div>
                </CardBody>
              </Card>
            ))}
            <Card>
              <CardBody>
                {permissions.jobManage && (
                  <StyledButton
                    title="Add user"
                    onClick={handleAddUser}
                    color="dark"
                    outline
                    className={styles["add-btn"]}
                  />
                )}
              </CardBody>
            </Card>
          </>
        )}
        <Card>
          <CardBody>
            <FormDeviceInput
              name="ufonId"
              label="Correction station"
              control={control}
              formState={formState}
              selectOptions={selectUfonOptions}
              isDisabledAll={isDisabledAll}
              isLoading={isLoadingUfons}
            />
          </CardBody>
        </Card>
      </>
    );
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)} id="create-job-form">
      <Cards>
        {body}
        <Card>
          <CardBody>
            <InputController
              id="notes"
              name="note"
              label="Notes"
              control={control}
              errors={errors}
              input={{ type: "textarea", disabled: isDisabledAll }}
            />
          </CardBody>
        </Card>
      </Cards>
    </Form>
  );
};
