import { string, array, object } from "yup";
import { JOB_TYPE_IDS, VALIDATIONS } from "../../../constants";

function findLastDuplicateUserIndex(arr: (string | undefined)[]) {
  const duplicateIds = new Map();
  let lastIndex = -1;

  for (let i = 0; i < arr.length; i++) {
    const userId = arr[i];
    if (!userId) break;
    duplicateIds.set(userId, i);
    if (duplicateIds.has(userId)) {
      lastIndex = i;
    }
  }
  return lastIndex;
}

function findLastDuplicateMowerIdIndex(
  assign: {
    userId: string | undefined;
    mowers:
      | {
          mowerId: string | undefined;
        }[]
      | undefined;
  }[]
) {
  const mowerIds = new Map();
  let lastDuplicateAssignIndex = -1;
  let lastDuplicateMowerIndex = -1;

  for (let i = 0; i < assign.length; i++) {
    const mowers = assign[i].mowers;
    if (!mowers) break;
    for (let j = 0; j < mowers.length; j++) {
      const mowerId = mowers[j].mowerId;
      mowerIds.set(mowerId, i);
      if (mowerIds.has(mowerId)) {
        lastDuplicateAssignIndex = i;
        lastDuplicateMowerIndex = j;
      }
    }
  }

  return {
    userIndex: lastDuplicateAssignIndex,
    mowerIndex: lastDuplicateMowerIndex,
  };
}

export const formjobCreateSchema = object({
  type: string(),
  name: string().required(VALIDATIONS.required),
  dueDate: string().required(VALIDATIONS.required),
  userId: string().when("type", {
    is: (type: string) => type !== `${JOB_TYPE_IDS.route}`,
    then: string().required(VALIDATIONS.required),
  }),
  routeId: string().when("type", {
    is: (type: string) => type === `${JOB_TYPE_IDS.route}`,
    then: string().required(VALIDATIONS.required),
  }),
  assign: array().when("type", {
    is: `${JOB_TYPE_IDS.route}`,
    then: array()
      .of(
        object().shape({
          userId: string().required(VALIDATIONS.required),
          mowers: array().of(
            object().shape({
              mowerId: string(),
            })
          ),
        })
      )
      .test("unique-user-id", "Duplicate user id", (value, ctx) => {
        if (!value) return true;
        const userIds = value?.map((item) => item.userId);
        if (userIds.length !== new Set(userIds).size) {
          const userIndex = findLastDuplicateUserIndex(userIds);
          if (userIndex < 0) return true;
          return ctx.createError({
            path: `assign[${userIndex}].userId`,
            message: "Duplicate user id",
          });
        }
        return true;
      })
      .test("unique-mower-id", "Duplicate mower id", (value, ctx) => {
        if (!value) return true;
        const mowerids = value?.reduce((acc, curr) => {
          curr.mowers?.forEach((item) => {
            if (item.mowerId) acc.push(item.mowerId);
          });
          return acc;
        }, [] as string[]);
        if (mowerids.length !== new Set(mowerids).size) {
          const { userIndex, mowerIndex } =
            findLastDuplicateMowerIdIndex(value);
          if (userIndex < 0 || mowerIndex < 0) return true;
          return ctx.createError({
            path: `assign[${userIndex}].mowers[${mowerIndex}].mowerId`,
            message: "Duplicate mower id",
          });
        }
        return true;
      })
      .test("min-1-mower", "Select at least one mower", (value, ctx) => {
        if (!value) return true;
        const userIdWithNoMowers = value.find((user) => {
          const filteredMowers = user.mowers?.filter((mower) => mower.mowerId);
          if (filteredMowers?.length === 0) return user;
        })?.userId;
        if (userIdWithNoMowers) {
          const userIndex = value.findIndex(
            (item) => item.userId === userIdWithNoMowers
          );
          return ctx.createError({
            path: `assign[${userIndex}].mowers[0].mowerId`,
            message: "Select at least one mower",
          });
        }
        return true;
      }),
  }),
  location: string()
    .when("type", {
      is: (type: string) => type !== `${JOB_TYPE_IDS.route}`,
      then: string(),
    })
    .nullable(),
  mowerId: string().when("type", {
    is: (type: string) =>
      type !== `${JOB_TYPE_IDS.route}` && type !== `${JOB_TYPE_IDS.survey}`,
    then: string().required(VALIDATIONS.required),
  }),
  ufonId: string()
    .nullable()
    .when("type", {
      is: (type: string) => type === `${JOB_TYPE_IDS.survey}`,
      then: string().required(VALIDATIONS.required),
    }),
  roverUfonId: string()
    .nullable()
    .when("type", {
      is: (type: string) => type === `${JOB_TYPE_IDS.survey}`,
      then: string()
        .required(VALIDATIONS.required)
        .test(
          "ufon-not-equal",
          "UFON and Rover UFON should be different",
          (value, ctx) => {
            const ufonId = ctx.parent.ufonId;
            if (ufonId === value) {
              return ctx.createError({
                path: "roverUfonId",
                message: "Ufon and Rover should be different",
              });
            }
            return true;
          }
        ),
    }),
  note: string(),
}).required();

export const chiselJobCreateSchema = object({
  type: string(),
  name: string().required(VALIDATIONS.required),
  dueDate: string().required(VALIDATIONS.required),
  routeId: string().required(VALIDATIONS.required),
  assign: array().when("type", {
    is: `${JOB_TYPE_IDS.route}`,
    then: array().of(
      object().shape({
        mowers: array().of(
          object().shape({
            mowerId: string().required(VALIDATIONS.required),
            // routeId: string().required(VALIDATIONS.required),
          })
        ),
      })
    ),
  }),
  mowerId: string().when("type", {
    is: (type: string) =>
      type !== `${JOB_TYPE_IDS.route}` && type !== `${JOB_TYPE_IDS.survey}`,
    then: string().required(VALIDATIONS.required),
  }),
  ufonId: string().nullable(),
  roverUfonId: string().nullable(),
  note: string(),
}).required();
