import { Card } from "reactstrap";
import { useNavigate } from "react-router";
import classNames from "classnames";
import { DeviceActivityInfo } from "../../../types/Common";
import { Icon } from "../../atoms/Icon";
import styles from "./deviceInfo.module.scss";

type Props = {
  deviceData: DeviceActivityInfo;
};

export default function DeviceActivityRow({
  deviceData: { label, data },
}: Props) {
  const navigate = useNavigate();
  const clickHandler = () => {
    if (!data?.linkTo) return;
    navigate(data.linkTo);
  };
  return (
    <div className="mb-2">
      <p className="mb-2">{label}</p>
      <Card
        className={classNames(styles.dataWrapper, {
          [styles["dataWrapper-clickable"]]: data?.linkTo,
        })}
        onClick={clickHandler}
      >
        {data ? (
          <div className={styles.titleWrapper}>
            <span className={styles.title}>{data.value}</span>
            {data.additionalValue && (
              <div className={styles.infoWrapper}>
                <span className={styles.phone}>{data.additionalValue}</span>
                <span className={styles.email}>{data.email}</span>
              </div>
            )}
          </div>
        ) : (
          <span className={styles.dash}>&mdash;</span>
        )}
        {data && <Icon icon="chevron-right" className={styles.chevron} />}
      </Card>
    </div>
  );
}
