import React, { FC, useEffect, useState } from "react";
import { Control, FieldErrors } from "react-hook-form";
import { client } from "../../../services/axios";
import { SelectOptionsT } from "../../../types/Common";

import { InputController } from "../Form/InputController";
import { JobCreateFormFieldsT } from "./FormJobCreate";
import { FormGroup, Input, Label } from "reactstrap";
import classNames from "classnames";
import styles from "../Form/inputController.module.scss";
import { createQueryString } from "../../../functions/routing";

interface Props {
  index: number;
  control: Control<JobCreateFormFieldsT, object>;
  errors: FieldErrors<JobCreateFormFieldsT>;
  groupOptions: SelectOptionsT;
  isLoadingGroup: boolean;
  folderId?: string;
}

type RoutesT = {
  id: number;
  name: string;
  fileName: string | null;
};

type RoutesOptionsT = {
  label: string;
  value?: string | number | undefined;
  disabled?: boolean | undefined;
};

const getDefaultGroupValue = (
  folderId: number | string | undefined,
  groupOptions: SelectOptionsT
) => {
  if (!folderId) {
    return groupOptions[0];
  }
  if (folderId && Number(folderId)) {
    const foundGroup = groupOptions.find(
      (option) => option.value === Number(folderId)
    );
    return foundGroup || groupOptions[0];
  }
};

export const RouteSelect: FC<Props> = ({
  index,
  control,
  errors,
  groupOptions,
  folderId,
}) => {
  const defaultGroup = getDefaultGroupValue(folderId, groupOptions);
  const initialRoute = [{ value: "", label: "Choose route", disabled: true }];
  const [filteredRoutes, setFilteredRoutes] =
    useState<RoutesOptionsT[]>(initialRoute);
  const generateRouteOptions = (availableRoutes: RoutesT[]) =>
    (initialRoute as SelectOptionsT).concat(
      (availableRoutes || []).map((route) => ({
        label: route.name,
        value: route.id,
      }))
    );

  const handleGroup = async (selectedFolderId: number | string | undefined) => {
    if (selectedFolderId) {
      const availableRoutes: RoutesT[] = await client
        .get(
          "/api/v1/route/list" +
            createQueryString({ folderId: selectedFolderId })
        )
        .then((response) => response.data.items);

      if (availableRoutes) {
        setFilteredRoutes(generateRouteOptions(availableRoutes));
      }
    } else {
      setFilteredRoutes(initialRoute);
    }
  };

  useEffect(() => {
    handleGroup(folderId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [folderId]);

  return (
    <>
      <FormGroup className={classNames(styles["wrapper-hack"])}>
        <Label className="d-block" for={`groupId-${index}`}>
          Folder
        </Label>
        <Input
          id={`groupId-${index}`}
          label="Folder"
          placeholder={"test"}
          type={"select"}
          onChange={(e) => handleGroup(e.target.value)}
          style={{ maxWidth: "12.5rem" }}
          defaultValue={defaultGroup?.value}
        >
          {groupOptions.map((option) => {
            return (
              <option value={option.value} key={option.value}>
                {option.label}
              </option>
            );
          })}
        </Input>
      </FormGroup>

      <InputController
        key={`assign-route-${index}`}
        name="routeId"
        label="Route"
        control={control}
        errors={errors}
        options={filteredRoutes}
        input={{ disabled: filteredRoutes.length < 2 }}
      />
    </>
  );
};
