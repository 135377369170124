import { toast } from "react-toastify";
import { PayloadAction } from "@reduxjs/toolkit";
import { EventChannel, eventChannel, Task } from "redux-saga";
import {
  all,
  call,
  cancel,
  delay,
  fork,
  put,
  race,
  select,
  spawn,
  take,
  takeLatest,
} from "redux-saga/effects";
import { client } from "../../services/axios";
import { store as appStore } from "../../store";
import { queryClient } from "../..";
import { ApiErrorT } from "../../types/Common";
import { JobUserTaskT, RouteComputeStatus, TaskT } from "../../types/Job";
import {
  AutomaticProcessData,
  JobAssignRouteData,
  MowerReplyData,
  RouteComputeCompleteData,
  StatusDeviceData,
  WSEventMessage,
} from "../../types/Websocket";
import { UfonStatusT, UfonT } from "../../types/Ufon";
import { MowerStatusT, MowerT } from "../../types/Mower";
import { SOCKET_URL } from "../../env";
import {
  ChangeUserActionType,
  FetchTaskActionType,
  fetchTasksAction,
  setDeviceStatus,
  setIsLoadingAction,
  setIsLoadingUserAction,
  setJobIdAction,
  setLastMessageAction,
  setRouteComputeStatusAction,
  setTasks,
  SubscribeToTaskActionType,
  unsubscribeFromTaskAction,
  updateTaskAction,
  UpdateTaskActionType,
} from "../slices/tasksSlice";
import {
  setMowerStatusAction,
  startMowerTrackingAction,
  setMowerRouteUploadedAction,
  setMowerIsOnlineAction,
  cleanMowerTrajectoryAction,
  setMowerDataAction,
} from "../slices/mowerSlice";
import { setUfonData, setUfonStatus } from "../slices/ufonSlice";
import { setWSChannelStateAction } from "../slices/webSocketSlice";
import {
  ChangeCompanyIdActionType,
  changeCompanyId,
} from "../slices/userSlice";
import { setShowRadioModeWarning } from "../slices/jobRouteTypeSlice";
import {
  subscribeMessage,
  unsubscribeMessage,
} from "../../functions/websockets";
import { getCompany } from "../../functions/localStorage";
import { shouldCleanTrajectory } from "../../pages/Job/functions";
import {
  fetchStatusDevice,
  fetchUserTasks,
  getEventType1,
  getEventType2,
  getTimeDiffFromDate,
  waitForMessageArr,
} from "./functions";

export const webSocket = new WebSocket(SOCKET_URL);
// Heartbeat interval - send ping through ws every 30s so that the browser wont close it
webSocket.addEventListener("open", () => {
  appStore.dispatch(setWSChannelStateAction(true));
  setInterval(() => {
    webSocket.send(`[5,"ping.test", []]`);
  }, 30000);
});
webSocket.addEventListener("close", () => {
  appStore.dispatch(setWSChannelStateAction(false));
});
const createWSChannel = () => {
  return eventChannel<Event>((emit) => {
    const handler = (event: Event) => {
      emit(event);
    };
    webSocket.addEventListener("open", handler);
    webSocket.addEventListener("message", handler);
    webSocket.addEventListener("ping.fe", handler);
    return () => {
      webSocket.removeEventListener("open", handler);
      webSocket.removeEventListener("message", handler);
      webSocket.removeEventListener("ping.fe", handler);
    };
  });
};

function* handleWSChannelSaga() {
  const wsChannel: EventChannel<MessageEvent> = yield call(createWSChannel);
  while (true) {
    try {
      const message: MessageEvent = yield take(wsChannel);
      if (message.type === "open") {
        const selectedCompanyId = getCompany();
        if (selectedCompanyId) {
          yield put(changeCompanyId({ id: selectedCompanyId }));
        } else {
          yield take("COMPANY_ID_CHANGED");
        }
      }

      // Parse IPUB wamp WS message to standard format
      if (message.type === "message") {
        const parsedMsg1: [number, string, string, string?] = JSON.parse(
          message.data
        );

        if (
          (parsedMsg1.length !== 3 || typeof parsedMsg1[2] !== "string") &&
          !parsedMsg1?.[3]?.includes("IPub/WebSockets")
        ) {
          /* eslint-disable no-console */
          console.log(JSON.parse(message.data));
          /* eslint-enable no-console */
          throw new Error("Invalid ws message!");
        }

        const parsedMsg2 = JSON.parse(
          JSON.parse(parsedMsg1[2])
        ) as WSEventMessage<unknown>;

        yield put(
          setLastMessageAction({ message: parsedMsg2, timestamp: new Date() })
        );

        // Handle assignment events:
        //  1. current job id from store
        //  2. if there is a same job in store, procceed
        //  3. trigger refetch to get updated task data
        if (
          parsedMsg2.eventType === "jobMowerAssignRouteChanged" ||
          parsedMsg2.eventType === "jobMowerAssignJourneyChanged"
        ) {
          const { jobId, routeId } = parsedMsg2.data as JobAssignRouteData;
          const currentJobId: string | null = yield select(
            (state) => state.tasks.jobId
          );
          const userTasks: JobUserTaskT | null = yield select(
            (state) => state.tasks.userTasks
          );

          if (parsedMsg2.eventType === "jobMowerAssignRouteChanged") {
            queryClient.refetchQueries(["coordinates", routeId]);
          }

          if (currentJobId === `${jobId}` && userTasks) {
            yield put(
              fetchTasksAction({
                jobId: `${jobId}`,
                userId: `${userTasks.user.id}`,
              })
            );
          }
        }

        if (parsedMsg2.eventType === "routeComputeStatus") {
          const status = parsedMsg2.data as RouteComputeStatus;
          yield put(setRouteComputeStatusAction(status));
        }

        if (parsedMsg2.eventType === "routeComputeComplete") {
          const { jobId } = parsedMsg2.data as RouteComputeCompleteData;
          queryClient.refetchQueries(["coordinates", jobId]);

          yield put(
            setRouteComputeStatusAction({
              isDone: true,
              isFailed: false,
              percentage: 100,
              status: "DONE",
              message: null,
              jobId,
            })
          );

          const currentId: string | null = yield select(
            (state) => state.tasks.jobId
          );
          const calculateTask: TaskT | undefined = yield select((state) =>
            state.tasks.userTasks?.tasks.find(
              (task: TaskT) => task.type === "calculateRoute"
            )
          );
          if (currentId === String(jobId) && calculateTask) {
            yield put(
              updateTaskAction({
                jobId: String(jobId),
                type: "calculateRoute",
                newStatus: "readyForDone",
                options: { mowerId: calculateTask.mowerId ?? undefined },
              })
            );
          }
        }

        if (parsedMsg2.eventType === "jobMowerAssignJourneyChanged") {
          queryClient.refetchQueries(["jobs"]);
        }

        if (parsedMsg2.eventType === "mowerReplyReceived") {
          const { type, success } = parsedMsg2.data as MowerReplyData;
          if (type === "exportTrack" && success) {
            yield put(
              setMowerRouteUploadedAction({
                mowerId: parsedMsg2.entityId,
                routeUploaded: true,
              })
            );
          }
        }

        if (parsedMsg2.eventType === "mowerReplyReceived") {
          queryClient.refetchQueries(["mower", parsedMsg2.entityId]);
        }

        if (parsedMsg2.eventType === "mowerDataReceived") {
          const mowerInfo = parsedMsg2.data as MowerT;
          yield put(
            setMowerDataAction({
              mowerId: parsedMsg2.entityId,
              mowerData: mowerInfo,
            })
          );
          yield put(
            setMowerIsOnlineAction({
              mowerId: parsedMsg2.entityId,
              isOnline: mowerInfo.isOnline,
            })
          );
        }

        if (parsedMsg2.eventType === "mowerStatusReceived") {
          const mowerStatus = parsedMsg2.data as MowerStatusT;
          yield put(
            setMowerStatusAction({ mowerStatus, mowerId: parsedMsg2.entityId })
          );
          if (mowerStatus.status.rtkMode) {
            yield put(
              setShowRadioModeWarning(mowerStatus.status.rtkMode === "RADIO")
            );
          }
        }

        if (parsedMsg2.eventType === "ufonDataReceived") {
          queryClient.refetchQueries(["ufon", parsedMsg2.entityId]);
          const ufonData = parsedMsg2.data as UfonT;
          yield put(setUfonData({ ufonData, ufonId: parsedMsg2.entityId }));

          const currentJobId: string | null = yield select(
            (state) => state.tasks.jobId
          );
          const userTasks: JobUserTaskT | null = yield select(
            (state) => state.tasks.userTasks
          );
          if (
            currentJobId &&
            userTasks &&
            userTasks.infoStatus.ufon &&
            userTasks.infoStatus.ufon.id === ufonData.id
          ) {
            yield put(
              fetchTasksAction({
                jobId: currentJobId,
                userId: String(userTasks.user.id),
              })
            );
          }
        }

        if (parsedMsg2.eventType === "ufonStatusReceived") {
          const ufonStatus = parsedMsg2.data as UfonStatusT;
          yield put(setUfonStatus({ ufonStatus, ufonId: parsedMsg2.entityId }));
        }

        if (parsedMsg2.eventType === "jobTaskUpdated") {
          const currentJobId: string | null = yield select(
            (state) => state.tasks.jobId
          );

          const userTasks: JobUserTaskT | null = yield select(
            (state) => state.tasks.userTasks
          );

          if (parsedMsg2.entityId === Number(currentJobId) && userTasks) {
            const jobId = String(parsedMsg2.entityId);
            const userTask: JobUserTaskT = yield call(
              fetchUserTasks,
              jobId,
              String(userTasks.user.id)
            );

            yield put(setTasks(userTask));
          }
        }
        // Create single message string type
        yield put({
          type: getEventType1(parsedMsg2),
          payload: parsedMsg2.data,
        });
      }
    } catch (e) {
      const { message } = e as ApiErrorT;
      toast.error(message);
    }
  }
}

function* subscribeToTaskSaga({ payload }: SubscribeToTaskActionType) {
  const { jobId } = payload;

  webSocket.send(subscribeMessage(`job.process.${jobId}`));

  while (true) {
    const {
      message,
      unsubscribe,
    }: {
      message: PayloadAction<AutomaticProcessData>;
      unsubscribe: PayloadAction<{ jobId: number | string }>;
    } = yield race({
      message: take(`taskAutomaticProcess_${jobId}`),
      unsubscribe: take(unsubscribeFromTaskAction.type),
    });

    if (message) {
      const { fetchTaskEndpoint, userId } = message.payload;
      if (fetchTaskEndpoint) {
        const userTask: JobUserTaskT = yield call(
          fetchUserTasks,
          String(jobId),
          String(userId)
        );
        yield put(setTasks(userTask));
      }
    }

    if (unsubscribe && unsubscribe.payload.jobId === jobId) {
      webSocket.send(unsubscribeMessage(`job.process.${jobId}`));
      break;
    }
  }
}

function* setStatusDeviceOfflineSaga(time: number) {
  yield delay(time); // ms
  yield put(setDeviceStatus(false));
}

// Get inital state from endpoint than update status throug WS messages
function* updateDeviceStatusSaga() {
  // Delay to make sure is queryClient is ready
  yield delay(0);

  try {
    const response: StatusDeviceData = yield call(fetchStatusDevice);
    if (response) {
      yield put(setDeviceStatus(response.isOnline));
    }

    const statusDeviceTask: Task = yield fork(
      setStatusDeviceOfflineSaga,
      getTimeDiffFromDate(response.validTo)
    );

    while (true) {
      const { payload }: PayloadAction<StatusDeviceData> = yield take(
        "statusDeviceDataReceived_null"
      );

      yield cancel(statusDeviceTask);
      const { isOnline, validTo } = payload;
      yield put(setDeviceStatus(isOnline));
      yield delay(getTimeDiffFromDate(validTo));
      yield put(setDeviceStatus(false));
    }
  } catch (e) {
    const { message } = e as ApiErrorT;
    toast.error(message);
  }
}

function* fetchTasksSaga({ payload }: FetchTaskActionType) {
  const { jobId, userId } = payload;
  yield put(setJobIdAction(jobId));
  try {
    const userTask: JobUserTaskT = yield call(fetchUserTasks, jobId, userId);
    yield put(setTasks(userTask));

    const mowerIds = userTask.infoStatus.allMowers;

    for (let i = 0; i < mowerIds.length; i++) {
      const mowerId = mowerIds[i];
      if (shouldCleanTrajectory(userTask)) {
        yield put(cleanMowerTrajectoryAction({ mowerId }));
      }
      yield put(startMowerTrackingAction({ mowerId }));
    }

    yield put(setIsLoadingAction(false));
    yield put(setIsLoadingUserAction(false));

    // const waitingMsg = waitForMessage(userTask);
    const waitingArr = waitForMessageArr(userTask);

    const waitingArrMsg = waitingArr?.map((task) =>
      getEventType2(task.waitFor)
    );

    if (!waitingArrMsg || !waitingArrMsg?.length || !waitingArrMsg[0].length) {
      return;
    }
    yield take(waitingArrMsg);
  } catch (e) {
    const { message } = e as ApiErrorT;
    toast.error(message);
  }
}

function* updateTaskSaga({ payload }: UpdateTaskActionType) {
  yield put(setIsLoadingAction(true));
  const { jobId, type, newStatus, options } = payload;

  try {
    const userTasks: JobUserTaskT | null = yield select(
      (store) => store.tasks.userTasks
    );
    if (!userTasks) {
      throw new Error("Cannot find user tasks!");
    }

    yield call(client.put, `/api/v1/job/${jobId}/task`, {
      type,
      status: newStatus,
      mowerId: options?.mowerId,
    });

    yield put(
      fetchTasksAction({ jobId: `${jobId}`, userId: `${userTasks.user.id}` })
    );
    if (type === "calculateRoute" && newStatus === "start") {
      yield call(client.put, `api/v1/job/${jobId}/compute-route`, {
        mowerId: options?.mowerId,
        diameter: options?.diameter,
        name: options?.name,
        folderId: options?.folderId,
      });
    }
  } catch (e) {
    const { message } = e as ApiErrorT;
    toast.error(message);
    yield put(setIsLoadingAction(false));
  }
}

function* changeUserSaga({ payload }: ChangeUserActionType) {
  yield put(setIsLoadingUserAction(true));
  yield put(fetchTasksAction({ jobId: payload.jobId, userId: payload.userId }));
}

function* unsubscribeWSChannelSaga({ payload }: ChangeCompanyIdActionType) {
  const selectedCompanyId = payload.id;
  if (selectedCompanyId !== 0) {
    webSocket.send(`[5,"user.status.${selectedCompanyId}"]`);
  } else {
    webSocket.send(`[5,"user.status.all"]`);
  }
}

export function* rootTasksSaga() {
  yield all([
    takeLatest("FETCH_TASKS", fetchTasksSaga),
    takeLatest("UPDATE_TASK", updateTaskSaga),
    takeLatest("CHANGE_USER", changeUserSaga),
    takeLatest("UPDATE_DEVICE_STATUS", updateDeviceStatusSaga),
    takeLatest("COMPANY_ID_CHANGED", unsubscribeWSChannelSaga),
    takeLatest("SUBSCRIBE_TO_TASK", subscribeToTaskSaga),
    spawn(handleWSChannelSaga),
  ]);
}
