import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { client } from "../services/axios";
import { ApiErrorT } from "../types/Common";

type NogoInputsT = {
  jobId: string;
  mowerId: number;
};

export const useMutationNogoCreate = () => {
  return useMutation(
    (data: NogoInputsT) => {
      const { jobId, mowerId } = data;

      return client.put(`/api/v1/job/${jobId}/subtask`, {
        type: "createNoGoZone",
        status: "start",
        mowerId: mowerId,
      });
    },
    {
      // onSuccess: () => {
      //   toast.success("Creating nogo zone has started");
      // },
      onError: (error: ApiErrorT) => {
        toast.error(error?.message);
      },
    }
  );
};
