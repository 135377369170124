import React, { FC, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { Marker } from "@react-google-maps/api";
import { useMowerTracker } from "../../../hooks/useMowerTracker";
import { JobMapInfo } from "../../../types/Route";
import {
  getMowerActiveIcon,
  getMowerInactiveIcon,
  getStrokeColor,
} from "../../../pages/JobRouteType/utils";
import { JOB_ROUTE_TYPE_COLORS } from "../../../constants";
import { JobT } from "../../../types/Job";
import { useSelector } from "../../../store/hooks";
import { Loader } from "../Loader/Loader";

type PropsT = {
  mowerId: number;
  showMarker?: boolean;
  setActiveMarker?: (arg0: JobMapInfo) => void;
  isCurrentUserMower?: boolean;
  segment?: {
    id: number;
    name: string;
    color: {
      primaryColor: string;
      secondaryColor: string;
    };
  };
  job?: JobT;
};

const MowerMarker: FC<PropsT> = ({
  mowerId,
  showMarker = true,
  setActiveMarker,
  isCurrentUserMower = true,
  segment,
  job,
}) => {
  const trackerData = useMowerTracker(mowerId);
  const isLoading = useSelector((state) => state.mower.isLoading);

  const [searchParams] = useSearchParams();

  const userId = searchParams.get("userId");

  const currentMower = job?.mowers.find((m) => m.id === mowerId);

  const iconColor = useMemo(() => {
    return getStrokeColor(
      segment?.color.primaryColor ?? currentMower?.color.primaryColor,
      userId,
      isCurrentUserMower
    );
  }, [segment, userId, isCurrentUserMower, currentMower]);

  const getMowerIcon = () => {
    if (trackerData?.isOnline) {
      return !userId || isCurrentUserMower
        ? getMowerActiveIcon(iconColor)
        : getMowerActiveIcon(
            JOB_ROUTE_TYPE_COLORS.inactiveDeviceFill,
            JOB_ROUTE_TYPE_COLORS.inactiveDeviceStroke
          );
    } else {
      return !userId || isCurrentUserMower
        ? getMowerInactiveIcon(iconColor)
        : getMowerInactiveIcon(JOB_ROUTE_TYPE_COLORS.inactiveDeviceFill);
    }
  };

  return (
    <>
      <Loader isLoading={isLoading} isCentered />
      {trackerData?.currentLocation && showMarker && (
        <Marker
          icon={{
            url: getMowerIcon(),
            scaledSize: new google.maps.Size(60, 60),
          }}
          position={{
            lat: trackerData.currentLocation.lat || 0,
            lng: trackerData.currentLocation.lng || 0,
          }}
          onClick={() =>
            setActiveMarker
              ? setActiveMarker({
                  deviceId: mowerId,
                  name: trackerData?.name,
                  lat: trackerData?.currentLocation?.lat || 0,
                  lng: trackerData?.currentLocation?.lng || 0,
                  ...(segment && {
                    segment: { name: segment.name, id: segment.id },
                  }),
                })
              : null
          }
        />
      )}
    </>
  );
};

export default MowerMarker;
