import React, { ReactNode } from "react";
import classNames from "classnames";
import { queryClient } from "../../..";
import { CompanyT } from "../../../types/Company";
import { useMutationDeleteCompany } from "../../../hooks/useMutationDeleteCompany";
import { useCompactDesign } from "../../../hooks/useCompactDesign";
import { ActionButton } from "../../atoms/ActionButton/ActionButton";
import { useConfirm } from "../ConfirmBoxProvider/ConfirmBoxProvider";
import styles from "./gridRow.module.scss";

type Props = {
  data: CompanyT;
  onUpdate: (item: CompanyT) => void;
  onCopy: (item: CompanyT) => void;
};

const GridCell: React.FC<{ children: ReactNode }> = ({ children }) => {
  const isCompact = useCompactDesign();

  return (
    <td
      className={classNames(
        styles["grid-row__cell"],
        isCompact && styles["grid-row__cell--compact"],
        isCompact && styles["grid-row__cell--6--compact"]
      )}
    >
      {children}
    </td>
  );
};

export const CompanyGridRow: React.FC<Props> = ({ data, onUpdate, onCopy }) => {
  const confirm = useConfirm();
  const isCompact = useCompactDesign();

  const deleteCompanyMutation = useMutationDeleteCompany({
    onSuccess: () => {
      queryClient.invalidateQueries("companies");
    },
  });

  const handleDelete = () => {
    confirm({
      title: "Remove company",
      subTitle: `Are you sure you want to delete company ${data.name}?`,
      asyncCallback: () => deleteCompanyMutation.mutateAsync(data.id),
      type: "delete",
    });
  };

  return (
    <tr className={isCompact ? styles["grid-row"] : styles["grid-row--large"]}>
      <GridCell>{data.id}</GridCell>
      <GridCell>{data.name}</GridCell>
      <GridCell>
        <div className={styles.actions}>
          <ActionButton
            onClick={() => {
              onCopy(data);
            }}
            icon="clone"
            tagTitle="Copy"
          />
          <ActionButton
            onClick={() => {
              onUpdate(data);
            }}
            icon="pen"
            tagTitle="Edit"
          />
          <ActionButton onClick={handleDelete} icon="trash" tagTitle="Delete" />
        </div>
      </GridCell>
    </tr>
  );
};
