import { ReactNode } from "react";
import { Card, CardBody, CardHeader } from "reactstrap";
import styles from "./cardWithHeader.module.scss";

type Props = {
  header: ReactNode;
  children: ReactNode;
};

export default function CardWithGenericHeader({ header, children }: Props) {
  return (
    <Card>
      <CardHeader className={styles.header}>{header}</CardHeader>
      <CardBody className={styles.body}>{children}</CardBody>
    </Card>
  );
}
