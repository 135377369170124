import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { client } from "../services/axios";
import { ApiErrorT } from "../types/Common";

export const useMutationRebootUfon = () => {
  return useMutation(
    (ufonId: string | number) => {
      return client.put(`/api/v1/ufon/${ufonId}/reboot`);
    },
    {
      onSuccess: () => {
        toast.success("Correction station rebooted successfuly");
      },
      onError: (error: ApiErrorT) => {
        toast.error(error?.message || "Correction station rebooting failed");
      },
    }
  );
};
