import React from "react";
import { Card, CardBody } from "reactstrap";
import { UserT } from "../../../types/User";
import Title from "../../atoms/Title/Title";
import { useMutationLostPassword } from "../../../hooks/useMutationLostPassword";
import { useConfirm } from "../ConfirmBoxProvider/ConfirmBoxProvider";
import UserInitials from "../UserInitials/UserInitials";
import styles from "./styles.module.scss";
import { useCompactDesign } from "../../../hooks/useCompactDesign";
import { Permission } from "./Permission";
import { usePermissionGeolocation } from "../../../hooks/usePermissionGeolocation";
import { usePermissionNotification } from "../../../hooks/usePermissionNotification";
import {
  requestGeolocationPermission,
  requestNotificationPermission,
} from "../../../functions/servicePermissions";
import { useSelector } from "../../../store/hooks";

type Props = {
  user: UserT;
};

export const UserCard: React.FC<Props> = ({ user }) => {
  const lostPasswordMutation = useMutationLostPassword();
  const confirm = useConfirm();
  const isCompact = useCompactDesign();
  const currentUser = useSelector((state) => state.user.user);

  const isCurrentUser = currentUser?.id === user.id;

  const handleResetPassword = () => {
    confirm({
      title: "Reset password?",
      subTitle: `Reset link will be send to: ${user.email}`,
      asyncCallback: () =>
        lostPasswordMutation.mutateAsync({ email: user.email }),
      type: "ok",
    });
  };

  const {
    permitted: geoPermitted,
    loading: geoLoading,
    reload: reloadGeo,
  } = usePermissionGeolocation();
  const {
    permitted: notificationPermitted,
    loading: notificationLoading,
    reload: reloadNotifications,
  } = usePermissionNotification();

  const additionalStyles = isCompact
    ? " flex-column align-items-center text-center"
    : "";

  return (
    <div className="d-flex flex-column gap-1">
      <Card>
        <CardBody>
          <div className={"d-flex gap-3" + additionalStyles}>
            <UserInitials user={user} />
            <div className={"pt-2 mt-1"}>
              <Title type="h2">{`${user.firstName} ${user.surname}`}</Title>
              <p>
                {user.companies.map((company, index) =>
                  index === 0 ? company.name : `, ${company.name}`
                )}
              </p>
              <div className="d-flex flex-column flex-sm-row gap-0 gap-sm-3">
                <a href={`tel:${user.phone}`}>{user.phone}</a>
                <a href={`mailto:${user.email}`}>{user.email}</a>
              </div>
              <div className={"mt-4"}>
                Role: <strong>{user.role.name}</strong>
              </div>
            </div>
          </div>
        </CardBody>
      </Card>
      {isCurrentUser && (
        <>
          <Permission
            name="Geolocation"
            allowed={geoPermitted}
            allow={async () => {
              await requestGeolocationPermission();
              reloadGeo();
            }}
            loading={geoLoading}
          />
          <Permission
            name="Notifications"
            allowed={notificationPermitted}
            allow={async () => {
              await requestNotificationPermission();
              reloadNotifications();
            }}
            loading={notificationLoading}
          />{" "}
        </>
      )}
      <div className="pt-4 text-center">
        <button
          onClick={handleResetPassword}
          className={styles["reset-password"]}
        >
          Reset password
        </button>
      </div>
    </div>
  );
};
