import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";
import {
  JOB_STATUSES,
  PRAGUE_LAT_LNG,
  ROLE_IDS,
  WINDOW_TITLE_NAMES,
} from "../../constants";
import { ROUTES } from "../../routes";
import { useDispatch, useSelector } from "../../store/hooks";
import {
  cleanMowerTrajectoryAction,
  startMowerTrackingAction,
} from "../../store/slices/mowerSlice";
import {
  setIsLoadingAction,
  setIsLoadingUserAction,
} from "../../store/slices/tasksSlice";
import { useQueryJobGet } from "../../hooks/useQueries";
import { useMutationStartJob } from "../../hooks/useMutationStartJob";
import { useMutationCompleteJob } from "../../hooks/useMutationCompleteJob";
import { useMutationCancelJob } from "../../hooks/useMutationCancelJob";
import { useMutationArchiveJob } from "../../hooks/useMutationArchiveJob";
import { useMutationRestoreJob } from "../../hooks/useMutationRestoreJob";
import {
  getJobJourneyIds,
  getJobMowers,
  getJobStatus,
} from "../../functions/jobStatus";
import TabsWithMapLayout from "../../components/organisms/TabsWithMapLayout/TabsWithMapLayout";
import Log from "../../components/molecules/LogNew/Log";
import JobCard from "../../components/molecules/JobCard/JobCard";
import { MowerCard } from "../../components/molecules/MowerCard/MowerCard";
import { JobStatusCard } from "../../components/molecules/JobStatusCard/JobStatusCard";
import { Cards } from "../../components/atoms/Cards/Cards";
import { StyledButton } from "../../components/atoms/Button/Button";
import { ExportJourneyDialogBase } from "../../components/atoms/ExportJourneyDialog/ExportJourneyDialogBase";
import { PageHeader } from "./PageHeader";
import JobMap from "./Map/JobManualMap";
import styles from "./jobManual.module.scss";
import { queryClient } from "../..";

export const JobManualPage = () => {
  useEffect(() => {
    document.title = WINDOW_TITLE_NAMES.jobs;
  }, []);
  const [center, setCenter] = useState({
    lat: PRAGUE_LAT_LNG.lat,
    lng: PRAGUE_LAT_LNG.lng,
  });
  const [mapZoom, setMapZoom] = useState(8);
  const [openExport, setOpenExport] = useState(false);
  const [showOverview, setShowOverview] = useState(true);
  const dispatch = useDispatch();
  const { jobId } = useParams() as { jobId: string };
  const navigate = useNavigate();
  const {
    data: jobData,
    isLoading,
    refetch: refetchJob,
  } = useQueryJobGet(jobId);
  const job =
    jobData?.data && jobData.data.geostick === undefined
      ? jobData.data
      : undefined;
  const { user: currentUser } = useSelector((store) => store.user);

  const mowerIdsToClean = useSelector(
    (state) => state.tasks.userTasks?.infoStatus.allMowers
  );
  useEffect(() => {
    const mowerId = job?.mowers[0].id;
    if (mowerId) {
      dispatch(startMowerTrackingAction({ mowerId }));
    }
    if (mowerIdsToClean) {
      for (const id of mowerIdsToClean) {
        dispatch(cleanMowerTrajectoryAction({ mowerId: id }));
      }
    }
    dispatch(setIsLoadingAction(false));
    dispatch(setIsLoadingUserAction(false));
    return () => {
      if (mowerId) {
        dispatch({ type: "STOP_MOWER_TRACKING" });
      }
    };
  }, [job, jobId, mowerIdsToClean, dispatch]);

  const mowers = useSelector((state) => state.mower.mowers);
  const currentMower = useMemo(
    () => job && job.mowers[0] && mowers[job.mowers[0].id],
    [job, mowers]
  );

  const journeyIds = useMemo(() => {
    return job ? getJobJourneyIds(job) : [];
  }, [job]);

  const journeyMowers = useMemo(() => {
    return job ? getJobMowers(job) : [];
  }, [job]);

  useEffect(() => {
    if (job && job.latitude && job.longitude) {
      const { latitude, longitude } = job;
      if (latitude && longitude) {
        setCenter({ lat: latitude, lng: longitude });
        setMapZoom(20);
      }
    } else if (currentMower && currentMower.currentLocation) {
      const { lat, lng } = currentMower.currentLocation;
      if (lat && lng) {
        setCenter({ lat, lng });
        setMapZoom(20);
      }
    }
  }, [job, currentMower]);

  const startJobMutation = useMutationStartJob({
    onSuccess: () => {
      refetchJob();
      setShowOverview((prev) => !prev);
    },
  });

  const completeJobMutation = useMutationCompleteJob(jobId, {
    onSuccess: () => {
      navigate(ROUTES.jobs());
    },
  });

  const cancelJobMutation = useMutationCancelJob(jobId, {
    onSuccess: () => {
      refetchJob();
      setShowOverview(true);
      const mowerId = job?.mowers[0].id;
      if (mowerId) {
        dispatch(cleanMowerTrajectoryAction({ mowerId }));
      }
      queryClient.invalidateQueries(["missedCoordinates"]);
    },
  });

  const handleCompleteJob = () => {
    completeJobMutation.mutate();
  };

  const handleCancelJob = () => {
    cancelJobMutation.mutate();
  };

  const archiveJobMutation = useMutationArchiveJob(jobId, {
    onSuccess: () => {
      navigate(ROUTES.jobs());
    },
  });

  const restoreJobMutation = useMutationRestoreJob(jobId, {
    onSuccess: () => {
      navigate(ROUTES.jobs());
    },
  });

  const handleArchiveJob = () => {
    archiveJobMutation.mutateAsync();
  };

  const handleRestoreJob = () => {
    restoreJobMutation.mutateAsync();
  };

  const isCompletedJob = job?.status.name === JOB_STATUSES.Finished;

  const canUserHandleTask =
    jobId === `${currentUser?.id}` || // user is the owner
    currentUser?.role.id === ROLE_IDS.developer ||
    currentUser?.role.id === ROLE_IDS.manager; // user is manager of company

  if (!job) {
    return null;
  }

  const mowerId = job.mowers[0].id;
  const status = getJobStatus(job);

  return (
    <PageHeader
      title={job?.name || `Job ${jobId}`}
      job={job}
      status={status}
      isCompletedJob={isCompletedJob}
      isLoading={completeJobMutation.isLoading}
      isArchived={job.archived}
      isDisabled={!canUserHandleTask}
      onStart={() => startJobMutation.mutate(job.id)}
      onComplete={handleCompleteJob}
      onCancel={handleCancelJob}
      onArchive={handleArchiveJob}
      onRestore={handleRestoreJob}
      onExport={
        journeyIds.length > 0
          ? () => setOpenExport(true)
          : () => {
              toast.error("No journeys to export");
            }
      }
      refetchJob={refetchJob}
    >
      <TabsWithMapLayout
        tabName="Status"
        mapComponent={
          <JobMap
            id="mapElement"
            mowerId={mowerId}
            center={center}
            zoom={mapZoom}
            isLoading={isLoading}
            jobId={jobId}
            job={job}
            journeyIds={journeyIds}
          />
        }
        logComponent={
          currentUser?.role.id === ROLE_IDS.developer ? (
            <Log id={mowerId} />
          ) : undefined
        }
      >
        {job && (
          <>
            {showOverview && <JobCard job={job} showStatus={false} />}
            {!showOverview && (
              <Cards>
                <JobStatusCard mowerId={mowerId} />
                <MowerCard
                  jobId={jobId}
                  mowerId={mowerId}
                  error={null}
                  routeName={undefined}
                  skipRoute
                />
              </Cards>
            )}
            {status === "started" && showOverview && (
              <StyledButton
                className={styles.jobProgressButton}
                title="Job progress"
                color="primary"
                onClick={() => {
                  setShowOverview((prev) => !prev);
                }}
                disabled={job.archived}
              />
            )}
          </>
        )}
      </TabsWithMapLayout>
      <ExportJourneyDialogBase
        isOpen={openExport}
        onClose={() => setOpenExport(false)}
        journeyIds={journeyIds}
        mowers={journeyMowers}
        jobId={jobId}
      />
    </PageHeader>
  );
};
