import React from "react";
import { FinishedUser, JobState, MowerLocalState, NameAndId } from "../types";
import { JobT, MowersByUser, UsersBySegment } from "../../../types/Job";
import { MowerManCard } from "../components/MowerManCard/MowerManCard";
import styles from "../jobRouteType.module.scss";

interface IProps {
  setCurrentPage: (state: JobState) => void;
  setMowerMan: (user: NameAndId | null) => void;
  setDetailMower: (mower: MowerLocalState | null) => void;
  job: JobT;
  userWithInactiveMowers?: FinishedUser[];
  userWithActiveMowers?: UsersBySegment[];
  mowersByUser?: MowersByUser[];
}

const MowerMenOverview = ({
  setCurrentPage,
  setMowerMan,
  setDetailMower,
  userWithInactiveMowers,
  userWithActiveMowers,
  job,
  mowersByUser,
}: IProps) => {
  const mowerWithFinishedSegmentsOnly =
    userWithInactiveMowers && userWithInactiveMowers.length !== 0;
  const mowerWithSomeActiveSegments =
    userWithActiveMowers && userWithActiveMowers.length !== 0;

  return (
    <>
      {!mowersByUser && (
        <>
          {mowerWithSomeActiveSegments && (
            <h5 className={styles.title}>Active mowers</h5>
          )}
          {userWithActiveMowers &&
            userWithActiveMowers.map((user) => (
              <React.Fragment key={`user-${user.id}`}>
                <MowerManCard
                  job={job}
                  mowersFromSegments={user.mowers}
                  operator={{ name: user.name, id: user.id }}
                  setMowerMan={setMowerMan}
                  setCurrentPage={setCurrentPage}
                  setDetailMower={setDetailMower}
                />
                <div className={styles.divider}></div>
              </React.Fragment>
            ))}
          {mowerWithFinishedSegmentsOnly && (
            <h5 className={styles.title}>Inactive mowers</h5>
          )}
          {userWithInactiveMowers &&
            userWithInactiveMowers.map((user) => (
              <React.Fragment key={`user-${user.id}`}>
                <MowerManCard
                  job={job}
                  mowerInactive={user.mowers}
                  operator={{ name: user.name, id: user.id }}
                />
                <div className={styles.divider}></div>
              </React.Fragment>
            ))}
        </>
      )}
      {mowersByUser &&
        mowersByUser.map((user) => (
          <React.Fragment key={`user-${user.userId}`}>
            <MowerManCard
              job={job}
              operator={{ name: user.userName, id: user.userId }}
              mowersByUser={user.mowers}
              setMowerMan={setMowerMan}
              setCurrentPage={setCurrentPage}
              setDetailMower={setDetailMower}
            />
            <div className={styles.divider}></div>
          </React.Fragment>
        ))}
    </>
  );
};

export default MowerMenOverview;
