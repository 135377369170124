import { JOB_STATUSES } from "../constants";
import { GeostickJobT, JobT } from "../types/Job";
import { UserMowerT } from "../types/User";
import { JobProgressStatusT } from "../components/molecules/JobSubheader/JobSubheader";

export function getJobStatus(job: JobT | GeostickJobT): JobProgressStatusT {
  const isCompleted = job.status.name === JOB_STATUSES.Finished;
  const started = !!job.startedAt;
  if (isCompleted) {
    return "completed";
  }
  if (started) {
    return "started";
  }
  return "beforeStart";
}

export function getJobJourneyIds(job: JobT) {
  const ids: number[][] = [];
  for (const user of job.users) {
    for (const mower of user.mowers) {
      ids.push(mower.journeys || []);
    }
  }
  for (const user of job.segments.users) {
    for (const mower of user.mowers) {
      ids.push(mower.journeys || []);
    }
  }
  ids.push(job.journeys || []);
  return ids.flat();
}

export function getJobMowers(job: JobT) {
  const mowers: UserMowerT[] = [];
  for (const user of job.users) {
    for (const mower of user.mowers) {
      mowers.push(mower);
    }
  }
  return mowers;
}
