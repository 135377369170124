import React from "react";
import { useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";
import { Container } from "reactstrap";
import { StyledButton } from "../../components/atoms/Button/Button";
import { Icon } from "../../components/atoms/Icon";
import Title from "../../components/atoms/Title/Title";
import { Layout } from "../../components/organisms/Layout/Layout";
import { useMutationApproveCompany } from "../../hooks/useMutationApproveCompany";
import { useSearchParams } from "../../hooks/useSearchParam";
import { ROUTES } from "../../routes";
import { useSelector } from "../../store/hooks";
import styles from "./styles.module.scss";

type Props = {};

export const JoinCompany: React.FC<Props> = () => {
  const { getParamValue } = useSearchParams();
  const { token } = useParams() as { token: string };
  const companyName = getParamValue("company");
  const user = useSelector((store) => store.user.user);
  const navigate = useNavigate();

  const assignCompanyMutation = useMutationApproveCompany({
    onSuccess: () => {
      toast.success(`Company ${companyName} was approved successfuly`);
      if (Boolean(user)) {
        navigate(ROUTES.dashboard());
      } else {
        navigate(ROUTES.login());
      }
    },
  });

  const handleJoinCompany = () => {
    assignCompanyMutation.mutate({ token });
  };

  return (
    <Layout>
      <Container>
        <div className={styles.wrapper}>
          <Title type="h2">
            You were invited to {companyName || "company"}
          </Title>

          <StyledButton
            type="button"
            color="primary"
            className={styles.button}
            onClick={handleJoinCompany}
            isLoading={assignCompanyMutation.isLoading}
          >
            <span className="me-2">Join company</span>
            <Icon className={"back-to-dash"} icon={"chevron-right"} />
          </StyledButton>
        </div>
      </Container>
    </Layout>
  );
};
