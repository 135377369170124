import { FC } from "react";
import { JobInfoStatusT } from "../../../types/Job";
import {
  ExportJourneyDialogBase,
  InputRoutesT,
} from "./ExportJourneyDialogBase";

type PropsT = {
  isOpen: boolean;
  data: JobInfoStatusT | undefined;
  jobId: string;
  onClose: () => void;
  route?: InputRoutesT;
};

export const ExportJourneyDialog: FC<PropsT> = ({
  isOpen,
  data,
  jobId,
  onClose,
  route,
}) => {
  return (
    <ExportJourneyDialogBase
      isOpen={isOpen}
      mowers={data?.mower}
      journeyIds={data?.journeys}
      jobId={jobId}
      onClose={onClose}
      route={route}
    />
  );
};
