import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { client } from "../services/axios";
import { ApiErrorT } from "../types/Common";
import { MutationOptionsT } from "../types/ReactQuery";

export const useMutationLostPassword = (options?: MutationOptionsT) => {
  return useMutation(
    (data: { email: string }) => {
      return client.post(`/api/v1/public-user/lost-password`, data);
    },
    {
      onSuccess: (_, { email }) => {
        toast.success(`Recovery link send to: ${email}`);
        if (options?.onSuccess) {
          options.onSuccess();
        }
      },
      onError: (error: ApiErrorT) => {
        toast.error(error?.message);
      },
    }
  );
};
