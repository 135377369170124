import React, { FC } from "react";
import classNames from "classnames";
import { NewDiode } from "../NewDiode/NewDiode";
import { LedT } from "../../../types/Common";
import styles from "./ufon.module.scss";

interface PropsT {
  led?: LedT;
}

export const Ufon: FC<PropsT> = ({ led }) => {
  return (
    <div className={classNames(styles.wrapper)}>
      <div className={classNames(styles["body--vertical"])} />
      <div
        className={classNames(styles["body--horizontal"], styles.diode_wrapper)}
      >
        {[...Array(5)].map((_, index) => (
          <NewDiode key={`led-${index}`} size="sm" {...led} />
        ))}
      </div>
    </div>
  );
};
