import styles from "./colorCircle.module.scss";
import classNames from "classnames";

type PropsT = {
  color: string;
  className?: string;
};

export const ColorCircle = ({ color, className }: PropsT) => {
  return (
    <div
      style={{ backgroundColor: color }}
      className={classNames(styles.circle, className)}
    />
  );
};
