import { MowerT } from "../../../types/Mower";
import NotificationTab from "../../../components/organisms/NotificationTab/notificationTab";

type PropsT = {
  mower: MowerT;
};

export default function MowerNotificationTab({ mower }: PropsT) {
  return <NotificationTab baseQuery={{ mowerId: String(mower.id) }} />;
}
