import React, { FC, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Container } from "reactstrap";
import { toast } from "react-toastify";
import { JOB_TYPE_IDS, WINDOW_TITLE_NAMES } from "../../constants";
import { ROUTES } from "../../routes";
import { useQueryRouteGroupListGet } from "../../hooks/useQueries";
import {
  RouteInputsFromJobT,
  useMutationRouteCreateFromJob,
} from "../../hooks/useMutationRouteCreateFromJob";
import { StyledButton } from "../../components/atoms/Button/Button";
import { PageWrapper } from "../../components/atoms/PageWrapper/PageWrapper";
import SubHeader from "../../components/atoms/SubHeader/SubHeader";
import { Layout } from "../../components/organisms/Layout/Layout";
import { FormRouteFromJob } from "../../components/molecules/FormRoute/FormRouteFromJob";

export type RouteFromJobInputsT = {
  jobId: number;
  routeId: number;
  folderId: number;
};

export const RouteCreateFromJobPage: FC = () => {
  document.title = WINDOW_TITLE_NAMES.routes;
  const [isFormTouched, setIsFormTouched] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const historyState = location.state as {
    type?: "job" | "route";
  };

  const { data: routeGroups } = useQueryRouteGroupListGet();
  const routeCreateMutation = useMutationRouteCreateFromJob();

  const { jobId } = useParams();

  const handleSubmit = (values: RouteInputsFromJobT) => {
    routeCreateMutation.mutate(values, {
      onSuccess: (response) => {
        const { id } = response.data;
        const routeId = id as number | undefined;
        if (historyState.type === "job" && routeId !== undefined) {
          const params = new URLSearchParams();
          params.set("route", routeId.toString());
          params.set("folder", values.folderId.toString());
          params.set("type", JOB_TYPE_IDS.route.toString());
          navigate(ROUTES.jobCreate() + "?" + params.toString());
        } else {
          navigate(ROUTES.jobManual(values.jobId));
        }
      },
      onError: () => {
        toast.error("Failed to create route");
      },
    });
  };

  useEffect(() => {
    if (jobId === undefined) {
      toast.error("Job id is missing");
    }
  }, [jobId]);

  return (
    <Layout>
      <SubHeader
        isDetail
        title="New route from job"
        actions={
          <>
            <StyledButton
              onClick={() => navigate(-1)}
              color="dark"
              outline
              title="Cancel"
            />
            <StyledButton
              disabled={!isFormTouched || jobId === undefined}
              color="primary"
              type="submit"
              form="route-form"
            >
              {!routeCreateMutation.isLoading ? "Create" : "Saving"}
            </StyledButton>
          </>
        }
      />
      <PageWrapper>
        <Container>
          {jobId !== undefined && (
            <FormRouteFromJob
              onTouch={() => setIsFormTouched(true)}
              onSubmit={handleSubmit}
              routeGroups={routeGroups?.data.items}
              jobId={Number(jobId)}
            />
          )}
        </Container>
      </PageWrapper>
    </Layout>
  );
};
