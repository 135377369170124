import { DeviceListItemT } from "../../types/Common";
import { FilterTypes } from "./Devices";

export const getFilteredDevices = (
  devices: DeviceListItemT[],
  filter: FilterTypes
) =>
  devices.filter((device) => {
    if (filter === "all") {
      return device;
    } else if (device.type === filter) {
      return device;
    }
  });

export const getDeviceCount = (
  type: "mower" | "ufon",
  items?: DeviceListItemT[]
) =>
  items?.reduce((acc, device) => {
    if (device.type === type) {
      acc += 1;
    }
    return acc;
  }, 0) || 0;
