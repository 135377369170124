import { FC, useRef } from "react";
import { FormGroup, Input } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import classNames from "classnames";
import { queryClient } from "../../..";
import { ROUTES } from "../../../routes";
import { JobListItemT } from "../../../types/Job";
import { DATE_FORMATS, JOB_STATUSES, JOB_TYPE_IDS } from "../../../constants";
import { useConfirm } from "../ConfirmBoxProvider/ConfirmBoxProvider";
import { getJobLink } from "../../../functions/routing";
import { formatDate } from "../../../functions/formatDate";
import { usePermissions } from "../../../hooks/usePermissions";
import { useCompactDesign } from "../../../hooks/useCompactDesign";
import { useObserveHeight } from "../../../hooks/useObserveHeight";
import { useMutationArchiveJob } from "../../../hooks/useMutationArchiveJob";
import { useMutationRestoreJob } from "../../../hooks/useMutationRestoreJob";
import { ActionButton } from "../../atoms/ActionButton/ActionButton";
import JobIcon from "../../atoms/JobIcon/JobIcon";
import { Icon } from "../../atoms/Icon";
import { Status } from "../../atoms/Status/Status";
import styles from "./gridRow.module.scss";

type Props = {
  data: JobListItemT;
  checkedJobs: number[];
  isMerging: boolean;
  onCheck: (id: number) => void;
  onDelete?: (job?: JobListItemT) => void;
  onUpdate?: (job?: JobListItemT) => void;
};

export const JobGridRow: FC<Props> = ({
  data,
  checkedJobs,
  isMerging,
  onDelete,
  onCheck,
  onUpdate,
}) => {
  const permissions = usePermissions();
  const navigate = useNavigate();
  const confirm = useConfirm();

  const rowRef = useRef<HTMLTableRowElement>(null);
  const linkHeight = useObserveHeight(rowRef);

  const getJobDetailLink = () => {
    return getJobLink(
      data.type.name,
      data.id,
      data.users.map((user) => user.id),
      permissions
    );
  };
  const jobDetailLink = getJobDetailLink();
  const copyJobLink = `${ROUTES.jobCreate(data.id)}/?type=${data.type.id}`;
  const isCompact = useCompactDesign();

  const onCopy = () => {
    confirm({
      title: "Copy job",
      subTitle: "Create job copy?",
      callback: () => navigate(copyJobLink),
      type: "ok",
    });
  };

  const handleDelete = () => {
    onDelete?.(data);
  };

  const handleUpdate = () => {
    onUpdate?.(data);
  };

  const handleClick = () => {
    if (isMerging) {
      onCheck(data.id);
    }
  };

  const archiveJobMutation = useMutationArchiveJob(data.id, {
    onSuccess: () => {
      queryClient.invalidateQueries(["jobs"]);
    },
  });

  const restoreJobMutation = useMutationRestoreJob(data.id, {
    onSuccess: () => {
      queryClient.invalidateQueries(["jobs"]);
    },
  });

  const handleArchiveJob = () => {
    archiveJobMutation.mutateAsync();
  };

  const handleRestoreJob = () => {
    restoreJobMutation.mutateAsync();
  };

  const showCheckbox = isMerging && data.type.id === 4;
  const isDisabled = isMerging && data.type.id !== 4;
  const isAutoJob = data.type.id === JOB_TYPE_IDS.auto;

  return (
    <tr
      ref={rowRef}
      onClick={handleClick}
      className={classNames(
        isCompact ? styles["grid-row"] : styles["grid-row--large"],
        {
          [styles["grid-row__disabled"]]: isDisabled,
          [styles["grid-row__merging"]]: showCheckbox,
        }
      )}
    >
      <td
        className={classNames(
          styles["grid-row__cell"],
          isCompact && styles["grid-row__cell--compact"],
          styles["grid-row__cell--head"],
          isCompact && styles["grid-row__cell--head--compact"]
        )}
      >
        <div className="d-flex flex-grow-1">
          {showCheckbox && (
            <FormGroup check className={styles["form-group"]}>
              <Input
                id={`check-${data.id}`}
                type="checkbox"
                readOnly
                checked={checkedJobs.includes(data.id)}
                className={styles.checkbox}
              />
            </FormGroup>
          )}
          {isMerging ? (
            <span
              className={classNames(
                styles["grid-row__cell-head-link"],
                isCompact && styles["grid-row__cell-head-link--compact"],
                styles["grid-row__cell-head-link__disabled"]
              )}
            >
              {data.name}
            </span>
          ) : (
            <span
              className={classNames(
                styles["grid-row__cell-head-link"],
                isCompact && styles["grid-row__cell-head-link--compact"]
              )}
            >
              {data.name}
              {isCompact && (
                <Icon
                  icon={"angle-right"}
                  className={styles["grid-row__cell-head-icon"]}
                />
              )}
            </span>
          )}
        </div>
      </td>
      <td
        className={classNames(
          styles["grid-row__cell"],
          isCompact && styles["grid-row__cell--compact"],
          isCompact && styles["grid-row__cell--3--compact"]
        )}
      >
        <Status job={data} />
      </td>
      <td
        className={classNames(
          styles["grid-row__cell"],
          data.status.name === JOB_STATUSES.Overdue && "text-error",
          isCompact && styles["grid-row__cell--compact"],
          isCompact && styles["grid-row__cell--3--compact"]
        )}
      >
        <Icon className="me-2" icon="calendar-alt" />
        <span className={styles.date}>{formatDate(data.dueDate)}</span>
      </td>
      {!isCompact && (
        <td
          className={classNames(
            styles["grid-row__cell"],
            isCompact && styles["grid-row__cell--compact"]
          )}
        >
          {data.startTime && (
            <>
              <Icon className="me-2" icon="calendar-alt" />
              <span className={styles.date}>
                {formatDate(data.startTime, DATE_FORMATS.dateTimeShort)}
              </span>
            </>
          )}
        </td>
      )}
      {!isCompact && (
        <td
          className={classNames(
            styles["grid-row__cell"],
            isCompact && styles["grid-row__cell--compact"]
          )}
        >
          {data.endTime && (
            <>
              <Icon className="me-2" icon="calendar-alt" />
              <span className={styles.date}>
                {formatDate(data.endTime, DATE_FORMATS.dateTimeShort)}
              </span>
            </>
          )}
        </td>
      )}
      <td
        className={classNames(
          styles["grid-row__cell"],
          isCompact && styles["grid-row__cell--compact"],
          isCompact && styles["grid-row__cell--3--compact"]
        )}
      >
        <JobIcon jobName={data.type.name} />
      </td>
      <td
        className={classNames(
          styles["grid-row__cell"],
          isCompact && styles["grid-row__cell--compact"],
          isCompact && styles["grid-row__cell--3--compact"]
        )}
      >
        {data.users?.map((user, index) => (
          <span key={user.id}>
            {index !== 0 && ", "} {user.name}
          </span>
        ))}
        {permissions.jobManage && !isMerging && (
          <div className={styles.actions}>
            {data.archived ? (
              <ActionButton
                onClick={handleRestoreJob}
                icon="trash-undo"
                tagTitle="Unarchive"
              />
            ) : (
              <>
                <ActionButton
                  disabled={isAutoJob}
                  onClick={onCopy}
                  icon="clone"
                  tagTitle="Copy"
                />
                <ActionButton
                  disabled={isAutoJob}
                  onClick={handleUpdate}
                  icon="pen"
                  tagTitle="Edit"
                />
                {data.status.name === JOB_STATUSES.Finished && (
                  <ActionButton
                    onClick={handleArchiveJob}
                    icon="archive"
                    tagTitle="Archive"
                  />
                )}
                <ActionButton
                  onClick={handleDelete}
                  icon="trash"
                  tagTitle="Delete"
                />
              </>
            )}
          </div>
        )}
      </td>
      <td className={styles.superLinkCell} style={{ height: linkHeight }}>
        <Link to={jobDetailLink} className={styles.superLink} />
      </td>
    </tr>
  );
};
