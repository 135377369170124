import { CoordinatesRawT, CoordinatesT } from "../types/Route";

export const getMapBounds = (coordinates: CoordinatesT<"route">) => {
  const bounds = new window.google.maps.LatLngBounds();
  coordinates?.coordinates.forEach((coordinate) => {
    coordinate?.coordinates?.forEach((marker) => {
      bounds.extend({ lat: marker.lat, lng: marker.lng });
    });
  });
  return bounds;
};

export const getBoundsFromJourneys = (coordinates: CoordinatesRawT[]) => {
  const bounds = new window.google.maps.LatLngBounds();

  coordinates.forEach((coordinate) => {
    Object.values(coordinate).forEach((value) => {
      value.flat(1).forEach(({ latitude, longitude }) => {
        bounds.extend({ lat: latitude, lng: longitude });
      });
    });
  });

  return bounds;
};

export const getSingleBound = (location: { lat: number; lng: number }) => {
  const bounds = new window.google.maps.LatLngBounds();
  bounds.extend({ lat: location.lat, lng: location.lng });
  return bounds;
};
