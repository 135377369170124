import React from "react";
import { Link } from "react-router-dom";
import { Card, CardBody } from "reactstrap";
import { ROUTES } from "../../routes";
import { Icon } from "../../components/atoms/Icon";
import { StyledButton } from "../../components/atoms/Button/Button";
import styles from "./dashboard.module.scss";
import { IconsId } from "../../assets/fonts/iconfont/icons";

const buttons = [
  { title: "Add user", link: ROUTES.userCreate(), icon: "plus" as IconsId },
  { title: "Add route", link: ROUTES.routeCreate(), icon: "plus" as IconsId },
  { title: "Create job", link: ROUTES.jobCreate(), icon: "plus" as IconsId },
];

const DashboardZeroState = () => {
  return (
    <div>
      <div className={styles.title}>
        <div className={styles["title-left"]}>
          <Link className={styles.superLink} to={ROUTES.jobs()} />
          <span className={styles["title-link"]}>Jobs</span>
        </div>
        <Icon icon="arrow-right" className={styles.chevron} />
      </div>
      <Card>
        <CardBody className={styles.zeroState}>
          <h5 className="mb-5">Welcome to My Spider portal!</h5>
          {buttons.map((btn, index) => (
            <StyledButton
              key={index}
              title={btn.title}
              icon={btn.icon}
              color="primary"
              link={btn.link}
              className={styles["zeroState-btn"]}
            />
          ))}
        </CardBody>
      </Card>
    </div>
  );
};

export default DashboardZeroState;
