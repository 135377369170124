import { FC, useCallback, useEffect, useRef, useState } from "react";
import { GoogleMap, GoogleMapProps, Polyline } from "@react-google-maps/api";
import { JOB_ROUTE_TYPE_COLORS, PRAGUE_LAT_LNG } from "../../../constants";
import { GeostickPointsT, MeasurementT } from "../../../types/Geostick";
import { onLoad } from "../../../functions/onMapLoad";
import { useUserLocation } from "../../../hooks/useUserLocation";
import MapControls from "../../../components/molecules/MapControls/MapControls";
import UserMapMarker from "../../../components/atoms/UserMapMarker/UserMapMarker";
import { Loader } from "../../../components/atoms/Loader/Loader";
import UfonMarker from "../../../components/atoms/MapMarkers/UfonMarker";
import { MeasurementInfoWindow } from "./MeasurementInfoWindow";
import { GeostickMarker } from "./GeostickMarker";
import { DropdownComponent } from "./DropdownComponent";
import { MeasurementMarker } from "./MeasurementMarker";
import styles from "./geostickMap.module.scss";

type LocationT = {
  lat: number;
  lng: number;
};

type MapUfonT = {
  id: number;
  name: string;
  latitude?: number | null;
  longitude?: number | null;
};

interface PropsT extends GoogleMapProps {
  ufon: MapUfonT | null | undefined;
  rover: MapUfonT | null | undefined;
  measurements: GeostickPointsT;
  toDelete?: (measurementId: MeasurementT) => void;
  toEdit?: (measurement: MeasurementT) => void;
  isLoading: boolean;
}

function getBounds(
  measurements: GeostickPointsT,
  ufonLocation: LocationT | null,
  userLocation: LocationT | null,
  defaultLocation: LocationT
) {
  const bounds = new google.maps.LatLngBounds();
  const allMeasurements = measurements.flatMap((group) => group.items);
  allMeasurements.forEach((measurement) => {
    bounds.extend({
      lat: measurement.latitude,
      lng: measurement.longitude,
    });
  });
  if (ufonLocation) {
    bounds.extend(ufonLocation);
  }
  if (allMeasurements.length === 0 && ufonLocation === null) {
    if (userLocation) {
      bounds.extend(userLocation);
    } else {
      bounds.extend(defaultLocation);
    }
  }
  return bounds;
}

function getDefaultToggledMeasurements(measurements: GeostickPointsT) {
  return measurements.reduce((acc, group) => {
    acc[group.type] = true;
    return acc;
  }, {} as Record<string, boolean>);
}

function updateMeasurements(
  newMeasurements: GeostickPointsT,
  toggledMeasurements: Record<string, boolean>
) {
  const newToggled: Record<string, boolean> = {};
  for (const item of newMeasurements) {
    newToggled[item.type] =
      item.type in toggledMeasurements ? toggledMeasurements[item.type] : true;
  }
  return newToggled;
}

export const GeostickMap: FC<PropsT> = ({
  ufon,
  rover,
  measurements,
  toDelete,
  toEdit,
  isLoading,
  ...restProps
}: PropsT) => {
  const mapRef = useRef<GoogleMap | null>(null);
  const [activeWindow, setActiveMarker] = useState<MeasurementT | null>(null);
  const { userLocation, mapCenter, handleUserLocation } = useUserLocation(
    PRAGUE_LAT_LNG,
    mapRef
  );

  const [shownMeasurements, setShownMeasurements] = useState<
    Record<string, boolean>
  >(getDefaultToggledMeasurements(measurements));

  useEffect(
    () => {
      setShownMeasurements((prev) => updateMeasurements(measurements, prev));
      if (
        activeWindow &&
        !measurements.some((group) =>
          group.items.some((item) => item.id === activeWindow.id)
        )
      ) {
        setActiveMarker(null);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [measurements]
  );

  const handleToggleMeasurement = (name: string) => {
    setShownMeasurements((prev) => {
      return {
        ...prev,
        [name]: !prev[name],
      };
    });
    if (activeWindow && name === activeWindow.type) {
      setActiveMarker(null);
    }
  };

  const resetLayers = () => {
    setShownMeasurements(getDefaultToggledMeasurements(measurements));
  };

  const ufonLongitude = ufon?.longitude;
  const ufonLatitude = ufon?.latitude;

  const handleHomeLocation = useCallback(() => {
    if (mapRef.current) {
      const ufonLocation =
        (ufonLongitude || ufonLongitude === 0) &&
        (ufonLatitude || ufonLatitude === 0)
          ? {
              lat: ufonLatitude,
              lng: ufonLongitude,
            }
          : null;
      const bounds = getBounds(
        measurements,
        ufonLocation,
        userLocation,
        PRAGUE_LAT_LNG
      );
      mapRef.current.state.map?.fitBounds(bounds);
      if (measurements.length === 0) {
        mapRef.current.state.map?.setZoom(ufonLocation ? 18 : 8);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [measurements, ufonLongitude, ufonLatitude]);

  useEffect(() => {
    handleHomeLocation();
  }, [handleHomeLocation]);

  const lines = measurements
    .filter((group) => shownMeasurements[group.type])
    .map((group) => {
      return group.items.map((measurement) => {
        return {
          lat: measurement.latitude,
          lng: measurement.longitude,
        };
      });
    });

  const roverLocation =
    rover &&
    rover.latitude !== undefined &&
    rover.longitude !== undefined &&
    rover.latitude !== null &&
    rover.longitude !== null
      ? {
          lat: rover.latitude,
          lng: rover.longitude,
        }
      : null;

  return (
    <div className={styles.map}>
      {isLoading && (
        <div className={styles.loader}>
          <Loader />
        </div>
      )}
      <GoogleMap
        tilt={0}
        mapTypeId={google.maps.MapTypeId.SATELLITE}
        mapContainerStyle={{ height: "100%" }}
        center={mapCenter}
        zoom={8}
        ref={mapRef}
        onLoad={(map) => {
          onLoad(map);
          handleHomeLocation();
          map.setZoom(8);
        }}
        {...restProps}
      >
        <MapControls
          handleHomeLocation={handleHomeLocation}
          handleUserLocation={handleUserLocation}
          dropdownComponent={
            <DropdownComponent
              measurements={shownMeasurements}
              handleToggle={handleToggleMeasurement}
              resetLayers={resetLayers}
            />
          }
        />
        {userLocation && <UserMapMarker userLocation={userLocation} />}
        {lines.map((line, index) => (
          <Polyline
            key={index}
            path={line}
            options={{
              strokeColor: JOB_ROUTE_TYPE_COLORS.plannedRoute,
              strokeOpacity: 1,
              strokeWeight: 2,
              zIndex: 200,
            }}
          />
        ))}
        {measurements
          .filter((group) => shownMeasurements[group.type])
          .flatMap((group) =>
            group.items.map((measurement) => (
              <MeasurementMarker
                key={measurement.id}
                measurement={measurement}
                active={!!activeWindow && activeWindow.id === measurement.id}
                onClick={() => setActiveMarker(measurement)}
              />
            ))
          )}
        {roverLocation && <GeostickMarker position={roverLocation} />}
        {ufon && <UfonMarker ufonId={ufon.id} />}
        {activeWindow && (
          <MeasurementInfoWindow
            onClose={() => setActiveMarker(null)}
            measurement={activeWindow}
            onDelete={
              toDelete
                ? () => {
                    toDelete(activeWindow);
                    setActiveMarker(null);
                  }
                : undefined
            }
            onEdit={
              toEdit
                ? () => {
                    toEdit(activeWindow);
                    setActiveMarker(null);
                  }
                : undefined
            }
          />
        )}
      </GoogleMap>
    </div>
  );
};
