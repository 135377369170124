import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { client } from "../services/axios";
import { MutationOptionsT } from "../types/ReactQuery";
import { ApiErrorT } from "../types/Common";

type GroupDataType = {
  name: string;
  groupId: number;
};

export const useMutationRouteGroupRename = (options?: MutationOptionsT) => {
  return useMutation(
    ({ name, groupId }: GroupDataType) => {
      return client.put(`/api/v1/route-folder/${groupId}`, { name });
    },
    {
      onSuccess: () => {
        toast.success("Route group has been renamed");
        if (options?.onSuccess) {
          options.onSuccess();
        }
      },
      onError: (error: ApiErrorT) => {
        toast.error(error?.message);
        if (options?.onError) {
          options.onError();
        }
      },
    }
  );
};
