import { useMutation } from "react-query";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { ROUTES } from "../routes";
import { client } from "../services/axios";
import { ApiErrorT, DeviceFormInput } from "../types/Common";
import { MutationOptionsT } from "../types/ReactQuery";
import { getMutationDeviceData } from "../functions/getMutationDeviceData";

export const useMutationUfonCreate = (options?: MutationOptionsT) => {
  const navigate = useNavigate();

  return useMutation(
    (data: DeviceFormInput) => {
      const dataToSend = getMutationDeviceData(data);
      return client.post(`/api/v1/ufon`, {
        ...dataToSend,
        companyId: Number(data.companyId),
      });
    },
    {
      onSuccess: (data) => {
        toast.success("Ufon has been created");
        navigate(ROUTES.ufon(data.data.id));
      },
      onError: (error: ApiErrorT) => {
        toast.error(error?.message);
        if (options?.onError) {
          options.onError();
        }
      },
    }
  );
};
