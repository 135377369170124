import { UfonT } from "../../../types/Ufon";
import NotificationTab from "../../../components/organisms/NotificationTab/notificationTab";

type PropsT = {
  ufon: UfonT;
};

export default function UfonNotificationTab({ ufon }: PropsT) {
  return <NotificationTab baseQuery={{ ufonId: String(ufon.id) }} />;
}
