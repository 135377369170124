import React, { useMemo } from "react";
import { JOB_ROUTE_TYPE_COLORS } from "../../constants";
import { DropdownConfig } from "../../types/Common";
import { CoordinatesT, ShowActivityTypesT } from "../../types/Route";
import { getActivityTypeLabel } from "../../functions/mapLayers";
import Dropdown from "../../components/molecules/Dropdown/Dropdown";
import { PerimeterLayersType, RouteLayersPerimeter } from "./types";
import { createSubmenuByType, renameLayer } from "./functions";

type Props = {
  showMowingArrows: boolean;
  setShowMowingArrows: () => void;
  activeRouteLayers: RouteLayersPerimeter;
  handleCheckAllRoutes: (type: PerimeterLayersType) => void;
  handleRouteLayersChange: (id: number[], type: PerimeterLayersType) => void;
  handleCheckActivity: () => void;
  activityChecked: boolean;
  isDisabled: boolean;
  coordinates?: CoordinatesT<"route">;
  activityTypes?: ShowActivityTypesT;
  setActivityType: (type: keyof ShowActivityTypesT, value: boolean) => void;
  resetLayers: () => void;
};

const DropdownComponent = React.memo(
  ({
    showMowingArrows,
    setShowMowingArrows,
    activeRouteLayers,
    activityChecked,
    handleRouteLayersChange,
    handleCheckAllRoutes,
    handleCheckActivity,
    isDisabled,
    coordinates,
    activityTypes,
    setActivityType,
    resetLayers,
  }: Props) => {
    const dropdownConfig = useMemo<DropdownConfig>(() => {
      const config: DropdownConfig = [];

      if (activityTypes) {
        const allActivitiesShown = Object.values(activityTypes).every((x) => x);
        config.push({
          id: "activityType",
          title: "Activity Type",
          selected: allActivitiesShown,
          onSelect: () => {
            (
              Object.keys(activityTypes) as Array<keyof ShowActivityTypesT>
            ).forEach((type) => setActivityType(type, !allActivitiesShown));
          },
          subItems: (
            Object.entries(activityTypes) as Array<
              [keyof ShowActivityTypesT, boolean]
            >
          ).map(([key, shown]) => {
            return {
              id: `show_${key}`,
              title: getActivityTypeLabel(key),
              selected: shown,
              onSelect: () => setActivityType(key, !shown),
            };
          }),
        });
      }

      config.push({
        id: "activity",
        title: "Activity",
        selected: activityChecked,
        onSelect: handleCheckActivity,
        color: JOB_ROUTE_TYPE_COLORS.activeDevice,
      });

      if (!isDisabled && coordinates) {
        for (const layer in activeRouteLayers) {
          const type = layer as PerimeterLayersType;
          config.push({
            id: type,
            title: renameLayer(type),
            color:
              type === "recording"
                ? JOB_ROUTE_TYPE_COLORS.calculatedRoute
                : JOB_ROUTE_TYPE_COLORS.plannedRoute,
            selected: activeRouteLayers[type].length !== 0,
            onSelect: () => handleCheckAllRoutes(type),
            subItems: createSubmenuByType(
              type,
              coordinates,
              activeRouteLayers[type],
              handleRouteLayersChange
            ),
          });
        }
      }

      config.push({
        id: "direction",
        title: "Show direction",
        selected: showMowingArrows,
        onSelect: setShowMowingArrows,
        hasSeparator: true,
      });

      return config;
    }, [
      activityChecked,
      handleCheckActivity,
      isDisabled,
      showMowingArrows,
      setShowMowingArrows,
      activeRouteLayers,
      coordinates,
      handleRouteLayersChange,
      handleCheckAllRoutes,
      activityTypes,
      setActivityType,
    ]);

    return <Dropdown config={dropdownConfig} resetLayers={resetLayers} />;
  }
);

export default DropdownComponent;
