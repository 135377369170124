import { useMemo, useState } from "react";
import { useNavigate } from "react-router";
import classNames from "classnames";
import { toast } from "react-toastify";
import { queryClient } from "../../..";
import { ROUTES } from "../../../routes";
import { JobT } from "../../../types/Job";
import { UserPreviewT } from "../../../types/User";
import { useDispatch, useSelector } from "../../../store/hooks";
import { autoUploadRouteAction } from "../../../store/slices/mowerSlice";
import { useMutationCompleteJob } from "../../../hooks/useMutationCompleteJob";
import { useMutationArchiveJob } from "../../../hooks/useMutationArchiveJob";
import { useMutationRestoreJob } from "../../../hooks/useMutationRestoreJob";
import { useCompactDesign } from "../../../hooks/useCompactDesign";
import { useMutationCancelJob } from "../../../hooks/useMutationCancelJob";
import { useMutationStartJob } from "../../../hooks/useMutationStartJob";
import {
  getJobJourneyIds,
  getJobMowers,
  getJobStatus,
} from "../../../functions/jobStatus";
import { JobSubheader } from "../../../components/molecules/JobSubheader/JobSubheader";
import { useBaseActions } from "../../../components/molecules/JobSubheader/functions";
import { EditJobModal } from "../../../components/molecules/EditJobModal/EditJobModal";
import SubHeader from "../../../components/atoms/SubHeader/SubHeader";
import { StyledButton } from "../../../components/atoms/Button/Button";
import { ExportJourneyDialogBase } from "../../../components/atoms/ExportJourneyDialog/ExportJourneyDialogBase";
import { JobState, MowerLocalState, NameAndId } from "../types";
import { UserSelectModal } from "./UserSelectModal";
import styles from "../jobRouteType.module.scss";

interface IProps {
  currentPage: JobState;
  isMultiMower: boolean;
  setCurrentPage: (state: JobState) => void;
  jobId: string;
  job: JobT;
  mowerMan: NameAndId | null;
  setMowerMan: (user: NameAndId | null) => void;
  setDetailMower: (mower: MowerLocalState | null) => void;
  mower: NameAndId | null;
  refetchJob: () => void;
  continueFromOverview: (user?: UserPreviewT) => void;
}

const JobPageHeader = ({
  currentPage,
  isMultiMower,
  setCurrentPage,
  job,
  mowerMan,
  setMowerMan,
  setDetailMower,
  mower,
  refetchJob,
  continueFromOverview,
}: IProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleGoBack = () => {
    navigate(ROUTES.jobs());
  };
  const isCompact = useCompactDesign();
  const [showUserSelectModal, setShowUserSelectModal] = useState(false);
  const [showExportDialog, setShowExportDialog] = useState(false);
  const [editModalData, setEditModalData] = useState<{
    job: JobT;
    name: string;
  } | null>(null);

  const status = getJobStatus(job);
  const { hidePageHeader } = useSelector((store) => store.jobRouteType);
  const handleEditJob = () => {
    if (status !== "beforeStart") {
      setEditModalData({
        job: job,
        name: job.name,
      });
    } else {
      navigate(`${ROUTES.jobEdit(job.id)}/?type=${job.type.id}`);
    }
  };

  const hasSegments = useMemo(
    () => Boolean(job.segments.users.find((user) => user.mowers.length !== 0)),
    [job]
  );

  const journeyIds = useMemo(() => {
    return job ? getJobJourneyIds(job) : [];
  }, [job]);

  const journeyMowers = useMemo(() => {
    return job ? getJobMowers(job) : [];
  }, [job]);

  const archiveJobMutation = useMutationArchiveJob(job.id, {
    onSuccess: () => {
      queryClient.invalidateQueries(["jobs"]);
      handleGoBack();
    },
  });

  const handleArchiveJob = () => {
    archiveJobMutation.mutateAsync();
  };

  const restoreJobMutation = useMutationRestoreJob(job.id, {
    onSuccess: () => {
      queryClient.invalidateQueries(["jobs"]);
      handleGoBack();
    },
  });

  const handleRestoreJob = () => {
    restoreJobMutation.mutateAsync();
  };

  const cancelJobMutation = useMutationCancelJob(job.id, {
    onSuccess: () => {
      refetchJob();
      setCurrentPage("jobOverview");
    },
  });

  const handleCancelJob = () => {
    cancelJobMutation.mutate();
  };

  const uploadRoute = () => {
    if (hasSegments) {
      const mowersWithSegments = job.segments.users.flatMap(
        (user) => user.mowers
      );
      mowersWithSegments.forEach((jobMower) => {
        dispatch(
          autoUploadRouteAction({
            jobId: job.id,
            mowerId: jobMower.id,
            segmentId: jobMower.segmentId,
          })
        );
      });
    } else {
      job.mowers.forEach((jobMower) =>
        dispatch(autoUploadRouteAction({ jobId: job.id, mowerId: jobMower.id }))
      );
    }
  };

  const startJobMutation = useMutationStartJob({
    onSuccess: () => {
      refetchJob();
      uploadRoute();
    },
  });

  const completeJobMutation = useMutationCompleteJob(job.id, {
    onSuccess: () => {
      navigate(ROUTES.jobs());
    },
  });

  const handleCompleteJob = () => {
    completeJobMutation.mutate();
  };

  const handleStartJob = async () => {
    await startJobMutation.mutateAsync(job.id);
  };

  const getTitle = () => {
    switch (currentPage) {
      case "jobOverview":
        return job.name;
      case "segmentsForm":
      case "segmentsOverview":
        return "Route segments";
      case "mowerManStatus":
        return mowerMan ? mowerMan.name : job.name;
      case "mowerStatus":
        return mower && isMultiMower ? mower.name : job.name;
      default:
        return job.name;
    }
  };

  const secondaryActions = [
    {
      title: "Edit job",
      action: handleEditJob,
      closeOnClick: true,
    },
    ...useBaseActions(job.id, job.type),
  ];

  const getSubheader = (onGoBack?: VoidFunction) => {
    return (
      <>
        <JobSubheader
          title={getTitle()}
          status={status}
          isDisabled={false}
          isLoading={false}
          isArchived={job.archived}
          onStart={isMultiMower ? undefined : handleStartJob}
          onCancel={handleCancelJob}
          onComplete={handleCompleteJob}
          onArchive={handleArchiveJob}
          onRestore={handleRestoreJob}
          onExport={() =>
            journeyIds.length > 0
              ? setShowExportDialog(true)
              : toast.error("No journeys to export")
          }
          secondaryActions={secondaryActions}
          onGoBack={onGoBack}
          completeJobTitle="Complete job"
        />
        <ExportJourneyDialogBase
          isOpen={showExportDialog}
          onClose={() => setShowExportDialog(false)}
          journeyIds={journeyIds}
          mowers={journeyMowers}
          jobId={job.id}
        />
        {editModalData && (
          <EditJobModal
            jobId={editModalData.job.id}
            initialName={editModalData.name}
            isOpen={true}
            onClose={() => {
              setEditModalData(null);
            }}
            refetch={refetchJob}
          />
        )}
      </>
    );
  };

  // overview
  if (currentPage === "jobOverview") {
    if (hidePageHeader) {
      return <SubHeader title={job.name} isDetail hideBackIcon />;
    }
    return (
      <>
        <UserSelectModal
          job={job}
          isOpen={showUserSelectModal}
          onClose={() => setShowUserSelectModal(false)}
          onSelectUser={continueFromOverview}
        />
        {getSubheader()}
      </>
    );
  }
  // create segments
  if (currentPage === "segmentsForm" || currentPage === "segmentsOverview") {
    return (
      <SubHeader
        title={job.name}
        secondaryTitle={getTitle()}
        isDetail
        isAdvancedDetail
        actions={
          <StyledButton
            icon="times"
            color="dark"
            outline
            className={classNames(isCompact && styles["job-button--compact"])}
            onClick={() => setCurrentPage("jobOverview")}
          />
        }
      />
    );
  }
  // mowerMan status
  if (currentPage === "mowerManStatus") {
    return getSubheader(() => {
      setMowerMan(null);
      setCurrentPage("jobOverview");
    });
  }
  // job running = mower detail
  if (currentPage === "mowerStatus") {
    return getSubheader(
      isMultiMower
        ? () => {
            setDetailMower(null);
            setCurrentPage("mowerManStatus");
          }
        : () => {
            setDetailMower(null);
            setCurrentPage("jobOverview");
          }
    );
  }
  // history
  if (currentPage === "jobHistory") {
    return (
      <SubHeader
        title="History"
        onGoBack={() => setCurrentPage("jobOverview")}
        isDetail
      />
    );
  }
  return <SubHeader title={job.name} />;
};

export default JobPageHeader;
