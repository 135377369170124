import React, { FC } from "react";
import { JOB_ROUTE_TYPE_COLORS } from "../../constants";
import CurrentMovePolylines from "../../components/molecules/CurrentMovePolylines/CurrentMovePolylines";
import MissedCoordinatesPolylines from "../../components/molecules/MissedcoordinatesPolylines/MissedCoordinatesPolylines";
import { useSelector } from "../../store/hooks";
import { JobT } from "../../types/Job";
import { showMowerTrajectory } from "./functions";

type PropsT = {
  isDirectionShowing: boolean;
  mowerId: number;
  jobId?: string;
  job?: JobT;
  showActivity: boolean;
  hideMissedCoordinates?: boolean;
};

const MapMowerTracker: FC<PropsT> = ({
  isDirectionShowing,
  mowerId,
  jobId,
  job,
  showActivity,
  hideMissedCoordinates,
}) => {
  const tasks = useSelector((store) => store.tasks.userTasks);

  const { isRecordingPerimeter, isRecordingNoGoZone, isMowing } =
    showMowerTrajectory(tasks);

  return (
    <>
      {jobId && !hideMissedCoordinates && Boolean(job?.startedAt) && (
        <MissedCoordinatesPolylines
          isDirectionShowing={isDirectionShowing}
          strokeColor={JOB_ROUTE_TYPE_COLORS.activeDevice}
          jobId={jobId}
          mowerId={mowerId}
          showActivity={showActivity}
        />
      )}
      {Boolean(job?.startedAt) && (
        <CurrentMovePolylines
          mowerId={mowerId}
          strokeColor={
            isRecordingPerimeter || isRecordingNoGoZone
              ? JOB_ROUTE_TYPE_COLORS.calculatedRoute
              : JOB_ROUTE_TYPE_COLORS.activeDevice
          }
          isDirectionShowing={isDirectionShowing}
          showActivity={showActivity}
          isVisible={
            isMowing ||
            isRecordingNoGoZone ||
            isRecordingPerimeter ||
            job?.type.name === "Manual"
          }
        />
      )}
    </>
  );
};

export default MapMowerTracker;
