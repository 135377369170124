import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { ROLE_IDS, WINDOW_TITLE_NAMES } from "../../constants";
import { JobT } from "../../types/Job";
import { UserPreviewT } from "../../types/User";
import {
  setMowerRouteUploadedAction,
  startMowerTrackingAction,
} from "../../store/slices/mowerSlice";
import { useSelector } from "../../store/hooks";
import { setUfonData } from "../../store/slices/ufonSlice";
import {
  clearSegmentsData,
  fetchRouteCoordinatesAction,
  fetchSegmentsCoordinatesAction,
  setHidePageHeader,
  setJobProgress,
  setLastSegments,
  setShowActivityByMower,
  setShowFullOverview,
} from "../../store/slices/jobRouteTypeSlice";
import { useSearchParams } from "../../hooks/useSearchParam";
import Log from "../../components/molecules/LogNew/Log";
import TabsWithMapLayout from "../../components/organisms/TabsWithMapLayout/TabsWithMapLayout";
import { jobState } from "./config";
import { JobState, MowerLocalState } from "./types";
import {
  getInitialPage,
  getLastSegments,
  getMowerById,
  getMowerIds,
  getMowerMan,
  getUfons,
  routeUploadedDataByMower,
  jobHasSegments,
} from "./utils";
import JobPageHeader from "./components/JobPageHeader";
import { UserSelectModal } from "./components/UserSelectModal";
import { JobMap } from "./Map/JobMap";
import styles from "./jobRouteType.module.scss";

const JobMainComponent = ({
  job,
  jobId,
  refetchJob,
  isJobRefetching,
}: {
  job: JobT;
  jobId: string;
  refetchJob: () => void;
  isJobRefetching: boolean;
}) => {
  const dispatch = useDispatch();
  useEffect(() => {
    document.title = WINDOW_TITLE_NAMES.jobs;
  }, []);
  const { user: currentUser } = useSelector((state) => state.user);

  const [center, setCenter] = useState({
    lat: 50.0807843861,
    lng: 14.715616947,
  });
  const [showUserSelectModal, setShowUserSelectModal] = useState(false);

  const { updateSearchParams, getParamValue } = useSearchParams();

  const userId = getParamValue("userId");
  const mowerId = getParamValue("mowerId");
  const pageIndex = getParamValue("pageIndex");
  const tab = getParamValue("tab");

  const isMultiMower = useMemo(
    () => job.users.flatMap((user) => user.mowers).length > 1,
    [job]
  );
  const hasSegments = useMemo(() => jobHasSegments(job), [job]);

  const jobSegmentIds = useMemo(
    () =>
      job.segments.users
        .flatMap((user) => user.mowers)
        .map((mower) => mower.segmentId),
    [job]
  );

  const mowerIds = useMemo(() => getMowerIds(job), [job]);

  const mowerRouteData = useMemo(
    () => routeUploadedDataByMower(job, hasSegments),
    [job, hasSegments]
  );
  const ufons = useMemo(() => getUfons(job), [job]);

  useEffect(() => {
    if (mowerIds.length !== 0) {
      const activityObj: Record<number, boolean> = {};
      for (const id of mowerIds) {
        dispatch(startMowerTrackingAction({ mowerId: id }));
        activityObj[id] = true;
      }
      dispatch(setShowActivityByMower(activityObj));
    }
    if (ufons.length !== 0) {
      for (const ufon of ufons) {
        dispatch(setUfonData({ ufonData: ufon, ufonId: ufon.id }));
      }
    }
  }, [dispatch, mowerIds, ufons]);

  useEffect(() => {
    if (mowerRouteData) {
      const ids = Object.keys(mowerRouteData);
      for (const n of ids) {
        const id = parseInt(n);
        dispatch(
          setMowerRouteUploadedAction({
            mowerId: id,
            routeUploaded: mowerRouteData[id],
          })
        );
      }
    }
  }, [mowerRouteData, dispatch]);

  const [currentPage, setCurrentPage] = useState<JobState>(() =>
    getInitialPage(pageIndex)
  );

  const [mowerMan, setMowerMan] = useState<{
    id: number;
    name: string;
  } | null>(getMowerMan(userId, job));

  const [detailMower, setDetailMower] = useState<MowerLocalState | null>(
    getMowerById(mowerId, userId, job)
  );
  const getTabName = () => {
    if (currentPage === "mowerStatus") {
      return "STATUS";
    } else if (currentPage === "jobHistory") {
      return "SEGMENTS";
    }
    return "DEVICES";
  };

  useEffect(() => {
    updateSearchParams((prev) => {
      return {
        ...prev,
        pageIndex: Object.keys(jobState).indexOf(currentPage).toString(),
        userId: mowerMan?.id.toString() || null,
        mowerId: detailMower?.id.toString() || null,
        tab: tab || null,
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  useEffect(() => {
    if (job && job.latitude && job.longitude) {
      const { latitude, longitude } = job;
      if (latitude && longitude) {
        setCenter({ lat: latitude, lng: longitude });
      }
    }
  }, [job]);

  useEffect(() => {
    dispatch(setJobProgress({ mower: job.progress }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [job]);

  useEffect(() => {
    if (job.route) {
      dispatch(fetchRouteCoordinatesAction({ routeId: job.route?.id }));
    }

    if (hasSegments) {
      const lastSegments = getLastSegments(job, mowerMan?.id);
      dispatch(setLastSegments(lastSegments));
      dispatch(
        fetchSegmentsCoordinatesAction({
          jobId: job.id,
          segmentIds: jobSegmentIds,
          ...(mowerMan && { currentUserId: mowerMan.id }),
          lastSegments,
        })
      );
    } else {
      dispatch(clearSegmentsData());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [job]);

  const continueFromOverview = (user?: UserPreviewT) => {
    if (!user) {
      dispatch(setShowFullOverview(true));
      dispatch(setHidePageHeader(true));
      return;
    }

    setMowerMan({
      id: user.id,
      name: user.name,
    });

    if (isMultiMower) {
      setCurrentPage("mowerManStatus");
      return;
    }

    setDetailMower({
      name: job.users[0].mowers[0].name,
      id: job.users[0].mowers[0].id,
    });
    setCurrentPage("mowerStatus");
  };

  const continueBtnHandler = (user?: UserPreviewT) => {
    if (user) {
      continueFromOverview(user);
      return;
    }

    if (job.users.length > 1) {
      if (hasSegments) {
        setShowUserSelectModal(true);
      } else {
        continueFromOverview();
      }
      return;
    }

    continueFromOverview(job.users[0]);
  };

  const JobStateComponent = jobState[currentPage];

  const jobMap = (
    <div className={styles.map} id="mapElement">
      <JobMap
        job={job}
        hasSegments={hasSegments}
        center={center}
        routeId={job.route?.id}
        mowerIds={mowerIds}
        jobId={jobId}
        currentUserId={mowerMan?.id}
        setMowerMan={setMowerMan}
        setDetailMower={setDetailMower}
        setCurrentPage={setCurrentPage}
        isJobRefetching={isJobRefetching}
      />
    </div>
  );

  return (
    <>
      <JobPageHeader
        currentPage={currentPage}
        isMultiMower={isMultiMower}
        setCurrentPage={setCurrentPage}
        mowerMan={mowerMan}
        job={job}
        jobId={jobId}
        mower={detailMower || null}
        setDetailMower={setDetailMower}
        setMowerMan={setMowerMan}
        refetchJob={refetchJob}
        continueFromOverview={continueBtnHandler}
      />
      <TabsWithMapLayout
        tabName={getTabName()}
        mapComponent={jobMap}
        logComponent={
          currentPage === "mowerStatus" &&
          currentUser?.role.id === ROLE_IDS.developer ? (
            <Log />
          ) : undefined
        }
        onlyMobileTabs={currentPage === "segmentsOverview"}
      >
        {showUserSelectModal && (
          <UserSelectModal
            job={job}
            isOpen={showUserSelectModal}
            onClose={() => setShowUserSelectModal(false)}
            onSelectUser={continueFromOverview}
          />
        )}
        <JobStateComponent
          setCurrentPage={setCurrentPage}
          jobId={jobId}
          job={job}
          isMultiMower={isMultiMower}
          mowerMan={mowerMan}
          setMowerMan={setMowerMan}
          hasSegments={hasSegments}
          setDetailMower={setDetailMower}
          detailMower={detailMower}
          refetchJob={refetchJob}
          isJobRefetching={isJobRefetching}
          mapComponent={jobMap}
          continueFromOverview={continueBtnHandler}
        />
      </TabsWithMapLayout>
    </>
  );
};

export default React.memo(JobMainComponent);
