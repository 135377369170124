import { useJsApiLoader } from "@react-google-maps/api";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { GOOGLE_MAP_API_KEY } from "./env";
import AppRouter from "./Routing";
import { useSelector } from "./store/hooks";
import { startupSequenceAction } from "./store/slices/userSlice";
import { SplashScreen } from "./components/molecules/SplashScreen/SplashScreen";
import styles from "./app.module.scss";
import { PermissionOverlays } from "./components/organisms/PermissionOverlays/PermissionOverlays";

const App = () => {
  const dispatch = useDispatch();
  const isSplashScreenShowing = useSelector(
    (state) => state.user.isSplashScreenShowing
  );
  const [isOnline, setIsOnline] = useState(true);

  const { isLoaded: isMapLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: GOOGLE_MAP_API_KEY,
  });

  useEffect(() => {
    dispatch(startupSequenceAction());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setIsOnline(navigator.onLine);
    window.addEventListener("online", () => {
      setIsOnline(true);
    });
    window.addEventListener("offline", () => {
      setIsOnline(false);
    });

    return () => {
      window.removeEventListener("online", () => {
        setIsOnline(true);
      });
      window.removeEventListener("offline", () => {
        setIsOnline(false);
      });
    };
  }, []);

  if (isSplashScreenShowing || !isMapLoaded) {
    return <SplashScreen />;
  }

  return (
    <>
      {!isOnline && (
        <div className={styles.internetHealthBar}>No internet connection</div>
      )}
      <PermissionOverlays />
      <AppRouter />
    </>
  );
};

export default App;
