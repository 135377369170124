import React from "react";
import { useQueriesJourneyGet } from "../../../hooks/useQueries";
import JourneyPolylinesBase, {
  JourneyPolylinesPropsT,
} from "./JourneyPolylinesBase";

type Props = Omit<JourneyPolylinesPropsT, "journeys" | "isLoadingJourneys"> & {
  journeyIds: number[] | [];
};

export const JourneyPolylines = React.memo(
  ({ journeyIds, ...props }: Props) => {
    const [journey, isLoading] = useQueriesJourneyGet(journeyIds);
    return (
      <JourneyPolylinesBase
        journeys={journey}
        isLoadingJourneys={isLoading}
        {...props}
      />
    );
  }
);

export default JourneyPolylines;
