import React, { FC } from "react";
import { Control, FieldErrors, useFieldArray } from "react-hook-form";
import { SelectOptionsT } from "../../../types/Common";
import { Icon } from "../../atoms/Icon";
import { InputController } from "../Form/InputController";
import { JobCreateFormFieldsT } from "./FormJobCreate";
import styles from "./formJobCreate.module.scss";
interface Props {
  nestIndex: number;
  control: Control<JobCreateFormFieldsT, object>;
  errors: FieldErrors<JobCreateFormFieldsT>;
  mowerOptions: SelectOptionsT;
  isLoadingMowers: boolean;
  isLoadingGroup: boolean;
  trigger: () => void;
}

export const FormMowers: FC<Props> = ({
  nestIndex,
  control,
  errors,
  mowerOptions,
  isLoadingMowers,
  trigger,
}) => {
  const { fields, remove, append } = useFieldArray({
    control,
    name: `assign.${nestIndex}.mowers`,
  });

  return (
    <div>
      {fields.map((_, index) => {
        return (
          <div
            key={`mower-field-${index}`}
            className="d-flex align-items-start gap-2"
          >
            <InputController
              key={`assign-mower-${index}`}
              name={`assign.${nestIndex}.mowers.${index}.mowerId`}
              label="Mower"
              control={control}
              errors={errors}
              options={mowerOptions}
              input={{ disabled: isLoadingMowers }}
            />
            <div className={styles["icon-blue-wrapper"]}>
              {fields.length > 1 && (
                <Icon
                  icon="minus-circle"
                  onClick={() => {
                    remove(index);
                    trigger();
                  }}
                  className={styles["icon-blue"]}
                />
              )}
              {index === fields.length - 1 && (
                <Icon
                  icon="plus-circle"
                  onClick={() => append({ mowerId: "" })}
                  className={styles["icon-blue"]}
                />
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};
