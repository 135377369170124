import React from "react";
import { MowerT } from "../../../types/Mower";
import Log from "../../../components/molecules/LogNew/Log";

type PropsT = {
  mower: MowerT;
};

export default function MowerLogTab({ mower }: PropsT) {
  return <Log id={mower.id} />;
}
