import { FC } from "react";
import { Form, Input } from "reactstrap";
import classNames from "classnames";
import { StyledButton } from "../../../components/atoms/Button/Button";
import styles from "./measurementForm.module.scss";

type PropsT = {
  name: string;
  number: number;
  onNameChange: (name: string) => void;
  onNumberChange: (number: number) => void;
  onEdit: VoidFunction;
  onSubmit: VoidFunction;
  onReset: VoidFunction;
  onDelete: VoidFunction;
  measurementPossible: boolean;
  noMeasurements: boolean;
  disabled: boolean;
  editing: boolean;
  className?: string;
};

export const MeasurementForm: FC<PropsT> = ({
  name,
  number,
  onNameChange,
  onNumberChange,
  onEdit,
  onSubmit,
  onReset,
  onDelete,
  measurementPossible,
  noMeasurements,
  editing,
  disabled,
  className,
}: PropsT) => {
  let buttonTitle = "Waiting for precise location...";
  if (editing) {
    buttonTitle = "Save";
  } else if (measurementPossible) {
    buttonTitle = "Get measurement";
  }

  const measurementDisabled = !measurementPossible && !editing;

  return (
    <Form className={classNames(className, styles.form)}>
      <div className={styles.group}>
        <Input
          className={styles["group-main"]}
          disabled={disabled}
          placeholder="Set point name"
          value={name}
          onChange={(e) => onNameChange(e.target.value)}
        />
        <Input
          className={styles["group-secondary"]}
          disabled={disabled}
          value={number}
          onChange={(e) => {
            onNumberChange(Number(e.target.value));
          }}
        />
      </div>
      <div className={styles.group}>
        <StyledButton
          className={classNames(
            styles["group-main"],
            editing ? styles.editBtn : styles.submitBtn,
            measurementDisabled && styles.disabledButton
          )}
          disabled={measurementDisabled || disabled}
          color={"primary"}
          title={buttonTitle}
          onClick={editing ? onEdit : onSubmit}
        />
        {!noMeasurements && (
          <StyledButton
            className={styles["group-secondary"]}
            disabled={disabled}
            color="light"
            icon={editing ? "trash" : "reload-arrow"}
            onClick={editing ? onDelete : onReset}
          />
        )}
      </div>
    </Form>
  );
};
