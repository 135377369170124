import { UfonT } from "../../../types/Ufon";
import UfonLog from "../../../components/molecules/LogNew/UfonLog";

type PropsT = {
  ufon: UfonT;
};

export function UfonLogTab({ ufon }: PropsT) {
  return <UfonLog id={ufon.id} />;
}
