import React, { useState } from "react";
import { useParams, useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { StyledButton } from "../../components/atoms/Button/Button";
import SubHeader from "../../components/atoms/SubHeader/SubHeader";
// import { AssignedDevices } from "../../components/molecules/AssignedDevices/AssignedDevices";
// import { Log } from "../../components/molecules/Log/Log";
import { UserCard } from "../../components/molecules/UserCard/UserCard";
import { AssignDeviceModal } from "../../components/organisms/AssignDeviceModal/AssignDeviceModal";
import { Layout } from "../../components/organisms/Layout/Layout";
import { ROUTES } from "../../routes";
import { useQueryUserGet } from "../../hooks/useQueries";
import { useMutationUserDelete } from "../../hooks/useMutationUserDelete";
import { UserT } from "../../types/User";
import { queryClient } from "../..";
import { Loader } from "../../components/atoms/Loader/Loader";
import { WINDOW_TITLE_NAMES } from "../../constants";
import { useConfirm } from "../../components/molecules/ConfirmBoxProvider/ConfirmBoxProvider";

type Props = {};

export const UserDetailPage: React.FC<Props> = () => {
  document.title = WINDOW_TITLE_NAMES.users;
  const { userId } = useParams() as { userId: string };
  const navigate = useNavigate();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const confirm = useConfirm();
  const { data, isLoading } = useQueryUserGet(userId);

  const deleteUserMutation = useMutationUserDelete({
    onSuccess: () => {
      navigate(ROUTES.users());
    },
  });

  const onDelete = () => {
    confirm({
      title: "Remove user",
      subTitle: `Are you sure you want to delete this user, ${
        data?.data.firstName + " " + data?.data.surname
      }?`,
      asyncCallback: () => deleteUserMutation.mutateAsync(userId),
      type: "delete",
    });
  };

  const handleToggleModal = () => {
    setIsOpenModal((prev) => !prev);
  };

  const handleRefetchData = () => {
    queryClient.invalidateQueries(["user", userId]);
  };

  return (
    <Layout>
      <SubHeader
        title={
          data ? `${data.data.firstName} ${data.data.surname}` : "Loading..."
        }
        isDetail
        actions={
          <>
            <StyledButton
              icon="pen"
              color="dark"
              outline
              tag={Link}
              link={ROUTES.userEdit(userId)}
            />
            <StyledButton
              onClick={onDelete}
              icon="trash"
              color="dark"
              outline
            />
          </>
        }
      />
      {isLoading && <Loader isCentered />}
      {data?.data && (
        <>
          <div className={"p-4 d-flex flex-column gap-3"}>
            <UserCard user={data.data as UserT} />
            {/* <AssignedDevices devices={data.data.devices} />
            <Log logs={data.data.logs} /> */}
          </div>
        </>
      )}

      <AssignDeviceModal
        isOpen={isOpenModal}
        onClose={handleToggleModal}
        userId={userId}
        onSuccess={handleRefetchData}
      />
    </Layout>
  );
};
