import axios, { AxiosInstance } from "axios";
import { API_URL } from "../env";
import { getCompany, getTokens } from "../functions/localStorage";
import { logoutUser, rotateTokens } from "../functions/OAuth";
import mem from "mem";

export const axiosClient: AxiosInstance = axios.create({
  baseURL: API_URL,
});

const refreshToken = async () => {
  try {
    const tokens = await rotateTokens();
    if (!tokens.access_token) {
      logoutUser();
    }
    return tokens;
  } catch (err) {
    logoutUser();
  }
};

const MAX_AGE = 10000;

export const memoizedRefreshToken = mem(refreshToken, { maxAge: MAX_AGE });

axiosClient.interceptors.request.use(
  async (config) => {
    const tokens = getTokens();

    if (tokens?.accessToken) {
      config.headers = {
        ...config.headers,
        authorization: `Bearer ${tokens.accessToken}`,
      };
    }

    const companyId = getCompany();

    if (companyId !== null) {
      config.headers = {
        ...config.headers,
        companyId,
      };
    }

    return config;
  },
  (err) => Promise.reject(err.response.data)
);

axiosClient.interceptors.response.use(
  (response) => response,
  async (err) => {
    const config = err?.config;

    if (err?.response?.status === 401 && config.url === "/api/oauth2/token") {
      logoutUser();
      return Promise.reject(err.response.data);
    }

    if (err?.response?.status === 401 && !config?.send) {
      config.send = true;

      const tokens = await memoizedRefreshToken();
      if (tokens?.access_token) {
        config.headers = {
          ...config.headers,
          authorization: `Bearer ${tokens.access_token}`,
        };
      }

      return axiosClient(config);
    }
    return Promise.reject(err.response.data);
  }
);

export const client = axiosClient;
