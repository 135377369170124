import { FC, ReactNode } from "react";
import { Header } from "../../molecules/Header/Header";
import { ErrorBoundary, FallbackProps } from "react-error-boundary";
import { Button } from "reactstrap";
import classNames from "classnames";
import styles from "./layout.module.scss";
import ReloadButton from "../../atoms/ReloadButton/ReloadButton";

type Props = {
  children: ReactNode;
  className?: string;
  whiteBackground?: boolean;
  requireWS?: boolean;
  title?: string;
};

function ErrorFallback({ error, resetErrorBoundary }: FallbackProps) {
  return (
    <div>
      <p>Oops, something went wrong.</p>
      <pre>{error.message}</pre>
      <Button onClick={resetErrorBoundary}>Try again</Button>
    </div>
  );
}

export const Layout: FC<Props> = ({
  children,
  className,
  whiteBackground,
  requireWS = false,
  title,
}) => {
  return (
    <div
      className={classNames(
        className,
        styles.layout,
        whiteBackground && styles["layout--white-bg"]
      )}
    >
      <Header title={title} />
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <div className={styles.container}>{children}</div>
      </ErrorBoundary>
      {requireWS && <ReloadButton />}
    </div>
  );
};
