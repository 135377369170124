import React, { FC, useCallback, useEffect, useMemo } from "react";

import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { useForm } from "react-hook-form";
import { Card, CardBody, Form } from "reactstrap";
import { InputController } from "../Form/InputController";
import { formRouteCloneSchema } from "./validations";
import { Cards } from "../../atoms/Cards/Cards";
import { RouteGroupT, RouteT } from "../../../types/Route";
import { SelectOptionsT } from "../../../types/Common";

export type RouteCloneInputsT = {
  name: string;
  group: string;
  routeId: string;
};

type PropsT = {
  defaultValues?: RouteT;
  onTouch: () => void;
  onSubmit: (v: RouteCloneInputsT) => void;
  routeId: string;
  routeGroups?: RouteGroupT[];
  isDisabledAll?: boolean;
  isEditingMode?: boolean;
};

export const FormRouteClone: FC<PropsT> = ({
  onTouch,
  onSubmit,
  routeGroups,
  defaultValues,
  isDisabledAll,
  isEditingMode,
  routeId,
}) => {
  const getDefaultValues = useCallback(
    () => ({
      name: defaultValues?.name || "",
      group: `${defaultValues?.folderId}` || "",
      routeId: routeId,
    }),
    [defaultValues, routeId]
  );

  const selectRouteGroupsOptions = useMemo(
    () =>
      (
        [{ value: "", label: "Choose group", disabled: true }] as SelectOptionsT
      ).concat(
        (routeGroups || []).map((group) => ({
          label: group.name,
          value: group.id,
        }))
      ),
    [routeGroups]
  );

  const {
    handleSubmit,
    control,
    formState: { errors, isDirty },
    reset,
  } = useForm<RouteCloneInputsT>({
    resolver: yupResolver(formRouteCloneSchema(!!isEditingMode)),
    defaultValues: getDefaultValues(),
  });

  useEffect(() => {
    reset(getDefaultValues());
  }, [getDefaultValues, reset]);

  useEffect(() => {
    if (isDirty) {
      onTouch();
    }
  }, [onTouch, isDirty]);

  return (
    <Form onSubmit={handleSubmit(onSubmit)} id="route-form">
      <Cards>
        <Card>
          <CardBody>
            <InputController
              name="name"
              label="Name"
              control={control}
              errors={errors}
              input={{ disabled: isDisabledAll }}
            />
            {!isEditingMode && (
              <>
                <InputController
                  name="group"
                  label="Group"
                  control={control}
                  errors={errors}
                  options={selectRouteGroupsOptions}
                  input={{ disabled: isDisabledAll }}
                />
              </>
            )}
          </CardBody>
        </Card>
      </Cards>
    </Form>
  );
};
