import { useMutation } from "react-query";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { ROUTES } from "../routes";
import { client } from "../services/axios";
import { ApiErrorT, DeviceFormInput } from "../types/Common";
import { getMutationDeviceData } from "../functions/getMutationDeviceData";

export const useMutationMowerCreate = () => {
  const navigate = useNavigate();

  return useMutation(
    (data: DeviceFormInput) => {
      const dataToSend = getMutationDeviceData(data);
      return client.post(`/api/v1/mower`, {
        ...dataToSend,
        companyId: Number(data.companyId),
      });
    },
    {
      onSuccess: (data) => {
        if (data.data.id) {
          navigate(ROUTES.mower(data.data.id));
        }
        toast.success("Mower created");
      },
      onError: (error: ApiErrorT) => {
        toast.error(error?.message);
      },
    }
  );
};
