import classNames from "classnames";
import { DATE_FORMATS } from "../../../constants";
import { formatDate } from "../../../functions/formatDate";
import { useQueryUserGet } from "../../../hooks/useQueries";
import { NotificationT } from "../../../types/Notification";
import { Icon } from "../../atoms/Icon";
import styles from "./notificationItem.module.scss";

type PropsT = {
  notification: NotificationT;
  className?: string;
};

export function NotificationItem({ notification, className }: PropsT) {
  const { data: user } = useQueryUserGet(notification.userId);
  return (
    <div className={classNames(styles.item, className)}>
      <div className={styles.itemTitle}>{notification.subject}</div>
      <div className={styles.itemContent}>{notification.content}</div>
      <div className={styles.itemMetadata}>
        <div>
          <Icon className={styles.itemMetadataIcon} icon="calendar-alt" />
          <span>
            {formatDate(notification.createdAt, DATE_FORMATS.dateTimeMedium)}
          </span>
        </div>
        <div>
          <Icon className={styles.itemMetadataIcon} icon="user" />
          <span>
            {user
              ? user.data.firstName + " " + user.data.surname
              : "Loading..."}
          </span>
        </div>
      </div>
    </div>
  );
}
