import { IconsId } from "../assets/fonts/iconfont/icons";
import { DEVICE_STATUSES } from "../constants";
import { JobListItemT } from "./Job";
import { DeviceT, MowerPreviewT } from "./Mower";
import { UfonPreviewT } from "./Ufon";

export type DeviceStatusT = keyof typeof DEVICE_STATUSES;

export type DeviceOnlineStatusT = {
  isOnline: boolean;
  type: string;
};

export type MapMarkerT = Pick<
  DeviceT,
  "id" | "longitude" | "latitude" | "isOnline" | "lastUser"
> & {
  job?: JobListItemT;
  name?: string;
  type?: string;
};

export type MapMarkersT = {
  type: keyof typeof DeviceTypes | string;
  items: Array<MapMarkerT>;
};

export enum DeviceTypes {
  mower = "mower",
  ufon = "ufon",
}

export type DeviceListItemT =
  | (UfonPreviewT & {
      type: DeviceTypes;
    })
  | (MowerPreviewT & {
      type: DeviceTypes;
    });

export type ApiErrorT = {
  status: string;
  message: string;
};

export type ApiSuccessModal<T = never> = {
  success: boolean;
  modal?: {
    message: string;
    buttonTitle: string;
    buttonAction: string;
    method: "PUT" | "GET" | "DELETE" | "PATCH" | "POST";
    otherData?: T;
  };
};

export type OtherDataStartJob = {
  id: number;
  name: string;
  type: { id: number; name: string };
  users: { id: number; name: string }[];
};

export type SelectOptionsT = {
  label: string;
  value?: string | number;
  disabled?: boolean;
}[];

export type LocationT = {
  latitude: number;
  longitude: number;
};

export type PermissionNamesT =
  | "dashboardJobs"
  | "jobManage"
  | "jobUsersAll"
  | "routeManage"
  | "deviceManage"
  | "usersVisibility"
  | "companiesVisibility"
  | "deviceCreate"
  | "createDeveloper";

export type PermissionsT = Partial<Record<PermissionNamesT, boolean>>;

export type LedT = {
  color: string;
  borderColor: string;
  blink: boolean;
  count?: number;
  duration?: number;
};

export type StatusGroupT = {
  led: LedT;
  additionalValue?: string;
};

export type DeviceFormInput = {
  title: string;
  name: string;
  serialNumber: string;
  smautSerialNumber: string;
  spiderSerialNumber: string;
  companyId: string;
  purchaseDate: string;
};

export type DropdownItemConfig = {
  id: string;
  title: string;
  color?: string;
  selected: boolean;
  onSelect: VoidFunction;
  subItems?: DropdownItemConfig[];
  hasSeparator?: boolean;
  backgroundColor?: string;
};

export type DeviceCurrentInfo = {
  label: string;
  value?: string;
  textColor: string;
  icon?: {
    id: IconsId;
    color: string;
  };
};

export type DeviceActivityInfo = {
  label: string;
  data?: {
    value: string;
    linkTo: string;
    additionalValue?: string;
    email?: string;
  };
};

export type DropdownConfig = DropdownItemConfig[];

export type Tab = {
  label: string;
  iconId?: IconsId;
  hideOnDesktop: boolean;
};

export type ChartData = {
  value: number;
  color?: string;
  time: number;
};

export enum ChartType {
  correction = "correction",
  internet = "internet",
  position = "position",
  server = "server",
}

export enum CorrectionType {
  lte = "lte",
  radio = "radio",
}

export type CorrectionTypeT = keyof typeof CorrectionType;

export type ConnectionT = {
  correction: {
    type: CorrectionTypeT | "";
    value: boolean | null;
    chartData: ConnectionDatapoint<boolean>[];
  };
  internet: {
    providerName: string;
    signalStrength: number | null;
    chartData: ConnectionDatapoint<number>[];
  };
  position: {
    satellites: number | null;
    chartData: ConnectionDatapoint<number>[];
  };
  server: {
    isConnected: boolean | null;
    delay: number | null;
  };
};

export type ConnectionDatapoint<T> = {
  date: number;
  value: T;
};
