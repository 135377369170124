import { all, fork } from "redux-saga/effects";
import { rootAuthSaga } from "./authSaga";
import { rootMowerSaga } from "./mowerSaga";
import { rootTasksSaga } from "./tasksSaga";
import { rootJobRouteTypeSaga } from "./jobRouteTypeSaga";
import { rootUfonSaga } from "./ufonSaga";
import { rootGeostickSaga } from "./geostickSaga";

export function* rootSaga() {
  yield all([
    fork(rootTasksSaga),
    fork(rootAuthSaga),
    fork(rootMowerSaga),
    fork(rootJobRouteTypeSaga),
    fork(rootUfonSaga),
    fork(rootGeostickSaga),
  ]);
}
