import { FC, useState } from "react";
import { OtherDataStartJob } from "../../../types/Common";
import { OtherJobRow } from "./OtherJobRow";
import styles from "./otherJobsModal.module.scss";

type Props = {
  jobs: OtherDataStartJob[];
};

export const OtherJobsModal: FC<Props> = ({ jobs }) => {
  const [dynamicJobs, setDynamicJobs] = useState(jobs);

  const removeJob = (jobId: number) => {
    setDynamicJobs((prev) => prev.filter((job) => job.id !== jobId));
  };

  return (
    <div className={styles.wrapper}>
      {dynamicJobs.map((job) => (
        <OtherJobRow job={job} onComplete={removeJob} key={job.id} />
      ))}
    </div>
  );
};
