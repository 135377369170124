import { FC } from "react";
import { JOB_ROUTE_TYPE_COLORS } from "../../../constants";
import { DropdownConfig } from "../../../types/Common";
import Dropdown from "../../../components/molecules/Dropdown/Dropdown";

type PropsT = {
  measurements: Record<string, boolean>;
  handleToggle: (name: string) => void;
  resetLayers: VoidFunction;
};

export const DropdownComponent: FC<PropsT> = ({
  measurements,
  handleToggle,
  resetLayers,
}: PropsT) => {
  const config: DropdownConfig = Object.keys(measurements).map((name) => ({
    id: name,
    title: name,
    selected: measurements[name],
    onSelect: () => handleToggle(name),
    color: JOB_ROUTE_TYPE_COLORS.calculatedRoute,
  }));

  return <Dropdown config={config} resetLayers={resetLayers} />;
};
