import { JobCreateFormFieldsT } from "../../components/molecules/FormJobCreate/FormJobCreate";
import { DATE_FORMATS, JOB_TYPE_IDS } from "../../constants";
import { formatDate } from "../../functions/formatDate";
import { JobInfoStatusT, JobT } from "../../types/Job";
import { RouteT } from "../../types/Route";
import { UserPreviewT } from "../../types/User";

export const getMainUser = (users?: UserPreviewT[]): string | null => {
  const mainUser = users?.find((user) => user.mainUser);
  if (!mainUser) {
    return null;
  }
  return mainUser.id.toString();
};

export const getRoute = (status?: JobInfoStatusT) => {
  if (!status?.route) {
    return null;
  }
  return status.route[0].id.toString();
};

const getDefaultIds = (job: JobT) => {
  const defaultIds = {
    userId: "",
    mowerId: "",
    ufonId: "",
    roverUfonId: "",
  };
  const user = job.users[0];
  if (user) {
    defaultIds.userId = job.users[0].id.toString();
    const mower = user.mowers[0];
    if (mower) {
      defaultIds.mowerId = mower.id.toString();
      const ufon = mower.ufon;
      if (ufon) {
        defaultIds.ufonId = ufon.id.toString();
      }
    }
  }
  return defaultIds;
};

export const getDefaultValuesFromJob = (
  job: JobT,
  routeData?: RouteT
): JobCreateFormFieldsT => {
  const commonValues = {
    type: job.type.id.toString(),
    name: job.name,
    dueDate: formatDate(job.dueDate, DATE_FORMATS.dateForm),
    location: job.location,
    latitude: job.latitude || undefined,
    longitude: job.longitude || undefined,
    note: job.description || "",
  };
  if (job.type.name !== "Route") {
    const { userId, mowerId, ufonId, roverUfonId } = getDefaultIds(job);
    const objectToReturn = {
      ...commonValues,
      userId,
      mowerId,
      ufonId,
      roverUfonId,
      assign: [],
    };
    return objectToReturn;
  }
  const { ufonId, roverUfonId } = getDefaultIds(job);
  const obj = {
    ...commonValues,
    userId: "",
    assign: job.users.map((user) => ({
      userId: user.id.toString(),
      mowers: user.mowers.map((mower) => ({ mowerId: mower.id.toString() })),
    })),
    mowerId: "",
    ufonId,
    roverUfonId,
    routeId: routeData?.id.toString() || "",
    routeFolderId: routeData?.folderId.toString() || "",
  };
  return obj;
};

export const getDefaultValues = (
  type?: string | null,
  defaultRoute?: string | null,
  defaultRouteFolder?: string | null,
  defaultMower?: string | null,
  defaultUfon?: string | null,
  defaultRoverUfon?: string | null,
  defaultUser?: string | null
): JobCreateFormFieldsT => {
  return {
    type: type || JOB_TYPE_IDS.manual.toString(),
    name: "",
    dueDate: formatDate(new Date(), DATE_FORMATS.dateForm),
    routeId: defaultRoute || "",
    routeFolderId: defaultRouteFolder || "",
    userId: defaultUser || "",
    assign: [
      {
        userId: defaultUser || "",
        mowers: [
          {
            mowerId: defaultMower || "",
          },
        ],
      },
    ],
    location: "",
    latitude: undefined,
    longitude: undefined,
    mowerId: defaultMower || "",
    ufonId: defaultUfon || "",
    roverUfonId: defaultRoverUfon || "",
    note: "",
  };
};
