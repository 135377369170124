import React, { FC } from "react";
import { useNavigate } from "react-router";
import { DeviceListItemT, DeviceTypes } from "../../types/Common";
import { WINDOW_TITLE_NAMES } from "../../constants";
import { ROUTES } from "../../routes";
import { queryClient } from "../..";
import { useMutationDeleteMower } from "../../hooks/useMutationDeleteMower";
import { useMutationDeleteUfon } from "../../hooks/useMutationDeleteUfon";
import { useSearchParams } from "../../hooks/useSearchParam";
import { usePermissions } from "../../hooks/usePermissions";
import { useQueryDeviceListGet } from "../../hooks/useQueries";
import { getCompany } from "../../functions/localStorage";
import { getDeviceCount, getFilteredDevices } from "./functions";
import { DropdownButton } from "../../components/atoms/DropdownButton/DropdownButton";
import SubHeader from "../../components/atoms/SubHeader/SubHeader";
import { useConfirm } from "../../components/molecules/ConfirmBoxProvider/ConfirmBoxProvider";
import { DataGrid } from "../../components/molecules/DataGrid/DataGrid";
import { Layout } from "../../components/organisms/Layout/Layout";
import MenuWithLayout from "../../components/organisms/MenuWithLayout/MenuWithLayout";

type PropsT = {};

export type FilterTypes = keyof typeof DeviceTypes | "all";

export const DevicesPage: FC<PropsT> = () => {
  document.title = WINDOW_TITLE_NAMES.devices;
  const navigate = useNavigate();
  const permissions = usePermissions();
  const confirm = useConfirm();

  const isAdminSection = getCompany() === 0;

  const { getParamValue, updateSearchParams } = useSearchParams();
  const search = getParamValue("search");
  const selectedDevice = getParamValue("device");

  const onSearch = (searchString: string) => {
    updateSearchParams((prev) => ({ ...prev, search: searchString }));
    queryClient.invalidateQueries(["users", { type: searchString }]);
  };
  const { isLoading, data, refetch } = useQueryDeviceListGet(search || "");

  const deleteMowerMutation = useMutationDeleteMower({
    onSuccess: () => {
      queryClient.invalidateQueries(["mowers"]);
    },
  });
  const deleteUfonMutation = useMutationDeleteUfon({
    onSuccess: () => {
      queryClient.invalidateQueries(["ufons"]);
    },
  });

  const handleDelete = (device: DeviceListItemT) => {
    if (!permissions.deviceCreate) {
      confirm({
        title: "Contact support",
        subTitle: (
          <p>
            For deleting a device, please contact customer support on email:{" "}
            <a href="mailto:info@spidernewtech.com">info@spidernewtech.com</a>
          </p>
        ),
        callback: () => {},
        type: "ok",
      });
    } else {
      confirm({
        title: "Remove device",
        subTitle: `Are you sure you want to delete selected device, ${device.name}?`,
        asyncCallback:
          device.type === DeviceTypes.mower
            ? () => deleteMowerMutation.mutateAsync(device.id)
            : () => deleteUfonMutation.mutateAsync(device.id),
        type: "delete",
      });
    }
  };

  const handleFilter = (value: FilterTypes) => {
    if (value === "all") {
      updateSearchParams((prev) => ({ ...prev, search: null, device: null }));
    } else {
      updateSearchParams((prev) => ({ ...prev, device: value }));
    }
  };

  const menuItems = [
    {
      id: "all",
      title: "All devices",
      onClick: () => handleFilter("all"),
      itemCount: data?.data.totalCount || 0,
    },
    {
      id: DeviceTypes.mower,
      title: "Mowers",
      onClick: () => handleFilter(DeviceTypes.mower),
      itemCount: getDeviceCount("mower", data?.data.items),
    },
    {
      id: DeviceTypes.ufon,
      title: "Correction stations",
      onClick: () => handleFilter(DeviceTypes.ufon),
      itemCount: getDeviceCount("ufon", data?.data.items),
    },
  ];

  const showTooltip = () => {
    confirm({
      title: "Contact support",
      subTitle: (
        <p>
          For creating a new device, please contact customer support on email:{" "}
          <a href="mailto:info@spidernewtech.com">info@spidernewtech.com</a>
        </p>
      ),
      callback: () => {},
      type: "ok",
    });
  };

  const createActions = [
    {
      label: "Create mower",
      onClick: () => {
        if (permissions.deviceCreate) {
          navigate(ROUTES.mowerCreate());
        } else {
          showTooltip();
        }
      },
    },
    {
      label: "Create correction station",
      onClick: () => {
        if (permissions.deviceCreate) {
          navigate(ROUTES.ufonCreate());
        } else {
          showTooltip();
        }
      },
    },
  ];

  const getDropdownTitle = () => {
    switch (selectedDevice) {
      case "mower":
        return "Mowers";
      case "ufon":
        return "Correction stations";
      default:
        return "All devices";
    }
  };

  const getHeaders = () => {
    const headers = ["Type", "Device name"];
    if (isAdminSection) {
      headers.push("Companies");
    }
    return [...headers, "Status", "Last seen", "Last user"];
  };

  return (
    <Layout whiteBackground>
      <SubHeader
        title="Devices"
        titleDropdown={getDropdownTitle()}
        search={{
          placeholder: "Search device",
          onChange: onSearch,
        }}
        items={menuItems}
        activeItem={selectedDevice || "all"}
        actions={
          permissions.deviceManage ? (
            <DropdownButton
              toggleProps={{ icon: "plus-circle", onlyIconOnMobile: true }}
              menuProps={{ end: true }}
              actions={createActions}
            >
              New device
            </DropdownButton>
          ) : undefined
        }
      />

      <MenuWithLayout items={menuItems} activeItem={selectedDevice || "all"}>
        <DataGrid
          headers={getHeaders()}
          rowComponent={"DeviceGridRow"}
          data={getFilteredDevices(
            data?.data.items || [],
            (selectedDevice as FilterTypes) || "all"
          )}
          onRefetechData={() => refetch()}
          isLoading={isLoading}
          onDelete={handleDelete}
        />
      </MenuWithLayout>
    </Layout>
  );
};
