import React, { useRef } from "react";
import classNames from "classnames";
import { Link, useNavigate } from "react-router-dom";
import { UserT } from "../../../types/User";
import { ROUTES } from "../../../routes";
import { useObserveHeight } from "../../../hooks/useObserveHeight";
import { usePermissions } from "../../../hooks/usePermissions";
import { useCompactDesign } from "../../../hooks/useCompactDesign";
import { ActionButton } from "../../atoms/ActionButton/ActionButton";
import { ActionDivider } from "../../atoms/ActionDivider/ActionDivider";
import { Icon } from "../../atoms/Icon";
import UserInitials from "../UserInitials/UserInitials";
import { getCompanyNames } from "./function";
import styles from "./gridRow.module.scss";

type Props = {
  data: UserT;
  onDelete?: (item?: UserT) => void;
  onAccept?: (item?: UserT) => void;
  onReject?: (item?: UserT) => void;
};

export const UserGridRow: React.FC<Props> = ({
  data,
  onDelete,
  onAccept,
  onReject,
}) => {
  const navigate = useNavigate();
  const permissions = usePermissions();
  const userDetailLink = ROUTES.user(data.id);
  const pendingUserDetailLink = ROUTES.pendingUser(data.id);
  const userCopyLink = `${ROUTES.userCreate()}?userId=${data.id}`;
  const isCompact = useCompactDesign();

  const rowRef = useRef<HTMLTableRowElement>(null);
  const linkHeight = useObserveHeight(rowRef);

  const onCopy = () => {
    navigate(userCopyLink);
  };

  const handleDelete = () => {
    onDelete?.(data);
  };

  const link = data.isPendingUser ? pendingUserDetailLink : userDetailLink;

  const handleEdit = () => {
    navigate(ROUTES.userEdit(data.id));
  };

  const handleNewJob = () => {
    navigate(`${ROUTES.jobCreate()}?type=Manual&userId=${data.id}`);
  };

  const handleAccept = () => {
    if (onAccept) {
      onAccept(data);
    }
  };

  const handleReject = () => {
    if (onReject) {
      onReject(data);
    }
  };

  return (
    <tr
      ref={rowRef}
      className={isCompact ? styles["grid-row"] : styles["grid-row--large"]}
    >
      <td
        className={classNames(
          styles["grid-row__cell--head"],
          isCompact && styles["grid-row__cell--head--compact"],
          isCompact && styles["grid-row__cell--head-title--compact"]
        )}
      >
        <div
          className={classNames(
            styles["grid-row__cell--head-avatar"],
            !isCompact && styles["grid-row__cell--head-avatar--large"]
          )}
        >
          <UserInitials user={data} />
          <span className={styles["grid-row__cell-head-link"]}>
            {`${data.firstName} ${data.surname}`}
            {isCompact && (
              <Icon
                icon={"angle-right"}
                className={styles["grid-row__cell-head-icon"]}
              />
            )}
          </span>
        </div>
      </td>
      {permissions.companiesVisibility && (
        <td
          className={classNames(
            styles["grid-row__cell"],
            isCompact && styles["grid-row__cell--compact"],
            styles["grid-row__cell--12"]
          )}
        >
          {getCompanyNames(data.companies)}
        </td>
      )}
      {
        <td
          className={classNames(
            styles["grid-row__cell"],
            isCompact && styles["grid-row__cell--compact"],
            styles["grid-row__cell--12"]
          )}
        >
          {data.role.name}
        </td>
      }
      <td
        className={classNames(
          styles["grid-row__cell"],
          isCompact && styles["grid-row__cell--compact"],
          styles["grid-row__cell--12"]
        )}
      >
        {data.phone ?? "-"}
      </td>
      <td
        className={classNames(
          styles["grid-row__cell"],
          isCompact && styles["grid-row__cell--compact"],
          styles["grid-row__cell--12"],
          !isCompact && styles["grid-row__cell--email--large"]
        )}
      >
        {data.email}
        <div className={styles.actions}>
          {data.isPendingUser ? (
            <>
              <ActionButton
                onClick={handleReject}
                icon="times"
                tagTitle="Reject"
              />
              <ActionDivider />
              <ActionButton
                onClick={handleAccept}
                icon="check"
                tagTitle="Accept"
              />
            </>
          ) : (
            <>
              <ActionButton
                onClick={handleNewJob}
                icon="play-circle"
                tagTitle="Create job"
              />
              <ActionDivider />
              <ActionButton onClick={onCopy} icon="clone" tagTitle="Copy" />
              <ActionButton onClick={handleEdit} icon="pen" tagTitle="Edit" />
              <ActionButton
                onClick={handleDelete}
                icon="trash"
                tagTitle="Delete"
              />
            </>
          )}
        </div>
      </td>
      <td className={styles.superLinkCell} style={{ height: linkHeight }}>
        <Link to={link} className={styles.superLink} />
      </td>
    </tr>
  );
};
