import { FC } from "react";
import classNames from "classnames";
import { useCompactDesign } from "../../../hooks/useCompactDesign";
import { DescriptionTerm } from "../DescriptionTerm/DescriptionTerm";
import { Icon } from "../Icon";
import styles from "./descriptionItem.module.scss";

type PropsT = {
  title?: string;
  showArrow?: boolean;
  onClick?: () => void;
};

export const DescriptionItem: FC<PropsT> = ({
  title,
  showArrow = true,
  onClick,
  children,
}) => {
  const isCompact = useCompactDesign();
  return (
    <div onClick={onClick} className={styles["description-item"]}>
      {title && <DescriptionTerm title={title} />}
      <dd
        className={classNames(styles["description-item__content"], {
          [styles["description-item__content--compact"]]: isCompact,
        })}
      >
        {children}
        {showArrow && (
          <Icon
            className={styles["description-item__icon"]}
            icon="chevron-right"
          />
        )}
      </dd>
    </div>
  );
};
