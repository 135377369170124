import React, { FC, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Container } from "reactstrap";
import { StyledButton } from "../../components/atoms/Button/Button";
import { PageWrapper } from "../../components/atoms/PageWrapper/PageWrapper";
import SubHeader from "../../components/atoms/SubHeader/SubHeader";

import {
  FormRoute,
  RouteInputsT,
} from "../../components/molecules/FormRoute/FormRoute";

import { Layout } from "../../components/organisms/Layout/Layout";
import { WINDOW_TITLE_NAMES } from "../../constants";

import { useMutationRouteCreate } from "../../hooks/useMutationRouteCreate";
import {
  useQueryRouteGet,
  useQueryRouteGroupListGet,
} from "../../hooks/useQueries";

export const RouteCreatePage: FC = () => {
  document.title = WINDOW_TITLE_NAMES.routes;
  const [isFormTouched, setIsFormTouched] = useState(false);
  const navigate = useNavigate();
  const { data: routeGroups } = useQueryRouteGroupListGet();
  const routeCreateMutation = useMutationRouteCreate();

  const [searchParams] = useSearchParams();
  const routeId = searchParams.get("routeId");
  const { data: routeData, isLoading } = useQueryRouteGet(routeId);

  const handleSubmit = (values: RouteInputsT) => {
    routeCreateMutation.mutate(values);
  };

  return (
    <Layout>
      <SubHeader
        isDetail
        title="New route - upload .kml"
        actions={
          <>
            <StyledButton
              onClick={() => navigate(-1)}
              color="dark"
              outline
              title="Cancel"
            />
            <StyledButton
              disabled={!isFormTouched}
              color="primary"
              type="submit"
              form="route-form"
            >
              {!routeCreateMutation.isLoading ? "Create" : "Saving"}
            </StyledButton>
          </>
        }
      />
      <PageWrapper>
        <Container>
          <FormRoute
            onTouch={() => setIsFormTouched(true)}
            onSubmit={handleSubmit}
            routeGroups={routeGroups?.data.items}
            defaultValues={routeData?.data}
            isDisabledAll={isLoading}
          />
        </Container>
      </PageWrapper>
    </Layout>
  );
};
