import { useInfiniteQuery, useQueries, useQuery } from "react-query";
import { ITEMS_PER_PAGE } from "../constants";
import { client } from "../services/axios";
import { ApiErrorT, DeviceListItemT, LocationT } from "../types/Common";
import { CompanyT } from "../types/Company";
import {
  FilterCountT,
  GeostickJobT,
  JobCommentT,
  JobHistory,
  JobListItemT,
  JobSegmentsT,
  JobT,
  JobTypeT,
  JobUserTaskT,
  JobsList,
  MissedCoorT,
  UsersBySegment,
} from "../types/Job";
import { LeicaGroupT, LeicaT } from "../types/Leica";
import {
  DeviceGroupT,
  LogListT,
  MowerConnectionT,
  MowerStatusT,
  MowerT,
} from "../types/Mower";
import {
  CoordinatesRawT,
  CoordinatesT,
  GrassCuttingHeight,
  PointInfoT,
  RouteGroupT,
  RouteT,
} from "../types/Route";
import { UfonStatusT, UfonT } from "../types/Ufon";
import { UserCurrentT, UserT, RoleT, PendingUserT } from "../types/User";
import {
  StatisticsOptionsQueryT,
  StatisticsOptionsResponseT,
  StatisticsQueryT,
  StatisticsResponseT,
} from "../types/Statistics";
import {
  NotificationListQueryT,
  NotificationListT,
} from "../types/Notification";
import { createQueryString } from "../functions/routing";

// Dashboard

export const useQueryDashboardDataGet = (
  active: boolean,
  limit?: number,
  offset?: number
) => {
  const url = `/api/v1/dashboard${createQueryString({
    limit: limit?.toString(),
    offset: offset?.toString(),
    status: active ? "active" : undefined,
  })}`;
  return useQuery<
    {
      data: {
        jobs: { items: Omit<JobListItemT, "users">[] };
        map: DeviceGroupT[];
      };
    },
    Object
  >("devices", () => client.get(url));
};

export const useQueryJobDashboardGet = (owner: "my" | "delegated" | null) => {
  const url =
    "/api/v1/job/list" +
    createQueryString({ limit: 7, offset: 0, jobOwner: owner });
  return useQuery<{
    data: JobsList;
  }>(["jobs", { type: owner }], () => client.get(url));
};

// User
export const useQueryUserCurrentGet = (token: string | null) =>
  useQuery<{ data: UserCurrentT }>(
    ["currentUser", token ? 1 : 0],
    () => client.get("/api/v1/user/current"),
    {
      enabled: Boolean(token),
    }
  );

export const useQueryUserGet = (userId: string | number | null) =>
  useQuery<{ data: UserT }, Object>(
    ["user", userId],
    () => client.get(`/api/v1/user/${userId}`),
    { enabled: Boolean(userId) }
  );

export const useQueryUsersGet = (search?: string) =>
  useQuery<{
    data: { items: UserT[]; totalCount: number };
  }>(["users", { type: search }], () =>
    client.get("/api/v1/user/list" + createQueryString({ search }))
  );

export const useQueryPendingUsersGet = (search?: string) =>
  useQuery<{ data: { items: PendingUserT[]; totalCount: number } }>(
    ["pending-users", { type: search }],
    () => client.get("/api/v1/user/pending" + createQueryString({ search }))
  );

export const useQueryRegistrationDataGet = (token?: string) =>
  useQuery<{ data: { companies: CompanyT[]; email: string } }>(
    ["registration-data"],
    () => client.get(`/api/v1/public-user/registration-data/${token}`),
    { enabled: Boolean(token) }
  );

export const useQueryRolesGet = () =>
  useQuery<{
    data: { items: RoleT[] };
  }>(["users"], () => client.get("/api/v1/role/list"));

// Mower
export const useQueryMowerGet = (mowerId?: number | string | null) =>
  useQuery<{ data: MowerT }, Object>(
    ["mower", mowerId],
    () => client.get(`/api/v1/mower/${mowerId}`),
    { enabled: Boolean(mowerId) }
  );

export const useQueryMowerStatusGet = (mowerId?: string | number | null) =>
  useQuery<{ data: MowerStatusT }, ApiErrorT>(
    ["mowerStatus", mowerId],
    () => client.get(`/api/v1/mower/${mowerId}/status`),
    { enabled: Boolean(mowerId) }
  );

export const useQueryMowerListGet = (search?: string) =>
  useQuery<{
    data: { items: DeviceListItemT[]; totalCount: number };
  }>(["mowers", { type: search }], () =>
    client.get("/api/v1/mower/list" + createQueryString({ search }))
  );

export const useQuerySegmentCoordinates = (
  jobId?: string | number | null,
  segmentId?: string | number | null
) =>
  useQuery<{ data: CoordinatesT<"route"> }, Object>(
    ["segmentcoordinates", segmentId],
    () =>
      client.get(
        `/api/v1/job/${jobId}/coordinates` + createQueryString({ segmentId })
      ),
    {
      enabled: Boolean(segmentId) && Boolean(jobId),
    }
  );

export const useQueryMowerLogGet = (
  mowerId: number | string | null,
  limit?: number,
  offset?: number
) => {
  return useQuery<{ data: { items: Object[]; totalCount: number } }, ApiErrorT>(
    ["mowerLog", offset],
    () =>
      client.get(
        `/api/v1/mower/${mowerId}/raw-data` +
          createQueryString({ limit, offset })
      ),
    { enabled: Boolean(mowerId) }
  );
};

export const useQueryMowerConnectionGet = (mowerId?: number | string) => {
  return useQuery<{ data: MowerConnectionT }>(
    ["mowerConnection", mowerId],
    () => client.get(`/api/v1/mower/${mowerId}/connection`),
    { enabled: Boolean(mowerId) }
  );
};

export const useQueryMowerLogList = (mowerId?: number | string) => {
  return useQuery<{ data: LogListT }>(
    ["mowerLogList", mowerId],
    () => client.get(`/api/v1/mower/${mowerId}/log/list`),
    { enabled: Boolean(mowerId) }
  );
};

// Ufon
export const useQueryUfonGet = (ufonId?: string | number | null) =>
  useQuery<{ data: UfonT }, Object>(
    ["ufon", ufonId],
    () => client.get(`/api/v1/ufon/${ufonId}`),
    { enabled: Boolean(ufonId) }
  );

export const useQueryUfonStatusGet = (ufonId?: string | number | null) =>
  useQuery<{ data: UfonStatusT }, ApiErrorT>(
    ["ufonStatus", ufonId],
    () => client.get(`/api/v1/ufon/${ufonId}/status`),
    { enabled: Boolean(ufonId) }
  );

export const useQueryUfonListGet = (search?: string) =>
  useQuery<{
    data: { items: DeviceListItemT[]; totalCount: number };
  }>(["ufons", { type: search }], () =>
    client.get("/api/v1/ufon/list" + createQueryString({ search }))
  );

export const useQueryUfonLogGet = (
  ufonId: number | string | null,
  limit?: number,
  offset?: number
) => {
  return useQuery<{ data: { items: Object[]; totalCount: number } }, ApiErrorT>(
    ["mowerLog", offset],
    () =>
      client.get(
        `/api/v1/ufon/${ufonId}/raw-data` + createQueryString({ limit, offset })
      ),
    { enabled: Boolean(ufonId) }
  );
};
// Devices
export const useQueryDeviceListGet = (search?: string) =>
  useQuery<{
    data: { items: DeviceListItemT[]; totalCount: number };
  }>(["mowers", { type: search }], () =>
    client.get(`/api/v1/device/list${createQueryString({ search })}`)
  );

// Route
export const useQueryRouteGet = (routeId: string | number | null | undefined) =>
  useQuery<{ data: RouteT }, Object>(
    ["route", routeId],
    () => client.get(`/api/v1/route/${routeId}`),
    { enabled: Boolean(routeId) }
  );

export const useQueryRouteListGet = () =>
  useQuery<{
    data: { items: RouteT[] };
  }>(["routes"], () => client.get(`/api/v1/route/list`));

export const useQueryRouteListInfiniteGet = (
  search: string,
  folderId: string | null,
  location: LocationT | null,
  archive: string | null,
  orderBy: "nearest" | "lastUsed" | null
) => {
  const latitude = location?.latitude.toFixed(6);
  const longitude = location?.longitude.toFixed(6);
  return useInfiniteQuery<{
    data: { items: RouteT[]; totalCount: number };
  }>(
    [
      "route",
      {
        type: search,
        folderId,
        lat: location?.latitude,
        lng: location?.longitude,
        archive,
      },
    ],
    async ({ pageParam = 0 }) => {
      const res = await client.get(
        "/api/v1/route/list" +
          createQueryString({
            folderId,
            mode: archive,
            limit: ITEMS_PER_PAGE,
            offset: pageParam * ITEMS_PER_PAGE,
            latitude,
            longitude,
            search,
            orderBy,
          })
      );
      return res;
    }
  );
};

export const useQueryCoordinatesGet = (routeId?: string | number | null) =>
  useQuery<{ data: CoordinatesT<"route"> }, Object>(
    ["coordinates", routeId],
    () => client.get(`/api/v1/route/${routeId}/coordinates`),
    {
      enabled: !!routeId,
    }
  );

export const useQueryRouteGroupListGet = () =>
  useQuery<{
    data: { items: RouteGroupT[] };
  }>(["group"], () => client.get(`/api/v1/route-folder/list`));

export const useQueryRouteMowerGrassHeightGet = () =>
  useQuery<{ data: { items: GrassCuttingHeight[] } }>(["grass-height"], () =>
    client.get("/api/v1/route/mower-grass-height")
  );

export const useQueryRouteJobsGet = (routeId: string | number | null) =>
  useQuery<
    {
      data: { items: JobListItemT[]; filterCount: FilterCountT };
    },
    Object
  >(["jobs", routeId], () => client.get(`/api/v1/route/${routeId}/jobs`), {
    enabled: Boolean(routeId),
  });

// Journey
export const useQueriesJourneyGet = (
  journeysId?: string[] | number[]
): [CoordinatesRawT[], boolean] => {
  const journeyIds = journeysId ?? [];
  const results = useQueries(
    journeyIds.map((journeyId) => {
      return {
        queryKey: ["journey", journeyId],
        queryFn: () =>
          client.get<CoordinatesRawT>(
            `/api/v1/journey/${journeyId}/coordinates`
          ),
        enabled: Boolean(journeyId),
      };
    })
  );

  const isLoading = results.some((result) => result.isLoading);
  const resultData = results.reduce((acc: CoordinatesRawT[], result) => {
    if (result.data?.data) {
      acc.push(result.data.data);
      return acc;
    }
    return acc;
  }, []);

  return [resultData, isLoading];
};

export const useQueryJourneyPositionGet = (pointId?: number) =>
  useQuery<{ data: PointInfoT }>(
    ["point", pointId],
    () => client.get(`/api/v1/journey/position/${pointId}`),
    { enabled: Boolean(pointId) }
  );

// Job journey
export const useQueryJourneyGet = (journeyId: string | number | null) =>
  useQuery<{ data: CoordinatesRawT }>(
    ["journey", journeyId],
    () => client.get(`/api/v1/journey/${journeyId}/coordinates`),
    { enabled: Boolean(journeyId) }
  );

// Leicas
export const useQueryLeicaListInfiniteGet = (
  search: string,
  folderId: number,
  location: LocationT | null,
  orderBy: "nearest" | "lastUsed" | null
) => {
  const latitude = location?.latitude.toFixed(6);
  const longitude = location?.longitude.toFixed(6);

  return useInfiniteQuery<{
    data: { items: RouteT[]; totalCount: number };
  }>(
    [
      "leicas",
      {
        type: search,
        folderId,
        lat: location?.latitude,
        lng: location?.longitude,
      },
    ],
    async ({ pageParam = 0 }) => {
      const res = await client.get(
        "/api/v1/leica/list" +
          createQueryString({
            folderId,
            limit: ITEMS_PER_PAGE,
            offset: pageParam * ITEMS_PER_PAGE,
            search,
            latitude,
            longitude,
            orderBy,
          })
      );
      return res;
    }
  );
};

export const useQueryLeicaGroupListGet = () =>
  useQuery<{
    data: { items: LeicaGroupT[] };
  }>(["leicasGroup"], () => client.get(`/api/v1/leica-folder/list`));

export const useQueryLeicaGet = (leicaId: string | number) =>
  useQuery<{ data: LeicaT }, Object>(["leica", leicaId], () =>
    client.get(`/api/v1/leica/${leicaId}/coordinates`)
  );

// Job
export const useQueryJobGet = (jobId: string | number | null) =>
  useQuery<{ data: JobT | GeostickJobT }>(
    ["job", jobId],
    () => client.get(`/api/v1/job/${jobId}`),
    { enabled: Boolean(jobId) }
  );
export const useQueryJobHistoryGet = (jobId: string | number | null) =>
  useQuery<{ data: JobHistory[] }>(["jobHistory"], () =>
    client.get(`/api/v1/job/${jobId}/history`)
  );
export const useQueryJobTaskGet = (
  jobId: string | number,
  userId?: string | number
) =>
  useQuery<{ data: JobUserTaskT }>(
    ["jobTask", jobId, userId],
    () => client.get(`/api/v1/job/${jobId}/task/${userId}`),
    { enabled: Boolean(userId && jobId) }
  );

export const useQueryJobTypesGet = () =>
  useQuery<{
    data: { items: JobTypeT[] };
  }>(["jobTypes"], () => client.get("/api/v1/job/type"));

export const useQueryJobCommentsGet = (jobId: string | number) =>
  useQuery<{
    data: JobCommentT[];
  }>(["jobComments"], () => client.get(`/api/v1/job/${jobId}/comment`));

export const useQueryJobSegmentsGet = (jobId: string | number) =>
  useQuery<{ data: JobSegmentsT }>(["jobSegments"], () =>
    client.get(`/api/v1/job/${jobId}/segments`)
  );

export const useQueryJobSegmentListGet = (jobId: string | number) =>
  useQuery<{
    data: UsersBySegment[];
  }>(["jobSegmentList"], () =>
    client.get(`/api/v1/job/${jobId}/segments/list`)
  );

export const useQueryMissedCoordinates = (
  jobId: string | number,
  mowerId: string | number,
  segmentId?: string | number
) =>
  useQuery<{ data: MissedCoorT[] }>(
    ["missedCoordinates", jobId, mowerId, segmentId],
    () =>
      client.get(
        `/api/v1/job/${jobId}/missed-coordinates` +
          createQueryString({ mowerId, segmentId })
      )
  );

// Company
export const useQueryCompaniesGet = () =>
  useQuery<{
    data: { items: CompanyT[] };
  }>(["companies"], () => client.get(`/api/v1/company/list`));

export const useQueryStatisticsPost = (query: StatisticsQueryT) =>
  useQuery<{ data: StatisticsResponseT }>(["statistics", query], () =>
    client.post("/api/v1/statistics", query)
  );

export const useQueryStatisticsOptionsPost = (query: StatisticsOptionsQueryT) =>
  useQuery<{ data: StatisticsOptionsResponseT }>(
    ["statisticsOptions", query],
    () => client.post("/api/v1/statistics/options", query)
  );

// Notification
export const useQueryNotificationPaginatedList = (
  page: number,
  query: NotificationListQueryT
) =>
  useQuery<{ data: NotificationListT }>(
    [
      "notifications",
      {
        ...query,
        page,
        createdAtFrom: query.createdAtFrom?.toISOString(),
        createdAtTo: query.createdAtTo?.toISOString(),
      },
    ],
    async () => {
      const res = await client.get(
        "/api/v1/notification/list" +
          createQueryString({
            ...query,
            isRead:
              query.isRead !== undefined ? String(query.isRead) : undefined,
            limit: ITEMS_PER_PAGE,
            offset: page * ITEMS_PER_PAGE,
          })
      );
      return res;
    },
    {
      keepPreviousData: true,
    }
  );

export const useQueryNotificationInfiniteList = (
  query: NotificationListQueryT
) =>
  useInfiniteQuery<{ data: NotificationListT }>(
    [
      "notifications",
      {
        ...query,
        createdAtFrom: query.createdAtFrom?.toISOString(),
        createdAtTo: query.createdAtTo?.toISOString(),
      },
    ],
    async ({ pageParam = 0 }) => {
      const res = await client.get(
        "/api/v1/notification/list" +
          createQueryString({
            ...query,
            isRead:
              query.isRead !== undefined ? String(query.isRead) : undefined,
            limit: ITEMS_PER_PAGE,
            offset: pageParam * ITEMS_PER_PAGE,
          })
      );
      return res;
    }
  );
