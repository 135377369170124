import { JobProgress } from "../../../types/Job";

export const getJobStatus = (
  job: { mower: JobProgress[] } | null,
  mowerId?: number,
  segmentId?: number
) => {
  if (!job) return null;
  if (mowerId) {
    const currentMower = job.mower.find((mower) => mower.mowerId === mowerId);

    if (!currentMower) return null;

    if (!segmentId) {
      return {
        donePercent: currentMower.overall.done.percent,
        remaining: currentMower.overall.remaining,
      };
    } else {
      const currentSegment = currentMower.segments?.find(
        (segment) => segment.segmentId === segmentId
      );
      if (!currentSegment) return null;
      return {
        donePercent: currentSegment.progress.done.percent,
        remaining: currentSegment.progress.remaining,
      };
    }
  } else {
    if (job.mower.length === 0) return null;

    const donePercent =
      job.mower.reduce((acc, curr) => {
        acc += curr.overall.done.percent;
        return acc;
      }, 0) / job.mower.length;

    const remaining = job.mower.reduce(
      (acc, curr) => {
        acc.distance += curr.overall.remaining.distance;
        if (curr.overall.remaining.minutes > acc.minutes)
          acc.minutes = curr.overall.remaining.minutes;
        return acc;
      },
      { distance: 0, minutes: 0 }
    );

    return {
      donePercent,
      remaining,
    };
  }
};
