import React, { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Container } from "reactstrap";
import { StyledButton } from "../../components/atoms/Button/Button";
import { PageWrapper } from "../../components/atoms/PageWrapper/PageWrapper";
import SubHeader from "../../components/atoms/SubHeader/SubHeader";
import {
  FormLeica,
  LeicaInputsT,
} from "../../components/molecules/FormLeica/FormLeica";

import { Layout } from "../../components/organisms/Layout/Layout";
import { WINDOW_TITLE_NAMES } from "../../constants";
import { useMutationLeicaCreate } from "../../hooks/useMutationLeicaCreate";
import { useQueryLeicaGroupListGet } from "../../hooks/useQueries";

export const LeicaCreatePage: FC = () => {
  document.title = WINDOW_TITLE_NAMES.routes;
  const navigate = useNavigate();
  const [isFormTouched, setIsFormTouched] = useState(false);

  // TODO - update when endpoint for ufons point is available
  // const [searchParams] = useSearchParams();
  // const leicaId = searchParams.get("leicaId");
  // const { data: ufon, isLoading } = useQueryUfonGet(leicaId);

  const { data: leicaGroups } = useQueryLeicaGroupListGet();
  const leicaCreateMutation = useMutationLeicaCreate();

  const handleSubmit = (values: LeicaInputsT) => {
    leicaCreateMutation.mutate(values);
  };

  return (
    <Layout>
      <SubHeader
        isDetail
        title="New leica - upload .csv"
        actions={
          <>
            <StyledButton
              onClick={() => navigate(-1)}
              color="dark"
              outline
              title="Cancel"
            />
            <StyledButton
              disabled={!isFormTouched}
              color="primary"
              type="submit"
              form="leica-form"
            >
              {!leicaCreateMutation.isLoading ? "Create" : "Saving"}
            </StyledButton>
          </>
        }
      />
      <PageWrapper>
        <Container>
          <FormLeica
            onTouch={() => setIsFormTouched(true)}
            onSubmit={handleSubmit}
            leicaGroups={leicaGroups?.data.items}
            // isDisabledAll={isLoading}
          />
        </Container>
      </PageWrapper>
    </Layout>
  );
};
