import { useState } from "react";
import classNames from "classnames";
import { DropdownConfig, DropdownItemConfig } from "../../../types/Common";
import { DropdownContext } from "./dropdownContext";
import DropdownToggle from "./DropdownToggle";
import styles from "./mapDropdown.module.scss";
import { DropdownMenu } from "./DropdownMenu";
import { Modal, ModalBody } from "reactstrap";

type Props = {
  config: DropdownConfig;
  resetLayers: VoidFunction;
};

function Dropdown({ config, resetLayers }: Props) {
  const [showMenu, setShowMenu] = useState(false);
  const [openSubmenu, setOpenSubmenu] = useState<string[]>([]);

  const contextValue = {
    showMenu,
    setShowMenu,
    openSubmenu,
    setOpenSubmenu,
  };

  const currentItems = openSubmenu.reduce<DropdownItemConfig[] | undefined>(
    (items: DropdownItemConfig[] | undefined, id: string) =>
      items?.find((item) => item.id === id)?.subItems,
    config
  );

  const parentItem =
    openSubmenu.length > 0
      ? openSubmenu.slice(0, -1).reduce<DropdownItemConfig | undefined>(
          (currItem: DropdownItemConfig | undefined, id: string) =>
            currItem?.subItems?.find((item) => item.id === id),
          config.find((item) => item.id === openSubmenu[0])
        )
      : undefined;

  return (
    <DropdownContext.Provider value={contextValue}>
      <div className={styles.dropdown} id="dropdown">
        <DropdownToggle
          label="Layers"
          setShowMenu={() => {
            setShowMenu((prev) => !prev);
            setOpenSubmenu([]);
          }}
        />
        <Modal
          isOpen={showMenu}
          toggle={() => setShowMenu((prev) => !prev)}
          size="lg"
        >
          <ModalBody className={styles["modal-body"]}>
            <div className={classNames(styles.menu)}>
              <DropdownMenu
                items={currentItems}
                parentItem={parentItem}
                resetView={resetLayers}
              />
            </div>
          </ModalBody>
        </Modal>
      </div>
    </DropdownContext.Provider>
  );
}

export default Dropdown;
