import { string, object, array } from "yup";
import { VALIDATIONS } from "../../../constants";

const phoneRegExp = /^\+[1-9][0-9]{6,14}$/;

export const getFormUserSchema = (editingMode: boolean) =>
  object({
    firstName: string().required(VALIDATIONS.required),
    surname: string().required(VALIDATIONS.required),
    email: string().required(VALIDATIONS.required).email(VALIDATIONS.email),
    phone: string().matches(phoneRegExp, VALIDATIONS.phone),
    company: array()
      .of(
        object().shape({
          companyId: string().required(VALIDATIONS.required),
        })
      )
      .required(VALIDATIONS.required),
    password: editingMode ? string() : string(),
  }).required();
