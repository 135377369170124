import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { UserRegisterFieldsT } from "../pages/UserRegister/UserRegisterForm";
import { client } from "../services/axios";
import { ApiErrorT } from "../types/Common";
import { MutationOptionsT } from "../types/ReactQuery";

export const useMutationUserRegister = (options?: MutationOptionsT) => {
  return useMutation(
    (data: Omit<UserRegisterFieldsT, "cpassword">) => {
      return client.post(`/api/v1/public-user/register`, data);
    },
    {
      onSuccess: () => {
        toast.success(`Registration was successful`);
        if (options?.onSuccess) {
          options.onSuccess();
        }
      },
      onError: (error: ApiErrorT) => {
        toast.error(error?.message);
      },
    }
  );
};
