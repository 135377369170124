import React from "react";
import { NearbyMowerT } from "../../../types/Mower";
import { DescriptionList } from "../../../components/atoms/DescriptionList/DescriptionList";
import { DescriptionTerm } from "../../../components/atoms/DescriptionTerm/DescriptionTerm";
import { NearbyMowerRow } from "../../../components/molecules/NearbyMowerRow/NearbyMowerRow";
import { DescriptionItem } from "../../../components/atoms/DescriptionItem/DescriptionItem";

type PropsT = {
  nearbyMowers: NearbyMowerT[];
};

export const NearbyMowersList: React.FC<PropsT> = ({
  nearbyMowers,
}: PropsT) => {
  return (
    <DescriptionList>
      <DescriptionTerm title="Nearby mowers" />
      {nearbyMowers.length > 0 ? (
        <>
          {nearbyMowers.map((mower) => (
            <NearbyMowerRow key={mower.mower.id} mower={mower} />
          ))}
        </>
      ) : (
        <DescriptionItem showArrow={false}>
          No mowers are currently nearby
        </DescriptionItem>
      )}
    </DescriptionList>
  );
};
