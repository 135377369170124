import { array, object, string } from "yup";
import { VALIDATIONS } from "../../constants";

export const mergeJobsSchema = object({
  name: string().required(VALIDATIONS.required),
  mowerUserPairs: array().of(
    object().shape({
      userId: string().required(VALIDATIONS.required),
      mowerId: string().required(VALIDATIONS.required),
    })
  ),
}).required();

export const editJobSchema = object({
  name: string().required(VALIDATIONS.required),
}).required();
