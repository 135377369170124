import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { client } from "../services/axios";
import { ApiErrorT } from "../types/Common";

export const useMutationStopMower = () => {
  return useMutation(
    (mowerId: string | number) => {
      return client.put(`/api/v1/mower/${mowerId}/stop-engine`);
    },
    {
      onSuccess: () => {
        toast.success("Mower stopped");
      },
      onError: (error: ApiErrorT) => {
        toast.error(error?.message || "Mower stopping failed");
      },
    }
  );
};
