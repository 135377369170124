import React, { useEffect, useState } from "react";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import classNames from "classnames";
import { IconsId } from "../../assets/fonts/iconfont/icons";
import { useSelector } from "../../store/hooks";
import { useCompactDesign } from "../../hooks/useCompactDesign";
import { UfonT } from "../../types/Ufon";
import { ROLE_IDS } from "../../constants";
import { Icon } from "../../components/atoms/Icon";
import { UfonInfoTab } from "./UfonInfoTab/UfonInfoTab";
import { UfonMapTab } from "./UfonMapTab/UfonMapTab";
import { UfonLogTab } from "./UfonLogTab/UfonLogTab";
import styles from "./ufonTabWrapper.module.scss";
import UfonNotificationTab from "./UfonNotificationTab/UfonNotificationTab";

type TabT = {
  iconId: IconsId;
  styles: string;
  hideOnDesktop: boolean;
  tab: (ufon: UfonT) => JSX.Element;
};

function getTabs(isDeveloper: boolean): TabT[] {
  const tabs: TabT[] = [
    {
      iconId: "broadcast",
      styles: styles.regularTab,
      hideOnDesktop: false,
      tab: (ufon: UfonT) => <UfonInfoTab ufon={ufon} />,
    },
    {
      iconId: "location",
      styles: styles.mapTab,
      hideOnDesktop: true,
      tab: (ufon: UfonT) => <UfonMapTab ufon={ufon} />,
    },
    {
      iconId: "comment",
      styles: styles.regularTab,
      hideOnDesktop: false,
      tab: (ufon: UfonT) => <UfonNotificationTab ufon={ufon} />,
    },
  ];
  if (isDeveloper) {
    tabs.push({
      iconId: "list-timeline",
      styles: styles.regularTab,
      hideOnDesktop: false,
      tab: (ufon: UfonT) => <UfonLogTab ufon={ufon} />,
    });
  }

  return tabs;
}

type PropsT = {
  ufon: UfonT;
};

export const UfonTabWrapper: React.FC<PropsT> = ({ ufon }: PropsT) => {
  const isCompact = useCompactDesign();
  const { user: currentUser } = useSelector((state) => state.user);

  const tabs = getTabs(currentUser?.role.id === ROLE_IDS.developer);
  const firstVisibleTabIdx =
    tabs.findIndex((tab) => !tab.hideOnDesktop) !== -1
      ? tabs.findIndex((tab) => !tab.hideOnDesktop)
      : 0;
  const [activeTabIdx, setActiveTabIdx] = useState(firstVisibleTabIdx);

  useEffect(() => {
    if (!isCompact && tabs[activeTabIdx].hideOnDesktop) {
      setActiveTabIdx(firstVisibleTabIdx);
    }
  }, [isCompact, activeTabIdx, firstVisibleTabIdx, tabs]);

  const visibleTabs = tabs.filter(
    (tab) => isCompact || !tab.hideOnDesktop
  ).length;

  if (visibleTabs === 0) {
    return null;
  }
  if (!isCompact && visibleTabs === 1) {
    return (
      <TabContent activeTab={firstVisibleTabIdx} className={styles.tabContent}>
        <TabPane
          tabId={firstVisibleTabIdx}
          className={tabs[firstVisibleTabIdx].styles}
        >
          {tabs[firstVisibleTabIdx].tab(ufon)}
        </TabPane>
      </TabContent>
    );
  }

  const activeTab = tabs[activeTabIdx];

  return (
    <div className={styles.wrapper}>
      <Nav tabs className={styles.nav}>
        {tabs.map((tab, index) =>
          !isCompact && tab.hideOnDesktop ? null : (
            <NavItem
              key={`${tab.iconId}-${index}`}
              className={styles["nav-item"]}
            >
              <NavLink
                onClick={() => setActiveTabIdx(index)}
                className={classNames(styles["nav-link"], {
                  [styles["nav-link--active"]]: activeTabIdx === index,
                })}
              >
                <Icon icon={tab.iconId} />
              </NavLink>
            </NavItem>
          )
        )}
      </Nav>
      <TabContent activeTab={activeTabIdx} className={styles.tabContent}>
        {
          <TabPane tabId={activeTabIdx} className={activeTab.styles}>
            {activeTab.tab(ufon)}
          </TabPane>
        }
      </TabContent>
    </div>
  );
};
