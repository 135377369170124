import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { client } from "../services/axios";
import { ApiErrorT } from "../types/Common";
import { MutationOptionsT } from "../types/ReactQuery";

export const useMutationDeleteMower = (options?: MutationOptionsT) => {
  return useMutation(
    (mowerId: string | number) => {
      return client.delete(`/api/v1/mower/${mowerId}`);
    },
    {
      onSuccess: () => {
        toast.success("Mower has been deleted");
        if (options?.onSuccess) {
          options.onSuccess();
        }
      },
      onError: (error: ApiErrorT) => {
        toast.error(error?.message || "Deleting mower failed");
        if (options?.onError) {
          options.onError();
        }
      },
    }
  );
};
