import {
  all,
  call,
  cancel,
  fork,
  put,
  race,
  select,
  take,
  takeEvery,
} from "redux-saga/effects";
import { toast } from "react-toastify";
import { Task } from "redux-saga";
import { AxiosResponse } from "axios";
import { PayloadAction } from "@reduxjs/toolkit";
import { CONNECTION_CUTOFF } from "../../constants";
import { UfonConnectionT, UfonStatusT, UfonT } from "../../types/Ufon";
import { ApiErrorT } from "../../types/Common";
import { UfonLocationData } from "../../types/Websocket";
import { client } from "../../services/axios";
import {
  StartUfonTrackingActionType,
  SubscribeToUfonConnectionActionType,
  TrackedUfonT,
  setUfonConnectionDataAction,
  setUfonData,
  setUfonLoadingAction,
  setUfonLocationAction,
  setUfonStatus,
  stopUfonTrackingAction,
  unsubscribeFromUfonConnectionAction,
} from "../slices/ufonSlice";
import {
  subscribeMessage,
  unsubscribeMessage,
} from "../../functions/websockets";
import { combineConnectionData, fetchUfonConnection } from "./functions";
import { webSocket } from "./tasksSaga";

export function* updateImmediateUfonPositionSaga(ufonId: number) {
  while (true) {
    const { payload }: PayloadAction<UfonLocationData> = yield take(
      `ufonCoordinateReceived_${ufonId}`
    );

    const { latitude, longitude } = payload;

    yield put(
      setUfonLocationAction({
        currentLocation: { lat: latitude, lng: longitude },
        ufonId,
      })
    );
  }
}

function* startUfonTracking({ payload }: StartUfonTrackingActionType) {
  const { ufonId } = payload;

  yield put(setUfonLoadingAction(true));

  try {
    const ufon: TrackedUfonT | undefined = yield select(
      (store) => store.ufon.ufons[ufonId]
    );

    if (!ufon || !ufon.ufon) {
      const { data }: AxiosResponse<UfonT> = yield call(
        client.get,
        `/api/v1/ufon/${ufonId}`
      );

      yield put(setUfonData({ ufonData: data, ufonId }));
    }
    if (!ufon || !ufon.ufonStatus) {
      const { data }: AxiosResponse<UfonStatusT> = yield call(
        client.get,
        `/api/v1/ufon/${ufonId}/status`
      );

      yield put(setUfonStatus({ ufonStatus: data, ufonId }));
    }

    yield put(setUfonLoadingAction(false));
    const ufonTrackingTask: Task = yield fork(
      updateImmediateUfonPositionSaga,
      ufonId
    );

    while (true) {
      const { payload: unsubscribe }: PayloadAction<{ ufonId: number }> =
        yield take(stopUfonTrackingAction.type);
      if (unsubscribe.ufonId === ufonId) {
        yield cancel(ufonTrackingTask);
        return;
      }
    }
  } catch (e) {
    const { message } = e as ApiErrorT;
    toast.error(message);
    yield put(setUfonLoadingAction(false));
  }
}

function* subscribeToUfonConnectionSaga({
  payload,
}: SubscribeToUfonConnectionActionType) {
  const { ufonId } = payload;

  const dataCutoffLimit = CONNECTION_CUTOFF.ufon;
  const connectionData: UfonConnectionT = yield call(
    fetchUfonConnection,
    ufonId
  );
  yield put(setUfonConnectionDataAction({ ufonId, connectionData }));

  webSocket.send(subscribeMessage(`ufon.connection.${ufonId}`));

  while (true) {
    const {
      message,
      unsubscribe,
    }: {
      message: PayloadAction<UfonConnectionT>;
      unsubscribe: PayloadAction<{ ufonId: number }>;
    } = yield race({
      message: take(`ufonDataConnectionReceived_${ufonId}`),
      unsubscribe: take(unsubscribeFromUfonConnectionAction.type),
    });

    if (message) {
      const currentConnection: UfonConnectionT = yield select(
        (state) => state.ufon.ufons[ufonId].ufonConnection
      );
      yield put(
        setUfonConnectionDataAction({
          ufonId,
          connectionData: combineConnectionData(
            currentConnection,
            message.payload,
            dataCutoffLimit
          ),
        })
      );
    }

    if (unsubscribe && unsubscribe.payload.ufonId === ufonId) {
      webSocket.send(unsubscribeMessage(`ufon.connection.${ufonId}`));
      break;
    }
  }
}

export function* rootUfonSaga() {
  yield all([
    takeEvery("SUBSCRIBE_TO_UFON_CONNECTION", subscribeToUfonConnectionSaga),
    takeEvery("START_UFON_TRACKING", startUfonTracking),
  ]);
}
