import React, { FC, useCallback, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { Card, CardBody, Form } from "reactstrap";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { RouteGroupT, RouteT } from "../../../types/Route";
import { SelectOptionsT } from "../../../types/Common";
import { RouteInputsFromJobT } from "../../../hooks/useMutationRouteCreateFromJob";
import { Cards } from "../../atoms/Cards/Cards";
import { InputController } from "../Form/InputController";
import { formFromJobSchema } from "./validations";

type PropsT = {
  defaultValues?: RouteT;
  onTouch: () => void;
  onSubmit: (v: RouteInputsFromJobT) => void;
  jobId: number;
  routeGroups?: RouteGroupT[];
  isDisabledAll?: boolean;
};

export const FormRouteFromJob: FC<PropsT> = ({
  onTouch,
  onSubmit,
  jobId,
  routeGroups,
  defaultValues,
  isDisabledAll,
}) => {
  const getDefaultValues = useCallback(
    () => ({
      name: defaultValues?.name || "",
      folderId: `${defaultValues?.folderId}` || "",
    }),
    [defaultValues]
  );

  const selectRouteGroupsOptions = useMemo(
    () =>
      (
        [{ value: "", label: "Choose group", disabled: true }] as SelectOptionsT
      ).concat(
        (routeGroups || []).map((group) => ({
          label: group.name,
          value: group.id,
        }))
      ),
    [routeGroups]
  );

  const {
    handleSubmit,
    control,
    formState: { errors, isDirty },
    reset,
  } = useForm<{ name: string; folderId: string }>({
    resolver: yupResolver(formFromJobSchema()),
    defaultValues: getDefaultValues(),
  });

  useEffect(() => {
    reset(getDefaultValues());
  }, [getDefaultValues, reset]);

  useEffect(() => {
    if (isDirty) {
      onTouch();
    }
  }, [onTouch, isDirty]);

  return (
    <Form
      onSubmit={handleSubmit((data) =>
        onSubmit({ name: data.name, folderId: Number(data.folderId), jobId })
      )}
      id="route-form"
    >
      <Cards>
        <Card>
          <CardBody>
            <InputController
              name="name"
              label="Name"
              control={control}
              errors={errors}
              input={{ disabled: isDisabledAll }}
            />
            <InputController
              name="folderId"
              label="Group"
              control={control}
              errors={errors}
              options={selectRouteGroupsOptions}
              input={{ disabled: isDisabledAll }}
            />
          </CardBody>
        </Card>
      </Cards>
    </Form>
  );
};
