import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { client } from "../services/axios";
import { ApiErrorT } from "../types/Common";
import { MutationOptionsT } from "../types/ReactQuery";

type CompleteSegmentT = {
  jobId: string | number;
  segmentId: number;
};

export const useMutationCompleteSingleSegment = (
  options?: MutationOptionsT
) => {
  return useMutation(
    ({ jobId, segmentId }: CompleteSegmentT) => {
      return client.put(`/api/v1/job/${jobId}/segment/${segmentId}/complete`);
    },
    {
      onSuccess: () => {
        toast.success("Segment has been completed");
        if (options?.onSuccess) {
          options.onSuccess();
        }
      },
      onError: (error: ApiErrorT) => {
        toast.error(error?.message);
        if (options?.onError) {
          options.onError();
        }
      },
    }
  );
};
