import React, { ReactNode } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { ButtonPropsT, StyledButton } from "../../atoms/Button/Button";

type PropsT = {
  title?: string;
  subTitle?: string | ReactNode;
  isOpen?: boolean;
  onClose: () => void;
  onConfirm: () => void;
  onCancel?: () => void;
  buttonCancelProps?: ButtonPropsT;
  buttonConfirmProps?: ButtonPropsT;
  modalContent?: JSX.Element;
};

export const ConfirmBox = ({
  title,
  subTitle,
  isOpen,
  onClose,
  onCancel,
  onConfirm,
  buttonConfirmProps,
  buttonCancelProps,
  modalContent,
}: PropsT) => {
  const handleCancel = () => {
    if (onCancel) {
      onCancel();
    }
    onClose();
  };

  return (
    <Modal isOpen={isOpen} toggle={onClose}>
      <ModalHeader toggle={onClose}>{title || "Are you sure?"}</ModalHeader>
      {(subTitle || modalContent) && (
        <ModalBody>
          {subTitle && <div className="mb-2">{subTitle}</div>}
          {modalContent && <>{modalContent}</>}
        </ModalBody>
      )}
      <ModalFooter>
        <StyledButton
          color="light"
          onClick={handleCancel}
          title="Cancel"
          {...buttonCancelProps}
        />
        <StyledButton
          color="primary"
          onClick={onConfirm}
          title="Ok"
          {...buttonConfirmProps}
        />
      </ModalFooter>
    </Modal>
  );
};
