import React, { FC, useMemo, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Container } from "reactstrap";
import { WINDOW_TITLE_NAMES } from "../../constants";
import { ROUTES } from "../../routes";
import { JobCreateInputsT } from "../../hooks/useMutationCreateJob";
import { useMutationEditJob } from "../../hooks/useMutationEditJob";
import { useQueryJobGet, useQueryRouteGet } from "../../hooks/useQueries";
import { getDefaultValues, getDefaultValuesFromJob } from "./functions";
import { Layout } from "../../components/organisms/Layout/Layout";
import {
  JobCreateFormFieldsT,
  FormJobCreate,
} from "../../components/molecules/FormJobCreate/FormJobCreate";
import { StyledButton } from "../../components/atoms/Button/Button";
import { PageWrapper } from "../../components/atoms/PageWrapper/PageWrapper";
import SubHeader from "../../components/atoms/SubHeader/SubHeader";
import { Loader } from "../../components/atoms/Loader/Loader";

export const JobEditPage: FC = () => {
  document.title = WINDOW_TITLE_NAMES.jobs;
  const [isFormTouched, setIsFormTouched] = useState(false);
  const { jobId } = useParams() as { jobId: string };
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const type = searchParams.get("type");

  const { data: jobData, isLoading: isLoadingJob } = useQueryJobGet(jobId);
  const job =
    jobData?.data && jobData.data.geostick === undefined
      ? jobData.data
      : undefined;
  const { data: routeData, isLoading: isLoadingRoute } = useQueryRouteGet(
    jobData?.data.route?.id
  );
  const editedJobHasSegments = useMemo(
    () => Boolean(job?.segments.users.find((user) => user.mowers.length !== 0)),
    [job]
  );
  const editJobMutation = useMutationEditJob(jobId, {
    onSuccess: () => {
      navigate(ROUTES.jobs());
    },
  });

  const handleJobSubmit = (values: JobCreateFormFieldsT) => {
    const valuesForMutation: JobCreateInputsT = {
      ...values,
      assign: values.assign.map((user) => ({
        userId: user.userId,
        mowerId: user.mowers.map((mower) => mower.mowerId),
      })),
    };

    editJobMutation.mutate(valuesForMutation);
  };
  const formDefaultValues = useMemo(
    () =>
      jobId && job
        ? getDefaultValuesFromJob(job, routeData?.data)
        : getDefaultValues(type),
    [jobId, job, routeData, type]
  );
  if (isLoadingJob || isLoadingRoute) {
    return <Loader isCentered />;
  }

  return (
    <Layout>
      <SubHeader
        isDetail
        title="Edit job"
        actions={
          <>
            <StyledButton
              onClick={() => navigate(-1)}
              color="dark"
              outline
              title="Cancel"
            />
            <StyledButton
              isLoading={editJobMutation.isLoading}
              disabled={!isFormTouched}
              color="primary"
              type="submit"
              form="create-job-form"
            >
              {editJobMutation.isLoading ? "Saving" : "Update"}
            </StyledButton>
          </>
        }
      />
      <PageWrapper>
        <Container>
          <FormJobCreate
            defaultValues={formDefaultValues}
            isDisabledAll={isLoadingJob || isLoadingRoute}
            isEditing
            editedJobHasSegments={editedJobHasSegments}
            onTouch={() => setIsFormTouched(true)}
            onSubmit={handleJobSubmit}
          />
        </Container>
      </PageWrapper>
    </Layout>
  );
};
