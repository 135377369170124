import React from "react";
import { CONNECTION_CUTOFF } from "../../../constants";
import { useSelector } from "../../../store/hooks";
import {
  anyChartDataPresent,
  getConnectionChartData,
  getDesiredTimestamps,
  newestTimestamp,
} from "../../../functions/charts";
import CardWithHeader from "../../../components/atoms/CardWithHeader/CardWithHeader";
import ConnectionChart from "../../../components/organisms/ConnectionChart/ConnectionChart";
import DeviceDetailInfoRow from "../../../components/molecules/DeviceInfo/DeviceDetailStatusRow";
import { getConnectionData } from "../functions";
import styles from "./ufonInfoTab.module.scss";

type PropsT = {
  ufonId: number;
};

export const UfonConnectionCard: React.FC<PropsT> = ({ ufonId }: PropsT) => {
  const connection = useSelector(
    (state) => state.ufon.ufons[ufonId]?.ufonConnection
  );
  const connectionData = getConnectionData(connection);

  const timestampCutoffLimit = CONNECTION_CUTOFF.ufon;
  const timestampFrequency = timestampCutoffLimit / 60;
  const timestamps = getDesiredTimestamps(
    timestampCutoffLimit,
    timestampFrequency,
    newestTimestamp(connection)
  );

  return (
    <CardWithHeader title="Connection">
      {connectionData.map((item, index) => {
        const data = getConnectionChartData(item.label, connection);
        return (
          <DeviceDetailInfoRow key={`${item.label}-${index}`} data={item}>
            {item.label !== "Server" && anyChartDataPresent(connection) && (
              <>
                <ConnectionChart
                  data={data?.chartData}
                  referenceLine={data?.referenceValue}
                  chartType={item.type}
                  desiredTimestamps={timestamps}
                  maxValue={data?.maxValue}
                />

                <div className={styles.xAxis}>
                  <span>{`-${timestampCutoffLimit / 60} min`}</span>
                  <span>Now</span>
                </div>
              </>
            )}
          </DeviceDetailInfoRow>
        );
      })}
    </CardWithHeader>
  );
};
