import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { client } from "../services/axios";
import { MutationOptionsT } from "../types/ReactQuery";

export const useMutationRemoveSegments = (
  jobId: string,
  options?: MutationOptionsT
) => {
  return useMutation(
    () => {
      return client.delete(`/api/v1/job/${jobId}/segments`);
    },
    {
      onSuccess: () => {
        toast.success("Job segments have been deleted");
        if (options?.onSuccess) {
          options.onSuccess();
        }
      },
      onError: (error: { status: boolean; message: string }) => {
        toast.error(error?.message);
      },
    }
  );
};
