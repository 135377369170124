import React, { FC, useEffect } from "react";
import { useNavigate } from "react-router";
import { Card, CardBody } from "reactstrap";
import { ENVIRONMENT } from "../../../env";
import { ROUTES } from "../../../routes";
import { useDispatch, useSelector } from "../../../store/hooks";
import { useQueryUfonStatusGet } from "../../../hooks/useQueries";
import { setUfonStatus } from "../../../store/slices/ufonSlice";
import { DeviceMessage } from "../../atoms/DeviceMessage/DeviceMessage";
import { CardHeader } from "../../atoms/CardHeader/CardHeader";
import { DeviceStatusItem } from "../../atoms/DeviceStatusItem/DeviceStatusItem";
import { Ufon } from "../../atoms/Ufon/Ufon";
import { Loader } from "../../atoms/Loader/Loader";
import DefaultUfonCard from "./DefaultUfonCard";

type PropsT = {
  ufonId: number;
};

export const UfonCard: FC<PropsT> = ({ ufonId }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { data: ufonData, isLoading, error } = useQueryUfonStatusGet(ufonId);
  const ufonStatus = useSelector(
    (store) => store.ufon.ufons[ufonId]?.ufonStatus
  );

  useEffect(() => {
    if (ufonData && ufonId) {
      dispatch(setUfonStatus({ ufonStatus: ufonData.data, ufonId }));
    }
  }, [ufonData, ufonId, dispatch]);

  if (!ufonStatus) {
    return error ? <DefaultUfonCard error={error} /> : <DefaultUfonCard />;
  }

  if (isLoading) {
    return <Loader isCentered />;
  }

  return (
    <Card>
      <CardBody>
        <CardHeader
          icon="broadcast"
          title={ufonStatus.name}
          withRtkMode={{ mode: ufonStatus.status.rtkMode ?? null }}
          onClick={() => {
            navigate(ROUTES.ufon(ufonId));
          }}
        />
        {ENVIRONMENT != "production" && (
          <div className="d-flex justify-content-center mb-4">
            <Ufon led={ufonStatus?.led} />
          </div>
        )}
        {ufonStatus.message && (
          <DeviceMessage
            text={ufonStatus.message.message}
            additionalMessage={ufonStatus.message.additionalMessage}
            status={ufonStatus.message.type}
            help={ufonStatus.message.help}
          />
        )}
        <>
          <div className="row mt-2 mb-2">
            <DeviceStatusItem
              text="Correction data"
              led={ufonStatus.status.correctionData.led}
            />
            <DeviceStatusItem
              text={`Battery${
                ufonStatus.status.battery.additionalValue
                  ? ` [${ufonStatus.status.battery.additionalValue}%]`
                  : ""
              }`}
              led={ufonStatus.status.battery.led}
            />
            <DeviceStatusItem
              text="Position/GPS"
              led={ufonStatus.status.position.led}
            />
            <DeviceStatusItem
              text={`LTE${
                ufonStatus.status.lte.additionalValue
                  ? ` [${ufonStatus.status.lte.additionalValue}%]`
                  : ""
              }`}
              led={ufonStatus.status.lte.led}
            />
          </div>
        </>
      </CardBody>
    </Card>
  );
};
