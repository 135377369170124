import React, { useMemo } from "react";
import { JOB_ROUTE_TYPE_COLORS } from "../../../constants";
import { DropdownConfig } from "../../../types/Common";
import { ShowActivityTypesT } from "../../../types/Route";
import { getActivityTypeLabel } from "../../../functions/mapLayers";
import Dropdown from "../../../components/molecules/Dropdown/Dropdown";

type Props = {
  showMowingArrows: boolean;
  setShowMowingArrows: () => void;
  handleCheckActivity: () => void;
  activityChecked: boolean;
  activityTypes?: ShowActivityTypesT;
  setActivityType: (type: keyof ShowActivityTypesT, value: boolean) => void;
  resetLayers: () => void;
};

const DropdownComponent = React.memo(
  ({
    showMowingArrows,
    setShowMowingArrows,
    activityChecked,
    handleCheckActivity,
    activityTypes,
    setActivityType,
    resetLayers,
  }: Props) => {
    const dropdownConfig = useMemo<DropdownConfig>(() => {
      const config: DropdownConfig = [];

      if (activityTypes) {
        const allActivitiesShown = Object.values(activityTypes).every((x) => x);
        config.push({
          id: "activityType",
          title: "Activity Type",
          selected: allActivitiesShown,
          onSelect: () => {
            (
              Object.keys(activityTypes) as Array<keyof ShowActivityTypesT>
            ).forEach((type) => setActivityType(type, !allActivitiesShown));
          },
          subItems: (
            Object.entries(activityTypes) as Array<
              [keyof ShowActivityTypesT, boolean]
            >
          ).map(([key, shown]) => {
            return {
              id: `show_${key}`,
              title: getActivityTypeLabel(key),
              selected: shown,
              onSelect: () => setActivityType(key, !shown),
            };
          }),
        });
      }

      config.push({
        id: "activity",
        title: "Activity",
        selected: activityChecked,
        onSelect: handleCheckActivity,
        color: JOB_ROUTE_TYPE_COLORS.activeDevice,
      });

      config.push({
        id: "direction",
        title: "Show direction",
        selected: showMowingArrows,
        onSelect: setShowMowingArrows,
        hasSeparator: true,
      });

      return config;
    }, [
      activityChecked,
      handleCheckActivity,
      showMowingArrows,
      setShowMowingArrows,
      activityTypes,
      setActivityType,
    ]);

    return <Dropdown config={dropdownConfig} resetLayers={resetLayers} />;
  }
);

export default DropdownComponent;
