import { MeasurementT } from "../../types/Geostick";

export function findLast<T>(
  array: T[],
  predicate: (item: T) => boolean
): T | undefined {
  for (let i = array.length - 1; i >= 0; i--) {
    if (predicate(array[i])) {
      return array[i];
    }
  }
  return undefined;
}

function prefixZero(value: number) {
  return value < 10 && value > -1 ? `0${value}` : value;
}

export function displayMeasurement(measurement: MeasurementT): string {
  return `${measurement.type} ${prefixZero(measurement.counter)}`;
}
