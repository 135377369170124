import { NotificationListQueryT } from "../../../types/Notification";
import { useQueryNotificationPaginatedList } from "../../../hooks/useQueries";
import { useCompactDesign } from "../../../hooks/useCompactDesign";
import { Pagination } from "../Pagination/Pagination";
import { NotificationItem } from "./NotificationItem";
import styles from "./notificationList.module.scss";

type PropsT = {
  currentPage: number;
  setCurrentPage: (page: number) => void;
  query: NotificationListQueryT;
};

export function NotificationList({
  currentPage,
  setCurrentPage,
  query,
}: PropsT) {
  const isSmall = useCompactDesign();

  const { data: notifications } = useQueryNotificationPaginatedList(
    currentPage - 1,
    query
  );

  return (
    <div className={styles.list}>
      {notifications?.data.items.map((notification) => (
        <NotificationItem key={notification.id} notification={notification} />
      ))}

      {notifications && notifications?.data.totalCount > 0 && (
        <div className={styles.pagination}>
          <Pagination
            currentPage={currentPage - 1}
            pageCount={notifications?.data.totalCount || 0}
            handlePageClick={(page: number) => setCurrentPage(page + 1)}
            shownNeighbours={isSmall ? 2 : 3}
            showNextPrev={!isSmall}
          />
        </div>
      )}
    </div>
  );
}
