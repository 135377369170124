import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { client } from "../services/axios";
import { MutationOptionsT } from "../types/ReactQuery";
import { ApiErrorT, ApiSuccessModal } from "../types/Common";
import { useConfirm } from "../components/molecules/ConfirmBoxProvider/ConfirmBoxProvider";
import { AxiosResponse } from "axios";
import { queryClient } from "..";
import { responseWithModal } from "../functions/responseWithModal";

export const useMutationRouteDelete = (options?: MutationOptionsT) => {
  const confirm = useConfirm();
  return useMutation(
    (routeId: string | number) => {
      return client.delete(`/api/v1/route/${routeId}`);
    },
    {
      onSuccess: (response: AxiosResponse<ApiSuccessModal>) => {
        responseWithModal({
          response,
          confirm,
          modalSuccess: async () => {
            await queryClient.invalidateQueries(["route"]);
            toast.success("Route has been archived");
          },
          regularSuccess: () => {
            toast.success("Route has been deleted");
            if (options?.onSuccess) {
              options.onSuccess();
            }
          },
          anotherModal: () => {},
        });
      },
      onError: (error: ApiErrorT) => {
        toast.error(error?.message || "Deleting route failed");
        if (options?.onError) {
          options.onError();
        }
      },
    }
  );
};
