import { FC, useState } from "react";
import { Link } from "react-router-dom";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import classNames from "classnames";
import { useCompactDesign } from "../../../hooks/useCompactDesign";
import { Icon } from "../Icon";
import styles from "./createNewMenu.module.scss";
import { ROUTES } from "../../../routes";

type Props = {};

export const CreateNewMenu: FC<Props> = () => {
  const [open, setOpen] = useState<boolean>(false);
  const isCompact = useCompactDesign();

  const toggle = () => {
    setOpen(!open);
  };

  return (
    <UncontrolledDropdown
      isOpen={open}
      toggle={toggle}
      inNavbar
      nav
      className={classNames(styles.userMenuDropdown, {
        [styles["userMenuDropdown--large"]]: !isCompact,
      })}
    >
      <DropdownToggle
        nav
        className={classNames(styles.userMenuDropdownToggle, {
          [styles["userMenuDropdownToggle--large"]]: !isCompact,
        })}
      >
        <Icon icon="plus-square" />
      </DropdownToggle>
      <DropdownMenu
        end
        flip={false}
        className={classNames(styles.userMenuDropdownMenu)}
      >
        <DropdownItem tag={Link} to={ROUTES.jobCreate()}>
          New job
        </DropdownItem>
        <DropdownItem tag={Link} to={ROUTES.routeCreate()}>
          New route
        </DropdownItem>
        <DropdownItem tag={Link} to={ROUTES.userCreate()}>
          New user
        </DropdownItem>
        <DropdownItem tag={Link} to={ROUTES.mowerCreate()}>
          New device
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};
