import { FC, useEffect } from "react";
import { Link } from "react-router-dom";
import { Card } from "reactstrap";
import classNames from "classnames";
import { ROUTES } from "../../../routes";
import { ChartType, DeviceCurrentInfo } from "../../../types/Common";
import { UfonT } from "../../../types/Ufon";
import { useDispatch, useSelector } from "../../../store/hooks";
import {
  subscribeToUfonConnectionAction,
  unsubscribeFromUfonConnectionAction,
} from "../../../store/slices/ufonSlice";
import { Icon } from "../../../components/atoms/Icon";
import { getBatteryInfo, getConnectionData } from "../../Ufon/functions";
import styles from "./ufonStatusCard.module.scss";

type PropsT = {
  type: "geostick" | "ufon";
  ufonId: number;
  ufonName: string;
  className?: string;
};

export const UfonStatusCard: FC<PropsT> = ({
  type,
  ufonName,
  ufonId,
  className,
}: PropsT) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (!ufonId) return;
    dispatch(subscribeToUfonConnectionAction({ ufonId: Number(ufonId) }));
    return () => {
      dispatch(unsubscribeFromUfonConnectionAction({ ufonId: Number(ufonId) }));
    };
  }, [dispatch, ufonId]);

  const ufon = useSelector((state) => state.ufon.ufons[ufonId]?.ufon);
  const connection = useSelector(
    (state) => state.ufon.ufons[ufonId]?.ufonConnection
  );
  const connectionData: DeviceCurrentInfo[] = getConnectionData(
    connection
  ).filter((item) => item.type !== ChartType.server);
  connectionData.push(
    getBatteryInfo((ufon as UfonT | undefined)?.data?.batteryPercent)
  );

  return (
    <Card className={classNames(styles.status, className)}>
      <div className={styles.title}>
        <Icon
          icon={type === "geostick" ? "crosshairs-location" : "broadcast-tower"}
        />
        <Link className={styles.link} to={ROUTES.ufon(ufonId)}>
          {ufonName}
        </Link>
      </div>
      <div className={styles.icons}>
        {connectionData.map((item, index) => {
          return (
            item.icon && (
              <Icon
                className={styles.icon}
                key={`${item.label}-${index}`}
                icon={item.icon.id}
                color={item.icon.color}
              />
            )
          );
        })}
      </div>
    </Card>
  );
};
