import { FC } from "react";
import { useQueryUfonLogGet } from "../../../hooks/useQueries";
import LogBase from "./LogBase";

type Props = {
  id: number | string;
};

const UfonLog: FC<Props> = ({ id }) => {
  return <LogBase id={id} useRawDataGet={useQueryUfonLogGet} />;
};

export default UfonLog;
