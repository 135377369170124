import classNames from "classnames";
import { useCompactDesign } from "../../../hooks/useCompactDesign";
import { MowerT } from "../../../types/Mower";
import CardWithHeader from "../../../components/atoms/CardWithHeader/CardWithHeader";
import MowerMapTab from "../MowerMapTab/MowerMapTab";
import MowerCurrentInfoCard from "./MowerCurrentInfoCard";
import MowerGeneralInfoCard from "./MowerGeneralInfoCard";
import MowerConnectionCard from "./MowerConnectionCard";
import MowerActivityCard from "./MowerActivityCard";
import styles from "./mowerInfoTab.module.scss";

type Props = {
  mower: MowerT;
};

export default function MowerInfoTab({ mower }: Props) {
  const isCompact = useCompactDesign();

  return (
    <>
      <div
        className={classNames(
          styles.mowerTab,
          !isCompact && styles["mowerTab--large"]
        )}
      >
        <div
          className={classNames(
            styles.left,
            !isCompact && styles["left--large"]
          )}
        >
          <MowerCurrentInfoCard />
          {!isCompact && <MowerActivityCard mower={mower} />}
          {isCompact && <MowerConnectionCard />}
        </div>
        <div
          className={classNames(
            styles.right,
            !isCompact && styles["right--large"]
          )}
        >
          {!isCompact && <MowerConnectionCard />}
          {isCompact && <MowerActivityCard mower={mower} />}
          {!isCompact && (
            <div className={styles.map}>
              <CardWithHeader
                className={styles["map-body"]}
                bodyClassName={styles["map-body"]}
                title="Last position"
              >
                <MowerMapTab />
              </CardWithHeader>
            </div>
          )}
          <MowerGeneralInfoCard />
        </div>
      </div>
    </>
  );
}
