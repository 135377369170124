import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import React, { FC, MouseEvent, useCallback, useMemo } from "react";
import { useForm } from "react-hook-form";
import { Col, Form, Label, Spinner } from "reactstrap";
import {
  useQueryRouteGroupListGet,
  useQueryRouteMowerGrassHeightGet,
} from "../../../hooks/useQueries";
import { SelectOptionsT } from "../../../types/Common";
import { InputController } from "../Form/InputController";
import { calculateRouteFormSchema } from "./validations";
import { StyledButton } from "../../atoms/Button/Button";
import { SwitchGroup } from "../../atoms/SwitchGroup/SwitchGroup";
import { SwitchButton } from "../../atoms/SwitchButton/SwitchButton";

export type CalculateRouteInputsT = {
  title: string;
  category: string;
  grassHeight: number;
};

type PropsT = {
  onSubmit: (v: CalculateRouteInputsT) => void;
  onCancel?: () => void;
  isSubmitDisabled?: boolean;
};

const CalculateRouteForm: FC<PropsT> = ({
  onSubmit,
  onCancel,
  isSubmitDisabled,
}) => {
  const { data: grasshHeightData, isLoading: grassHeightLoading } =
    useQueryRouteMowerGrassHeightGet();
  const { data: routeListData, isLoading: routeListLoading } =
    useQueryRouteGroupListGet();
  const isLoading = grassHeightLoading || routeListLoading;

  const categoryOptions = useMemo(() => {
    const categories = routeListData?.data.items.map((item) => ({
      value: item.id,
      label: item.name,
    }));

    return [
      { value: "", label: "ChooseCategory", disabled: true },
      ...(categories || []),
    ];
  }, [routeListData]);

  const getDefaultValues = useCallback(
    (): CalculateRouteInputsT => ({
      title: "",
      category: "",
      grassHeight: 80,
    }),
    []
  );

  const {
    handleSubmit,
    setValue,
    getValues,
    watch,
    control,
    formState: { errors },
  } = useForm<CalculateRouteInputsT>({
    resolver: yupResolver(calculateRouteFormSchema),
    defaultValues: getDefaultValues(),
  });

  const handleCancel = (event: MouseEvent<HTMLElement>) => {
    event.preventDefault();
    if (onCancel) {
      onCancel();
    }
  };

  const grassHeightOptions: SelectOptionsT =
    grasshHeightData?.data.items.map(({ name, value }) => ({
      label: name,
      value,
    })) || [];

  watch("grassHeight");

  return (
    <Form onSubmit={handleSubmit(onSubmit)} id="calculate-route-form">
      <InputController
        id="title"
        name="title"
        label="Route title"
        placeholder="Set title (optional)"
        control={control}
        errors={errors}
        input={{
          disabled: isLoading,
        }}
      />
      <InputController
        name="category"
        label="Job type"
        control={control}
        errors={errors}
        options={categoryOptions}
        input={{
          disabled: isLoading,
        }}
      />
      <Label>
        Grass height/density{" "}
        {grassHeightLoading && <Spinner color="primary" size="sm" />}
      </Label>
      <Col>
        {grasshHeightData && (
          <SwitchGroup stats>
            {grassHeightOptions.map((option) => {
              const isChecked = getValues("grassHeight") === option.value;

              return (
                <SwitchButton
                  key={option.value}
                  title={option.label}
                  onClick={() => setValue("grassHeight", Number(option.value))}
                  active={isChecked}
                />
              );
            })}
          </SwitchGroup>
        )}
      </Col>
      <StyledButton
        color="primary"
        size="sm"
        className="me-2 mb-2"
        disabled={isSubmitDisabled}
      >
        Start
      </StyledButton>
      {onCancel && (
        <StyledButton
          size="sm"
          className="me-2 mb-2"
          onClick={handleCancel}
          disabled={isSubmitDisabled}
        >
          Cancel
        </StyledButton>
      )}
    </Form>
  );
};

export default CalculateRouteForm;
