import { useDeviceScreenWidth } from "./useDeviceScreenWidth";
import { LG_SCREEN_WIDTH } from "../constants";
import { Capacitor } from "@capacitor/core";

export const useCompactDesign = (width: number = LG_SCREEN_WIDTH) => {
  const isSmallScreen = useDeviceScreenWidth(width);
  const isNative = Capacitor.isNativePlatform();

  return isSmallScreen || isNative;
};
