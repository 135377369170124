import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { client } from "../services/axios";
import { MutationOptionsT } from "../types/ReactQuery";
import { ApiErrorT } from "../types/Common";

type UserInviteDataT = {
  email: string;
  company: number[];
};

export const useMutationUserInvite = (options?: MutationOptionsT) => {
  return useMutation(
    (mergeData: UserInviteDataT) =>
      client.post("/api/v1/user/invite", mergeData),
    {
      onSuccess: (_, { email }) => {
        toast.success(`Invitation send to ${email}`);
        if (options?.onSuccess) {
          options.onSuccess();
        }
      },
      onError: (error: ApiErrorT) => {
        toast.error(error?.message || "Failed to send invitation");
        if (options?.onError) {
          options.onError();
        }
      },
    }
  );
};
