export async function base64ToBlob(base64String: string) {
  const base64 = await fetch(base64String);
  const blob = await base64.blob();
  return blob;
}

export async function downloadFile(downloadUrl: string, fileName: string) {
  const link = document.createElement("a");
  link.href = downloadUrl;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
  link.remove();
  window.URL.revokeObjectURL(downloadUrl);
}
