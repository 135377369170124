import React, { FC } from "react";
import styles from "./title.module.scss";
import classNames from "classnames";

export type TitleSizes = "h1" | "h2" | "h3" | "h4" | "h5" | "h6";

type PropsT = {
  type?: TitleSizes;
  size?: TitleSizes;
  children: React.ReactNode;
  className?: string;
  noMarginBottom?: boolean;
  lineThrough?: boolean;
};

const Title: FC<PropsT> = ({
  type = "h1",
  size,
  noMarginBottom,
  className,
  children,
  lineThrough,
}) => {
  return React.createElement(
    type,
    {
      className: classNames(styles[size || type], className, {
        [styles["no-margin-bottom"]]: noMarginBottom,
        [styles["line-through"]]: lineThrough,
      }),
    },
    children
  );
};

export default Title;
