import { FC, useState } from "react";
import classNames from "classnames";
import { OptionT, StatisticsQueryT } from "../../types/Statistics";
import { useQueryStatisticsOptionsPost } from "../../hooks/useQueries";
import { useCompactDesign } from "../../hooks/useCompactDesign";
import { DatePicker } from "../../components/molecules/DatePicker/DatePicker";
import { StyledButton } from "../../components/atoms/Button/Button";
import { Multiselect } from "../../components/atoms/Multiselect/Multiselect";
import { getOptions, getQuery } from "./functions";
import styles from "./statistics.module.scss";

type PropsT = {
  statisticsQueried: (query: StatisticsQueryT) => void;
  initialStartDate: Date;
  initialEndDate: Date;
};

export const StatisticsFilter: FC<PropsT> = ({
  statisticsQueried,
  initialStartDate,
  initialEndDate,
}: PropsT) => {
  const isCompact = useCompactDesign();

  const [dateRange, setDateRange] = useState<{ start: Date; end: Date }>({
    start: initialStartDate,
    end: initialEndDate,
  });
  const [selectedMowers, setSelectedMowers] = useState<OptionT[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<OptionT[]>([]);
  const [selectedJobs, setSelectedJobs] = useState<OptionT[]>([]);

  const { data: options } = useQueryStatisticsOptionsPost(
    getQuery(
      dateRange.start,
      dateRange.end,
      selectedMowers,
      selectedJobs,
      selectedUsers
    )
  );

  const mowers = (options && getOptions(options.data.mowers)) || [];
  const users = (options && getOptions(options.data.users)) || [];
  const jobs = (options && getOptions(options.data.jobs)) || [];

  const datePicked = (start: Date, end: Date) => {
    setDateRange({ start, end });
  };

  const filter = () => {
    statisticsQueried(
      getQuery(
        dateRange.start,
        dateRange.end,
        selectedMowers,
        selectedJobs,
        selectedUsers
      )
    );
  };

  return (
    <div
      className={classNames(
        styles.filter,
        !isCompact && styles["filter--large"]
      )}
    >
      <DatePicker
        className={classNames(!isCompact && styles["datePicker--large"])}
        startDatetime={dateRange.start}
        endDatetime={dateRange.end}
        onRangeSelected={datePicked}
        maxDate={new Date()}
      />
      <Multiselect
        className={classNames(!isCompact && styles["multiselect--large"])}
        initialTextValue="All jobs"
        selected={selectedJobs}
        data={jobs}
        onChange={setSelectedJobs}
      />
      <Multiselect
        className={styles.multiselect}
        initialTextValue="All users"
        selected={selectedUsers}
        data={users}
        onChange={setSelectedUsers}
      />
      <Multiselect
        className={styles.multiselect}
        initialTextValue="All mowers"
        selected={selectedMowers}
        data={mowers}
        onChange={setSelectedMowers}
      />
      <div className={styles.filterButtons}>
        <StyledButton
          className={styles.button}
          title="Filter"
          color="primary"
          onClick={filter}
        />
        <StyledButton
          className={styles.button}
          title="Reset"
          color="link"
          onClick={() => {
            setSelectedJobs([]);
            setSelectedMowers([]);
            setSelectedUsers([]);
            setDateRange({ start: initialStartDate, end: initialEndDate });
          }}
        />
      </div>
    </div>
  );
};
