import React, { FC } from "react";
import { SubmitHandler } from "react-hook-form";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { useMutationDeviceAssign } from "../../../hooks/useMutationUserAssignDevice";
import {
  useQueryMowerListGet,
  useQueryUfonListGet,
  useQueryUserGet,
} from "../../../hooks/useQueries";
import { DeviceTypes } from "../../../types/Common";
import {
  FormAssignDevice,
  FormAssignDeviceInputsT,
} from "../../molecules/FormAssignDevice/FormAssignDevice";

type PropsT = {
  isOpen: boolean;
  onClose: () => void;
  userId: string;
  onSuccess?: () => void;
};

export const AssignDeviceModal: FC<PropsT> = ({
  isOpen,
  onClose,
  userId,
  onSuccess,
}) => {
  const { data: mowerData, isLoading: mowerIsLoading } = useQueryMowerListGet();
  const { data: ufonData, isLoading: ufonIsLoading } = useQueryUfonListGet();
  const { data: userData, isLoading: userIsLoading } = useQueryUserGet(userId);

  const isLoading = mowerIsLoading || ufonIsLoading || userIsLoading;

  const assignDeviceMutation = useMutationDeviceAssign({
    onSuccess: () => {
      if (onSuccess) {
        onSuccess();
      }
      onClose();
    },
  });

  const filteredMowers = mowerData?.data.items.filter(
    (mower) =>
      !userData?.data.devices.mower.some((assigned) => assigned.id === mower.id)
  );

  const filteredUfons = ufonData?.data.items.filter(
    (ufon) =>
      !userData?.data.devices.ufon.some((assigned) => assigned.id === ufon.id)
  );

  const handleSubmit: SubmitHandler<FormAssignDeviceInputsT> = (values) => {
    const [deviceType, deviceId] = values.deviceId.split("_") as [
      DeviceTypes,
      string
    ];
    assignDeviceMutation.mutate({ deviceType, deviceId, userId });
  };

  return (
    <Modal isOpen={isOpen} toggle={onClose}>
      <ModalHeader toggle={onClose}>Assign device</ModalHeader>
      <ModalBody>
        <FormAssignDevice
          onSubmit={handleSubmit}
          ufons={filteredUfons}
          mowers={filteredMowers}
          isLoadingData={isLoading}
        />
      </ModalBody>
      <ModalFooter>
        <Button color="light" onClick={onClose}>
          Cancel
        </Button>
        <Button
          color="primary"
          type="submit"
          form="assign-device-form"
          className="me-2"
        >
          {assignDeviceMutation.isLoading ? "Assigning" : "Assign"}
        </Button>
      </ModalFooter>
    </Modal>
  );
};
