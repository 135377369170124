import React from "react";
import { Button } from "reactstrap";
import { Icon } from "../../atoms/Icon";
import styles from "./mapDropdown.module.scss";

type Props = {
  setShowMenu: () => void;
  label: string;
};

const DropdownToggle = ({ setShowMenu, label }: Props) => {
  return (
    <Button onClick={setShowMenu} className={styles.toggle}>
      <span className={styles["toggle-label"]}>{label}</span>
      <Icon icon="map-layers" className={styles["toggle-icon-label"]} />
      <Icon icon="chevron-left" className={styles["toggle-chevron"]} />
    </Button>
  );
};

export default DropdownToggle;
