import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "../../store/hooks";
import { WINDOW_TITLE_NAMES } from "../../constants";
import { ROUTES } from "../../routes";
import { UfonT } from "../../types/Ufon";
import { usePermissions } from "../../hooks/usePermissions";
import { useQueryUfonGet } from "../../hooks/useQueries";
import { useMutationDeleteUfon } from "../../hooks/useMutationDeleteUfon";
import { useMutationRebootUfon } from "../../hooks/useMutationRebootUfon";
import {
  startTrackingNearbyMowersAction,
  stopTrackingNearbyMowersAction,
} from "../../store/slices/mowerSlice";
import {
  setUfonData,
  subscribeToUfonConnectionAction,
  unsubscribeFromUfonConnectionAction,
} from "../../store/slices/ufonSlice";
import { Layout } from "../../components/organisms/Layout/Layout";
import { useConfirm } from "../../components/molecules/ConfirmBoxProvider/ConfirmBoxProvider";
import { StyledButton } from "../../components/atoms/Button/Button";
import SubHeader from "../../components/atoms/SubHeader/SubHeader";
import { Loader } from "../../components/atoms/Loader/Loader";
import { UfonTabWrapper } from "./UfonTabWrapper";

type Props = {};

type ParamsT = {
  ufonId: string;
};

export const UfonPage: React.FC<Props> = () => {
  document.title = WINDOW_TITLE_NAMES.devices;
  const navigate = useNavigate();
  const { ufonId } = useParams<ParamsT>();
  const permissions = usePermissions();
  const confirm = useConfirm();
  const dispatch = useDispatch();
  const { data: ufonData, isLoading } = useQueryUfonGet(Number(ufonId));
  useEffect(() => {
    if (ufonData) {
      dispatch(
        setUfonData({ ufonData: ufonData.data, ufonId: ufonData.data.id })
      );
    }
  }, [ufonData, dispatch]);
  const ufon = useSelector(
    (state) => state.ufon.ufons[Number(ufonId)]?.ufon
  ) as UfonT | undefined | null;

  const { mutate: rebootUfon, isLoading: isRebooting } =
    useMutationRebootUfon();
  const deleteUfonMutation = useMutationDeleteUfon({
    onSuccess: () => {
      navigate(ROUTES.devices());
    },
  });

  useEffect(() => {
    if (ufonData) {
      dispatch(
        setUfonData({ ufonData: ufonData.data, ufonId: ufonData.data.id })
      );
    }
  }, [ufonData, dispatch]);

  useEffect(() => {
    if (ufonId) {
      dispatch(subscribeToUfonConnectionAction({ ufonId: Number(ufonId) }));
    }
    return () => {
      dispatch(unsubscribeFromUfonConnectionAction({ ufonId: Number(ufonId) }));
    };
  }, [dispatch, ufonId]);

  useEffect(() => {
    if (ufonData) {
      dispatch(
        startTrackingNearbyMowersAction({
          initialNearbyMowers: ufonData.data.nearbyMowers,
          deviceId: Number(ufonId),
          deviceType: "ufon",
        })
      );
    }
    return () => {
      dispatch(stopTrackingNearbyMowersAction());
    };
  }, [ufonData, dispatch, ufonId]);

  const onDelete = () => {
    if (!permissions.deviceCreate) {
      confirm({
        title: "Contact support",
        subTitle: (
          <p>
            For deleting a device, please contact customer support on email:{" "}
            <a href="mailto:info@spidernewtech.com">info@spidernewtech.com</a>
          </p>
        ),
        callback: () => {},
        type: "ok",
      });
    } else {
      confirm({
        title: "Remove correction station",
        subTitle: `Are you sure you want to delete this correction station, ${ufonData?.data.name}?`,
        asyncCallback: () => deleteUfonMutation.mutateAsync(ufonId || 0),
        type: "delete",
      });
    }
  };

  return (
    <Layout requireWS>
      <SubHeader
        isDetail
        title={ufon?.name || "Loading..."}
        status={ufon?.isOnline}
        onGoBack={() => navigate(ROUTES.devices())}
        actions={
          permissions.deviceManage ? (
            <>
              {ufon?.isOnline && (
                <StyledButton
                  onlyIconOnMobile
                  disabled={isRebooting}
                  className="me-2"
                  onClick={() => {
                    rebootUfon(ufonId || -1);
                  }}
                  color="error"
                  icon="reboot"
                  title="Reboot device"
                />
              )}
              <StyledButton
                icon="pen"
                color="dark"
                outline
                tag={Link}
                link={ufonId && ROUTES.ufonEdit(ufonId)}
              />
              <StyledButton
                onClick={onDelete}
                icon="trash"
                color="dark"
                outline
              />
            </>
          ) : undefined
        }
      />
      {isLoading && <Loader isCentered />}
      {ufon && <UfonTabWrapper ufon={ufon} />}
    </Layout>
  );
};
