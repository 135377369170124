import { FC, useCallback, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { Card, CardBody, Form } from "reactstrap";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { SelectOptionsT } from "../../../types/Common";
import { RouteT } from "../../../types/Route";
import { Cards } from "../../atoms/Cards/Cards";
import { InputController } from "../Form/InputController";
import { updateRouteSchema } from "./validations";

export type RouteInputsT = {
  route: string;
  file: FileList;
};

type PropsT = {
  defaultValues?: { id: string };
  onTouch: () => void;
  onSubmit: (v: RouteInputsT) => void;
  routes?: RouteT[];
  isDisabledAll?: boolean;
};

export const FormChangeRoute: FC<PropsT> = ({
  onTouch,
  onSubmit,
  routes,
  defaultValues,
  isDisabledAll,
}) => {
  const getDefaultValues = useCallback(
    () => ({
      route: defaultValues?.id,
    }),
    [defaultValues]
  );

  const selectRouteOptions = useMemo(
    () =>
      (
        [
          { value: undefined, label: "Choose route", disabled: true },
        ] as SelectOptionsT
      ).concat(
        (routes || []).map((route) => ({
          label: route.name,
          value: String(route.id),
        }))
      ),
    [routes]
  );

  const {
    handleSubmit,
    control,
    formState: { errors, isDirty },
    reset,
  } = useForm<RouteInputsT>({
    resolver: yupResolver(updateRouteSchema()),
    defaultValues: getDefaultValues(),
  });

  useEffect(() => {
    reset(getDefaultValues());
  }, [getDefaultValues, reset]);

  useEffect(() => {
    if (isDirty) {
      onTouch();
    }
  }, [onTouch, isDirty]);

  return (
    <Form onSubmit={handleSubmit(onSubmit)} id="route-form">
      <Cards>
        <Card>
          <CardBody>
            <>
              <InputController
                name="route"
                label="Route"
                required
                control={control}
                errors={errors}
                options={selectRouteOptions}
                input={{ disabled: isDisabledAll }}
              />
              <InputController
                name="file"
                label="New File (kml)"
                required
                control={control}
                errors={errors}
                input={{ type: "file", disabled: isDisabledAll }}
              />
            </>
          </CardBody>
        </Card>
      </Cards>
    </Form>
  );
};
