import { FC, useState } from "react";
import { UseQueryResult } from "react-query";
import ReactJson from "react-json-view";
import { ApiErrorT } from "../../../types/Common";
import { Loader } from "../../../components/atoms/Loader/Loader";
import { StyledButton } from "../../../components/atoms/Button/Button";
import Pagination from "./Pagination";
import styles from "./pagination.module.scss";

const PAGE_LIMIT = 5;

type RawDataT = {
  data: {
    items: Object[];
    totalCount: number;
  };
};

type Props = {
  id: number | string;
  useRawDataGet: (
    id: number | string,
    limit: number,
    offset: number
  ) => UseQueryResult<RawDataT, ApiErrorT>;
};

const LogBase: FC<Props> = ({ id, useRawDataGet }) => {
  const [currentPage, setCurrentPage] = useState(0);
  const {
    data: logData,
    isLoading,
    isFetching,
    refetch,
  } = useRawDataGet(id, PAGE_LIMIT, currentPage * PAGE_LIMIT);

  if (!logData) {
    return null;
  }

  if (isLoading || isFetching) {
    return <Loader isCentered />;
  }

  return (
    <div>
      {logData.data.items.length === 0 && (
        <p className="text-center">No logs found</p>
      )}
      {logData.data.items.length !== 0 && (
        <div className={styles.wrapper}>
          <StyledButton
            icon="reboot"
            title="Refresh data"
            color="primary"
            size="sm"
            onClick={() => {
              setCurrentPage(0);
              refetch();
            }}
          />
          <ReactJson src={logData.data.items} />
          <Pagination
            currentPage={currentPage}
            pagesCount={logData.data.totalCount}
            handlePreviousClick={() => setCurrentPage((prev) => prev - 1)}
            handleNextClick={() => setCurrentPage((prev) => prev + 1)}
          />
        </div>
      )}
    </div>
  );
};

export default LogBase;
