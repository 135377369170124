import React, { FC, MouseEvent } from "react";
import { Container } from "reactstrap";
import classNames from "classnames";
import { useCompactDesign } from "../../../hooks/useCompactDesign";
import { StyledButton } from "../../atoms/Button/Button";
import { FilterItem } from "../../atoms/FilterItem/FilterItem";
import { SwitchButton } from "../../atoms/SwitchButton/SwitchButton";
import { SwitchGroup } from "../../atoms/SwitchGroup/SwitchGroup";
import styles from "./menuWithLayout.module.scss";

export type MenuWithLayoutItemT = {
  id?: number | string;
  title: string;
  hasSeparator?: boolean;
  itemCount?: number;
  active?: boolean;
  isRadio?: boolean;
  onClick?: () => void;
  onDelete?: (id: number) => void;
  onUpdate?: (id: number) => void;
};

export type MenuWithLayoutPropsT = {
  items?: MenuWithLayoutItemT[];
  separateItems?: MenuWithLayoutItemT[];
  fixedItem?: MenuWithLayoutItemT;
  switchers?: MenuWithLayoutItemT[];
  activeItem?: number | string | Array<string | number>;
  addNewButton?: {
    onClick: () => void;
    title?: string;
  };
  checkedItems?: string[];
};

export const getIsCheckedItem = (
  item: MenuWithLayoutItemT,
  checkedItems?: string[]
) => {
  if (!checkedItems) {
    return false;
  }
  return checkedItems.includes(item.id?.toString() || "");
};

const MenuWithLayout: FC<MenuWithLayoutPropsT> = ({
  children,
  items,
  separateItems,
  fixedItem,
  switchers,
  activeItem,
  addNewButton,
  checkedItems,
}) => {
  const isCompact = useCompactDesign();
  const handleClick = (
    event: MouseEvent<HTMLElement>,
    item: MenuWithLayoutItemT
  ) => {
    event.preventDefault();
    if (item.onClick) {
      item.onClick();
    }
  };

  const handleOnDelete = (
    event: MouseEvent<HTMLDivElement>,
    item: MenuWithLayoutItemT
  ) => {
    event.preventDefault();
    item.onDelete?.(item.id as number);
    event.stopPropagation();
  };

  const handleOnUpdate = (
    event: MouseEvent<HTMLDivElement>,
    item: MenuWithLayoutItemT
  ) => {
    event.preventDefault();
    item.onUpdate?.(item.id as number);
    event.stopPropagation();
  };

  const getIsActiveItem = (item: MenuWithLayoutItemT) => {
    if (Array.isArray(activeItem) && item.id) {
      return activeItem?.includes(item.id);
    }
    return activeItem === item.id;
  };

  return (
    <Container
      fluid
      className={classNames(!isCompact && styles["container--large"])}
    >
      <div
        className={classNames(
          styles.wrapper,
          !isCompact && styles["wrapper--large"]
        )}
      >
        {!isCompact && (
          <div className={styles["col-navigation"]}>
            {switchers && (
              <SwitchGroup>
                {switchers.map((switcher) => (
                  <SwitchButton key={switcher.title} {...switcher} />
                ))}
              </SwitchGroup>
            )}
            {separateItems?.map((item) => (
              <FilterItem
                key={item.id}
                item={item}
                itemCount={item.itemCount}
                isActiveItem={getIsActiveItem(item)}
                isCheckedItem={getIsCheckedItem(item, checkedItems)}
                onClick={(event) => handleClick(event, item)}
                onDelete={item.onDelete ? handleOnDelete : undefined}
                onUpdate={item.onUpdate ? handleOnUpdate : undefined}
                isStandalone
              />
            ))}
            <div className={styles.scrollable}>
              {items?.map((item) => (
                <FilterItem
                  key={item.id}
                  item={item}
                  itemCount={item.itemCount}
                  isActiveItem={getIsActiveItem(item)}
                  isCheckedItem={getIsCheckedItem(item, checkedItems)}
                  onClick={(event) => handleClick(event, item)}
                  onDelete={item.onDelete ? handleOnDelete : undefined}
                  onUpdate={item.onUpdate ? handleOnUpdate : undefined}
                  isStandalone
                />
              ))}
            </div>
            {(fixedItem || addNewButton) && (
              <>
                <div
                  className={classNames(
                    !isCompact && styles["fixed-bottom--large"]
                  )}
                >
                  {fixedItem && (
                    <FilterItem
                      key={fixedItem.id}
                      item={fixedItem}
                      className={classNames(
                        !isCompact && styles["archive--large"]
                      )}
                      isActiveItem={getIsActiveItem(fixedItem)}
                      isCheckedItem={getIsCheckedItem(fixedItem, checkedItems)}
                      onClick={(event) => handleClick(event, fixedItem)}
                      onDelete={fixedItem.onDelete ? handleOnDelete : undefined}
                      onUpdate={fixedItem.onUpdate ? handleOnUpdate : undefined}
                      isStandalone
                    />
                  )}
                  {addNewButton && (
                    <StyledButton
                      icon="plus-circle"
                      color="link"
                      size="sm"
                      className={classNames(
                        !isCompact && styles["group-btn--large"]
                      )}
                      onClick={addNewButton.onClick}
                      title={addNewButton.title}
                    />
                  )}
                </div>
              </>
            )}
          </div>
        )}
        <div className={classNames(!isCompact && styles["col-content--large"])}>
          {children}
        </div>
      </div>
    </Container>
  );
};

export default MenuWithLayout;
