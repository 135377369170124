import { getMowerActivityData } from "../functions";
import { useParams } from "react-router";
import { useSelector } from "../../../store/hooks";
import { MowerT } from "../../../types/Mower";
import DeviceActivityRow from "../../../components/molecules/DeviceInfo/DeviceActivityRow";
import CardWithHeader from "../../../components/atoms/CardWithHeader/CardWithHeader";

type Props = {
  mower: MowerT;
};

export default function MowerActivityCard({ mower }: Props) {
  const { mowerId } = useParams();

  const mowerData = useSelector(
    (store) => store.mower.mowers[Number(mowerId as string)]?.mowerData
  );
  const activityData = getMowerActivityData(mower, mowerData);
  return (
    <CardWithHeader title="Activity">
      {activityData.map((data, index) => (
        <DeviceActivityRow key={`${data.label}-${index}`} deviceData={data} />
      ))}
    </CardWithHeader>
  );
}
