import { useSelector } from "../../../store/hooks";
import styles from "./radioWarning.module.scss";

function RadioWarning() {
  const { showRadioModeWarning } = useSelector((store) => store.jobRouteType);

  return (
    <div className={showRadioModeWarning ? styles.radioWarning : "d-none"}>
      <span>Map might be inaccurate in radio mode</span>
    </div>
  );
}

export default RadioWarning;
