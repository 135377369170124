import { CompanyT } from "../../../types/Company";
import { UserPreviewT } from "../../../types/User";

export const getUsersNames = (users: UserPreviewT[]): string =>
  users.reduce((acc: string, user, index) => {
    if (index === 0) {
      return acc + user.name;
    } else {
      return `${acc}, ${user.name}`;
    }
  }, "");

export const getCompanyNames = (companies: CompanyT[]): string => {
  if (companies.length === 0) {
    return "-";
  }
  return companies.reduce((acc: string, company, index) => {
    if (index === 0) {
      return acc + company.name;
    } else {
      return `${acc}, ${company.name}`;
    }
  }, "");
};
