import React from "react";
import classNames from "classnames";
import { UfonT } from "../../../types/Ufon";
import { useCompactDesign } from "../../../hooks/useCompactDesign";
import CardWithHeader from "../../../components/atoms/CardWithHeader/CardWithHeader";
import { UfonMapTab } from "../UfonMapTab/UfonMapTab";
import { UfonCurrentInfoCard } from "./UfonCurrentInfoCard";
import { UfonActivityCard } from "./UfonActivityCard";
import { UfonConnectionCard } from "./UfonConnectionCard";
import { UfonGeneralInfoCard } from "./UfonGeneralInfoCard";
import styles from "./ufonInfoTab.module.scss";

type PropsT = { ufon: UfonT };

export const UfonInfoTab: React.FC<PropsT> = ({ ufon }: PropsT) => {
  const isCompact = useCompactDesign();

  return (
    <>
      <div
        className={classNames(styles.tab, !isCompact && styles["tab--large"])}
      >
        <div
          className={classNames(
            styles.left,
            !isCompact && styles["left--large"]
          )}
        >
          <UfonCurrentInfoCard ufon={ufon} />
          {!isCompact && <UfonActivityCard ufon={ufon} />}
          {isCompact && <UfonConnectionCard ufonId={ufon.id} />}
        </div>
        <div
          className={classNames(
            styles.right,
            !isCompact && styles["right--large"]
          )}
        >
          {!isCompact && <UfonConnectionCard ufonId={ufon.id} />}
          {isCompact && <UfonActivityCard ufon={ufon} />}
          {!isCompact && (
            <div
              className={classNames(
                styles.map,
                !isCompact && styles["map--large"]
              )}
            >
              <CardWithHeader
                className={styles["map-body"]}
                bodyClassName={styles["map-body"]}
                title="Last position"
              >
                <UfonMapTab ufon={ufon} />
              </CardWithHeader>
            </div>
          )}
          <UfonGeneralInfoCard ufon={ufon} />
        </div>
      </div>
    </>
  );
};
