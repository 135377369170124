import React, { FC, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Container } from "reactstrap";
import { StyledButton } from "../../components/atoms/Button/Button";
import { PageWrapper } from "../../components/atoms/PageWrapper/PageWrapper";
import SubHeader from "../../components/atoms/SubHeader/SubHeader";
import {
  FormUser,
  UserInputsT,
} from "../../components/molecules/FormUser/FormUser";
import { Layout } from "../../components/organisms/Layout/Layout";
import { WINDOW_TITLE_NAMES } from "../../constants";
import { useMutationUserUpdate } from "../../hooks/useMutationUserUpdate";
import { useQueryUserGet, useQueryCompaniesGet } from "../../hooks/useQueries";
// import { useSelector } from "../../store/hooks";

type PropsT = {};

export const UserEditPage: FC<PropsT> = () => {
  document.title = WINDOW_TITLE_NAMES.users;
  const [isFormTouched, setIsFormTouched] = useState(false);
  const { userId } = useParams();
  const navigate = useNavigate();

  const { data: userData, isLoading } = useQueryUserGet(userId as string);
  const editUserMutation = useMutationUserUpdate(userId as string);

  // const { user } = useSelector((state) => state.user);
  const { data: companies } = useQueryCompaniesGet();

  const handleSubmit = (values: UserInputsT) => {
    editUserMutation.mutate({ ...values, role: Number(values.role) });
  };

  return (
    <Layout>
      <SubHeader
        isDetail
        title="Edit user"
        actions={
          <>
            <StyledButton
              onClick={() => navigate(-1)}
              color="dark"
              outline
              title="Cancel"
            />
            <StyledButton
              disabled={!isFormTouched}
              color="primary"
              type="submit"
              form="user-form"
              isLoading={editUserMutation.isLoading}
            >
              {!editUserMutation.isLoading ? "Save" : "Saving"}
            </StyledButton>
          </>
        }
      />
      <PageWrapper>
        <Container>
          <FormUser
            onTouch={() => setIsFormTouched(true)}
            isEditingMode={true}
            onSubmit={handleSubmit}
            defaultUserData={userData?.data}
            isDisabledAll={isLoading}
            companies={companies?.data.items || []}
          />
        </Container>
      </PageWrapper>
    </Layout>
  );
};
