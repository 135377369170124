import React from "react";
import { UfonT } from "../../../types/Ufon";
import CardWithHeader from "../../../components/atoms/CardWithHeader/CardWithHeader";
import DeviceDetailStatusRow from "../../../components/molecules/DeviceInfo/DeviceDetailStatusRow";
import { getGeneralData } from "../functions";

type PropsT = {
  ufon: UfonT;
};

export const UfonGeneralInfoCard: React.FC<PropsT> = ({ ufon }: PropsT) => {
  return (
    <CardWithHeader title="Info">
      {getGeneralData(ufon).map((item, index) => (
        <DeviceDetailStatusRow key={`${item.label}-${index}`} data={item} />
      ))}
    </CardWithHeader>
  );
};
