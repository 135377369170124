import { Polyline } from "@react-google-maps/api";
import { useSelector } from "../../../../store/hooks";
import { LatLngT } from "../../../../types/Route";
import { getMowerFuturePolylineOptions } from "../../../../functions/getMowerPolylineOptions";
import { useEffect, useState } from "react";

type Props = {
  mowerId: number;
  segmentId?: number;
  strokeColor: string;
  isVisible: boolean;
  isDirectionShowing: boolean;
};

const NextCoordinatesPolylines = ({
  mowerId,
  segmentId,
  strokeColor,
  isVisible,
  isDirectionShowing,
}: Props) => {
  const nextCoordinates = useSelector(
    (store) => store.jobRouteType.nextCoordinates
  );
  let coordinatesToShow: LatLngT[] | undefined;

  if (segmentId) {
    coordinatesToShow = nextCoordinates[mowerId]?.segment.find(
      (segment) => segment.id === segmentId
    )?.coordinates;
  } else {
    coordinatesToShow = nextCoordinates[mowerId]?.general.coordinates;
  }

  const [hidden, setHidden] = useState(false);

  useEffect(() => {
    const id = setTimeout(
      () => setHidden((prev) => !prev),
      hidden ? 2000 : 1000
    );
    return () => clearTimeout(id);
  }, [hidden]);

  if (!coordinatesToShow) return null;

  return (
    <Polyline
      path={coordinatesToShow}
      options={getMowerFuturePolylineOptions(strokeColor, isDirectionShowing)}
      visible={isVisible && !hidden}
    />
  );
};

export default NextCoordinatesPolylines;
