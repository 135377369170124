import React, { FC, ReactNode } from "react";
import styles from "./styles.module.scss";

type PropsT = {
  children: string | ReactNode;
};

const LinkText: FC<PropsT> = ({ children }) => {
  return <span className={styles["device-name"]}>{children}</span>;
};

export default LinkText;
