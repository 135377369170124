import React, { useEffect, useState } from "react";
import { Card, CardBody, CardHeader } from "reactstrap";
import { JobStateProps } from "../types";
import { useDispatch } from "react-redux";
import classNames from "classnames";
import { useSelector } from "../../../store/hooks";
import { findLastCreatedSegments, getLastSegments } from "../utils";
import { useMutationRemoveSegments } from "../../../hooks/useMutationRemoveSegments";
import { useQueryJobSegmentListGet } from "../../../hooks/useQueries";
import {
  changeActiveSegmentsLayers,
  toggleActiveSegmentsLayers,
} from "../../../store/slices/jobRouteTypeSlice";
import { RouteLayers } from "../../../types/Route";
import { MowersBySegment } from "../../../types/Job";
import { Loader } from "../../../components/atoms/Loader/Loader";
import { Icon } from "../../../components/atoms/Icon";
import { Cards } from "../../../components/atoms/Cards/Cards";
import { StyledButton } from "../../../components/atoms/Button/Button";
import styles from "./jobSegmentList.module.scss";

const minOneFinishedIsOn = (
  activeSegmentsLayers: Record<number, RouteLayers>,
  finishedSegments: MowersBySegment[]
) => {
  let oneFinishedIsOn = false;
  for (const segment of finishedSegments) {
    if (activeSegmentsLayers[segment.segmentId]?.checkedAll) {
      oneFinishedIsOn = true;
      break;
    }
  }
  return oneFinishedIsOn;
};

const SegmentsOverview = ({
  job,
  jobId,
  setCurrentPage,
  refetchJob,
  isJobRefetching,
  mapComponent,
}: JobStateProps) => {
  const dispatch = useDispatch();

  const { data: segmentsList, isLoading } = useQueryJobSegmentListGet(jobId);

  const {
    lastCreatedSegmentIds,
    activeSegmentsLayers,
    segmentIsLoading,
    routeIsLoading,
  } = useSelector((store) => store.jobRouteType);

  const lastCreatedSegments = findLastCreatedSegments(
    lastCreatedSegmentIds,
    segmentsList?.data
  );
  const finishedSegments = segmentsList?.data.flatMap((user) =>
    user.mowers.filter((segment) => segment.status === "finished")
  );

  const [showMap, setShowMap] = useState(
    window.innerWidth < 1054 && window.innerWidth > 768
  );

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 1054 && window.innerWidth > 768) {
        setShowMap(true);
      }
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  const [finishedSegmentsOn, setFinishedSegmentsOn] = useState(false);

  useEffect(() => {
    dispatch(
      changeActiveSegmentsLayers({ segmentIdsToShow: lastCreatedSegmentIds })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!activeSegmentsLayers || !finishedSegments) return;
    setFinishedSegmentsOn(
      minOneFinishedIsOn(activeSegmentsLayers, finishedSegments)
    );
  }, [activeSegmentsLayers, finishedSegments]);

  const removeSegments = useMutationRemoveSegments(jobId);

  const handleShowSegment = (segmentIds: number[]) => {
    dispatch(toggleActiveSegmentsLayers({ layer: "all", segmentIds }));
  };

  const removeHandler = async () => {
    await removeSegments.mutateAsync();
    refetchJob();
    setCurrentPage("segmentsForm");
  };

  if (isLoading || segmentIsLoading || routeIsLoading || isJobRefetching)
    return <Loader isCentered />;

  if (!segmentsList) return <div>No segment data</div>;

  return (
    <>
      {showMap && <div className={styles.map}>{mapComponent}</div>}
      {activeSegmentsLayers && (
        <Cards className={styles.list}>
          {lastCreatedSegments.map(
            (user) =>
              user.mowers.length !== 0 && (
                <Card className={styles.segment} key={`user-${user.id}`}>
                  <CardHeader className={styles["segment-header"]}>
                    <p className={styles["segment-header-text"]}>{user.name}</p>
                  </CardHeader>
                  {user.mowers.map((segment) => (
                    <React.Fragment key={`mower-${segment.segmentId}`}>
                      <CardBody
                        className={classNames(
                          styles["segment-item"],
                          styles["segment-item--overview"]
                        )}
                        style={{
                          borderLeftColor: segment.segmentColor.primaryColor,
                          borderLeftWidth: "5px",
                          borderLeftStyle: "solid",
                        }}
                      >
                        <div className={styles["segment-item-left"]}>
                          <Icon
                            icon="mower"
                            className={styles["segment-item-icon"]}
                          />
                          <p className={styles["segment-item-text"]}>
                            {segment.name} / {segment.segmentName}
                          </p>
                        </div>
                        <StyledButton
                          kind="iconButton"
                          icon={
                            activeSegmentsLayers[segment.segmentId]?.checkedAll
                              ? "toggle-on"
                              : "toggle-off"
                          }
                          className={styles.toggle}
                          onClick={() => handleShowSegment([segment.segmentId])}
                        />
                      </CardBody>
                    </React.Fragment>
                  ))}
                </Card>
              )
          )}
        </Cards>
      )}
      {activeSegmentsLayers && finishedSegments && finishedSegments.length > 0 && (
        <Cards className={styles.list}>
          <div className={classNames("d-flex justify-content-between px-1")}>
            <h5>Finished segments</h5>
            <StyledButton
              kind="iconButton"
              icon={finishedSegmentsOn ? "toggle-on" : "toggle-off"}
              className={styles.toggle}
              onClick={() => {
                handleShowSegment(finishedSegments.map((seg) => seg.segmentId));
                setFinishedSegmentsOn((prev) => !prev);
              }}
            />
          </div>
          <>
            {finishedSegments.map((segment) => (
              <CardBody
                key={`mower-${segment.segmentId}`}
                className={classNames(
                  styles["segment-item"],
                  styles["segment-item--overview"]
                )}
                style={{
                  borderLeftColor: segment.segmentColor.primaryColor,
                  borderLeftWidth: "5px",
                  borderLeftStyle: "solid",
                }}
              >
                <div className={styles["segment-item-left"]}>
                  <Icon icon="mower" className={styles["segment-item-icon"]} />
                  <p className={styles["segment-item-text"]}>
                    {segment.name} / {segment.segmentName}
                  </p>
                </div>
                <StyledButton
                  kind="iconButton"
                  icon={
                    activeSegmentsLayers[segment.segmentId]?.checkedAll
                      ? "toggle-on"
                      : "toggle-off"
                  }
                  className={styles.toggle}
                  onClick={() => {
                    handleShowSegment([segment.segmentId]);
                  }}
                />
              </CardBody>
            ))}
          </>
        </Cards>
      )}
      <div className={styles.footerButtons}>
        <StyledButton
          title="Remove segments and start over"
          color="light"
          onClick={removeHandler}
          className={styles.formBtn}
        />
        <StyledButton
          title="Back to job"
          color="primary"
          onClick={() => {
            dispatch(
              changeActiveSegmentsLayers({
                segmentIdsToShow: getLastSegments(job),
              })
            );
            setCurrentPage("jobOverview");
          }}
          className={styles.formBtn}
        />
      </div>
    </>
  );
};

export default SegmentsOverview;
