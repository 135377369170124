import React, { FC } from "react";
import { Button, Card, CardBody } from "reactstrap";
import { useMutationJobCommentCreate } from "../../../hooks/useMutationJobCommentCreate";
import { useQueryJobCommentsGet } from "../../../hooks/useQueries";
import { JobCommentT } from "../../../types/Job";
import { Cards } from "../../atoms/Cards/Cards";
import {
  FormJobComment,
  JobCommentInputsT,
} from "../../molecules/FormJobComment/FormJobComment";
import styles from "./JobComments.module.scss";
import { JobCommentsList } from "./JobCommentsList";

type PropsT = {
  jobId: string | number;
};

export interface CompleteCommentT extends JobCommentT {
  authorName?: string;
}

export const JobComments: FC<PropsT> = ({ jobId }) => {
  const {
    data: comments,
    refetch,
    isRefetching,
  } = useQueryJobCommentsGet(jobId);

  const handleRefetchComments = () => {
    refetch();
  };

  const mutationCommentCreate = useMutationJobCommentCreate(jobId, {
    onSuccess: handleRefetchComments,
  });

  const handleSubmit = (values: JobCommentInputsT) => {
    mutationCommentCreate.mutate(values);
  };

  return (
    <div className={styles.wrapper}>
      <Cards>
        <Card>
          <CardBody>
            <FormJobComment onSubmit={handleSubmit} comments={comments?.data} />
            <div className="text-end">
              <Button
                color="light"
                form="job-comment-form"
                disabled={isRefetching || mutationCommentCreate.isLoading}
              >
                {isRefetching || mutationCommentCreate.isLoading
                  ? "Posting comment"
                  : "Post comment"}
              </Button>
            </div>

            {comments && <JobCommentsList comments={comments.data} />}
          </CardBody>
        </Card>
      </Cards>
    </div>
  );
};
