import { Polyline } from "@react-google-maps/api";
import React from "react";
import { sortCoordinatesByMode } from "./utils";
import { Loader } from "../../atoms/Loader/Loader";
import { getMowerPolylineOptions } from "../../../functions/getMowerPolylineOptions";
import { useQueryMissedCoordinates } from "../../../hooks/useQueries";

type Props = {
  jobId: string | number;
  mowerId: number;
  segmentId?: number;
  strokeColor: string;
  isDirectionShowing: boolean;
  showActivity: boolean;
};

export const MissedCoordinatesPolylines = React.memo(
  ({
    jobId,
    mowerId,
    segmentId,
    strokeColor,
    isDirectionShowing,
    showActivity,
  }: Props) => {
    const { data, isLoading: missedIsLoading } = useQueryMissedCoordinates(
      jobId,
      mowerId,
      segmentId
    );
    const missedCoordinates =
      data?.data[0]?.coordinates.flatMap((coordinateSet) =>
        sortCoordinatesByMode(coordinateSet)
      ) || [];

    if (missedIsLoading) {
      return <Loader isCentered />;
    }

    return (
      <>
        {missedCoordinates.map((coordinates, index) => (
          <Polyline
            path={coordinates}
            key={index}
            visible={showActivity}
            options={getMowerPolylineOptions(
              strokeColor,
              isDirectionShowing,
              coordinates[0].knivesOn ?? true
            )}
          />
        ))}
      </>
    );
  }
);

export default MissedCoordinatesPolylines;
