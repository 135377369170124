import React, { FC } from "react";
import { SubmitHandler } from "react-hook-form";
import { toast } from "react-toastify";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { queryClient } from "../../..";
import { useMutationRouteGroupRename } from "../../../hooks/useMutationRouteGroupRename";
import {
  FormRouteGroup,
  FormRouteGroupInputsT,
} from "../../molecules/FormRouteGroup/FormRouteGroup";

type PropsT = {
  isOpen: boolean;
  groupId: number | undefined;
  onClose: () => void;
};

export const RouteGroupRenameModal: FC<PropsT> = ({
  isOpen,
  groupId,
  onClose,
}) => {
  const renameGroupMutation = useMutationRouteGroupRename({
    onSuccess: () => {
      onClose();
      queryClient.invalidateQueries(["group"]);
    },
  });

  const handleSubmitGroupCreate: SubmitHandler<FormRouteGroupInputsT> = async ({
    name,
  }) => {
    if (groupId) {
      renameGroupMutation.mutate({ name, groupId });
    } else {
      toast.error("Renaming failed on folder ID.");
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={onClose}>
      <ModalHeader toggle={onClose}>Rename group</ModalHeader>
      <ModalBody>
        <FormRouteGroup onSubmit={handleSubmitGroupCreate} />
      </ModalBody>
      <ModalFooter>
        <Button color="light" onClick={onClose}>
          Cancel
        </Button>
        <Button
          color="primary"
          type="submit"
          form="route-group-form"
          className="me-2"
        >
          Rename
        </Button>
      </ModalFooter>
    </Modal>
  );
};
