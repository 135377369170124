import { FC, ReactNode } from "react";
import { Icon } from "../../atoms/Icon";
import classNames from "classnames";
import style from "./remoteActionNeededSign.module.scss";

type PropsT = {
  active: boolean;
  className?: string;
  children: ReactNode;
};

export const RemoteActionNeededSign: FC<PropsT> = ({
  active,
  className,
  children,
}: PropsT) => {
  return (
    <div
      className={classNames(className, style.container, {
        [style["container--active"]]: active,
      })}
    >
      {active && <Icon icon="remote-control" className={style.icon} />}
      {children}
    </div>
  );
};
