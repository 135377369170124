import { useMutation } from "react-query";

import { JobCommentInputsT } from "../components/molecules/FormJobComment/FormJobComment";
import { client } from "../services/axios";
import { MutationOptionsT } from "../types/ReactQuery";

export const useMutationJobCommentCreate = (
  jobId: string | number,
  options?: MutationOptionsT
) => {
  return useMutation(
    (data: JobCommentInputsT) => {
      return client.post(`/api/v1/job/${jobId}/comment`, data);
    },
    {
      onSuccess: () => {
        if (options?.onSuccess) {
          options.onSuccess();
        }
      },
      onError: () => {
        if (options?.onError) {
          options.onError();
        }
      },
    }
  );
};
