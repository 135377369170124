import { MapLayers, RouteLayers } from "../../types/Route";

export const setLayers = (
  layer: MapLayers,
  obj: RouteLayers,
  setSegmentLayers: boolean
) => {
  let newState = { ...obj };
  if (layer === "endPoint") {
    newState = { ...newState, endPointChecked: !newState.endPointChecked };
  } else if (layer === "startPoint") {
    newState = { ...newState, startPointChecked: !newState.startPointChecked };
  } else if (layer === "route") {
    newState = { ...newState, routeChecked: !newState.routeChecked };
  } else if (layer === "activity") {
    newState = { ...newState, activityChecked: !newState.activityChecked };
  } else {
    if (newState.checkedAll) {
      newState = {
        ...newState,
        checkedAll: false,
        endPointChecked: false,
        startPointChecked: false,
        routeChecked: false,
        ...(setSegmentLayers && { activityChecked: false }),
      };
    } else {
      newState = {
        ...newState,
        checkedAll: true,
        endPointChecked: true,
        startPointChecked: true,
        routeChecked: true,
        ...(setSegmentLayers && { activityChecked: true }),
      };
    }
  }
  const checkedAll = setSegmentLayers
    ? newState.startPointChecked ||
      newState.endPointChecked ||
      newState.routeChecked ||
      newState.activityChecked
    : newState.startPointChecked ||
      newState.endPointChecked ||
      newState.routeChecked;

  newState = {
    ...newState,
    checkedAll,
  };

  return newState;
};
