import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import React, { FC, useCallback, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { Card, CardBody, Form } from "reactstrap";
import { InputController } from "../Form/InputController";
import { formRouteSchema } from "./validations";
import { Cards } from "../../atoms/Cards/Cards";
import { RouteGroupT, RouteT } from "../../../types/Route";
import { SelectOptionsT } from "../../../types/Common";

export type RouteInputsT = {
  name: string;
  group: string;
  file: FileList;
};

type PropsT = {
  defaultValues?: RouteT;
  onTouch: () => void;
  onSubmit: (v: RouteInputsT) => void;
  routeGroups?: RouteGroupT[];
  isDisabledAll?: boolean;
  isEditingMode?: boolean;
};

export const FormRoute: FC<PropsT> = ({
  onTouch,
  onSubmit,
  routeGroups,
  defaultValues,
  isDisabledAll,
  isEditingMode,
}) => {
  const getDefaultValues = useCallback(
    () => ({
      name: defaultValues?.name || "",
      group: `${defaultValues?.folderId}` || "",
      file: undefined,
    }),
    [defaultValues]
  );

  const selectRouteGroupsOptions = useMemo(
    () =>
      (
        [{ value: "", label: "Choose group", disabled: true }] as SelectOptionsT
      ).concat(
        (routeGroups || []).map((group) => ({
          label: group.name,
          value: group.id,
        }))
      ),
    [routeGroups]
  );

  const {
    handleSubmit,
    control,
    formState: { errors, isDirty },
    reset,
  } = useForm<RouteInputsT>({
    resolver: yupResolver(formRouteSchema(!!isEditingMode)),
    defaultValues: getDefaultValues(),
  });

  useEffect(() => {
    reset(getDefaultValues());
  }, [getDefaultValues, reset]);

  useEffect(() => {
    if (isDirty) {
      onTouch();
    }
  }, [onTouch, isDirty]);

  return (
    <Form onSubmit={handleSubmit(onSubmit)} id="route-form">
      <Cards>
        <Card>
          <CardBody>
            <InputController
              name="name"
              label="Name"
              control={control}
              errors={errors}
              input={{ disabled: isDisabledAll }}
            />
            <>
              <InputController
                name="group"
                label="Group"
                control={control}
                errors={errors}
                options={selectRouteGroupsOptions}
                input={{ disabled: isDisabledAll }}
              />
              {!isEditingMode && (
                <InputController
                  name="file"
                  label="File (kml)"
                  control={control}
                  errors={errors}
                  input={{ type: "file", disabled: isDisabledAll }}
                />
              )}
            </>
          </CardBody>
        </Card>
      </Cards>
    </Form>
  );
};
