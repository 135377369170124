import { ReactElement, FC } from "react";
import classNames from "classnames";
import { useCompactDesign } from "../../../hooks/useCompactDesign";
import styles from "./pageWrapper.module.scss";

type Props = {
  children: ReactElement;
};

export const PageWrapper: FC<Props> = ({ children }) => {
  const isCompact = useCompactDesign();
  return (
    <div
      className={classNames(
        styles["page-wrapper"],
        !isCompact && styles["page-wrapper--large"]
      )}
    >
      {children}
    </div>
  );
};
