import { FC } from "react";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";
import { ROUTES } from "../../../routes";
import { NearbyMowerT } from "../../../types/Mower";
import LinkText from "../../atoms/LinkText/LinkText";
import { Icon } from "../../atoms/Icon";
import styles from "./styles.module.scss";
import { useCompactDesign } from "../../../hooks/useCompactDesign";

type Props = {
  mower: NearbyMowerT;
};

export const NearbyMowerRow: FC<Props> = ({ mower }) => {
  const navigate = useNavigate();
  const link = ROUTES.mower(mower.mower.id);
  const isCompact = useCompactDesign();

  return (
    <div
      onClick={() => navigate(link)}
      className={classNames(
        styles["device-row-wrap"],
        isCompact && styles["device-row-wrap-compact"]
      )}
    >
      <div className={"d-flex align-items-center gap-3"}>
        <LinkText>{mower.mower.name}</LinkText>
      </div>
      <div
        className={classNames(
          styles["fs-7"],
          "d-flex",
          " align-items-center",
          "gap-2"
        )}
      >
        <div>
          Distance: <strong>{mower.distance} m</strong>
        </div>
      </div>
      <div className={"d-flex justify-content-end align-items-center"}>
        <Icon className={styles["icon-blue"]} icon={"chevron-right"} />
      </div>
    </div>
  );
};
