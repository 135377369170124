import { useState, useEffect, RefObject } from "react";

export const useObserveHeight = (observedRef: RefObject<HTMLElement>) => {
  const [height, setHeight] = useState(0);
  useEffect(() => {
    if (!observedRef.current) {
      return;
    }
    const observer = new ResizeObserver((entries: ResizeObserverEntry[]) => {
      for (const entry of entries) {
        if (entry.borderBoxSize && entry.borderBoxSize[0]) {
          setHeight(entry.borderBoxSize[0].blockSize);
        }
      }
    });
    observer.observe(observedRef.current);
    return () => observer.disconnect();
  }, [observedRef]);

  return height;
};
