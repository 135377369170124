const loadEnv = () => {
  try {
    const GOOGLE_MAP_API_KEY = process.env.REACT_APP_GOOGLE_MAP_API_KEY;
    const CLIENT_SECRET = process.env.REACT_APP_CLIENT_SECRET;
    const API_URL = process.env.REACT_APP_API_URL;
    const SOCKET_URL = process.env.REACT_APP_SOCKET_URL;
    const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT;

    if (!GOOGLE_MAP_API_KEY) {
      throw new Error("Missing .env variable: GOOGLE_MAP_API_KEY");
    }
    if (!CLIENT_SECRET) {
      throw new Error("Missing .env variable: CLIENT_SECRET");
    }
    if (!API_URL) {
      throw new Error("Missing .env variable: API_URL");
    }
    if (!SOCKET_URL) {
      throw new Error("Missing .env variable: SOCKET_URL");
    }
    if (!ENVIRONMENT) {
      throw new Error("Missing .env variable: ENVIRONMENT");
    }

    return {
      GOOGLE_MAP_API_KEY,
      CLIENT_SECRET,
      API_URL,
      SOCKET_URL,
      ENVIRONMENT,
    };
  } catch (e) {
    if (e instanceof Error) {
      // eslint-disable-next-line
      console.error(e);
    }
  }
};

export const {
  GOOGLE_MAP_API_KEY,
  CLIENT_SECRET,
  API_URL,
  SOCKET_URL,
  ENVIRONMENT,
} = loadEnv() || {
  GOOGLE_MAP_API_KEY: "",
  CLIENT_SECRET: "",
  API_URL: "",
  SOCKET_URL: "",
  ENVIRONMENT: "",
};

const loadFirebaseEnv = () => {
  try {
    const FIREBASE_API_KEY = process.env.REACT_APP_FIREBASE_API_KEY;
    const FIREBASE_AUTH_DOMAIN = process.env.REACT_APP_FIREBASE_AUTH_DOMAIN;
    const FIREBASE_PROJECT_ID = process.env.REACT_APP_FIREBASE_PROJECT_ID;
    const FIREBASE_STORAGE_BUCKET =
      process.env.REACT_APP_FIREBASE_STORAGE_BUCKET;
    const FIREBASE_MESSAGING_SENDER_ID =
      process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID;
    const FIREBASE_APP_ID = process.env.REACT_APP_FIREBASE_APP_ID;
    const FIREBASE_MEASUREMENT_ID =
      process.env.REACT_APP_FIREBASE_MEASUREMENT_ID;
    const FIREBASE_VAPID_KEY = process.env.REACT_APP_FIREBASE_VAPID_KEY;

    if (!FIREBASE_API_KEY) {
      throw new Error("Missing .env variable: FIREBASE_API_KEY");
    }
    if (!FIREBASE_AUTH_DOMAIN) {
      throw new Error("Missing .env variable: FIREBASE_AUTH_DOMAIN");
    }
    if (!FIREBASE_PROJECT_ID) {
      throw new Error("Missing .env variable: FIREBASE_PROJECT_ID");
    }
    if (!FIREBASE_STORAGE_BUCKET) {
      throw new Error("Missing .env variable: FIREBASE_STORAGE_BUCKET");
    }
    if (!FIREBASE_MESSAGING_SENDER_ID) {
      throw new Error("Missing .env variable: FIREBASE_MESSAGING_SENDER_ID");
    }
    if (!FIREBASE_APP_ID) {
      throw new Error("Missing .env variable: FIREBASE_APP_ID");
    }
    if (!FIREBASE_MEASUREMENT_ID) {
      throw new Error("Missing .env variable: FIREBASE_MEASUREMENT_ID");
    }
    if (!FIREBASE_VAPID_KEY) {
      throw new Error("Missing .env variable: FIREBASE_VAPID_KEY");
    }

    return {
      apiKey: FIREBASE_API_KEY,
      authDomain: FIREBASE_AUTH_DOMAIN,
      projectId: FIREBASE_PROJECT_ID,
      storageBucket: FIREBASE_STORAGE_BUCKET,
      messagingSenderId: FIREBASE_MESSAGING_SENDER_ID,
      appId: FIREBASE_APP_ID,
      measurementId: FIREBASE_MEASUREMENT_ID,
      vapidKey: FIREBASE_VAPID_KEY,
    };
  } catch (e) {
    if (e instanceof Error) {
      // eslint-disable-next-line
      console.error(e);
    }
  }
};

export const firebaseConfig = loadFirebaseEnv() || {
  apiKey: "",
  authDomain: "",
  projectId: "",
  storageBucket: "",
  messagingSenderId: "",
  appId: "",
  measurementId: "",
  vapidKey: "",
};
