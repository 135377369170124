import React from "react";
import { UfonT } from "../../../types/Ufon";
import DeviceDetailMap from "../../../components/molecules/DeviceDetailMap/DeviceDetailMap";
import { DeviceTypes } from "../../../types/Common";

type PropsT = {
  ufon: UfonT;
  className?: string;
};

export const UfonMapTab: React.FC<PropsT> = ({ ufon, className }: PropsT) => {
  return (
    <DeviceDetailMap
      deviceType={DeviceTypes.ufon}
      center={{ lat: ufon.latitude, lng: ufon.longitude }}
      deviceId={ufon.id}
      mapContainerClassName={className}
    />
  );
};
