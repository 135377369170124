import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import React, { FC, useCallback, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { Card, CardBody, Col, Form, Row } from "reactstrap";
import { MowerT } from "../../../types/Mower";
import { DeviceFormInput, SelectOptionsT } from "../../../types/Common";
import { formatDate } from "../../../functions/formatDate";
import { formMowerSchema } from "./validations";
import { useQueryCompaniesGet } from "../../../hooks/useQueries";
import { DATE_FORMATS } from "../../../constants";
import { Cards } from "../../atoms/Cards/Cards";
import { InputController } from "../Form/InputController";

type PropsT = {
  defaultValues?: MowerT;
  onSubmit: (v: DeviceFormInput) => void;
  onTouch: () => void;
  isDisabledAll?: boolean;
};

export const FormMower: FC<PropsT> = ({
  onSubmit,
  onTouch,
  defaultValues,
  isDisabledAll,
}) => {
  const getDefaultValues = useCallback(
    () => ({
      title: defaultValues?.name ?? "",
      name: defaultValues?.internalName ?? "",
      serialNumber: defaultValues?.info.serialNumber ?? "",
      smautSerialNumber: defaultValues?.info.smautSerialNumber ?? "",
      spiderSerialNumber: defaultValues?.info.spiderSerialNumber ?? "",
      companyId: defaultValues?.info.company.id.toString() ?? "",
      purchaseDate: defaultValues?.purchaseDate
        ? formatDate(defaultValues?.purchaseDate, DATE_FORMATS.dateForm)
        : "",
    }),
    [defaultValues]
  );

  const {
    handleSubmit,
    control,
    formState: { errors, isDirty },
    reset,
  } = useForm<DeviceFormInput>({
    resolver: yupResolver(formMowerSchema),
    defaultValues: getDefaultValues(),
  });

  const { data: companiesData, isLoading } = useQueryCompaniesGet();

  const selectCompanyOptions = useMemo(
    () =>
      (
        [
          { value: "", label: "Choose company", disabled: true },
        ] as SelectOptionsT
      ).concat(
        (companiesData?.data.items || []).map((company) => ({
          label: company.name,
          value: company.id,
        }))
      ),

    [companiesData]
  );

  useEffect(() => {
    reset(getDefaultValues());
  }, [getDefaultValues, reset]);

  useEffect(() => {
    if (isDirty) {
      onTouch();
    }
  }, [onTouch, isDirty]);

  return (
    <Form onSubmit={handleSubmit(onSubmit)} id="mower-form">
      <Cards>
        <Card>
          <CardBody>
            <Row>
              <Col lg="6">
                <InputController
                  required
                  name="title"
                  label="Title"
                  control={control}
                  errors={errors}
                  input={{ disabled: isDisabledAll }}
                />
                <InputController
                  required
                  name="companyId"
                  label="Company"
                  control={control}
                  errors={errors}
                  options={selectCompanyOptions}
                  input={{
                    disabled: isDisabledAll || isLoading,
                  }}
                />
                <InputController
                  required
                  name="serialNumber"
                  label="Control unit serial number"
                  control={control}
                  errors={errors}
                  input={{ disabled: isDisabledAll }}
                />
                <InputController
                  name="smautSerialNumber"
                  label="SMAUT serial number"
                  control={control}
                  errors={errors}
                  input={{ disabled: isDisabledAll }}
                />
                <InputController
                  name="spiderSerialNumber"
                  label="Spider serial number"
                  control={control}
                  errors={errors}
                  input={{ disabled: isDisabledAll }}
                />
                <InputController
                  required
                  name="name"
                  label="Internal code"
                  control={control}
                  errors={errors}
                  input={{ disabled: isDisabledAll }}
                />
                <InputController
                  name="purchaseDate"
                  label="Purchase date"
                  control={control}
                  errors={errors}
                  input={{
                    type: "date",
                    disabled: isDisabledAll,
                  }}
                />
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Cards>
    </Form>
  );
};
