import { useEffect, useState } from "react";

export const useDeviceScreenWidthInterval = (lower: number, higher: number) => {
  const [isInInterval, setIsInInterval] = useState(
    window?.innerWidth < higher && window?.innerWidth > lower
  );

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < higher && window.innerWidth > lower) {
        setIsInInterval(true);
      } else {
        setIsInInterval(false);
      }
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [lower, higher]);

  return isInInterval;
};
