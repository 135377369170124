import { FC } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import { Control, FormState } from "react-hook-form";
import { SelectOptionsT } from "../../../types/Common";
import { formatDate } from "../../../functions/formatDate";
import { InputController } from "../Form/InputController";
import { JobCreateFormFieldsT } from "./FormJobCreate";

type PropsT = {
  control: Control<JobCreateFormFieldsT, object>;
  formState: FormState<JobCreateFormFieldsT>;
  selectUserOptions: SelectOptionsT;
  isDisabledAll?: boolean;
  isLoadingUsers: boolean;
  chooseUser?: boolean;
};

export const FormBaseMetadataCard: FC<PropsT> = ({
  control,
  formState,
  selectUserOptions,
  isDisabledAll,
  isLoadingUsers,
  chooseUser,
}: PropsT) => {
  const { errors } = formState;
  return (
    <Card>
      <CardBody>
        <Row>
          <Col lg="6">
            <InputController
              name="name"
              label="Title"
              control={control}
              errors={errors}
              placeholder="Set job title"
              input={{ disabled: isDisabledAll }}
            />
          </Col>
        </Row>
        <Row>
          <Col lg="6">
            <InputController
              name="dueDate"
              label="Due date"
              input={{
                type: "date",
                min: formatDate(new Date(), "dateBackend"),
                disabled: isDisabledAll,
              }}
              control={control}
              errors={errors}
            />
          </Col>
        </Row>

        {chooseUser && (
          <Row>
            <Col lg="6">
              <InputController
                name="userId"
                label={"Assign to"}
                control={control}
                errors={errors}
                options={selectUserOptions}
                input={{ disabled: isDisabledAll || isLoadingUsers }}
              />
            </Col>
          </Row>
        )}
      </CardBody>
    </Card>
  );
};
