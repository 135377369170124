import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import React, { FC } from "react";
import { useForm } from "react-hook-form";
import { Form } from "reactstrap";
import { InputController } from "../Form/InputController";
import { formLoginSchema } from "./validations";

export type FormLoginInputsT = {
  username: string;
  password: string;
};

type PropsT = {
  onSubmit: (inputs: FormLoginInputsT) => void;
};

export const FormLogin: FC<PropsT> = ({ onSubmit }) => {
  const getDefaultValues = () => ({
    username: "",
    password: "",
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<FormLoginInputsT>({
    resolver: yupResolver(formLoginSchema),
    defaultValues: getDefaultValues(),
  });

  return (
    <Form onSubmit={handleSubmit(onSubmit)} id="login-form" inline>
      <InputController
        name="username"
        control={control}
        errors={errors}
        input={{
          placeholder: "Email",
          bsSize: "lg",
          type: "email",
        }}
        formGroup={{
          className: "mb-2",
        }}
      />
      <InputController
        name="password"
        control={control}
        errors={errors}
        input={{
          placeholder: "Password",
          bsSize: "lg",
          type: "password",
          autoComplete: "password",
        }}
        formGroup={{
          className: "mb-4",
        }}
      />
    </Form>
  );
};
