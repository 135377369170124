import { FC, useState, memo, useRef, useEffect } from "react";
import { GoogleMap } from "@react-google-maps/api";
import { useNavigate } from "react-router";
import {
  CoordinatesT,
  CoordinateT,
  JobMapInfo,
  RoutePreviewT,
} from "../../../types/Route";
import { DeviceListItemT, MapMarkersT } from "../../../types/Common";
import {
  DEFAULT_SHOW_ACTIVITY_TYPES,
  JOB_ROUTE_TYPE_COLORS,
} from "../../../constants";
import { ROUTES } from "../../../routes";
import { onLoad } from "../../../functions/onMapLoad";
import { useUserLocation } from "../../../hooks/useUserLocation";
import MapOverlay from "../../molecules/MapOverlay/MapOverlay";
import MapControls from "../../molecules/MapControls/MapControls";
import JourneyPolylines from "../../molecules/JourneyPolylines/JourneyPolylines";
import JobMapInfoWindow from "../../molecules/MapInfoWindow/JobMapInfoWindow";
import { Loader } from "../../atoms/Loader/Loader";
import UserMapMarker from "../../atoms/UserMapMarker/UserMapMarker";
import MowerMarker from "../../atoms/MapMarkers/MowerMarker";
import {
  RoutePageLayers,
  RoutePageLayersType,
  activeLayersSetter,
  getMapBounds,
  getMapBoundsFromDevices,
} from "./functions";
import { MapPolylineControl } from "./MapPolylineControl";
import DropdownComponent from "./DropdownComponent";
import MapRouteSection from "./MapRouteSection";
import MapDeviceCluster from "./MapDeviceCluster";
import styles from "./map.module.scss";

type PropsT = {
  center: google.maps.LatLngLiteral;
  zoom: number;
  devices?: MapMarkersT[];
  mapType?: string;
  isLoading?: boolean;
  coordinates?: CoordinatesT<"route">;
  fitBounds?: boolean;
  mobileFullWidth?: boolean;
  onCoordinatesChange?: (updatedCoordinates: CoordinatesT<"route">) => void;
  availableRoutes?: RoutePreviewT[];
  availableRoutesSelected?: number;
  onAvailableRouteSelect?: (routeId: number) => void;
  showEditActions: boolean;
  selectedJourneys: number[];
  onlineMowers?: DeviceListItemT[];
};

const containerStyle = {
  height: "100%",
};

const Map: FC<PropsT> = ({
  center,
  zoom,
  devices,
  coordinates,
  mapType = "roadmap",
  fitBounds,
  isLoading,
  onCoordinatesChange,
  showEditActions,
  selectedJourneys,
  onlineMowers,
}) => {
  const navigate = useNavigate();
  const [stepMeters, setStepMeters] = useState(0.00005);

  const [dragStartData, setDragStartData] =
    useState<CoordinateT<"route"> | null>(null);

  const [showMowingArrows, setShowMowingArrows] = useState(false);

  const [editablePolyline, setEditablePolyline] = useState(0);

  const [activePolylineMarker, setActivePolylineMarker] = useState(-1);

  const [activeMowerMarker, setActiveMowerMarker] = useState<
    JobMapInfo | undefined
  >(undefined);

  const handlePolylineMarkerClick = (
    data: google.maps.MapMouseEvent,
    activeCoordinate: CoordinateT<"route">
  ) => {
    setEditablePolyline(activeCoordinate.id as number);
    const currentActiveMarker = activeCoordinate.coordinates.findIndex(
      (c) => c.lat === data?.latLng?.lat() && c.lng === data.latLng.lng()
    );

    if (currentActiveMarker !== -1) {
      setActivePolylineMarker(currentActiveMarker);
    }
  };

  const handleMapClick = () => {
    setEditablePolyline(0);
    setActivePolylineMarker(-1);
  };

  const handlePolylineDragStart = (
    event: google.maps.MapMouseEvent,
    originalCoordinates: CoordinateT<"route">
  ) => {
    handlePolylineMarkerClick(event, originalCoordinates);

    if (event.latLng?.lat() && event.latLng?.lng()) {
      setDragStartData(originalCoordinates);
    }
  };

  const handlePolylineDragEnd = (event: google.maps.MapMouseEvent) => {
    if (event.latLng?.lat() && event.latLng?.lng() && coordinates) {
      const newCoordinates: CoordinatesT<"route"> = {
        ...coordinates,
        coordinates: coordinates.coordinates.map((c) => {
          return c.id === dragStartData?.id
            ? {
                ...c,
                coordinates: c.coordinates.map((coordinate, index) =>
                  index === activePolylineMarker && event.latLng
                    ? {
                        lng: event.latLng.lng(),
                        lat: event.latLng.lat(),
                      }
                    : coordinate
                ),
              }
            : c;
        }),
      };

      if (newCoordinates) {
        onCoordinatesChange?.(newCoordinates);
      }
    }
    setDragStartData(null);
  };

  const mapRef = useRef<GoogleMap>(null);

  const { userLocation, mapCenter, setMapCenter, handleUserLocation } =
    useUserLocation(center, mapRef);

  useEffect(() => {
    if (!editablePolyline && fitBounds) {
      let bounds: google.maps.LatLngBounds;
      if (devices) {
        bounds = getMapBoundsFromDevices(devices);
      } else {
        bounds = getMapBounds(coordinates);
      }
      mapRef.current?.state.map?.fitBounds(bounds);
    }
  }, [coordinates, devices, editablePolyline, fitBounds]);

  const [activeRouteLayers, setActiveRouteLayers] = useState<RoutePageLayers>(
    {}
  );

  const handleUpdateCenter = () => {
    const lat = mapRef.current?.state.map?.getCenter()?.lat();
    const lng = mapRef.current?.state.map?.getCenter()?.lng();

    if (lat && lng) {
      setMapCenter({ lat, lng });
    }
  };

  const handleRouteLocation = () => {
    if (!coordinates || !mapRef.current?.state.map) {
      return;
    }
    const bounds: google.maps.LatLngBounds = getMapBounds(coordinates);
    mapRef.current.state.map.fitBounds(bounds);
  };
  useEffect(() => {
    if (coordinates) {
      const layers = activeLayersSetter(coordinates.coordinates);
      setActiveRouteLayers(layers);
    }
  }, [coordinates]);

  const resetActiveLayers = () => {
    setShowMowingArrows(false);
    if (coordinates) {
      setActiveRouteLayers(activeLayersSetter(coordinates.coordinates));
    } else {
      setActiveRouteLayers({});
    }
  };

  const handleRouteLayersChange = (
    ids: number[],
    type: RoutePageLayersType
  ) => {
    const activeType = activeRouteLayers[type];
    if (!activeType) return;
    let newIds = [...activeType];
    for (const id of ids) {
      if (newIds.includes(id)) {
        newIds = newIds.filter((i) => i !== id);
      } else {
        newIds.push(id);
      }
    }
    setActiveRouteLayers((prev) => ({ ...prev, [type]: newIds }));
  };

  const handleAllLayersByType = (ids: number[], type: RoutePageLayersType) => {
    const activeType = activeRouteLayers[type];
    if (!activeType) return;
    if (activeType.length !== 0) {
      setActiveRouteLayers((prev) => ({ ...prev, [type]: [] }));
    } else {
      setActiveRouteLayers((prev) => ({ ...prev, [type]: ids }));
    }
  };

  const showOverlay = !Boolean(center.lat) || !Boolean(center.lng);

  return (
    <div className={styles.map}>
      {isLoading && (
        <div className={styles.loader}>
          <Loader />
        </div>
      )}
      {showOverlay && <MapOverlay />}
      <GoogleMap
        ref={mapRef}
        onDragEnd={handleUpdateCenter}
        mapTypeId={mapType}
        tilt={0}
        mapContainerStyle={containerStyle}
        center={mapCenter}
        zoom={zoom}
        onClick={handleMapClick}
        onLoad={onLoad}
      >
        {devices && <MapDeviceCluster devices={devices} />}
        {userLocation && <UserMapMarker userLocation={userLocation} />}
        <MapControls
          dropdownComponent={
            Object.values(activeRouteLayers).length > 0 && coordinates ? (
              <DropdownComponent
                showMowingArrows={showMowingArrows}
                setShowMowingArrows={() => setShowMowingArrows((prev) => !prev)}
                activeRouteLayers={activeRouteLayers}
                coordinates={coordinates}
                handleRouteLayersChange={handleRouteLayersChange}
                handleAllLayersByType={handleAllLayersByType}
                resetActiveLayers={resetActiveLayers}
              />
            ) : undefined
          }
          handleUserLocation={handleUserLocation}
          handleHomeLocation={handleRouteLocation}
        />
        <MapPolylineControl
          editablePolyline={editablePolyline}
          stepMeters={stepMeters}
          currentCoordinates={coordinates}
          activePolylineMarker={activePolylineMarker}
          onSetStepMeters={setStepMeters}
          onCoordinatesChange={onCoordinatesChange}
        />
        {coordinates && (
          <MapRouteSection
            editablePolyline={editablePolyline}
            route={coordinates}
            showArrows={showMowingArrows}
            activeRouteLayers={activeRouteLayers}
            onClick={showEditActions ? handlePolylineMarkerClick : undefined}
            onMouseDown={showEditActions ? handlePolylineDragStart : undefined}
            onMouseUp={showEditActions ? handlePolylineDragEnd : undefined}
          />
        )}
        {selectedJourneys.map((journeyId, index) => {
          return (
            <JourneyPolylines
              key={`${journeyId}-${index}`}
              journeyIds={[journeyId]}
              strokeColor={JOB_ROUTE_TYPE_COLORS.activeDevice}
              isDirectionShowing={showMowingArrows}
              showActivity
              showActivityTypes={DEFAULT_SHOW_ACTIVITY_TYPES}
            />
          );
        })}
        {onlineMowers &&
          onlineMowers.map((mower) => {
            return (
              <MowerMarker
                key={mower.id}
                mowerId={mower.id}
                setActiveMarker={setActiveMowerMarker}
                showMarker
              />
            );
          })}
        {activeMowerMarker && (
          <JobMapInfoWindow
            onClose={() => setActiveMowerMarker(undefined)}
            onClick={() => navigate(ROUTES.mower(activeMowerMarker.deviceId))}
            mowerId={activeMowerMarker.deviceId}
            icon="mower"
            title={activeMowerMarker.name}
            position={{
              lat: activeMowerMarker.lat,
              lng: activeMowerMarker.lng,
            }}
          />
        )}
      </GoogleMap>
    </div>
  );
};

export default memo(Map);
