import React, { FC } from "react";
import classNames from "classnames";
import { NewDiode } from "../NewDiode/NewDiode";
import styles from "./mower.module.scss";
import { LedT } from "../../../types/Common";

interface PropsT {
  remoteLed?: LedT;
  rtkLed?: LedT;
  leftLed?: LedT;
  middleLed?: LedT;
  rightLed?: LedT;
}

export const Mower: FC<PropsT> = ({
  remoteLed,
  rtkLed,
  leftLed,
  middleLed,
  rightLed,
}) => {
  return (
    <div className={classNames(styles.wrapper)}>
      <div className={classNames(styles.top_diode, styles["top_diode--left"])}>
        <NewDiode size="sm" {...remoteLed} />
      </div>
      <div className={classNames(styles.top_diode, styles["top_diode--right"])}>
        <NewDiode size="sm" {...rtkLed} />
      </div>
      <div className={classNames(styles.body, styles.diode_wrapper)}>
        <NewDiode size="sm" {...leftLed} />
        {[...Array(6)].map((_, index) => (
          <NewDiode key={`led-${index}`} size="sm" {...middleLed} />
        ))}
        <NewDiode size="sm" {...rightLed} />
      </div>
      <div className={classNames(styles.wheel, styles["wheel--left"])} />
      <div className={classNames(styles.wheel, styles["wheel--right"])} />
    </div>
  );
};
