import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { GoogleMap } from "@react-google-maps/api";
import { Capacitor } from "@capacitor/core";
import { Geolocation } from "@capacitor/geolocation";
import {
  geolocationPermissionStatus,
  requestGeolocationPermission,
} from "../functions/servicePermissions";

const getCurrentPosition = async () => {
  const coordinates = await Geolocation.getCurrentPosition({
    enableHighAccuracy: true,
    maximumAge: Infinity,
  });
  return {
    lat: coordinates.coords.latitude,
    lng: coordinates.coords.longitude,
  };
};

export const useUserLocation = (
  center: google.maps.LatLng | google.maps.LatLngLiteral | undefined,
  mapRef: React.RefObject<GoogleMap>,
  showErrorIfDisallowed?: boolean
) => {
  const [mapCenter, setMapCenter] = useState(center);
  const [userLocation, setUserLocation] = useState<{
    lat: number;
    lng: number;
  } | null>(null);

  const handleUserLocation = async () => {
    try {
      const status = await geolocationPermissionStatus();
      if (status !== "granted") {
        let granted = false;
        if (status !== "denied") {
          const secondStatus = await requestGeolocationPermission();
          granted = secondStatus === "granted";
        }
        if (!granted) {
          if (showErrorIfDisallowed) {
            toast.error(
              Capacitor.isNativePlatform()
                ? "Grant the application permissions for geolocation"
                : "Allow the site to see geolocation in the browser settings"
            );
          }
          return;
        }
      }

      const userLoc = await getCurrentPosition();
      setUserLocation(userLoc);
      mapRef.current?.state.map?.setZoom(18);
      setMapCenter(userLoc);

      /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    } catch (e: GeolocationPositionError | any) {
      toast.error(e.message ? e.message : "Error getting location");
    }
  };

  useEffect(() => {
    let canUpdateState = true;
    let watchId = "";
    const updateUserLocation = async () => {
      try {
        const permissionStatus = await geolocationPermissionStatus();
        if (!canUpdateState) {
          return;
        }
        if (
          (Capacitor.isNativePlatform() && permissionStatus !== "granted") ||
          (!Capacitor.isNativePlatform() && permissionStatus === "denied")
        ) {
          if (showErrorIfDisallowed) {
            toast.error(
              Capacitor.isNativePlatform()
                ? "Grant the application permissions for geolocation"
                : "Allow the site to see geolocation in the browser settings"
            );
          }
          return;
        }
        // call watchPosition once as according to this issue: https://github.com/ionic-team/capacitor/issues/1279#issuecomment-978069835
        watchId = await Geolocation.watchPosition(
          {
            enableHighAccuracy: true,
            maximumAge: Infinity,
          },
          (coordinates) => {
            if (coordinates) {
              setUserLocation({
                lat: coordinates.coords.latitude,
                lng: coordinates.coords.longitude,
              });
            }
            Geolocation.clearWatch({ id: watchId });
          }
        );
        const coordinates = await getCurrentPosition();
        setUserLocation(coordinates);
        /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
      } catch (e: GeolocationPositionError | any) {
        // toast.error(e.message ? e.message : "Error getting location");
      }
    };
    updateUserLocation();
    return () => {
      canUpdateState = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { userLocation, mapCenter, setMapCenter, handleUserLocation };
};
