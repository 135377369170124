import { DropdownItemConfig } from "../../../types/Common";
import { DropdownItem } from "./DropdownItem";
import { StyledButton } from "../../atoms/Button/Button";
import { ColorCircle } from "../../atoms/ColorCircle/ColorCircle";
import { useContext } from "react";
import { DropdownContext } from "./dropdownContext";
import styles from "./mapDropdown.module.scss";
import classNames from "classnames";

type PropsT = {
  items?: DropdownItemConfig[];
  parentItem?: DropdownItemConfig;
  resetView: VoidFunction;
};

export const DropdownMenu = ({ items, parentItem, resetView }: PropsT) => {
  const { openSubmenu, setOpenSubmenu, setShowMenu } =
    useContext(DropdownContext);

  const close = () => {
    setOpenSubmenu([]);
    setShowMenu(false);
  };

  const goBack = () => {
    setOpenSubmenu(openSubmenu.slice(0, -1));
  };

  return (
    <div>
      <div className={styles["title-header"]}>
        <span className={styles.title}>Layers</span>
        <StyledButton icon="times" color="link" onClick={close} />
      </div>
      <div className={styles["item-list"]}>
        <div
          className={classNames(styles["item-parent"], {
            [styles["item-parent-active"]]: Boolean(parentItem),
          })}
        >
          <div className={styles["item-parent-left"]}>
            <div>
              <StyledButton icon="chevron-left" color="link" onClick={goBack} />
            </div>
            <span className={styles["item-parent-title"]}>
              {parentItem && parentItem.title}
            </span>
          </div>
          {parentItem && parentItem.color && (
            <ColorCircle className={styles.circle} color={parentItem.color} />
          )}
        </div>
        <ul>
          {items &&
            items.map((item) => (
              <DropdownItem key={item.id} configItem={item} />
            ))}
        </ul>
      </div>
      <div className={styles["bottom-btns"]}>
        <StyledButton
          className={styles.reset}
          title="Reset view"
          color="link"
          onClick={resetView}
        />
        <StyledButton
          className={styles.close}
          title="Close"
          color="dark"
          outline
          onClick={close}
        />
      </div>
    </div>
  );
};
