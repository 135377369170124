import React from "react";
import classNames from "classnames";
import styles from "./dashboard.module.scss";

type Props = {
  showActiveDevices: boolean;
  setShowActiveDevices: (showActive: boolean) => void;
};

const DeviceStatusSelector = ({
  showActiveDevices,
  setShowActiveDevices,
}: Props) => {
  return (
    <div className={classNames(styles["map-tab-selector__wrapper"])}>
      <div
        className={classNames(styles["map-tab-selector__tab"], {
          [styles["map-tab-selector__tab--active"]]: showActiveDevices,
        })}
        onClick={() => {
          setShowActiveDevices(true);
        }}
      >
        <span className={styles["map-tab-selector__tab-label"]}>
          Active device
        </span>
      </div>
      <div
        className={classNames(styles["map-tab-selector__tab"], {
          [styles["map-tab-selector__tab--active"]]: !showActiveDevices,
        })}
        onClick={() => {
          setShowActiveDevices(false);
        }}
      >
        <span className={styles["map-tab-selector__tab-label"]}>Last seen</span>
      </div>
    </div>
  );
};

export default DeviceStatusSelector;
