import React, { FC, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { Container } from "reactstrap";
import { StyledButton } from "../../components/atoms/Button/Button";
import { PageWrapper } from "../../components/atoms/PageWrapper/PageWrapper";
import SubHeader from "../../components/atoms/SubHeader/SubHeader";
import { FormUfon } from "../../components/molecules/FormUfon/FormUfon";

import { Layout } from "../../components/organisms/Layout/Layout";
import { WINDOW_TITLE_NAMES } from "../../constants";
import { useMutationEditUfon } from "../../hooks/useMutationEditUfon";
import { useQueryUfonGet } from "../../hooks/useQueries";
import { DeviceFormInput } from "../../types/Common";

export const UfonEditPage: FC = () => {
  document.title = WINDOW_TITLE_NAMES.devices;
  const [isFormTouched, setIsFormTouched] = useState(false);
  const { ufonId } = useParams();
  const navigate = useNavigate();
  const { data: ufonData, isLoading } = useQueryUfonGet(ufonId as string);
  const editUfonMutation = useMutationEditUfon(ufonId as string);

  const handleSubmit = (values: DeviceFormInput) => {
    editUfonMutation.mutate(values);
  };

  return (
    <Layout>
      <SubHeader
        isDetail
        title={ufonData?.data.name}
        actions={
          <>
            <StyledButton
              onClick={() => navigate(-1)}
              color="dark"
              outline
              title="Cancel"
            />
            <StyledButton
              disabled={!isFormTouched}
              color="primary"
              type="submit"
              form="ufon-form"
              isLoading={editUfonMutation.isLoading}
            >
              {!editUfonMutation.isLoading ? "Save" : "Saving"}
            </StyledButton>
          </>
        }
      />
      <PageWrapper>
        <Container>
          <FormUfon
            onTouch={() => setIsFormTouched(true)}
            onSubmit={handleSubmit}
            defaultValues={ufonData?.data}
            isDisabledAll={isLoading}
          />
        </Container>
      </PageWrapper>
    </Layout>
  );
};
