import React, { useState } from "react";
import { useGoogleMap } from "@react-google-maps/api";
import MapTypeTabs from "../../atoms/MapTypeControls/MapTypeControls";
import MapLocationControls from "../../atoms/MapLocationControls/MapLocationControls";
import MapZoomControls from "../../atoms/MapZoomControls/MapZoomControls";
import styles from "./mapControls.module.scss";

type Props = {
  dropdownComponent?: JSX.Element;
  handleHomeLocation?: () => void;
  handleUserLocation?: () => void;
  onlyIcons?: boolean;
  showZoomControls?: boolean;
};

const MapControls = ({
  dropdownComponent,
  handleHomeLocation,
  handleUserLocation,
  onlyIcons,
  showZoomControls = true,
}: Props) => {
  const map = useGoogleMap();
  const [mapTypeId, setMapTypeId] = useState<google.maps.MapTypeId>(
    map?.getMapTypeId() === "satellite"
      ? google.maps.MapTypeId.SATELLITE
      : google.maps.MapTypeId.TERRAIN
  );

  return (
    <div className={styles.controls}>
      <div className={styles["controls-left"]}>
        {dropdownComponent}
        <MapTypeTabs
          mapTypeId={mapTypeId}
          setMapTypeId={setMapTypeId}
          onlyIcons={onlyIcons}
        />
      </div>
      <div className={styles["controls-right"]}>
        {handleHomeLocation && handleUserLocation && (
          <MapLocationControls
            handleHomeLocation={handleHomeLocation}
            handleUserLocation={handleUserLocation}
          />
        )}
        {showZoomControls && <MapZoomControls setMapTypeId={setMapTypeId} />}
      </div>
    </div>
  );
};

export default MapControls;
