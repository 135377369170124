import { FC } from "react";
import { Col, Row } from "reactstrap";
import { Control, FormState } from "react-hook-form";
import { SelectOptionsT } from "../../../types/Common";
import { InputController } from "../Form/InputController";
import { JobCreateFormFieldsT, JobCreateFormNamesT } from "./FormJobCreate";

type PropsT = {
  name: JobCreateFormNamesT;
  label: string;
  control: Control<JobCreateFormFieldsT, object>;
  formState: FormState<JobCreateFormFieldsT>;
  selectOptions: SelectOptionsT;
  isDisabledAll?: boolean;
  isLoading: boolean;
};

export const FormDeviceInput: FC<PropsT> = ({
  name,
  label,
  control,
  formState,
  selectOptions,
  isDisabledAll,
  isLoading,
}: PropsT) => {
  const { errors } = formState;
  return (
    <Row>
      <Col lg="6">
        <InputController
          name={name}
          label={label}
          control={control}
          errors={errors}
          options={selectOptions}
          input={{ disabled: isDisabledAll || isLoading }}
        />
      </Col>
    </Row>
  );
};
