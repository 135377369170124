import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { client } from "../services/axios";
import { MutationOptionsT } from "../types/ReactQuery";

export const useMutationLeicaGroupCreate = (options?: MutationOptionsT) => {
  return useMutation(
    (name: string) => {
      return client.post(`/api/v1/leica-folder`, { name });
    },
    {
      onSuccess: () => {
        toast.success("Ufon's point group has been created");
        if (options?.onSuccess) {
          options.onSuccess();
        }
      },
      onError: () => {
        toast.error("Creating ufon's point group failed");
        if (options?.onError) {
          options.onError();
        }
      },
    }
  );
};
