import { useEffect, useState } from "react";
import {
  geolocationPermissionStatus,
  notificationPermissionStatus,
  requestGeolocationPermission,
  requestNotificationPermission,
} from "../../../functions/servicePermissions";
import { PermissionOverlay } from "../../molecules/PermissionOverlay/PermissionOverlay";
import { Capacitor, PermissionState } from "@capacitor/core";

export const PermissionOverlays = () => {
  const [showGeolocation, setShowGeolocation] = useState(false);
  const [showNotification, setShowNotification] = useState(false);

  const prompt = (status: PermissionState) => {
    return (
      status !== "denied" &&
      status !== "granted" &&
      Capacitor.isNativePlatform()
    );
  };

  useEffect(() => {
    const checkPermissions = async () => {
      const geolocationStatus = await geolocationPermissionStatus();
      setShowGeolocation(prompt(geolocationStatus));

      const notificationStatus = await notificationPermissionStatus();
      setShowNotification(prompt(notificationStatus));
    };

    checkPermissions();
  }, []);

  const geolocationGoNext = () => {
    setShowGeolocation(false);
  };
  const notificationGoNext = () => {
    setShowNotification(false);
  };

  return (
    <>
      <PermissionOverlay
        message="Please enable geolocation for proper functioning of the application"
        icon="terrain-marker"
        isOpen={showGeolocation}
        allow={async () => {
          await requestGeolocationPermission();
          geolocationGoNext();
        }}
        goNext={geolocationGoNext}
        allowText="Allow geolocation"
      />
      <PermissionOverlay
        message="We use notifications to let you know about device activity"
        icon="messages"
        isOpen={showNotification}
        allow={async () => {
          await requestNotificationPermission();
          notificationGoNext();
        }}
        goNext={notificationGoNext}
        allowText="Allow notifications"
      />
    </>
  );
};
