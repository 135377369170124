import React, { FC } from "react";
import { useIsFetching } from "react-query";
import { Link } from "react-router-dom";
import { StyledButton } from "../../components/atoms/Button/Button";
import { ROUTES } from "../../routes";

type PropsT = {
  canManageJobs: boolean;
  isMerging: boolean;
  mergingDisabled: boolean;
  onCancel: () => void;
  onMergeClick: () => void;
};

const HeaderActions: FC<PropsT> = ({
  canManageJobs,
  isMerging,
  mergingDisabled,
  onCancel,
  onMergeClick,
}) => {
  const isLoading = useIsFetching(["merge-dialog-options"]);

  return (
    <>
      {canManageJobs ? (
        <>
          {isMerging && (
            <StyledButton
              title="Cancel"
              color="dark"
              outline
              icon="times"
              onClick={onCancel}
              onlyIconOnMobile
            />
          )}
          <StyledButton
            onlyIconOnMobile
            disabled={isMerging && mergingDisabled}
            onClick={onMergeClick}
            title="Merge"
            icon="check-circle-outline"
            color="dark"
            outline
            isLoading={Boolean(isLoading)}
          />
          <StyledButton
            onlyIconOnMobile
            color="primary"
            title="New Job"
            icon="plus-circle"
            tag={Link}
            link={ROUTES.jobCreate()}
          />
        </>
      ) : (
        <StyledButton
          onlyIconOnMobile
          color="primary"
          title="New Job"
          icon="plus-circle"
          tag={Link}
          link={ROUTES.jobCreate()}
        />
      )}
    </>
  );
};

export default HeaderActions;
