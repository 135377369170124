import { object, ref, string } from "yup";
import { VALIDATIONS } from "../../constants";

const passwordRegExp = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*])");

export const passwordRecoverySchema = object({
  password: string()
    .required(VALIDATIONS.required)
    .min(8, "Password length must be at least 8 characters")
    .matches(
      passwordRegExp,
      "Password must contain at least one lowercase letter, one capital letter, and one special character"
    ),
  cpassword: string()
    .required(VALIDATIONS.required)
    .oneOf([ref("password")], "Passwords do not match"),
}).required();
