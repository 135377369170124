import { ROUTES } from "../../routes";
import {
  DATE_FORMATS,
  DEVICE_DETAIL_COLORS,
  INTERNET_VALUE,
  SATELLITE_VALUE,
} from "../../constants";
import {
  DeviceActivityInfo,
  DeviceCurrentInfo,
  ChartType,
} from "../../types/Common";
import { MowerConnectionT, MowerDataT, MowerT } from "../../types/Mower";
import {
  getHumanReadableText,
  removeDash,
} from "../../functions/getHumanReadableText";
import {
  getConnectionIconColor,
  getIcon,
  getInternetIcon,
  getLabel,
  getPositionFixLabel,
} from "../../functions/charts";
import { IconsId } from "../../assets/fonts/iconfont/icons";
import { formatDate } from "../../functions/formatDate";
import { TrackedMowerT } from "../../store/slices/mowerSlice";

export const getCurrentStatusInfo = (status?: MowerT): DeviceCurrentInfo[] => {
  const labels: IconsId[] = [
    "engine",
    "fuel",
    "blades",
    "mode",
    "remote-control",
  ];
  const mowerInfo: DeviceCurrentInfo[] = [];
  labels.forEach((label) =>
    mowerInfo.push({
      label: removeDash(getHumanReadableText(label)),
      value: undefined,
      icon: { id: label, color: DEVICE_DETAIL_COLORS.default },
      textColor: DEVICE_DETAIL_COLORS.default,
    })
  );
  if (!status) {
    return mowerInfo;
  }
  const mapCallback = (item: DeviceCurrentInfo): DeviceCurrentInfo => {
    switch (item.label) {
      case "Engine":
        if (status.status.engine === null) return item;
        const engineColor = status.status.engine
          ? DEVICE_DETAIL_COLORS.ok
          : DEVICE_DETAIL_COLORS.error;

        return {
          ...item,
          value: status.status.engine ? "ON" : "OFF",
          icon: item.icon
            ? {
                ...item.icon,
                color: engineColor,
              }
            : undefined,
          textColor: engineColor,
        };
      case "Fuel":
        if (status.status.fuel === null) return item;
        let fuelColor = "";
        if (status.status.fuel.percent > 30) {
          fuelColor = DEVICE_DETAIL_COLORS.ok;
        } else if (
          status.status.fuel.percent <= 30 &&
          status.status.fuel.percent > 10
        ) {
          fuelColor = DEVICE_DETAIL_COLORS.warning;
        } else {
          fuelColor = DEVICE_DETAIL_COLORS.error;
        }
        return {
          ...item,
          value: `${status.status.fuel.percent}% / ${status.status.fuel.hours} hours`,
          icon: item.icon
            ? {
                ...item.icon,
                color: fuelColor,
              }
            : undefined,
          textColor: DEVICE_DETAIL_COLORS.black,
        };
      case "Blades":
        if (status.status.knives === null) return item;
        const knivesColor = status.status.knives
          ? DEVICE_DETAIL_COLORS.warning
          : DEVICE_DETAIL_COLORS.default;
        return {
          ...item,
          value: status.status.engine
            ? status.status.knives
              ? "ON"
              : "OFF"
            : undefined,
          icon: item.icon
            ? {
                ...item.icon,
                color: knivesColor,
              }
            : undefined,
          textColor: knivesColor,
        };
      case "Mode":
        if (status.status.mode === null) return item;
        const modeColor =
          status.status.mode.isManual || !status.status.engine
            ? DEVICE_DETAIL_COLORS.default
            : DEVICE_DETAIL_COLORS.warning;
        return {
          ...item,
          value: status.status.engine
            ? getHumanReadableText(status.status.mode.name)
            : undefined,
          icon: item.icon
            ? {
                ...item.icon,
                color: modeColor,
              }
            : undefined,
          textColor: modeColor,
        };
      case "Remote control":
        if (status.status.remoteControl === null) return item;
        const remoteControlColor = status.status.remoteControl
          ? DEVICE_DETAIL_COLORS.ok
          : DEVICE_DETAIL_COLORS.error;
        return {
          ...item,
          value: status.status.remoteControl ? "Connected" : "Disconnected",
          icon: item.icon
            ? {
                ...item.icon,
                color: remoteControlColor,
              }
            : undefined,
          textColor: remoteControlColor,
        };
      default:
        return item;
    }
  };
  return mowerInfo.map((item) => mapCallback(item));
};

export const getCurrentDataInfo = (data?: MowerT): DeviceCurrentInfo[] => {
  const otherProperties: Record<
    keyof Omit<MowerDataT, "wheelAngle" | "pressure">,
    string
  > = {
    load: "Load",
    throttle: "Throttle",
    revolutions: "Revolutions",
    oilTemperature: "Engine temperature",
    intakeTemperature: "Intake temperature",
  };
  const info: DeviceCurrentInfo[] = [];

  for (const key of Object.keys(otherProperties) as Array<
    keyof typeof otherProperties
  >) {
    info.push({
      label: otherProperties[key],
      value: data?.data[key] || undefined,
      textColor: DEVICE_DETAIL_COLORS.black,
    });
  }

  return info;
};

export const getConnectionData = (
  data?: MowerConnectionT
): DeviceCurrentInfo[] => {
  const connectionData: DeviceCurrentInfo[] = [];

  Object.values(ChartType).forEach((item) => {
    connectionData.push({
      label: getLabel(item),
      value: undefined,
      icon: { id: getIcon(item), color: DEVICE_DETAIL_COLORS.default },
      textColor: DEVICE_DETAIL_COLORS.default,
    });
  });

  if (!data) {
    return connectionData;
  }

  const getSatelliteText = (satelliteCount: number) =>
    `${satelliteCount} satellite${satelliteCount !== 1 ? "s" : ""}`;

  const mapCallback = (item: DeviceCurrentInfo): DeviceCurrentInfo => {
    if (!item.icon) return item;
    switch (item.icon.id) {
      case "compas":
        let compasColor = item.icon?.color;
        if (data.correction.value === null)
          compasColor = DEVICE_DETAIL_COLORS.default;
        else if (data.correction.value) {
          compasColor = DEVICE_DETAIL_COLORS.ok;
        } else {
          compasColor = DEVICE_DETAIL_COLORS.error;
        }

        return {
          ...item,
          value: data.correction.type
            ? `Mode: ${data.correction.type.toUpperCase()}`
            : undefined,
          icon: {
            ...item.icon,
            color: compasColor,
          },
          textColor: DEVICE_DETAIL_COLORS.black,
        };
      case "signal":
        const signalColor = getConnectionIconColor(
          data.internet.signalStrength,
          INTERNET_VALUE.error,
          INTERNET_VALUE.warning
        );
        return {
          ...item,
          value: data.internet.signalStrength
            ? `${data.internet.providerName} / ${data.internet.signalStrength}%`
            : undefined,
          icon: {
            id: getInternetIcon(data.internet.signalStrength),
            color: signalColor,
          },
          textColor:
            signalColor === DEVICE_DETAIL_COLORS.ok
              ? DEVICE_DETAIL_COLORS.black
              : signalColor,
        };
      case "satellite-pin":
        const positionColor =
          data.position.fix === "rtk-float"
            ? DEVICE_DETAIL_COLORS.warning
            : getConnectionIconColor(
                data.position.satellites,
                SATELLITE_VALUE.error,
                SATELLITE_VALUE.warning
              );
        return {
          ...item,
          value:
            data.position.satellites !== null
              ? `${getSatelliteText(
                  data.position.satellites
                )} (${getPositionFixLabel(data.position.fix)})`
              : undefined,
          icon: {
            ...item.icon,
            color: positionColor,
          },
          textColor:
            positionColor === DEVICE_DETAIL_COLORS.ok
              ? DEVICE_DETAIL_COLORS.black
              : positionColor,
        };
      case "server":
        let serverColor = item.icon.color;
        if (data.server.isConnected === null) {
          serverColor = DEVICE_DETAIL_COLORS.default;
        } else if (!data.server.isConnected) {
          serverColor = DEVICE_DETAIL_COLORS.error;
        } else if (data.server.delay === 0) {
          serverColor = DEVICE_DETAIL_COLORS.ok;
        } else {
          serverColor = DEVICE_DETAIL_COLORS.warning;
        }

        let serverValue = undefined;
        if (data.server.isConnected !== null && data.server.delay !== null) {
          if (!data.server.isConnected) {
            serverValue = "No connection";
          } else if (data.server.delay === 0) {
            serverValue = "No delay";
          } else {
            serverValue = `Delay ${data.server.delay} s`;
          }
        }

        return {
          ...item,
          value: serverValue,
          icon: {
            ...item.icon,
            color: serverColor,
          },
          textColor:
            serverColor === DEVICE_DETAIL_COLORS.ok
              ? DEVICE_DETAIL_COLORS.black
              : serverColor,
        };
      default:
        return item;
    }
  };
  return connectionData.map((item) => mapCallback(item));
};

export const getMowerActivityData = (
  dataApi: MowerT,
  dataRedux?: MowerT
): DeviceActivityInfo[] => {
  const getJobDetailLink = () => {
    if (!dataApi.job) return;
    if (dataApi.job.type.name === "Route") {
      return ROUTES.jobRouteType(dataApi.job.id);
    }
    if (dataApi.job.type.name === "Manual") {
      return ROUTES.jobManual(dataApi.job.id);
    }
    return dataApi.lastUser
      ? ROUTES.job(dataApi.job.id, dataApi.lastUser.id)
      : undefined;
  };
  const activityData: DeviceActivityInfo[] = [
    {
      label: "Connected CST",
      data:
        dataRedux && dataRedux.ufon
          ? {
              value: dataRedux.ufon.name,
              linkTo: ROUTES.ufon(dataRedux.ufon.id),
              additionalValue: dataRedux.ufon.rtk,
            }
          : undefined,
    },
    {
      label: "Last job",
      data:
        dataApi && dataApi.job
          ? {
              value: dataApi.job.name,
              linkTo: getJobDetailLink() || "",
            }
          : undefined,
    },
    {
      label: "Uploaded route",
      data:
        dataRedux && dataRedux.route
          ? {
              value: dataRedux.route.name,
              linkTo: ROUTES.route(dataRedux.route.id),
            }
          : undefined,
    },
    {
      label: "Last user",
      data:
        dataApi && dataApi.lastUser
          ? {
              value: dataApi.lastUser.name,
              linkTo: ROUTES.user(dataApi.lastUser.id),
              additionalValue: dataApi.lastUser.phone,
              email: dataApi.lastUser.email,
            }
          : undefined,
    },
  ];

  return activityData;
};

export const getGeneralData = (
  data: MowerT | undefined,
  isDeveloper: boolean
): DeviceCurrentInfo[] => {
  const info: DeviceCurrentInfo[] = [
    {
      label: "Title",
      value: data?.name,
      textColor: data?.name
        ? DEVICE_DETAIL_COLORS.black
        : DEVICE_DETAIL_COLORS.default,
    },
    {
      label: "Company",
      value: data?.info.company.name,
      textColor: data?.info.company.name
        ? DEVICE_DETAIL_COLORS.black
        : DEVICE_DETAIL_COLORS.default,
    },
    {
      label: "Control unit serial number",
      value: data?.info.serialNumber,
      textColor: data?.info.serialNumber
        ? DEVICE_DETAIL_COLORS.black
        : DEVICE_DETAIL_COLORS.default,
    },
    {
      label: "SMAUT serial number",
      value: data?.info.smautSerialNumber || undefined,
      textColor: data?.info.smautSerialNumber
        ? DEVICE_DETAIL_COLORS.black
        : DEVICE_DETAIL_COLORS.default,
    },
    {
      label: "Purchase date",
      value: data?.purchaseDate
        ? formatDate(data?.purchaseDate, DATE_FORMATS.date)
        : undefined,
      textColor: data?.purchaseDate
        ? DEVICE_DETAIL_COLORS.black
        : DEVICE_DETAIL_COLORS.default,
    },
  ];
  if (isDeveloper) {
    info.push({
      label: "Spidercontrol",
      value: data?.info.fw || undefined,
      textColor: data?.info.fw
        ? DEVICE_DETAIL_COLORS.black
        : DEVICE_DETAIL_COLORS.default,
    });
    info.push({
      label: "Firmware GSM",
      value: data?.info.gsmFwVersion || undefined,
      textColor: data?.info.gsmFwVersion
        ? DEVICE_DETAIL_COLORS.black
        : DEVICE_DETAIL_COLORS.default,
    });
    info.push({
      label: "Spidercontrol library",
      value: data?.info.lib || undefined,
      textColor: data?.info.lib
        ? DEVICE_DETAIL_COLORS.black
        : DEVICE_DETAIL_COLORS.default,
    });
    info.push({
      label: "External procesor firmware",
      value: data?.info.extPVersion || undefined,
      textColor: data?.info.extPVersion
        ? DEVICE_DETAIL_COLORS.black
        : DEVICE_DETAIL_COLORS.default,
    });
  }
  return info;
};

export const getServiceData = (): DeviceCurrentInfo[] => {
  const info: DeviceCurrentInfo[] = [
    {
      label: "Engine hours",
      textColor: DEVICE_DETAIL_COLORS.black,
    },
    {
      label: "Autonomous hours",
      textColor: DEVICE_DETAIL_COLORS.black,
    },
    {
      label: "Blades hours",
      textColor: DEVICE_DETAIL_COLORS.black,
    },
    {
      label: "Distance",
      textColor: DEVICE_DETAIL_COLORS.black,
    },
  ];
  return info;
};

export const isMowerAutonomous = (mower: MowerT): boolean =>
  mower.status.mode?.isManual === false;

export const isMowerPaused = (
  mower: TrackedMowerT | undefined
): boolean | undefined =>
  mower?.mowerStatus?.status.mode !== undefined
    ? mower.mowerStatus.status.mode.additionalValue === "Paused"
    : undefined;
