import { TaskStatusT, TaskT } from "../../../types/Job";
import { MowerPreviewT } from "../../../types/Mower";
import { RoutePreviewT } from "../../../types/Route";

export const getBtnLabel = (task: TaskT, isWaiting: boolean) => {
  if (task.status === null) {
    return "Start";
  }
  if (task.status === "start") {
    return "Done";
  }
  if (isWaiting) {
    return "Pending";
  }
  return "Done";
};

export const getNewStatus = (task: TaskT): TaskStatusT => {
  if (task.status === null && task.type !== "manualModeToSetPerimeter") {
    return "start";
  }
  if (task.status === "start") {
    return "done";
  }
  return "done";
};

const capitilizeFirstLetter = (str: string) => {
  if (!str || str === "") {
    return str;
  }
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const getTaskButtonTitle = (task: TaskT): string => {
  return capitilizeFirstLetter(getNewStatus(task));
};

export const getRouteNames = (
  mowers: MowerPreviewT[],
  routes: RoutePreviewT[],
  mowerId: number
): string => {
  const currentMower = mowers.find((mower) => mower.id === mowerId);
  if (!currentMower) {
    return "";
  }
  const currentRoute = routes.find((route) => route.id === currentMower.route);
  if (!currentRoute) {
    return "";
  }
  return currentRoute.name;
};
