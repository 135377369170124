import { useMutation } from "react-query";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { LeicaInputsT } from "../components/molecules/FormLeica/FormLeica";
import { ROUTES } from "../routes";
import { client } from "../services/axios";
import { ApiErrorT } from "../types/Common";

export const useMutationLeicaCreate = () => {
  const navigate = useNavigate();

  return useMutation(
    (data: LeicaInputsT) => {
      const formData = new FormData();
      formData.append("name", data.name);
      formData.append("folderId", data.group);
      formData.append("file", data.file[0]);
      formData.append("zone", data.zone);
      return client.post(`/api/v1/leica/import`, formData);
    },
    {
      onSuccess: (data, input) => {
        toast.success("Leica has been created");
        navigate(ROUTES.leica(data.data.id, input.name));
      },
      onError: (error: ApiErrorT) => {
        toast.error(error?.message);
      },
    }
  );
};
