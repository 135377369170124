import React, { FC, useState } from "react";
import { useDispatch } from "react-redux";
import classNames from "classnames";
import { client } from "../../../services/axios";
import { queryClient } from "../../..";
import { JobTypeT, TaskT } from "../../../types/Job";
import { useSelector } from "../../../store/hooks";
import { updateTaskAction } from "../../../store/slices/tasksSlice";
import { useMutationNogoCreate } from "../../../hooks/useMutationNogoCreate";
import { useCompactDesign } from "../../../hooks/useCompactDesign";
import { IconsId } from "../../../assets/fonts/iconfont/icons";
import { StyledButton } from "../../atoms/Button/Button";
import { Icon } from "../../atoms/Icon";
import ProgressBar from "../../atoms/ProgressBar/ProgressBar";
import { RemoteActionNeededSign } from "./RemoteActionNeededSign";
import { getNewStatus, getRouteNames, getTaskButtonTitle } from "./functions";
import styles from "./wizardItem.module.scss";

export type DialogTypes = "mowing" | "noGoZones" | "calculateRoute";

type UploadRouteStatusT = "pending" | "success" | "error";
interface UploadRouteT {
  status: UploadRouteStatusT;
  error?: string;
}

type PropsT = {
  task: TaskT;
  isActive: boolean;
  wasActivePrevious: boolean;
  jobId: string;
  jobType: JobTypeT;
  isWaitingArr: TaskT[];
  onOpenDialog: (dialogData: { type: DialogTypes; mowerId: number }) => void;
  fetchTasks: () => void;
};

export const WizardItem: FC<PropsT> = ({
  task,
  isActive,
  wasActivePrevious,
  jobId,
  jobType,
  isWaitingArr,
  onOpenDialog,
  fetchTasks,
}) => {
  const { isLoading, userTasks, routeComputeStatus } = useSelector(
    (state) => state.tasks
  );
  const routeCompute = routeComputeStatus[jobId];

  const dispatch = useDispatch();
  const [uploadRouteStatus, setUploadRouteStatus] =
    useState<UploadRouteT | null>(null);
  const isCompact = useCompactDesign();

  const uploadRoute = async () => {
    setUploadRouteStatus({ status: "pending" });
    const [{ data }] = await Promise.all([
      client.put(`/api/v1/job/${jobId}/send-coordinates/${task.mowerId}`),
      // Wait at least a second to show the loader
      new Promise((resolve) => setTimeout(resolve, 1000)),
    ]);
    if (data.success) {
      setUploadRouteStatus({ status: "success" });
    } else {
      setUploadRouteStatus({ status: "error", error: data.message });
    }
  };

  const handleClickButtonPrimary = () => {
    if (
      task.type === "startMowing" &&
      task.status === null &&
      jobType.name !== "Manual"
    ) {
      onOpenDialog({ type: "mowing", mowerId: task.mowerId || 0 });
      return;
    }
    if (task.type === "calculateRoute" && task.status === null) {
      onOpenDialog({ type: "calculateRoute", mowerId: task.mowerId || 0 });
      return;
    }
    if (task.type === "uploadRouteToMower" && task.status === null) {
      uploadRoute();
    }

    dispatch(
      updateTaskAction({
        jobId,
        type: task.type,
        newStatus: getNewStatus(task),
        options: {
          mowerId: task.mowerId ?? undefined,
        },
      })
    );
  };

  // TODO: Should be made as query mutations
  const createNogoMutation = useMutationNogoCreate();

  const startNogo = async () => {
    await createNogoMutation.mutateAsync({
      jobId: jobId,
      mowerId: task.mowerId || 0,
    });
    fetchTasks();
  };

  const finishNogo = async (nogoId: number) => {
    await client.put(`/api/v1/job/${jobId}/subtask`, {
      id: nogoId,
      type: "createNoGoZone",
      status: "done",
      mowerId: task.mowerId,
    });
    queryClient.invalidateQueries(["coordinates"]);

    fetchTasks();
  };

  const deleteNogo = async (nogoId: number) => {
    await client.delete(`/api/v1/job/${jobId}/subtask/${nogoId}`);
    queryClient.invalidateQueries(["coordinates"]);

    fetchTasks();
  };

  const reopenNogo =
    task.type === "setNoGoZones" && wasActivePrevious
      ? async () => {
          await client.put(`/api/v1/job/${jobId}/reopen-no-go-zone`);

          fetchTasks();
        }
      : undefined;

  let redoOperation = undefined;
  if (task.type === "calculateRoute" && task.status !== null) {
    redoOperation = () => {
      onOpenDialog({ type: "calculateRoute", mowerId: task.mowerId || 0 });
    };
  } else if (
    task.type === "uploadRouteToMower" &&
    (uploadRouteStatus !== null || task.status !== null)
  ) {
    redoOperation = uploadRoute;
  }

  const acceptedRoutes = userTasks?.infoStatus?.route;
  const mowers = userTasks?.infoStatus.mower || [];

  const waitingForTask =
    isWaitingArr.filter(
      (isWaitingTask) => isWaitingTask.mowerId === task.mowerId
    ).length > 0;

  const uploadingRoute = uploadRouteStatus?.status === "pending";

  const showRouteName =
    task.type === "uploadRouteToMower" &&
    (uploadRouteStatus?.status === "success" ||
      (task.status === "start" &&
        uploadRouteStatus?.status !== "error" &&
        uploadRouteStatus?.status !== "pending")) &&
    acceptedRoutes &&
    !waitingForTask;

  let subtitle: { text: string; icon: IconsId | null } | null = null;
  if (showRouteName) {
    subtitle = {
      text: `Route ${getRouteNames(
        mowers,
        acceptedRoutes,
        task.mowerId || 0
      )} was accepted by mower`,
      icon: "check-circle",
    };
  } else if (task.type === "calculateRoute" && routeCompute?.isFailed) {
    subtitle = {
      text: routeCompute?.message
        ? `Error: ${routeCompute.message}`
        : "Error occurred",
      icon: null,
    };
  } else if (
    task.type === "uploadRouteToMower" &&
    uploadRouteStatus?.status === "error"
  ) {
    subtitle = {
      text: uploadRouteStatus.error
        ? `Error: ${uploadRouteStatus.error}`
        : "Error occurred",
      icon: null,
    };
  }

  const isTaskFinished = task.status === "done";

  let progress = undefined;
  if (
    task.type === "calculateRoute" &&
    routeCompute &&
    !routeCompute.isFailed &&
    (task.status === "pending" || task.status === "readyForDone")
  ) {
    progress = routeCompute.percentage;
  } else if (
    task.type === "uploadRouteToMower" &&
    uploadRouteStatus?.status === "pending"
  ) {
    progress = 50;
  }

  const isTaskDisabled =
    waitingForTask ||
    isLoading ||
    (!isActive && !isTaskFinished) ||
    uploadingRoute ||
    uploadRouteStatus?.status === "error";

  if (task.hideButton) {
    return (
      <RemoteActionNeededSign
        active={isActive}
        className={classNames(styles.item, {
          [styles["item--active"]]: isActive,
          [styles["item--finished"]]: isTaskFinished,
        })}
      >
        <div className={classNames(styles["item-content"])}>
          <div>
            <h4
              className={classNames(styles.title, {
                [styles["title--active"]]: isActive,
                [styles["title--finished"]]: isTaskFinished,
              })}
            >
              {task.name}
            </h4>
          </div>

          {isTaskFinished && (
            <div className={styles["checkmark-wrapper"]}>
              <Icon icon="check" className={styles["icon-is-finished"]} />
            </div>
          )}
        </div>
      </RemoteActionNeededSign>
    );
  }

  return (
    <div
      className={classNames(styles.item, {
        [styles["item--active"]]: isActive,
        [styles["item--finished"]]: isTaskFinished,
      })}
    >
      <div className={styles["item-content"]}>
        <div>
          <h4
            className={classNames(styles.title, {
              [styles["title--active"]]: isActive,
              [styles["title--finished"]]: isTaskFinished,
            })}
          >
            {task.name}
          </h4>
          {progress &&
            (progress === 100 ? (
              <span className={styles.label}>Done</span>
            ) : (
              <ProgressBar value={progress} />
            ))}
          {subtitle && (
            <p className={styles["paragraph-small"]}>
              {subtitle.icon && (
                <Icon icon={subtitle.icon} className={styles["icon-active"]} />
              )}
              {subtitle.text}
            </p>
          )}
        </div>
        {!isTaskFinished && isActive && (
          <div
            className={classNames(
              styles["button-wrapper"],
              isCompact && styles["button-wrapper--compact"]
            )}
          >
            <StyledButton
              isLoading={isLoading || uploadingRoute}
              disabled={isTaskDisabled}
              color={"primary"}
              onClick={handleClickButtonPrimary}
              icon={waitingForTask || uploadingRoute ? "pending" : undefined}
              title={
                waitingForTask || isLoading || uploadingRoute
                  ? undefined
                  : getTaskButtonTitle(task)
              }
              classNameForIcon={classNames({
                [styles.pending]: waitingForTask,
              })}
            />
            {redoOperation && (
              <StyledButton
                disabled={!isActive}
                size="sm"
                color="link"
                icon="reload-arrow"
                onClick={redoOperation}
                classNameForIcon={styles["redo-button"]}
              />
            )}
          </div>
        )}
        {isTaskFinished && (
          <div className={styles["checkmark-wrapper"]}>
            {reopenNogo && (
              <StyledButton
                isLoading={isLoading}
                className={styles["reopen-nogo"]}
                title="Reopen"
                color="link"
                onClick={reopenNogo}
              />
            )}
            <Icon icon="check" className={styles["icon-is-finished"]} />
          </div>
        )}
      </div>

      {task.type === "setNoGoZones" &&
        (isActive || (isTaskFinished && task?.subtask?.items?.length > 0)) && (
          <div className={styles["item-options"]}>
            {task?.subtask?.items?.length > 0
              ? task?.subtask?.items?.map((item, index) => (
                  <React.Fragment key={item.id}>
                    <div
                      className={classNames(styles["item-option"], {
                        ["item-option--disabled"]: isTaskFinished,
                      })}
                    >
                      <span>
                        {index + 1}. {item.name}
                      </span>
                      {!isTaskFinished &&
                        (item.status === "start" ? (
                          <StyledButton
                            color={"primary"}
                            size="sm"
                            onClick={() => finishNogo(item.id)}
                            title="Done"
                          />
                        ) : (
                          <StyledButton
                            color="danger"
                            size="sm"
                            onClick={() => deleteNogo(item.id)}
                            disabled={isLoading || isTaskFinished}
                            icon="trash"
                          />
                        ))}
                    </div>

                    {task?.subtask?.items?.length === index + 1 &&
                      !isTaskFinished &&
                      item.status === "done" && (
                        <div className={styles["item-option"]}>
                          <span>
                            {index + 2}.
                            {
                              "Move mower to a starting point of a no-go zone and press start"
                            }
                          </span>
                          <StyledButton
                            color="primary"
                            size="sm"
                            onClick={startNogo}
                            style={{ minWidth: "80px" }}
                            title="Start"
                          />
                        </div>
                      )}
                  </React.Fragment>
                ))
              : !isTaskFinished && (
                  <div className={styles["item-option"]}>
                    <span>
                      {
                        "1. Move mower to a starting point of a no-go zone and press start"
                      }
                    </span>

                    <StyledButton
                      color={isActive ? "primary" : "dark"}
                      size="sm"
                      onClick={startNogo}
                      style={{ minWidth: "80px" }}
                      disabled={isLoading || isTaskFinished}
                      title="Start"
                    />
                  </div>
                )}
          </div>
        )}
    </div>
  );
};
