import {
  all,
  call,
  put,
  race,
  take,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import {
  FetchRouteCoordinatesActionType,
  FetchSegmentsCoordinatesActionType,
  SubscribeToJobProgressActionType,
  SubscribeToNextCoordinatesUpdateActionType,
  routeLayersInitial,
  setActiveRouteLayers,
  setActiveSegmentLayers,
  setJobProgress,
  setNextCoordinates,
  setRouteCoordinates,
  setRouteIsLoading,
  setSegmentIsLoading,
  setSegmentsCoordinates,
} from "../slices/jobRouteTypeSlice";
import { CoordinatesT } from "../../types/Route";
import {
  fetchRouteCoordinates,
  fetchSegmentCoordinates,
  getLayersFromCoordinates,
  getSegmentLayersFromCoordinates,
} from "./functions";
import { ApiErrorT } from "../../types/Common";
import { JobProgress, NextCoordinatesByMower } from "../../types/Job";
import {
  subscribeMessage,
  unsubscribeMessage,
} from "../../functions/websockets";
import { webSocket } from "./tasksSaga";

function* fetchRouteCoordinatesSaga({
  payload,
}: FetchRouteCoordinatesActionType) {
  const { routeId } = payload;
  yield put(setRouteIsLoading(true));
  try {
    const routeCoordinates: CoordinatesT<"route"> = yield call(
      fetchRouteCoordinates,
      routeId
    );

    yield put(setRouteCoordinates(routeCoordinates));

    const newState = getLayersFromCoordinates(
      routeCoordinates,
      routeLayersInitial,
      true
    );

    yield put(setActiveRouteLayers(newState));
  } catch (e) {
    const { message } = e as ApiErrorT;
    toast.error(message);
  } finally {
    yield put(setRouteIsLoading(false));
  }
}

function* fetchSegmentsCoordinatesSaga({
  payload,
}: FetchSegmentsCoordinatesActionType) {
  const { jobId, segmentIds, lastSegments } = payload;
  yield put(setSegmentIsLoading(true));
  try {
    const segmentsData: CoordinatesT<"route">[] = yield all(
      segmentIds.map((id) => call(fetchSegmentCoordinates, jobId, id))
    );
    const coordinatesBySegment = segmentIds.reduce<
      Record<number, CoordinatesT<"route">>
    >((acc, curr, index) => {
      return { ...acc, [curr]: segmentsData[index] };
    }, {});

    yield put(setSegmentsCoordinates(coordinatesBySegment));

    const newState = getSegmentLayersFromCoordinates(
      coordinatesBySegment,
      null,
      segmentIds,
      lastSegments
    );

    yield put(setActiveSegmentLayers(newState));
  } catch (e) {
    const { message } = e as ApiErrorT;
    toast.error(message);
  } finally {
    yield put(setSegmentIsLoading(false));
  }
}

function* subscribeToJobProgressSaga({
  payload,
}: SubscribeToJobProgressActionType) {
  const { jobId } = payload;

  webSocket.send(subscribeMessage(`job.status.${jobId}`));

  while (true) {
    const {
      message,
      unsubscribe,
    }: {
      message: PayloadAction<{ mower?: JobProgress[] }>;
      unsubscribe: PayloadAction;
    } = yield race({
      message: take(`jobStatus_${jobId}`),
      unsubscribe: take("UNSUBSCRIBE_FROM_JOB_PROGRESS"),
    });

    if (message && message.payload.mower) {
      yield put(setJobProgress({ mower: message.payload.mower }));
    }

    if (unsubscribe) {
      webSocket.send(unsubscribeMessage(`job.status.${jobId}`));
      break;
    }
  }
}

function* subscribeToNextCoordinatesSaga({
  payload,
}: SubscribeToNextCoordinatesUpdateActionType) {
  const { jobId } = payload;

  webSocket.send(subscribeMessage(`job.coordinates.${jobId}`));

  while (true) {
    const {
      message,
      unsubscribe,
    }: {
      message: PayloadAction<NextCoordinatesByMower[]>;
      unsubscribe: PayloadAction;
    } = yield race({
      message: take(`jobNextCoordinates_${jobId}`),
      unsubscribe: take("UNSUBSCRIBE_FROM_NEXT_COORDINATES_UPDATE"),
    });

    if (message) {
      const coordinatesByMower = message.payload.reduce<
        Record<number, NextCoordinatesByMower>
      >((acc, next) => {
        return { ...acc, [next.mowerId]: next };
      }, {});
      yield put(setNextCoordinates(coordinatesByMower));
    }

    if (unsubscribe) {
      webSocket.send(unsubscribeMessage(`job.coordinates.${jobId}`));
    }
  }
}

export function* rootJobRouteTypeSaga() {
  yield all([
    takeLatest("FETCH_ROUTE_COORDINATES", fetchRouteCoordinatesSaga),
    takeLatest("FETCH_SEGMENTS_COORDINATES", fetchSegmentsCoordinatesSaga),
    takeEvery("SUBSCRIBE_TO_JOB_PROGRESS", subscribeToJobProgressSaga),
    takeEvery("SUBSCRIBE_TO_NEXT_COORDINATES", subscribeToNextCoordinatesSaga),
  ]);
}
