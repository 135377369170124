import { useState } from "react";
import { useParams } from "react-router";
import { CONNECTION_CUTOFF } from "../../../constants";
import { ChartType } from "../../../types/Common";
import { useSelector } from "../../../store/hooks";
import {
  anyChartDataPresent,
  getConnectionChartData,
  getDesiredTimestamps,
  newestTimestamp,
} from "../../../functions/charts";
import DeviceDetailInfoRow from "../../../components/molecules/DeviceInfo/DeviceDetailStatusRow";
import CardWithHeader from "../../../components/atoms/CardWithHeader/CardWithHeader";
import ConnectionChart from "../../../components/organisms/ConnectionChart/ConnectionChart";
import ChartModal, {
  ModalConfig,
} from "../../../components/molecules/ChartModal/ChartModal";
import { getConnectionData } from "../functions";
import styles from "./mowerInfoTab.module.scss";

export default function MowerConnectionCard() {
  const { mowerId } = useParams() as { mowerId: string };
  const connection = useSelector(
    (state) => state.mower.mowers[Number(mowerId)]?.mowerConnection
  );

  const [modalConfig, setModalConfig] = useState<ModalConfig | null>(null);

  const connectionData = getConnectionData(connection);

  function getChartType(label: string) {
    switch (label) {
      case "Corrections / RTK":
        return ChartType.correction;
      case "Internet":
        return ChartType.internet;
      case "Position / GPS":
        return ChartType.position;
      default:
        return ChartType.server;
    }
  }

  /*
  const getModalConfig = (label: string) => {
    const now = new Date();
    const thirtyMinsBefore = new Date(new Date().getTime() - 30 * 60 * 1000);
    const chartData = getConnectionChartData(
      label,
      connection?.data,
      thirtyMinsBefore,
      now
    );

    if (!chartData) return;

    setModalConfig({
      title: `${label} connection history`,
      chartData: chartData.chartData,
      from: thirtyMinsBefore,
      to: now,
      referenceLine: chartData.referenceValue,
      onRangeSelected: () => {},
      chartType: getChartType(label),
    });
  };
  */

  const timestampCutoffLimit = CONNECTION_CUTOFF.mower;
  const timestampFrequency = timestampCutoffLimit / 60;
  const timestamps = getDesiredTimestamps(
    timestampCutoffLimit,
    timestampFrequency,
    newestTimestamp(connection)
  );

  return (
    <>
      {modalConfig && (
        <ChartModal
          isOpen={Boolean(modalConfig)}
          onClose={() => setModalConfig(null)}
          config={modalConfig}
        />
      )}
      <CardWithHeader title="Connection">
        {connectionData.map((item, index) => {
          const data = getConnectionChartData(item.label, connection);
          return (
            <DeviceDetailInfoRow
              key={`${item.label}-${index}`}
              data={item}
              onClick={
                /*item.label !== "Server" ? getModalConfig : undefined*/ undefined
              }
            >
              {item.label !== "Server" && anyChartDataPresent(connection) && (
                <>
                  <ConnectionChart
                    data={data?.chartData}
                    referenceLine={data?.referenceValue}
                    chartType={getChartType(item.label)}
                    desiredTimestamps={timestamps}
                    maxValue={data?.maxValue}
                  />

                  <div className={styles.xAxis}>
                    <span>{`-${timestampCutoffLimit / 60} min`}</span>
                    <span>Now</span>
                  </div>
                </>
              )}
            </DeviceDetailInfoRow>
          );
        })}
      </CardWithHeader>
    </>
  );
}
