import { useCallback, FC, useState, memo } from "react";
import {
  GoogleMap,
  Marker,
  Polygon,
  useJsApiLoader,
} from "@react-google-maps/api";
import classNames from "classnames";
import styles from "./map.module.scss";
import { LeicaCoordinatesT, LeicaLinePolyStyleT } from "../../../types/Leica";
import { GOOGLE_MAP_API_KEY } from "../../../env";

type PropsT = {
  center: google.maps.LatLngLiteral;
  zoom: number;
  mapType?: string;
  polygonStyle: LeicaLinePolyStyleT;
  coordinates?: LeicaCoordinatesT;
};

const containerStyle = {
  height: "100%",
};

const MapLeica: FC<PropsT> = ({
  center,
  zoom,
  mapType = "roadmap",
  polygonStyle,
  coordinates,
}) => {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: GOOGLE_MAP_API_KEY,
  });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [map, setMap] = useState(null);

  const onLoad = useCallback((innerMap) => {
    setMap(innerMap);
  }, []);

  const onUnmount = useCallback(() => {
    setMap(null);
  }, []);

  const getPolygoneOptions = () => {
    return {
      fillColor: `#${polygonStyle?.PolyStyle?.color?.slice(0, -2) || "000000"}`,
      fillOpacity: 0.5,
      strokeColor: `#${
        polygonStyle?.LineStyle?.color?.slice(0, -2) || "000000"
      }`,
      strokeOpacity: 1,
      strokeWeight: polygonStyle?.LineStyle?.width || 2,
      clickable: false,
      draggable: false,
      editable: false,
      geodesic: false,
      zIndex: 1,
    };
  };

  return isLoaded ? (
    <div className={classNames([styles.map])}>
      <GoogleMap
        mapTypeId={mapType}
        mapContainerStyle={containerStyle}
        center={center}
        zoom={zoom}
        onLoad={onLoad}
        onUnmount={onUnmount}
      >
        {coordinates?.readonly?.map((coordinate, idx) => (
          <Marker
            key={idx}
            position={{
              lat: coordinate.coordinates[0].lat || 0,
              lng: coordinate.coordinates[0].lng || 0,
            }}
          />
        ))}

        <Polygon
          paths={coordinates?.editable[0].coordinates}
          options={getPolygoneOptions()}
        />
      </GoogleMap>
    </div>
  ) : (
    <></>
  );
};

export default memo(MapLeica);
