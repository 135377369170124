import React, {
  createContext,
  FC,
  ReactNode,
  useContext,
  useState,
} from "react";
import { ConfirmBox } from "../ConfirmBox/ConfirmBox";
import { toast } from "react-toastify";

type PropsT = {
  children: ReactNode;
};

export type ConfirmBoxT = {
  title: string;
  subTitle?: string | ReactNode;
  // eslint-disable-next-line
  asyncCallback?: () => Promise<any>;
  callback?: () => void;
  type: "delete" | "ok";
  buttonConfirmTitle?: string;
  buttonCancelTitle?: string;
  modalContent?: JSX.Element;
};

type ConfirmBoxLoadingT = ConfirmBoxT & { isLoading: boolean };

const ConfirmBoxContext = createContext<(params: ConfirmBoxT) => void>(
  () => {}
);

const ConfirmBoxProvider: FC<PropsT> = ({ children }) => {
  const [confirmBoxes, setConfirmBoxes] = useState<ConfirmBoxLoadingT[]>([]);

  const setLoading = (confirmBox: ConfirmBoxLoadingT, isLoading: boolean) => {
    setConfirmBoxes((prev) =>
      prev.map((b) => {
        if (b.title === confirmBox.title) return { ...b, isLoading };
        return b;
      })
    );
  };

  const onConfirm = async (confirmBox: ConfirmBoxLoadingT) => {
    setLoading(confirmBox, true);
    if (confirmBox.asyncCallback) {
      setConfirmBoxes((prev) => prev.slice(0, -1));
      try {
        await confirmBox.asyncCallback();
        // eslint-disable-next-line
      } catch (er: any) {
        toast.error(er.message);
      } finally {
        setLoading(confirmBox, false);
      }
    }
    if (confirmBox.callback) {
      confirmBox.callback();
      setLoading(confirmBox, false);
      setConfirmBoxes((prev) => prev.slice(0, -1));
    }
  };

  const onOpen = (params: ConfirmBoxT) => {
    setConfirmBoxes((prev) => [...prev, { ...params, isLoading: false }]);
  };

  const getButtonConfirmTitle = (confirmBox: ConfirmBoxLoadingT) => {
    if (confirmBox.buttonConfirmTitle) {
      return confirmBox.buttonConfirmTitle;
    }
    return confirmBox.type === "delete" ? "Delete" : "OK";
  };

  return (
    <>
      <ConfirmBoxContext.Provider value={onOpen}>
        {confirmBoxes.map((box, index) => (
          <ConfirmBox
            key={index}
            isOpen={Boolean(box)}
            title={box.title}
            subTitle={box.subTitle}
            onConfirm={() => onConfirm(box)}
            onClose={() => setConfirmBoxes((prev) => prev.slice(0, -1))}
            buttonConfirmProps={{
              isLoading: box.isLoading,
              color: box.type === "delete" ? "error" : "primary",
              title: getButtonConfirmTitle(box),
            }}
            buttonCancelProps={{
              title: box.buttonCancelTitle ?? "Cancel",
            }}
            modalContent={box.modalContent}
          />
        ))}
        {children}
      </ConfirmBoxContext.Provider>
    </>
  );
};

export default ConfirmBoxProvider;
export const useConfirm = () => useContext(ConfirmBoxContext);
