import React, { FC, useEffect, useState } from "react";
import classNames from "classnames";
import styles from "./newDiode.module.scss";
interface PropsT {
  size?: "sm" | "lg";
  color?: string;
  borderColor?: string;
  blink?: boolean;
  count?: number | "infinite";
  duration?: number;
  border?: boolean;
}

export const NewDiode: FC<PropsT> = ({
  size = "lg",
  color = "#ffffff",
  borderColor = "#cbcbcb",
  blink = false,
  count = "infinite",
  duration = 1500,
  border = false,
}) => {
  const [shouldBlink, setShouldBlink] = useState(blink);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    setShouldBlink(blink);
    if (typeof count === "number") {
      timeoutId = setTimeout(() => {
        setShouldBlink(false);
      }, duration * count);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [duration, count, blink, color]);

  return (
    <div
      id="ufonDiode"
      className={classNames(styles.diode, {
        [styles[`diode--sm`]]: size === "sm",
        [styles[`diode--with-border`]]: border,
      })}
      style={{
        backgroundColor: color,
        color,
        animationName: shouldBlink ? styles.blink : "",
        animationDuration: `${duration}ms`,
        animationTimingFunction: "step-start",
        animationIterationCount: count,
        borderColor: border ? borderColor : "",
      }}
    />
  );
};
