import React from "react";
import { Marker } from "@react-google-maps/api";

const UserMapMarker = ({
  userLocation,
}: {
  userLocation: {
    lat: number;
    lng: number;
  };
}) => {
  return (
    <Marker
      position={userLocation}
      icon={{
        path: google.maps.SymbolPath.CIRCLE,
        fillColor: "#1577B3",
        fillOpacity: 1,
        strokeColor: "#FFFFFF",
        scale: 6,
        strokeWeight: 3,
      }}
    />
  );
};

export default UserMapMarker;
