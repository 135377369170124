import React from "react";
import { Icon } from "../Icon";
import { renameJobType } from "../../../functions/renameJobType";
import { JobTypeNamesEnum } from "../../../types/Job";

const JobIcon = ({
  jobName,
  className,
}: {
  jobName: JobTypeNamesEnum;
  className?: string;
}) => {
  const getIcon = () => {
    switch (jobName) {
      case JobTypeNamesEnum.Manual:
        return <Icon className="me-2" icon="joystick" />;
      case JobTypeNamesEnum.Perimeter:
        return <Icon className="me-2" icon="ruler-combined" />;
      case JobTypeNamesEnum.Route:
        return <Icon className="me-2" icon="route" />;
      case JobTypeNamesEnum.Survey:
        return <Icon className="me-2" icon="ruler" />;
      default:
        return null;
    }
  };
  return (
    <div className={className}>
      {getIcon()}
      {renameJobType(jobName)}
    </div>
  );
};

export default JobIcon;
