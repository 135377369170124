import React from "react";
import {
  Controller,
  ControllerProps,
  FieldErrors,
  FieldPath,
  FieldValues,
} from "react-hook-form";
import { Input, InputProps } from "reactstrap";
import { getFieldError } from "../../../functions/forms";
import { SelectOptionsT } from "../../../types/Common";

export type BaseInputControllerT<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> = Omit<ControllerProps<TFieldValues, TName>, "render"> & {
  id?: string;
  input?: InputProps;
  placeholder?: string;
  options?: SelectOptionsT;
  errors?: FieldErrors<TFieldValues>;
  isLoading?: boolean;
};

export const BaseInputController = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  id,
  name,
  control,
  input,
  errors,
  placeholder,
  options,
  defaultValue,
}: BaseInputControllerT<TFieldValues, TName>) => {
  const fieldError = getFieldError(name, errors);

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field }) => (
        <Input
          id={id}
          invalid={!!fieldError?.message}
          placeholder={placeholder}
          type={options ? "select" : input?.type}
          checked={
            (input?.type === "radio" &&
              `${input?.value}` === `${field?.value}`) ||
            (input?.type === "checkbox" && field?.value)
          }
          {...field}
          value={input?.type === "file" ? undefined : field.value}
          onChange={
            input?.type === "file"
              ? (event) => field.onChange(event.target.files)
              : field.onChange
          }
          {...input}
        >
          {options?.map((option, index) => (
            <option key={index} value={option.value}>
              {option.label}
            </option>
          ))}
        </Input>
      )}
    />
  );
};
