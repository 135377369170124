import React, { FC, useEffect, useMemo } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { Col, Form, Row } from "reactstrap";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { InputController } from "../../components/molecules/Form/InputController";
import { mergeJobsSchema } from "./validations";
import { JobMergeOptionT } from "../../types/Job";
import { SelectOptionsT } from "../../types/Common";
import { useQueryUsersGet } from "../../hooks/useQueries";
import styles from "./Jobs.module.scss";

export type MergeJobsFormFieldsT = {
  name: string;
  mapping: { mowerId: number; userId: number }[];
};

type PropsT = {
  mergeOptions: JobMergeOptionT[];
  onTouch: () => void;
  onSubmit: (values: MergeJobsFormFieldsT) => void;
};

const MergeJobsForm: FC<PropsT> = ({ mergeOptions, onTouch, onSubmit }) => {
  const { data: usersData, isLoading: isLoadingUsers } = useQueryUsersGet();

  const {
    handleSubmit,
    control,
    formState: { errors, isDirty },
  } = useForm<MergeJobsFormFieldsT>({
    resolver: yupResolver(mergeJobsSchema),
    defaultValues: {
      name: "",
      mapping: mergeOptions.map((option) => ({
        mowerId: option.mower.id,
        userId: undefined,
      })),
    },
  });

  const { fields: mapping } = useFieldArray({
    name: "mapping",
    control,
  });

  const selectUserOptions = useMemo(
    () =>
      (
        [{ value: "", label: "Choose user", disabled: true }] as SelectOptionsT
      ).concat(
        (usersData?.data.items || []).map((user) => ({
          label: `${user.firstName} ${user.surname}`,
          value: user.id,
        }))
      ),
    [usersData]
  );

  useEffect(() => {
    if (isDirty) {
      onTouch();
    }
  }, [isDirty, onTouch]);

  return (
    <Form onSubmit={handleSubmit(onSubmit)} id="merge-jobs-form">
      <InputController
        name="name"
        label="Name"
        control={control}
        errors={errors}
        input={{
          placeholder: "Job name",
        }}
      />
      <div className={styles["options-list"]}>
        {mapping.map((pair, index) => (
          <Row key={pair.id} className={styles["option-row"]}>
            <Col lg="5" className={styles["option-label"]}>
              <span>{mergeOptions[index].mower.name}:</span>
            </Col>
            <Col lg="7">
              <InputController
                name={`mapping.${index}.userId`}
                control={control}
                errors={errors}
                options={selectUserOptions}
                input={{ disabled: isLoadingUsers }}
              />
            </Col>
          </Row>
        ))}
      </div>
    </Form>
  );
};

export default MergeJobsForm;
