import React, { FC, useState } from "react";
import { SubmitHandler } from "react-hook-form";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { queryClient } from "../..";
import {
  FormCompany,
  FormCompanyInputsT,
} from "../../components/molecules/FormCompany/FormCompany";
import { useMutationCompanyUpdate } from "../../hooks/useMutationCompanyUpdate";
import { CompanyT } from "../../types/Company";

type PropsT = {
  isOpen: boolean;
  initialData: CompanyT;
  onClose: () => void;
};

export const CompanyUpdateModal: FC<PropsT> = ({
  isOpen,
  initialData,
  onClose,
}) => {
  const [isFormTouched, setIsFormTouched] = useState(false);
  const updateCompanyMutation = useMutationCompanyUpdate(initialData.id, {
    onSuccess: () => {
      onClose();
      queryClient.invalidateQueries(["companies"]);
    },
  });

  const handleSubmit: SubmitHandler<FormCompanyInputsT> = async (
    data: FormCompanyInputsT
  ) => {
    updateCompanyMutation.mutate(data);
  };

  return (
    <Modal isOpen={isOpen} toggle={onClose}>
      <ModalHeader toggle={onClose}>Update Company</ModalHeader>
      <ModalBody>
        <FormCompany
          initialData={initialData}
          onTouch={() => setIsFormTouched(true)}
          onSubmit={handleSubmit}
        />
      </ModalBody>
      <ModalFooter>
        <Button color="light" onClick={onClose}>
          Cancel
        </Button>
        <Button
          disabled={!isFormTouched}
          color="primary"
          type="submit"
          form="company-form"
          className="me-2"
        >
          Update
        </Button>
      </ModalFooter>
    </Modal>
  );
};
