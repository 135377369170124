import { useEffect, useState } from "react";
import { Geolocation } from "@capacitor/geolocation";

export const usePermissionGeolocation = () => {
  const [permitted, setPermitted] = useState(false);
  const [loading, setLoading] = useState(true);

  async function checkPermission() {
    const state = await Geolocation.checkPermissions();
    if (state.location === "granted") {
      setPermitted(true);
    }
    setLoading(false);
  }

  const reload = () => {
    setLoading(true);
    setPermitted(false);
    checkPermission();
  };

  useEffect(() => {
    checkPermission();
  });

  return { permitted, loading, reload };
};
