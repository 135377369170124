import { FC } from "react";
import { Modal, ModalBody, ModalFooter } from "reactstrap";
import { Icon } from "../../atoms/Icon";
import { IconsId } from "../../../assets/fonts/iconfont/icons";
import { StyledButton } from "../../atoms/Button/Button";
import styles from "./permissionOverlay.module.scss";

type PropsT = {
  message: string;
  icon: IconsId;
  isOpen: boolean;
  allowText: string;
  allow: VoidFunction;
  goNext: VoidFunction;
};

export const PermissionOverlay: FC<PropsT> = ({
  message,
  icon,
  isOpen,
  allowText,
  allow,
  goNext,
}: PropsT) => {
  return (
    <Modal
      className={styles.modal}
      contentClassName={styles.overlay}
      isOpen={isOpen}
      fullscreen
    >
      <ModalBody className={styles.body}>
        <Icon className={styles.icon} icon={icon} />
        <div className={styles.message}>{message}</div>
      </ModalBody>
      <ModalFooter className={styles.footer}>
        <StyledButton
          className={styles.allowButton}
          onClick={allow}
          color="primary"
        >
          {allowText}
        </StyledButton>
        <StyledButton
          className={styles.goNextButton}
          onClick={goNext}
          icon="arrow-right"
          color="link"
        />
      </ModalFooter>
    </Modal>
  );
};
