import { FC } from "react";
import { NotificationT } from "../../../types/Notification";
import { NotificationItem } from "../../atoms/NotificationItem/NotificationItem";
import styles from "./gridRow.module.scss";

type PropsT = {
  data: NotificationT;
};

export const NotificationGridRow: FC<PropsT> = ({ data }: PropsT) => {
  return (
    <NotificationItem className={styles.notificationItem} notification={data} />
  );
};
