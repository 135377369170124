import {
  Pagination as RPagination,
  PaginationItem,
  PaginationLink,
} from "reactstrap";

type PropsT = {
  currentPage: number;
  pageCount: number;
  handlePageClick: (page: number) => void;
  shownNeighbours?: number;
  showNextPrev?: boolean;
};

function getPageNumbers(
  current: number,
  neighborCount: number,
  pageCount: number
) {
  const low = current - neighborCount;
  return Array.from(Array(2 * neighborCount + 1).keys())
    .map((x) => x + low)
    .filter((x) => x > 0 && x <= pageCount);
}

// current page expects be zero indexed
export function Pagination({
  currentPage,
  pageCount,
  handlePageClick,
  shownNeighbours,
  showNextPrev = true,
}: PropsT) {
  const pageNumbers = shownNeighbours
    ? getPageNumbers(currentPage + 1, shownNeighbours, pageCount)
    : [currentPage + 1];
  return (
    <RPagination>
      {showNextPrev && (
        <PaginationItem disabled={currentPage <= 0}>
          <PaginationLink
            onClick={() => handlePageClick(currentPage - 1)}
            previous
          >
            Prev
          </PaginationLink>
        </PaginationItem>
      )}
      {pageNumbers.map((page) => (
        <PaginationItem
          key={page}
          active={currentPage + 1 === page}
          disabled={currentPage + 1 === page}
        >
          <PaginationLink onClick={() => handlePageClick(page - 1)}>
            {page}
          </PaginationLink>
        </PaginationItem>
      ))}
      {showNextPrev && (
        <PaginationItem disabled={currentPage >= pageCount - 1}>
          <PaginationLink onClick={() => handlePageClick(currentPage + 1)} next>
            Next
          </PaginationLink>
        </PaginationItem>
      )}
    </RPagination>
  );
}
