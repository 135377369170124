import React, { FC, useState } from "react";
import {
  Dropdown,
  DropdownItem,
  DropdownItemProps,
  DropdownMenu,
  DropdownMenuProps,
  DropdownProps,
  DropdownToggle,
} from "reactstrap";
import { ButtonPropsT, StyledButton } from "../Button/Button";

type PropsT = {
  toggleProps?: ButtonPropsT;
  menuProps?: DropdownMenuProps;
  actions: Array<
    Omit<DropdownItemProps, "children"> & {
      label: string;
    }
  >;
} & DropdownProps;

export const DropdownButton: FC<PropsT> = ({
  toggleProps,
  actions,
  menuProps,
  children,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen((prev) => !prev);
  };

  return (
    <Dropdown toggle={handleToggle} isOpen={isOpen}>
      <DropdownToggle tag="div">
        <StyledButton color={toggleProps?.color || "primary"} {...toggleProps}>
          {children}
        </StyledButton>
      </DropdownToggle>
      <DropdownMenu {...menuProps}>
        {actions.map((action) => (
          <DropdownItem key={`dropdown-${action.label}`} {...action}>
            {action.label}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </Dropdown>
  );
};
