import React, { FC } from "react";
import { Input } from "reactstrap";
import { CoordinatesT, LatLngT } from "../../../types/Route";
import { StyledButton } from "../../atoms/Button/Button";
import styles from "./map.module.scss";

export type PolymarkerDirectionsT = "north" | "south" | "east" | "west";

type PropsT = {
  editablePolyline: number;
  activePolylineMarker: number;
  onSetStepMeters: (value: number) => void;
  stepMeters: number;
  currentCoordinates?: CoordinatesT<"route">;
  onCoordinatesChange?: (updatedCoordinates: CoordinatesT<"route">) => void;
};

export const MapPolylineControl: FC<PropsT> = ({
  editablePolyline,
  currentCoordinates,
  onCoordinatesChange,
  activePolylineMarker,
  onSetStepMeters,
  stepMeters,
}) => {
  if (!currentCoordinates) {
    return null;
  }

  const getNewLatitude = (
    oldLatitude: number,
    direction: string,
    coef: number
  ) => {
    switch (direction) {
      case "west":
      case "east":
        return oldLatitude;
      case "south":
        return oldLatitude - (coef / 6378) * (180 / Math.PI);
      case "north":
      default:
        return oldLatitude + (coef / 6378) * (180 / Math.PI);
    }
  };

  const getNewLongitude = (
    oldLatitude: number,
    oldLongitude: number,
    direction: PolymarkerDirectionsT,
    coef: number
  ) => {
    switch (direction) {
      case "west":
        return (
          oldLongitude -
          ((coef / 6378) * (180 / Math.PI)) /
            Math.cos((oldLatitude * Math.PI) / 180)
        );
      case "east":
        return (
          oldLongitude +
          ((coef / 6378) * (180 / Math.PI)) /
            Math.cos((oldLatitude * Math.PI) / 180)
        );
      case "south":
        return oldLongitude;
      case "north":
      default:
        return oldLongitude;
    }
  };

  const movePolylineMarker = (direction: PolymarkerDirectionsT) => {
    const meters = stepMeters;
    const oldPositionCoordinates = currentCoordinates?.coordinates.find(
      (c) => c.id === editablePolyline
    );
    const oldPositionMarker = oldPositionCoordinates?.coordinates.find(
      (_, index) => index === activePolylineMarker
    );

    const newLat = getNewLatitude(
      oldPositionMarker?.lat as number,
      direction,
      meters
    );

    const newLong = getNewLongitude(
      oldPositionMarker?.lat as number,
      oldPositionMarker?.lng as number,
      direction,
      meters
    );

    const newCoordinates: CoordinatesT<"route"> = {
      ...currentCoordinates,
      coordinates: currentCoordinates.coordinates.map((c) => {
        return c.id === editablePolyline
          ? {
              ...c,
              coordinates: c.coordinates.map((coordinate, index) => {
                return index === activePolylineMarker
                  ? ({ lng: newLong, lat: newLat } as LatLngT)
                  : coordinate;
              }),
            }
          : c;
      }),
    };
    onCoordinatesChange?.(newCoordinates);
  };

  if (!(activePolylineMarker !== -1)) {
    return null;
  }

  return (
    <div className={styles["polyline-marker-control"]}>
      <div className={styles["polyline-marker-control--inputs"]}>
        <div className="d-flex align-items-center gap-1 fs-7">
          <div>Position:</div>
          <div className="fw-bold">123456, 45455665</div>
        </div>
        <div className="d-flex align-items-center gap-1 fs-7">
          <div>Move point</div>
          <div className={styles["input-wrapper"]}>
            <Input
              min="1"
              type="number"
              className="ps-2 pe-0 text-end"
              value={stepMeters * 100000}
              onChange={(event) =>
                onSetStepMeters(parseInt(event.target.value) / 100000)
              }
            />
          </div>
          <div>cm</div>
        </div>
      </div>

      <div className={styles["grid-container"]}>
        <StyledButton icon="arrow-left-up" color="primary" kind="iconButton" />
        <StyledButton
          color="primary"
          icon="arrow-up"
          kind="iconButton"
          onClick={() => movePolylineMarker("north")}
        />
        <StyledButton icon="arrow-up-right" color="primary" kind="iconButton" />
        <StyledButton
          color="primary"
          icon="arrow-left"
          kind="iconButton"
          onClick={() => movePolylineMarker("west")}
        />
        <StyledButton className="invisible" />
        <StyledButton
          color="primary"
          icon="arrow-right-rounded"
          kind="iconButton"
          onClick={() => movePolylineMarker("east")}
        />
        <StyledButton
          color="primary"
          icon="arrow-down-left"
          kind="iconButton"
        />
        <StyledButton
          color="primary"
          icon="arrow-down"
          kind="iconButton"
          onClick={() => movePolylineMarker("south")}
        />
        <StyledButton
          color="primary"
          icon="arrow-down-right"
          kind="iconButton"
        />
      </div>
    </div>
  );
};
