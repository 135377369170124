import { useMutation } from "react-query";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { UserInputsT } from "../components/molecules/FormUser/FormUser";
import { ROUTES } from "../routes";
import { client } from "../services/axios";
import { ApiErrorT } from "../types/Common";
import { MutationOptionsT } from "../types/ReactQuery";

export const useMutationUserCreate = (options?: MutationOptionsT) => {
  const navigate = useNavigate();

  return useMutation(
    (data: UserInputsT) => {
      const { company, ...dataToPut } = data;

      return client.post(`/api/v1/user`, {
        ...dataToPut,
        company: company.map((comp) => Number(comp.companyId)),
        role: Number(data.role),
      });
    },
    {
      onSuccess: () => {
        toast.success("User has been created");
        navigate(ROUTES.users());
        if (options?.onSuccess) {
          options.onSuccess();
        }
      },
      onError: (error: ApiErrorT) => {
        toast.error(error?.message);
        if (options?.onError) {
          options.onError();
        }
      },
    }
  );
};
