import React, { FC } from "react";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { useForm } from "react-hook-form";
import {
  Button,
  Form,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { object, string } from "yup";
import { VALIDATIONS } from "../../../constants";
import { useMutationEditJob } from "../../../hooks/useMutationEditJob";
import { useQueryJobGet } from "../../../hooks/useQueries";
import { createInputsFromJob } from "../../../functions/createJob";
import { InputController } from "../../../components/molecules/Form/InputController";

type PropsT = {
  jobId: number | string;
  initialName: string;
  isOpen: boolean;
  onClose: () => void;
  refetch: () => void;
};

const editJobSchema = object({
  name: string().required(VALIDATIONS.required),
}).required();

export const EditJobModal: FC<PropsT> = ({
  jobId,
  initialName,
  isOpen,
  onClose,
  refetch,
}) => {
  const mutation = useMutationEditJob(`${jobId}`, {
    onSuccess: () => {
      refetch();
      onClose();
    },
  });

  const jobData = useQueryJobGet(jobId);

  const {
    handleSubmit,
    control,
    formState: { errors, isDirty },
  } = useForm<{ name: string }>({
    resolver: yupResolver(editJobSchema),
    defaultValues: { name: initialName },
  });

  const handleEdit = (formValues: { name: string }) => {
    if (jobData.data?.data) {
      mutation.mutate(createInputsFromJob(jobData.data.data, formValues.name));
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={onClose}>
      <ModalHeader toggle={onClose}>Rename job</ModalHeader>
      <ModalBody>
        <Form onSubmit={handleSubmit(handleEdit)} id="merge-jobs-form">
          <InputController
            name="name"
            label="Name"
            control={control}
            errors={errors}
            input={{
              placeholder: "Job name",
            }}
          />
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="light" onClick={onClose}>
          Cancel
        </Button>
        <Button
          disabled={!isDirty}
          color="primary"
          type="submit"
          form="merge-jobs-form"
          className="me-2"
        >
          Merge
        </Button>
      </ModalFooter>
    </Modal>
  );
};
