import classNames from "classnames";
import { DATE_FORMATS } from "../../../constants";
import { NotificationT } from "../../../types/Notification";
import { useQueryJobGet } from "../../../hooks/useQueries";
import { formatDate } from "../../../functions/formatDate";
import styles from "./notificationList.module.scss";

type PropsT = {
  notification: NotificationT;
  className?: string;
};

export function NotificationItem({ notification, className }: PropsT) {
  const { data: job } = useQueryJobGet(notification.jobId);
  return (
    <div className={classNames(styles.item, className)}>
      <div className={styles.itemHeader}>{notification.subject}</div>
      <div className={styles.itemContent}>
        <span>
          {formatDate(notification.createdAt, DATE_FORMATS.dateTimeMedium)}
        </span>
        <span>{job ? job.data.name : "-"}</span>
      </div>
    </div>
  );
}
