import React from "react";
import { StyledButton } from "../Button/Button";
import styles from "./reloadButton.module.scss";
import { useSelector } from "../../../store/hooks";

const ReloadButton = () => {
  const { wsIsOpen } = useSelector((store) => store.webSocket);

  if (wsIsOpen) {
    return null;
  }

  return (
    <div className={styles.refreshPage}>
      <StyledButton
        icon="reboot"
        color="warning"
        size="lg"
        className={styles["refreshPage-btn"]}
        onClick={() => {
          window.location.reload();
        }}
      >
        Live data unavailable, refresh page
      </StyledButton>
    </div>
  );
};

export default ReloadButton;
