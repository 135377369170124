import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import React, { FC } from "react";
import { useForm } from "react-hook-form";
import { Form } from "reactstrap";
import { InputController } from "../../components/molecules/Form/InputController";
import { passwordRecoverySchema } from "./validation";

export type PasswordRecoveryFieldsT = {
  password: string;
  cpassword: string;
};

type PropsT = {
  onSubmit: (inputs: PasswordRecoveryFieldsT) => void;
};

export const PasswordRecoveryForm: FC<PropsT> = ({ onSubmit }) => {
  const getDefaultValues = () => ({
    password: "",
    cpassword: "",
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<PasswordRecoveryFieldsT>({
    resolver: yupResolver(passwordRecoverySchema),
    defaultValues: getDefaultValues(),
  });

  return (
    <Form onSubmit={handleSubmit(onSubmit)} id="password-recovery-form" inline>
      <InputController
        name="password"
        control={control}
        errors={errors}
        input={{
          placeholder: "New password",
          bsSize: "lg",
          type: "password",
        }}
        formGroup={{
          className: "mb-2",
        }}
      />
      <InputController
        name="cpassword"
        control={control}
        errors={errors}
        input={{
          placeholder: "Confirm new password",
          bsSize: "lg",
          type: "password",
        }}
        formGroup={{
          className: "mb-4",
        }}
      />
    </Form>
  );
};
