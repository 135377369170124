import { FC } from "react";
import { Marker } from "@react-google-maps/api";
import { JOB_ROUTE_TYPE_COLORS } from "../../../constants";
import { MeasurementT } from "../../../types/Geostick";

type PropsT = {
  measurement: MeasurementT;
  onClick: VoidFunction;
  active: boolean;
};

const getPointIcon = (outsideColor: string, insideColor: string) => {
  const encodedOutsideColor = outsideColor.replace("#", "%23");
  const encodedInsideColor = insideColor.replace("#", "%23");
  return `data:image/svg+xml;charset=UTF-8,<svg width="500" height="500" viewBox="0 0 500 500" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g transform="scale(22.727272) translate(-12 -8)">
<circle cx="23" cy="19" r="11" fill="${encodedInsideColor}"/>
<circle cx="23" cy="19" r="9" stroke="${encodedOutsideColor}" stroke-width="4"/>
    </g>
</svg>
`;
};

export const MeasurementMarker: FC<PropsT> = ({
  measurement,
  active,
  onClick,
}: PropsT) => {
  const defaultInsideColor = measurement.fix ? "#ffffff" : "#ffca05";
  return (
    <Marker
      position={{ lat: measurement.latitude, lng: measurement.longitude }}
      zIndex={250}
      icon={{
        url: getPointIcon(
          JOB_ROUTE_TYPE_COLORS.plannedRoute,
          active ? JOB_ROUTE_TYPE_COLORS.plannedRoute : defaultInsideColor
        ),
        scaledSize: new google.maps.Size(18, 18),
        anchor: new google.maps.Point(9, 9),
      }}
      onClick={onClick}
    />
  );
};
