import React, { FC, ReactElement, MouseEvent } from "react";
import { Container, Dropdown, DropdownToggle } from "reactstrap";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";
import { useSearchParams } from "../../../hooks/useSearchParam";
import { useCompactDesign } from "../../../hooks/useCompactDesign";
import {
  MenuWithLayoutItemT,
  MenuWithLayoutPropsT,
  getIsCheckedItem,
} from "../../organisms/MenuWithLayout/MenuWithLayout";
import { StyledButton } from "../Button/Button";
import { FilterItem } from "../FilterItem/FilterItem";
import { FilterMenu } from "../FilterMenu/FilterMenu";
import { Icon } from "../Icon";
import { PlainButton } from "../PlainButton/PlainButton";
import { Search } from "../Search/Search";
import { SwitchButton } from "../SwitchButton/SwitchButton";
import { SwitchGroup } from "../SwitchGroup/SwitchGroup";
import Title from "../Title/Title";
import { Diode } from "../Diode/Diode";
import styles from "./subHeader.module.scss";

export type SubHeaderPropsT = Pick<
  MenuWithLayoutPropsT,
  "items" | "switchers" | "activeItem" | "checkedItems" | "fixedItem"
> & {
  title?: string;
  secondaryTitle?: string;
  titleDropdown?: string;
  search?: {
    placeholder: string;
    onChange: (search: string) => void;
  };
  actions?: ReactElement;
  addNew?: () => void;
  onGoBack?: () => void;
  addNewTitle?: string;
  isDetail?: boolean;
  isAdvancedDetail?: boolean;
  status?: boolean;
  headerRef?: React.RefObject<HTMLDivElement>;
  hideBackIcon?: boolean;
};

const SubHeader: FC<SubHeaderPropsT> = ({
  title,
  secondaryTitle,
  search,
  actions,
  items,
  activeItem,
  checkedItems,
  fixedItem,
  titleDropdown,
  switchers,
  addNew,
  onGoBack,
  addNewTitle,
  isDetail,
  isAdvancedDetail,
  status,
  headerRef,
  hideBackIcon,
}) => {
  const [open, setOpen] = React.useState<boolean>(false);
  const { getParamValue } = useSearchParams();
  const toggle = () => {
    setOpen(!open);
  };
  const isCompact = useCompactDesign();

  const handleClick = (
    event: MouseEvent<HTMLElement>,
    item: MenuWithLayoutItemT
  ) => {
    event.preventDefault();
    if (item.onClick) {
      item.onClick();
    }
  };

  const handleOnDelete = (
    event: MouseEvent<HTMLDivElement>,
    item: MenuWithLayoutItemT
  ) => {
    event.preventDefault();
    item.onDelete?.(item.id as number);
    event.stopPropagation();
  };

  const handleOnUpdate = (
    event: MouseEvent<HTMLDivElement>,
    item: MenuWithLayoutItemT
  ) => {
    event.preventDefault();
    item.onUpdate?.(item.id as number);
    event.stopPropagation();
  };

  const navigate = useNavigate();

  const getIsActiveItem = (item: MenuWithLayoutItemT) => {
    if (Array.isArray(activeItem) && item.id) {
      return activeItem?.includes(item.id);
    }
    return activeItem === item.id;
  };

  const handleGoBack = () => {
    if (onGoBack) {
      onGoBack();
    } else {
      navigate(-1);
    }
  };

  return (
    <div
      ref={headerRef}
      className={classNames(
        styles["sub-header"],
        isDetail && styles["sub-header--detail"],
        !isCompact && styles["sub-header--large"]
      )}
    >
      <Container
        className={classNames(
          styles.container,
          isDetail && styles["container--detail"],
          {
            [styles["container--detail-advanced"]]: isAdvancedDetail,
            [styles["container--compact"]]: isCompact,
          }
        )}
        fluid
      >
        <div
          className={classNames(
            isCompact
              ? styles["title-and-menu--compact"]
              : styles["title-and-menu"],
            {
              [styles["title-and-menu--detail"]]: isDetail,
              [styles["title-and-menu--detail--compact"]]:
                isDetail && isCompact,
              [styles["title-and-menu--detail-advanced"]]: isAdvancedDetail,
              [styles["title-and-menu--detail-advanced--large"]]:
                isAdvancedDetail && !isCompact,
            }
          )}
        >
          {isDetail && (
            <PlainButton
              onClick={handleGoBack}
              icon="chevron-left"
              className={hideBackIcon ? "d-none" : ""}
            />
          )}
          {(!isCompact || isDetail) && (
            <div
              className={classNames(
                isDetail && styles["title--with-diode"],
                styles.titleWrapper
              )}
            >
              {typeof status === "boolean" && (
                <Diode withBorder status={status ? "ok" : "nok"} />
              )}
              <Title
                className={classNames(
                  styles.title,
                  isCompact && styles["title--compact"]
                )}
                type="h1"
                size="h2"
                noMarginBottom
              >
                {title}
              </Title>
            </div>
          )}
          {items && (
            <Dropdown className={styles.dropdown} isOpen={open} toggle={toggle}>
              {isCompact && (
                <DropdownToggle className={styles["dropdown-toggle"]}>
                  {titleDropdown}
                  <Icon
                    icon="angle-down"
                    className={styles["dropdown-toggle__caret"]}
                  />
                </DropdownToggle>
              )}
              <FilterMenu>
                {switchers && (
                  <SwitchGroup>
                    {switchers.map((switcher) => (
                      <SwitchButton key={switcher.title} {...switcher} />
                    ))}
                  </SwitchGroup>
                )}
                {items.map((item) => (
                  <FilterItem
                    key={item.title}
                    onClick={(event) => {
                      handleClick(event, item);
                      toggle();
                    }}
                    item={item}
                    itemCount={item.itemCount}
                    isActiveItem={getIsActiveItem(item)}
                    isCheckedItem={getIsCheckedItem(item, checkedItems)}
                    onDelete={item.onDelete ? handleOnDelete : undefined}
                    onUpdate={item.onUpdate ? handleOnUpdate : undefined}
                  />
                ))}
                {addNew && (
                  <StyledButton
                    icon="plus-circle"
                    color="link"
                    className="ps-3 pt-4"
                    size="sm"
                    onClick={addNew}
                    title={addNewTitle}
                  />
                )}
                {fixedItem && (
                  <FilterItem
                    key={fixedItem.title}
                    onClick={(event) => {
                      handleClick(event, fixedItem);
                      toggle();
                    }}
                    item={fixedItem}
                    itemCount={fixedItem.itemCount}
                    isActiveItem={getIsActiveItem(fixedItem)}
                    isCheckedItem={getIsCheckedItem(fixedItem, checkedItems)}
                    onDelete={fixedItem.onDelete ? handleOnDelete : undefined}
                    onUpdate={fixedItem.onUpdate ? handleOnUpdate : undefined}
                  />
                )}
              </FilterMenu>
            </Dropdown>
          )}
        </div>
        <div
          className={classNames(styles["search-and-actions"], {
            [styles["search-and-actions--large"]]: !isCompact,
            [styles["search-and-actions--detail--compact"]]:
              isCompact && isDetail,
            [styles["search-and-actions--advanced"]]: isAdvancedDetail,
            [styles["search-and-actions--advanced--large"]]:
              !isCompact && isAdvancedDetail,
          })}
        >
          {isAdvancedDetail && (
            <Title
              className={classNames(
                styles.title,
                styles["title--secondary"],
                isCompact && styles["title--compact"],
                !isCompact && styles["title--secondary--large"]
              )}
              type="h1"
              size="h2"
              noMarginBottom
            >
              {secondaryTitle}
            </Title>
          )}
          {search && (
            <>
              <Search
                initialValue={getParamValue("search") || ""}
                placeholder={search.placeholder}
                onChange={search.onChange}
              />
            </>
          )}
          {actions && <div className={styles.actions}>{actions}</div>}
        </div>
      </Container>
    </div>
  );
};

export default SubHeader;
