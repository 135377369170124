export const getHumanReadableText = (str: string | null): string => {
  if (str) {
    return `${str[0].toUpperCase()}${str
      .slice(1)
      .replace(/([a-z\d])([A-Z]+)/g, "$1 $2")
      .toLowerCase()}`;
  }

  return "";
};

export const removeDash = (str: string): string => {
  return str.replace(/-/g, " ");
};

export const splitPascalCase = (str: string): string => {
  const result = str
    .replace(/([A-Z][a-z])/g, " $1")
    .replace(/([A-Z]+)/g, " $1")
    .replace(/ +/g, " ")
    .replace(/^ +/g, "");

  return result.charAt(0).toUpperCase() + result.slice(1);
};
