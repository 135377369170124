import { useState } from "react";
import { InputGroup, InputGroupText } from "reactstrap";
import { DebounceInput } from "react-debounce-input";
import classNames from "classnames";
import { useCompactDesign } from "../../../hooks/useCompactDesign";
import { Icon } from "../Icon";
import { StyledButton } from "../Button/Button";
import styles from "./search.module.scss";

type Props = {
  placeholder: string;
  onChange: (search: string) => void;
  initialValue?: string;
  className?: string;
};

export const Search: React.FC<Props> = ({
  placeholder,
  onChange,
  className,
  initialValue,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const isCompact = useCompactDesign();

  const handleToggleOpen = () => {
    setIsOpen((prev) => !prev);
  };

  return (
    <>
      <StyledButton
        color="primary"
        icon="search"
        className={styles["search-button"]}
        onClick={handleToggleOpen}
      />
      <div
        className={classNames([
          styles["search-form"],
          className,
          { [styles["search-form--open"]]: isOpen },
        ])}
      >
        <InputGroup
          className={classNames(
            styles["search-input-group"],
            isCompact && styles["search-input-group--compact"]
          )}
        >
          <InputGroupText
            className={classNames(
              styles["search-input-group-text"],
              isCompact && styles["search-input-group-text--compact"]
            )}
          >
            <Icon icon="search" />
          </InputGroupText>
          <DebounceInput
            value={initialValue}
            minLength={2}
            debounceTimeout={500}
            className={classNames(
              styles["search-input"],
              isCompact && styles["search-input--compact"],
              "form-control"
            )}
            placeholder={placeholder}
            onChange={(event) => onChange(event.target.value)}
          />
          <StyledButton
            onClick={handleToggleOpen}
            icon="times"
            className={styles["search-close"]}
          />
        </InputGroup>
      </div>
    </>
  );
};
