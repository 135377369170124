import { ROUTES } from "../../../routes";

export const getLinks = (
  selectedCompanyId: number | null,
  usersVisibility: boolean,
  areCompaniesShowing: boolean,
  areStatisticsShowing: boolean
) => {
  const links = [
    { title: "Dashboard", link: ROUTES.dashboard(), pathKeys: ["/"] },
  ];
  if (selectedCompanyId !== 0) {
    links.push({
      title: "Jobs",
      link: ROUTES.jobs(),
      pathKeys: ["job"],
    });
    links.push({
      title: "Routes",
      link: ROUTES.routes(),
      pathKeys: ["route", "leica"],
    });
  }
  links.push({
    title: "Devices",
    link: ROUTES.devices(),
    pathKeys: ["devices", "mower", "ufon"],
  });
  if (usersVisibility) {
    links.push({ title: "Users", link: ROUTES.users(), pathKeys: ["user"] });
  }
  if (areCompaniesShowing) {
    links.push({
      title: "Companies",
      link: ROUTES.companies(),
      pathKeys: ["companies"],
    });
  }
  if (areStatisticsShowing) {
    links.push({
      title: "Statistics",
      link: ROUTES.statistics(),
      pathKeys: ["statistics"],
    });
  }
  return links;
};
