import React from "react";
import { Container } from "reactstrap";
import logo from "../../../assets/images/logo.svg";
import { Loader } from "../../atoms/Loader/Loader";
import styles from "./styles.module.scss";

type Props = {};

export const SplashScreen: React.FC<Props> = () => {
  return (
    <Container>
      <div className={styles.wrapper}>
        <img className="mb-4" src={logo} width="227" height="49" />
        <Loader />
      </div>
    </Container>
  );
};
