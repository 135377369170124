import classNames from "classnames";
import { JOB_STATUSES } from "../../../constants";
import { JobStatusNameT } from "../../../types/Job";
import styles from "./numberBullet.module.scss";

type Props = {
  number: number | undefined;
  className?: string;
  status?: JobStatusNameT;
};

export const NumberBullet: React.FC<Props> = ({
  number,
  className,
  status,
}) => {
  return (
    <div
      className={classNames([styles["number-bullet"], className], {
        [styles["number--active"]]:
          status === JOB_STATUSES.Open || status === JOB_STATUSES.Online,
        [styles["number--danger"]]: status === JOB_STATUSES.Overdue,
      })}
    >
      {number}
    </div>
  );
};
