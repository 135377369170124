import { useMutation } from "react-query";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { RouteCloneInputsT } from "../components/molecules/FormRoute/FormRouteClone";
import { ROUTES } from "../routes";
import { client } from "../services/axios";
import { ApiErrorT } from "../types/Common";

export const useMutationRouteClone = () => {
  const navigate = useNavigate();

  return useMutation(
    (data: RouteCloneInputsT) => {
      return client.post(`/api/v1/route/${data.routeId}/clone`, {
        name: data.name,
        areaFolder: Number(data.group),
      });
    },
    {
      onSuccess: (data) => {
        toast.success("Route has been cloned");
        navigate(ROUTES.route(data.data.id));
      },
      onError: (error: ApiErrorT) => {
        toast.error(error?.message);
      },
    }
  );
};
