import classNames from "classnames";
import { FC } from "react";
import { useCompactDesign } from "../../../hooks/useCompactDesign";
import styles from "./diode.module.scss";

export type DiodeStatus = "done" | "ok" | "nok" | "warning";

export type DiodeStatesT = {
  status: DiodeStatus;
  withBorder?: boolean;
  size?: "sm";
  noMarginRight?: boolean;
  withBorderSmall?: boolean;
};

export const Diode: FC<DiodeStatesT> = ({
  status,
  size,
  noMarginRight,
  withBorder,
  withBorderSmall,
}) => {
  const isCompact = useCompactDesign();
  return (
    <span
      className={classNames(styles.diode, {
        [styles["diode-sm"]]: size === "sm",
        [styles.ok]: status === "ok",
        [styles.nok]: status === "nok",
        [styles.warning]: status === "warning",
        [styles.done]: status === "done",
        [styles["diode--with-border"]]: withBorder,
        [styles["diode--with-border--compact"]]: isCompact && withBorder,
        ["me-0"]: noMarginRight,
        [classNames(
          styles["diode--with-border"],
          styles["diode--with-border--small"],
          { [styles["diode--with-border--compact"]]: isCompact }
        )]: withBorderSmall,
      })}
    />
  );
};
