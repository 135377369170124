import React, { FC, useMemo, useState } from "react";
import { Marker, MarkerClusterer } from "@react-google-maps/api";
import { MapMarkersT, MapMarkerT } from "../../../types/Common";
import MowerOfflineIcon from "../../../assets/images/mower-tracker-offline.svg";
import MowerOnlineIcon from "../../../assets/images/mower-tracker-online.svg";
import UfonOnlineIcon from "../../../assets/images/ufon-tracker-online.svg";
import UfonOfflineIcon from "../../../assets/images/ufon-tracker-offline.svg";
import { MapInfoWindow } from "./MapInfoWindow";

type PropsT = {
  devices: MapMarkersT[];
  showTooltips?: boolean;
};

const MapDeviceCluster: FC<PropsT> = ({ devices, showTooltips = true }) => {
  const [activeMarker, setActiveMarker] = useState<string>();
  const markers = useMemo(() => {
    if (devices.length === 1) {
      return devices[0].items.map((item) => ({
        ...item,
        type: devices[0].type,
      }));
    }

    return [
      ...devices[0].items.map((item) => ({ ...item, type: devices[0].type })),
      ...devices[1].items.map((item) => ({ ...item, type: devices[1].type })),
    ];
  }, [devices]);

  const getIcon = (device: MapMarkerT) => {
    if (device.type === "ufon") {
      return device.isOnline ? UfonOnlineIcon : UfonOfflineIcon;
    } else {
      return device.isOnline ? MowerOnlineIcon : MowerOfflineIcon;
    }
  };

  return (
    <>
      <MarkerClusterer maxZoom={18}>
        {(clusterer) => (
          <>
            {markers.map((marker) => {
              const { id, latitude, longitude, type } = marker;
              if (!latitude || !longitude) {
                return null;
              }

              return (
                <Marker
                  clusterer={clusterer}
                  icon={{
                    url: getIcon(marker),
                    scaledSize:
                      marker.type === "ufon"
                        ? new google.maps.Size(50, 50)
                        : new google.maps.Size(60, 60),
                  }}
                  key={`${type}-${id}`}
                  onClick={() => setActiveMarker(`${type}-${id}`)}
                  position={{
                    lat: latitude,
                    lng: longitude,
                  }}
                />
              );
            })}
          </>
        )}
      </MarkerClusterer>
      {showTooltips && (
        <MapInfoWindow
          activeMarker={markers.find(
            ({ id, type }) => `${type}-${id}` === activeMarker
          )}
          onClose={() => setActiveMarker(undefined)}
        />
      )}
    </>
  );
};

export default MapDeviceCluster;
